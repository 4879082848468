import { useIntl } from "react-intl";

const useErrorMessageMultiLanguageForgotPassword = () => {
const intl = useIntl()

  const ERROR_EMAIL_NOT_FOUND = intl.formatMessage({
    id: "forgotPassword.errorMessage.404_Email_Not_Found"
  })

  const ERROR_USER_NOT_APPROVED = intl.formatMessage({
    id: "forgotPassword.errorMessage.400_User_Not_Approved"
  })

  const ERROR_FORGOT_PASSWORD_MUST_DIFFERENT = intl.formatMessage({
    id: "changePassword.errorMessage.400.passwordMustDifferent"
  })

  const ERROR_USER_HAS_BEEN_SUSPENDED = intl.formatMessage({
    id: 'changePassword.errorMessage.400_User_Has_Been_Suspended',
  });

  return {
    ERROR_EMAIL_NOT_FOUND,
    ERROR_USER_NOT_APPROVED,
    ERROR_FORGOT_PASSWORD_MUST_DIFFERENT,
    ERROR_USER_HAS_BEEN_SUSPENDED
  };
};

export default useErrorMessageMultiLanguageForgotPassword;