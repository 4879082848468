import React, { useEffect, useState } from 'react';
import { Button, Form, Input, Popover } from 'antd';
import FormFileUpload from 'page/Application/ManageProjectRegistration/component/FormFileUpload/index';
import AntModalTitleFile from 'components/AntModalTitleFile';
import { Grid1, Grid2, GridForm } from './styled';
import FormLabelGrid from 'page/Application/ManageProjectRegistration/component/FormLabelGrid';
import FormTextRequired from 'page/Application/ManageProjectRegistration/component/FormTextRequired';
import { Container, FormWrapper } from 'components/Form/Template/styled';
import FormRadio from 'page/Application/ManageProjectRegistration/component/FormRadio';
import FormDateRangePicker from 'page/Application/ManageProjectRegistration/component/FormDateRangePicker';
import { InfoCircleOutlined } from '@ant-design/icons';
import { FormDatePicker } from 'page/Application/ManageNotificationList/component/Form';
import { DATE_FORMAT_LIST } from 'constant/notificationConstants';

const ModalTitleFile = ({
  isModalOpen,
  handleCancel,
  handleOk,
  isEdit,
  form,
  dataSource,
  checkStatus,
  setDetailStatus,
  isCheckDate,
  setIsCheckDate,
  setFileObject,
  fileObject,
  formName,
}) => {
  const [, setIdFile] = useState(null);
  const { TextArea } = Input;
  const [displayStatus, setDisplayStatus] = useState(
    dataSource?.displayStatus ?? true,
  );
  const [isDisabled, setIsDisabled] = useState(false);

  const onChangeStatus = (e) => {
    const statusValue = e.target.value;
    form.setFieldValue('publishedFromToDate', []);
    form.setFieldValue('displayStatus', statusValue);
    setDisplayStatus(statusValue);
    setDetailStatus(!statusValue);
  };
  const initialValues = {
    displayStatus: displayStatus,
    publishedFromToDate: [null],
  };

  useEffect(() => {
    setDetailStatus(!dataSource?.displayStatus);
    setFileObject({
      fileId: dataSource?.multipartFile?.id,
      keyFileName: dataSource?.multipartFile?.uid,
      fileName: dataSource?.multipartFile?.name,
      size: dataSource?.multipartFile?.size,
      contentType: dataSource?.multipartFile?.type,
    });
    form.setFieldsValue({
      titleFile: dataSource?.title,
      file: [dataSource?.multipartFile],
      displayedDate: dataSource?.displayDate,
      textArea: dataSource?.explanation,
      publishedFromToDate: [dataSource?.publishedFrom, dataSource?.publishedTo],
    });
  }, [dataSource, form, setDetailStatus, setFileObject]);

  return (
    <AntModalTitleFile
      title={isEdit ? 'ファイル情報の変更' : 'ファイル情報入力'}
      handleOk={handleOk}
      handleCancel={handleCancel}
      isModalOpen={isModalOpen}
      width={1000}
      paddingRight={'12px'}
      isDisabled={isDisabled}
      content={
        <FormWrapper>
          <Container style={{ margin: '40px 20px 8px 40px' }}>
            <Form
              form={form}
              layout="vertical"
              name={formName}
              initialValues={initialValues}
              onFinish={handleOk}
            >
              <GridForm>
                <Grid1>
                  <FormLabelGrid labelText={'タイトル'} required={true} />
                </Grid1>
                <Grid2>
                  <FormTextRequired
                    placeHolder={'タイトル名を入力してください'}
                    name="titleFile"
                    required={true}
                    maxLength={256}
                  />
                </Grid2>
                <FormRadio
                  name={'displayStatus'}
                  required={false}
                  onChange={onChangeStatus}
                />
                <Grid1>
                  <FormLabelGrid labelText={'説明'} required={false} />
                </Grid1>
                <Grid2 style={{ paddingBottom: 14 }}>
                  <Form.Item name={'textArea'}>
                    <TextArea
                      style={{ fontFamily: 'inter-regular, sans-serif' }}
                      rows={4}
                    />
                  </Form.Item>
                </Grid2>
                <Grid1>
                  <FormLabelGrid
                    labelText={`公開日時予約・非公開日時予約`}
                    required={false}
                  />
                </Grid1>
                <Grid2 style={{ display: 'flex' }}>
                  <FormDateRangePicker
                    name="publishedFromToDate"
                    message={
                      '終了日時を入力しない場合は、公開期間は無期限になります。'
                    }
                    width={'640px'}
                    listDisable={checkStatus}
                    isShowTime={true}
                    required={false}
                    dateFormat={DATE_FORMAT_LIST[0]}
                    form={form}
                    isCheckDate={isCheckDate}
                    setIsCheckDate={setIsCheckDate}
                  />
                  <Popover
                    placement="topRight"
                    content={
                      <span style={{ color: ' #656464' }}>
                        終了日時を入力しない場合は、公開期間は無期限になります。
                      </span>
                    }
                  >
                    <Button
                      type="link"
                      style={{
                        display: 'flex',
                        paddingLeft: 8,
                        alignItems: 'center',
                      }}
                    >
                      <InfoCircleOutlined
                        style={{
                          color: '#A5A5A5',
                          fontSize: '20px',
                        }}
                      />
                    </Button>
                  </Popover>
                </Grid2>
                <Grid1>
                  <FormLabelGrid labelText={'表示日付'} required={false} />
                </Grid1>
                <Grid2>
                  <FormDatePicker
                    name="displayedDate"
                    width={'100%'}
                    dateFormat={DATE_FORMAT_LIST[1]}
                    placeHolder={'日時選択'}
                    message={'情報を入力してください。'}
                    required={false}
                  />
                </Grid2>
                <Grid1>
                  <FormLabelGrid required={true} labelText={'ファイル選択'} />
                </Grid1>
                <Grid2>
                  <FormFileUpload
                    name={'file'}
                    multipleUpload={false}
                    maxCount={1}
                    text={'アップロード可能なファイル形式とサイズ：'}
                    hint={
                      'pdf, pptx, ppt, xlsx, xls, xlsm, docx, doc - 50 MB; txt - 1MB; zip, bin, exe, nabin, efm - 1GB; iso - 4GB; csv - 10MB'
                    }
                    isPreview={false}
                    setIdFile={setIdFile}
                    form={form}
                    fileObject={fileObject}
                    setFileObject={setFileObject}
                    isDisabled={isDisabled}
                    setIsDisabled={setIsDisabled}
                  />
                </Grid2>
              </GridForm>
            </Form>
          </Container>
        </FormWrapper>
      }
    />
  );
};

export default ModalTitleFile;
