import { Upload } from 'antd';
import styled from 'styled-components';

export const CustomUpload = styled(Upload.Dragger)`
  .ant-upload-list-item-actions .ant-btn-icon-only {
    display: contents;
  }

  //edit font,size for アップロード at screen manage-project-registration/edit
  .ant-upload-text {
    color: #656464 !important;
    font-size: 14px !important;
    font-family: Roboto;
  }

  .ant-upload-list-item-name {
    text-decoration: underline;
    text-decoration-thickness: 0.5px !important;
    text-decoration-skip-ink: none;
    text-underline-offset: 3px;
    color: #656464;
    font-family: Roboto;
  }
`;
