import styled from 'styled-components';

export const FormWrapper = styled.div`
  //height: calc(100% - 59px);
  //overflow-y: auto;
  margin: 0 auto;

  &::-webkit-scrollbar {
    width: 6px;
  }

  /* Track */

  &::-webkit-scrollbar-track {
    background: transparent;
  }

  /* Handle */

  &::-webkit-scrollbar-thumb {
    background: #d9d9d9;
    border-radius: 2px;
  }

  /* Handle on hover */

  &::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
`;
export const Container = styled.div`
  width: fit-content;
  margin: 30px auto 8px;
`;

export const FooterButtonContainer = styled.div`
  padding-top: 30px;
`;
