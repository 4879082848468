import React, { useMemo } from 'react';
import { message } from 'antd';
import { getTopPage } from 'api/product/topPage.api';
import Breadcrumb from 'components/Layout/ApplicationLayout/Breadcrumb';
import useCustomQuery from 'hook/useQuery/useCustomQuery';
import ButtonBreadcrumb from 'page/Application/ManageProductAndService/TopPageProduct/TopPage/component/ButtonBreadCrumb/index';
import { BreadCrumbDetail } from 'page/Application/ManageProductAndService/TopPageProduct/TopPageProductDetail/styled';
import { Link, useNavigate } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import { userInformation } from 'recoil/atom/authentication';
import CategoryTopPageView from './component/CategoryTopPageView';
import HeaderTopPage from './component/HeaderTopPage';
import LayoutTopPage from './component/LayoutTopPage';
import LayoutTopPageBottom from './component/LayoutTopPageBottom';
import TableNotifyTopPage from './component/TableNotifyTopPage';
import { network } from 'recoil/atom/network';

const TopPagePartnerUser = () => {
  const isOnline = useRecoilValue(network);
  const [messageErrorNetWork, contextErrorNetWork] = message.useMessage();
  const { useQueryWithoutParams } = useCustomQuery();
  const navigate = useNavigate();
  const { role } = useRecoilValue(userInformation);

  const listItemTopPageSA = useMemo(() => {
    return [
      {
        title: <Link to={'/manage-company-list'}>企業管理</Link>,
      },
      {
        title: <BreadCrumbDetail>製品一覧</BreadCrumbDetail>,
      },
    ];
  }, []);

  const listItemTopPageSU = useMemo(() => {
    return [
      {
        title: <Link to={'/my-page'}>マイページ</Link>,
      },
      {
        title: <BreadCrumbDetail>製品一覧</BreadCrumbDetail>,
      },
    ];
  }, []);

  const listItemTopPagePartner = useMemo(() => {
    return [
      {
        title: <Link to={'/partner-page'}>パートナー専用ページ</Link>,
      },
      {
        title: <BreadCrumbDetail>製品一覧</BreadCrumbDetail>,
      },
    ];
  }, []);

  const listItemByRole = useMemo(() => {
    return {
      ROLE_SOLITON_ADMIN: listItemTopPageSA,
      ROLE_SOLITON_USER: listItemTopPageSU,
      ROLE_PARTNER_ADMIN: listItemTopPagePartner,
      ROLE_PARTNER_USER: listItemTopPagePartner,
    };
  }, [listItemTopPagePartner, listItemTopPageSA, listItemTopPageSU]);

  const { data: listTopPage, isLoading } = useQueryWithoutParams({
    api: getTopPage,
    key: 'topPageList',
  });
  const errorNetWork = () => {
    messageErrorNetWork
      .open({
        type: 'error',
        content:
          '問題が発生しました。インターネット接続を確認して、もう一度やり直してください。',
      })
      .then();
  };

  const handleOpenEditMode = () => {
    if (isOnline) {
      navigate(`/top-page/edit`);
    } else {
      errorNetWork();
    }
  };

  return (
    <>
      {contextErrorNetWork}
      <Breadcrumb listItem={listItemByRole[role]} titlePage="製品一覧">
        {role === 'ROLE_SOLITON_ADMIN' && (
          <ButtonBreadcrumb text={'編集'} onClick={handleOpenEditMode} />
        )}
      </Breadcrumb>
      <LayoutTopPage>
        <HeaderTopPage
          errorNetWork={errorNetWork}
          titleScreen={'お知らせ'}
          loading={isLoading}
          isDisplayNotifyList={true}
          isNotifyDetail={false}
        />
        <TableNotifyTopPage
          errorNetWork={errorNetWork}
          isLoading={isLoading}
          dataNotify={listTopPage?.data?.listNotify}
        />
      </LayoutTopPage>
      <LayoutTopPageBottom>
        {listTopPage?.data?.listCategory?.length > 0 && (
          <CategoryTopPageView
            errorNetWork={errorNetWork}
            dataCategory={listTopPage?.data?.listCategory}
            isLoading={isLoading}
          />
        )}
      </LayoutTopPageBottom>
    </>
  );
};

export default TopPagePartnerUser;
