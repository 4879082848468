import React from 'react';
import { LabelFrom } from 'page/Application/ManageNotificationList/component/Form/styled';

const FormLabelGrid = ({
  labelText,
  required,
  textAlign,
  title,
  marginBottom,
}) => {
  return (
    <LabelFrom
      style={{ textAlign: textAlign ?? 'right', marginBottom: marginBottom }}
    >
      <span title={title}>{labelText}</span>
      {required && <span className="mark">*</span>}
    </LabelFrom>
  );
};

export default FormLabelGrid;
