/*
 * id range from 300 - 399
 * */

import React from 'react';
import {
  AuditOutlined,
  LayoutOutlined,
  ProjectOutlined,
} from '@ant-design/icons';

export const solitonUserMenu = [
  {
    id: 300,
    label: 'マイページ', // my page
    path: '/my-page',
    icon: <LayoutOutlined />,
    isParent: true,
    isTopPage: false,
    parentId: 0,
    children: [],
  },

  {
    id: 302,
    label: '製品一覧', // top page
    path: '/top-page',
    icon: <AuditOutlined />,
    isParent: true,
    isTopPage: false,
    parentId: 0,
    children: [],
  },

  {
    id: 303,
    label: '案件登録お申し込み',
    path: '/project-registration',
    icon: <ProjectOutlined />,
    isParent: true,
    isTopPage: false,
    parentId: 0,
    children: [],
  },
];
