import { useEffect, useState } from 'react';
import { Grid2to4 } from '../FormAccount/styled';
import { Checkbox, Col, Row } from 'antd';
import { CheckboxStyled } from 'page/Application/ManagerAccountPartner/styled';

const TextBoxGroupJapanArea = ({
  isEdit,
  formDetailData,
  checkedListAll,
  setCheckedListAll,
}) => {
  // NATIONWIDE
  const [checkAllNationwide, setCheckAllNationwide] = useState(false);
  const [neutralListNationwide, setNeutralListNationwide] = useState(false);
  // HOKKAIDO
  const [checkAllHokkaido, setCheckAllHokkaido] = useState(false);
  const [checkedListHokkaido, setCheckedListHokkaido] = useState([]);
  const [neutralListHokkaido, setNeutralListHokkaido] = useState(false);
  const onloadOptionsHokkaido = [{ label: '北海道', value: 12 }];
  const onloadOptionsHokkaidoId = onloadOptionsHokkaido.map(
    (record) => record.value,
  );
  // TOKOHU
  const [checkAllTohoku, setCheckAllTohoku] = useState(false);
  const [checkedListTohoku, setCheckedListTohoku] = useState([]);
  const [neutralListTohoku, setNeutralListTohoku] = useState(false);
  const onloadOptionsTohoku = [
    { label: '青森', value: 14 },
    { label: '岩手', value: 15 },
    { label: '秋田', value: 16 },
    { label: '宮城', value: 17 },
    { label: '山形', value: 18 },
    { label: '福島', value: 19 },
  ];
  const onloadOptionsTohokuId = onloadOptionsTohoku.map(
    (record) => record.value,
  );
  // KANTO
  const [checkAllKanto, setCheckAllKanto] = useState(false);
  const [checkedListKanto, setCheckedListKanto] = useState([]);
  const [neutralListKanto, setNeutralListKanto] = useState(false);
  const onloadOptionsKanto = [
    { label: '茨城', value: 21 },
    { label: '栃木', value: 22 },
    { label: '群馬', value: 23 },
    { label: '埼玉', value: 24 },
    { label: '千葉', value: 25 },
    { label: '神奈川', value: 26 },
    { label: '東京', value: 27 },
  ];
  const onloadOptionsKantoId = onloadOptionsKanto.map((record) => record.value);
  // CHUBU
  const [checkAllChubu, setCheckAllChubu] = useState(false);
  const [checkedListChubu, setCheckedListChubu] = useState([]);
  const [neutralListChubu, setNeutralListChubu] = useState(false);
  const onloadOptionsChubu = [
    { label: '新潟', value: 29 },
    { label: '富山', value: 30 },
    { label: '石川', value: 31 },
    { label: '福井', value: 32 },
    { label: '山梨', value: 33 },
    { label: '長野', value: 34 },
    { label: '岐阜', value: 35 },
    { label: '静岡', value: 36 },
    { label: '愛知', value: 37 },
  ];
  const onloadOptionsChubuId = onloadOptionsChubu.map((record) => record.value);

  // KINKI
  const [checkAllKinki, setCheckAllKinki] = useState(false);
  const [checkedListKinki, setCheckedListKinki] = useState([]);
  const [neutralListKinki, setNeutralListKinki] = useState(false);
  const onloadOptionsKinki = [
    { label: '三重', value: 39 },
    { label: '滋賀', value: 40 },
    { label: '京都', value: 41 },
    { label: '大阪', value: 42 },
    { label: '兵庫', value: 43 },
    { label: '奈良', value: 44 },
    { label: '和歌山', value: 45 },
  ];
  const onloadOptionsKinkiId = onloadOptionsKinki.map((record) => record.value);

  // MIDDLE
  const [checkAllMiddle, setCheckAllMiddle] = useState(false);
  const [checkedListMiddle, setCheckedListMiddle] = useState([]);
  const [neutralListMiddle, setNeutralListMiddle] = useState(false);
  const onloadOptionsMiddle = [
    { label: '鳥取', value: 47 },
    { label: '島根', value: 48 },
    { label: '岡山', value: 49 },
    { label: '広島', value: 50 },
    { label: '山口', value: 51 },
  ];
  const onloadOptionsMiddleId = onloadOptionsMiddle.map(
    (record) => record.value,
  );

  // SHIKOKU
  const [checkAllShikoku, setCheckAllShikoku] = useState(false);
  const [checkedListShikoku, setCheckedListShikoku] = useState([]);
  const [neutralListShikoku, setNeutralListShikoku] = useState(false);
  const onloadOptionsShikoku = [
    { label: '徳島', value: 53 },
    { label: '香川', value: 54 },
    { label: '愛媛', value: 55 },
    { label: '高知', value: 56 },
  ];
  const onloadOptionsShikokuId = onloadOptionsShikoku.map(
    (record) => record.value,
  );

  // KYUSHU
  const [checkAllKyushu, setCheckAllKyushu] = useState(false);
  const [checkedListKyushu, setCheckedListKyushu] = useState([]);
  const [neutralListKyushu, setNeutralListKyushu] = useState(false);
  const onloadOptionsKyushu = [
    { label: '福岡', value: 58 },
    { label: '佐賀', value: 59 },
    { label: '長崎', value: 60 },
    { label: '熊本', value: 61 },
    { label: '大分', value: 62 },
    { label: '宮崎', value: 63 },
    { label: '鹿児島', value: 64 },
    { label: '沖縄', value: 65 },
  ];
  const onloadOptionsKyushuId = onloadOptionsKyushu.map(
    (record) => record.value,
  );

  useEffect(() => {
    if (isEdit && formDetailData?.data) {
      const listAreaId = formDetailData?.data?.listAreaInCharge;
      if (listAreaId) {
        const listAreaIdNum = listAreaId.map((record) => record.areaId);
        // list Hokkaido
        setCheckedListHokkaido(
          onloadOptionsHokkaidoId.filter((item) =>
            listAreaIdNum.includes(item),
          ),
        );
        setCheckedListTohoku(
          onloadOptionsTohokuId.filter((item) => listAreaIdNum.includes(item)),
        );
        // list Kanto
        setCheckedListKanto(
          onloadOptionsKantoId.filter((item) => listAreaIdNum.includes(item)),
        );
        setCheckedListChubu(
          onloadOptionsChubuId.filter((item) => listAreaIdNum.includes(item)),
        );
        setCheckedListKinki(
          onloadOptionsKinkiId.filter((item) => listAreaIdNum.includes(item)),
        );
        setCheckedListMiddle(
          onloadOptionsMiddleId.filter((item) => listAreaIdNum.includes(item)),
        );
        setCheckedListShikoku(
          onloadOptionsShikokuId.filter((item) => listAreaIdNum.includes(item)),
        );
        setCheckedListKyushu(
          onloadOptionsKyushuId.filter((item) => listAreaIdNum.includes(item)),
        );
      }
    }
  }, [isEdit, formDetailData?.data]);

  // >>> FUNCTION PLACE - START
  // >>> NATIONWIDE
  const onCheckNationwide = (e) => {
    setCheckAllNationwide(e?.target?.checked);
    onCheckTohoku(e);
    onCheckKanto(e);
    onCheckHokkaido(e);
    onCheckChubu(e);
    onCheckKinki(e);
    onCheckMiddle(e);
    onCheckShikoku(e);
    onCheckKyushu(e);
  };

  // >>> HOKKAIDO
  const onGroupChangeHokkaido = (checkedListHokkaido) => {
    setCheckedListHokkaido(checkedListHokkaido);
  };

  const onCheckHokkaido = (e) => {
    const values = onloadOptionsHokkaido.map((record) => record.value);
    setCheckAllHokkaido(e?.target?.checked);
    setCheckedListHokkaido(e.target.checked ? values : []);
    setNeutralListHokkaido(false);
  };

  // >>> TOHOKU
  const onGroupChangeTohoku = (checkedListTohoku) => {
    setCheckedListTohoku(checkedListTohoku);
  };

  const onCheckTohoku = (e) => {
    const values = onloadOptionsTohoku.map((record) => record.value);
    setCheckAllTohoku(e?.target?.checked);
    setCheckedListTohoku(e.target.checked ? values : []);
    setNeutralListTohoku(false);
  };

  // >>> KANTO
  const onGroupChangeKanto = (checkedListKanto) => {
    setCheckedListKanto(checkedListKanto);
  };

  const onCheckKanto = (e) => {
    const values = onloadOptionsKanto.map((record) => record.value);
    setCheckAllKanto(e?.target?.checked);
    setCheckedListKanto(e.target.checked ? values : []);
    setNeutralListKanto(false);
  };

  // >>> CHUBU
  const onGroupChangeChubu = (checkedListChubu) => {
    setCheckedListChubu(checkedListChubu);
  };

  const onCheckChubu = (e) => {
    const values = onloadOptionsChubu.map((record) => record.value);
    setCheckAllChubu(e?.target?.checked);
    setCheckedListChubu(e.target.checked ? values : []);
    setNeutralListChubu(false);
  };

  // >>> KINKI
  const onGroupChangeKinki = (checkedListKinki) => {
    setCheckedListKinki(checkedListKinki);
  };

  const onCheckKinki = (e) => {
    const values = onloadOptionsKinki.map((record) => record.value);
    setCheckAllKinki(e?.target?.checked);
    setCheckedListKinki(e.target.checked ? values : []);
    setNeutralListKinki(false);
  };

  // >>> MIDDLE
  const onGroupChangeMiddle = (checkedListMiddle) => {
    setCheckedListMiddle(checkedListMiddle);
  };

  const onCheckMiddle = (e) => {
    const values = onloadOptionsMiddle.map((record) => record.value);
    setCheckAllMiddle(e?.target?.checked);
    setCheckedListMiddle(e.target.checked ? values : []);
    setNeutralListMiddle(false);
  };

  // >>> SHIKOKU
  const onGroupChangeShikoku = (checkedListShikoku) => {
    setCheckedListShikoku(checkedListShikoku);
  };

  const onCheckShikoku = (e) => {
    const values = onloadOptionsShikoku.map((record) => record.value);
    setCheckAllShikoku(e?.target?.checked);
    setCheckedListShikoku(e.target.checked ? values : []);
    setNeutralListShikoku(false);
  };

  // >>> KYUSHU
  const onGroupChangeKyushu = (checkedListKyushu) => {
    setCheckedListKyushu(checkedListKyushu);
  };

  const onCheckKyushu = (e) => {
    const values = onloadOptionsKyushu.map((record) => record.value);
    setCheckAllKyushu(e?.target?.checked);
    setCheckedListKyushu(e.target.checked ? values : []);
    setNeutralListKyushu(false);
  };

  // >>> FUNCTION PLACE - END

  // SET CHECKALL LOGIC
  useEffect(() => {
    setCheckAllNationwide(
      checkAllKanto &&
        checkAllTohoku &&
        checkAllHokkaido &&
        checkAllChubu &&
        checkAllKinki &&
        checkAllMiddle &&
        checkAllShikoku &&
        checkAllKyushu,
    );
    setNeutralListNationwide(
      neutralListTohoku ||
        neutralListKanto ||
        neutralListHokkaido ||
        neutralListChubu ||
        neutralListKinki ||
        neutralListMiddle ||
        neutralListShikoku ||
        neutralListKyushu ||
        (!(
          checkAllKanto &&
          checkAllTohoku &&
          checkAllHokkaido &&
          checkAllChubu &&
          checkAllKinki &&
          checkAllMiddle &&
          checkAllShikoku &&
          checkAllKyushu
        ) &&
          (checkAllKanto ||
            checkAllTohoku ||
            checkAllHokkaido ||
            checkAllChubu ||
            checkAllKinki ||
            checkAllMiddle ||
            checkAllShikoku ||
            checkAllKyushu)),
    );
  }, [
    checkAllKanto,
    checkAllTohoku,
    checkAllHokkaido,
    checkAllChubu,
    checkAllKinki,
    checkAllMiddle,
    checkAllShikoku,
    checkAllKyushu,
    neutralListTohoku,
    neutralListKanto,
    neutralListHokkaido,
    neutralListChubu,
    neutralListKinki,
    neutralListMiddle,
    neutralListShikoku,
    neutralListKyushu,
    checkedListHokkaido,
    checkedListTohoku,
    checkedListKanto,
    checkedListChubu,
    checkedListKinki,
    checkedListMiddle,
    checkedListShikoku,
    checkedListKyushu,
  ]);

  // SET LIST CHECKED BEFORE SAVE OR UPDATE
  useEffect(() => {
    setCheckedListAll([
      ...checkedListHokkaido,
      ...checkedListTohoku,
      ...checkedListKanto,
      ...checkedListChubu,
      ...checkedListKinki,
      ...checkedListMiddle,
      ...checkedListShikoku,
      ...checkedListKyushu,
    ]);

    // Hokkaido
    setCheckAllHokkaido(
      checkedListHokkaido.length === onloadOptionsHokkaido.length,
    );
    setNeutralListHokkaido(
      checkedListHokkaido.length !== onloadOptionsHokkaido.length &&
        checkedListHokkaido.length > 0,
    );
    // Tohoku
    setCheckAllTohoku(checkedListTohoku.length === onloadOptionsTohoku.length);
    setNeutralListTohoku(
      checkedListTohoku.length !== onloadOptionsTohoku.length &&
        checkedListTohoku.length > 0,
    );
    // Kanto
    setCheckAllKanto(checkedListKanto.length === onloadOptionsKanto.length);
    setNeutralListKanto(
      checkedListKanto.length !== onloadOptionsKanto.length &&
        checkedListKanto.length > 0,
    );
    // Chubu
    setCheckAllChubu(checkedListChubu.length === onloadOptionsChubu.length);
    setNeutralListChubu(
      checkedListChubu.length !== onloadOptionsChubu.length &&
        checkedListChubu.length > 0,
    );
    // Kinki
    setCheckAllKinki(checkedListKinki.length === onloadOptionsKinki.length);
    setNeutralListKinki(
      checkedListKinki.length !== onloadOptionsKinki.length &&
        checkedListKinki.length > 0,
    );
    // Middle
    setCheckAllMiddle(checkedListMiddle.length === onloadOptionsMiddle.length);
    setNeutralListMiddle(
      checkedListMiddle.length !== onloadOptionsMiddle.length &&
        checkedListMiddle.length > 0,
    );
    // Shikoku
    setCheckAllShikoku(
      checkedListShikoku.length === onloadOptionsShikoku.length,
    );
    setNeutralListShikoku(
      checkedListShikoku.length !== onloadOptionsShikoku.length &&
        checkedListShikoku.length > 0,
    );
    // Kyushu
    setCheckAllKyushu(checkedListKyushu.length === onloadOptionsKyushu.length);
    setNeutralListKyushu(
      checkedListKyushu.length !== onloadOptionsKyushu.length &&
        checkedListKyushu.length > 0,
    );
  }, [
    checkedListHokkaido,
    checkedListTohoku,
    checkedListKanto,
    checkedListChubu,
    checkedListKinki,
    checkedListMiddle,
    checkedListShikoku,
    checkedListKyushu,
  ]);

  return (
    <>
      <Grid2to4>
        <Row>
          <Col span={4}>
            <CheckboxStyled
              onChange={onCheckNationwide}
              checked={checkAllNationwide}
              indeterminate={neutralListNationwide}
            >
              全国
            </CheckboxStyled>
          </Col>
        </Row>
      </Grid2to4>
      <Grid2to4>
        <Row>
          <Col span={4}>
            <CheckboxStyled
              onChange={onCheckHokkaido}
              checked={checkAllHokkaido}
              indeterminate={neutralListHokkaido}
            >
              北海道
            </CheckboxStyled>
          </Col>
          <Col span={30}>
            <b>[ &nbsp;</b>
            <Checkbox.Group
              options={onloadOptionsHokkaido}
              onChange={onGroupChangeHokkaido}
              value={checkedListHokkaido}
            />
            <b>]</b>
          </Col>
        </Row>
      </Grid2to4>
      <Grid2to4>
        <Row>
          <Col span={4}>
            <CheckboxStyled
              onChange={onCheckTohoku}
              checked={checkAllTohoku}
              indeterminate={neutralListTohoku}
            >
              東北
            </CheckboxStyled>
          </Col>
          <Col span={30}>
            <b>[ &nbsp;</b>
            <Checkbox.Group
              options={onloadOptionsTohoku}
              onChange={onGroupChangeTohoku}
              value={checkedListTohoku}
            />
            <b>]</b>
          </Col>
        </Row>
      </Grid2to4>
      <Grid2to4>
        <Row>
          <Col span={4}>
            <CheckboxStyled
              onChange={onCheckKanto}
              checked={checkAllKanto}
              indeterminate={neutralListKanto}
            >
              関東
            </CheckboxStyled>
          </Col>
          <Col span={30}>
            <b>[ &nbsp;</b>
            <Checkbox.Group
              options={onloadOptionsKanto}
              onChange={onGroupChangeKanto}
              value={checkedListKanto}
            />
            <b>]</b>
          </Col>
        </Row>
      </Grid2to4>
      <Grid2to4>
        <Row>
          <Col span={4}>
            <CheckboxStyled
              onChange={onCheckChubu}
              checked={checkAllChubu}
              indeterminate={neutralListChubu}
            >
              中部
            </CheckboxStyled>
          </Col>
          <Col span={30}>
            <b>[ &nbsp;</b>
            <Checkbox.Group
              options={onloadOptionsChubu}
              onChange={onGroupChangeChubu}
              value={checkedListChubu}
            />
            <b>]</b>
          </Col>
        </Row>
      </Grid2to4>
      <Grid2to4>
        <Row>
          <Col span={4}>
            <CheckboxStyled
              onChange={onCheckKinki}
              checked={checkAllKinki}
              indeterminate={neutralListKinki}
            >
              近畿
            </CheckboxStyled>
          </Col>
          <Col span={30}>
            <b>[ &nbsp;</b>
            <Checkbox.Group
              options={onloadOptionsKinki}
              onChange={onGroupChangeKinki}
              value={checkedListKinki}
            />
            <b>]</b>
          </Col>
        </Row>
      </Grid2to4>
      <Grid2to4>
        <Row>
          <Col span={4}>
            <CheckboxStyled
              onChange={onCheckMiddle}
              checked={checkAllMiddle}
              indeterminate={neutralListMiddle}
            >
              中国
            </CheckboxStyled>
          </Col>
          <Col span={30}>
            <b>[ &nbsp;</b>
            <Checkbox.Group
              options={onloadOptionsMiddle}
              onChange={onGroupChangeMiddle}
              value={checkedListMiddle}
            />
            <b>]</b>
          </Col>
        </Row>
      </Grid2to4>
      <Grid2to4>
        <Row>
          <Col span={4}>
            <CheckboxStyled
              onChange={onCheckShikoku}
              checked={checkAllShikoku}
              indeterminate={neutralListShikoku}
            >
              四国
            </CheckboxStyled>
          </Col>
          <Col span={30}>
            <b>[ &nbsp;</b>
            <Checkbox.Group
              options={onloadOptionsShikoku}
              onChange={onGroupChangeShikoku}
              value={checkedListShikoku}
            />
            <b>]</b>
          </Col>
        </Row>
      </Grid2to4>
      <Grid2to4>
        <Row>
          <Col span={4}>
            <CheckboxStyled
              onChange={onCheckKyushu}
              checked={checkAllKyushu}
              indeterminate={neutralListKyushu}
            >
              九州
            </CheckboxStyled>
          </Col>
          <Col span={30}>
            <b>[ &nbsp;</b>
            <Checkbox.Group
              options={onloadOptionsKyushu}
              onChange={onGroupChangeKyushu}
              value={checkedListKyushu}
            />
            <b>]</b>
          </Col>
        </Row>
      </Grid2to4>
    </>
  );
};
export default TextBoxGroupJapanArea;
