import React from 'react';
import {
  getPreSignedUrlToUploadOrDownload,
  uploadFileToS3WithPreSignedUrl,
} from 'api/storage/storage.api';
import { useRecoilValue } from 'recoil';
import { network } from 'recoil/atom/network';
import useCustomMutate from 'hook/useQuery/useCustomMutate';
import useCustomQuery from 'hook/useQuery/useCustomQuery';
import {
  FormMessage,
  Grid1,
  Grid2,
  UploadImg,
} from 'page/Application/ManageProjectRegistration/component/styled';
import { InfoCircleOutlined } from '@ant-design/icons';
import { Form, Popover } from 'antd';
import useUploadFileMultiLanguage from 'hook/useUploadFileMultiLanguage';
import { ButtonStyle } from 'page/Application/ManageCompanyList/component/Form/styled';
import FormLabelGrid from 'page/Application/ManageProjectRegistration/component/FormLabelGrid';

const FormUploadImg = ({
  hideButtonUploadImg,
  setHideButtonUploadImg,
  form,
  setIdFile,
  imageObject,
  setImageObject,
  isDisable,
  setIsCancel,
  setIsDisable,
  showImg,
  setShowImg,
}) => {
  const { useQueryAPI } = useCustomQuery();
  const { useMutationAPI } = useCustomMutate();
  const { UPLOAD_FILE_SUCCESS, UPLOAD_FILE_FAILURE } =
    useUploadFileMultiLanguage();
  const status = useRecoilValue(network);

  const { refetch: refetchPreSignedUrl } = useQueryAPI({
    api: getPreSignedUrlToUploadOrDownload,
    params: {
      fileName: imageObject?.keyFileName,
      httpMethod: 'PUT',
    },
    options: {
      refetchOnWindowFocus: false,
    },
    enabled: !!imageObject?.keyFileName,
  });

  const { mutateAsync: mutateAsyncUpload } = useMutationAPI({
    api: uploadFileToS3WithPreSignedUrl,
    messageSuccess: UPLOAD_FILE_SUCCESS,
    messageError: UPLOAD_FILE_FAILURE,
  });

  const props = {
    beforeUpload: (file) => {
      const isPNG = file.type === 'image/png';
      const isJPG = file.type === 'image/jpeg';
      const imgSize = file?.size / 1024 / 1024 <= 1;

      if (isPNG || isJPG) {
        if (imgSize) {
          setHideButtonUploadImg(false);
          return true;
        } else {
          setHideButtonUploadImg(true);
          return false;
        }
      } else {
        setHideButtonUploadImg(true);
        return false;
      }
    },
    disabled: isDisable,
    onPreview: () => {},
    listType: 'picture',
    maxCount: 1,
    onRemove: () => setHideButtonUploadImg(true),
    showUploadList: showImg,
    progress: {
      strokeColor: {
        '0%': '#108ee9',
        '100%': '#87d068',
      },
      size: 'small',
      format: (percent) => percent && `${parseFloat(percent.toFixed(2))}%`,
    },
    customRequest: async ({ file, onError, onSuccess, onProgress }) => {
      setIsCancel(true);
      setIsDisable(true);

      const extension = file?.name?.substring(
        file?.name?.lastIndexOf('.'),
        file?.name?.length,
      );

      const modifiedFileName = file?.name?.replace(
        extension,
        extension?.toLowerCase(),
      );

      await setImageObject({
        fileName: modifiedFileName,
        keyFileName: file?.uid,
        size: file?.size,
        contentType: file?.type,
      });

      refetchPreSignedUrl().then((value) => {
        mutateAsyncUpload({
          url: value?.data,
          file: file,
          options: options(onProgress, file),
        })
          .then((value) => {
            onSuccess(value, file);
            setIsCancel(false);
            setIsDisable(false);
          })
          .catch((error) => {
            onError(error);
            setIsCancel(false);
            setIsDisable(false);
          });
      });
    },
  };

  const normFile = (e) => {
    if (Array.isArray(e)) {
      return e;
    }
    setIdFile(null);
    return e?.fileList;
  };

  const options = (onProgress, file) => ({
    onUploadProgress: (progress) => {
      const { loaded, total } = progress;
      onProgress(
        {
          percent: Math.round((loaded / total) * 100),
        },
        file,
      );
    },
    headers: {
      'Content-Type': file?.type,
    },
  });

  return (
    <>
      <Grid1>
        <FormLabelGrid labelText={'一覧表示用画像'} />
      </Grid1>
      <Grid2>
        <div
          style={{
            display: hideButtonUploadImg ? 'flex' : 'grid',
            marginBottom: 30,
            position: 'relative',
          }}
        >
          <Form.Item
            name="avatar"
            valuePropName={'fileList'}
            getValueFromEvent={normFile}
            rules={[
              {
                validator: (_, value = []) => {
                  // if (!status) {
                  //   // form.setFieldValue('file', []);
                  //   return Promise.reject();
                  // }
                  const file = value[0];
                  if (value.length === 0) {
                    setShowImg(false);
                    return Promise.resolve();
                  }
                  if (
                    !value ||
                    value.length === 0 ||
                    file?.type === 'image/png' ||
                    file?.type === 'image/jpeg'
                  ) {
                    if (!(file?.size / 1024 / 1024 <= 1) && value.length) {
                      form.setFieldValue('avatar', []);
                      setShowImg(false);
                      return Promise.reject(
                        <FormMessage>
                          容量は大きすぎます。画像を1MB以内でアップロードしてください。
                        </FormMessage>,
                      );
                    }
                    setShowImg(true);
                    return Promise.resolve();
                  }
                  setShowImg(false);
                  form.setFieldValue('avatar', []);
                  return Promise.reject(
                    <FormMessage>
                      形式がjpg、pngの画像をアップロードしてください。
                    </FormMessage>,
                  );
                },
              },
            ]}
          >
            <UploadImg {...props}>
              {(hideButtonUploadImg || !showImg) && (
                <ButtonStyle>
                  <span> {'追加'}</span>
                </ButtonStyle>
              )}
            </UploadImg>
          </Form.Item>
          {(hideButtonUploadImg || !showImg) && (
            <Popover
              placement="right"
              content={
                <span style={{ color: ' #656464' }}>
                  最大容量が1MB以内で形式がjpg、pngの画像のみアップロードしてください。
                </span>
              }
            >
              <InfoCircleOutlined
                style={{
                  position: 'absolute',
                  color: '#A5A5A5',
                  fontSize: '20px',
                  marginLeft: 96,
                  height: 40,
                }}
              />
            </Popover>
          )}
        </div>
      </Grid2>
    </>
  );
};
export default FormUploadImg;
