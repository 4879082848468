import styled from 'styled-components';
import { Checkbox } from 'antd';

export const CheckboxStyled = styled(Checkbox)`
  color: ${(props) => (props?.color ? props.color : 'var(--CHECKBOX-default)')};

  span {
    font-size: 14px;
    font-family: Roboto, serif;
    font-weight: 400;
  }
`;
