import React from 'react';
import LoadingSpin from 'components/LoadingSpin';
import { BtnPrimary } from 'components/Button/styled';

/*
size: Small, middle, Large,
text: text inside button,
onClick: function handle,
isLoading: show spin when API pending,
*/
const ButtonPrimary = ({
  text,
  onClick,
  isLoading,
  size,
  width,
  disabled,
  marginRight,
}) => {
  return (
    <BtnPrimary
      type="primary"
      size={size ?? 'large'}
      htmlType="submit"
      onClick={onClick}
      disabled={isLoading || disabled}
      style={{
        width: width,
        marginRight: marginRight,
      }}
    >
      <span> {isLoading ? <LoadingSpin /> : text}</span>
    </BtnPrimary>
  );
};

export default ButtonPrimary;
