import axiosClient from 'api/config/axiosClient';

const getContentList = (props) => {
  const url =
    `v1/detail?pageNumber=${props.pageNumber}&pageSize=${props.pageSize}&keyword=${props.keyword}` +
    `&sortField=${props.sortField}&sortDirection=${props.sortDirection}&productId=${props.productId}&menuId=${props.menuId}` +
    `&displayType=${props.displayType}&status=${props.status}&displayedDateFrom=${props.displayedDateFrom}&displayedDateTo=${props.displayedDateTo}`;
  return axiosClient.get(url);
};

const getDetailTag = (props) => {
  const url = 'v1/detail/tags';
  return axiosClient.get(url);
};

const registerDetail = (payload) => {
  const url = 'v1/detail';
  return axiosClient.post(url, payload);
};

const getDetailContent = ({ detailId }) => {
  const url = `v1/detail/${detailId}`;
  return axiosClient.get(url);
};

const updateDetail = ({ detailId, payload }) => {
  const url = `v1/detail/${detailId}`;
  return axiosClient.put(url, payload);
};

const deleteDetail = (contentId) => {
  const url = `v1/detail/${contentId}`;
  return axiosClient.delete(url);
};

const updateDisplayStatus = (payload) => {
  const url = `v1/detail`;
  return axiosClient.put(url, payload);
};

const getListDetailWithoutPaginate = () => {
  const url = `v1/detail/all-without-paginate`;
  return axiosClient.get(url);
};

const countDetailFile = (props) => {
  const url =
    `v1/detail/count-detail-file?keyword=${props.keyword}` +
    `&productId=${props.productId}&menuId=${props.menuId}` +
    `&displayType=${props.displayType}&status=${props.status}`+
    `&displayedDateFrom=${props.displayedDateFrom}`+
    `&listDetailId=${props.listDetailId}`+
    `&displayedDateTo=${props.displayedDateTo}`;
  return axiosClient.get(url);
};

export {
  getContentList,
  getDetailTag,
  registerDetail,
  updateDetail,
  deleteDetail,
  getDetailContent,
  updateDisplayStatus,
  getListDetailWithoutPaginate,
  countDetailFile,
};
