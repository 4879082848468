import React, { useState } from 'react';
import useMultiLanguageRegisterCompany from 'page/Application/ManageCompanyList/RegisterCompany/hook/useMultiLanguageRegisterCompany';
import FormLabelGrid from 'page/Application/ManageCompanyList/component/Form/FormLabelGrid';
import ModalListProduct from 'page/Application/ManageCompanyList/component/Form/FormAddProduct/ModalListProduct';
import { useRecoilState } from 'recoil';
import { FormAddPermissionWrapper } from 'page/Application/ManageCompanyList/component/Form/FormAddProduct/styled';
import { idProduct, listProduct, product } from 'recoil/atom/selectProduct';
import { listProductType } from 'page/Application/ManageCompanyList/component/ListProduct';
import { Tooltip } from 'antd';
import {
  ButtonStyle,
  Grid1,
  Grid2,
} from 'page/Application/ManageCompanyList/component/Form/styled';

const FormAddProduct = () => {
  const { REGISTER_COMPANY_EDIT, REGISTER_COMPANY_PERMISSION_SETTINGS } =
    useMultiLanguageRegisterCompany();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [listSelectedProduct, setListSelectedProduct] =
    useRecoilState(listProduct);

  const [productSelected,] = useRecoilState(product);
  const [idProductSelected,] = useRecoilState(idProduct);

  const handleOpenModal = () => {
    setIsModalOpen(true);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };
  // compare updated time product
  const compareRecordUpdatedTime = (a, b) => {
    if (a < b) return -1;
    if (a > b) return 1;
    return 0;
  };

  const handleAddProduct = () => {
    setIsModalOpen(false);
    let newListProduct = [...productSelected];
    newListProduct.sort((a, b) => {
      const recordUpdatedTimeA = new Date(Date.parse(a.updatedTime));
      const recordUpdatedTimeB = new Date(Date.parse(b.updatedTime));
      return compareRecordUpdatedTime(recordUpdatedTimeB, recordUpdatedTimeA);
    });

    setListSelectedProduct({
      listIdProduct: idProductSelected,
      listRecordProduct: newListProduct,
      display: true,
    });
  };

  return (
    <>
      {
        <ModalListProduct
          isModalOpen={isModalOpen}
          handleCancel={handleCancel}
          handleOk={handleAddProduct}
        />
      }
      <Grid1>
        <FormLabelGrid
          labelText={REGISTER_COMPANY_PERMISSION_SETTINGS}
          required={false}
        />
      </Grid1>
      {listSelectedProduct?.listRecordProduct?.[0] &&
        listSelectedProduct?.listRecordProduct?.length > 0 && (
          <Grid2>
            <div
              style={{
                marginBottom: 20,
                paddingTop: 8,
                wordWrap: 'break-word',
              }}
            >
              {listSelectedProduct?.listRecordProduct
                ?.filter((item) => item)
                ?.map((item) => {
                  return (
                    <div
                      style={{
                        whiteSpace: 'nowrap',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        fontFamily: 'inter-regular, sans-serif',
                      }}
                      key={item?.key}
                    >
                      <span style={{ marginLeft: -8 }}>
                        {item?.isDisplayed === true ? '【表示】' : '【非表示】'}
                      </span>
                      <span style={{ marginRight: 4 }}>
                        【{listProductType[item?.type]}】
                      </span>
                      <Tooltip title={item?.productName} placement={'topLeft'}>
                        {item?.productName}
                      </Tooltip>
                    </div>
                  );
                })}
            </div>
          </Grid2>
        )}
      <Grid2>
        <FormAddPermissionWrapper>
          <ButtonStyle onClick={handleOpenModal}>
            {/*must have a span tag so there are no spaces between letters*/}
            <span> {REGISTER_COMPANY_EDIT}</span>
          </ButtonStyle>
        </FormAddPermissionWrapper>
      </Grid2>
    </>
  );
};

export default FormAddProduct;
