import styled from 'styled-components';

export const FormTitleWrapper = styled.div`
  padding: 20px 0 24px 0;
  font-weight: 600;
  font-size: 16px;
  color: var(--FORM-LABEL-default);
  border-bottom: 1px solid #d2d3d4;
  font-family: 'inter-semibold', sans-serif;
`;
export const FormNoticeWrapper = styled.div`
  padding: 20px 0 24px 0;
  border-bottom: 1px solid #d2d3d4;
  font-family: 'inter-semibold', sans-serif;
`;
export const Notice = styled.span`
  font-weight: 700;
  font-size: 16px;
  color: var(--FORM-LABEL-default);
  font-family: 'inter-regular', sans-serif;
`;
export const FormTextWrapper = styled.span`
  font-weight: 400;
  font-size: 16px;
  color: var(--FORM-LABEL-default);
  font-family: 'inter-regular', sans-serif;
`;
