import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { getListCategorySelectBox } from 'api/product/category.api';
import {
  deleteProduct,
  getDetailProduct,
  updateProduct,
} from 'api/product/product.api';
import { queryClient } from 'api/config/queryClient';
import useCustomQuery from 'hook/useQuery/useCustomQuery';
import { defaultData } from 'components/Tree/treeData';
import { MANAGE_PRODUCT_PATH } from 'constant/appPath';
import { PRODUCT_STATUS_DATA } from 'constant/productConstants';
import { CHECK_SPECIAL_CHARACTER } from 'constant/regex';
import useCustomMutateRenderMessage from 'hook/useQuery/useCustomMutateRenderMessage';
import FormProduct from '../component/FormProduct';
import { useProduct } from '../component/ProductContext';
import useErrorCodeManageProduct from '../hook/useErrorCodeManageProduct';
import useMultiLanguageEditProduct from './hook/useMultiLanguageEditProduct';

const EditProduct = () => {
  const navigate = useNavigate();
  const { useQueryAPI, useQueryWithoutParams } = useCustomQuery();
  const [, setIdFile] = useState(null);
  const [content, setContent] = useState(null);
  const {
    APPLICATION_PRODUCT_EDIT_TITLE,
    APPLICATION_PRODUCT_MESSAGE_EDIT_SUCCESS,
    APPLICATION_PRODUCT_MESSAGE_DELETE_SUCCESS,
  } = useMultiLanguageEditProduct();

  const { productId } = useParams();
  const [isDisable, setIsDisable] = useState(false);
  const [treeData, setTreeData] = useState(defaultData);
  const { ERROR_CODE_MANAGE_PRODUCT } = useErrorCodeManageProduct();
  const { errorMessage, useMutationAPIHandleErrorCode } =
    useCustomMutateRenderMessage({
      errorCode: ERROR_CODE_MANAGE_PRODUCT,
    });

  const { currentProduct, setCurrentProduct } = useProduct();
  const [isDisplayed, setIsDisplayed] = useState(PRODUCT_STATUS_DATA[0].value);
  const [fileObject, setFileObject] = useState(null);
  const [plainText, setPlainText] = useState(null);

  const {
    data: productDetail = [],
    isLoading,
    error,
  } = useQueryAPI({
    api: getDetailProduct,
    key: 'productDetail',
    params: {
      productId: productId,
    },
    options: {
      refetchOnWindowFocus: false, // cancel fetch data when out focus
    },
  });

  const { data: listCategoryProduct = [] } = useQueryWithoutParams({
    api: getListCategorySelectBox,
    key: 'categoryList',
  });

  const {
    mutate: updateProductMutate,
    isLoading: isLoadingUpdate,
    isSuccess,
  } = useMutationAPIHandleErrorCode({
    api: updateProduct,
    refetchKey: 'productDetail',
    path: `/${MANAGE_PRODUCT_PATH}`,
    messageSuccess: APPLICATION_PRODUCT_MESSAGE_EDIT_SUCCESS,
  });

  const onFinish = (values) => {
    const avatar = values?.avatar;
    const productRequest = {
      productName: values?.productName?.trim(),
      productContent: content === '<p>&nbsp;</p>' ? '' : content,
      productSearchContent: plainText,
      isDisplayed: values.isDisplayed,
      categoryId: values?.categoryId?.value ?? values?.categoryId,
      menuRequests: treeData?.children,
      fileStorageRequest: fileObject && {
        fileName: fileObject?.fileName,
        keyFileName: fileObject?.keyFileName,
        contentType: fileObject?.contentType,
        size: fileObject?.size,
      },
      fileId: avatar?.[0]?.id,
    };
    let formData = new FormData();
    formData.append(
      'productRequest',
      new Blob([JSON.stringify(productRequest)], { type: 'application/json' }),
    );
    if (avatar && avatar[0]) {
      formData.append('avatar', avatar[0].originFileObj);
    }

    queryClient.invalidateQueries(['productDetail', 'topPageList']).then();

    updateProductMutate({
      productId: productId,
      payload: formData,
    });
  };

  const {
    mutate: deleteProductMutate,
    isError,
    isLoading: isLoadingDelete,
    isSuccess: isSuccessDelete,
  } = useMutationAPIHandleErrorCode({
    api: deleteProduct,
    refetchKey: 'listProduct',
    path: `/${MANAGE_PRODUCT_PATH}`,
    messageSuccess: `${APPLICATION_PRODUCT_MESSAGE_DELETE_SUCCESS}`,
  });

  const handleDeleteProduct = () => {
    deleteProductMutate({
      productId: productId,
    });
  };

  useEffect(() => {
    if (
      error?.response?.data?.message === '404_PRODUCT_NOT_FOUND' ||
      error?.response?.data?.message === '400_ARGUMENT_IS_NOT_VALID' ||
      CHECK_SPECIAL_CHARACTER.test(productId)
    ) {
      navigate('/product-not-found');
    }
    const product = productDetail?.data;
    setIsDisplayed(product?.isDisplayed ?? product?.isDisplayed);
    setContent(product?.productContent);
    setPlainText(product?.productSearchContent);
    const fileStorage = product?.fileStorageResponse;
    setIdFile(fileStorage?.id);
  }, [
    currentProduct,
    error?.response?.data?.message,
    navigate,
    productDetail?.data,
    productId,
  ]);

  useEffect(() => {
    if (isLoading === false && productDetail.data) {
      setTreeData({
        ...defaultData,
        children: productDetail.data?.productMenus,
      });
    }
  }, [isLoading, productDetail.data]);

  return (
    <FormProduct
      titleForm={APPLICATION_PRODUCT_EDIT_TITLE}
      formDetailData={productDetail.data}
      isEdit={true}
      isLoading={isLoading || isLoadingUpdate || isLoadingDelete}
      listCategoryProduct={listCategoryProduct?.data}
      onFinish={onFinish}
      isDisable={isDisable}
      setIsDisable={setIsDisable}
      handleDeleteProduct={handleDeleteProduct}
      errorMessage={errorMessage}
      isError={isError}
      isSuccess={isSuccess || isSuccessDelete}
      setIsDisplayed={setIsDisplayed}
      isDisplayed={isDisplayed}
      productId={productId}
      setIdFile={setIdFile}
      content={content}
      setContent={setContent}
      currentProduct={currentProduct}
      setCurrentProduct={setCurrentProduct}
      treeData={treeData}
      setTreeData={setTreeData}
      setFileObject={setFileObject}
      fileObject={fileObject}
      setPlainText={setPlainText}
    />
  );
};

export default EditProduct;
