const BASE_PATH = 'v1/account';
// authentication router
const SIGN_IN_PATH = '/';
const SIGN_UP_PATH = 'sign-up';
const SIGN_UP_CHOOSE_PARTNER_PATH = 'choose-partner';
const FORGOT_PASSWORD_PATH = 'forgot-password';
const RESET_PASSWORD_PATH = `${BASE_PATH}/:emailToken`;
const PRIVACY = 'privacy';
export {
  SIGN_IN_PATH,
  SIGN_UP_PATH,
  SIGN_UP_CHOOSE_PARTNER_PATH,
  FORGOT_PASSWORD_PATH,
  RESET_PASSWORD_PATH,
  PRIVACY,
};
