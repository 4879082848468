import { useMemo } from 'react';
import { useRecoilValue } from 'recoil';
import { authenticationToken } from 'recoil/atom/authentication';

const useCheckRouterValid = () => {
  const { accessToken } = useRecoilValue(authenticationToken);
  const checkRouterValid = useMemo(() => {
    if (accessToken.length > 0) {
      try {
        const base64Url = accessToken.split('.')[1];
        const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
        const jsonPayload = decodeURIComponent(
          window
            .atob(base64)
            .split('')
            .map(function (c) {
              return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
            })
            .join(''),
        );
        const { exp, iat, passwordExpiredTime, sub, userRole } = {
          ...JSON.parse(jsonPayload),
        };

        if (
          exp &&
          iat &&
          passwordExpiredTime &&
          sub !== '' &&
          userRole !== ''
        ) {
          return true;
        }
      } catch (error) {
        localStorage.removeItem('user_token');
        localStorage.removeItem('password_expire');
        window.location.replace(window.location.host);
        window.location.reload();
        return false;
      }
    }
  }, [accessToken]);

  return { checkRouterValid };
};

export default useCheckRouterValid;
