import React, { useMemo } from 'react';
import LoadingSpin from 'components/LoadingSpin';
import useMultiLanguage from 'hook/useMultiLanguage';
// import Link from 'components/Link';
import { Link } from 'react-router-dom';
import { SIGN_IN_PATH, SIGN_UP_PATH } from 'constant/authenticationPath';
import { ButtonSubmit } from 'page/Authentication/styled';
import { FormItemStyled, TextAlignCenter } from 'components/Form/styled';
import { useResetRecoilState } from 'recoil';
import { signUpUser } from 'recoil/atom/signUpUser';

const FormItemButtonSubmit = ({ loading, formName, size, acceptPrivacy }) => {
  const resetSignUpUserRecoil = useResetRecoilState(signUpUser);

  const {
    FORM_SIGN_IN_BUTTON_SUBMIT,
    FORM_SIGN_IN_MOVE_TO_SIGN_UP,
    FORM_FORGOT_PASSWORD_BUTTON_SUBMIT,
    FORM_FORGOT_PASSWORD_MOVE_TO_SIGN_IN,
    FORM_SIGN_UP_BUTTON_SUBMIT,
    FORM_SIGN_UP_NAVIGATE_TO_LOGIN,
    FORM_PASSWORD_BUTTON_SUBMIT,
  } = useMultiLanguage();

  const checkFormName = useMemo(() => {
    switch (formName) {
      case 'sign-in':
        return {
          buttonText: FORM_SIGN_IN_BUTTON_SUBMIT,
          linkText: FORM_SIGN_IN_MOVE_TO_SIGN_UP,
          path: `/${SIGN_UP_PATH}`,
        };
      case 'sign-up':
        return {
          buttonText: FORM_SIGN_UP_BUTTON_SUBMIT,
          linkText: FORM_SIGN_UP_NAVIGATE_TO_LOGIN,
          path: SIGN_IN_PATH,
        };
      case 'sign-up-choose-partner':
        return {
          buttonText: '登録',
          linkText: '戻る',
          path: `/${SIGN_UP_PATH}`,
        };
      case 'forgot-password':
        return {
          buttonText: FORM_FORGOT_PASSWORD_BUTTON_SUBMIT,
          linkText: FORM_FORGOT_PASSWORD_MOVE_TO_SIGN_IN,
          path: SIGN_IN_PATH,
        };
      case 'reset-password':
        return {
          buttonText: FORM_PASSWORD_BUTTON_SUBMIT,
          linkText: FORM_FORGOT_PASSWORD_MOVE_TO_SIGN_IN,
          path: SIGN_IN_PATH,
        };
      case 'password-expire':
        return {
          buttonText: FORM_PASSWORD_BUTTON_SUBMIT,
          linkText: FORM_FORGOT_PASSWORD_MOVE_TO_SIGN_IN,
          path: SIGN_IN_PATH,
        };

      default:
        return null;
    }
  }, [
    formName,
    FORM_SIGN_IN_BUTTON_SUBMIT,
    FORM_SIGN_IN_MOVE_TO_SIGN_UP,
    FORM_FORGOT_PASSWORD_BUTTON_SUBMIT,
    FORM_FORGOT_PASSWORD_MOVE_TO_SIGN_IN,
    FORM_SIGN_UP_BUTTON_SUBMIT,
    FORM_SIGN_UP_NAVIGATE_TO_LOGIN,
    FORM_PASSWORD_BUTTON_SUBMIT,
  ]);

  const handleNavigateBack = () => {
    if (formName === 'sign-up') {
      resetSignUpUserRecoil();
    }
  };

  return (
    <FormItemStyled>
      <ButtonSubmit
        className="button-responsive"
        type="primary"
        block
        htmlType="submit"
        disabled={acceptPrivacy === false ? !acceptPrivacy : loading}
      >
        {' '}
        {loading ? <LoadingSpin /> : checkFormName.buttonText}
      </ButtonSubmit>
      <TextAlignCenter>
        <Link
          to={checkFormName.path}
          style={{
            color:
              formName === 'sign-up-choose-partner' ? '#302A29' : '#9D1C49',
          }}
          onClick={handleNavigateBack}
        >
          {checkFormName.linkText}
        </Link>
      </TextAlignCenter>
    </FormItemStyled>
  );
};

export default FormItemButtonSubmit;
