import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { registerDetail } from 'api/product/content.api';
import { Form } from 'antd';
import { DATE_FORMAT_LIST, TIME_ZONE } from 'constant/notificationConstants';
import dayjs from 'dayjs';
import useModalConfirm from 'hook/useModalConfirm';
import useCustomMutateRenderMessage from 'hook/useQuery/useCustomMutateRenderMessage';
import useStatusContent from 'hook/useStatusContent';
import FormContent from 'page/Application/ManageProductAndService/Content/component/form/FormContent';
import useErrorCodeManageContent from 'page/Application/ManageProductAndService/Content/hook/useErrorCodeManageContent';
import useErrorNetworkMessage from 'hook/useErrorNetworkMessage';

const RegisterContent = () => {
  const { STATUS_FOR_FORM, STATUS_TYPE_FOR_FORM } = useStatusContent();
  const [form] = Form.useForm();
  const [isDisplayedStatus, setIsDisplayedStatus] = useState(
    STATUS_FOR_FORM[0].value,
  );
  const [explanation, setExplanation] = useState('');
  const [content, setContent] = useState('');
  const [isDisplayedType, setIsDisplayedType] = useState(
    STATUS_TYPE_FOR_FORM[0].value,
  );
  const [isShowHash, setIsShowHash] = useState(true);
  const { contextHolder, handleConfirmAction } = useModalConfirm();
  const [isCancel, setIsCancel] = useState(false);
  const navigate = useNavigate();
  const { ERROR_CODE_MANAGE_CONTENT } = useErrorCodeManageContent();
  const [dataSelect, setDataSelect] = useState([]);
  const { errorMessage, useMutationAPIHandleErrorCode } =
    useCustomMutateRenderMessage({
      errorCode: ERROR_CODE_MANAGE_CONTENT,
    });
  const [fileObject, setFileObject] = useState(null);
  const [contentPlainText, setContentPlainText] = useState(null);
  const [explanationPlainText, setExplanationPlainText] = useState(null);
  const { isOnline, errorNetWork, contextErrorNetWork } =
    useErrorNetworkMessage();
  const {
    mutate: registerContentMutate,
    isError,
    isLoading,
    isSuccess,
  } = useMutationAPIHandleErrorCode({
    api: registerDetail,
    refetchKey: 'contentManagement',
    messageSuccess: 'コンテンツを登録しました。',
    path: `/content`,
  });

  const handleCancel = () => {
    if (isOnline) {
      handleConfirmAction({
        confirmFn: navigate,
        params: `/content`,
        title: 'コンテンツ登録をキャンセルしてもよろしいでしょうか？',
        okText: 'はい',
        cancelText: 'キャンセル',
        setIsDisable: setIsCancel,
      });
      setIsCancel(true);
    } else {
      errorNetWork();
    }
  };

  const onFinish = (values) => {
    if (isOnline) {
      let menuIdList = [];
      if (values?.menuIdLst[0]?.productId) {
        menuIdList = values?.menuIdLst?.map(
          (values) => values?.menuId[values?.menuId?.length - 1],
        );
      }
      const dateFrom =
        values?.publishedFromToDate && values?.publishedFromToDate[0]
          ? new Date(values?.publishedFromToDate[0]).setSeconds(0)
          : null;
      const dateTo =
        values?.publishedFromToDate && values?.publishedFromToDate[1]
          ? new Date(values?.publishedFromToDate[1]).setSeconds(0)
          : null;
      const payload = {
        detailTitle: values?.detailTitle?.trim(),
        displayStatus: values?.detailDisplayStatus,
        displayDate: dayjs(values.detailDisplayDate).format(
          DATE_FORMAT_LIST[3],
        ),
        publishedFrom:
          values.detailDisplayStatus === 'HIDE'
            ? null
            : dayjs.tz(
                dateTo && dateFrom > dateTo ? dateTo : dateFrom,
                TIME_ZONE,
              ),
        publishedTo:
          values.detailDisplayStatus === 'HIDE'
            ? null
            : dateTo &&
              dayjs.tz(dateFrom > dateTo ? dateFrom : dateTo, TIME_ZONE),
        displayType: values?.detailDisplayType,
        menuIdLst: menuIdList,
        tagLst: dataSelect?.length ? [...dataSelect] : undefined,
        detailExplanation: explanation === '<p>&nbsp;</p>' ? '' : explanation,
        detailSearchExplanation: explanationPlainText,
        detailContent: content === '<p>&nbsp;</p>' ? '' : content,
        detailSearchContent: contentPlainText,
        detailUrl: values?.detailUrl?.trim(),
        detailMenuIdLst: values?.menuIdLst?.map((values) => values?.menuId),
        isNewTab: values?.isNewTab,
        showHash: values.showHash,
        fileStorageRequest: fileObject && {
          fileName: fileObject?.fileName,
          keyFileName: fileObject?.keyFileName,
          contentType: fileObject?.contentType,
          size: fileObject?.size,
        },
      };

      registerContentMutate(payload);
    } else {
      errorNetWork();
    }
  };

  return (
    <>
      {contextHolder}
      {contextErrorNetWork}
      <FormContent
        setIsDisplayedStatus={setIsDisplayedStatus}
        isDisplayedStatus={isDisplayedStatus}
        isDisplayedType={isDisplayedType}
        setIsDisplayedType={setIsDisplayedType}
        dataSelect={dataSelect}
        setDataSelect={setDataSelect}
        isShowHash={isShowHash}
        setIsShowHash={setIsShowHash}
        onFinish={onFinish}
        form={form}
        explanation={explanation}
        setExplanation={setExplanation}
        handleCancel={handleCancel}
        isCancel={isCancel}
        content={content}
        setContent={setContent}
        isError={isError}
        isLoading={isLoading}
        isSuccess={isSuccess}
        errorMessage={errorMessage}
        fileObject={fileObject}
        setFileObject={setFileObject}
        setIsCancel={setIsCancel}
        setContentPlainText={setContentPlainText}
        setExplanationPlainText={setExplanationPlainText}
      />
    </>
  );
};

export default RegisterContent;
