import CategoryList from 'page/Application/ManageProductAndService/Category/CategoryList';
import EditCategory from 'page/Application/ManageProductAndService/Category/EditCategory';
import RegisterCategory from 'page/Application/ManageProductAndService/Category/RegisterCategory';
import React from 'react';
import { Outlet } from 'react-router-dom';

const Category = () => {
  return <Outlet />;
};

export default Category;

export { CategoryList, EditCategory, RegisterCategory };
