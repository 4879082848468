import React, { useEffect, useState } from 'react';
import { useMutation } from 'react-query';
import { useNavigate } from 'react-router-dom';
import { useRecoilState } from 'recoil';
import { registrationAccount, registrationAccountVerify } from 'api/authentication/authen.api';
import useCheckErrorCode from 'hook/useCheckErrorCode';
import useErrorCodeSignUp from 'page/Authentication/SignUp/hook/useErrorCodeSignUp';
import {
  FormItemButtonSubmit,
  FormItemValidateEmail,
  FormItemValidateStrongPassword,
  FormItemValidateText,
} from 'components/Form';
import useMultiLanguageSignUp from 'page/Authentication/SignUp/hook/useMultiLanguageSignUp';
import useRemoveBlankSpace from 'hook/useRemoveBlankSpace';
import { FormContainer, TitleAuthentication } from 'page/Authentication/styled';
import { Alert, Form } from 'antd';
import useSetTitlePage from 'hook/useSetTitlePage';
import { signUpUser } from 'recoil/atom/signUpUser';
import FormItemValidatePhoneNumber from './component/FormItemValidatePhoneNumber';
import CheckboxCustom from 'components/Checkbox';
import Link from 'components/Link';
import { SIGN_IN_PATH } from '../../../constant/authenticationPath';
import { messageFeedback } from '../../../recoil/atom/authentication';

const SignUp = () => {
  const [loading, setLoading] = useState(false);
  const [acceptPrivacy, setAcceptPrivacy] = useState(false);
  const { SIGN_UP_TITLE } = useMultiLanguageSignUp();
  const navigate = useNavigate();
  useSetTitlePage('Solitonパートナーサイト│新規アカウント登録');
  const [formRegistration] = Form.useForm();
  const [signUpUserRecoil, setSignUpUserRecoil] = useRecoilState(signUpUser);
  const { handleTrimInput } = useRemoveBlankSpace();
  const { ERROR_CODE_SIGN_UP } = useErrorCodeSignUp();
  const { errorMessage, handleClearErrorMessage, handleRenderMessage } =
    useCheckErrorCode({ errorCode: ERROR_CODE_SIGN_UP });
  const [, setShowMessageFeedback] = useRecoilState(messageFeedback);

  const { mutate, isError } = useMutation({
    mutationFn: registrationAccountVerify,
    onSuccess: (data, variables) => {
      handleClearErrorMessage();
      setLoading(false);
      if (!data.data) {
        navigate('/sign-up/choose-partner');
      } else {
        const payload = {
          email: variables?.email,
          firstName: variables?.firstName,
          lastName: variables?.lastName,
          password: variables?.password,
          phoneNumber: variables?.phoneNumber,
          partnerId: data?.data,
        };
        setLoading(true);
        mutateRegistration(payload);
      }
    },

    onError: (response) => {
      setLoading(false);
      handleRenderMessage(response);
    },
  });

  const { mutate: mutateRegistration, isError: isErrorUpdate } = useMutation({
    mutationFn: registrationAccount,
    onSuccess: () => {
      handleClearErrorMessage();
      setLoading(false);

      // show message feed back
      setShowMessageFeedback({
        display: true,
        type: 'success',
        content:
          '登録が完了しました。管理者へアカウント承認の確認を連絡してください。',
      });
      navigate(SIGN_IN_PATH);
    },
  });
  const onChangePhoneNumber = (value) => {
    const valuePhoneNumber = value?.target?.value;
    formRegistration.setFieldValue('phoneNumber', valuePhoneNumber);
  };

  const onFinish = (value) => {
    const payload = {
      email: value?.email.trim(),
      firstName: value.firstName.trim(),
      lastName: value.lastName.trim(),
      password: value.password.trim(),
      phoneNumber: value.phoneNumber.trim().replaceAll('-', ''),
      isPrivacy: acceptPrivacy,
    };
    setLoading(true);
    setSignUpUserRecoil({ ...payload });

    mutate({
      ...payload,
    });
  };

  useEffect(() => {
    if (signUpUserRecoil) {
      formRegistration.setFieldsValue({
        ...signUpUserRecoil,
      });
      setAcceptPrivacy(signUpUserRecoil?.isPrivacy);
    }
  }, [formRegistration, signUpUserRecoil]);

  //maintain
  useEffect(()=>{
    const fetchData = async () => {
      try {
        const response = await fetch('./maintenancetimeconfig.json');
        const jsonData = await response.json();
        let isMaintenance = jsonData.isMaintenance;
        if(isMaintenance){
          window.location.replace(SIGN_IN_PATH)
        }
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };
    fetchData().then();
  },[])

  return (
    <FormContainer className='form-responsive' style={{ width: '380px' }}>
      <TitleAuthentication className='title-form-sign-up-responsive'>
        {SIGN_UP_TITLE}
      </TitleAuthentication>
      {(isError || isErrorUpdate) && errorMessage !== '' && (
        <Alert message={errorMessage} type='error' />
      )}
      <Form
        className='size'
        form={formRegistration}
        name='account_registration'
        initialValues={{
          remember: true,
        }}
        onFinish={onFinish}
        layout={'vertical'}
      >
        <FormItemValidateEmail
          className='email'
          name='email'
          onChange={(e) =>
            handleTrimInput(formRegistration, 'email', e.target.value)
          }
        />

        <FormItemValidateStrongPassword name='password' maxLength={20} />
        <div className='form-sign-up-row-responsive2'>
          <FormItemValidateText name='lastName' inputType='lastName' />
          <FormItemValidateText name='firstName' inputType='firstName' />
        </div>

        {/* <FormValidatePhoneNumber /> */}
        <FormItemValidatePhoneNumber
          onChange={onChangePhoneNumber}
          isEdit={true}
          maxLength={12}
          isPhone={true}
        ></FormItemValidatePhoneNumber>
        <Form.Item>
          <CheckboxCustom
            text={
              <Link
                to='/privacy'
                text='個人情報の取り扱いに同意します。'
                decoration='underline'
                target='_blank'
              ></Link>
            }
            value={acceptPrivacy}
            onChange={(e) => {
              setAcceptPrivacy(e.target.checked);
            }}
          />
        </Form.Item>
        <FormItemButtonSubmit
          size='12px'
          button='signup'
          className='note-signup'
          formName='sign-up'
          acceptPrivacy={acceptPrivacy}
          loading={loading}
        />
      </Form>
      {/*<Mask className="mask"></Mask>*/}
    </FormContainer>
  );
};

export default SignUp;
