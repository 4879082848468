import React from 'react';
import { Form, Radio } from 'antd';
import { RadioStyled } from 'page/Application/ManageProjectRegistration/component/FormRadio/styled';
import useStatusContent from 'hook/useStatusContent';
import {
  Grid1,
  Grid2,
} from 'page/Application/ManageProjectRegistration/component/styled';
import FormLabelGrid from 'page/Application/ManageProjectRegistration/component/FormLabelGrid';

const FormRadioValueHash = ({ name, onChange, required }) => {
  const { STATUS_VALUE_HASH_FOR_FORM } = useStatusContent();
  return (
    <>
      <Grid1>
        <FormLabelGrid labelText={'ハッシュ値表示'} required={false} />
      </Grid1>
      <Grid2>
        <Form.Item name={name} required={required}>
          <Radio.Group name="isShowHash" onChange={onChange}>
            <RadioStyled value={STATUS_VALUE_HASH_FOR_FORM[0].value}>
              {STATUS_VALUE_HASH_FOR_FORM[0].label}
            </RadioStyled>
            <RadioStyled value={STATUS_VALUE_HASH_FOR_FORM[1].value}>
              {STATUS_VALUE_HASH_FOR_FORM[1].label}
            </RadioStyled>
          </Radio.Group>
        </Form.Item>
      </Grid2>
    </>
  );
};
export default FormRadioValueHash;
