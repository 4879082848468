import React, { Suspense } from 'react';
import CategoryTopPage from '../CategoryTopPage';
import ProductListTopPage from '../ProductListTopPage';
import LayoutDefault from 'components/Layout/LayoutDefault';
import { CategoryContainer, CategoryListWrapper } from './styled';
import { Spin } from 'antd';

const CategoryTopPageView = ({ dataCategory, isLoading, errorNetWork }) => {
  return (
    <LayoutDefault>
      <Suspense fallback={isLoading}>
        <Spin spinning={isLoading}>
          <CategoryContainer>
            {dataCategory?.map((category) => (
              <CategoryListWrapper key={category?.categoryId}>
                <CategoryTopPage categoryName={category?.title} />
                <ProductListTopPage
                  listProduct={category?.listProduct}
                  errorNetWork={errorNetWork}
                />
              </CategoryListWrapper>
            ))}
          </CategoryContainer>
        </Spin>
      </Suspense>
    </LayoutDefault>
  );
};
export default CategoryTopPageView;
