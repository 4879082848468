import React, { useEffect, useMemo, useState } from 'react';
import {
  countDetailFile,
  getContentList,
  updateDisplayStatus,
} from 'api/product/content.api';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useRecoilState, useRecoilValue, useResetRecoilState } from 'recoil';
import { fileDetailsNew, listFileDetailsRecoil } from 'recoil/atom/fileRequest';
import { filterContent } from 'recoil/atom/filterContent';
import { queryClient } from 'api/config/queryClient';
import { network } from 'recoil/atom/network';
import { Form, message } from 'antd';
import { STATUS_FOR_MODAL } from 'constant/contentConstants';
import { DATE_FORMAT_LIST, TIME_ZONE } from 'constant/notificationConstants';
import dayjs from 'dayjs';
import useModalConfirm from 'hook/useModalConfirm';
import { ButtonBreadcrumb } from 'components/Button/index';
import Breadcrumb from 'components/Layout/ApplicationLayout/Breadcrumb/index';
import HeaderListDataLayout from 'components/Layout/HeaderListDataLayout/index';
import LayoutDefault from 'components/Layout/LayoutDefault/index';
import Link from 'components/Link/index';
import Pagination from 'components/Pagination/index';
import {
  defaultPageSizeOptions,
  defaultSelectPageSizeOption,
} from 'constant/Pagination/pageSizeOption';
import { SORT_DIRECTION } from 'constant/sortConstants';
import useCustomMutate from 'hook/useQuery/useCustomMutate';
import useCustomQuery from 'hook/useQuery/useCustomQuery';
import useSetTitlePage from 'hook/useSetTitlePage';
import useMultiLanguageCompanyList from 'page/Application/ManageCompanyList/CompanyList/hook/useMultiLanguageCompanyList';
import {
  HeaderButtons,
  ModalContentList,
  SelectAllTable,
} from 'page/Application/ManageProductAndService/Content/ContentList/component';
import TableContentList from 'page/Application/ManageProductAndService/Content/ContentList/component/TableContentList/index';

const initFilterData = {
  productId: '',
  displayType: '',
  displayedDateFrom: '',
  displayedDateTo: '',
  displayStatus: '',
  menuId: '',
};

const ContentList = () => {
  const { COMPANY_LIST_PLACEHOLDER_SEARCH_NODATA, COMPANY_LIST_NO_DATA } =
    useMultiLanguageCompanyList();

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [current, setCurrent] = useState(1);
  const [page, setPage] = useState(10);
  const [sortedInfo, setSortedInfo] = useState({});
  const [, setListItemDelete] = useState([]);
  const [selectAllData, setSelectAllData] = useState(false);
  const [, setSelectAllPage] = useState(false);
  const [listIdSelected, setListIdSelected] = useState([]);
  const [isFilter, setIsFilter] = useState(false);
  const [keyword, setKeyword] = useState('');
  const [searchValue, setSearchValue] = useState('');
  const [emptyText, setEmptyText] = useState(COMPANY_LIST_NO_DATA);
  const [filterData, setFilterData] = useState(initFilterData);
  const [resetFormStatus, setResetFormStatus] = useState(false);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [, setSelectedFileRecords] = useState([]);
  const [isCancel, setIsCancel] = useState(false);
  const [listDateDisabled, setListDateDisabled] = useState([]);

  const [form] = Form.useForm();
  const navigate = useNavigate();
  const { contextHolder, handleConfirmAction } = useModalConfirm();
  const [searchParams, setSearchParams] = useSearchParams();
  const menuId = searchParams.get('menuId');
  const productId = searchParams.get('productId');
  const [filteredContent] = useRecoilState(filterContent);
  const isOnline = useRecoilValue(network);
  const [messageErrorNetWork, contextErrorNetWork] = message.useMessage();
  const resetFilterContent = useResetRecoilState(filterContent);
  const resetFileDetailList = useResetRecoilState(fileDetailsNew);
  const resetListFileDetailList = useResetRecoilState(listFileDetailsRecoil);

  const { useQueryAPI } = useCustomQuery();
  const { useMutationAPI } = useCustomMutate();

  const listItem = useMemo(() => {
    return [
      {
        title: (
          <Link
            text={'企業管理'}
            to={'/manage-company-list'}
            fontSize={14}
          ></Link>
        ),
      },
      {
        title: 'コンテンツ管理',
      },
    ];
  }, []);

  const { data: contentList = [], isLoading } = useQueryAPI({
    api: getContentList,
    key: 'contentManagement',
    params: {
      pageNumber: current,
      pageSize: page,
      keyword: encodeURIComponent(keyword),
      sortField: sortedInfo.sortField ?? '',
      sortDirection: sortedInfo.sortDirection ?? '',
      productId: filterData?.productId ?? null,
      menuId: filterData?.menuId ?? null,
      displayType: filterData.displayType,
      status: filterData.displayStatus,
      displayedDateFrom: filterData.displayedDateFrom,
      displayedDateTo: filterData.displayedDateTo,
    },
    enabled: !!isOnline,
  });

  const { data: countDetailFileForUpdating = [] } = useQueryAPI({
    api: countDetailFile,
    params: {
      keyword: encodeURIComponent(keyword),
      productId: filterData.productId,
      menuId: filterData?.menuId,
      displayType: filterData.displayType,
      status: filterData.displayStatus,
      displayedDateFrom: filterData.displayedDateFrom,
      displayedDateTo: filterData.displayedDateTo,
      listDetailId: !selectAllData ? listIdSelected : ""
    },
    key: 'countDetailFile',
    enabled: !!isOnline,
  });

  const { mutate: updateContentDisplayStatus } = useMutationAPI({
    api: updateDisplayStatus,
    refetchKey: 'contentManagement',
    messageSuccess: 'ステータスを更新しました。',
  });
  const errorNetWork = () => {
    messageErrorNetWork
      .open({
        type: 'error',
        content:
          '問題が発生しました。インターネット接続を確認して、もう一度やり直してください。',
      })
      .then();
  };

  const handleMoveRegisterPage = () => {
    if (isOnline) {
      navigate('/content/register');
      resetFileDetailList();
      resetListFileDetailList();
    } else {
      errorNetWork();
    }
  };

  const handleChangePageSize = (pageSize) => {
    if (isOnline) {
      setCurrent(1);
      setPage(pageSize);
    } else {
      errorNetWork();
    }
  };

  const handlePagingChange = (pageNumber, pageSize) => {
    if (isOnline) {
      setCurrent(pageNumber);
      setPage(pageSize);
      queryClient.resetQueries(['contentManagement']).then();
    } else {
      errorNetWork();
    }
  };

  const handleChange = (pagination, filters, sorter) => {
    if (isOnline) {
      queryClient.resetQueries(['contentManagement']).then();
      setSortedInfo({
        sortField: sorter.order && sorter.field,
        sortDirection: sorter.order && SORT_DIRECTION[sorter.order],
        order: sorter.order,
        columnKey: sorter.columnKey,
      });
    } else {
      errorNetWork();
    }
  };

  const handleSelectRowToDefault = () => {
    if (isOnline) {
      setSelectAllData(false);
      setSelectAllPage(false);
      setSelectedRowKeys([]);
      setListIdSelected([]);
      setSelectedFileRecords([]);
    } else {
      errorNetWork();
    }
  };

  const resetPageToDefault = () => {
    if (isOnline) {
      setCurrent(1);
      setPage(10);
      setSortedInfo({});
    } else {
      errorNetWork();
    }
  };

  const handleResetData = () => {
    if (isOnline) {
      resetPageToDefault();
      setResetFormStatus(true);
      handleResetKeyword();
      handleSelectRowToDefault();
      resetFilterContent();
      resetFilterThroughParamURL();
      resetFilterDataToDefault();
    } else {
      errorNetWork();
    }
  };

  const resetFilterThroughParamURL = () => {
    if (isOnline) {
      if (searchParams.has('menuId')) {
        searchParams.delete('menuId');
      }
      if (searchParams.has('productId')) {
        searchParams.delete('productId');
      }
      setSearchParams(searchParams);
    } else {
      errorNetWork();
    }
  };

  const resetFilterDataToDefault = () => {
    if (isOnline) {
      setFilterData(initFilterData);
    } else {
      errorNetWork();
    }
  };

  const handleResetKeyword = () => {
    if (isOnline) {
      setSearchValue('');
      setKeyword('');
    } else {
      errorNetWork();
    }
  };

  const handleOpenModalDisplay = (status) => {
    if (isOnline) {
      setIsModalOpen(true);
      setIsFilter(false);
      setListDateDisabled(status ? [status, !status] : [status, status]);
      form.setFieldValue('detailDisplayStatus', status ? 'SHOW' : 'SCHEDULED');
    } else {
      errorNetWork();
    }
  };

  const handleMultipleSearch = () => {
    if (isOnline) {
      setIsModalOpen(true);
      form.setFieldsValue({
        ...filteredContent,
        displayedDate: [
          filteredContent?.displayedDateFrom
            ? dayjs(filteredContent?.displayedDateFrom)
            : null,
          filteredContent?.displayedDateTo
            ? dayjs(filteredContent?.displayedDateTo)
            : null,
        ],
      });
      setIsFilter(true);
      setEmptyText(COMPANY_LIST_PLACEHOLDER_SEARCH_NODATA);
    } else {
      errorNetWork();
    }
  };

  const handleSelectAllRecord = () => {
    if (isOnline) {
      if (!selectAllData) {
        setSelectAllData((current) => !current);
      } else {
        handleSelectRowToDefault();
      }
    }
  };

  const handleUpdateContentStatus = (status) => {
    if (isOnline) {
      form
        .validateFields()
        .then((values) => {
          setIsModalOpen(false);
          const dateFrom =
            values?.publishedFromToDate && values?.publishedFromToDate[0]
              ? new Date(values?.publishedFromToDate[0]).setSeconds(0)
              : null;
          const dateTo =
            values?.publishedFromToDate && values?.publishedFromToDate[1]
              ? new Date(values?.publishedFromToDate[1]).setSeconds(0)
              : null;
          const payload = {
            isUpdateAll: selectAllData,
            listDetailId: listIdSelected,
            detailDisplayStatus: STATUS_FOR_MODAL[status],
            detailDisplayDate: values.displayDate
              ? dayjs(values.displayDate).format(DATE_FORMAT_LIST[3])
              : null,
            detailPublishedFrom: dayjs.tz(
              dateTo && dateFrom > dateTo ? dateTo : dateFrom,
              TIME_ZONE,
            ),
            detailPublishedTo:
              dateTo &&
              dayjs.tz(dateFrom > dateTo ? dateFrom : dateTo, TIME_ZONE),
            keyword: keyword,
            productId: filterData.productId,
            menuId: filterData.menuId,
            displayType: filterData.displayType,
            status: filterData.displayStatus,
            displayedDateFrom: filterData.displayedDateFrom,
            displayedDateTo: filterData.displayedDateTo,
          };
          updateContentDisplayStatus(payload);
          form.resetFields(['publishedFromToDate', 'displayDate']);
        })
        .catch(() => {
          setIsModalOpen(true);
        });
    } else {
      errorNetWork();
    }
  };

  const handleConfirmUpdateStatus = (status) => {
    if (isOnline) {
      form
        .validateFields()
        .then(() => {
          handleConfirmAction({
            confirmFn: () => handleUpdateContentStatus(status),
            params: `/content`,
            title: `選択した${
              !selectAllData ? listIdSelected?.length : contentList?.data?.total
            }コンテンツのうち、${
              status === 'DL停止'
                ? countDetailFileForUpdating?.data
                : !selectAllData
                  ? listIdSelected?.length
                  : contentList?.data?.total
            }件のみ${status}に変更可能です。よろしいですか？`,
            okText: 'はい',
            cancelText: 'キャンセル',
            setIsDisable: setIsCancel,
          });
          setIsCancel(true);
        })
        .catch(() => {
          setIsModalOpen(true);
        });
    } else {
      errorNetWork();
    }
  };

  const onSearch = (value) => {
    if (isOnline) {
      const trimmedValue = value.trim();
      setKeyword(trimmedValue);
      if (value.length > 0) {
        setEmptyText(COMPANY_LIST_PLACEHOLDER_SEARCH_NODATA);
      } else {
        setEmptyText(COMPANY_LIST_NO_DATA);
      }
      resetPageToDefault();
      setResetFormStatus(true);
      resetFilterContent();
      resetFilterThroughParamURL();
      resetFilterDataToDefault();
      handleSelectRowToDefault();
    } else {
      errorNetWork();
    }
  };

  const onChangeRow = (rowKeys, records) => {
    if (isOnline) {
      setSelectedFileRecords(
        records?.filter((value) => value?.detailDisplayType === 'FILE'),
      );
      setSelectedRowKeys(rowKeys);
    }
  };

  useEffect(() => {
    if (menuId || productId) {
      setFilterData({
        ...initFilterData,
        menuId: menuId,
        productId: productId,
      });
    }
  }, [menuId, productId, listIdSelected]);

  useSetTitlePage('コンテンツ管理');

  return (
    <>
      {contextErrorNetWork}
      {contextHolder}
      <Breadcrumb listItem={listItem} titlePage={'コンテンツ管理'}>
        <ButtonBreadcrumb text={'新規登録'} onClick={handleMoveRegisterPage} />
      </Breadcrumb>
      <ModalContentList
        resetFilterThroughParamURL={resetFilterThroughParamURL}
        handleSelectRowToDefault={handleSelectRowToDefault}
        resetPageToDefault={resetPageToDefault}
        handleResetKeyword={handleResetKeyword}
        width={isFilter ? 700 : 800}
        isModalOpen={isModalOpen}
        setIsModalOpen={setIsModalOpen}
        isFilter={isFilter}
        setFilterData={setFilterData}
        updateContentDisplayStatus={updateContentDisplayStatus}
        listIdSelected={listIdSelected}
        selectAllData={selectAllData}
        setResetFormStatus={setResetFormStatus}
        resetFormStatus={resetFormStatus}
        form={form}
        handleConfirmUpdateStatus={handleConfirmUpdateStatus}
        isCancel={isCancel}
        isOnline={isOnline}
        errorNetWork={errorNetWork}
        listDateDisabled={listDateDisabled}
      />
      <LayoutDefault>
        <HeaderListDataLayout
          titleScreen={'コンテンツ管理'}
          searchPlaceholder={'タイトルに従え、検索します。'}
          onSearch={onSearch}
          isLoading={isLoading}
          value={page}
          shortenTitle={false}
          handleChangePageSize={handleChangePageSize}
          pageSizeOptions={defaultSelectPageSizeOption}
          searchValue={searchValue}
          setSearchValue={setSearchValue}
        >
          <HeaderButtons
            handleConfirmUpdateStatus={handleConfirmUpdateStatus}
            listIdSelected={listIdSelected}
            handleOpenModalDisplay={handleOpenModalDisplay}
            handleMultipleSearch={handleMultipleSearch}
            handleResetData={handleResetData}
            selectAllData={selectAllData}
            totalItems={contentList?.data?.total}
            isCancel={isCancel}
          />
        </HeaderListDataLayout>
        {listIdSelected.length !== 0 && (
          <SelectAllTable
            dataRaw={contentList.data}
            handleSelectAllRecord={handleSelectAllRecord}
            selectAllData={selectAllData}
            listIdSelected={listIdSelected}
          />
        )}
        <TableContentList
          dataRaw={contentList.data}
          isLoading={isLoading}
          sortedInfo={sortedInfo}
          onChange={handleChange}
          setListItemDelete={setListItemDelete}
          selectAllData={selectAllData}
          setSelectAllData={setSelectAllData}
          setSelectAllPage={setSelectAllPage}
          setListIdSelected={setListIdSelected}
          listIdSelected={listIdSelected}
          emptyText={emptyText}
          onChangeRow={onChangeRow}
          selectedRowKeys={selectedRowKeys}
          isOnline={isOnline}
          errorNetWork={errorNetWork}
        />
        <Pagination
          currentPage={current}
          handlePagingChange={handlePagingChange}
          total={contentList?.data?.total}
          pageSize={page}
          totalTitle={'コンテンツ総数'}
          pageSizeOptions={defaultPageSizeOptions}
          handleChangePageSize={handleChangePageSize}
        />
      </LayoutDefault>
    </>
  );
};

export default ContentList;
