import { Form, Radio } from 'antd';
import React from 'react';
import { RadioStyled } from 'page/Application/ManageProjectRegistration/component/FormRadio/styled';
import useStatusContent from 'hook/useStatusContent';
import {
  Grid1,
  Grid2,
} from 'page/Application/ManageProjectRegistration/component/styled';
import FormLabelGrid from 'page/Application/ManageProjectRegistration/component/FormLabelGrid';

const FormRadioContent = ({ name, onChange, required }) => {
  const { STATUS_FOR_FORM } = useStatusContent();
  return (
    <>
      <Grid1>
        <FormLabelGrid labelText={'ステータス'} required={true} />
      </Grid1>
      <Grid2>
        <Form.Item name={name} required={required}>
          <Radio.Group name={name} onChange={onChange}>
            <RadioStyled value={STATUS_FOR_FORM[0].value}>
              {STATUS_FOR_FORM[0].label}
            </RadioStyled>
            <RadioStyled value={STATUS_FOR_FORM[1].value}>
              {STATUS_FOR_FORM[1].label}
            </RadioStyled>
            <RadioStyled value={STATUS_FOR_FORM[2].value}>
              {STATUS_FOR_FORM[2].label}
            </RadioStyled>
            <RadioStyled value={STATUS_FOR_FORM[3].value}>
              {STATUS_FOR_FORM[3].label}
            </RadioStyled>
          </Radio.Group>
        </Form.Item>
      </Grid2>
    </>
  );
};
export default FormRadioContent;
