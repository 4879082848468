import AntdTable from 'components/AntdTable';
import { DATE_FORMAT_LIST } from 'constant/notificationConstants';
import dayjs from 'dayjs';
import React, { useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { ButtonAction } from 'style/styleComponent/styled';
import { Pinned } from '../../styled';
import LinkNotify from 'page/Application/ManageProductAndService/TopPageProductPartnerUser/component/LinkNotify';
import { handleNavigate } from 'page/Application/ManageProductAndService/utils/navigate/navigateNotifyTopPage';
import { useRecoilValue } from 'recoil';
import { network } from 'recoil/atom/network';
const OtherNotifyTable = ({
  isLoading,
  dataSource,
  setDataSource,
  emptyText,
  isDisabledPin,
  refetch,
  setNotifyId,
  productId,
  errorNetWork,
}) => {
  const navigate = useNavigate();
  const isOnline = useRecoilValue(network);
  const columns = useMemo(() => {
    const handlePin = (notifyId) => {
      setDataSource((prevState) => {
        let lstNewSource = prevState.map((data, index) => {
          if (data.isPinned == true) {
            if (data.notifyOrderId == 1) {
              data.notifyOrderId = 2;
            } else if (data.notifyOrderId == 2) {
              data.isPinned = false;
            }
          }
          if (data.notifyId === notifyId) {
            data.isPinned = true;
            data.notifyOrderId = 1;
          }
          return data;
        });
        return lstNewSource;
      });
    };
    return [
      {
        title: 'space',
        key: 'space',
        width: 50,
        responsive: ['sm'],
      },
      {
        title: 'お知らせ',
        render: (record) => {
          return (
            <>
              <p style={{ wordBreak: 'break-word' }}>
                {record.titleWithCategory}
              </p>
              <p>{dayjs(record.displayDate).format(DATE_FORMAT_LIST[1])}</p>
            </>
          );
        },
        responsive: ['xs'],
      },
      {
        key: 'displayedDate',
        dataIndex: 'displayedDate',
        ellipsis: true,
        align: 'left',
        width: '150px',
        responsive: ['sm'],
        render: (text) => {
          return (
            <div title={''}>{dayjs(text).format(DATE_FORMAT_LIST[1])}</div>
          );
        },
        showSorterTooltip: false,
        sorter: true,
      },
      {
        key: 'titleWithCategory',
        title: 'お知らせ',
        dataIndex: 'titleWithCategory',
        align: 'left',
        showSorterTooltip: false,
        sorter: true,
        render: (text, record) => {
          return (
            <div>
              {record?.destinationType === 'NO_DETAILS' ? (
                <p style={{ wordBreak: 'break-word' }}>
                  {record.titleWithCategory}
                </p>
              ) : (
                <LinkNotify
                  text={record.titleWithCategory}
                  type={record?.destinationType}
                  handleNavigate={() => {
                    if (isOnline) {
                      handleNavigate(
                        record,
                        navigate,
                        setNotifyId,
                        refetch,
                        productId,
                      ).then();
                    } else {
                      errorNetWork();
                    }
                  }}
                />
              )}
            </div>
          );
        },
        responsive: ['sm'],
      },
      {
        key: 'notifyId',
        dataIndex: 'notifyId',
        render: (notifyId) => (
          <ButtonAction
            type="link"
            onClick={() => handlePin(notifyId)}
            disabled={isDisabledPin}
            icon={<Pinned />}
          />
        ),
        align: 'center',
        width: 50,
      },
    ];
  }, [
    setDataSource,
    navigate,
    setNotifyId,
    refetch,
    productId,
    isDisabledPin,
    isOnline,
  ]);

  const dataSourceOther = useMemo(() => {
    if (dataSource && dataSource != [] && dataSource != undefined) {
      let count = 0;
      return dataSource
        .sort((o1, o2) =>
          o1.displayedDate < o2.displayedDate
            ? 1
            : o1.displayedDate > o2.displayedDate
              ? -1
              : 0,
        )
        .filter((item, index) => {
          if (!item.isPinned) {
            count++;
          }
          if (count >= 6) {
            return !item.isPinned;
          }
        });
    }
  }, [dataSource]);

  return (
    <>
      <AntdTable
        columns={columns}
        isLoading={isLoading}
        dataSource={dataSourceOther}
        emptyText={emptyText}
        showHeader={false}
        isBoldBottomBorder={true}
      />
    </>
  );
};

export default OtherNotifyTable;
