import styled from 'styled-components';

export const Grid1 = styled.div`
  padding: 0 12px;
  grid-column: 1 / 2;
`;

export const Grid2 = styled.div`
  padding: 0 12px;
  grid-column: 2 / 3;
`;

export const LabelFrom = styled.div`
  margin-bottom: 4px;
  .mark {
    margin-left: 6px;
    color: #9d1c49;
  }
  span {
    font-family: 'inter-regular', sans-serif;
    font-weight: 500;
    color: #656464;
  }
`;

export const ButtonGroup = styled.div`
  display: flex;
  width: 800px;
  justify-content: center;
`;
