import React, { useEffect, useMemo, useState } from 'react';
import { getListAccountSoliton } from 'api/account/solitonAccount.api';
import { deleteMultipleUser } from 'api/account/account.api';
import { ButtonBreadcrumb } from 'components/Button';
import { useRecoilValue } from 'recoil';
import TableData from 'page/Application/ManageAccountSoliton/AccountSolitonList/component/TableData';
import Pagination from 'components/Pagination';
import useModalConfirm from 'hook/useModalConfirm';
import useCustomMutate from 'hook/useQuery/useCustomMutate';
import useCustomQuery from 'hook/useQuery/useCustomQuery';
import HeaderListDataLayout from 'components/Layout/HeaderListDataLayout';
import useMultiLanguageAccountSolitonList from 'page/Application/ManageAccountSoliton/AccountSolitonList/hook/useMultiLanguageManageAccountSolitonList';
import useSearchKeyword from 'hook/useSearchKeyword';
import LayoutDefault from 'components/Layout/LayoutDefault';
import Breadcrumb from 'components/Layout/ApplicationLayout/Breadcrumb';
import useMultiLanguageRegisterAccount from 'page/Application/ManageAccountSoliton/RegisterAccountSoliton/hook/useMultiLanguageRegisterAccount';
import SelectAllTable from './component/SelectAllTable';
import { Link, useNavigate } from 'react-router-dom';
import { userInformation } from 'recoil/atom/authentication';
import { ButtonStyled } from 'page/Application/ManageCompanyList/CompanyList/styled';
import {
  defaultSelectPageSizeOption,
  defaultPageSizeOptions,
} from 'constant/Pagination/pageSizeOption';
import { SORT_DIRECTION } from 'constant/sortConstants';
import { DeleteOutlined, PlusOutlined } from '@ant-design/icons';
import { IconWrapper } from 'page/Application/ManagerAccountPartner/AccountPartnerList/styled';
import useErrorNetworkMessage from 'hook/useErrorNetworkMessage';

const AccountSolitonList = () => {
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);

  const {
    ACCOUNT_LIST_TITLE,
    ACCOUNT_LIST_MULTI_DELETE,
    ACCOUNT_LIST_MULTI_DELETE_CONFIRM1,
    ACCOUNT_LIST_MULTI_DELETE_CONFIRM2,
    ACCOUNT_LIST_MULTI_DELETE_SUCCESS1,
    ACCOUNT_LIST_MULTI_DELETE_SUCCESS2,
    ACCOUNT_PLACEHOLDER_SEARCH,
    ACCOUNT_LIST_TABLE_PAGINATION_TOTAL,
  } = useMultiLanguageAccountSolitonList();

  const { DETAIL_ACCOUNT_DELETE_ACCOUNT_SUCCESS } =
    useMultiLanguageRegisterAccount();
  const navigate = useNavigate();

  const { role } = useRecoilValue(userInformation);
  const [, setListItemDelete] = useState([]);
  const [page, setPage] = useState(10);

  const [current, setCurrent] = useState(1);
  const { handleConfirmAction, contextHolder } = useModalConfirm();
  const [isDisable, setIsDisable] = useState(false);

  const { useMutationAPI } = useCustomMutate();
  const { useQueryAPI } = useCustomQuery();
  const [sortedInfo, setSortedInfo] = useState({});

  const [, setSelectAllPage] = useState(false);
  const [listIdSelected, setListIdSelected] = useState([]);
  const [selectAllData, setSelectAllData] = useState(false);

  const { searchKeyword, onSearch, emptyText } = useSearchKeyword();
  const [searchValue, setSearchValue] = useState('');
  const { isOnline, errorNetWork, contextErrorNetWork } =
    useErrorNetworkMessage();
  const listItemSA = useMemo(() => {
    return [
      {
        title: <Link to={'/'}>企業管理</Link>,
      },
      {
        title: 'ソリトンアカウント管理',
      },
    ];
  }, []);
  const listItemByRole = useMemo(() => {
    return {
      ROLE_SOLITON_ADMIN: listItemSA,
      ROLE_SOLITON_USER: listItemSA,
    };
  }, [listItemSA]);

  const { data: listAccountSoliton = [], isLoading } = useQueryAPI({
    api: getListAccountSoliton,
    key: 'accountListSoliton',
    params: {
      pageNumber: current,
      pageSize: page,
      keyword: encodeURIComponent(searchKeyword),
      isPartner: false, // value === false for get list user soliton
      displayedDateFrom: '',
      displayedDateTo: '',
      category: '',
      status: '',
      sortField: sortedInfo?.sortField ?? '',
      sortDirection: sortedInfo?.sortDirection ?? '',
    },
  });
  const { mutate: deleteSinglePartner } = useMutationAPI({
    api: deleteMultipleUser,
    refetchKey: 'accountListSoliton',
    messageSuccess: DETAIL_ACCOUNT_DELETE_ACCOUNT_SUCCESS,
  });

  const { mutate: mutateMultipleDeleteItem } = useMutationAPI({
    api: deleteMultipleUser,
    refetchKey: 'accountListSoliton',
    messageSuccess:
      ACCOUNT_LIST_MULTI_DELETE_SUCCESS1 +
      `${
        selectAllData ? listAccountSoliton?.data?.total : listIdSelected?.length
      }` +
      ACCOUNT_LIST_MULTI_DELETE_SUCCESS2,
  });

  const handlePagingChange = (pageNumber, pageSize) => {
    if (isOnline) {
      setCurrent(pageNumber);
      setPage(pageSize);
    } else {
      errorNetWork();
    }
  };

  const handleChangePageSize = (value) => {
    if (isOnline) {
      setPage(value);
      setCurrent(1);
    } else {
      errorNetWork();
    }
  };

  const handleMultiDelete = () => {
    if (isOnline) {
      const payload = {
        keyword: encodeURIComponent(searchValue),
        isPartner: false,
        listUserId: listIdSelected,
        isAll: selectAllData,
      };
      mutateMultipleDeleteItem(payload);
      setListIdSelected([]);
    } else {
      errorNetWork();
    }
  };

  const handleDeleteUser = (id) => {
    deleteSinglePartner({
      listUserId: [id],
      isPartner: true,
      isAll: false,
    });
    if (isOnline) {
      setListIdSelected([]);
    } else {
      errorNetWork();
    }
  };

  const handleNavigate = () => {
    if (isOnline) {
      navigate('/manage-account-soliton/register');
    } else {
      errorNetWork();
    }
  };

  const handleSelectAllRecord = () => {
    if (isOnline) {
      if (!selectAllData) {
        setSelectAllData((current) => !current);
      } else {
        handleSelectRowToDefault();
      }
    } else {
      errorNetWork();
    }
  };

  const handleSelectRowToDefault = () => {
    if (isOnline) {
      setSelectAllData(false);
      setSelectAllPage(false);
      setSelectedRowKeys([]);
      setListIdSelected([]);
    } else {
      errorNetWork();
    }
  };

  const handleChange = (pagination, filters, sorter) => {
    if (isOnline) {
      setSortedInfo({
        sortField: sorter.order && sorter.field,
        sortDirection: sorter.order && SORT_DIRECTION[sorter.order],
        order: sorter.order,
        columnKey: sorter.columnKey,
      });
    } else {
      errorNetWork();
    }
  };

  useEffect(() => {
    if (searchKeyword) {
      setCurrent(1);
    }
  }, [searchKeyword]);

  return (
    <>
      <Breadcrumb
        listItem={listItemByRole[role]}
        titlePage="ソリトンアカウント管理"
      >
        <ButtonBreadcrumb
          text={'新規登録'}
          icon={<PlusOutlined />}
          onClick={handleNavigate}
        />
      </Breadcrumb>
      <LayoutDefault>
        {contextErrorNetWork}
        {contextHolder}
        <HeaderListDataLayout
          titleScreen={ACCOUNT_LIST_TITLE}
          searchPlaceholder={ACCOUNT_PLACEHOLDER_SEARCH}
          onSearch={onSearch}
          loading={isLoading}
          value={page}
          handleChangePageSize={handleChangePageSize}
          pageSizeOptions={defaultSelectPageSizeOption}
          searchValue={searchValue}
          setSearchValue={setSearchValue}
        >
          <ButtonStyled
            type="text"
            disabled={listIdSelected.length === 0 || isDisable}
            size={'middle'}
            icon={
              <IconWrapper
                style={{
                  opacity: listIdSelected.length === 0 || isDisable ? 0.3 : 1,
                }}
              >
                <DeleteOutlined />
              </IconWrapper>
            }
            onClick={() => {
              if (isOnline) {
                handleConfirmAction({
                  confirmFn: handleMultiDelete,
                  title: (
                    <div>
                      <span>{ACCOUNT_LIST_MULTI_DELETE_CONFIRM1}</span>
                      <span style={{ fontSize: '16px' }}>
                        {selectAllData
                          ? listAccountSoliton?.data?.total
                          : listIdSelected.length}
                      </span>
                      <span>{ACCOUNT_LIST_MULTI_DELETE_CONFIRM2}</span>
                    </div>
                  ),
                  okText: <span> {ACCOUNT_LIST_MULTI_DELETE}</span>,
                  setIsDisable: setIsDisable,
                });
                setIsDisable(true);
              } else {
                errorNetWork();
              }
            }}
          >
            {ACCOUNT_LIST_MULTI_DELETE}
          </ButtonStyled>
        </HeaderListDataLayout>
        {listIdSelected.length !== 0 && (
          <SelectAllTable
            dataRaw={listAccountSoliton.data}
            handleSelectAllRecord={handleSelectAllRecord}
            selectAllData={selectAllData}
            listIdSelected={listIdSelected}
          />
        )}
        <TableData
          setCurrent={setCurrent}
          isLoading={isLoading}
          isDisable={isDisable}
          setIsDisable={setIsDisable}
          dataRaw={listAccountSoliton.data}
          emptyText={emptyText}
          setListItemDelete={setListItemDelete}
          handleDeleteUser={handleDeleteUser}
          onChange={handleChange}
          setListIdSelected={setListIdSelected}
          listIdSelected={listIdSelected}
          setSelectAllPage={setSelectAllPage}
          selectAllData={selectAllData}
          setSelectAllData={setSelectAllData}
          sortedInfo={sortedInfo}
          selectedRowKeys={selectedRowKeys}
          setSelectedRowKeys={setSelectedRowKeys}
        />

        <Pagination
          totalTitle={ACCOUNT_LIST_TABLE_PAGINATION_TOTAL}
          currentPage={current}
          pageSize={page}
          handlePagingChange={handlePagingChange}
          total={listAccountSoliton.data?.total}
          pageSizeOptions={defaultPageSizeOptions}
          handleChangePageSize={handleChangePageSize}
        />
      </LayoutDefault>
    </>
  );
};

export default AccountSolitonList;
