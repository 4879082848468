import useErrorMessageMultiLanguage from 'page/Authentication/SignUp/hook/useErrorMessageMultiLanguage';
import { useMemo } from 'react';

const useErrorCodeSignUp = () => {
  const {
    ERROR_EMAIL_ALREADY_EXISTED,
    ERROR_DOMAIN_NOT_REGISTERED,
    ERROR_USER_NOT_APPROVED,
  } = useErrorMessageMultiLanguage();

  // sign up error code
  const ERROR_CODE_SIGN_UP = useMemo(() => {
    return {
      '401_EMAIL_ALREADY_EXISTED': {
        message: ERROR_EMAIL_ALREADY_EXISTED,
        statusCode: 401,
        type: 'error',
      },
      '401_DOMAIN_NOT_REGISTERED': {
        message: ERROR_DOMAIN_NOT_REGISTERED,
        statusCode: 401,
        type: 'error',
      },
      '406_DOMAIN_IS_NOT_PARTNER': {
        message: ERROR_DOMAIN_NOT_REGISTERED,
        statusCode: 401,
        type: 'error',
      },
      '400_USER_NOT_APPROVED': {
        message: ERROR_USER_NOT_APPROVED,
        statusCode: 400,
        type: 'error',
      },
      '404_DOMAIN_NOT_EXISTS': {
        message: 'このアカウントは未登録企業です。',
        statusCode: 404,
        type: 'error',
      },
    };
  }, [
    ERROR_EMAIL_ALREADY_EXISTED,
    ERROR_DOMAIN_NOT_REGISTERED,
    ERROR_USER_NOT_APPROVED,
  ]);

  return { ERROR_CODE_SIGN_UP };
};

export default useErrorCodeSignUp;
