import React, { useState } from 'react';
import useCustomMutate from 'hook/useQuery/useCustomMutate';
import useCustomQuery from 'hook/useQuery/useCustomQuery';
import FormMessage from 'components/Form/FormMessage/index';
import {
  getPreSignedUrlToUploadOrDownload,
  uploadFileToS3WithPreSignedUrl,
} from 'api/storage/storage.api';
import { useRecoilValue } from 'recoil';
import {
  NOTIFICATION_FILE_EXTENSION,
  NOTIFICATION_FILE_TYPE,
} from 'constant/notificationConstants';
import { InboxOutlined } from '@ant-design/icons';
import { Form } from 'antd';
import { network } from 'recoil/atom/network';
import { CustomUpload } from './style';
import useUploadFileMultiLanguage from 'hook/useUploadFileMultiLanguage';

const FormFileUpload = ({
  name,
  multipleUpload,
  isDisabled,
  maxCount,
  text,
  isPreview,
  form,
  setIdFile,
  setIsShowHash,
  hint,
  fileObject,
  setFileObject,
  setIsCancel,
  setIsDisable,
}) => {
  const [isShowFile, setIsShowFile] = useState(true);
  const status = useRecoilValue(network);
  const { useQueryAPI } = useCustomQuery();
  const { useMutationAPI } = useCustomMutate();

  const {
    UPLOAD_FILE_SUCCESS,
    UPLOAD_FILE_FAILURE,
    UPLOAD_FILE_ALLOWED_EXTENSION,
    UPLOAD_FILE_MAX_SIZE,
    // UPLOAD_FILE_WITHOUT_INTERNET,
  } = useUploadFileMultiLanguage();

  const { refetch: refetchPreSignedUrl } = useQueryAPI({
    api: getPreSignedUrlToUploadOrDownload,
    params: {
      fileName: fileObject?.keyFileName,
      httpMethod: 'PUT',
    },
    options: {
      refetchOnWindowFocus: false,
    },
    enabled: !!fileObject?.keyFileName,
  });

  const { mutateAsync: mutateAsyncUpload } = useMutationAPI({
    api: uploadFileToS3WithPreSignedUrl,
    messageSuccess: UPLOAD_FILE_SUCCESS,
    messageError: UPLOAD_FILE_FAILURE,
  });

  const normFile = (e) => {
    if (Array.isArray(e)) {
      return e;
    }
    setIdFile(null);
    return e?.fileList;
  };

  const onDrop = () => {
    setIsShowHash(false);
  };

  const options = (onProgress, file) => ({
    onUploadProgress: (progress) => {
      const { loaded, total } = progress;
      onProgress(
        {
          percent: Math.round((loaded / total) * 100),
        },
        file,
      );
    },
    headers: {
      'Content-Type': file?.type,
    },
  });

  const beforeUpload = (file) => {
    const fileType = NOTIFICATION_FILE_TYPE[file.type];
    const fileExtension =
      NOTIFICATION_FILE_EXTENSION[file.name?.split('.')?.pop().toLowerCase()];
    if (!status) {
      return false;
    }
    if (file) {
      if (!fileType && !fileExtension) {
        return false;
      } else {
        if (fileType) {
          if (file.size > fileType.size) {
            return false;
          }
        } else if (fileExtension) {
          if (file.size > fileExtension.size) {
            return false;
          }
        }
      }
    }
    return true;
  };

  return (
    <Form.Item
      style={{ marginBottom: '5px' }}
      name={name}
      valuePropName={form.getFieldValue('file')?.[0] ? 'fileList' : 'file'}
      getValueFromEvent={normFile}
      required={true}
      rules={[
        {
          validator: (_, value) => {
            // if (!status && value) {
            //   form.setFieldValue('file', []);
            //   setIsShowFile(false);
            //   return Promise.reject(
            //     <FormMessage
            //       text1={UPLOAD_FILE_WITHOUT_INTERNET}
            //       marginBottom={'12px'}
            //     />,
            //   );
            // }
            if (!isPreview && value && value.length > 0) {
              const fileExtension =
                NOTIFICATION_FILE_EXTENSION[
                  value[0].name?.split('.')?.pop().toLowerCase()
                ];
              const fileType = NOTIFICATION_FILE_TYPE[value[0].type];
              if (!fileType && !fileExtension) {
                form.setFieldValue('file', []);
                setIsShowFile(false);
                return Promise.reject(
                  <FormMessage
                    text1={UPLOAD_FILE_ALLOWED_EXTENSION}
                    marginBottom={'12px'}
                  />,
                );
              } else {
                let sizeError = false;
                if (fileType) {
                  if (value[0].size > fileType.size) {
                    sizeError = true;
                  }
                } else if (fileExtension) {
                  if (value[0].size > fileExtension.size) {
                    sizeError = true;
                  }
                }
                if (sizeError === true) {
                  form.setFieldValue('file', []);
                  setIsShowFile(false);
                  return Promise.reject(
                    <FormMessage
                      text1={UPLOAD_FILE_MAX_SIZE}
                      marginBottom={'12px'}
                    />,
                  );
                }
              }
            }
            setIsShowFile(true);
            return Promise.resolve();
          },
        },
      ]}
    >
      <CustomUpload
        multipleUpload={multipleUpload}
        disabled={isDisabled}
        maxCount={maxCount}
        showUploadList={isShowFile}
        onRemove={() => {
          setIsShowHash(false);
        }}
        beforeUpload={beforeUpload}
        onDrop={() => onDrop()}
        progress={{
          strokeColor: {
            '0%': '#108ee9',
            '100%': '#87d068',
          },
          size: 'small',
          format: (percent) => percent && `${parseFloat(percent.toFixed(2))}%`,
        }}
        customRequest={async ({ file, onError, onSuccess, onProgress }) => {
          setIsShowHash(false);
          setIsCancel(true);
          setIsDisable(true);

          const extension = file?.name?.substring(
            file?.name?.lastIndexOf('.'),
            file?.name?.length,
          );

          const modifiedFileName = file?.name?.replace(
            extension,
            extension?.toLowerCase(),
          );

          await setFileObject({
            fileName: modifiedFileName,
            keyFileName: file?.uid,
            size: file?.size,
            contentType: file?.type,
          });

          refetchPreSignedUrl().then((value) => {
            mutateAsyncUpload({
              url: value?.data,
              file: file,
              options: options(onProgress, file),
            })
              .then((value) => {
                onSuccess(value, file);
                setIsCancel(false);
                setIsDisable(false);
              })
              .catch((error) => {
                onError(error);
                setIsCancel(false);
                setIsDisable(false);
              });
          });
        }}
      >
        <p className="ant-upload-drag-icon">
          <InboxOutlined />
        </p>
        <p>{text}</p>
        <p className={'ant-upload-hint'}>{hint}</p>
      </CustomUpload>
    </Form.Item>
  );
};
export default FormFileUpload;
