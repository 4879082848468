import React from 'react';
import { FormMessage } from 'components/Form';
import { FormItem } from 'components/Form2';
import { InputStyled } from 'components/Form2/styled';
import { MAX_LENGTH_TEXT } from 'constant/inputMaxlength';
import {
  CHECK_HAS_NUMBER,
  CHECK_LATIN_JAPANESE,
  CHECK_NUMBER_AFTER_BLANK,
  CHECK_SPECIAL_CHARACTER,
} from 'constant/regex';
import useMultiLanguageRegisterAccount from 'page/Application/ManageAccountSoliton/RegisterAccountSoliton/hook/useMultiLanguageRegisterAccount';
import {
  GridFormAccount,
  GridFormAccount1to2,
  GridFormAccount2to3,
} from 'page/Application/MyPage/component/FormMyPage/styled';
import LabelFormMyPage from 'page/Application/MyPage/component/FormMyPage/LabelFormMyPage';

const LastNameFirstName = ({ disabled, editMode, isEdit }) => {
  const { REGISTER_ACCOUNT_CATEGORY_ENTRY_REQUIRED } =
    useMultiLanguageRegisterAccount();

  const rulesValidateText = [
    {
      required: true,
      message: (
        <FormMessage
          text1={REGISTER_ACCOUNT_CATEGORY_ENTRY_REQUIRED}
          marginBottom={'12px'}
        />
      ),
    },
    () => ({
      validator(_, value) {
        if (
          value &&
          (!CHECK_HAS_NUMBER.test(value) || // check has a number
            CHECK_SPECIAL_CHARACTER.test(value) ||
            !CHECK_LATIN_JAPANESE.test(value) || // check japanese, latin
            CHECK_NUMBER_AFTER_BLANK.test(value) || // check blank then number
            value.trim() === '' || // check blank
            (value && [...value].some((char) => char.codePointAt(0) > 65535))) // check 4-byte characters
        ) {
          return Promise.reject(
            <FormMessage
              text1={'無効な文字列です。全角半角文字のみで入力してください。'}
              marginBottom={'12px'}
            />,
          );
        }
        return Promise.resolve();
      },
    }),
  ];

  return (
    <GridFormAccount>
      <GridFormAccount1to2>
        <LabelFormMyPage labelText={'姓'} required={isEdit} />
      </GridFormAccount1to2>
      <GridFormAccount2to3>
        <FormItem name={'lastName'} rules={rulesValidateText}>
          <InputStyled
            disabled={disabled}
            maxLength={MAX_LENGTH_TEXT}
            placeholder={editMode ? '姓を入力してください。' : ''}
          />
        </FormItem>
      </GridFormAccount2to3>

      <GridFormAccount1to2>
        <LabelFormMyPage labelText={'名'} required={isEdit} />
      </GridFormAccount1to2>
      <GridFormAccount2to3>
        <FormItem name={'firstName'} rules={rulesValidateText}>
          <InputStyled
            disabled={disabled}
            maxLength={MAX_LENGTH_TEXT}
            placeholder={editMode ? '名を入力してください。' : ''}
          />
        </FormItem>
      </GridFormAccount2to3>
    </GridFormAccount>
  );
};

export default LastNameFirstName;
