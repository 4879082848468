import styled from 'styled-components';

export const Text = styled.div`
  //display: flex;
  //justify-content: space-between;
  color: VAR(--FORM-LABEL-default);
  font-size: 16px;
  padding: 5px 10px 25px 10px;
  line-height: 20px;
`;
