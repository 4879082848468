import React from 'react';
import CompanyInformationOverview from 'page/Application/MyPagePartnerAdmin/CompanyInformation/CompanyInformationOverview';
import CompanyInformationEdit from 'page/Application/MyPagePartnerAdmin/CompanyInformation/CompanyInformationEdit';
import { Outlet } from 'react-router-dom';
import useSetTitlePage from 'hook/useSetTitlePage';

const CompanyInformation = () => {
  useSetTitlePage('企業情報');

  return <Outlet />;
};

export default CompanyInformation;

export { CompanyInformationOverview, CompanyInformationEdit };
