import styled from 'styled-components';
import { Block } from 'style/styleComponent/styled';

export const Container = styled.div`
  min-height: calc(100dvh - 309px);
  margin: 0 25px;
`;

export const ContentWrapper = styled.div`
  background: white;
  border-radius: 8px;
  font-family: 'inter-regular', sans-serif;
  position: relative;
`;

export const BlockFooter = styled(Block)`
  border-radius: 0 0 8px 8px;
`;
export const BlockHeader = styled(Block)`
  border-radius: 8px 8px 0 0;
`;
