import { createGlobalStyle } from "styled-components";
import MontserratBlack from "assets/fonts/montserrat/Montserrat-Black.ttf"
import InterBold from "assets/fonts/inter/Inter-Bold.ttf"
import InterMedium from "assets/fonts/inter/Inter-Medium.ttf"
import InterRegular from "assets/fonts/inter/Inter-Regular.ttf"
import InterSemiBold from "assets/fonts/inter/Inter-SemiBold.ttf"

export const TypographyVariable = createGlobalStyle`
  @font-face {
    font-family: "montserrat-black";
    src: url(${MontserratBlack}) format("truetype");
  }

  @font-face {
    font-family: "inter-bold";
    src: url(${InterBold}) format("truetype");
  }

  @font-face {
    font-family: "inter-semibold";
    src: url(${InterSemiBold}) format("truetype");
  }

  @font-face {
    font-family: "inter-medium";
    src: url(${InterMedium}) format("truetype");
  }

  @font-face {
    font-family: "inter-regular";
    src: url(${InterRegular}) format("truetype");
  }
  
:root {

  
  
  --font-weight-700: 700;
  --font-weight-500: 500;
  --font-weight-400: 400;
  --font-weight-300: 300;

  --H1-font-size: 28px;
  --H1-line-height: 34px;

  --H3-font-size: 21px;
  --H3-line-height: 29px;

  --H4-font-size: 16px;
  --H4-line-height: 23px;

  --P1-font-size: 16px;
  --P1-line-height: 24px;

  --P2-font-size: 14px;
  --P2-line-height: 21px;
}
`;
