import React, { useEffect, useState } from 'react';
import { EditOutlined, DeleteOutlined } from '@ant-design/icons';
import { FormAddPermissionWrapper } from 'page/Application/ManageCompanyList/component/Form/FormAddProduct/styled';
import { ButtonStyle } from 'page/Application/ManageCompanyList/component/Form/styled';
import FormLabelGrid from 'page/Application/ManageProjectRegistration/component/FormLabelGrid';
import ModalURL from 'page/Application/ManageProjectRegistration/component/ModalURL';
import {
  Grid1,
  Grid2,
} from 'page/Application/ManageProjectRegistration/component/styled';
import { useRecoilState } from 'recoil';
import { projectUrl } from 'recoil/atom/projectUrl';
import { ButtonAction } from 'style/styleComponent/styled';

const FormURL = ({ isEdit, data, form }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isEditModal, setIsEditModal] = useState(false);
  const [projectUrlRecoil, setProjectUrlRecoil] = useRecoilState(projectUrl);

  const isValidUrl = (url) => {
    try {
      const parsedURL = new URL(url);
      return parsedURL.protocol !== '' && parsedURL.hostname !== '';
    } catch (error) {
      return false;
    }
  };

  const isUrlValid = isValidUrl(form.getFieldValue('projectUrl'));
  const handleOpenModal = () => {
    setIsModalOpen(true);
  };

  const handleDeleteURL = () => {
    form.resetFields(['projectUrlTitle', 'projectUrl']);
    setProjectUrlRecoil({
      projectUrlTitle: '案件登録お申し込みはこちら',
      projectUrl: '',
    });
  };

  const handleCancel = () => {
    setIsModalOpen(false);
    if (projectUrlRecoil) {
      form.setFieldsValue({
        ...projectUrlRecoil,
      });
    }
  };

  const handleURL = () => {
    form
      .validateFields(['projectUrlTitle', 'projectUrl'])
      .then((values) => {
        setProjectUrlRecoil({
          projectUrlTitle: values.projectUrlTitle,
          projectUrl: values.projectUrl,
        });
        setIsModalOpen(false);
      })
      .catch(() => {
        setIsModalOpen(true);
        return false;
      });
  };

  useEffect(() => {
    if (isEdit) {
      setProjectUrlRecoil({
        projectUrlTitle: data?.projectUrlTitle,
        projectUrl: data?.projectUrl,
      });
    }
  }, [data?.projectUrl, data?.projectUrlTitle, setProjectUrlRecoil, isEdit]);

  return (
    <>
      <ModalURL
        isEditModal={isEditModal}
        isModalOpen={isModalOpen}
        handleCancel={handleCancel}
        handleOk={handleURL}
        titleUrl={projectUrlRecoil?.projectUrlTitle}
      />
      <Grid1>
        <FormLabelGrid labelText={'フォームURL'} />
      </Grid1>
      <Grid2>
        {!projectUrlRecoil?.projectUrl ? (
          <FormAddPermissionWrapper>
            <ButtonStyle
              onClick={() => {
                setIsEditModal(false);
                handleOpenModal();
              }}
            >
              {/*must have a span tag so there are no spaces between letters*/}
              <span> {'追加'}</span>
            </ButtonStyle>
          </FormAddPermissionWrapper>
        ) : (
          <div
            style={{
              padding: '4px 0',
              height: 32,
              marginBottom: 30,
              display: 'grid',
              gridTemplateColumns: 'auto 50px 50px',
              borderRadius: 8,
              fontFamily: 'inter-regular, sans-serif',
            }}
          >
            <span
              style={{
                display: 'flex',
                alignItems: 'center',
              }}
            >
              <a
                href={isUrlValid ? projectUrlRecoil?.projectUrl : undefined}
                target={'_blank'}
                style={{
                  textDecoration: 'underline',
                  maxWidth: 598,
                  paddingRight: 4,
                  whiteSpace: 'nowrap',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  fontFamily: 'Roboto',
                  color: '#1890FF',
                }}
                rel="noreferrer"
              >
                {projectUrlRecoil?.projectUrlTitle ||
                  projectUrlRecoil?.projectUrl}
              </a>
            </span>
            <ButtonAction
              style={{ height: 32, padding: 0 }}
              type="link"
              onClick={() => {
                setIsEditModal(true);
                handleOpenModal();
              }}
            >
              <EditOutlined />
            </ButtonAction>
            <ButtonAction
              style={{ height: 32, padding: 0 }}
              type="link"
              onClick={() => handleDeleteURL()}
            >
              <DeleteOutlined />
            </ButtonAction>
          </div>
        )}
      </Grid2>
    </>
  );
};
export default FormURL;
