import { atom } from 'recoil';

export const listFileDetailsRecoil = atom({
  key: 'listFileDetails',
  default: [],
});

export const fileDetailsNew = atom({
  key: 'fileDetailsNew',
  default: {
    title: '',
    explanation: '',
    displayStatus: '',
    displayDate: '',
    publishedFrom: '',
    publishedTo: '',
    multipartFile: '',
  },
});
