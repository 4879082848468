import { useIntl } from 'react-intl';

const useMultiLanguage = () => {
  const intl = useIntl();
  const FORGOT_PASSWORD_EMAIL_ADDRESS = intl.formatMessage({
    id: 'authentication.signIn.emailAddress',
  });
  const FORGOT_PASSWORD_EMAIL_ADDRESS_ALERT = intl.formatMessage({
    id: 'authentication.forgotPassword.emailAddress.alert',
  });

  const FORGOT_PASSWORD_ADDRESS_PLACEHOLDER = intl.formatMessage({
    id: 'authentication.signIn.emailAddress.placeholder',
  });

  const FORGOT_PASSWORD_TITLE = intl.formatMessage({
    id: 'authentication.forgotPassword.title',
  });

  const FORGOT_PASSWORD_MESSAGE = intl.formatMessage({
    id: 'authentication.forgotPassword.message',
  });

  const FORGOT_PASSWORD_SUCCESS_MESSAGE = intl.formatMessage({
    id: 'authentication.forgotPassword.successMessage',
  });

  return {
    FORGOT_PASSWORD_EMAIL_ADDRESS,
    FORGOT_PASSWORD_EMAIL_ADDRESS_ALERT,
    FORGOT_PASSWORD_ADDRESS_PLACEHOLDER,
    FORGOT_PASSWORD_TITLE,
    FORGOT_PASSWORD_MESSAGE,
    FORGOT_PASSWORD_SUCCESS_MESSAGE,
  };
};

export default useMultiLanguage;
