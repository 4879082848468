import styled from 'styled-components';

export const GridForm = styled.div`
  display: grid;
  width: 780px;
  grid-template-columns: 370px 370px;
  column-gap: 20px;
  justify-content: space-between;
`;

export const Grid1 = styled.div`
  grid-column: 1 / 2;
`;

export const Grid2 = styled.div`
  grid-column: 2 / 3;
`;
