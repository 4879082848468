import React, { useMemo } from 'react';
import LabelFormMyPage from 'page/Application/MyPage/component/FormMyPage/LabelFormMyPage';
import { FormItem } from 'components/Form2';
import { SelectStyled } from 'components/Form2/FormEmailSelectDomain/styled';
import {
  Grid1to2,
  Grid2to3,
  GridForm,
  GridFormAccount,
  GridFormAccount1to2,
  GridFormAccount2to3,
} from 'page/Application/MyPagePartnerAdmin/styled';
import { getListDutyExisted } from 'api/account/account.api';
import useCustomQuery from 'hook/useQuery/useCustomQuery';

const Duty = ({ editMode }) => {
  const { useQueryWithoutParams } = useCustomQuery();
  const { data: listDuty = [] } = useQueryWithoutParams({
    api: getListDutyExisted,
    key: 'listDuty',
    options: {
      refetchOnWindowFocus: false,
    },
  });

  const dutyOptions = useMemo(() => {
    return listDuty?.data?.map((value) => ({
      label: value?.name,
      title: '',
      value: value?.id,
    }));
  }, [listDuty?.data]);

  return (
    <GridFormAccount>
      <GridFormAccount1to2>
        <LabelFormMyPage labelText={'職務'} />
      </GridFormAccount1to2>
      <GridFormAccount2to3>
        <FormItem name={'dutyId'}>
          <SelectStyled
            options={dutyOptions}
            placeholder={editMode ? '職務を入力してください。' : ''}
          />
        </FormItem>
      </GridFormAccount2to3>
    </GridFormAccount>
  );
};

export default Duty;
