import styled from 'styled-components';
import { Table } from 'antd';

export const TableStyled2 = styled(Table)`
  .ant-table-container {
    //min-height: calc(100dvh - 413px);
  }

  .ant-table-cell {
    padding: 10px !important;
    font-family: 'inter-regular', sans-serif;
    color: #656464;
    height: 48px;
  }

  .ant-table-column-sorters {
    justify-content: start !important;
    column-gap: 12px;
  }

  .ant-table-column-title {
    flex: unset;
  }

  ::-webkit-scrollbar {
    width: 6px;
  }

  /* Track */

  ::-webkit-scrollbar-track {
    background: transparent;
  }

  /* Handle */

  ::-webkit-scrollbar-thumb {
    background: #d9d9d9;
    border-radius: 2px;
  }

  /* Handle on hover */

  ::-webkit-scrollbar-thumb:hover {
    background: #555;
  }

  .ant-table-thead {
    font-size: 16px;
  }
`;
