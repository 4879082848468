import { EditOutlined } from '@ant-design/icons';
import { message, Tooltip } from 'antd';
import AntdTable from 'components/AntdTable';
import { EDIT_PATH, MANAGE_PROJECT_REGISTRATION_PATH } from 'constant/appPath';
import useProjectStatusMultiLanguage from 'hook/useProjectStatusMultiLanguage';
import React, { useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { ButtonAction, GroupButtonAction } from 'style/styleComponent/styled';
import { TooltipHide } from 'page/Application/ManageCompanyList/CompanyList/styled';

const TableProject = ({ dataRaw, isLoading, onChange, sortedInfo }) => {
  const navigate = useNavigate();
  const { STATUS_FOR_PROJECT } = useProjectStatusMultiLanguage();
  const [isOnline, setIsOnline] = useState(true);

  const handleNetworkStatus = (isOnline) => {
    if (!isOnline) {
      message
        .error({
          key: 'error',
          content:
            '問題が発生しました。インターネット接続を確認して、もう一度やり直してください。',
          duration: 2,
        })
        .then();
      return false;
    }
    return true;
  };

  useEffect(() => {
    window.addEventListener('offline', () => setIsOnline(false));
    window.addEventListener('online', () => setIsOnline(true));
  }, []);

  // initial columns for table
  const columns = useMemo(() => {
    // move user to edit screen
    const handleEdit = (id) => {
      if (handleNetworkStatus(isOnline)) {
        navigate(`/${MANAGE_PROJECT_REGISTRATION_PATH}/${EDIT_PATH}/${id}`);
      }
    };
    return [
      {
        key: 'projectName',
        title: (
          <Tooltip title={'タイトル'} placement={'topLeft'}>
            {'タイトル'}
          </Tooltip>
        ),
        dataIndex: 'projectName',
        ellipsis: true,
        align: 'left',
        showSorterTooltip: false,
        sorter: true,
        render: (projectName) => (
          <TooltipHide>
            <Tooltip title={projectName} placement={'topLeft'}>
              {projectName}
            </Tooltip>
          </TooltipHide>
        ),
        sortOrder:
          sortedInfo.columnKey === 'projectName' ? sortedInfo.order : null,
      },
      {
        key: 'projectIsDisplayed',
        title: (
          <Tooltip title={'ステータス'} placement={'topLeft'}>
            {'ステータス'}
          </Tooltip>
        ),
        dataIndex: 'projectIsDisplayed',
        align: 'left',
        ellipsis: true,
        render: (text) => {
          return <div title={''}>{STATUS_FOR_PROJECT[text]}</div>;
        },
        sorter: true,
        sortOrder:
          sortedInfo.columnKey === 'projectIsDisplayed'
            ? sortedInfo.order
            : null,
        width: '20%',
      },
      {
        title: <span style={{ paddingLeft: '20px' }}>操作</span>,
        dataIndex: 'projectId',
        ellipsis: true,
        render: (projectId) => (
          <GroupButtonAction>
            <ButtonAction type="link" onClick={() => handleEdit(projectId)}>
              <EditOutlined />
            </ButtonAction>
          </GroupButtonAction>
        ),
        align: 'center',
        width: '15%',
      },
    ];
  }, [STATUS_FOR_PROJECT, isOnline, navigate, sortedInfo]);

  const dataSource = useMemo(() => {
    if (isLoading && !dataRaw) return [];
    return dataRaw?.list?.map((item) => ({
      ...item,
      key: item.projectId,
    }));
  }, [dataRaw, isLoading]);

  return (
    <AntdTable
      height={532}
      columns={columns}
      isLoading={isLoading}
      dataSource={dataSource}
      emptyText={'データが見つかりませんでした。'}
      onChange={onChange}
    />
  );
};

export default TableProject;
