import { atom } from 'recoil';

export const filterContent = atom({
  key: 'filterContent',
  default: {
    productId: '',
    displayType: '',
    displayedDateFrom: '',
    displayedDateTo: '',
    displayStatus: '',
    menuId: '',
    menuIds: [],
  },
});
