import React, { useEffect, useMemo, useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import Breadcrumb from 'components/Layout/ApplicationLayout/Breadcrumb';
import ProductContent from 'page/Application/ManageProductAndService/TopPageProductPartnerAdmin/TopPageProductDetailPartnerAdmin/component/ProductContent';
import LayoutTopPage from 'page/Application/ManageProductAndService/TopPageProductPartnerAdmin/TopPagePartnerAdmin/component/LayoutTopPage';
import dayjs from 'dayjs';
import parse from 'html-react-parser';
import {
  BodyProductDetail,
  BreadCrumbDetail,
  FormContent,
  FormContentAndDate,
  FormDate,
  FormDetailTitle,
  TableListProductDetail,
  TextWrapper,
  TitleFormProductDetail,
} from 'page/Application/ManageProductAndService/TopPageProduct/TopPageProductDetail/styled';
import { Divider, Spin, Tooltip } from 'antd';
import {
  getTopPageDetailProduct,
  getTopPageMenuParent,
  getTopPageProduct,
} from 'api/product/topPage.api';
import useCustomQuery from 'hook/useQuery/useCustomQuery';
import { useRecoilValue } from 'recoil';
import { userInformation } from 'recoil/atom/authentication';
import {
  TagStyled,
  TagTitle,
  TagWrapper,
} from 'page/Application/ManageProductAndService/TopPageProduct/TopPageViewProductSuperDetail/styled';
import { TagContainer } from 'page/Application/ManageProductAndService/TopPageProductPartnerAdmin/TopPageProductSuperDetailPartnerAdmin/styled';
import { ERROR_MESSAGES_RESPONSE } from 'constant/topPageConstants';

const TopPageViewSuperDetailProduct = () => {
  const { menuId, productId, detailId } = useParams();
  const { useQueryAPI } = useCustomQuery();
  const { role } = useRecoilValue(userInformation);
  const navigate = useNavigate();
  const [listMenu, setListMenu] = useState([]);

  const { data: menuParent = [], isLoading: menuPartnerLoading } = useQueryAPI({
    api: getTopPageMenuParent,
    key: 'menuParent',
    params: {
      menuId: menuId,
    },
  });

  const {
    data: dataDetail = [],
    isLoading: loadingDataDetail,
    error,
  } = useQueryAPI({
    api: getTopPageDetailProduct,
    key: 'dataDetail',
    params: {
      productId: productId,
      menuId: menuId,
      detailId: detailId,
    },
  });

  const { data: dataProduct = [], isLoading: loadingDataProduct } = useQueryAPI(
    {
      api: getTopPageProduct,
      key: 'dataProduct',
      params: {
        productId: productId,
      },
    },
  );

  const listItemTopPagePartner = useMemo(() => {
    return [
      {
        title: <Link to={'/partner-page'}>パートナー専用ページ</Link>,
      },
      {
        title: <Link to={'/top-page'}>製品一覧</Link>,
      },
      {
        title: (
          <Tooltip
            placement={'topLeft'}
            title={dataProduct?.data?.product?.productName}
          >
            <Link to={`/top-page/top-page-product/${productId}`}>
              <BreadCrumbDetail>
                {dataProduct?.data?.product?.productName}
              </BreadCrumbDetail>
            </Link>
          </Tooltip>
        ),
      },
      ...listMenu,
      {
        title: (
          <Tooltip placement={'topLeft'} title={dataDetail?.data?.detailTitle}>
            <BreadCrumbDetail>{dataDetail?.data?.detailTitle}</BreadCrumbDetail>
          </Tooltip>
        ),
      },
    ];
  }, [
    dataDetail?.data?.detailTitle,
    dataProduct?.data?.product?.productName,
    listMenu,
    productId,
  ]);

  const listItemTopPageSA = useMemo(() => {
    return [
      {
        title: <Link to={'/manage-company-list'}>企業管理</Link>,
      },
      {
        title: <Link to={'/top-page'}>製品一覧</Link>,
      },
      {
        title: (
          <Tooltip
            placement={'topLeft'}
            title={dataProduct?.data?.product?.productName}
          >
            <Link to={`/top-page/top-page-product/${productId}`}>
              <BreadCrumbDetail>
                {dataProduct?.data?.product?.productName ?? ''}
              </BreadCrumbDetail>
            </Link>
          </Tooltip>
        ),
      },
      ...listMenu,
      {
        title: (
          <Tooltip placement={'topLeft'} title={dataDetail?.data?.detailTitle}>
            <BreadCrumbDetail>{dataDetail?.data?.detailTitle}</BreadCrumbDetail>
          </Tooltip>
        ),
      },
    ];
  }, [
    dataDetail?.data?.detailTitle,
    dataProduct?.data?.product?.productName,
    listMenu,
    productId,
  ]);

  const listItemTopPageSU = useMemo(() => {
    return [
      {
        title: <Link to={'/my-page'}>マイページ</Link>,
      },
      {
        title: <Link to={'/top-page'}>製品一覧</Link>,
      },
      {
        title: (
          <Tooltip
            placement={'topLeft'}
            title={dataProduct?.data?.product?.productName}
          >
            <Link to={`/top-page/top-page-product/${productId}`}>
              <BreadCrumbDetail>
                {dataProduct?.data?.product?.productName ?? ''}
              </BreadCrumbDetail>
            </Link>
          </Tooltip>
        ),
      },
      ...listMenu,
      {
        title: (
          <Tooltip placement={'topLeft'} title={dataDetail?.data?.detailTitle}>
            <BreadCrumbDetail>{dataDetail?.data?.detailTitle}</BreadCrumbDetail>
          </Tooltip>
        ),
      },
    ];
  }, [
    dataDetail?.data?.detailTitle,
    dataProduct?.data?.product?.productName,
    listMenu,
    productId,
  ]);

  const listItemByRole = useMemo(() => {
    return {
      ROLE_SOLITON_ADMIN: listItemTopPageSA,
      ROLE_SOLITON_USER: listItemTopPageSU,
      ROLE_PARTNER_ADMIN: listItemTopPagePartner,
      ROLE_PARTNER_USER: listItemTopPagePartner,
    };
  }, [listItemTopPageSA, listItemTopPageSU, listItemTopPagePartner]);

  const handleNavigateOpenSearch = (tag) => {
    const currentUrl = window.location.origin;
    window.open(
      `${currentUrl}/#/opensearch?keyword=${encodeURIComponent(tag)}`,
      '_self',
    );
  };

  useEffect(() => {
    if (ERROR_MESSAGES_RESPONSE[error?.response?.data?.message]) {
      navigate('/detail-not-found');
    }

    // Set list breadcrumb menu top page
    setListMenu(
      menuParent?.data && !menuPartnerLoading
        ? menuParent?.data?.map((item) => {
            return {
              title: (
                <Tooltip title={item?.menuName} placement={'topLeft'}>
                  <Link
                    to={`/top-page/top-page-product/${productId}/top-page-detail-product/${item?.menuId}`}
                  >
                    <BreadCrumbDetail>{item?.menuName}</BreadCrumbDetail>
                  </Link>
                </Tooltip>
              ),
            };
          })
        : '',
    );
  }, [
    error?.response?.data?.message,
    menuParent?.data,
    menuPartnerLoading,
    navigate,
    productId,
  ]);

  return (
    <Spin
      spinning={menuPartnerLoading || loadingDataProduct || loadingDataDetail}
    >
      <Breadcrumb
        listItem={listItemByRole[role]}
        titlePage={dataProduct?.data?.product?.productName}
      />
      {(dataProduct?.data?.product?.productContent ||
        dataProduct?.data?.product?.image) && (
        <LayoutTopPage>
          <ProductContent
            isLoading={menuPartnerLoading}
            dataNotify={dataProduct?.data}
          />
        </LayoutTopPage>
      )}
      <TableListProductDetail>
        {/*<Divider orientation="left" orientationMargin="0">*/}
        <TitleFormProductDetail>
          <div style={{ display: 'flex' }}>
            <Tooltip
              title={dataDetail?.data?.detailTitle}
              placement={'topLeft'}
            >
              <FormDetailTitle
                cursor={'text'}
                color={'var(--BTN-TEXT-SECONDARY-default)'}
              >
                {dataDetail?.data?.detailTitle}
              </FormDetailTitle>
            </Tooltip>
          </div>
          <FormDate className={'date-padding'}>
            更新日:{' '}
            <span style={{ fontWeight: 400 }}>
              {dayjs(dataDetail?.data?.detailDisplayDate).format(
                'YYYY年MM月DD日',
              )}
            </span>
          </FormDate>
        </TitleFormProductDetail>

        <Divider style={{ margin: '8px 0 20px', color: '#656464' }} />
        {/*</Divider>*/}
        <BodyProductDetail>
          {dataDetail?.data?.detailTagLst?.length > 0 && (
            <TagWrapper>
              <TagTitle>タグ</TagTitle>
              <TagContainer>
                {dataDetail?.data?.detailTagLst?.map((itemTag, index) => (
                  <TagStyled
                    key={index}
                    onClick={() => handleNavigateOpenSearch(itemTag)}
                  >
                    {itemTag}
                  </TagStyled>
                ))}
              </TagContainer>
            </TagWrapper>
          )}
          {dataDetail?.data?.detailContent && (
            <FormContentAndDate>
              <FormContent>
                <TextWrapper>
                  {parse(dataDetail?.data?.detailContent)}
                </TextWrapper>
              </FormContent>
            </FormContentAndDate>
          )}
        </BodyProductDetail>
      </TableListProductDetail>
    </Spin>
  );
};
export default TopPageViewSuperDetailProduct;
