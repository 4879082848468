import styled from 'styled-components';

export const LogoStyled = styled.img`
  height: 50px;

  ${({ content }) => {
    if (content) {
      return `
      padding-top: 15px;
      padding-left: 20px
      `;
    } else {
      return `margin: 18px 20px;`;
    }
  }};

  @media all and(max-width: 500px) {
    height: auto;
    width: 90%;
    object-fit: cover;
    object-position: center;
  }
`;
