const useStatusContent = () => {
  const STATUS_FOR_LIST = {
    SHOW: '表示',
    HIDE: '非表示',
    SCHEDULED: '予約',
    DOWNLOADABLE_STOPPED: 'DL停止',
  };
  const STATUS_FOR_FORM = [
    {
      id: 1,
      label: '表示',
      value: 'SHOW',
    },
    {
      id: 2,
      label: '非表示',
      value: 'HIDE',
    },
    {
      id: 3,
      label: '予約',
      value: 'SCHEDULED',
    },
    {
      id: 4,
      label: 'DL停止',
      value: 'DOWNLOADABLE_STOPPED',
    },
  ];

  const STATUS_TYPE_FOR_LIST = {
    FILE: 'ファイル',
    LINK: 'リンク',
    DETAIL: '詳細あり',
  };
  const STATUS_TYPE_FOR_FORM = [
    {
      id: 1,
      label: 'ファイル',
      value: 'FILE',
    },
    {
      id: 2,
      label: 'リンク',
      value: 'LINK',
    },
    {
      id: 3,
      label: '詳細あり',
      value: 'DETAIL',
    },
  ];

  const STATUS_VALUE_HASH_FOR_LIST = {
    true: '表示する',
    false: '表示しない',
  };
  const STATUS_VALUE_HASH_FOR_FORM = [
    {
      id: 1,
      label: '表示する',
      value: true,
    },
    {
      id: 2,
      label: '表示しない',
      value: false,
    },
  ];

  return {
    STATUS_FOR_FORM,
    STATUS_FOR_LIST,
    STATUS_TYPE_FOR_FORM,
    STATUS_TYPE_FOR_LIST,
    STATUS_VALUE_HASH_FOR_FORM,
    STATUS_VALUE_HASH_FOR_LIST,
  };
};

export default useStatusContent;
