import styled from 'styled-components';

export const FormAddPermissionWrapper = styled.div`
  margin-bottom: 24px;
`;

export const PartnerNameItems = styled.div`
  color: var(--PRIMARY-default);
  text-decoration: underline;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;
