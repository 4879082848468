import React from 'react';
import { Form, Select } from 'antd';
import FormMessage from 'components/Form/FormMessage/index';

const FormDropDown = ({
  name,
  required,
  data,
  messageRequired,
  placeHolder,
  isLoading,
  onChange,
}) => {
  return (
    <Form.Item
      name={name}
      rules={[
        {
          required: required,
          message: (
            <FormMessage
              text1={messageRequired}
              marginBottom={'12px'}
            ></FormMessage>
          ),
        },
      ]}
    >
      <Select
        placeholder={placeHolder}
        popupMatchSelectWidth={false}
        options={data}
        allowClear
        loading={isLoading}
        onChange={onChange}
      />
    </Form.Item>
  );
};

export default FormDropDown;
