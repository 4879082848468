import React, { useMemo } from 'react';
import LabelForm from 'components/Form/LabelForm';
import useMultiLanguage from 'hook/useMultiLanguage';
import FormMessage from 'components/Form/FormMessage';
import { FormItemStyled, InputStyled } from 'components/Form/styled';
import { MAX_LENGTH_TEXT } from 'constant/inputMaxlength';

const FormItemValidateText = ({ inputType, name, maxLength }) => {
  const {
    FORM_LAST_NAME_LABEL,
    FORM_LAST_NAME_LABEL_PLACEHOLDER,
    FORM_FIRST_NAME_LABEL,
    FORM_FIRST_NAME_PLACEHOLDER,
    FORM_NAME_ERROR_MESSAGE,
    FORM_NAME_ERROR_MESSAGE_EMPTY,
  } = useMultiLanguage();

  const checkInputType = useMemo(() => {
    switch (inputType) {
      case 'lastName':
        return {
          label: FORM_LAST_NAME_LABEL,
          placeholder: FORM_LAST_NAME_LABEL_PLACEHOLDER,
        };
      case 'firstName':
        return {
          label: FORM_FIRST_NAME_LABEL,
          placeholder: FORM_FIRST_NAME_PLACEHOLDER,
        };
      default:
        return null;
    }
  }, [
    inputType,
    FORM_FIRST_NAME_LABEL,
    FORM_LAST_NAME_LABEL_PLACEHOLDER,
    FORM_LAST_NAME_LABEL,
    FORM_FIRST_NAME_PLACEHOLDER,
  ]);
  const regexSpecialCharacter = /[-._!"`'#%&,:;<>=@{}~$()*+/\\?[\]^|]+/;
  const regexOnlyNumber = /^[0-9]+$/;
  const regexLatinJapanese = /^[A-Za-zぁ-んァ-ヶー一-龠々〆ヵヶ]+$/;

  return (
    <FormItemStyled
      className="name-textbox"
      name={name}
      label={<LabelForm text={checkInputType.label} />}
      rules={[
        {
          required: true,
          message: (
            <FormMessage
              text1={FORM_NAME_ERROR_MESSAGE_EMPTY}
              marginBottom={'12px'}
            />
          ),
        },
        () => ({
          validator(_, value) {
            if (
              regexOnlyNumber.test(value) ||
              regexSpecialCharacter.test(value) ||
              !regexLatinJapanese.test(value) ||
              (value && [...value].some((char) => char.codePointAt(0) > 65535))
            ) {
              return Promise.reject(
                <FormMessage
                  text1={FORM_NAME_ERROR_MESSAGE}
                  marginBottom={'12px'}
                />,
              );
            }
            return Promise.resolve();
          },
        }),
      ]}
    >
      <InputStyled
        className="input-size-large-44"
        type="text"
        maxLength={maxLength ? maxLength : MAX_LENGTH_TEXT}
        placeholder={checkInputType.placeholder}
      />
    </FormItemStyled>
  );
};

export default FormItemValidateText;
