import axios from 'axios';
import { ERROR_CODE_RESPONSE } from 'constant/errorCode';

const TIME_OUT = 259200000; //3 days

const axiosClientNotTimeOut = axios.create({
  baseURL: process.env.REACT_APP_API_ENDPOINT,
  timeout: TIME_OUT,
});

export default axiosClientNotTimeOut;

// Add a request interceptor
axiosClientNotTimeOut.interceptors.request.use(
  function (config) {
    return config;
  },
  function (error) {
    // Do something with request error
    return Promise.reject(error);
  },
);

// Add a response interceptor
axiosClientNotTimeOut.interceptors.response.use(
  function (response) {
    // Any status code that lie within the range of 2xx cause this function to trigger
    // Do something with response data
    return response.data;
  },
  function (error) {
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    // Do something with response error

    // if (
    //   error.code === 'ERR_BAD_REQUEST' &&
    //   error.response.data.message === '400_JWT_TOKEN_IS_EXPIRED'
    // ) {
    //   localStorage.removeItem('user_token');
    //   localStorage.removeItem('password_expire');
    //   window.location.replace('/');
    //   window.location.reload();
    // }

    if (
      error.code === 'ERR_BAD_REQUEST' &&
      ERROR_CODE_RESPONSE[error.response.data.message]
    ) {
      localStorage.removeItem('user_token');
      localStorage.removeItem('password_expire');
      window.location.replace('/');
      window.location.reload();
    }
    return Promise.reject(error);
  },
);
