import React, { Suspense } from 'react';
import AntdModal from 'components/AntdModal';
import { DatePicker, Form, Input } from 'antd';
import { InputStyled } from 'components/Form2/styled';
import { FormItem } from 'components/Form2';
import dayjs from 'dayjs';
import FormMessage from 'components/Form/FormMessage';
import FormLabelGrid from 'components/Form/FormLabelGrid';

const { TextArea } = Input;

const ModalNotification = ({
  totalData,
  isModalOpen,
  onCancel,
  dataEdit,
  isEditPopup,
  form,
  dataSource,
  setDataSource,
  setIsModalOpen,
}) => {
  const disabledDate = (current) => {
    return (
      !current ||
      current.isBefore('1920-01-01') ||
      current.isAfter('2039-01-01')
    );
  };

  const sortObjectsByDateDescending = (objects) => {
    const objectsWithoutNoticeId = [];
    const objectsWithNoticeId = [];
    let foundNoticeId = false;

    for (let i = 0; i < objects.length; i++) {
      const obj = objects[i];
      if ((!foundNoticeId && obj.noticeId) || obj?.hadSort) {
        foundNoticeId = true;
      }

      if (foundNoticeId) {
        objectsWithNoticeId.push(obj);
      } else {
        objectsWithoutNoticeId.push(obj);
      }
    }

    objectsWithoutNoticeId.sort(
      (a, b) => new Date(b.noticeDisplayDate) - new Date(a.noticeDisplayDate),
    );

    return [...objectsWithoutNoticeId, ...objectsWithNoticeId];
  };

  const onFinish = () => {
    form
      .validateFields()
      .then(() => {
        const value = form.getFieldsValue(true);
        if (isEditPopup) {
          const editDataSource = dataSource.map((item) => {
            if (dataEdit.key === item.key) {
              return {
                ...item,
                ...value,
                noticeContent: value?.noticeContent?.trim(),
                noticeDisplayDate: value.noticeDisplayDate
                  ? dayjs(value.noticeDisplayDate).format('YYYY-MM-DD')
                  : item.noticeDisplayDate,
              };
            }
            return { ...item };
          });
          setDataSource(editDataSource);
          setIsModalOpen(false);
          form.resetFields();
        } else {
          const editDataSource = (prev) =>
            sortObjectsByDateDescending([
              {
                key: totalData + 1,
                ...value,
                noticeContent: value?.noticeContent?.trim(),
                noticeDisplayDate: dayjs(value.noticeDisplayDate).format(
                  'YYYY-MM-DD',
                ),
              },
              ...prev,
            ]);
          setDataSource(editDataSource);
          setIsModalOpen(false);
          form.resetFields();
        }
      })
      .catch(() => {
        setIsModalOpen(true);
        return false;
      });
  };

  return (
    <Suspense fallback={form}>
      <AntdModal
        isModalOpen={isModalOpen}
        onCancel={onCancel}
        title={!isEditPopup ? 'お知らせの追加' : 'お知らせの変更'}
        textSubmit={!isEditPopup ? '登録' : '保存'}
        width={740}
        handleOk={onFinish}
      >
        <Form
          form={form}
          style={{ paddingTop: 32 }}
          name="registerNotify"
          labelCol={{
            span: 4,
          }}
        >
          <FormItem
            name={'noticeTitle'}
            rules={[
              {
                validator: (_, value) => {
                  const trimmedString = value?.trim();
                  if (
                    !value ||
                    trimmedString.length === 0 ||
                    value.trim() === ''
                  ) {
                    return Promise.reject(
                      <FormMessage
                        text1={'情報を入力してください。'}
                        marginBottom={'12px'}
                      />,
                    );
                  }
                  return Promise.resolve();
                },
              },
            ]}
            label={<FormLabelGrid labelText={'タイトル'} required={true} />}
          >
            <InputStyled
              maxLength={256}
              placeholder={'タイトル名を入力してください'}
            />
          </FormItem>

          <FormItem
            label={<FormLabelGrid labelText={'表示日付'} required={true} />}
            name={'noticeDisplayDate'}
            rules={[
              {
                message: (
                  <FormMessage
                    text1={'情報を入力してください。'}
                    marginBottom={'12px'}
                  />
                ),
                required: true,
              },
            ]}
          >
            <DatePicker
              inputReadOnly
              style={{ height: 40, width: '100%' }}
              placeholder={'日付選択'}
              format={'YYYY年MM月DD日'}
              disabledDate={(date) => disabledDate(date)}
            />
          </FormItem>

          <FormItem
            label={<FormLabelGrid labelText={'内容'} required={false} />}
            name="noticeContent"
          >
            <TextArea
              name="contentNotify"
              style={{
                height: 150,
                resize: 'none',
                whiteSpace: 'pre-wrap',
                lineHeight: 1.5,
              }}
            />
          </FormItem>
        </Form>
      </AntdModal>
    </Suspense>
  );
};

export default ModalNotification;
