import { useIntl } from 'react-intl';

const useMultiLanguageEditCompany = () => {
  const intl = useIntl();
  const DETAIL_COMPANY_TITLE = intl.formatMessage({
    id: 'application.EditCompany.title',
  });
  const DETAIL_COMPANY_USER_ACCOUNT = intl.formatMessage({
    id: 'application.EditCompany.userAccount',
  });
  const DETAIL_COMPANY_USER_NAME = intl.formatMessage({
    id: 'application.EditCompany.userName',
  });
  const DETAIL_COMPANY_USER_EMAIL = intl.formatMessage({
    id: 'application.EditCompany.userEmail',
  });
  const DETAIL_COMPANY_USER_ACCOUNT_STATUS = intl.formatMessage({
    id: 'application.EditCompany.userAccountStatus',
  });
  const DETAIL_COMPANY_USER_ACCOUNT_STATUS_ENABLED = intl.formatMessage({
    id: 'application.EditCompany.userAccountStatus.enabled',
  });
  const DETAIL_COMPANY_USER_ACCOUNT_STATUS_UNAPPROVED = intl.formatMessage({
    id: 'application.EditCompany.userAccountStatus.unapproved',
  });
  const DETAIL_COMPANY_USER_ACCOUNT_STATUS_SUSPENDED = intl.formatMessage({
    id: 'application.EditCompany.userAccountStatus.suspended',
  });
  const DETAIL_COMPANY_MESSAGE_SUCCESS = intl.formatMessage({
    id: 'application.EditCompany.messageSuccess',
  });
  const DETAIL_COMPANY_CONFIRM_CANCEL = intl.formatMessage({
    id: 'application.EditCompany.confirmCancel',
  });
  const DETAIL_COMPANY_CONFIRM_SAVE = intl.formatMessage({
    id: 'application.EditCompany.confirmSave',
  });
  const DETAIL_COMPANY_CONFIRM_DELETE = intl.formatMessage({
    id: 'application.EditCompany.confirmDelete',
  });
  const DETAIL_COMPANY_BUTTON_DELETE = intl.formatMessage({
    id: 'application.EditCompany.deleteButton',
  });
  const DETAIL_COMPANY_SALES_FORCE_ID_EXISTED = intl.formatMessage({
    id: 'application.EditCompany.salesForceId.existed',
  });

  return {
    DETAIL_COMPANY_TITLE,
    DETAIL_COMPANY_USER_ACCOUNT,
    DETAIL_COMPANY_USER_NAME,
    DETAIL_COMPANY_USER_EMAIL,
    DETAIL_COMPANY_USER_ACCOUNT_STATUS,
    DETAIL_COMPANY_USER_ACCOUNT_STATUS_ENABLED,
    DETAIL_COMPANY_USER_ACCOUNT_STATUS_UNAPPROVED,
    DETAIL_COMPANY_USER_ACCOUNT_STATUS_SUSPENDED,
    DETAIL_COMPANY_MESSAGE_SUCCESS,
    DETAIL_COMPANY_CONFIRM_CANCEL,
    DETAIL_COMPANY_CONFIRM_SAVE,
    DETAIL_COMPANY_CONFIRM_DELETE,
    DETAIL_COMPANY_BUTTON_DELETE,
    DETAIL_COMPANY_SALES_FORCE_ID_EXISTED,
  };
};
export default useMultiLanguageEditCompany;
