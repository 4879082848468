import { Form, Radio } from 'antd';
import React from 'react';
import { RadioStyled } from 'page/Application/ManageProjectRegistration/component/FormRadio/styled';
import useStatusProduct from 'hook/useStatusProduct';

const FormRadio = ({ name, onChange, required }) => {
  const { STATUS_FOR_FORM } = useStatusProduct();

  return (
    <>
      <Form.Item name={name} required={required}>
        <Radio.Group name="productCategoryIsDisplayed" onChange={onChange}>
          <RadioStyled value={STATUS_FOR_FORM[0].value}>
            {STATUS_FOR_FORM[0].label}
          </RadioStyled>
          <RadioStyled value={STATUS_FOR_FORM[1].value}>
            {STATUS_FOR_FORM[1].label}
          </RadioStyled>
        </Radio.Group>
      </Form.Item>
    </>
  );
};
export default FormRadio;
