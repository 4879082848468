import { atom } from 'recoil';

export const positionItemDragEnter = atom({
  key: 'positionItemDragEnter',
  default: null,
});

export const errorMessage = atom({
  key: 'errorMessage',
  default: false,
});

// only use for tree when open create or edit mode
export const treeStatus = atom({
  key: 'treeStatus',
  default: {
    editing: false, // true is editing
  },
});

export const inputChange = atom({
  key: 'inputChange',
  default: false,
});
