import React, { useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { EditOutlined } from '@ant-design/icons';
import AntdTable from 'components/AntdTable';
import { EDIT_PATH } from 'constant/appPath';
import {
  DETAIL_CONTENT_TYPE_LIST,
  STATUS_FOR_LIST_CONTENT,
} from 'constant/contentConstants';
import { DATE_FORMAT_LIST } from 'constant/notificationConstants';
import dayjs from 'dayjs';
import {
  CustomRowTable,
  TableGrid2,
  TableGrid3,
} from 'page/Application/ManageProductAndService/Content/ContentList/component/TableContentList/styled';
import { ButtonAction, GroupButtonAction } from 'style/styleComponent/styled';
import { Tooltip } from 'antd';
import { TooltipHide } from 'page/Application/ManageCompanyList/CompanyList/styled';

const TableContentList = ({
  errorNetWork,
  dataRaw,
  isLoading,
  sortedInfo,
  onChange,
  setSelectAllData,
  setSelectAllPage,
  setListIdSelected,
  listIdSelected,
  emptyText,
  onChangeRow,
  selectedRowKeys,
  isOnline,
}) => {
  const navigate = useNavigate();

  // initial columns for table
  const columns = useMemo(() => {
    // move user to edit screen
    const handleEdit = (id) => {
      if (isOnline) {
        navigate(`/content/${EDIT_PATH}/${id}`);
      } else {
        errorNetWork();
      }
    };

    return [
      {
        key: 'detailTitle',
        title: (
          <Tooltip title={'タイトル'} placement={'topLeft'}>
            {'タイトル'}
          </Tooltip>
        ),
        dataIndex: 'detailTitle',
        ellipsis: true,
        align: 'left',
        width: '23%',
        showSorterTooltip: false,
        sorter: true,
        render: (detailTitle) => (
          <TooltipHide>
            <Tooltip title={detailTitle} placement={'topLeft'}>
              {detailTitle}
            </Tooltip>
          </TooltipHide>
        ),
        sortOrder:
          sortedInfo.columnKey === 'detailTitle' ? sortedInfo.order : null,
      },
      {
        key: 'productName',
        title: (
          <div>
            <Tooltip title={'プロダクト名'} placement={'topLeft'}>
              {'プロダクト名'}
            </Tooltip>
          </div>
        ),
        dataIndex: 'productName',
        ellipsis: true,
        align: 'left',
        width: '10%',
        render: (productName) => (
          <TooltipHide>
            <Tooltip title={productName} placement={'topLeft'}>
              {productName}
            </Tooltip>
          </TooltipHide>
        ),
        showSorterTooltip: false,
      },
      {
        key: 'menuName',
        title: (
          <div>
            <Tooltip title={'メニュー'} placement={'topLeft'}>
              {'メニュー'}
            </Tooltip>
          </div>
        ),
        dataIndex: 'menuName',
        ellipsis: true,
        render: (menuName) => (
          <TooltipHide>
            <Tooltip title={menuName} placement={'topLeft'}>
              {menuName}
            </Tooltip>
          </TooltipHide>
        ),
        align: 'left',
        width: '8%',
      },
      {
        key: 'detailDisplayType',
        title: (
          <Tooltip title={'コンテンツタイプ'} placement={'topLeft'}>
            {'コンテンツタイプ'}
          </Tooltip>
        ),
        dataIndex: 'detailDisplayType',
        align: 'left',
        ellipsis: true,
        width: '10%',
        showSorterTooltip: false,
        sorter: true,
        sortOrder:
          sortedInfo.columnKey === 'detailDisplayType'
            ? sortedInfo.order
            : null,
        render: (text) => {
          return <div title={''}>{DETAIL_CONTENT_TYPE_LIST[text] ?? ''}</div>;
        },
      },
      {
        key: 'detailDisplayDate',
        title: (
          <Tooltip title={'表示日付'} placement={'topLeft'}>
            {'表示日付'}
          </Tooltip>
        ),
        dataIndex: 'detailDisplayDate',
        align: 'left',
        ellipsis: true,
        width: '8%',
        showSorterTooltip: false,
        sorter: true,
        sortOrder:
          sortedInfo.columnKey === 'detailDisplayDate'
            ? sortedInfo.order
            : null,
        render: (text) => {
          return (
            <TooltipHide>
              <Tooltip
                title={text ? dayjs(text).format(DATE_FORMAT_LIST[1]) : ''}
                placement={'topLeft'}
              >
                {text ? dayjs(text).format(DATE_FORMAT_LIST[1]) : ''}
              </Tooltip>
            </TooltipHide>
          );
        },
      },
      {
        key: 'detailDisplayStatus',
        title: (
          <Tooltip title={'ステータス'} placement={'topLeft'}>
            {'ステータス'}
          </Tooltip>
        ),
        dataIndex: 'detailDisplayStatus',
        align: 'left',
        ellipsis: true,
        width: '7%',
        showSorterTooltip: false,
        sorter: true,
        sortOrder:
          sortedInfo.columnKey === 'detailDisplayStatus'
            ? sortedInfo.order
            : null,
        render: (text) => {
          return <div title={''}>{STATUS_FOR_LIST_CONTENT[text]}</div>;
        },
      },
      {
        title: (
          <span title={''} style={{ paddingLeft: '20px' }}>
            操作
          </span>
        ),
        dataIndex: 'detailId',
        ellipsis: true,
        render: (detailId) => (
          <GroupButtonAction>
            <ButtonAction type="link" onClick={() => handleEdit(detailId)}>
              <EditOutlined />
            </ButtonAction>
          </GroupButtonAction>
        ),
        align: 'center',
        width: '5%',
      },
    ];
  }, [
    isOnline,
    navigate,
    sortedInfo.columnKey,
    sortedInfo.order,
    errorNetWork,
  ]);

  const dataSource = useMemo(() => {
    if (isLoading && !dataRaw) return [];
    return dataRaw?.list?.map((item) => ({
      ...item,
      key: item.detailId,
      productMenuLst: item.productMenuLst?.map((childrenItem) => {
        childrenItem.productId ??= '';
        return {
          ...childrenItem,
          key:
            childrenItem?.productId +
            childrenItem?.productUuid +
            childrenItem?.menuId +
            childrenItem?.menuUuid,
        };
      }),
    }));
  }, [dataRaw, isLoading]);

  // render expand row, style like a row in table
  const expandable = {
    expandedRowRender: (record) => {
      return record?.productMenuLst?.map((item) => (
        <CustomRowTable key={item.key}>
          <TableGrid2
            ellipsis={{
              suffix: '',
              tooltip: { title: item.productName, placement: 'topLeft' },
            }}
          >
            {item.productName}
          </TableGrid2>
          <TableGrid3
            ellipsis={{
              suffix: '',
              tooltip: { title: item.menuName, placement: 'topLeft' },
            }}
          >
            {item.menuName}
          </TableGrid3>
        </CustomRowTable>
      ));
    },
    defaultExpandAllRows: true,
    showExpandColumn: false,
    rowExpandable: (record) => {
      return record?.productMenuLst?.length > 0;
    },
  };

  // render checkbox, select list item for delete
  const rowSelection = {
    onSelect: (record, selected, selectedRows) => {
      if (isOnline) {
        if (selectedRows.length === 0) {
          setSelectAllPage(false);
        } else {
          setSelectAllPage(true);
        }
        if (selected) {
          setSelectAllData(false);
          setListIdSelected((keys) => [...keys, record.key]);
        } else {
          setSelectAllData(false);
          setListIdSelected((keys) => {
            const index = keys.indexOf(record.key);
            return [...keys.slice(0, index), ...keys.slice(index + 1)];
          });
        }
      } else {
        errorNetWork();
      }
    },
    onSelectAll: (selected, selectedRows, changeRows) => {
      if (isOnline) {
        setSelectAllPage(selected);
        if (selected) {
          let listIdSelected = changeRows?.map((item) => item?.detailId);
          setListIdSelected((current) => [...current, ...listIdSelected]);
        } else {
          setSelectAllData(false);
          let listIdRemove = changeRows?.map((item) => item?.detailId);
          const newData = listIdSelected.filter(
            (item) => !listIdRemove.includes(item),
          );
          setListIdSelected(newData);
        }
      } else {
        errorNetWork();
      }
    },
    preserveSelectedRowKeys: true,
    columnWidth: '2%',
    onChange: onChangeRow,
    selectedRowKeys: selectedRowKeys,
    // checkStrictly: true,
  };

  return (
    <AntdTable
      height={532}
      columns={columns}
      isLoading={isLoading}
      dataSource={dataSource}
      emptyText={emptyText}
      onChange={onChange}
      expandable={expandable}
      rowSelection={rowSelection}
      checkStrictly={false}
    />
  );
};
export default TableContentList;
