import { useIntl } from 'react-intl';

const useMultiLanguageSignIn = () => {
  const intl = useIntl();

  const SIGN_UP_EMAIL_SUCCESS_MESSAGE = intl.formatMessage({
    id: 'authentication.signUp.success'
  })

  const SIGN_IN_TITLE = intl.formatMessage({
    id: 'authentication.signIn.title',
  });

  const SIGN_IN_REMEMBER_ME = intl.formatMessage({
    id: 'authentication.signIn.rememberMe',
  });

  const SIGN_IN_FORGOT_PASSWORD = intl.formatMessage({
    id: 'authentication.signIn.forgotPassword',
  });

  const SIGN_IN_PASSWORD_ERROR_MESSAGE_VALIDATE1 = intl.formatMessage({
    id: 'authentication.password.errorMessage.validate1'
  })

  const SIGN_IN_PASSWORD_ERROR_MESSAGE_VALIDATE2 = intl.formatMessage({
    id: 'authentication.password.errorMessage.validate2'
  })

  const MAINTENANCE_MESSAGE = intl.formatMessage({
    id:'maintenance.message'
  })

  return {
    SIGN_IN_TITLE,
    SIGN_IN_REMEMBER_ME,
    SIGN_IN_FORGOT_PASSWORD,
    SIGN_IN_PASSWORD_ERROR_MESSAGE_VALIDATE1,
    SIGN_IN_PASSWORD_ERROR_MESSAGE_VALIDATE2,
    SIGN_UP_EMAIL_SUCCESS_MESSAGE,
    MAINTENANCE_MESSAGE
  };
};

export default useMultiLanguageSignIn;
