import React, { useEffect, useState, Suspense } from 'react';
import { ButtonBasic, ButtonPrimary } from 'components/Button';
import {
  DepartmentPositionDuty,
  EmailPasswordAccountType,
  InformationCompany,
  LastNameFirstName,
} from 'page/Application/MyPage/component/FormMyPage';
import {
  getDetailPartnerUser,
  updatePartnerUser,
} from 'api/myPage/partnerUser.api';
import Breadcrumb from 'components/Layout/ApplicationLayout/Breadcrumb';
import LayoutDefault from 'components/Layout/LayoutDefault';
import RowButton from 'components/Layout/RowButton';
import useModalConfirm from 'hook/useModalConfirm';
import useMultiLanguage from 'hook/useMultiLanguage';
import useCustomMutateRenderMessage from 'hook/useQuery/useCustomMutateRenderMessage';
import useCustomQuery from 'hook/useQuery/useCustomQuery';
import useErrorCodeAccountPartner from 'page/Application/ManagerAccountPartner/hook/useErrorCodeAccountPartner';
import { useLocation, useNavigate, Link } from 'react-router-dom';
import { Alert, Form, Spin } from 'antd';
import {
  FormMyPageWrapper,
  GridFormAccount,
  GridFormAccount2to3,
} from 'page/Application/MyPage/component/FormMyPage/styled';
import PhoneNumber from 'page/Application/MyPage/component/FormMyPage/PhoneNumber';
import { TextBoxGroupJapanArea } from 'page/Application/MyPagePartnerAdmin/component/Form';
import { FormTitle } from 'page/Application/ManageCompanyList/component/Form';

const MyPageEditPartnerUser = () => {
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const [editPassword, setEditPassword] = useState(true);
  const location = useLocation();
  const { ERROR_CODE_ACCOUNT_PARTNER } = useErrorCodeAccountPartner();
  const { useMutationAPIHandleErrorCode, errorMessage } =
    useCustomMutateRenderMessage({ errorCode: ERROR_CODE_ACCOUNT_PARTNER });
  const { APPLICATION_OK_TEXT, APPLICATION_CANCEL_TEXT } = useMultiLanguage();
  const { handleConfirmAction, contextHolder } = useModalConfirm();
  const [isDisable, setIsDisable] = useState(false);
  const { useQueryWithoutParams } = useCustomQuery();
  const [data, setData] = useState(location.state);
  const [checkedListAll, setCheckedListAll] = useState([]);

  const { data: partnerDetail = [], isLoading } = useQueryWithoutParams({
    api: getDetailPartnerUser,
    key: 'myPagePU',
    options: {
      refetchOnWindowFocus: false, // cancel fetch data when out focus
    },
  });

  const listItem = [
    {
      title: <Link to={'/'}>パートナー専用ページ</Link>,
    },
    {
      title: 'マイページ',
    },
  ];

  const handleBack = () => {
    navigate('/my-page');
  };

  const onFinish = (values) => {
    const trimPosition = values?.position?.trim();
    const trimDepartment = values?.department?.trim();
    const payload = {
      ...values,
      position: trimPosition,
      department: trimDepartment,
      listAreaId: checkedListAll,
      phoneNumber: values?.phoneNumber.replaceAll('-', ''),
      dutyId: values.dutyId,
    };
    updateAccount({
      payload: payload,
    });
  };

  useEffect(() => {
    const detailData = partnerDetail?.data;
    form.setFieldsValue({
      listAreaId: detailData?.listAreaInCharge?.map((value) => {
        if (value?.parentId) {
          return [11, value?.parentId, value?.areaId];
        } else {
          return [value?.areaId];
        }
      }),
      duty: detailData?.duty,
      dutyId: detailData?.dutyId,
      ...detailData,
      phoneNumber: detailData?.phoneNumber ?? detailData?.mobileNumber,
    });
    if (!data) {
      setData(partnerDetail?.data);
    }
  }, [form, data, partnerDetail?.data, partnerDetail]);

  const {
    mutate: updateAccount,
    isError,
    isLoading: isLoadingUpdate,
    isSuccess,
  } = useMutationAPIHandleErrorCode({
    api: updatePartnerUser,
    messageSuccess: '変更した情報を保存しました。',
    path: '/my-page',
    refetchKey: 'myPagePU',
  });

  const onChangePhoneNumber = (value) => {
    const valuePhoneNumber = value?.target?.value;
    form.setFieldValue('phoneNumber', valuePhoneNumber);
  };

  return (
    <>
      {contextHolder}
      <Suspense fallback={isLoading}>
        <Breadcrumb listItem={listItem} titlePage={'マイページ'} />
        <LayoutDefault>
          <FormTitle
            notice={'注意：'}
            title={
              '変更した内容は画面下部の「保存」ボタンを押すと保存されます。'
            }
            type={'text'}
            styles={{ color: '#9D1C49' }}
          />
          <Spin spinning={isLoading || isLoadingUpdate || isSuccess}>
            <FormMyPageWrapper>
              {(isError || errorMessage) && (
                <GridFormAccount>
                  <GridFormAccount2to3>
                    <Alert message={errorMessage} type="error" />
                  </GridFormAccount2to3>
                </GridFormAccount>
              )}
              <Form size={'Large'} form={form} onFinish={onFinish}>
                <InformationCompany disabled={true} editMode={true} />
                <LastNameFirstName editMode={true} isEdit={true} />
                <EmailPasswordAccountType
                  isEdit={true}
                  data={data}
                  disabled={true}
                  setEditPassword={setEditPassword}
                  editPassword={editPassword}
                />
                <PhoneNumber
                  onChange={onChangePhoneNumber}
                  isEdit={true}
                  maxLength={12}
                  isPhone={true}
                />
                <DepartmentPositionDuty editMode={true} />
                <TextBoxGroupJapanArea
                  isEdit={true}
                  formDetailData={partnerDetail}
                  checkedListAll={checkedListAll}
                  setCheckedListAll={setCheckedListAll}
                  disabled={false}
                />
                <GridFormAccount className="margin-top-20">
                  <GridFormAccount2to3>
                    <RowButton>
                      <ButtonPrimary text={'保存'} width={86} />
                      <ButtonBasic
                        text={'キャンセル'}
                        disable={isDisable}
                        onClick={() => {
                          handleConfirmAction({
                            confirmFn: handleBack,
                            title: (
                              <span>
                                {'変更をキャンセルしてもよろしいでしょうか？'}
                              </span>
                            ),
                            cancelText: <span> {APPLICATION_CANCEL_TEXT}</span>,
                            okText: <span> {APPLICATION_OK_TEXT}</span>,
                            setIsDisable: setIsDisable,
                          });
                          setIsDisable(true);
                        }}
                      />
                    </RowButton>
                  </GridFormAccount2to3>
                </GridFormAccount>
              </Form>
            </FormMyPageWrapper>
          </Spin>
        </LayoutDefault>
      </Suspense>
    </>
  );
};

export default MyPageEditPartnerUser;
