import { useMemo } from 'react';

const useErrorCodeTopPage = () => {
  const ERROR_CODE_TOP_PAGE = useMemo(() => {
    return {
      '400_DETAIL_ID_IS_DUPLICATE': {
        message: '問題が発生しました。この操作は実施できておりません。',
        statusCode: 400,
        type: 'error',
      },
      '400_DETAIL_ID_NOT_EXISTED': {
        message: '問題が発生しました。この操作は実施できておりません。',
        statusCode: 400,
        type: 'error',
      },
      '400_MENU_NOT_EXISTED': {
        message: '問題が発生しました。この操作は実施できておりません。',
        statusCode: 400,
        type: 'error',
      },
      '400_CAN_NOT_ACCESS_DATA': {
        message: '問題が発生しました。この操作は実施できておりません。',
        statusCode: 400,
        type: 'error',
      },
      '404_NOTIFY_IN_TOP_PAGE_NOT_FOUND': {
        message: '問題が発生しました。この操作は実施できておりません。',
        statusCode: 400,
        type: 'error',
      },
      '400_PRODUCT_NOT_EXIST': {
        message: '問題が発生しました。この操作は実施できておりません。',
        statusCode: 400,
        type: 'error',
      },
    };
  }, []);
  return {
    ERROR_CODE_TOP_PAGE,
  };
};

export default useErrorCodeTopPage;
