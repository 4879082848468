import React, { useMemo, useState } from 'react';
import {
  getListDomain,
  registerAccountPartner,
} from 'api/account/partnerAccount.api';
import { useRecoilValue } from 'recoil';
import FormAccount from 'components/Form/Template/FormAccount';
import useCustomQuery from 'hook/useQuery/useCustomQuery';
import useMultiLanguageRegisterAccount from 'page/Application/ManageAccountSoliton/RegisterAccountSoliton/hook/useMultiLanguageRegisterAccount';
import useCustomMutateRenderMessage from 'hook/useQuery/useCustomMutateRenderMessage';
import useErrorCodeAccountPartner from 'page/Application/ManagerAccountPartner/hook/useErrorCodeAccountPartner';
import LayoutDefault from 'components/Layout/LayoutDefault';
import Breadcrumb from 'components/Layout/ApplicationLayout/Breadcrumb';
import { Form } from 'antd';
import { Link } from 'react-router-dom';
import { ACCOUNT_STATUS } from 'constant/accountStatus';
import { MANAGE_ACCOUNT_PARTNER_PATH } from 'constant/appPath';
import { userInformation } from 'recoil/atom/authentication';

const RegisterAccountPartner = () => {
  const { role: SigningRole } = useRecoilValue(userInformation);

  const [isDisable, setIsDisable] = useState(false);
  const [toggleChangePassword, setToggleChangePassword] = useState(false);
  const [checkedListAll, setCheckedListAll] = useState([]);
  const [role, setRole] = useState(ACCOUNT_STATUS.enable.value);
  const [email, setEmail] = useState('');

  const [form] = Form.useForm();
  const { ERROR_CODE_ACCOUNT_PARTNER } = useErrorCodeAccountPartner();
  const { useQueryAPI } = useCustomQuery();
  const { useMutationAPIHandleErrorCode, errorMessage } =
    useCustomMutateRenderMessage({ errorCode: ERROR_CODE_ACCOUNT_PARTNER });
  const { REGISTER_ACCOUNT_TITLE, REGISTER_ACCOUNT_REGISTER_SUCCESS } =
    useMultiLanguageRegisterAccount();
  const {partnerId} = useRecoilValue(userInformation);

  const listItemSA = useMemo(() => {
    return [
      {
        title: <Link to={'/'}>企業管理</Link>,
      },
      {
        title: (
          <Link to={'/manage-account-partner'}>パートナーアカウント管理</Link>
        ),
      },
      {
        title: 'パートナーアカウント登録',
      },
    ];
  }, []);

  const listItemPA = useMemo(() => {
    return [
      {
        title: <Link to={'/'}>パートナー専用ページ</Link>,
      },
      {
        title: <Link to={'/manage-account-partner'}>アカウント管理</Link>,
      },
      {
        title: 'アカウント登録',
      },
    ];
  }, []);

  const listItemByRole = useMemo(() => {
    return {
      ROLE_SOLITON_ADMIN: listItemSA,
      ROLE_SOLITON_USER: listItemSA,
      ROLE_PARTNER_ADMIN: listItemPA,
      ROLE_PARTNER_USER: listItemPA,
    };
  }, [listItemPA, listItemSA]);

  const listTitleByRole = useMemo(() => {
    return {
      ROLE_SOLITON_ADMIN: 'パートナーアカウント登録',
      ROLE_SOLITON_USER: 'パートナーアカウント登録',
      ROLE_PARTNER_ADMIN: 'アカウント登録',
      ROLE_PARTNER_USER: 'アカウント登録',
    };
  }, []);

  const { data: domainList = [], isLoading } = useQueryAPI({
    api: getListDomain,
    key: 'domain',
    params: {
      isPartner: true,
    },
  });

  const {
    mutate: registerAccountPartnerMutate,
    isError,
    isLoading: isLoadingSubmit,
    isSuccess: isSuccessSubmit,
  } = useMutationAPIHandleErrorCode({
    refetchKey: 'accountListPartner',
    api: registerAccountPartner,
    messageSuccess: REGISTER_ACCOUNT_REGISTER_SUCCESS,
    path: `/${MANAGE_ACCOUNT_PARTNER_PATH}`,
  });

  const onFinish = (value) => {
    const trimedPosition = value?.position?.trim();
    const trimedDepartment = value?.department?.trim();
    if (SigningRole === 'ROLE_PARTNER_ADMIN') {
      const payload = {
        email: `${value?.localPart.trim()}@${value.domain}`,
        password: value.password,
        firstName: value.firstName,
        lastName: value.lastName,
        position: trimedPosition,
        department: trimedDepartment,
        dutyId: value?.duty,
        role: value.role,
        approveStatus: role,
        phoneNumber: value.phoneNumber.replaceAll('-', ''),
        listAreaId: checkedListAll,
        partnerId: partnerId,
      };
      registerAccountPartnerMutate(payload);
    } else {
      const payload = {
        email: value.email,
        password: value.password,
        firstName: value.firstName,
        lastName: value.lastName,
        position: trimedPosition,
        department: trimedDepartment,
        dutyId: value.duty,
        role: value.role,
        approveStatus: role,
        phoneNumber: value?.phoneNumber?.replaceAll('-', ''),
        listAreaId: checkedListAll,
        partnerId: value.partnerId,
      };
      registerAccountPartnerMutate(payload);
    }
  };

  return (
    <>
      <Breadcrumb
        listItem={listItemByRole[SigningRole]}
        titlePage={listTitleByRole[SigningRole]}
      />
      <LayoutDefault>
        <FormAccount
          titleForm={REGISTER_ACCOUNT_TITLE}
          form={form}
          isDisable={isDisable}
          setIsDisable={setIsDisable}
          onFinish={onFinish}
          domainList={domainList}
          role={role}
          errorMessage={errorMessage}
          isError={isError}
          setRole={setRole}
          isLoading={isLoading}
          isLoadingSubmit={isLoadingSubmit}
          setToggleChangePassword={setToggleChangePassword}
          toggleChangePassword={toggleChangePassword}
          type={'title'}
          checkedListAll={checkedListAll}
          setCheckedListAll={setCheckedListAll}
          isSuccessSubmit={isSuccessSubmit}
          email={email}
          setEmail={setEmail}
        />
      </LayoutDefault>
    </>
  );
};

export default RegisterAccountPartner;
