import { ButtonGroup } from 'components/AntdModal3/styled';
import { ButtonOutline, ButtonPrimary } from 'components/Button/index';
import React from 'react';

const ButtonModal = ({
  loadingSubmit,
  handleOk,
  submittable,
  handleReset,
  isFilter,
  onCancel,
}) => {
  return (
    <ButtonGroup
      style={{
        marginRight: isFilter ? '20px' : '48px',
        paddingBottom: '30px',
      }}
    >
      <ButtonPrimary
        key="submit"
        loading={loadingSubmit}
        onClick={handleOk}
        text={isFilter ? '検索' : '一括変更'}
        disabled={isFilter ? submittable : false}
        width={isFilter ? 80 : 100}
        size={'large'}
      />
      <ButtonOutline
        key="back"
        onClick={isFilter ? handleReset : onCancel}
        text={isFilter ? 'リセット' : 'キャンセル'}
      />
    </ButtonGroup>
  );
};

export default ButtonModal;
