import styled from 'styled-components';

export const TagWrapper = styled.div`
  display: flex;
  gap: 8px;
`;

export const TagTitle = styled.div`
  padding: 8px 0;
  min-width: 40px;
  font-weight: 600;
  color: #656464;
  font-family: 'inter-semibold', sans-serif;
`;

export const TagStyled = styled.div`
  width: fit-content;
  padding: 8px;
  color: #656464;
  text-decoration: underline;
  cursor: pointer;
  transition: 0.25s;
  border-radius: 4px;
  word-break: break-word;
  max-width: 100%;

  &:hover {
    color: #1677ff;
    background: #f5f5f5;
  }
`;

export const TagContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
`;
