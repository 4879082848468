import React from 'react';
import { FormTextWrapper, FormTitleWrapper } from './styled';

const FormTitle = ({ title, type, styles }) => {
  return type === 'title' ? (
    <FormTitleWrapper style={styles}>{title}</FormTitleWrapper>
  ) : (
    <FormTextWrapper style={styles}>{title}</FormTextWrapper>
  );
};

export default FormTitle;
