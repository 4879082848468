import {
  DASHBOARD_PATH,
  EDIT_ACCOUNT_PARTNER_PATH,
  EDIT_ACCOUNT_SOLITON_PATH,
  EDIT_COMPANY_ABSOLUTE_PATH,
  MANAGE_ACCOUNT_PARTNER_PATH,
  MANAGE_ACCOUNT_SOLITON_PATH,
  MANAGE_COMPANY_LIST_PATH,
  MANAGE_NOTIFICATION_PATH,
  MANAGE_PROJECT_REGISTRATION_PATH,
  MANAGE_SOLUTION_PATH,
  REGISTER_PATH,
  WEBSITE_SETTING_PATH,
  EDIT_NOTIFICATION_PATH,
  PREVIEW_NOTIFICATION_PATH,
  OPENSEARCH_RESULT,
  PROJECT_REGISTRATION_PATH,
} from 'constant/appPath';
import { RESET_PASSWORD_PATH } from 'constant/authenticationPath';

import Application from 'page/Application';
import NotFound from 'page/NotFound';

// manage account soliton
import ManageAccountSoliton, {
  AccountSolitonList,
  RegisterAccountSoliton,
  EditAccountSoliton,
} from 'page/Application/ManageAccountSoliton';

// manage account partner
import ManagerAccountPartner, {
  AccountPartnerList,
  RegisterAccountPartner,
  EditAccountPartner,
} from 'page/Application/ManagerAccountPartner';

// manage company / partner
import ManageCompanyList, {
  CompanyList,
  EditCompany,
  RegisterCompany,
} from 'page/Application/ManageCompanyList';

// manage notification
import ManageNotificationList, {
  NotificationList,
  RegistrationNotification,
  EditNotification,
  PreviewNotification,
} from 'page/Application/ManageNotificationList';

import ManageProjectRegistration, {
  ProjectList,
  RegisterProject,
  EditProject,
} from 'page/Application/ManageProjectRegistration';
import ManageSolution from 'page/Application/ManageSolution';
import WebsiteSetting from 'page/Application/WebsiteSetting';

// manage product and service
// category
import Category, {
  CategoryList,
  RegisterCategory,
  EditCategory,
} from 'page/Application/ManageProductAndService/Category';

// product
import Product, {
  ProductList,
  RegisterProduct,
  EditProduct,
} from 'page/Application/ManageProductAndService/Product';

// content
import Content, {
  ContentList,
  RegisterContent,
  EditContent,
} from 'page/Application/ManageProductAndService/Content';

import PartnerWebsite, {
  PartnerWebsiteEdit,
  PartnerWebsiteOverView,
} from 'page/Application/PartnerWebsite';

//top page product
import TopPageProductPartnerAdmin, {
  DetailNotifyPartnerAdmin,
  TopPageNotifyDetailPartnerAdmin,
  TopPagePartnerAdmin,
  TopPageProductDetailPartnerAdmin,
  TopPageProductSuperDetailPartnerAdmin,
} from 'page/Application/ManageProductAndService/TopPageProductPartnerAdmin';
import TopPageEditSuperDetailProduct from 'page/Application/ManageProductAndService/TopPageProduct/TopPageEditSuperDetailProduct';

import ProjectPreviewSolitonAdmin, {
  ProjectPreview,
  ProjectDetail,
} from 'page/Application/ProjectPreviewSolitonAdmin';

import PartnerWebsiteDetail from 'page/Application/PartnerWebsite/PartnerWebsiteDetail';
import ManageProjectEditMode from 'page/Application/ManageProjectRegistration/ManageProjectEditMode';
import TopPageViewSuperDetailProduct from 'page/Application/ManageProductAndService/TopPageProduct/TopPageViewProductSuperDetail';
import DetailContentEditMode from 'page/Application/ManageProductAndService/TopPageProduct/DetailContentEditMode';
import TopPageEditProduct from 'page/Application/ManageProductAndService/TopPageProduct/TopPageEditProduct';
import OpensearchResult from 'page/Application/Opensearch/OpensearchResult';
import ResetPassword from 'page/Authentication/ForgotPassword/ResetPassword';

export const solitonAdminRouterData = [
  {
    path: DASHBOARD_PATH,
    element: <Application />,
    errorElement: <NotFound />,
    children: [
      // {
      //   index: true,
      //   element: <Dashboard />,
      // },
      {
        path: MANAGE_COMPANY_LIST_PATH,
        element: <ManageCompanyList />,
        children: [
          {
            index: true,
            element: <CompanyList />,
          },
          {
            path: REGISTER_PATH,
            element: <RegisterCompany />,
          },
          {
            path: EDIT_COMPANY_ABSOLUTE_PATH,
            element: <EditCompany />,
          },
        ],
      },
      {
        path: `/partner-page/:partnerId`,
        element: <PartnerWebsite />,
        children: [
          {
            index: true,
            element: <PartnerWebsiteOverView />,
          },
          {
            path: `/partner-page/:partnerId/edit`,
            element: <PartnerWebsiteEdit />,
          },
          {
            path: `/partner-page/:partnerId/detail/:noticeId`,
            element: <PartnerWebsiteDetail />,
          },
        ],
      },
      {
        path: MANAGE_ACCOUNT_SOLITON_PATH,
        element: <ManageAccountSoliton />,
        children: [
          {
            index: true,
            element: <AccountSolitonList />,
          },
          {
            path: REGISTER_PATH,
            element: <RegisterAccountSoliton />,
          },
          {
            path: EDIT_ACCOUNT_SOLITON_PATH,
            element: <EditAccountSoliton />,
          },
        ],
      },
      {
        path: MANAGE_ACCOUNT_PARTNER_PATH,
        element: <ManagerAccountPartner />,
        children: [
          {
            index: true,
            element: <AccountPartnerList />,
          },
          {
            path: REGISTER_PATH,
            element: <RegisterAccountPartner />,
          },
          {
            path: EDIT_ACCOUNT_PARTNER_PATH,
            element: <EditAccountPartner />,
          },
        ],
      },
      {
        path: MANAGE_NOTIFICATION_PATH,
        element: <ManageNotificationList />,
        children: [
          {
            index: true,
            element: <NotificationList />,
          },
          {
            path: REGISTER_PATH,
            element: <RegistrationNotification />,
          },
          {
            path: EDIT_NOTIFICATION_PATH,
            element: <EditNotification />,
          },
          {
            path: PREVIEW_NOTIFICATION_PATH,
            element: <PreviewNotification />,
          },
        ],
      },
      {
        path: 'category',
        element: <Category />,
        children: [
          {
            index: true,
            element: <CategoryList />,
          },
          {
            path: 'register',
            element: <RegisterCategory />,
          },
          {
            path: 'edit/:categoryId',
            element: <EditCategory />,
          },
        ],
      },
      {
        path: 'product',
        element: <Product />,
        children: [
          {
            index: true,
            element: <ProductList />,
          },
          {
            path: 'register',
            element: <RegisterProduct />,
          },
          {
            path: 'edit/:productId',
            element: <EditProduct />,
          },
        ],
      },
      {
        path: 'content',
        element: <Content />,
        children: [
          {
            index: true,
            element: <ContentList />,
          },
          {
            path: 'register',
            element: <RegisterContent />,
          },
          {
            path: 'edit/:contentId',
            element: <EditContent />,
          },
        ],
      },
      {
        path: 'top-page',
        element: <TopPageProductPartnerAdmin />,
        children: [
          {
            index: true,
            element: <TopPagePartnerAdmin />,
          },
          {
            path: 'edit',
            element: <DetailContentEditMode />,
          },
          {
            path: 'top-page-product/:productId/edit',
            element: <TopPageEditProduct />,
          },
          {
            path: 'top-page-product/:productId',
            element: <TopPageProductDetailPartnerAdmin />,
          },
          {
            path: 'top-page-product/list-notify/:productId',
            element: <DetailNotifyPartnerAdmin />,
          },
          {
            path: 'top-page-product/list-notify/:productId/notify-detail/:notifyId',
            element: <TopPageNotifyDetailPartnerAdmin />,
          },
          {
            path: 'top-page-product/:productId/notify-detail/:notifyId',
            element: <TopPageNotifyDetailPartnerAdmin />,
          },
          {
            path: 'top-page-product/:productId/top-page-detail-product/:menuId',
            element: <TopPageProductSuperDetailPartnerAdmin />,
          },
          {
            path: 'top-page-product/:productId/top-page-detail-product/:menuId/edit',
            element: <TopPageEditSuperDetailProduct />,
          },
          {
            path: 'top-page-product/:productId/top-page-detail-product/:menuId/detail/:detailId',
            element: <TopPageViewSuperDetailProduct />,
          },
          {
            path: 'list-notify',
            element: <DetailNotifyPartnerAdmin />,
          },
          {
            path: 'notify-detail/:notifyId',
            element: <TopPageNotifyDetailPartnerAdmin />,
          },
          {
            path: 'list-notify/notify-detail/:notifyId',
            element: <TopPageNotifyDetailPartnerAdmin />,
          },
        ],
      },

      {
        path: MANAGE_PROJECT_REGISTRATION_PATH,
        element: <ManageProjectRegistration />,
        children: [
          {
            index: true,
            element: <ProjectList />,
          },
          {
            path: 'register',
            element: <RegisterProject />,
          },
          {
            path: 'edit/:projectId',
            element: <EditProject />,
          },
          {
            path: 'editmode',
            element: <ManageProjectEditMode />,
          },
        ],
      },
      {
        path: PROJECT_REGISTRATION_PATH,
        element: <ProjectPreviewSolitonAdmin />,
        children: [
          {
            index: true,
            element: <ProjectPreview />,
          },
          {
            path: 'detail/:projectId',
            element: <ProjectDetail />,
          },
        ],
      },
      {
        path: MANAGE_SOLUTION_PATH,
        element: <ManageSolution />,
      },
      {
        path: WEBSITE_SETTING_PATH,
        element: <WebsiteSetting />,
      },
      {
        path: OPENSEARCH_RESULT,
        element: <OpensearchResult roleType={'solitonAdmin'} />,
      },
    ],
  },
  {
    path: RESET_PASSWORD_PATH,
    element: <ResetPassword />,
  },
  {
    path: '404',
    element: <NotFound/>
  }
];
