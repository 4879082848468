import React from 'react';
import isPropValid from '@emotion/is-prop-valid';
import { StyleSheetManager } from 'styled-components';
import { LogoStyled } from './styled';

const LogoViewer = ({ imageUrl, content }) => {
  const shouldForwardProp = (propName, target) => {
    if (typeof target === 'string') {
      return isPropValid(propName);
    }
    return true;
  };

  return (
    <StyleSheetManager shouldForwardProp={shouldForwardProp}>
      <LogoStyled src={imageUrl} content={content} />
    </StyleSheetManager>
  );
};

export default LogoViewer;
