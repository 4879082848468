import React from 'react';
import { Outlet } from 'react-router-dom';
import ProductList from 'page/Application/ManageProductAndService/Product/ProductList';
import RegisterProduct from 'page/Application/ManageProductAndService/Product/RegisterProduct';
import EditProduct from 'page/Application/ManageProductAndService/Product/EditProduct';
import { ProductProvider } from './component/ProductContext';
import useSetTitlePage from "../../../../hook/useSetTitlePage";
const Product = () => {
  useSetTitlePage('プロダクト管理');
  return (
    <>
      <ProductProvider>
        <Outlet />
      </ProductProvider>
    </>
  );
};

export default Product;

export { ProductList, RegisterProduct, EditProduct };
