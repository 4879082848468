import useErrorMessageMultiLanguageForgotPassword from 'page/Authentication/ForgotPassword/hook/useErrorMessageMultiLanguageForgotPassword';
import { useMemo } from 'react';

const useErrorCodeForgotPassword = () => {
  const {
    ERROR_EMAIL_NOT_FOUND,
    ERROR_USER_NOT_APPROVED,
    ERROR_FORGOT_PASSWORD_MUST_DIFFERENT,
    ERROR_USER_HAS_BEEN_SUSPENDED,
  } = useErrorMessageMultiLanguageForgotPassword();

  // forgot password error code
  const ERROR_CODE_FORGOT_PASSWORD = useMemo(() => {
    return {
      '404_EMAIL_NOT_FOUND': {
        message: ERROR_EMAIL_NOT_FOUND,
        statusCode: 404,
        type: 'error',
      },
      '400_USER_NOT_APPROVED': {
        message: ERROR_USER_NOT_APPROVED,
        statusCode: 400,
        type: 'error',
      },
      '400_NEW_PASSWORD_MUST_DIFFERENT_FROM_OLD_PASSWORD': {
        message: ERROR_FORGOT_PASSWORD_MUST_DIFFERENT,
        statusCode: 400,
        type: 'error',
      },
      '400_USER_HAS_BEEN_SUSPENDED': {
        message: ERROR_USER_HAS_BEEN_SUSPENDED,
        statusCode: 400,
        type: 'error',
      },
    };
  }, [
    ERROR_EMAIL_NOT_FOUND,
    ERROR_USER_NOT_APPROVED,
    ERROR_FORGOT_PASSWORD_MUST_DIFFERENT,
    ERROR_USER_HAS_BEEN_SUSPENDED,
  ]);

  return { ERROR_CODE_FORGOT_PASSWORD };
};

export default useErrorCodeForgotPassword;
