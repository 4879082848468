import React, { useEffect, useMemo, useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { Alert, message, Spin } from 'antd';
import { BreadCrumbDetail } from 'page/Application/ManageProductAndService/TopPageProduct/TopPageProductDetail/styled';
import { defaultData } from 'components/Tree/treeData';
import { TreeWrapper } from './styled';
import {
  getTopPageEditMode,
  updateTopPagePosition,
} from 'api/product/topPage.api';
import { downloadNotifyFile } from 'api/storage/storage.api';
import { ButtonOutline, ButtonPrimary } from 'components/Button';
import Breadcrumb from 'components/Layout/ApplicationLayout/Breadcrumb';
import Tree from 'components/Tree';
import useModalConfirm from 'hook/useModalConfirm';
import useCustomMutateRenderMessage from 'hook/useQuery/useCustomMutateRenderMessage';
import useCustomQuery from 'hook/useQuery/useCustomQuery';
import useMultiLanguageCompanyList from 'page/Application/ManageCompanyList/CompanyList/hook/useMultiLanguageCompanyList';
import HeaderTopPageCollapse from 'page/Application/ManageProductAndService/TopPageProduct/TopPage/component/HeaderTopPageCollapse';
import LayoutTopPage from '../TopPage/component/LayoutTopPage';
import NewestTable from './component/NewestTable';
import OtherNotifyTable from './component/OtherNotifyTable';
import PinnedTable from './component/PinnedTable';
import useMultiLanguageDetailContentEditMode from './hook/useMultiLanguageDetailContentEditMode';
import useErrorCodeTopPage from 'page/Application/ManageProductAndService/TopPageProduct/hook/useErrorCodeTopPage';
import { useRecoilValue } from 'recoil';
import { network } from 'recoil/atom/network';

const DetailContentEditMode = () => {
  const [messageErrorNetWork, contextErrorNetWork] = message.useMessage();
  const isOnline = useRecoilValue(network);
  const { useQueryAPI, useQueryWithoutParams } = useCustomQuery();
  const { ERROR_CODE_TOP_PAGE } = useErrorCodeTopPage();
  const [dataSource, setDataSource] = useState([]);
  const [treeData, setTreeData] = useState(defaultData);
  const { COMPANY_LIST_NO_DATA } = useMultiLanguageCompanyList();
  const [emptyText] = useState(COMPANY_LIST_NO_DATA);
  const [isCancel, setIsCancel] = useState(false);
  const [, setEditDataSource] = useState([]);
  const [isDisabledPin, setIsDisabledPin] = useState(false);
  const { contextHolder, handleConfirmAction } = useModalConfirm();
  const [showPinTable, setShowPinTable] = useState(true);
  const [showNewestTable, setShowNewestTable] = useState(true);
  const [showAnnouncements, setShowAnnouncements] = useState(true);
  const navigate = useNavigate();
  const { errorMessage, useMutationAPIHandleErrorCode } =
    useCustomMutateRenderMessage({
      errorCode: ERROR_CODE_TOP_PAGE,
    });
  const [notifyId, setNotifyId] = useState(null);
  const { productId } = useParams();
  const { APPLICATION_TOPPAGE_MESSAGE_EDIT_SUCCESS } =
    useMultiLanguageDetailContentEditMode();
  const { data: topPageNotifyDetail = [], isLoading } = useQueryWithoutParams({
    api: getTopPageEditMode,
    key: 'topPageEditMode',
    options: {
      refetchOnWindowFocus: false,
    },
  });
  const listItemTopPage = [
    {
      title: <Link to={'/manage-company-list'}>企業管理</Link>,
    },
    {
      title: <Link to={'/top-page'}>製品一覧</Link>,
    },
    {
      title: <BreadCrumbDetail>編集</BreadCrumbDetail>,
    },
  ];

  const { mutate: updateTopPagePositionMutation, isLoading: isUpdateLoading } =
    useMutationAPIHandleErrorCode({
      api: updateTopPagePosition,
      messageSuccess: APPLICATION_TOPPAGE_MESSAGE_EDIT_SUCCESS,
      path: `/top-page`,
      refetchKey: 'topPageNotifyDetail',
    });
  const errorNetWork = () => {
    messageErrorNetWork
      .open({
        type: 'error',
        content:
          '問題が発生しました。インターネット接続を確認して、もう一度やり直してください。',
      })
      .then();
  };
  const handleCancel = () => {
    if (isOnline) {
      handleConfirmAction({
        confirmFn: navigate,
        params: `/top-page`,
        title: '変更をキャンセルしてもよろしいでしょうか？',
        okText: 'はい',
        cancelText: 'キャンセル',
        setIsDisable: setIsCancel,
      });
      setIsCancel(true);
    } else {
      errorNetWork();
    }
  };

  // create tree data
  const treeDataFormated = useMemo(() => {
    if (isLoading === false && topPageNotifyDetail) {
      const listCategory = topPageNotifyDetail?.data?.listCategory;
      const listItemTree = [];
      let categoryOrderId = 0;
      listCategory.forEach((element) => {
        const listProduct = element?.listProduct;
        const listItemTreeProduct = [];

        let productOrderId = 0;
        listProduct?.forEach((product) => {
          listItemTreeProduct.push({
            value: product.productId,
            label: product.productName,
            isRoot: false,
            isParent: false,
            menuId: product.productUuid,
            parentUuid: element.categoryUuid,
            menuUuid: product.productUuid,
            menuName: product.productName,
            orderId: productOrderId,
            isDrag: false,
            children: [],
          });
          productOrderId++;
        });

        listItemTree.push({
          value: element.categoryId,
          label: element.title,
          isRoot: true,
          isParent: true,
          parentUuid: '0',
          menuId: element.categoryUuid,
          menuUuid: element.categoryUuid,
          menuName: element.title,
          orderId: categoryOrderId,
          isDrag: true,
          children: listItemTreeProduct,
        });
        categoryOrderId++;
      });
      return listItemTree;
    }
    return defaultData;
  }, [isLoading, topPageNotifyDetail]);

  useEffect(() => {
    if (isLoading === false && topPageNotifyDetail) {
      setDataSource(() => {
        return topPageNotifyDetail?.data?.listFullData;
      });
    }
    if (isLoading === false && treeDataFormated) {
      setTreeData({
        ...defaultData,
        children: treeDataFormated,
      });
    }
  }, [isLoading, topPageNotifyDetail, treeDataFormated]);

  const handleUpdate = () => {
    if (isOnline) {
      const listTreeDataCategory = [];
      const listChildren = treeData?.children;
      let countOrderIdCategory = 0;
      listChildren?.forEach((category) => {
        countOrderIdCategory++;
        const listTreeDataProduct = [];
        let countOrderIdProduct = 0;
        category?.children?.forEach((product) => {
          countOrderIdProduct++;
          listTreeDataProduct.push({
            productId: product.value,
            productOrderId: countOrderIdProduct,
          });
        });

        listTreeDataCategory.push({
          categoryId: category.value,
          categoryOrderId: countOrderIdCategory,
          listProduct: listTreeDataProduct,
        });
      });

      setEditDataSource({
        listNotify: dataSource,
        listCategory: listTreeDataCategory,
      });

      let listNotify = [];
      dataSource?.forEach((notify) => {
        if (notify && notify.isPinned !== true) {
          notify.notifyOrderId = 0;
        }
        listNotify.push(notify);
      });

      updateTopPagePositionMutation({
        payload: {
          listNotify: listNotify,
          listCategory: listTreeDataCategory,
        },
      });
    } else {
      errorNetWork();
    }
  };

  const dataSourcePinned = useMemo(() => {
    if (dataSource && dataSource !== [] && dataSource !== undefined) {
      const dataPinned = dataSource
        .filter((item) => item.isPinned)
        .sort((o1, o2) =>
          o1.notifyOrderId > o2.notifyOrderId
            ? 1
            : o1.notifyOrderId < o2.notifyOrderId
              ? -1
              : 0,
        );
      if (dataPinned?.length >= 2) {
        setIsDisabledPin(true);
      } else {
        setIsDisabledPin(false);
      }
      return dataPinned;
    }
  }, [dataSource, setIsDisabledPin]);

  const { refetch } = useQueryAPI({
    api: downloadNotifyFile,
    params: {
      notifyId: notifyId,
    },
    enabled: !!notifyId,
  });

  const handleToggleTable = (type) => {
    switch (type) {
      case 'pin-table':
        setShowPinTable(!showPinTable);
        break;
      case 'newest-table':
        setShowNewestTable(!showNewestTable);
        break;
      case 'notify-table':
        setShowAnnouncements(!showAnnouncements);
        break;
      default:
        break;
    }
  };

  return (
    <Spin spinning={isLoading || isUpdateLoading}>
      {contextErrorNetWork}
      {contextHolder}
      <Breadcrumb listItem={listItemTopPage} titlePage="製品一覧">
        <div
          style={{
            display: 'flex',
          }}
        >
          <ButtonPrimary
            text={'保存'}
            width={'79px'}
            marginRight={'8px'}
            onClick={handleUpdate}
          />
          <ButtonOutline
            text={'キャンセル'}
            width={'123px'}
            isDisabled={isCancel}
            onClick={handleCancel}
          />
        </div>
      </Breadcrumb>
      {errorMessage && (
        <LayoutTopPage>
          <div>
            <Alert
              message={errorMessage}
              type="error"
              style={{ margin: '12px 0' }}
            />
          </div>
        </LayoutTopPage>
      )}
      <LayoutTopPage>
        <HeaderTopPageCollapse
          titleScreen={'お知らせトップ'}
          handleToggleTable={handleToggleTable}
          type={'pin-table'}
          collapse={true}
        />
        {/* Pin table */}
        {showPinTable && (
          <PinnedTable
            errorNetWork={errorNetWork}
            isLoading={isLoading}
            dataSourcePinned={dataSourcePinned}
            setDataSource={setDataSource}
            setIsDisabledPin={setIsDisabledPin}
            refetch={refetch}
            setNotifyId={setNotifyId}
            productId={productId}
          />
        )}
        {/* Pin table */}
      </LayoutTopPage>
      <LayoutTopPage>
        <HeaderTopPageCollapse
          titleScreen={'最新なお知らせ'}
          handleToggleTable={handleToggleTable}
          type={'newest-table'}
          collapse={true}
        />
        {showNewestTable && (
          <NewestTable
            errorNetWork={errorNetWork}
            isLoading={isLoading}
            dataSource={dataSource}
            setDataSource={setDataSource}
            emptyText={emptyText}
            isDisabledPin={isDisabledPin}
            refetch={refetch}
            setNotifyId={setNotifyId}
            productId={productId}
          />
        )}
      </LayoutTopPage>
      <LayoutTopPage>
        <HeaderTopPageCollapse
          titleScreen={'他のお知らせ'}
          handleToggleTable={handleToggleTable}
          type={'notify-table'}
          collapse={true}
        />
        {showAnnouncements && (
          <OtherNotifyTable
            errorNetWork={errorNetWork}
            isLoading={isLoading}
            dataSource={dataSource}
            setDataSource={setDataSource}
            emptyText={emptyText}
            isDisabledPin={isDisabledPin}
            refetch={refetch}
            setNotifyId={setNotifyId}
            productId={productId}
          />
        )}
      </LayoutTopPage>
      <LayoutTopPage>
        {treeData.children.length > 0 && (
          <>
            <HeaderTopPageCollapse
              titleScreen={'製品一覧'}
              handleToggleTable={handleToggleTable}
              collapse={false}
            />
            <div style={{ marginTop: 12 }}></div>
            <TreeWrapper>
              <Tree
                treeData={treeData}
                setTreeData={setTreeData}
                isLoading={isLoading}
                width={546}
                masterDoomWidth={618}
                showDropdown={false}
              />
            </TreeWrapper>
          </>
        )}
      </LayoutTopPage>
    </Spin>
  );
};

export default DetailContentEditMode;
