import React, { useMemo } from 'react';
import { useRecoilValue } from 'recoil';
import { userInformation } from 'recoil/atom/authentication';
import MyPagePartnerUser from 'page/Application/MyPage/MyPageOverview/MyPagePartnerUser';
import MyPageSolitonUser from 'page/Application/MyPage/MyPageOverview/MyPageSolitonUser';
import useSetTitlePage from 'hook/useSetTitlePage';

const MyPageOverview = () => {
  const { role } = useRecoilValue(userInformation);
  useSetTitlePage('マイページ');

  const CheckRole = useMemo(() => {
    return {
      ROLE_PARTNER_USER: <MyPagePartnerUser />,
      ROLE_SOLITON_USER: <MyPageSolitonUser />,
    };
  }, []);

  return <>{CheckRole[role]}</>;
};

export default MyPageOverview;
