import { useIntl } from 'react-intl';

const useDestinationTypeMultiLanguage = () => {
  const intl = useIntl();

  const NOTIFICATION_DESTINATION_TYPE_NO_DETAILS = intl.formatMessage({
    id: 'destinationType.notification.noDetails',
  });

  const NOTIFICATION_DESTINATION_TYPE_DETAILS_AVAILABLE = intl.formatMessage({
    id: 'destinationType.notification.detailsAvailable',
  });

  const NOTIFICATION_DESTINATION_TYPE_INTERNAL_LINK = intl.formatMessage({
    id: 'destinationType.notification.internalLink',
  });

  const NOTIFICATION_DESTINATION_TYPE_EXTERNAL_LINK = intl.formatMessage({
    id: 'destinationType.notification.externalLink',
  });

  const NOTIFICATION_DESTINATION_TYPE_PDF = intl.formatMessage({
    id: 'destinationType.notification.pdf',
  });

  const NOTIFICATION_DESTINATION_TYPE = [
    {
      label: NOTIFICATION_DESTINATION_TYPE_NO_DETAILS,
      value: 'NO_DETAILS',
    },
    {
      label: NOTIFICATION_DESTINATION_TYPE_DETAILS_AVAILABLE,
      value: 'DETAILS_AVAILABLE',
    },
    {
      label: NOTIFICATION_DESTINATION_TYPE_INTERNAL_LINK,
      value: 'INTERNAL_LINKS',
    },
    {
      label: NOTIFICATION_DESTINATION_TYPE_EXTERNAL_LINK,
      value: 'EXTERNAL_LINKS',
    },
    {
      label: NOTIFICATION_DESTINATION_TYPE_PDF,
      value: 'PDF',
    },
  ];

  return {
    NOTIFICATION_DESTINATION_TYPE,
  };
};

export default useDestinationTypeMultiLanguage;
