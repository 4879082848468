// 400 ERROR MESSAGE
const INVALID_TOKEN = "400_INVALID_TOKEN";
const EXPIRED_TOKEN = "400_EXPIRED_TOKEN";
const INVALID_JWT_TOKEN = '400_INVALID_JWT_TOKEN'
const JWT_TOKEN_IS_EXPIRED = "400_JWT_TOKEN_IS_EXPIRED";
const ACCOUNT_HAS_ALREADY_LOGGED_IN = "400_ACCOUNT_HAS_ALREADY_LOGGED_IN";
const NAME_SMALLER_THAN_3_CHARACTERS = "400_NAME_SMALLER_THAN_3_CHARACTERS";
const VALIDATION_ERROR = "400_VALIDATION_ERROR";
const USER_NOT_APPROVED = "400_USER_NOT_APPROVED";
const USER_INACTIVE_USER = '400_USER_INACTIVE_USER'
const EMAIL_NOT_VERIFIED = '400_EMAIL_NOT_VERIFIED'
const NEW_PASSWORD_MUST_DIFFERENT_FROM_OLD_PASSWORD = '400_NEW_PASSWORD_MUST_DIFFERENT_FROM_OLD_PASSWORD'

const EMAIL_NOT_VERIFIED_401 = '401_EMAIL_NOT_VERIFIED'

const EMAIL_NOT_FOUND = '401_EMAIL_NOT_FOUND'

const WRONG_USERNAME_PASSWORD = '401_WRONG_USERNAME_PASSWORD'

const WRONG_TYPE_OF_AUTHENTICATION = '401_WRONG_TYPE_OF_AUTHENTICATION'

const CAN_NOT_UPDATE_ACTIVE_STATUS = '401_CAN_NOT_UPDATE_ACTIVE_STATUS'

const EMAIL_ALREADY_EXISTED = '401_EMAIL_ALREADY_EXISTED'

const DOMAIN_NOT_REGISTERED = '401_DOMAIN_NOT_REGISTERED'

const UNAUTHORIZED = '401_UNAUTHORIZED'

const ROLE_HAS_BEEN_CHANGED = '401_ROLE_HAS_BEEN_CHANGED'

const EMAIL_NOT_FOUND_404 = '404_EMAIL_NOT_FOUND'

export {
  INVALID_TOKEN,
  EXPIRED_TOKEN,
  JWT_TOKEN_IS_EXPIRED,
  ACCOUNT_HAS_ALREADY_LOGGED_IN,
  NAME_SMALLER_THAN_3_CHARACTERS,
  VALIDATION_ERROR,
  USER_NOT_APPROVED,
  USER_INACTIVE_USER,
  EMAIL_NOT_VERIFIED,
  NEW_PASSWORD_MUST_DIFFERENT_FROM_OLD_PASSWORD,
  EMAIL_NOT_VERIFIED_401,
  EMAIL_NOT_FOUND,
  WRONG_USERNAME_PASSWORD,
  WRONG_TYPE_OF_AUTHENTICATION,
  CAN_NOT_UPDATE_ACTIVE_STATUS,
  EMAIL_ALREADY_EXISTED,
  DOMAIN_NOT_REGISTERED,
  UNAUTHORIZED,
  ROLE_HAS_BEEN_CHANGED,
  EMAIL_NOT_FOUND_404,
  INVALID_JWT_TOKEN

};
