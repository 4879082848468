import React, { createContext, useContext, useState } from 'react';

const ProductContext = createContext(null);

export const ProductProvider = ({ children }) => {
  const [currentProduct, setCurrentProduct] = useState(null);
  return (
    <ProductContext.Provider
      value={{
        currentProduct,
        setCurrentProduct,
      }}
    >
      {children}
    </ProductContext.Provider>
  );
};

export const useProduct = () => {
  return useContext(ProductContext);
};
