import styled from 'styled-components';

export const CKEditorWrapper = styled.div`
  .ck-content {
    font-size: 14px;
    height: 200px;
    font-family: 'inter-regular', sans-serif;
  }

  .ck.ck-content ul {
    padding-left: 40px;
  }

  .ck.ck-content ol {
    padding-left: 40px;
  }

  .ck-editor__editable_inline {
    padding: 0 10px !important;
  }
`;
