import React from 'react';
import { ThumbnailStyled } from 'page/Application/ProjectRegistration/component/Thumbnail/styled';
import isPropValid from '@emotion/is-prop-valid';
import { StyleSheetManager } from 'styled-components';

const Thumbnail = ({ imageUrl }) => {
  // This implements the default behavior from styled-components v5
  const shouldForwardProp = (propName, target) => {
    if (typeof target === 'string') {
      // For HTML elements, forward the prop if it is a valid HTML attribute
      return isPropValid(propName);
    }
    // For other elements, forward all props
    return true;
  };

  return (
    <StyleSheetManager shouldForwardProp={shouldForwardProp}>
      <ThumbnailStyled image={imageUrl} />
    </StyleSheetManager>
  );
};

export default Thumbnail;
