import React, { useEffect, useMemo, useState } from 'react';
import { FilterOutlined, RedoOutlined } from '@ant-design/icons';
import { Link, useNavigate } from 'react-router-dom';
import { Form, message } from 'antd';
import { SORT_DIRECTION } from 'constant/sortConstants';
import { getListProject } from 'api/project/solitonAdmin.api';
import {
  ButtonBreadcrumb,
  ButtonTextIcon,
  LineVertical,
} from 'components/Button';
import Breadcrumb from 'components/Layout/ApplicationLayout/Breadcrumb';
import HeaderListDataLayout from 'components/Layout/HeaderListDataLayout';
import LayoutDefault from 'components/Layout/LayoutDefault';
import Pagination from 'components/Pagination';
import useCustomQuery from 'hook/useQuery/useCustomQuery';
import {
  ModalFilter,
  TableProjectList,
} from 'page/Application/ManageProjectRegistration/ProjectList/component';
import { useRecoilState, useRecoilValue, useResetRecoilState } from 'recoil';
import { filterProject } from 'recoil/atom/filterProject';
import { fileDetailsNew, listFileDetailsRecoil } from 'recoil/atom/fileRequest';
import {
  defaultPageSizeOptions,
  defaultSelectPageSizeOption,
} from 'constant/Pagination/pageSizeOption';
import { network } from 'recoil/atom/network';
import { projectUrl } from 'recoil/atom/projectUrl';
import { InteractionIcon } from '../styled';
import { IconWrapper } from 'components/Icon/styled';

const initFilterData = {
  keyword: '',
  status: '',
};
const ProjectList = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [sortedInfo, setSortedInfo] = useState({});
  const [current, setCurrent] = useState(1);
  const [page, setPage] = useState(10);
  const [resetFormStatus, setResetFormStatus] = useState(false);
  const [filterData, setFilterData] = useState(initFilterData);

  const [form] = Form.useForm();
  const [messageErrorNetWork, contextErrorNetWork] = message.useMessage();
  const navigate = useNavigate();

  const { useQueryAPI } = useCustomQuery();

  const isOnline = useRecoilValue(network);
  const [filterProjectValues] = useRecoilState(filterProject);
  const resetFilterProject = useResetRecoilState(filterProject);
  const resetProjectUrlRecoil = useResetRecoilState(projectUrl);
  const resetFileDetailList = useResetRecoilState(fileDetailsNew);
  const resetListFileDetailList = useResetRecoilState(listFileDetailsRecoil);

  const { data: projectList = [], isLoading } = useQueryAPI({
    api: getListProject,
    key: 'projectList',
    params: {
      pageNumber: current,
      pageSize: page,
      keyword: encodeURIComponent(filterData.keyword),
      status: filterData.status,
      displayedDateFrom: '',
      displayedDateTo: '',
      category: '',
      sortField: sortedInfo?.sortField ?? '',
      sortDirection: sortedInfo?.sortDirection ?? '',
      isViewMode: false,
    },
  });

  const listItem = useMemo(() => {
    return [
      {
        title: <Link to={'/'}>企業管理</Link>,
      },
      {
        title: '案件登録お申込み管理',
      },
    ];
  }, []);

  const errorNetWork = () => {
    messageErrorNetWork
      .open({
        type: 'error',
        content:
          '問題が発生しました。インターネット接続を確認して、もう一度やり直してください。',
      })
      .then();
  };
  const handleMoveRegisterPage = () => {
    if (isOnline) {
      resetFileDetailList();
      resetListFileDetailList();
      resetProjectUrlRecoil();
      navigate('/manage-project-registration/register');
    } else {
      errorNetWork();
    }
  };

  const handleMoveEditMode = () => {
    if (isOnline) {
      resetFileDetailList();
      resetListFileDetailList();
      navigate('/manage-project-registration/editmode');
    } else {
      errorNetWork();
    }
  };

  const handleChangePageSize = (pageSize) => {
    if (isOnline) {
      setCurrent(1);
      setPage(pageSize);
    } else {
      errorNetWork();
    }
  };

  const handleResetData = () => {
    if (isOnline) {
      setPage(10);
      setCurrent(1);
      setSortedInfo({});
      setFilterData(initFilterData);
      setResetFormStatus(true);
      resetFilterProject();
    } else {
      errorNetWork();
    }
  };
  const handlePagingChange = (pageNumber, pageSize) => {
    if (isOnline) {
      setCurrent(pageNumber);
      setPage(pageSize);
    } else {
      errorNetWork();
    }
  };

  const handleOpenModalFilter = () => {
    if (isOnline) {
      form.setFieldsValue({
        title: filterProjectValues?.keyword,
        status: filterProjectValues?.status,
      });
      setIsModalOpen(true);
    } else {
      errorNetWork();
    }
  };

  useEffect(() => {
    if (filterData.keyword || filterData.status) {
      setCurrent(1);
    }
  }, [filterData]);

  const handleChange = (pagination, filters, sorter) => {
    if (isOnline) {
      setSortedInfo({
        sortField: sorter.order && sorter.field,
        sortDirection: sorter.order && SORT_DIRECTION[sorter.order],
        order: sorter.order,
        columnKey: sorter.columnKey,
      });
    } else {
      errorNetWork();
    }
  };

  return (
    <>
      {contextErrorNetWork}
      <ModalFilter
        width={640}
        isModalOpen={isModalOpen}
        setFilterData={setFilterData}
        setIsModalOpen={setIsModalOpen}
        setCurrent={setCurrent}
        isOnline={isOnline}
        handleResetData={handleResetData}
        setResetFormStatus={setResetFormStatus}
        resetFormStatus={resetFormStatus}
        form={form}
        errorNetWork={errorNetWork}
      />
      <Breadcrumb listItem={listItem} titlePage={'案件登録お申込み管理'}>
        <ButtonBreadcrumb
          text={'並べ替え'}
          marginRight={'8px'}
          onClick={handleMoveEditMode}
          icon={<InteractionIcon></InteractionIcon>}
          color={'#656464'}
        />
        <ButtonBreadcrumb text={'新規登録'} onClick={handleMoveRegisterPage} />
      </Breadcrumb>
      <LayoutDefault>
        <HeaderListDataLayout
          titleScreen={'案件登録お申込み管理'}
          loading={isLoading}
          value={page}
          handleChangePageSize={handleChangePageSize}
          isHidden={true}
          pageSizeOptions={defaultSelectPageSizeOption}
        >
          <ButtonTextIcon
            text={'絞り込み検索'}
            icon={
              <IconWrapper>
                <FilterOutlined style={{ color: '#9d1c49' }} />
              </IconWrapper>
            }
            onClick={handleOpenModalFilter}
          />
          <LineVertical />
          <ButtonTextIcon
            text={'リフレッシュ'}
            icon={<RedoOutlined />}
            onClick={handleResetData}
          />
        </HeaderListDataLayout>
        <TableProjectList
          dataRaw={projectList?.data}
          isLoading={isLoading}
          sortedInfo={sortedInfo}
          onChange={handleChange}
        />
        <Pagination
          currentPage={current}
          handlePagingChange={handlePagingChange}
          total={projectList?.data?.total}
          pageSize={page}
          totalTitle={'案件登録お申込み総数'}
          pageSizeOptions={defaultPageSizeOptions}
          handleChangePageSize={handleChangePageSize}
        />
      </LayoutDefault>
    </>
  );
};

export default ProjectList;
