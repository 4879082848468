import axiosClient from 'api/config/axiosClient';

const getListCategory = ({
  pageNumber,
  pageSize,
  keyword,
  displayedDateFrom,
  displayedDateTo,
  category,
  status,
  sortField,
  sortDirection,
}) => {
  const url = `v1/category?pageNumber=${pageNumber}&pageSize=${pageSize}&keyword=${encodeURIComponent(keyword)}&displayedDateFrom=${displayedDateFrom}&displayedDateTo=${displayedDateTo}&category=${category}&status=${status}&sortField=${sortField}&sortDirection=${sortDirection}`;
  return axiosClient.get(url);
};

const registerCategory = ({ title, isDisplayed }) => {
  const url = '/v1/category';
  return axiosClient.post(url, { title, isDisplayed });
};

const getDetailCategory = ({ categoryId }) => {
  const url = `v1/category/${categoryId}`;
  return axiosClient.get(url);
};

const deleteCategory = ({ categoryId }) => {
  const url = `v1/category/${categoryId}`;
  return axiosClient.delete(url);
};

const updateCategory = ({ categoryId, payload }) => {
  const url = `v1/category/${categoryId}`;
  return axiosClient.put(url, payload);
};

const checkCategoryExistsProduct = ({ categoryId }) => {
  const url = `v1/category/checkProduct/${categoryId}`;
  return axiosClient.get(url);
};

const getListCategorySelectBox = () => {
  const url = `v1/category/getList`;
  return axiosClient.get(url);
};

const getDetailProduct = ({ productId }) => {
  const url = `v1/product/${productId}`;
  return axiosClient.get(url);
};

export {
  getListCategory,
  registerCategory,
  getDetailCategory,
  deleteCategory,
  updateCategory,
  checkCategoryExistsProduct,
  getListCategorySelectBox,
  getDetailProduct,
};
