import styled from 'styled-components';

export const FormWrapper = styled.div`
  margin: 50px 0 42px 0;

  @media all and (min-width: 0px) and (max-width: 320px) {
    margin: 20px 0 12px 0;
  }
  @media all and (min-width: 320px) and (max-width: 430px) {
    margin: 20px 0 12px 0;
  }

  @media all and (min-width: 430px) and (max-width: 768px) {
    margin: 20px 0 12px 0;
  }
`;

export const GridForm = styled.div`
  display: grid;
  margin: 0 auto;
  column-gap: 12px;
  grid-template-columns: 160px 400px;
  width: 572px;

  @media all and (min-width: 0px) and (max-width: 320px) {
    grid-template-columns: 1fr 1fr;
    width: 268px;
  }

  @media all and (min-width: 320px) and (max-width: 430px) {
    grid-template-columns: 1fr 1fr;
    width: auto;
  }

  @media all and (min-width: 430px) and (max-width: 768px) {
    grid-template-columns: 160px 1fr;
    width: auto;
  }
`;
export const Grid1to2 = styled.div`
  grid-column: 1/2;
  @media all and (min-width: 0px) and (max-width: 320px) {
    grid-column: 1/3;
  }

  @media all and (min-width: 320px) and (max-width: 430px) {
    grid-column: 1/3;
  }

  @media all and (min-width: 430px) and (max-width: 768px) {
    grid-column: 1/3;
  }
`;

export const Grid2to3 = styled.div`
  grid-column: 2/3;
  @media all and (min-width: 0px) and (max-width: 320px) {
    grid-column: 1/3;
  }
  @media all and (min-width: 320px) and (max-width: 430px) {
    grid-column: 1/3;
  }
  @media all and (min-width: 430px) and (max-width: 768px) {
    grid-column: 1/3;
  }
`;

export const GridFormAccount = styled.div`
  display: grid;
  grid-template-columns: 160px 400px 1fr;
  column-gap: 15px;
  width: 975px;
  margin: 0 auto;

  @media all and (min-width: 0px) and (max-width: 320px) {
    grid-template-columns: 1fr;
    column-gap: unset;
    width: auto;
  }
  @media all and (min-width: 320px) and (max-width: 430px) {
    grid-template-columns: 1fr;
    column-gap: unset;
    width: auto;
  }
  @media all and (min-width: 431px) and (max-width: 768px) {
    grid-template-columns: 1fr;
    column-gap: unset;
    width: auto;
  }
  @media all and (min-width: 769px) and (max-width: 1024px) {
    width: 700px;
  }
  @media all and (min-width: 1025px) and (max-width: 1200px) {
    width: 600px;
  }
  @media all and (min-width: 1201px) and (max-width: 1440px) {
    width: 800px;
  }

  &.margin-top-20 {
    margin-top: 20px;
  }
`;

export const GridFormAccount1to2 = styled.div`
  grid-column: 1/2;

  @media all and (min-width: 0px) and (max-width: 320px) {
    grid-column: 1/2;
  }
  @media all and (min-width: 320px) and (max-width: 430px) {
    grid-column: 1/2;
  }
  @media all and (min-width: 430px) and (max-width: 768px) {
    grid-column: 1/2;
  }
`;

export const GridFormAccount2to3 = styled.div`
  grid-column: 2/3;

  @media all and (min-width: 0px) and (max-width: 320px) {
    grid-column: 1/2;
  }
  @media all and (min-width: 320px) and (max-width: 430px) {
    grid-column: 1/2;
  }
  @media all and (min-width: 430px) and (max-width: 768px) {
    grid-column: 1/2;
  }
`;

export const GridFormAccount2to4 = styled.div`
  grid-column: 2/4;

  @media all and (min-width: 0px) and (max-width: 320px) {
    grid-column: 1/2;
  }
  @media all and (min-width: 320px) and (max-width: 430px) {
    grid-column: 1/2;
  }
  @media all and (min-width: 430px) and (max-width: 768px) {
    grid-column: 1/2;
  }
`;

export const GridFormAccount3to4 = styled.div`
  grid-column: 3/4;
  height: 70px;
  @media all and (min-width: 0px) and (max-width: 320px) {
    grid-column: 1/2;
  }
  @media all and (min-width: 320px) and (max-width: 430px) {
    grid-column: 1/2;
  }
  @media all and (min-width: 430px) and (max-width: 768px) {
    grid-column: 1/2;
  }
`;
