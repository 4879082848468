import React from 'react';
import AntModalTitleFile2 from 'components/AntModalTitleFile2/index';
import { Container, FormWrapper } from 'components/Form/Template/styled';
import FormLabelGrid from 'page/Application/ManageProjectRegistration/component/FormLabelGrid';
import FormText from 'page/Application/ManageProjectRegistration/component/FormText/index';
import { Grid1, Grid2, GridForm } from './styled';
import FormTextURLRequired from 'page/Application/ManageProjectRegistration/component/FormTextURLRequired';

const ModalURL = ({
  isModalOpen,
  handleCancel,
  handleOk,
  isEditModal,
  titleUrl,
}) => {
  return (
    <AntModalTitleFile2
      title={isEditModal ? 'フォームURLの変更' : 'フォームURLの追加'}
      handleOk={handleOk}
      handleCancel={handleCancel}
      isModalOpen={isModalOpen}
      width={830}
      content={
        <FormWrapper>
          <Container>
            <GridForm>
              <Grid1>
                <FormLabelGrid
                  labelText={'タイトル'}
                  textAlign={'left'}
                  required={false}
                  marginBottom={8}
                />
                <FormText
                  placeHolder={'タイトル名を入力してください'}
                  name={'projectUrlTitle'}
                  required={false}
                  maxLength={256}
                />
              </Grid1>
              <Grid2>
                <FormLabelGrid
                  labelText={'リンク先'}
                  textAlign={'left'}
                  required={titleUrl || isEditModal}
                  marginBottom={8}
                />
                <FormTextURLRequired
                  placeHolder={'URL'}
                  name="projectUrl"
                  isEditModal={isEditModal}
                  required={isModalOpen}
                />
              </Grid2>
            </GridForm>
          </Container>
        </FormWrapper>
      }
    />
  );
};

export default ModalURL;
