import { useIntl } from 'react-intl';

const useErrorMessageMultiLanguage = () => {
  const intl = useIntl();

  const ERROR_EMAIL_ALREADY_EXISTED = intl.formatMessage({
    id: 'signUp.errorMessage.401_Email_Already_Existed',
  });

  const ERROR_DOMAIN_NOT_REGISTERED = intl.formatMessage({
    id: 'signUp.errorMessage.401_Domain_Not_Registered',
  });

  const ERROR_USER_NOT_APPROVED = intl.formatMessage({
    id: 'signUp.errorMessage.400_User_Not_Approved',
  });
  return {
    ERROR_EMAIL_ALREADY_EXISTED,
    ERROR_DOMAIN_NOT_REGISTERED,
    ERROR_USER_NOT_APPROVED,
  };
};

export default useErrorMessageMultiLanguage;