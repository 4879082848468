import styled from 'styled-components';

export const TitleStyle = styled.div`
  color: var(--PRIMARY-default);
`;
export const FooterModal = styled.div`
  font-family: 'inter-semibold', sans-serif;
  font-weight: 400;
  font-size: 14px;
  color: var(--CHECKBOX-default);
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
export const ButtonGroup = styled.div`
  button {
    span {
      font-size: 14px;
    }
  }
`;
