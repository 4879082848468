import React from 'react';
import { FormItem } from 'components/Form2';
import { InputPasswordRemoveIcon } from 'components/Form2/FormValidateStrongPassword/styled';
import useMultiLanguage from 'hook/useMultiLanguage';
import LabelForm from 'components/Form/LabelForm';
import FormMessage from 'components/Form/FormMessage';
import { CHECK_STRONG_PASSWORD } from 'constant/regex';
import { MAX_LENGTH_PASSWORD } from 'constant/inputMaxlength';

const FormValidateStrongPassword = ({
  maxLength,
  name,
  label,
  placeholder,
  displayIcon,
  message,
}) => {
  const {
    FORM_PASSWORD_PLACEHOLDER,
    FORM_PASSWORD_ERROR_MESSAGE_EMPTY,
    FORM_PASSWORD_ERROR_MESSAGE_VALIDATE_1,
    FORM_PASSWORD_ERROR_MESSAGE_VALIDATE_2,
  } = useMultiLanguage();

  return (
    <FormItem
      name={name ? name : 'password'}
      label={label ? <LabelForm text={label} /> : false}
      rules={[
        {
          required: true,
          message: (
            <FormMessage
              text1={message ? message : FORM_PASSWORD_ERROR_MESSAGE_EMPTY}
              marginBottom={'12px'}
            />
          ),
        },
        () => ({
          validator(_, value) {
            if (value?.match(CHECK_STRONG_PASSWORD) === null) {
              // reject
              return Promise.reject(
                <FormMessage
                  text1={FORM_PASSWORD_ERROR_MESSAGE_VALIDATE_1}
                  text2={FORM_PASSWORD_ERROR_MESSAGE_VALIDATE_2}
                  marginBottom={'12px'}
                />,
              );
            } else {
              // resolve
              return Promise.resolve();
            }
          },
        }),
      ]}
    >
      <InputPasswordRemoveIcon
        displayicon={displayIcon}
        placeholder={placeholder ? placeholder : FORM_PASSWORD_PLACEHOLDER}
        autoComplete="new-password"
        size="large"
        maxLength={maxLength ? maxLength : MAX_LENGTH_PASSWORD}
      />
    </FormItem>
  );
};

export default FormValidateStrongPassword;
