import styled from 'styled-components';
import { Dropdown } from 'antd';

export const DropDownStyled = styled(Dropdown)`
  width: 40px;
  height: 40px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  font-size: 20px;
  opacity: ${(props) => props.opacity};
  transition: 0.15s;
  transition-timing-function: ease-in;
  border-radius: 0 6px 6px 0;
  color: #505050;
`;
