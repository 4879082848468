import styled from 'styled-components';
import { Button } from 'antd';

export const ButtonStyle = styled(Button)`
  width: 120px;
  font-family: 'inter-regular', sans-serif;
  font-size: 14px !important;
  box-shadow: none !important;
  span {
    font-weight: 500;
  }
`;
