import styled from 'styled-components';
import { Button } from 'antd';
export const ButtonOutlineStyled = styled(Button)`
  border: 1px solid #656464;
  color: #656464;
  font-family: inter-regular, sans-serif;
  width: ${(props) => props.width || '120'}px;
  span {
    font-size: 14px;
  }
`;
