export const editorConfiguration = {
  title: {
    placeholder: 'Nhập tiêu đề bài viết',
  },
  placeholder: '',
  toolbar: {
    items: [
      'heading',
      'alignment',
      'fontColor',
      'bold',
      'italic',
      'underline',
      'bulletedList',
      'numberedList',
      'link',
      'sourceEditing',
    ],
  },
  language: 'en',
  image: {
    toolbar: [
      'imageTextAlternative',
      'toggleImageCaption',
      'imageStyle:inline',
      'imageStyle:block',
      'imageStyle:side',
      'linkImage',
    ],
  },
  // table: {
  //   contentToolbar: [
  //     'tableColumn',
  //     'tableRow',
  //     'mergeTableCells',
  //     'tableCellProperties',
  //     'tableProperties',
  //   ],
  // },
  mediaEmbed: {
    toolbar: ['MediaEmbed'],
    mediaEmbed: {
      elementName: 'iframe',
    },
  },
  link: {
    defaultProtocol: 'https://',
    addTargetToExternalLinks: true,
    decorators: [
      {
        mode: 'manual',
        label: 'Downloadable',
        attributes: {
          download: 'download',
        },
      },
      {
        openInNewTab: {
          mode: 'manual',
          label: 'Open in a new tab',
          attributes: {
            target: '_blank',
            rel: 'noopener noreferrer',
          },
        },
      },
    ],
  },
  htmlSupport: {
    allow: [
      {
        name: /.*/,
        attributes: true,
        classes: true,
        styles: true,
      },
    ],
  },
};
