import axiosClient from 'api/config/axiosClient';

const getDetailSolitonUser = ({ token }) => {
  const url = `v1/account/mypage`;
  return axiosClient.get(url, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

const updateSolitonUser = ({ payload }) => {
  const url = `v1/account/mypage`;
  return axiosClient.put(url, payload);
};

export { getDetailSolitonUser, updateSolitonUser };
