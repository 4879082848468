import { useIntl } from "react-intl";

const useMultiLanguageResetPassword = () => {
  const intl = useIntl();

  const RESET_PASSWORD = intl.formatMessage({
    id: 'authentication.signIn.emailAddress',
  });

  const URL_EXPIRE_12H = intl.formatMessage({
    id: 'changePassword.message.tokenResetPasswordExpire12h',
  });

  const URL_TIME_OUT = intl.formatMessage({
    id: 'changePassword.message.tokenResetPasswordTimeOut',
  });

  const CHANGE_PASSWORD_MESSAGE_SUCCESS = intl.formatMessage({
    id: 'changePassword.message.success',
  });
  const CHANGE_PASSWORD_TITLE = intl.formatMessage({
    id: 'changePassword.title',
  });

  return {
    RESET_PASSWORD,
    URL_EXPIRE_12H,
    URL_TIME_OUT,
    CHANGE_PASSWORD_MESSAGE_SUCCESS,
    CHANGE_PASSWORD_TITLE
  }
};

export default useMultiLanguageResetPassword;