import React from 'react';
import LabelFormMyPage from 'page/Application/MyPage/component/FormMyPage/LabelFormMyPage';
import { FormItem } from 'components/Form2';
import { InputStyled } from 'components/Form2/styled';
import {
  Grid1to2,
  Grid2to3,
  GridForm,
  GridFormAccount,
  GridFormAccount1to2,
  GridFormAccount2to3,
} from 'page/Application/MyPagePartnerAdmin/styled';

const AccountEmail = ({ disabled }) => {
  return (
    <GridFormAccount>
      <GridFormAccount1to2>
        <LabelFormMyPage labelText={'メールアドレス'} required={false} />
      </GridFormAccount1to2>
      <GridFormAccount2to3>
        <FormItem name={'email'}>
          <InputStyled disabled={disabled} />
        </FormItem>
      </GridFormAccount2to3>
    </GridFormAccount>
  );
};

export default AccountEmail;
