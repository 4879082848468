import React, { Suspense, useEffect, useMemo, useState } from 'react';
import Breadcrumb from 'components/Layout/ApplicationLayout/Breadcrumb';
import LayoutDefault from 'components/Layout/LayoutDefault';
import { Link, useNavigate } from 'react-router-dom';
import { getDetailMyPageCompanyInformation } from 'api/myPage/partnerAdmin.api';
import {
  AccessibleProduct,
  ButtonSwitchMode,
  CheckboxDomain,
  PartnerName,
} from 'page/Application/MyPagePartnerAdmin/component/Form';
import useCustomQuery from 'hook/useQuery/useCustomQuery';
import { Form, Spin } from 'antd';
import { FormWrapper } from 'page/Application/MyPagePartnerAdmin/styled';
import { FormTitle } from 'page/Application/ManageCompanyList/component/Form';

const CompanyInformationOverview = () => {
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const [domainSelected, setDomainSelected] = useState([]);
  const [, setOpenMode] = useState(false);

  const { useQueryWithoutParams } = useCustomQuery();
  const { data: CompanyInformationOverview = [], isLoading } =
    useQueryWithoutParams({
      api: getDetailMyPageCompanyInformation,
      key: 'CompanyInformationOverview',
    });
  useEffect(() => {
    if (CompanyInformationOverview?.data) {
      form.setFieldsValue(CompanyInformationOverview?.data);
      setDomainSelected(CompanyInformationOverview.data.lstDomainResponses);
    }
    if (CompanyInformationOverview?.data?.partnerEmail.length === 0) {
      setOpenMode(true);
    } else {
      setOpenMode(false);
    }
  }, [form, CompanyInformationOverview.data]);

  const listItem = useMemo(() => {
    return [
      {
        title: <Link to={'/'}>パートナー専用ページ</Link>,
      },
      {
        title: '企業情報',
      },
    ];
  }, []);

  const handleSwitchEditMode = () => {
    navigate(`/company-information/edit`);
  };

  return (
    <Suspense fallback={isLoading}>
      <Breadcrumb listItem={listItem} titlePage={'企業情報'} />
      <LayoutDefault>
        <FormTitle
          notice={'注意：'}
          title={'変更した内容は画面下部の「保存」ボタンを押すと保存されます。'}
          type={'text'}
          styles={{ color: '#9D1C49' }}
        />
        <Spin spinning={isLoading}>
          <FormWrapper>
            <Form
              size={'Large'}
              form={form}
              initialValues={CompanyInformationOverview?.data}
              disabled={true}
            >
              <PartnerName />
              <CheckboxDomain
                domainSelected={domainSelected}
                setDomainSelected={setDomainSelected}
              />
              <AccessibleProduct
                productData={
                  CompanyInformationOverview?.data?.lstProductResponses
                }
              />

              {/*<GridForm>*/}
              {/*  <Grid1to2>*/}
              {/*    <LabelFormMyPage labelText={'配信用メールアドレス'} />*/}
              {/*  </Grid1to2>*/}

              {/*  {openMode ? (*/}
              {/*    // show input disable if user don't have email*/}
              {/*    <Grid2to3>*/}
              {/*      <FormItem width={'100%'}>*/}
              {/*        <InputStyled maxLength={256} />*/}
              {/*      </FormItem>*/}
              {/*    </Grid2to3>*/}
              {/*  ) : (*/}
              {/*    // show list input email disable if user have > 1 emails*/}
              {/*    <ListEmail />*/}
              {/*  )}*/}
              {/*</GridForm>*/}
            </Form>
            <ButtonSwitchMode text="編集" onClick={handleSwitchEditMode} />
          </FormWrapper>
        </Spin>
      </LayoutDefault>
    </Suspense>
  );
};

export default CompanyInformationOverview;
