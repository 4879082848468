import { CKEditor } from '@ckeditor/ckeditor5-react';
import { editorConfiguration } from 'components/CKEditorBuilder/ckeditor.config';
import Editor from 'ckeditor5-custom-build/build/ckeditor';
import { CKEditorWrapper } from 'components/CKEditorBuilder/styled';
import React from 'react';

const CKEditorBuilder = ({ data, setData, setPlainText, isDisabled }) => {
  return (
    <CKEditorWrapper>
      <CKEditor
        editor={Editor}
        disabled={isDisabled}
        data={data ?? ''}
        config={editorConfiguration}
        onBlur={(event, editor) => {
          setData(editor.getData());
          setPlainText(editor.editing.view.getDomRoot().innerText);
        }}
      />
    </CKEditorWrapper>
  );
};

export default CKEditorBuilder;
