import { useIntl } from 'react-intl';

const useMultiLanguageEditProduct = () => {
  const intl = useIntl();

  const APPLICATION_PRODUCT_EDIT_TITLE = intl.formatMessage({
    id: 'application.product.edit.title',
  });

  const APPLICATION_PRODUCT_MESSAGE_EDIT_SUCCESS = intl.formatMessage({
    id: 'application.product.message.edit.success',
  });

  const APPLICATION_PRODUCT_MESSAGE_DELETE_SUCCESS = intl.formatMessage({
    id: 'application.product.message.delete.success',
  });

  return {
    APPLICATION_PRODUCT_EDIT_TITLE,
    APPLICATION_PRODUCT_MESSAGE_EDIT_SUCCESS,
    APPLICATION_PRODUCT_MESSAGE_DELETE_SUCCESS,
  };
};

export default useMultiLanguageEditProduct;
