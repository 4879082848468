import styled from 'styled-components';
import { Button } from 'antd';

export const BtnBaseStyled = styled(Button)`
  font-family: 'inter-regular', sans-serif;
  font-size: 16px !important;
  box-shadow: none !important;
  span {
    font-weight: 500;
  }
  padding: 7px 34px;
`;

export const BtnBreadcrumb = styled(BtnBaseStyled)`
  span {
    color: var(--BTN-TEXT-PRIMARY-default);
  }
`;
