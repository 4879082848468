import React, { useCallback, useMemo } from 'react';
import AntdTable from 'components/AntdTable';
import useModalConfirm from 'hook/useModalConfirm';
import { userInformation } from 'recoil/atom/authentication';
import { useRecoilValue } from 'recoil';
import { ButtonAction, GroupButtonAction } from 'style/styleComponent/styled';
import { DeleteOutlined, EditOutlined } from '@ant-design/icons';
import useMultiLanguageCompanyList from 'page/Application/ManageAccountSoliton/AccountSolitonList/hook/useMultiLanguageManageAccountSolitonList';
import { EDIT_ACCOUNT_PARTNER_ABSOLUTE_PATH } from 'constant/appPath';
import useMultiLanguageEditCompany from 'page/Application/ManageCompanyList/EditCompany/hook/useMultiLanguageEditCompany';
import useRoleMultiLanguage from 'hook/useRoleMultiLanguage';
import { useNavigate } from 'react-router-dom';
import { Tooltip } from 'antd';
import { TooltipHide } from 'page/Application/ManageCompanyList/CompanyList/styled';

const TablePartner = ({
  setCurrent,
  isLoading,
  dataRaw,
  handleDeleteUser,
  setItemDelete,
  listIdSelected,
  setListIdSelected,
  emptyText,
  onChange,
  isDisable,
  setIsDisable,
  setIdSelected,
  setSelectAllPage,
  setSelectAllData,
  sortedInfo,
  selectedRowKeys,
  setSelectedRowKeys,
}) => {
  const {
    ACCOUNT_LIST_TABLE_TITLE_ACCOUNT_NAME,
    ACCOUNT_LIST_TABLE_TITLE_EMAIL,
    ACCOUNT_LIST_TABLE_TITLE_ACCOUNT_TYPE,
    ACCOUNT_LIST_TABLE_TITLE_ACCOUNT_STATUS,
    ACCOUNT_LIST_TABLE_TITLE_STATUS_DELETE,
    ACCOUNT_LIST_TABLE_TITLE_ACTION,
    ACCOUNT_LIST_TABLE_STATUS_STATUS_VALID,
    ACCOUNT_LIST_TABLE_STATUS_STATUS_NON,
    ACCOUNT_LIST_TABLE_STATUS_STATUS_BANNED,
  } = useMultiLanguageCompanyList();
  const navigate = useNavigate();
  const { handleConfirmAction, contextHolder } = useModalConfirm();
  const { role } = useRecoilValue(userInformation);
  const { DETAIL_COMPANY_BUTTON_DELETE } = useMultiLanguageEditCompany();
  const { ROLE } = useRoleMultiLanguage();

  const handleEdit = useCallback(
    (userId) => {
      navigate(`${EDIT_ACCOUNT_PARTNER_ABSOLUTE_PATH}/${userId}`);
    },
    [navigate],
  );

  const columnSA = useMemo(
    () => [
      {
        key: 'fullName',
        title: (
          <Tooltip
            title={ACCOUNT_LIST_TABLE_TITLE_ACCOUNT_NAME}
            placement={'topLeft'}
          >
            {ACCOUNT_LIST_TABLE_TITLE_ACCOUNT_NAME}
          </Tooltip>
        ),
        dataIndex: 'fullName',
        align: 'left',
        ellipsis: true,
        showSorterTooltip: false,
        sorter: true,
        render: (fullName) => (
          <TooltipHide>
            <Tooltip title={fullName} placement={'topLeft'}>
              {fullName}
            </Tooltip>
          </TooltipHide>
        ),
        sortOrder:
          sortedInfo.columnKey === 'fullName' ? sortedInfo.order : null,
      },
      {
        key: 'partnerName',
        title: (
          <Tooltip title={'会社名'} placement={'topLeft'}>
            {'会社名'}
          </Tooltip>
        ),
        dataIndex: 'partnerName',
        align: 'left',
        ellipsis: true,
        showSorterTooltip: false,
        sorter: true,
        render: (partnerName) => (
          <TooltipHide>
            <Tooltip title={partnerName} placement={'topLeft'}>
              {partnerName}
            </Tooltip>
          </TooltipHide>
        ),
        sortOrder:
          sortedInfo.columnKey === 'partnerName' ? sortedInfo.order : null,
      },
      {
        key: 'email',
        title: (
          <Tooltip title={ACCOUNT_LIST_TABLE_TITLE_EMAIL} placement={'topLeft'}>
            {ACCOUNT_LIST_TABLE_TITLE_EMAIL}
          </Tooltip>
        ),
        dataIndex: 'email',
        width: '25%',
        align: 'left',
        ellipsis: true,
        showSorterTooltip: false,
        render: (email) => (
          <TooltipHide>
            <Tooltip title={email} placement={'topLeft'}>
              {email}
            </Tooltip>
          </TooltipHide>
        ),
        sorter: true,
        sortOrder: sortedInfo.columnKey === 'email' ? sortedInfo.order : null,
      },
      {
        key: 'role',
        title: (
          <Tooltip
            title={ACCOUNT_LIST_TABLE_TITLE_ACCOUNT_TYPE}
            placement={'topLeft'}
          >
            {ACCOUNT_LIST_TABLE_TITLE_ACCOUNT_TYPE}
          </Tooltip>
        ), // company name
        dataIndex: 'role',
        render: (text) => {
          return (
            <TooltipHide>
              <Tooltip title={ROLE[text]} placement={'topLeft'}>
                {ROLE[text]}
              </Tooltip>
            </TooltipHide>
          );
        },
        align: 'left',
        ellipsis: true,
        showSorterTooltip: false,
        sorter: true,
        sortOrder: sortedInfo.columnKey === 'role' ? sortedInfo.order : null,
      },
      {
        key: 'approveStatus',
        title: (
          <Tooltip
            title={ACCOUNT_LIST_TABLE_TITLE_ACCOUNT_STATUS}
            placement={'topLeft'}
          >
            {ACCOUNT_LIST_TABLE_TITLE_ACCOUNT_STATUS}
          </Tooltip>
        ), // approve status
        dataIndex: 'approveStatus',
        render: (text) => {
          if (text === 'ENABLED') {
            return (
              <TooltipHide>
                <Tooltip
                  title={ACCOUNT_LIST_TABLE_STATUS_STATUS_VALID}
                  placement={'topLeft'}
                >
                  {ACCOUNT_LIST_TABLE_STATUS_STATUS_VALID}
                </Tooltip>
              </TooltipHide>
            );
          } else if (text === 'SUSPENDED') {
            return (
              <TooltipHide>
                <Tooltip
                  title={ACCOUNT_LIST_TABLE_STATUS_STATUS_BANNED}
                  placement={'topLeft'}
                >
                  {ACCOUNT_LIST_TABLE_STATUS_STATUS_BANNED}
                </Tooltip>
              </TooltipHide>
            );
          } else {
            return (
              <TooltipHide>
                <Tooltip
                  title={ACCOUNT_LIST_TABLE_STATUS_STATUS_NON}
                  placement={'topLeft'}
                >
                  {ACCOUNT_LIST_TABLE_STATUS_STATUS_NON}
                </Tooltip>
              </TooltipHide>
            );
          }
        },
        align: 'left',
        ellipsis: true,
        showSorterTooltip: false,
        sorter: true,
        sortOrder:
          sortedInfo.columnKey === 'approveStatus' ? sortedInfo.order : null,
      },
      {
        key: 'userId',
        title: (
          <span style={{ paddingLeft: 16 }}>
            {ACCOUNT_LIST_TABLE_TITLE_ACTION}
          </span>
        ),
        dataIndex: 'userId',
        render: (userId, record) => {
          return (
            <GroupButtonAction>
              <ButtonAction type="link" onClick={() => handleEdit(userId)}>
                <EditOutlined />
              </ButtonAction>
              <ButtonAction
                type="link"
                disabled={isDisable}
                onClick={() => {
                  handleConfirmAction({
                    confirmFn: handleDeleteUser,
                    title:
                      record.fullName + ACCOUNT_LIST_TABLE_TITLE_STATUS_DELETE,
                    params: userId,
                    okText: <span> {DETAIL_COMPANY_BUTTON_DELETE}</span>,
                    setIsDisable: setIsDisable,
                  });
                  setItemDelete(record.fullName);
                  setIsDisable(true);
                }}
              >
                <DeleteOutlined />
              </ButtonAction>
            </GroupButtonAction>
          );
        },
        width: 120,
        align: 'left',
      },
    ],
    [
      ACCOUNT_LIST_TABLE_STATUS_STATUS_BANNED,
      ACCOUNT_LIST_TABLE_STATUS_STATUS_NON,
      ACCOUNT_LIST_TABLE_STATUS_STATUS_VALID,
      ACCOUNT_LIST_TABLE_TITLE_ACCOUNT_NAME,
      ACCOUNT_LIST_TABLE_TITLE_ACCOUNT_STATUS,
      ACCOUNT_LIST_TABLE_TITLE_ACCOUNT_TYPE,
      ACCOUNT_LIST_TABLE_TITLE_ACTION,
      ACCOUNT_LIST_TABLE_TITLE_EMAIL,
      ACCOUNT_LIST_TABLE_TITLE_STATUS_DELETE,
      DETAIL_COMPANY_BUTTON_DELETE,
      ROLE,
      handleConfirmAction,
      handleDeleteUser,
      handleEdit,
      isDisable,
      setIsDisable,
      setItemDelete,
      sortedInfo.columnKey,
      sortedInfo.order,
    ],
  );

  const columnPA = useMemo(
    () => [
      {
        key: 'fullName',
        title: (
          <Tooltip
            title={ACCOUNT_LIST_TABLE_TITLE_ACCOUNT_NAME}
            placement={'topLeft'}
          >
            {ACCOUNT_LIST_TABLE_TITLE_ACCOUNT_NAME}
          </Tooltip>
        ),
        dataIndex: 'fullName',
        align: 'left',
        ellipsis: true,
        sorter: true,
        showSorterTooltip: false,
        render: (fullName) => (
          <TooltipHide>
            <Tooltip title={fullName} placement={'topLeft'}>
              {fullName}
            </Tooltip>
          </TooltipHide>
        ),
        sortOrder:
          sortedInfo.columnKey === 'fullName' ? sortedInfo.order : null,
      },
      {
        key: 'email',
        title: (
          <Tooltip title={ACCOUNT_LIST_TABLE_TITLE_EMAIL} placement={'topLeft'}>
            {ACCOUNT_LIST_TABLE_TITLE_EMAIL}
          </Tooltip>
        ),
        dataIndex: 'email',
        width: '25%',
        align: 'left',
        ellipsis: true,
        showSorterTooltip: false,
        sorter: true,
        render: (fullName) => (
          <TooltipHide>
            <Tooltip title={fullName} placement={'topLeft'}>
              {fullName}
            </Tooltip>
          </TooltipHide>
        ),
        sortOrder: sortedInfo.columnKey === 'email' ? sortedInfo.order : null,
      },
      {
        key: 'role',
        title: (
          <Tooltip
            title={ACCOUNT_LIST_TABLE_TITLE_ACCOUNT_TYPE}
            placement={'topLeft'}
          >
            {ACCOUNT_LIST_TABLE_TITLE_ACCOUNT_TYPE}
          </Tooltip>
        ), // company name
        dataIndex: 'role',
        sorter: true,
        showSorterTooltip: false,
        render: (text) => {
          return (
            <TooltipHide>
              <Tooltip title={ROLE[text]} placement={'topLeft'}>
                {ROLE[text]}
              </Tooltip>
            </TooltipHide>
          );
        },
        align: 'left',
        ellipsis: true,
        sortOrder: sortedInfo.columnKey === 'role' ? sortedInfo.order : null,
      },
      {
        key: 'approveStatus',
        title: (
          <Tooltip
            title={ACCOUNT_LIST_TABLE_TITLE_ACCOUNT_STATUS}
            placement={'topLeft'}
          >
            {ACCOUNT_LIST_TABLE_TITLE_ACCOUNT_STATUS}
          </Tooltip>
        ), // approve status
        dataIndex: 'approveStatus',
        render: (text) => {
          if (text === 'ENABLED') {
            return (
              <TooltipHide>
                <Tooltip
                  title={ACCOUNT_LIST_TABLE_STATUS_STATUS_VALID}
                  placement={'topLeft'}
                >
                  {ACCOUNT_LIST_TABLE_STATUS_STATUS_VALID}
                </Tooltip>
              </TooltipHide>
            );
          } else if (text === 'SUSPENDED') {
            return (
              <TooltipHide>
                <Tooltip
                  title={ACCOUNT_LIST_TABLE_STATUS_STATUS_BANNED}
                  placement={'topLeft'}
                >
                  {ACCOUNT_LIST_TABLE_STATUS_STATUS_BANNED}
                </Tooltip>
              </TooltipHide>
            );
          } else {
            return (
              <TooltipHide>
                <Tooltip
                  title={ACCOUNT_LIST_TABLE_STATUS_STATUS_NON}
                  placement={'topLeft'}
                >
                  {ACCOUNT_LIST_TABLE_STATUS_STATUS_NON}
                </Tooltip>
              </TooltipHide>
            );
          }
        },
        align: 'left',
        ellipsis: true,
        sorter: true,
        showSorterTooltip: false,
        sortOrder:
          sortedInfo.columnKey === 'approveStatus' ? sortedInfo.order : null,
      },
      {
        key: 'userId',
        title: (
          <TooltipHide style={{ paddingLeft: 16 }}>
            {ACCOUNT_LIST_TABLE_TITLE_ACTION}
          </TooltipHide>
        ),
        dataIndex: 'userId',
        render: (userId, record) => {
          return (
            <GroupButtonAction>
              <ButtonAction type="link" onClick={() => handleEdit(userId)}>
                <EditOutlined />
              </ButtonAction>
              <ButtonAction
                type="link"
                disabled={isDisable}
                onClick={() => {
                  setIdSelected(userId);
                  handleConfirmAction({
                    confirmFn: handleDeleteUser,
                    title:
                      record.fullName + ACCOUNT_LIST_TABLE_TITLE_STATUS_DELETE,
                    params: userId,
                    okText: <span> {DETAIL_COMPANY_BUTTON_DELETE}</span>,
                    setIsDisable: setIsDisable,
                  });
                  setItemDelete(record.fullName);
                  setIsDisable(true);
                }}
              >
                <DeleteOutlined />
              </ButtonAction>
            </GroupButtonAction>
          );
        },
        width: 120,
        align: 'left',
      },
    ],
    [
      ACCOUNT_LIST_TABLE_STATUS_STATUS_BANNED,
      ACCOUNT_LIST_TABLE_STATUS_STATUS_NON,
      ACCOUNT_LIST_TABLE_STATUS_STATUS_VALID,
      ACCOUNT_LIST_TABLE_TITLE_ACCOUNT_NAME,
      ACCOUNT_LIST_TABLE_TITLE_ACCOUNT_STATUS,
      ACCOUNT_LIST_TABLE_TITLE_ACCOUNT_TYPE,
      ACCOUNT_LIST_TABLE_TITLE_ACTION,
      ACCOUNT_LIST_TABLE_TITLE_EMAIL,
      ACCOUNT_LIST_TABLE_TITLE_STATUS_DELETE,
      DETAIL_COMPANY_BUTTON_DELETE,
      ROLE,
      handleConfirmAction,
      handleDeleteUser,
      handleEdit,
      isDisable,
      setIdSelected,
      setIsDisable,
      setItemDelete,
      sortedInfo.columnKey,
      sortedInfo.order,
    ],
  );

  const RoleColumn = {
    ROLE_PARTNER_ADMIN: columnPA,
    ROLE_SOLITON_ADMIN: columnSA,
  };

  const dataSource = useMemo(() => {
    if (isLoading && !dataRaw) return [];
    if (dataRaw?.pages !== 0 && dataRaw?.pageNum - dataRaw?.pages === 1)
      return setCurrent(dataRaw?.pages);
    return dataRaw?.list?.map((item) => ({
      ...item,
      key: item.userId,
    }));
  }, [dataRaw, isLoading, setCurrent]);

  const onSelectChange = (newSelectedRowKeys) => {
    setSelectedRowKeys(newSelectedRowKeys);
  };
  // render checkbox, select list item for delete
  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
    onSelect: (record, selected, selectedRows) => {
      if (selectedRows.length === 0) {
        setSelectAllPage(false);
      } else {
        setSelectAllPage(true);
      }
      if (selected) {
        setSelectAllData(false);
        setListIdSelected((keys) => [...keys, record.key]);
      } else {
        setSelectAllData(false);
        setListIdSelected((keys) => {
          const index = keys.indexOf(record.key);
          return [...keys.slice(0, index), ...keys.slice(index + 1)];
        });
      }
    },
    onSelectAll: (selected, selectedRows, changeRows) => {
      setSelectAllPage(selected);

      if (selected) {
        let listIdSelected = changeRows?.map((item) => item?.userId);
        setListIdSelected((current) => [...current, ...listIdSelected]);
      } else {
        setSelectAllData(false);
        let listIdRemove = changeRows?.map((item) => item?.userId);
        const newData = listIdSelected.filter(
          (item) => !listIdRemove.includes(item),
        );
        setListIdSelected(newData);
      }
    },
    preserveSelectedRowKeys: true,
  };

  return (
    <>
      {contextHolder}
      {/*{selectAllPage && listIdSelected.length !== 0 && (*/}
      {/*  <SelectAllTable*/}
      {/*    dataRaw={dataRaw}*/}
      {/*    handleSelectAllRecord={handleSelectAllRecord}*/}
      {/*    // numberRowSelected={numberRowSelected}*/}
      {/*    selectAllData={selectAllData}*/}
      {/*    listIdSelected={listIdSelected}*/}
      {/*  />*/}
      {/*)}*/}
      <AntdTable
        height={532}
        columns={RoleColumn[role]}
        dataSource={dataSource}
        rowSelection={rowSelection}
        isLoading={isLoading}
        emptyText={emptyText}
        onChange={onChange}
      />
    </>
  );
};
export default TablePartner;
