import { atom } from 'recoil';

export const listPartner = atom({
    key: 'listPartner',
    default: {
        display: false,
        listIdPartner: [],
        listRecordPartner: [],
    },
});

export const listSelectPartner = atom ( {
    key: 'listSelectPartner',
    default: {
        display: false,
        listIdPartner: [],
        listRecordPartner: [],
    }
})