import styled from 'styled-components';
import { Select } from 'antd';

export const TitleScreen = styled.h2`
  font-family: 'inter-semibold', sans-serif;
  font-size: 14px;
  font-weight: 700;
  color: var(--FORM-LABEL-default);
  //padding: 10px 31px;

  //@media all and (min-width: 0px) and (max-width: 320px) {
  //  padding: 10px 15px;
  //}
  //@media all and (min-width: 321px) and (max-width: 430px) {
  //  padding: 10px 15px;
  //}
`;

export const HeaderTable = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  align-items: center;
  margin: 7px 0 5px 0;
  padding: 10px;
  background: #d9d9d94d;
  border-radius: 6px;
`;

export const SelectStyled = styled(Select)`
  height: 36px;
  width: 120px;
  @media all and (min-width: 0px) and (max-width: 320px) {
    width: 100%;
  }
  @media all and (min-width: 321px) and (max-width: 430px) {
    width: 100%;
  }
`;
