import React, { useMemo } from 'react';
import AntdTable from 'components/AntdTable';
import useModalConfirm from 'hook/useModalConfirm';
import useRoleMultiLanguage from 'hook/useRoleMultiLanguage';
import useMultiLanguageAccountSolitonList from 'page/Application/ManageAccountSoliton/AccountSolitonList/hook/useMultiLanguageManageAccountSolitonList';
import { useNavigate } from 'react-router-dom';
import { DeleteOutlined, EditOutlined } from '@ant-design/icons';
import { Table, Tooltip } from 'antd';
import { ButtonAction, GroupButtonAction } from 'style/styleComponent/styled';
import { TooltipHide } from 'page/Application/ManageCompanyList/CompanyList/styled';
import useErrorNetworkMessage from 'hook/useErrorNetworkMessage';

const TableData = ({
  setCurrent,
  dataRaw,
  isLoading,
  emptyText,
  setListItemDelete,
  handleDeleteUser,
  isDisable,
  setIsDisable,
  onChange,
  listIdSelected,
  setListIdSelected,
  setSelectAllData,
  setSelectAllPage,
  sortedInfo,
  selectedRowKeys,
  setSelectedRowKeys,
}) => {
  const {
    ACCOUNT_LIST_TABLE_TITLE_ACCOUNT_NAME,
    ACCOUNT_LIST_TABLE_TITLE_EMAIL,
    ACCOUNT_LIST_TABLE_TITLE_ACCOUNT_TYPE,
    ACCOUNT_LIST_TABLE_TITLE_ACCOUNT_STATUS,
    ACCOUNT_LIST_TABLE_TITLE_ACTION,
    ACCOUNT_LIST_TABLE_STATUS_STATUS_VALID,
    ACCOUNT_LIST_TABLE_STATUS_STATUS_BANNED,
    ACCOUNT_LIST_TABLE_TITLE_STATUS_DELETE,
    ACCOUNT_LIST_MULTI_DELETE,
  } = useMultiLanguageAccountSolitonList();
  const navigate = useNavigate();
  const { handleConfirmAction, contextHolder } = useModalConfirm();
  const { ROLE } = useRoleMultiLanguage();
  const { isOnline, errorNetWork, contextErrorNetWork } =
    useErrorNetworkMessage();
  const columns = useMemo(() => {
    const handleEdit = (id) => {
      if (isOnline) {
        navigate(`/manage-account-soliton/edit/${id}`);
      } else {
        errorNetWork();
      }
    };

    return [
      Table.SELECTION_COLUMN,
      {
        key: 'fullName',
        title: (
          <Tooltip
            title={ACCOUNT_LIST_TABLE_TITLE_ACCOUNT_NAME}
            placement={'topLeft'}
          >
            {ACCOUNT_LIST_TABLE_TITLE_ACCOUNT_NAME}
          </Tooltip>
        ),
        dataIndex: 'fullName',
        align: 'left',
        ellipsis: true,
        showSorterTooltip: false,
        sorter: true,
        render: (fullName) => (
          <TooltipHide>
            <Tooltip title={fullName} placement={'topLeft'}>
              {fullName}
            </Tooltip>
          </TooltipHide>
        ),
        sortOrder:
          sortedInfo.columnKey === 'fullName' ? sortedInfo.order : null,
      },
      {
        key: 'email',
        title: (
          <Tooltip title={ACCOUNT_LIST_TABLE_TITLE_EMAIL} placement={'topLeft'}>
            {ACCOUNT_LIST_TABLE_TITLE_EMAIL}
          </Tooltip>
        ),
        dataIndex: 'email',
        align: 'left',
        ellipsis: true,
        showSorterTooltip: false,
        sorter: true,
        render: (email) => (
          <TooltipHide>
            <Tooltip title={email} placement={'topLeft'}>
              {email}
            </Tooltip>
          </TooltipHide>
        ),
        sortOrder: sortedInfo.columnKey === 'email' ? sortedInfo.order : null,
      },
      {
        key: 'role',
        title: (
          <Tooltip
            title={ACCOUNT_LIST_TABLE_TITLE_ACCOUNT_TYPE}
            placement={'topLeft'}
          >
            {ACCOUNT_LIST_TABLE_TITLE_ACCOUNT_TYPE}
          </Tooltip>
        ),
        dataIndex: 'role',
        align: 'left',
        render: (text) => {
          return (
            <TooltipHide>
              <Tooltip title={ROLE[text]} placement={'topLeft'}>
                {ROLE[text]}
              </Tooltip>
            </TooltipHide>
          );
        },
        ellipsis: true,
        showSorterTooltip: false,
        sorter: true,
        sortOrder: sortedInfo.columnKey === 'role' ? sortedInfo.order : null,
      },
      {
        // According to the returned data. Change accountStatus to approveStatus for them to work
        key: 'approveStatus',
        title: (
          <Tooltip
            title={ACCOUNT_LIST_TABLE_TITLE_ACCOUNT_STATUS}
            placement={'topLeft'}
          >
            {ACCOUNT_LIST_TABLE_TITLE_ACCOUNT_STATUS}
          </Tooltip>
        ),
        dataIndex: 'approveStatus',
        showSorterTooltip: false,
        sorter: true,
        ellipsis: true,
        sortOrder:
          sortedInfo.columnKey === 'approveStatus' ? sortedInfo.order : null,
        render: (approveStatus) => {
          return (
            <TooltipHide>
              {approveStatus === 'ENABLED'
                ? ACCOUNT_LIST_TABLE_STATUS_STATUS_VALID
                : ACCOUNT_LIST_TABLE_STATUS_STATUS_BANNED}
            </TooltipHide>
          );
        },
        align: 'left',
      },
      {
        key: 'userId',
        title: (
          <TooltipHide style={{ paddingLeft: 22 }}>
            {ACCOUNT_LIST_TABLE_TITLE_ACTION}
          </TooltipHide>
        ),
        dataIndex: 'userId',
        render: (userId, record) => (
          <GroupButtonAction>
            <ButtonAction type="link" onClick={() => handleEdit(userId)}>
              <EditOutlined />
            </ButtonAction>
            <ButtonAction
              type="link"
              disabled={isDisable}
              onClick={() => {
                if (isOnline) {
                  handleConfirmAction({
                    confirmFn: handleDeleteUser,
                    title:
                      record.fullName + ACCOUNT_LIST_TABLE_TITLE_STATUS_DELETE,
                    params: userId,
                    okText: <span> {ACCOUNT_LIST_MULTI_DELETE}</span>,
                    setIsDisable: setIsDisable,
                  });
                  setListItemDelete(record.fullName);
                  setIsDisable(true);
                } else {
                  errorNetWork();
                }
              }}
            >
              <DeleteOutlined />
            </ButtonAction>
          </GroupButtonAction>
        ),
        align: 'left',
        width: 120,
      },
    ];
  }, [
    ACCOUNT_LIST_TABLE_TITLE_ACCOUNT_NAME,
    ACCOUNT_LIST_TABLE_TITLE_EMAIL,
    ACCOUNT_LIST_TABLE_TITLE_ACCOUNT_TYPE,
    ACCOUNT_LIST_TABLE_TITLE_ACCOUNT_STATUS,
    ACCOUNT_LIST_TABLE_TITLE_ACTION,
    navigate,
    ROLE,
    ACCOUNT_LIST_TABLE_STATUS_STATUS_VALID,
    ACCOUNT_LIST_TABLE_STATUS_STATUS_BANNED,
    isDisable,
    handleConfirmAction,
    handleDeleteUser,
    ACCOUNT_LIST_TABLE_TITLE_STATUS_DELETE,
    ACCOUNT_LIST_MULTI_DELETE,
    setIsDisable,
    setListItemDelete,
    errorNetWork,
    isOnline,
    sortedInfo.columnKey,
    sortedInfo.order
  ]);

  const dataSource = useMemo(() => {
    if (isLoading && !dataRaw) return [];
    if (dataRaw.pages !== 0 && dataRaw.pageNum - dataRaw.pages === 1)
      return setCurrent(dataRaw.pages);
    return dataRaw?.list.map((item) => ({
      ...item,
      key: item.userId,
    }));
  }, [dataRaw, isLoading, setCurrent]);
  const onSelectChange = (newSelectedRowKeys) => {
    setSelectedRowKeys(newSelectedRowKeys);
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,

    onSelect: (record, selected, selectedRows) => {
      if (selectedRows.length === 0) {
        setSelectAllPage(false);
      } else {
        setSelectAllPage(true);
      }
      if (selected) {
        setSelectAllData(false);
        setListIdSelected((keys) => [...keys, record.key]);
      } else {
        setSelectAllData(false);
        setListIdSelected((keys) => {
          const index = keys.indexOf(record.key);
          return [...keys.slice(0, index), ...keys.slice(index + 1)];
        });
      }
    },

    onSelectAll: (selected, selectedRows, changeRows) => {
      setSelectAllPage(selected);

      if (selected) {
        let listIdSelected = changeRows?.map((item) => item?.userId);
        setListIdSelected((current) => [...current, ...listIdSelected]);
      } else {
        setSelectAllData(false);
        let listIdRemove = changeRows?.map((item) => item?.userId);
        const newData = listIdSelected.filter(
          (item) => !listIdRemove.includes(item),
        );
        setListIdSelected(newData);
      }
    },
    preserveSelectedRowKeys: true,
  };

  return (
    <>
      {contextHolder}
      {contextErrorNetWork}
      <AntdTable
        height={532}
        columns={columns}
        rowSelection={rowSelection}
        isLoading={isLoading}
        emptyText={emptyText}
        dataSource={dataSource}
        onChange={onChange}
      />
    </>
  );
};

export default TableData;
