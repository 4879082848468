import React, { useEffect, useMemo } from 'react';
import { listPartner, listSelectPartner } from 'recoil/atom/selectPartner';
import { useRecoilState } from 'recoil';
import useMultiLanguageRegisterAccount from 'page/Application/ManageAccountSoliton/RegisterAccountSoliton/hook/useMultiLanguageRegisterAccount';
import AntdTableModal from 'components/AntdTableModal';
import { Tooltip } from 'antd';

const TableListPartner = ({ dataRaw, isLoading, emptyText }) => {
  const [listSelectedPartner] = useRecoilState(listPartner);
  const [newListSelectedPartner, setNewListSelectedPartner] =
    useRecoilState(listSelectPartner);
  const { REGISTER_ACCOUNT_MODAL_PARTNER_TABLE } =
    useMultiLanguageRegisterAccount();
  const columns = [
    {
      title: (
        <div style={{ fontSize: 16, fontFamily: 'inter-regular, sans-serif' }}>
          {REGISTER_ACCOUNT_MODAL_PARTNER_TABLE}
        </div>
      ),
      dataIndex: 'partnerName',
      key: 'partnerName',
      render: (text) => {
        return (
          <Tooltip title={text} placement={'topLeft'}>
            <div
              style={{
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
              }}
            >
              {text}
            </div>
          </Tooltip>
        );
      },
      ellipsis: true,
    },
  ];

  const rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      setNewListSelectedPartner({
        display: false,
        listIdPartner: selectedRowKeys,
        listRecordPartner: selectedRows,
      });
    },
    selectedRowKeys: newListSelectedPartner.listIdPartner,
    preserveSelectedRowKeys: true,
  };

  const dataSource = useMemo(() => {
    if (dataRaw && dataRaw.data && dataRaw.ec === false) {
      const listPartner = (dataRaw.data.partnerResponseList ??= []);
      return listPartner.map((item) => ({
        ...item,
        key: item.partnerId,
      }));
    }
  }, [dataRaw]);
  useEffect(() => {
    setNewListSelectedPartner(listSelectedPartner);
  }, [setNewListSelectedPartner, listSelectedPartner]);

  return (
    <AntdTableModal
      columns={columns}
      dataSource={dataSource}
      rowSelection={rowSelection}
      emptyText={emptyText}
      isLoading={isLoading}
    />
  );
};

export default TableListPartner;
