import { Form, Input } from 'antd';
import FormMessage from 'components/Form/FormMessage/index';
import React from 'react';

const FormTextRequired = ({
  required,
  maxLength,
  name,
  placeHolder,
  isDisabled,
  marginBottom,
  isUrl,
}) => {
  return (
    <Form.Item
      style={{ marginBottom: marginBottom }}
      name={name ?? 'name'}
      rules={[
        {
          validator: (_, value) => {
            if (required && !value) {
              return Promise.reject(
                <FormMessage text1={'情報を入力してください。'} />,
              );
            }
            if (isUrl) {
              const urlRegex = /^(http|https):\/\/.+$/i;
              if (required && !value) {
                return Promise.reject(
                  <FormMessage text1={'情報を入力してください。'} />,
                );
              }

              if (value && !urlRegex.test(value)) {
                return Promise.reject(
                  <FormMessage text1={'URLの形式が正しくありません。'} />,
                );
              }
            }
            return Promise.resolve();
          },
        },
      ]}
    >
      <Input
        disabled={isDisabled}
        maxLength={maxLength}
        placeholder={placeHolder}
      />
    </Form.Item>
  );
};

export default FormTextRequired;
