import React from 'react';
import { Modal } from 'antd';
import { InfoCircleFilled } from '@ant-design/icons';
import { ButtonOutline, ButtonPrimary } from 'components/Button';
import {
  IconTitle,
  Title,
  TitleModal,
} from 'components/Tree/components/ModalConfirmDelete/syled';

const ModalConfirmDelete = ({ isModalOpen, handleOk, handleCloseModal }) => {
  return (
    <Modal
      title={
        <TitleModal>
          <IconTitle>
            <InfoCircleFilled />
          </IconTitle>
          <Title>このメニューを削除します。よろしいですか？</Title>
        </TitleModal>
      }
      centered={true}
      open={isModalOpen}
      onOk={handleOk}
      onCancel={handleCloseModal}
      footer={
        <>
          <ButtonPrimary
            onClick={handleOk}
            text={'削除'}
            size={'large'}
            width={80}
          />
          <ButtonOutline onClick={handleCloseModal} text={'キャンセル'} />
        </>
      }
    >
      <div style={{ height: '20px' }} />
    </Modal>
  );
};

export default ModalConfirmDelete;
