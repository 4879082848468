import useMultiLanguageRegisterCategory from 'page/Application/ManageProductAndService/Category/RegisterCategory/hook/useMultiLanguageRegisterCategory';

const useStatusProductCategory = () => {
  const {
    APPLICATION_REGISTERCATEGORY_STATUS_SHOW,
    APPLICATION_REGISTERCATEGORY_STATUS_HIDE,
  } = useMultiLanguageRegisterCategory();

  const STATUS_FOR_LIST = {
    SHOW: APPLICATION_REGISTERCATEGORY_STATUS_SHOW,
    HIDE: APPLICATION_REGISTERCATEGORY_STATUS_HIDE,
  };
  const STATUS_FOR_FORM = [
    {
      id: 1,
      label: APPLICATION_REGISTERCATEGORY_STATUS_SHOW,
      value: true,
    },
    {
      id: 2,
      label: APPLICATION_REGISTERCATEGORY_STATUS_HIDE,
      value: false,
    },
  ];

  return {
    STATUS_FOR_FORM,
    STATUS_FOR_LIST,
  };
};

export default useStatusProductCategory;
