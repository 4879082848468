import React, { useEffect, useMemo, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { PlusOutlined } from '@ant-design/icons';
import { message } from 'antd';
import { network } from 'recoil/atom/network';
import { REGISTER_PATH } from 'constant/appPath';
import {
  defaultPageSizeOptions,
  defaultSelectPageSizeOption,
} from 'constant/Pagination/pageSizeOption';
import { SORT_DIRECTION } from 'constant/sortConstants';
import { useRecoilValue } from 'recoil';
import { getListCategory } from 'api/product/category.api';
import { ButtonBreadcrumb } from 'components/Button';
import Breadcrumb from 'components/Layout/ApplicationLayout/Breadcrumb';
import HeaderListDataLayout from 'components/Layout/HeaderListDataLayout';
import LayoutDefault from 'components/Layout/LayoutDefault';
import Pagination from 'components/Pagination';
import useCustomQuery from 'hook/useQuery/useCustomQuery';
import useSetTitlePage from 'hook/useSetTitlePage';
import useMultiLanguageCompanyList from 'page/Application/ManageCompanyList/CompanyList/hook/useMultiLanguageCompanyList';
import TableCategory from 'page/Application/ManageProductAndService/Category/CategoryList/component/TableCategory';

const CategoryList = () => {
  const isOnline = useRecoilValue(network);
  const [messageErrorNetWork, contextErrorNetWork] = message.useMessage();
  const [keyword, setKeyword] = useState('');
  const [page, setPage] = useState(10);
  const [current, setCurrent] = useState(1);
  const { useQueryAPI } = useCustomQuery();
  const [sortedInfo, setSortedInfo] = useState({});
  const [searchValue, setSearchValue] = useState('');
  const navigate = useNavigate();
  const { COMPANY_LIST_PLACEHOLDER_SEARCH_NODATA, COMPANY_LIST_NO_DATA } =
    useMultiLanguageCompanyList();
  const [emptyText, setEmptyText] = useState(COMPANY_LIST_NO_DATA);

  const { data: listCategoryProduct = [], isLoading } = useQueryAPI({
    api: getListCategory,
    key: 'categoryList',
    params: {
      pageNumber: current,
      pageSize: page,
      keyword: keyword,
      displayedDateFrom: '',
      displayedDateTo: '',
      category: '',
      status: '',
      sortField: sortedInfo?.sortField ?? '',
      sortDirection: sortedInfo?.sortDirection ?? '',
    },
  });

  const listItemSA = useMemo(() => {
    return [
      {
        title: <Link to={'/manage-company-list'}>企業管理</Link>,
      },
      {
        title: 'カテゴリ管理',
      },
    ];
  }, []);
  const errorNetWork = () => {
    messageErrorNetWork
      .open({
        type: 'error',
        content:
          '問題が発生しました。インターネット接続を確認して、もう一度やり直してください。',
      })
      .then();
  };
  const handleChangePageSize = (value) => {
    if (isOnline) {
      setPage(value);
      setCurrent(1);
    } else {
      errorNetWork();
    }
  };

  const handlePagingChange = (pageNumber, pageSize) => {
    if (isOnline) {
      setCurrent(pageNumber);
      setPage(pageSize);
    } else {
      errorNetWork();
    }
  };

  const handleNavigate = () => {
    if (isOnline) {
      navigate(`${REGISTER_PATH}`);
    } else {
      errorNetWork();
    }
  };

  const handleChange = (pagination, filters, sorter) => {
    if (isOnline) {
      setSortedInfo({
        sortField: sorter.order && sorter.field,
        sortDirection: sorter.order && SORT_DIRECTION[sorter.order],
        order: sorter.order,
        columnKey: sorter.columnKey,
      });
    } else {
      errorNetWork();
    }
  };

  useEffect(() => {
    if (keyword) {
      setCurrent(1);
    }
  }, [keyword]);

  const onSearch = (value) => {
    if (isOnline) {
      const trimmedValue = value.trim();
      setKeyword(trimmedValue);
      if (value.length > 0) {
        setEmptyText(COMPANY_LIST_PLACEHOLDER_SEARCH_NODATA);
      } else {
        setEmptyText(COMPANY_LIST_NO_DATA);
      }
      setSortedInfo({});
    } else {
      errorNetWork();
    }
  };

  useSetTitlePage('カテゴリ管理');

  return (
    <>
      {contextErrorNetWork}
      <Breadcrumb listItem={listItemSA} titlePage="カテゴリ管理">
        <ButtonBreadcrumb
          text={'新規登録'}
          icon={<PlusOutlined />}
          onClick={handleNavigate}
        />
      </Breadcrumb>
      <LayoutDefault>
        <HeaderListDataLayout
          titleScreen={'カテゴリ一覧'}
          searchPlaceholder={'カテゴリ名に従え、検索します。'}
          onSearch={onSearch}
          loading={isLoading}
          searchValue={searchValue}
          setSearchValue={setSearchValue}
          value={page}
          handleChangePageSize={handleChangePageSize}
          pageSizeOptions={defaultSelectPageSizeOption}
        />
        <TableCategory
          isOnline={isOnline}
          errorNetWork={errorNetWork}
          isLoading={isLoading}
          dataRaw={listCategoryProduct.data}
          emptyText={emptyText}
          sortedInfo={sortedInfo}
          onChange={handleChange}
        />
        <Pagination
          totalTitle={'カテゴリ総数'}
          currentPage={current}
          pageSize={page}
          handlePagingChange={handlePagingChange}
          total={listCategoryProduct.data?.total}
          pageSizeOptions={defaultPageSizeOptions}
          handleChangePageSize={handleChangePageSize}
        />
      </LayoutDefault>
    </>
  );
};

export default CategoryList;
