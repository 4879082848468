import styled from 'styled-components';
import { DatePicker, Form, Input, Select } from 'antd';

export const FormItemStyled = styled(Form.Item)`
  margin-bottom: ${(props) => props?.marginbottom};
  width: ${(props) => props?.formwidth || 'auto'};

  label {
    font-family: 'inter-regular', sans-serif;
    color: #656464 !important;
  }

  .ant-form-item-explain-error {
    &:not(:first-child) {
      display: none;
    }
  }
`;

export const InputStyled = styled(Input)`
  height: 40px;
  font-size: 14px;
  text-overflow: ellipsis;
  span {
    height: 40px;
  }
  input {
    height: 40px;
  }
`;

export const InputPasswordStyled = styled(Input.Password)`
  height: 40px;
  font-size: 14px;
`;

export const SelectStyled = styled(Select)`
  height: 40px;

  .ant-select-selection-item {
    font-size: 14px;
  }

  .ant-select-selection-placeholder {
    font-size: 14px;
  }
`;

export const DateRangePicker = styled(DatePicker.RangePicker)`
  height: 40px;

  .ant-select-selection-item {
    font-size: 14px;
  }

  .ant-select-selection-placeholder {
    font-size: 14px;
  }
`;
