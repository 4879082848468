import React, { Suspense, useEffect, useState } from 'react';
import CKEditorBuilder from 'components/CKEditorBuilder';
import Breadcrumb from 'components/Layout/ApplicationLayout/Breadcrumb';
import LayoutDefault from 'components/Layout/LayoutDefault';
import FormFooterSubmit from 'page/Application/ManageProjectRegistration/component/FormFooterSubmit';
import FormLabelGrid from 'page/Application/ManageProjectRegistration/component/FormLabelGrid';
import FormPermission from 'page/Application/ManageProjectRegistration/component/FormPermission';
import FormRadio from 'page/Application/ManageProjectRegistration/component/FormRadio';
import FormTextRequired from 'page/Application/ManageProjectRegistration/component/FormTextRequired';
import FormUploadImg from 'page/Application/ManageProjectRegistration/component/FormUploadImg';
import FormURL from 'page/Application/ManageProjectRegistration/component/FormURL';
import { Alert, Form, Spin } from 'antd';
import { Container, FormWrapper } from 'components/Form/Template/styled';
import { Grid1, Grid2, GridForm } from 'page/Application/ManageProjectRegistration/component/styled';
import { FormTitle } from 'page/Application/ManageCompanyList/component/Form';
import FormNote from 'page/Application/ManageProjectRegistration/component/FormNote/index';

const FormProject = ({
                       isLoading,
                       isEdit,
                       isSuccess,
                       setIsDisplayed,
                       isDisplayed,
                       onFinish,
                       isError,
                       errorMessage,
                       content,
                       setContent,
                       // form,
                       listItem,
                       data,
                       setIdFile,
                       handleCancel,
                       handleDeleteProject,
                       isCancel,
                       setIsCancel,
                       imageObject,
                       setImageObject,
                       setPlainText,
                     }) => {
  const [form] = Form.useForm();
  const [hideButtonUploadImg, setHideButtonUploadImg] = useState(true);
  const [listPermissionProduct, setListPermissionProduct] = useState([]);
  const [isDisableFile, setIsDisableFile] = useState(false);
  const [isDisable, setIsDisable] = useState(false);
  const [showImg, setShowImg] = useState(true);

  const initialValues = {
    projectIsDisplayed: isDisplayed,
    projectUrlTitle: '案件登録お申し込みはこちら',
  };

  const onChangeProjectDisplayed = (e) => {
    const isDisplayedValue = e.target.value;
    form.setFieldValue('projectIsDisplayed', isDisplayedValue);
    setIsDisplayed(isDisplayedValue);
  };

  useEffect(() => {
    if (isEdit && data) {
      const image = data?.image;
      form.setFieldsValue(data);
      if (image) {
        setShowImg(true);
        form.setFieldValue('avatar', [
          {
            uid: '0',
            name: data?.avatarName,
            status: 'done',
            thumbUrl: image,
            type: data?.contentType,
            size: data?.size,
          },
        ]);
        setHideButtonUploadImg(false);
      }
    }
  }, [isEdit, data, form]);

  return (
    <>
      <Breadcrumb titlePage={listItem[2].title} listItem={listItem} />
      <Suspense fallback={isLoading || isSuccess}>
        <LayoutDefault>
          <FormTitle
            notice={isEdit && '注意：'}
            title={
              isEdit &&
              '変更した内容は画面下部の「保存」ボタンを押すと保存されます。'
            }
            type={'text'}
            styles={{ color: isEdit && '#9D1C49' }}
          />
          <FormWrapper>
            <Container>
              <Spin spinning={isLoading || isSuccess}>
                <Form
                  encType="multipart/form-data"
                  form={form}
                  layout="horizontal"
                  size="large"
                  onFinish={onFinish}
                  initialValues={initialValues}
                >
                  <GridForm>
                    <Grid2>
                      {(isError || errorMessage) && (
                        <Alert
                          message={errorMessage}
                          type="error"
                          style={{ marginBottom: '24px' }}
                        />
                      )}
                    </Grid2>
                    <Grid1>
                      <FormLabelGrid labelText={'タイトル'} required={true} />
                    </Grid1>
                    <Grid2>
                      <FormTextRequired
                        placeHolder={'タイトル名を入力してください'}
                        name="projectName"
                        required={true}
                        maxLength={256}
                      />
                    </Grid2>
                    <FormRadio
                      name={'projectIsDisplayed'}
                      required={true}
                      onChange={onChangeProjectDisplayed}
                    />
                    <Grid1>
                      <FormLabelGrid labelText={'内容'} />
                    </Grid1>
                    <Grid2>
                      <Form.Item>
                        <CKEditorBuilder
                          data={content}
                          setData={setContent}
                          setPlainText={setPlainText}
                        />
                        <FormNote
                          text={
                            'ソースコードモードで編集した内容は、ソースコードモードをOFFにしないと反映しません。'
                          }
                        />
                      </Form.Item>
                    </Grid2>
                    <FormPermission
                      isDisable={isDisableFile}
                      setIsDisable={setIsDisableFile}
                      dataRaw={listPermissionProduct}
                      setListPermissionPeoduct={setListPermissionProduct}
                    />
                    <FormURL isEdit={isEdit} data={data} form={form} />
                    <FormUploadImg
                      showImg={showImg}
                      setShowImg={setShowImg}
                      setIdFile={setIdFile}
                      form={form}
                      hideButtonUploadImg={hideButtonUploadImg}
                      setHideButtonUploadImg={setHideButtonUploadImg}
                      imageObject={imageObject}
                      setImageObject={setImageObject}
                      isDisable={isDisable}
                      setIsCancel={setIsCancel}
                      setIsDisable={setIsDisable}
                    />
                    <Grid2>
                      <FormFooterSubmit
                        isDisable={isDisable}
                        onCancel={handleCancel}
                        onDelete={handleDeleteProject}
                        textOk={isEdit ? '保存' : '登録'}
                        textCancel={'キャンセル'}
                        textDelete={'削除'}
                        isDelete={isEdit}
                        isCancel={isCancel}
                      />
                    </Grid2>
                  </GridForm>
                </Form>
              </Spin>
            </Container>
          </FormWrapper>
        </LayoutDefault>
      </Suspense>
    </>
  );
};
export default FormProject;
