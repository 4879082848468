import { useIntl } from 'react-intl';

const ValidatePasswordEmptyLanguage = () => {
  const intl = useIntl();

  const FORM_PASSWORD_LABEL = intl.formatMessage({
    id: 'authentication.password',
  });

  const FORM_PASSWORD_PLACEHOLDER = intl.formatMessage({
    id: 'authentication.password.placeholder',
  });

  const FORM_PASSWORD_ERROR_MESSAGE_EMPTY = intl.formatMessage({
    id: 'authentication.password.errorMessage.empty',
  });

  return {
    FORM_PASSWORD_LABEL,
    FORM_PASSWORD_PLACEHOLDER,
    FORM_PASSWORD_ERROR_MESSAGE_EMPTY,
  };
};

export default ValidatePasswordEmptyLanguage;
