import { useMemo } from 'react';

const useErrorCodeMyPage = () => {
  const ERROR_CODE_MY_PAGE = useMemo(() => {
    return {
      '400_NEW_PASSWORD_MUST_DIFFERENT_FROM_OLD_PASSWORD': {
        message:
          '新しいパスワードは、現在のパスワードと異なるものを入力してください。',
        statusCode: 400,
        type: 'error',
      },
      '404_DOMAIN_NOT_FOUND': {
        message: '',
        statusCode: 400,
        type: 'error',
      },
      '400_VALIDATION_ERROR': {
        message: '',
        statusCode: 400,
        type: 'error',
      },
    };
  }, []);
  return {
    ERROR_CODE_MY_PAGE,
  };
};

export default useErrorCodeMyPage;
