import React from 'react';
import { Form, Input } from 'antd';

const FormTextURLRequired = ({
  required,
  maxLength,
  name,
  placeHolder,
  isDisabled,
}) => {
  return (
    <Form.Item
      name={name ?? 'name'}
      rules={[
        {
          validator: (_, value) => {
            const urlRegex = /^(http|https):\/\/.+$/i;
            if (required && !value) {
              return Promise.reject('情報を入力してください。');
            }

            if (value && !urlRegex.test(value)) {
              return Promise.reject('URLの形式が正しくありません。');
            }
            return Promise.resolve();
          },
        },
      ]}
    >
      <Input
        disabled={isDisabled}
        maxLength={maxLength}
        placeholder={placeHolder}
      />
    </Form.Item>
  );
};

export default FormTextURLRequired;
