import React from 'react';
import {
  BreadcrumbWrapper,
  BreadcrumbCustom,
  BreadcrumbTitle,
} from 'components/Layout/ApplicationLayout/Breadcrumb/styled';
import 'components/Layout/ApplicationLayout/style/style.scss';
import { Tooltip } from 'antd';

const Breadcrumb = ({ titlePage, listItem, children }) => {
  return (
    <BreadcrumbWrapper className="breadcrumb-responsive">
      <div>
        <Tooltip title={titlePage} placement={'topLeft'}>
          <BreadcrumbTitle className="breadcrumb-responsive__title">
            {titlePage}
          </BreadcrumbTitle>
        </Tooltip>
        <BreadcrumbCustom
          items={listItem ?? []}
          className="breadcrumb-responsive__list-item"
        />
      </div>
      <div>{children}</div>
    </BreadcrumbWrapper>
  );
};

export default Breadcrumb;
