import styled from 'styled-components';

export const CategoryContainer = styled.div`
  color: var(--FORM-LABEL-default);
  height: fit-content;
`;

export const CategoryListWrapper = styled.div`
  margin: 20px 10px 12px;
  overflow: hidden;
  @media all and (min-width: 0px) and (max-width: 320px) {
    margin: 0;
  }
  @media all and (min-width: 320px) and (max-width: 430px) {
    margin: 0;
  }
`;
