import { Form } from 'antd';
import { FormMessage } from 'components/Form/index';
import { CHECK_NAME_TAGS } from 'constant/regex';
import { SelectList } from 'page/Application/ManageProductAndService/Content/component/form/FormContent/styled';
import React from 'react';

const FormTags = ({ dataSelect, handleChange, optionsSelected }) => {
  return (
    <Form.Item
      name="tags"
      rules={[
        {
          validator: (_, values) => {
            if (values) {
              const invalidTags = values.filter(
                (value) => !CHECK_NAME_TAGS.test(value),
              );
              if (invalidTags.length) {
                return Promise.reject(
                  <FormMessage
                    text1={
                      '形式が正しくありません。全角半角文字・「_」と「-」の記号のみで入力してください。'
                    }
                    marginBottom={'12px'}
                  />,
                );
              }
            }
            return Promise.resolve();
          },
        },
      ]}
    >
      <SelectList
        dataselect={dataSelect?.length}
        mode="tags"
        placeholder="タグ"
        onChange={handleChange}
        options={optionsSelected}
      ></SelectList>
    </Form.Item>
  );
};

export default FormTags;
