import styled from 'styled-components';
import { Radio } from 'antd';

export const RadioStyle = styled(Radio)`
  justify-content: space-between;

  .ant-radio-checked {
    color: var(--PRIMARY-default);
  }

  &.ant-radio-wrapper {
    margin: 0;

    .ant-radio {
      &-inner:hover {
        /* background-color: #ff0000; // Màu n?n c?a ô radio khi chua du?c ch?n */
        border-color: var(--PRIMARY-default);
      }

      &-input:checked + .ant-radio-inner {
        background-color: var(
          --PRIMARY-default
        ); // Màu n?n c?a ô radio khi du?c ch?n
        border-color: var(
          --PRIMARY-default
        ); // Màu vi?n c?a ô radio khi du?c ch?n
      }
    }
  }
`;
