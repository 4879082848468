import { Form } from 'antd';
import { CheckboxStyled } from './styled';

import React from 'react';

const FormCheckBox = ({ name, text, onChange, value, color }) => {
  return (
    <Form.Item name={name} valuePropName="checked">
      <CheckboxStyled color={color}>{text}</CheckboxStyled>
    </Form.Item>
  );
};

export default FormCheckBox;
