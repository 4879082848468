import axiosClient from 'api/config/axiosClient';

const loginToApp = ({ email, password }) => {
  const url = 'v1/auth/login';
  return axiosClient.post(url, { email, password });
};

const registrationAccount = ({
  email,
  password,
  firstName,
  lastName,
  phoneNumber,
  partnerId,
}) => {
  const url = 'v1/auth/signup';
  return axiosClient.post(url, {
    email,
    password,
    firstName,
    lastName,
    phoneNumber,
    partnerId,
  });
};

const registrationAccountVerify = ({ email }) => {
  const url = 'v1/auth/signup-verify';
  return axiosClient.post(url, {
    email,
  });
};

const getListPartnerForRegister = ({ email }) => {
  const url = `v1/auth/partners?email=${email}`;
  return axiosClient.get(url);
};

const sendEmailResetPassword = ({ email }) => {
  const url = 'v1/account/forgot-password';
  return axiosClient.post(url, { email });
};

const checkTokenResetPassword = ({ email, token, signal }) => {
  const url = `v1/account/verify-token?email=${email}&token=${token}`;
  return axiosClient.get(url, signal);
};

const resetPassword = ({ email, token, password }) => {
  const url = `v1/account/reset-password?email=${email}&token=${token}`;
  return axiosClient.put(url, password);
};

const changePassword = ({ newPassword }) => {
  const url = `v1/account/change-password`;
  return axiosClient.put(url, { newPassword });
};

export {
  loginToApp,
  registrationAccount,
  resetPassword,
  sendEmailResetPassword,
  changePassword,
  checkTokenResetPassword,
  getListPartnerForRegister,
  registrationAccountVerify,
};
