import React, { useState } from 'react';
import { useRecoilState } from 'recoil';
import { FormCheckBox } from '../index';
import FormLabelGrid from 'page/Application/ManageNotificationList/component/Form/FormLabelGrid';
import ModalListProduct from 'page/Application/ManageNotificationList/component/Form/FormAddProduct/ModalListProduct';
import useMultiLanguageRegisterNotification from 'page/Application/ManageNotificationList/RegisterNotification/hook/useMultiLanguageRegisterNotification';
import { FormAddPermissionWrapper } from 'page/Application/ManageNotificationList/component/Form/FormAddProduct/styled';
import { listProduct, listSelectProduct } from 'recoil/atom/selectProduct';
import { listProductType } from 'page/Application/ManageCompanyList/component/ListProduct';
import { Tooltip } from 'antd';
import {
  Grid1,
  Grid2,
  Grid4,
  GridForm2,
} from 'page/Application/ManageNotificationList/component/Form/styled';
import { ButtonOutline } from 'components/Button';

const FormAddProduct = ({ isEdit }) => {
  const {
    REGISTER_NOTIFICATION_FORM_POSITIONS,
    REGISTER_NOTIFICATION_ADD_PRODUCT,
    REGISTER_NOTIFICATION_EDIT_PRODUCT,
  } = useMultiLanguageRegisterNotification();
  const [isModalOpen, setIsModalOpen] = useState(false);

  const [listSelectedProduct, setListSelectedProduct] =
    useRecoilState(listProduct);
  const [newListSelectedProduct, setNewListSelectedProduct] =
    useRecoilState(listSelectProduct);

  const handleOpenModal = () => {
    setIsModalOpen(true);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
    setNewListSelectedProduct(listSelectedProduct);
  };
  const compareProductUpdatedTime = (a, b) => {
    if (a < b) return -1;
    if (a > b) return 1;
    return 0;
  };
  const handleAddProduct = () => {
    setIsModalOpen(false);
    let newListProduct = [...newListSelectedProduct.listRecordProduct];
    newListProduct.sort((a, b) => {
      const productUpdatedTimeA = new Date(Date.parse(a.updatedTime));
      const productUpdatedTimeB = new Date(Date.parse(b.updatedTime));
      return compareProductUpdatedTime(
        productUpdatedTimeB,
        productUpdatedTimeA,
      );
    });
    setListSelectedProduct({
      ...newListSelectedProduct,
      listRecordProduct: newListProduct,
      display: true,
    });
  };

  return (
    <>
      {
        <ModalListProduct
          isModalOpen={isModalOpen}
          handleCancel={handleCancel}
          handleOk={handleAddProduct}
        />
      }
      <Grid1>
        <FormLabelGrid
          labelText={REGISTER_NOTIFICATION_FORM_POSITIONS}
          required={false}
        />
      </Grid1>
      <GridForm2>
        <Grid2>
          <FormCheckBox name="isTopPage" />
        </Grid2>
        <Grid4>
          <FormLabelGrid labelText={'トップページ'} />
        </Grid4>
      </GridForm2>
      {listSelectedProduct?.listRecordProduct &&
        listSelectedProduct?.listRecordProduct.length > 0 && (
          <Grid2>
            <div
              style={{
                marginBottom: 20,
              }}
            >
              {listSelectedProduct.listRecordProduct.map((item) => {
                return (
                  <div
                    key={item.key}
                    title={item.productName}
                    style={{
                      whiteSpace: 'nowrap',
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                    }}
                  >
                    <span
                      style={{
                        marginRight: 4,
                        textDecoration: 'underline',
                        textDecorationThickness: '0.5px !important',
                        textDecorationSkipInk: 'none',
                        textUnderlineOffset: '3px',
                        color: '#656464',
                      }}
                    >
                      【{listProductType[item?.type]}】
                      <Tooltip title={item.productName} placement={'topLeft'}>
                        {item.productName}
                      </Tooltip>
                    </span>
                  </div>
                );
              })}
            </div>
          </Grid2>
        )}
      <Grid2>
        <FormAddPermissionWrapper>
          <ButtonOutline
            onClick={handleOpenModal}
            text={
              isEdit
                ? REGISTER_NOTIFICATION_EDIT_PRODUCT
                : REGISTER_NOTIFICATION_ADD_PRODUCT
            }
            width={88}
          />
        </FormAddPermissionWrapper>
      </Grid2>
    </>
  );
};

export default FormAddProduct;
