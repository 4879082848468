import { useIntl } from 'react-intl';

const useNotifyCategoryMultiLanguage = () => {
  const intl = useIntl();

  const NOTIFICATION_CATEGORY_UPDATE_INFORMATION = intl.formatMessage({
    id: 'notification.category.updateInformation',
  });

  const NOTIFICATION_CATEGORY_NEWS = intl.formatMessage({
    id: 'notification.category.news',
  });

  const NOTIFICATION_CATEGORY_IMPORTANT_NOTICE = intl.formatMessage({
    id: 'notification.category.importantNotice',
  });

  const NOTIFICATION_CATEGORY_FOR_LIST = {
    UPDATE_INFORMATION: NOTIFICATION_CATEGORY_UPDATE_INFORMATION,
    NEWS: NOTIFICATION_CATEGORY_NEWS,
    IMPORTANT_NOTICE: NOTIFICATION_CATEGORY_IMPORTANT_NOTICE,
  };

  const NOTIFICATION_CATEGORY_FOR_FORM = [
    {
      value: 'UPDATE_INFORMATION',
      label: NOTIFICATION_CATEGORY_UPDATE_INFORMATION,
      title: '',
    },
    {
      value: 'NEWS',
      label: NOTIFICATION_CATEGORY_NEWS,
      title: '',
    },
    {
      value: 'IMPORTANT_NOTICE',
      label: NOTIFICATION_CATEGORY_IMPORTANT_NOTICE,
      title: '',
    },
  ];
  return {
    NOTIFICATION_CATEGORY_FOR_LIST,
    NOTIFICATION_CATEGORY_FOR_FORM,
  };
};

export default useNotifyCategoryMultiLanguage;
