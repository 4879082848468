import styled from 'styled-components';

export const CategoryContainer = styled.div`
  color: var(--FORM-LABEL-default);
  min-height: 560px;
`;

export const CategoryListWrapper = styled.div`
  background: white;
  border-radius: 8px;
  font-family: 'inter-regular', sans-serif;
  margin-bottom: 12px;
  margin-top: 20px;
  margin-left: 10px;
  margin-right: 10px;
  padding-bottom: 12px;
  overflow: hidden;
`;

export const CategoryWrapper = styled.div`
  margin-bottom: 26px;
  padding-bottom: 26px;
`;
