import React from 'react';
import { FormItemStyled, InputStyled } from 'components/Form2/styled';
import { FormMessage } from 'components/Form';

const FormValidateEmpty = ({
  message,
  required,
  maxLength,
  formName,
  placeholder,
}) => {
  return (
    <FormItemStyled
      name={formName ?? 'name'}
      rules={[
        {
          required: required,
          message: <FormMessage text1={message} marginBottom={'12px'} />,
        },
      ]}
    >
      <InputStyled maxLength={maxLength ?? 256} placeholder={placeholder} />
    </FormItemStyled>
  );
};

export default FormValidateEmpty;
