import React, { useEffect, useState } from 'react';
import useMultiLanguage from 'page/Authentication/ForgotPassword/hook/useMultiLanguage';
import useSetTitlePage from 'hook/useSetTitlePage';
import { FormContainer, TitleAuthentication } from 'page/Authentication/styled';
import { TextAlignCenter } from 'page/Authentication/ForgotPassword/VerifyEmail/styled';
import { Alert, Form } from 'antd';
import { useMutation } from 'react-query';
import { sendEmailResetPassword } from 'api/authentication/authen.api';
import { FormItemButtonSubmit, FormItemValidateEmail } from 'components/Form';
import { useNavigate } from 'react-router-dom';
import useRemoveBlankSpace from 'hook/useRemoveBlankSpace';
import { useForm } from 'antd/es/form/Form';
import { useRecoilState } from 'recoil';
import { messageFeedback } from 'recoil/atom/authentication';
import useErrorCodeForgotPassword from 'page/Authentication/ForgotPassword/hook/useErrorCodeForgotPassword';
import useCheckErrorCode from 'hook/useCheckErrorCode';
import { SIGN_IN_PATH } from '../../../../constant/authenticationPath';

const ForgotPassword = () => {
  useSetTitlePage('Solitonパートナーサイト│パスワード再設定');
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const { ERROR_CODE_FORGOT_PASSWORD } = useErrorCodeForgotPassword();
  const { handleClearErrorMessage, handleRenderMessage, errorMessage } =
    useCheckErrorCode({ errorCode: ERROR_CODE_FORGOT_PASSWORD });
  const { handleTrimInput } = useRemoveBlankSpace();
  const [, setShowMessageFeedback] = useRecoilState(messageFeedback);
  const [formForgotPassword] = useForm();
  const {
    FORGOT_PASSWORD_TITLE,
    FORGOT_PASSWORD_MESSAGE,
    FORGOT_PASSWORD_SUCCESS_MESSAGE,
  } = useMultiLanguage();

  const { mutate, isError } = useMutation({
    mutationFn: sendEmailResetPassword,
    onSuccess: () => {
      handleClearErrorMessage();
      setLoading(false);
      setShowMessageFeedback({
        display: true,
        type: 'success',
        content: FORGOT_PASSWORD_SUCCESS_MESSAGE,
      });
      navigate('/');
    },
    onError: (response) => {
      setLoading(false);
      handleRenderMessage(response);
    },
  });

  const onFinish = (values) => {
    const payload = {
      email: values.email,
    };
    setLoading(true);
    mutate(payload);
  };

  //maintain
  useEffect(()=>{
    const fetchData = async () => {
      try {
        const response = await fetch('./maintenancetimeconfig.json');
        const jsonData = await response.json();
        let isMaintenance = jsonData.isMaintenance;
        if(isMaintenance){
          window.location.replace(SIGN_IN_PATH)
        }
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };
    fetchData().then();
  },[])

  return (
    <>
      <FormContainer className="form-responsive">
        <div className="title-form-forgot-password-responsive">
          <TitleAuthentication
            style={{ marginBottom: '12px' }}
            className="title-form"
          >
            {FORGOT_PASSWORD_TITLE}
          </TitleAuthentication>
          <TextAlignCenter className="guide">
            {FORGOT_PASSWORD_MESSAGE}
          </TextAlignCenter>
        </div>

        {isError && errorMessage !== '' && (
          <div className="alert">
            <Alert message={errorMessage} type="error" />
          </div>
        )}

        <Form
          className="size"
          name="forgot-password"
          layout={'vertical'}
          onFinish={onFinish}
          form={formForgotPassword}
        >
          <FormItemValidateEmail
            className="email"
            name="email"
            onChange={(e) =>
              handleTrimInput(formForgotPassword, 'email', e.target.value)
            }
          />
          <FormItemButtonSubmit
            size="14px"
            button="verifyEmail"
            formName="forgot-password"
            loading={loading}
          />
        </Form>
      </FormContainer>
      <div className="blank-space-verify-email" />
    </>
  );
};

export default ForgotPassword;
