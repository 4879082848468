import styled from 'styled-components';

export const CategoryTopPageDiv = styled.div`
  font-size: 16px;
  font-family: 'inter-regular', sans-serif;
  font-weight: 700;
  max-width: 100%;
  text-align: left;
  border-bottom: 0.5px solid #f0f0f0;
  line-height: 0.1em;
  margin: 23px 0 7px;
  text-overflow: ellipsis;
  text-wrap: nowrap
  }
`;
export const CategoryTopPageSpan = styled.span`
  padding: 0 10px;
  background: #fff;
`;
