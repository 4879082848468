import React, { useEffect, useMemo, useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { Col, Divider, Row, Spin, Tooltip } from 'antd';
import {
  BreadCrumbDetail,
  ButtonStyle,
  FormDate,
  FormDetailTitle,
  FormDownloadFile,
  FormHashCode,
  TableListProductDetail,
  TitleFormProductDetail,
  TitleSubmenu,
} from 'page/Application/ManageProductAndService/TopPageProduct/TopPageProductDetail/styled';
import { userInformation } from 'recoil/atom/authentication';
import { ERROR_MESSAGES_RESPONSE } from 'constant/topPageConstants';
import dayjs from 'dayjs';
import {
  TagContainer,
  TagStyled,
  TagTitle,
  TagWrapper,
} from 'page/Application/ManageProductAndService/TopPageProductPartnerAdmin/TopPageProductSuperDetailPartnerAdmin/styled';
import {
  getTopPageMenuParent,
  getTopPageMenuProduct,
  getTopPageProduct,
} from 'api/product/topPage.api';
import { downloadContentFile } from 'api/storage/storage.api';
import { ButtonBreadcrumb } from 'components/Button';
import { useRecoilValue } from 'recoil';
import Breadcrumb from 'components/Layout/ApplicationLayout/Breadcrumb';
import useCustomQuery from 'hook/useQuery/useCustomQuery';
import LayoutTopPage from 'page/Application/ManageProductAndService/TopPageProductPartnerAdmin/TopPagePartnerAdmin/component/LayoutTopPage';
import ProductContent from 'page/Application/ManageProductAndService/TopPageProductPartnerAdmin/TopPageProductDetailPartnerAdmin/component/ProductContent';
import ShowMoreTextSpanItem from 'page/Application/ManageProductAndService/TopPageProduct/components/ShowMoreTextSpanItem';

const TopPageProductSuperDetailPartnerAdmin = () => {
  const navigate = useNavigate();
  const { useQueryAPI } = useCustomQuery();
  const { role } = useRecoilValue(userInformation);
  const [detailId, setDetailId] = useState(null);
  const { menuId, productId } = useParams();
  const [isDisableDownload, setIsDisableDownload] = useState(null);
  const [listMenu, setListMenu] = useState([]);

  const {
    data: productDetail = [],
    isLoading,
    error: errorMenu,
  } = useQueryAPI({
    api: getTopPageMenuProduct,
    key: 'productDetail',
    params: {
      menuId: menuId,
    },
  });

  const { data: menuParent = [], isLoading: menuPartnerLoading } = useQueryAPI({
    api: getTopPageMenuParent,
    key: 'menuParent',
    params: {
      menuId: menuId,
    },
  });

  const {
    data: dataProduct = [],
    isLoading: loadingDataProduct,
    error: errorProduct,
  } = useQueryAPI({
    api: getTopPageProduct,
    key: 'dataProduct',
    params: {
      productId: productId,
    },
  });

  const productMenuName = useMemo(() => {
    return dataProduct?.data?.product?.productName;
  }, [dataProduct]);

  const listItemTopPagePartner = useMemo(() => {
    return [
      {
        title: <Link to={'/partner-page'}>パートナー専用ページ</Link>,
      },
      {
        title: <Link to={'/top-page'}>製品一覧</Link>,
      },
      {
        title: (
          <Tooltip title={productMenuName} placement={'topLeft'}>
            <Link to={`/top-page/top-page-product/${productId}`}>
              <BreadCrumbDetail>{productMenuName} </BreadCrumbDetail>
            </Link>
          </Tooltip>
        ),
      },
      ...listMenu,
    ];
  }, [listMenu, productId, productMenuName]);

  const listItemTopPageSA = useMemo(() => {
    return [
      {
        title: <Link to={'/manage-company-list'}>企業管理</Link>,
      },
      {
        title: <Link to={'/top-page'}>製品一覧</Link>,
      },
      {
        title: (
          <Tooltip placement={'topLeft'} title={productMenuName}>
            <Link to={`/top-page/top-page-product/${productId}`}>
              <BreadCrumbDetail>{productMenuName}</BreadCrumbDetail>
            </Link>
          </Tooltip>
        ),
      },
      ...listMenu,
    ];
  }, [listMenu, productId, productMenuName]);

  const listItemTopPageSU = useMemo(() => {
    return [
      {
        title: <Link to={'/my-page'}>マイページ</Link>,
      },
      {
        title: <Link to={'/top-page'}>製品一覧</Link>,
      },
      {
        title: (
          <Tooltip placement={'topLeft'} title={productMenuName}>
            <Link to={`/top-page/top-page-product/${productId}`}>
              <BreadCrumbDetail>{productMenuName}</BreadCrumbDetail>
            </Link>
          </Tooltip>
        ),
      },
      ...listMenu,
    ];
  }, [listMenu, productId, productMenuName]);

  const listItemByRole = useMemo(() => {
    return {
      ROLE_SOLITON_ADMIN: listItemTopPageSA,
      ROLE_SOLITON_USER: listItemTopPageSU,
      ROLE_PARTNER_ADMIN: listItemTopPagePartner,
      ROLE_PARTNER_USER: listItemTopPagePartner,
    };
  }, [listItemTopPageSA, listItemTopPageSU, listItemTopPagePartner]);

  const { refetch, isSuccess, isError, isRefetching } = useQueryAPI({
    api: downloadContentFile,
    params: {
      detailId: detailId,
    },
    options: {
      refetchOnWindowFocus: false,
    },
    enabled: !!detailId,
  });

  const handleGoToViewPage = (index) => {
    navigate(`detail/${productDetail?.data?.listDetail[index]?.detailId}`);
  };

  const handleMoveToSuperChildDetailProduct = (menuId) => {
    navigate(
      `/top-page/top-page-product/${productId}/top-page-detail-product/${menuId}`,
    );
  };

  const handleDownLoad = async (fileName, detailId) => {
    await setDetailId(detailId);
    await refetch().then(async (value) => {
      await window.open(value?.data, '_self');
    });
  };

  useEffect(() => {
    if (
      ERROR_MESSAGES_RESPONSE[errorProduct?.response?.data?.message] ||
      ERROR_MESSAGES_RESPONSE[errorMenu?.response?.data?.message]
    ) {
      navigate('/top-page-not-found');
    }
    if (isSuccess || isError || !isRefetching) {
      setTimeout(() => setIsDisableDownload(null), 1000);
    }

    // Set list breadcrumb menu top page
    setListMenu(
      menuParent?.data && !menuPartnerLoading
        ? menuParent?.data?.map((item, index) => {
            if (index + 1 < menuParent?.data?.length) {
              return {
                title: (
                  <Tooltip title={item?.menuName} placement={'topLeft'}>
                    <Link
                      to={`/top-page/top-page-product/${productId}/top-page-detail-product/${item?.menuId}`}
                    >
                      <BreadCrumbDetail>{item?.menuName}</BreadCrumbDetail>
                    </Link>
                  </Tooltip>
                ),
              };
            } else {
              return {
                title: (
                  <Tooltip title={item?.menuName} placement={'topLeft'}>
                    <BreadCrumbDetail>{item?.menuName}</BreadCrumbDetail>
                  </Tooltip>
                ),
              };
            }
          })
        : '',
    );
  }, [
    errorProduct?.response?.data?.message,
    errorMenu?.response?.data?.message,
    isError,
    isRefetching,
    isSuccess,
    navigate,
    menuParent?.data,
    productId,
    menuPartnerLoading,
  ]);

  const handleNavigateForSA = () => {
    navigate('edit/');
  };

  const handleNavigateOpenSearch = (tag) => {
    const currentUrl = window.location.origin;
    window.open(
      `${currentUrl}/#/opensearch?keyword=${encodeURIComponent(tag)}`,
      '_self',
    );
  };

  return (
    <Spin spinning={isLoading || loadingDataProduct}>
      {role === 'ROLE_SOLITON_ADMIN' ? (
        <Breadcrumb
          listItem={listItemByRole[role]}
          titlePage={dataProduct?.data?.product?.productName}
        >
          <ButtonBreadcrumb
            width={100}
            icon={'none'}
            text={'編集'}
            onClick={handleNavigateForSA}
          />
        </Breadcrumb>
      ) : (
        <Breadcrumb
          listItem={listItemByRole[role]}
          titlePage={dataProduct?.data?.product?.productName}
        />
      )}
      {(dataProduct?.data?.product?.productContent ||
        dataProduct?.data?.product?.image) && (
        <LayoutTopPage>
          <ProductContent
            isLoading={isLoading}
            dataNotify={dataProduct?.data}
          />
        </LayoutTopPage>
      )}
      <div style={{ background: '#ebebeb', padding: '20px 35px 0' }}>
        <TitleSubmenu>{productDetail?.data?.menu?.menuName}</TitleSubmenu>
        {productDetail?.data?.menu?.children?.length > 0 && (
          <Row gutter={20} style={{ padding: '0 0 20px' }}>
            {productDetail?.data?.menu?.children?.map((item) => (
              <Col
                xs={24}
                sm={24}
                md={12}
                lg={8}
                xl={8}
                xxl={6}
                key={item?.menuId}
              >
                <ButtonStyle
                  block={true}
                  onClick={() =>
                    handleMoveToSuperChildDetailProduct(item?.menuId)
                  }
                >
                  <Tooltip title={item?.menuName} placement={'topLeft'}>
                    <div
                      style={{
                        whiteSpace: 'nowrap',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        fontWeight: 700,
                      }}
                    >
                      {item?.menuName}
                    </div>
                  </Tooltip>
                </ButtonStyle>
              </Col>
            ))}
          </Row>
        )}
      </div>
      <div>
        {productDetail?.data?.listDetail?.map((item, index) => (
          <TableListProductDetail
            hashtags={item?.detailTagLst?.length}
            detailType={'FILE'}
            key={index}
            style={{ padding: '16px 35px' }}
          >
            <TitleFormProductDetail>
              <div style={{ display: 'flex' }}>
                {item?.detailDisplayType !== 'FILE' ? (
                  item?.detailDisplayType === 'LINK' ? (
                    <Tooltip title={item?.detailTitle} placement={'topLeft'}>
                      <FormDetailTitle
                        href={item?.detailUrl}
                        target={item?.isNewTab ? '_blank' : ''}
                        className={'link'}
                        rel="noreferrer"
                      >
                        {item?.detailTitle}
                      </FormDetailTitle>{' '}
                    </Tooltip>
                  ) : (
                    <Tooltip title={item?.detailTitle} placement={'topLeft'}>
                      <FormDetailTitle
                        onClick={() => handleGoToViewPage(index)}
                        className={'link'}
                        rel="noreferrer"
                      >
                        {item?.detailTitle}
                      </FormDetailTitle>
                    </Tooltip>
                  )
                ) : (
                  <Tooltip title={item?.detailTitle} placement={'topLeft'}>
                    <FormDetailTitle
                      cursor={'initial'}
                      color={'var(--BTN-TEXT-SECONDARY-default)'}
                    >
                      {item?.detailTitle}
                    </FormDetailTitle>
                  </Tooltip>
                )}
              </div>
              <FormDate className={'date-padding'}>
                更新日:{' '}
                <span style={{ fontWeight: 400 }}>
                  {dayjs(item?.detailDisplayDate).format('YYYY年MM月DD日')}
                </span>
              </FormDate>
            </TitleFormProductDetail>
            <Divider style={{ margin: '8px 0 20px', color: '#656464' }} />
            {item.detailExplanation && <ShowMoreTextSpanItem item={item} />}
            {item?.detailDisplayType === 'FILE' && (
              <>
                {item?.showHash && (
                  <FormHashCode>
                    SHA256：{item?.fileResponse?.fileHash ?? '計算中'}
                  </FormHashCode>
                )}
                <FormDownloadFile
                  onClick={() => {
                    handleDownLoad(
                      item?.fileResponse?.fileName,
                      item?.detailId,
                    ).then();
                    setIsDisableDownload(item?.detailId);
                  }}
                  disabled={
                    item?.detailDisplayStatus === 'DOWNLOADABLE_STOPPED' ||
                    isDisableDownload === item?.detailId
                  }
                >
                  ダウンロード
                </FormDownloadFile>
              </>
            )}
            {item?.detailTagLst?.length > 0 && (
              <TagWrapper>
                <TagTitle>タグ</TagTitle>
                <TagContainer>
                  {item?.detailTagLst?.map((itemTag, index) => (
                    <TagStyled
                      key={index}
                      onClick={() => handleNavigateOpenSearch(itemTag)}
                    >
                      {itemTag}
                    </TagStyled>
                  ))}
                </TagContainer>
              </TagWrapper>
            )}
          </TableListProductDetail>
        ))}
      </div>
    </Spin>
  );
};
export default TopPageProductSuperDetailPartnerAdmin;
