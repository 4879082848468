import React, { Suspense, useEffect, useMemo, useState } from 'react';
import { getListProject } from 'api/project/partnerAdmin.api';
import { useRecoilValue } from 'recoil';
import Breadcrumb from 'components/Layout/ApplicationLayout/Breadcrumb';
import LayoutDefault from 'components/Layout/LayoutDefault';
import HeaderListDataLayout from 'components/Layout/HeaderListDataLayout';
import useSearchKeyword from 'hook/useSearchKeyword';
import Pagination from 'components/Pagination';
import UseCustomQuery from 'hook/useQuery/useCustomQuery';
import useMultiLanguageCompanyList from 'page/Application/ManageCompanyList/CompanyList/hook/useMultiLanguageCompanyList';
import Thumbnail from 'page/Application/ProjectRegistration/component/Thumbnail';
import ErrorImage from 'assets/images/errorImage.jpg';
import { network } from 'recoil/atom/network';
import { Link, useNavigate } from 'react-router-dom';
import {
  DescProject,
  ProjectItem,
  TitleProject,
} from 'page/Application/ProjectRegistration/ProjectRegistrationOverview/styled';
import {
  defaultPageSizeOptions,
  defaultSelectPageSizeOption,
} from 'constant/Pagination/pageSizeOption';
import { InboxOutlined } from '@ant-design/icons';
import { Col, Empty, Row, Spin, Tooltip } from 'antd';

const ProjectRegistrationOverview = () => {
  const navigate = useNavigate();
  const { searchKeyword, onSearch, emptyText } = useSearchKeyword();
  const { useQueryAPI } = UseCustomQuery();
  const [page, setPage] = useState(10);
  const [current, setCurrent] = useState(1);
  const [searchValue, setSearchValue] = useState('');

  const { COMPANY_LIST_NO_DATA } = useMultiLanguageCompanyList();
  const isOnline = useRecoilValue(network);

  const { data: projectData = [], isLoading } = useQueryAPI({
    api: getListProject,
    key: 'listProject',
    params: {
      pageNumber: current,
      pageSize: page,
      keyword: encodeURIComponent(searchKeyword) ?? searchKeyword,
      sortField: '',
      sortDirection: '',
    },
  });

  const listItem = useMemo(() => {
    return [
      {
        title: <Link to={'/'}>パートナー専用ページ</Link>,
      },
      {
        title: '案件登録お申込み',
      },
    ];
  }, []);

  const handleChangePageSize = (value) => {
    if (isOnline) {
      setPage(value);
      setCurrent(1);
    }
  };

  const handlePagingChange = (pageNumber, pageSize) => {
    if (isOnline) {
      setCurrent(pageNumber);
      setPage(pageSize);
    }
  };

  const projectList = useMemo(() => {
    if (projectData && projectData.ec === false) {
      return projectData.data.list;
    } else return [];
  }, [projectData]);

  const handleMoveToDetail = (id) => {
    if (isOnline) navigate(`detail/${id}`);
  };

  useEffect(() => {
    if (searchKeyword) {
      setCurrent(1);
    }
  }, [searchKeyword]);

  return (
    <>
      <Breadcrumb listItem={listItem} titlePage={'案件登録お申込み'} />
      <LayoutDefault>
        <Suspense fallback={isLoading}>
          <Spin spinning={isLoading} style={{ maxHeight: 'unset' }}>
            <HeaderListDataLayout
              titleScreen={'案件登録お申し込み一覧'}
              searchPlaceholder={'タイトルに従え、検索します '}
              isLoading={isLoading}
              onSearch={onSearch}
              // width={300}
              value={page}
              handleChangePageSize={handleChangePageSize}
              pageSizeOptions={defaultSelectPageSizeOption}
              searchValue={searchValue}
              setSearchValue={setSearchValue}
            />
            <>
              {isLoading && <div style={{ height: 520 }} />}
              {(projectList === null || projectList?.length === 0 )&& isLoading === false && (
                  <Empty
                    image={<InboxOutlined />}
                    imageStyle={{ fontSize: 80 }}
                    description={null}
                    style={{ fontSize: '16px', minHeight: 532 }}
                  >
                    {emptyText || COMPANY_LIST_NO_DATA}
                  </Empty>
              )}
              {projectList?.length > 0 && isLoading === false && (
                <Row gutter={20}>
                  {projectList.map((item) => (
                    <Col
                      xs={24}
                      sm={12}
                      md={12}
                      lg={8}
                      xl={6}
                      key={item.projectId}
                    >
                      <ProjectItem>
                        <div onClick={() => handleMoveToDetail(item.projectId)}>
                          {item.image === '' ? (
                            <Thumbnail imageUrl={ErrorImage} />
                          ) : (
                            <Thumbnail imageUrl={item.image} />
                          )}
                        </div>
                        <DescProject>
                          <TitleProject
                            onClick={() => handleMoveToDetail(item.projectId)}
                          >
                            <Tooltip
                              title={item.projectName}
                              placement={'topLeft'}
                            >
                              {item.projectName}
                            </Tooltip>
                          </TitleProject>
                        </DescProject>
                      </ProjectItem>
                    </Col>
                  ))}
                </Row>
              )}
            </>
            <Pagination
              totalTitle={'案件登録お申込み'}
              total={projectData.data?.total}
              currentPage={current}
              pageSize={page}
              pageSizeOptions={defaultPageSizeOptions}
              handlePagingChange={handlePagingChange}
              handleChangePageSize={handleChangePageSize}
            />
          </Spin>
        </Suspense>
      </LayoutDefault>
    </>
  );
};

export default ProjectRegistrationOverview;
