import React, { useEffect, useState } from 'react';
import { useRecoilState } from 'recoil';
import { filterContent } from 'recoil/atom/filterContent';
import { Col, Form, Row } from 'antd';
import AntdModal3 from 'components/AntdModal3/index';
import { FormLabelGrid } from 'components/Form/index';
import { FormWrapper } from 'components/Form/Template/styled';
import { DATE_FORMAT_LIST } from 'constant/notificationConstants';
import dayjs from 'dayjs';
import ButtonModal from 'page/Application/ManageProductAndService/Content/ContentList/component/ButtonModal/index';
import {
  FormModalDisplay,
  FormModalFilter,
} from 'page/Application/ManageProductAndService/Content/ContentList/component/index';

const ModalContentList = ({
  isModalOpen,
  width,
  setIsModalOpen,
  isFilter,
  setFilterData,
  resetFormStatus,
  setResetFormStatus,
  form,
  handleSelectRowToDefault,
  resetPageToDefault,
  resetFilterThroughParamURL,
  handleResetKeyword,
  handleConfirmUpdateStatus,
  isCancel,
  isOnline,
  errorNetWork,
  listDateDisabled,
}) => {
  const [isSubmit, setIsSubmit] = useState(true);
  const values = Form.useWatch([], form);

  const [, setFilteredContent] = useRecoilState(filterContent);

  const handleCloseModalFilter = () => {
    form.resetFields();
  };

  const handleOk = () => {
    if (isOnline) {
      const filterData = {
        productId: values?.productId ?? '',
        displayType: values?.displayType ?? '',
        displayedDateFrom: values?.displayedDate?.[0]
          ? dayjs(values?.displayedDate?.[0]).format(DATE_FORMAT_LIST[3])
          : '',
        displayedDateTo: values?.displayedDate?.[1]
          ? dayjs(values?.displayedDate?.[1]).format(DATE_FORMAT_LIST[3])
          : '',
        displayStatus: values?.displayStatus ?? '',
        menuIds: values?.menuIds,
        menuId: values?.menuIds?.[values?.menuIds?.length - 1] ?? '',
      };
      setFilterData(filterData);
      setFilteredContent(filterData);
      resetPageToDefault();
      handleSelectRowToDefault();
      resetFilterThroughParamURL();
      handleResetKeyword();
      setIsModalOpen(false);
    } else {
      errorNetWork();
    }
  };

  useEffect(() => {
    if (isModalOpen && isFilter) {
      if (
        values?.productId ||
        values?.displayType ||
        values?.displayedDate?.[0] ||
        values?.displayedDate?.[1] ||
        values?.displayStatus ||
        values?.menuIds?.length
      ) {
        setIsSubmit(true);
      } else {
        setIsSubmit(false);
      }
      if (resetFormStatus) {
        form.resetFields();
        setResetFormStatus(false);
      }
    }
  }, [
    form,
    isFilter,
    isModalOpen,
    resetFormStatus,
    setResetFormStatus,
    values,
  ]);

  const handleSelectedDate = (dates) => {
    if (dates) {
      form.setFieldValue('displayedDate', [
        dayjs(dates[0]).isValid() ? dayjs(dates[0]) : null,
        dayjs(dates[1]).isValid() ? dayjs(dates[1]) : null,
      ]);
    }
  };

  const onCloseFilter = () => {
    setIsModalOpen(false);
    form.resetFields(['publishedFromToDate']);
  };

  return (
    <AntdModal3
      title={isFilter ? '絞り込み検索' : '情報変更'}
      width={width}
      isModalOpen={isModalOpen}
      onCancel={onCloseFilter}
      justifyContent="flex-end"
      closable={isFilter}
      footer={
        <ButtonModal
          key={'modal'}
          submittable={!isSubmit || isCancel}
          handleOk={() =>
            isFilter
              ? handleOk()
              : handleConfirmUpdateStatus(listDateDisabled[0] ? '表示' : '予約')
          }
          handleReset={handleCloseModalFilter}
          isFilter={isFilter}
          onCancel={onCloseFilter}
        />
      }
    >
      {!isFilter && (
        <Row gutter={25} style={{ marginTop: '20px' }}>
          <Col>
            <FormLabelGrid
              labelText={`一括変更を押下すると、対象レコードが${
                listDateDisabled[0] ? '「表示」' : '「予約」'
              }に変更されます。`}
            />
          </Col>
        </Row>
      )}
      <FormWrapper style={{ marginTop: '20px' }}>
        <Form form={form} layout="horizontal" size="large">
          {isFilter ? (
            <FormModalFilter
              handleSelectedDate={handleSelectedDate}
              form={form}
            />
          ) : (
            <FormModalDisplay form={form} listDateDisabled={listDateDisabled} />
          )}
        </Form>
      </FormWrapper>
    </AntdModal3>
  );
};

export default ModalContentList;
