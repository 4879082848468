import React, { useMemo, useState } from 'react';
import { Alert, Form } from 'antd';
import { FormContainer, TitleAuthentication } from 'page/Authentication/styled';
import { SIGN_IN_PATH } from 'constant/authenticationPath';
import { FormItemButtonSubmit, FormItemChangePassword } from 'components/Form';
import { useForm } from 'antd/es/form/Form';
import { useMutation, useQuery } from 'react-query';
import {
  checkTokenResetPassword,
  resetPassword,
} from 'api/authentication/authen.api';
import { useLocation, useNavigate } from 'react-router-dom';
import useMultiLanguageResetPassword from 'page/Authentication/ForgotPassword/hook/useMultiLanguageResetPassword';
import { useRecoilState } from 'recoil';
import { EXPIRED_TOKEN, INVALID_TOKEN } from 'constant/errorMessage';
import { messageFeedback } from 'recoil/atom/authentication';
import useErrorCodeForgotPassword from 'page/Authentication/ForgotPassword/hook/useErrorCodeForgotPassword';
import useCheckErrorCode from 'hook/useCheckErrorCode';
import useSetTitlePage from 'hook/useSetTitlePage';

const ResetPassword = () => {
  const { search } = useLocation();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [formResetPassword] = useForm();
  const [, setShowMessageFeedback] = useRecoilState(messageFeedback);
  const { URL_EXPIRE_12H, URL_TIME_OUT } = useMultiLanguageResetPassword();
  const { ERROR_CODE_FORGOT_PASSWORD } = useErrorCodeForgotPassword();
  const { handleRenderMessage, handleClearErrorMessage, errorMessage } =
    useCheckErrorCode({ errorCode: ERROR_CODE_FORGOT_PASSWORD });
  const { CHANGE_PASSWORD_MESSAGE_SUCCESS, CHANGE_PASSWORD_TITLE } =
    useMultiLanguageResetPassword();
  useSetTitlePage('Solitonパートナーサイト│パスワード再設定');
  const getEmailAndTokenFromUrl = useMemo(() => {
    let emailIndex = search.indexOf('email=');
    let tokenIndex = search.indexOf('&token=');
    let emailValue = search.slice(emailIndex + 6, tokenIndex);
    let tokenValue = search.slice(tokenIndex + 7);
    return {
      emailValue,
      tokenValue,
    };
  }, [search]);

  // check url reset password time out or expire
  useQuery({
    queryFn: ({ signal }) =>
      checkTokenResetPassword({
        email: getEmailAndTokenFromUrl.emailValue,
        token: getEmailAndTokenFromUrl.tokenValue,
        signal,
      }),
    onError: (response) => {
      let errorMessage = response.response.data.message;
      let errorCode = response.response.data.ec;

      // show message feed back
      if (errorCode && errorMessage === INVALID_TOKEN) {
        // token time out
        setShowMessageFeedback({
          display: true,
          content: URL_TIME_OUT,
          type: 'error',
        });
      } else if (errorCode && errorMessage === EXPIRED_TOKEN) {
        // token expire 12h
        setShowMessageFeedback({
          display: true,
          content: URL_EXPIRE_12H,
          type: 'error',
        });
      }
      // navigate(`/${FORGOT_PASSWORD_PATH}`);
    },
  });

  // call API change password
  const { mutate, isError } = useMutation({
    mutationFn: resetPassword,
    onSuccess: () => {
      setLoading(false);
      handleClearErrorMessage();
      // show message feed back
      setShowMessageFeedback({
        display: true,
        content: CHANGE_PASSWORD_MESSAGE_SUCCESS,
        type: 'success',
      });
      navigate(`${SIGN_IN_PATH}`);
    },
    onError: (response) => {
      setLoading(false);
      handleRenderMessage(response);
    },
  });

  // get data from form
  const onFinish = ({ password, verifyPassword }) => {
    setLoading(true);
    if (password === verifyPassword) {
      const payload = {
        email: getEmailAndTokenFromUrl.emailValue,
        token: getEmailAndTokenFromUrl.tokenValue,
        password: verifyPassword,
      };
      mutate(payload);
    }
  };

  return (
    <>
      <FormContainer className="form-responsive">
        <TitleAuthentication className="title-form-reset-password-responsive">
          {CHANGE_PASSWORD_TITLE}
        </TitleAuthentication>
        {isError && errorMessage !== '' && (
          <Alert
            className="alert"
            message={errorMessage}
            type="error"
            style={{ marginBottom: '24px' }}
          />
        )}
        <Form
          form={formResetPassword}
          name="reset-password"
          onFinish={onFinish}
          layout={'vertical'}
        >
          <FormItemChangePassword form={formResetPassword} />
          <FormItemButtonSubmit
            size="14px"
            button="reset"
            loading={loading}
            formName="reset-password"
          />
        </Form>
      </FormContainer>
      <div className="blank-space-reset-password" />
    </>
  );
};

export default ResetPassword;
