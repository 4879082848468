import styled from 'styled-components';
import pinnedLineIcon from 'assets/logo/clarity_pinned-line.svg';
import pinned from 'assets/logo/pinned.svg';

export const PinnedLineIcon = styled.div`
  padding: 12px;
  background-image: url(${pinnedLineIcon});
`;
export const Pinned = styled.div`
  padding: 9px;
  background-image: url(${pinned});
`;

export const TreeWrapper = styled.div`
  margin: -12px 0 20px 0;
`;

export const FormDetailTitle = styled.a`
  font-family: 'inter-regular', sans-serif;
  height: 40px;
  margin-left: 20px;
  color: var(--BTN-TEXT-SECONDARY-default);
  font-weight: 700;
  cursor: ${(props) => props?.cursor ?? 'pointer'};
  font-size: 16px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  pointer-events: ${(props) => props?.pointerEvents};
  &.link {
    text-decoration: underline;
  }

  &:hover {
    color: ${(props) => props?.color};
  }

  @media all and (min-width: 0px) and (max-width: 320px) {
    max-width: 50px;
  }
  @media all and (min-width: 321px) and (max-width: 430px) {
    max-width: 80px;
  }
  @media all and (min-width: 431px) and (max-width: 768px) {
    max-width: 150px;
  }
  @media all and (min-width: 769px) and (max-width: 1024px) {
    max-width: 460px;
  }
  @media all and (min-width: 1025px) and (max-width: 1200px) {
    max-width: 700px;
  }
  @media all and (min-width: 1201px) and (max-width: 1440px) {
    max-width: 650px;
  }

  // Tach ra cho 2 truong hop hien thi o view va o edit mode
  @media all and (min-width: 1441px) and (max-width: 1720px) {
    max-width: 800px;
  }
  // Tach ra cho 2 truong hop hien thi o view va o edit mode
  @media all and (min-width: 1721px) and (max-width: 1921px) {
    max-width: 1260px;
  }
`;
