import React, { useEffect, useMemo, useState } from 'react';
import { ButtonBreadcrumb } from 'components/Button';
import { Link, useNavigate, useParams } from 'react-router-dom';
import {
  ListFilePartner,
  ListNotification,
} from 'page/Application/PartnerWebsite/component';
import { getPartnerPage } from 'api/partner/partner.api';
import { useRecoilValue } from 'recoil';
import { StyleSheetManager } from 'styled-components';
import isPropValid from '@emotion/is-prop-valid';
import Breadcrumb from 'components/Layout/ApplicationLayout/Breadcrumb';
import useSetTitlePage from 'hook/useSetTitlePage';
import useMultiLanguagePagePartner from 'page/Application/PartnerWebsite/useMultiLanguagePagePartner';
import useCustomQuery from 'hook/useQuery/useCustomQuery';
import { Spin, message } from 'antd';
import { partnerIdRecoil, userInformation } from 'recoil/atom/authentication';
import {
  GridLayout,
  HeaderShowEmail,
  HeaderTitleName,
} from 'page/Application/PartnerWebsite/styled';
import { BreadCrumbDetail } from 'page/Application/ManageProductAndService/TopPageProduct/TopPageProductDetail/styled';
import { network } from 'recoil/atom/network';
// This implements the default behavior from styled-components v5
const shouldForwardProp = (propName, target) => {
  if (typeof target === 'string') {
    // For HTML elements, forward the prop if it is a valid HTML attribute
    return isPropValid(propName);
  }
  // For other elements, forward all props
  return true;
};

const PartnerWebsiteOverView = () => {
  const isOnline = useRecoilValue(network);
  const [messageErrorNetWork, contextErrorNetWork] = message.useMessage();
  useSetTitlePage('パートナー専用ページ');
  const { useQueryAPI } = useCustomQuery();
  const { partnerId: partnerIdParam } = useParams();
  const navigate = useNavigate();
  const { role } = useRecoilValue(userInformation);
  const { partnerId } = useRecoilValue(partnerIdRecoil);
  const [mainPartnerId] = useState(
    partnerIdParam ||
      partnerId ||
      JSON.parse(localStorage['user_information'])?.partnerId,
  );
  const { PAGE_PARTNER_TITLE_INFORMATION } = useMultiLanguagePagePartner();
  const {
    data: partnerPageDetail,
    isLoading,
    error,
  } = useQueryAPI({
    api: getPartnerPage,
    key: 'partnerPageDetail',
    params: {
      partnerId: mainPartnerId,
    },
    enabled: !!mainPartnerId,
  });
  const errorNetWork = () => {
    messageErrorNetWork
      .open({
        type: 'error',
        content:
          '問題が発生しました。インターネット接続を確認して、もう一度やり直してください',
      })
      .then();
  };

  const handleNavigate = () => {
    if (isOnline) {
      navigate(
        role === 'ROLE_PARTNER_ADMIN' || role === 'ROLE_PARTNER_USER'
          ? '/partner-page/edit'
          : `/partner-page/${mainPartnerId}/edit`,
        {
          state: { partnerId: mainPartnerId },
        },
      );
    } else {
      errorNetWork();
    }
  };

  const listItem_SA = useMemo(() => {
    return [
      {
        title: <Link to={`/manage-company-list`}>企業管理</Link>,
      },
      {
        title: (
          <Link to={`/manage-company-list/edit/${mainPartnerId}`}>
            企業詳細
          </Link>
        ),
      },
      {
        title: <BreadCrumbDetail>パートナー専用ページ</BreadCrumbDetail>,
      },
    ];
  }, [mainPartnerId]);

  const listItem_SU = useMemo(() => {
    return [
      {
        title: <Link to={`/my-page`}>マイページ</Link>,
      },
      {
        title: <BreadCrumbDetail>パートナー専用ページ</BreadCrumbDetail>,
      },
    ];
  }, []);

  const listItem_PA_PU = useMemo(() => {
    return [
      {
        title: <BreadCrumbDetail>パートナー専用ページ</BreadCrumbDetail>,
      },
    ];
  }, []);

  const listItemByRole = useMemo(() => {
    return {
      ROLE_SOLITON_ADMIN: listItem_SA,
      ROLE_SOLITON_USER: listItem_SU,
      ROLE_PARTNER_ADMIN: listItem_PA_PU,
      ROLE_PARTNER_USER: listItem_PA_PU,
    };
  }, [listItem_SA, listItem_SU, listItem_PA_PU]);

  useEffect(() => {
    if (
      partnerPageDetail?.ec ||
      error?.response?.data?.message === '400_PARTNER_NOT_EXISTS' ||
      error?.response?.data?.message === '400_ARGUMENT_IS_NOT_VALID' ||
      error?.response?.data?.message === '400_PARTNER_IS_NOT_YOUR_IN_CHARGE'
    ) {
      navigate('/partner-not-found');
    }
  }, [partnerPageDetail, navigate, error?.response?.data?.message]);

  return (
    <StyleSheetManager shouldForwardProp={shouldForwardProp}>
      {contextErrorNetWork}
      <Spin spinning={isLoading}>
        <Breadcrumb
          listItem={listItemByRole[role]}
          titlePage="パートナー専用ページ"
        >
          {role === 'ROLE_PARTNER_USER' ? null : (
            <ButtonBreadcrumb
              width={100}
              icon={'none'}
              text={'編集'}
              onClick={handleNavigate}
            />
          )}
        </Breadcrumb>
        <div className="information-wrapper">
          {partnerPageDetail?.data?.partnerName && (
            <HeaderTitleName className="base-text information-wrapper__title">
              {partnerPageDetail?.data?.partnerName}
            </HeaderTitleName>
          )}
          <HeaderShowEmail className="base-text">
            <span className="text">{PAGE_PARTNER_TITLE_INFORMATION}：</span>
            <span className="text">
              {partnerPageDetail?.data?.solitonEmail}
            </span>
          </HeaderShowEmail>
        </div>
        <GridLayout>
          <ListNotification
            marginDateForOverview={12}
            displayForOverview={'inherit'}
            partnerId={mainPartnerId}
            dataRaw={partnerPageDetail?.data?.noticeResponses}
            isLoading={isLoading}
            isOnline={isOnline}
            errorNetWork={errorNetWork}
          />
          <ListFilePartner
            dataRaw={partnerPageDetail?.data?.detailResponses}
            isLoading={isLoading}
            isOnline={isOnline}
            errorNetWork={errorNetWork}
          />
        </GridLayout>
      </Spin>
    </StyleSheetManager>
  );
};

export default PartnerWebsiteOverView;
