import styled from 'styled-components';

export const TitleSearchStyle = styled.div`
  word-break: break-word;
  color: #656464;
  font-size: 14px;
  font-weight: 700;
  font-family: 'inter-semibold', sans-serif;
`;
export const TypeSearchStyle = styled.div`
  font-family: 'inter-regular', sans-serif;
  font-weight: 400;
  font-size: 12px;
  color: #a5a5a5;
  padding: 10px 0;
`;

export const ContentSearchStyle = styled.div`
  font-family: 'inter-regular', sans-serif;
  font-weight: 400;
  font-size: 12px;
  color: #a5a5a5;
`;

export const HightlightContentSearchStyle = styled.span`
  font-family: 'inter-regular', sans-serif;
  font-weight: 700;
  font-size: 14px;
  color: #656464;
`;
export const Roww = styled.div`
  display: table;
`;
export const Columnn = styled.div`
  display: table-cell;
`;

export const SearchStyleWrapper = styled.div`
  padding: 0 10px;

  //background: #279863;
`;
