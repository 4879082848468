import React, { useEffect, useState } from 'react';
import { message } from 'antd';
import { Link, useNavigate } from 'react-router-dom';
import { PlusOutlined } from '@ant-design/icons';
import { REGISTER_PATH } from 'constant/appPath';
import {
  defaultPageSizeOptions,
  defaultSelectPageSizeOption,
} from 'constant/Pagination/pageSizeOption';
import { SORT_DIRECTION } from 'constant/sortConstants';
import { getListProduct } from 'api/product/product.api';
import { ButtonBreadcrumb } from 'components/Button';
import Breadcrumb from 'components/Layout/ApplicationLayout/Breadcrumb';
import HeaderListDataLayout from 'components/Layout/HeaderListDataLayout';
import LayoutDefault from 'components/Layout/LayoutDefault';
import Pagination from 'components/Pagination';
import useCustomQuery from 'hook/useQuery/useCustomQuery';
import useSearchKeyword from 'hook/useSearchKeyword';
import TableProduct from './component/TableProduct';
import { useRecoilValue } from 'recoil';
import { network } from 'recoil/atom/network';

const listItemSA = [
  {
    title: <Link to={'/'}>企業管理</Link>,
  },
  {
    title: 'プロダクト管理',
  },
];

const ProductList = () => {
  const [messageErrorNetWork, contextErrorNetWork] = message.useMessage();
  const isOnline = useRecoilValue(network);
  const { searchKeyword, onSearch, emptyText } = useSearchKeyword();
  const [page, setPage] = useState(10);
  const [current, setCurrent] = useState(1);
  const { useQueryAPI } = useCustomQuery();
  const [searchValue, setSearchValue] = useState('');
  const [sortedInfo, setSortedInfo] = useState({});
  const navigate = useNavigate();
  const errorNetWork = () => {
    messageErrorNetWork
      .open({
        type: 'error',
        content:
          '問題が発生しました。インターネット接続を確認して、もう一度やり直してください。',
      })
      .then();
  };
  const handleNavigate = () => {
    if (isOnline) {
      navigate(`${REGISTER_PATH}`);
    } else {
      errorNetWork();
    }
  };

  const handleChangePageSize = (value) => {
    if (isOnline) {
      setPage(value);
      setCurrent(1);
    } else {
      errorNetWork();
    }
  };

  const handleChange = (pagination, filters, sorter) => {
    if (isOnline) {
      setSortedInfo({
        sortField: sorter.order && sorter.field,
        sortDirection: sorter.order && SORT_DIRECTION[sorter.order],
        order: sorter.order,
        columnKey: sorter.columnKey,
      });
    } else {
      errorNetWork();
    }
  };

  const handlePagingChange = (pageNumber, pageSize) => {
    if (isOnline) {
      setCurrent(pageNumber);
      setPage(pageSize);
    } else {
      errorNetWork();
    }
  };

  const { data: listProduct = [], isLoading } = useQueryAPI({
    api: getListProduct,
    key: 'productList',
    params: {
      pageNumber: current,
      pageSize: page,
      keyword: searchKeyword,
      displayedDateFrom: '',
      displayedDateTo: '',
      category: '',
      status: '',
      sortField: sortedInfo?.sortField ?? '',
      sortDirection: sortedInfo?.sortDirection ?? '',
    },
  });

  useEffect(() => {
    if (searchKeyword) {
      setCurrent(1);
      setSortedInfo({});
    }
  }, [searchKeyword]);

  return (
    <>
      {contextErrorNetWork}
      <Breadcrumb listItem={listItemSA} titlePage="プロダクト管理">
        <ButtonBreadcrumb
          text={'新規登録'}
          icon={<PlusOutlined />}
          onClick={handleNavigate}
        />
      </Breadcrumb>
      <LayoutDefault>
        <HeaderListDataLayout
          titleScreen={'プロダクト一覧'}
          searchPlaceholder={
            'プロダクト名、又は、カテゴリ名に従え、検索します。'
          }
          onSearch={onSearch}
          loading={isLoading}
          value={page}
          handleChangePageSize={handleChangePageSize}
          pageSizeOptions={defaultSelectPageSizeOption}
          searchValue={searchValue}
          setSearchValue={setSearchValue}
        />
        <TableProduct
          errorNetWork={errorNetWork}
          isLoading={isLoading}
          dataRaw={listProduct.data}
          emptyText={emptyText}
          sortedInfo={sortedInfo}
          onChange={handleChange}
        />
        <Pagination
          totalTitle={'プロダクト総数'}
          currentPage={current}
          pageSize={page}
          handlePagingChange={handlePagingChange}
          total={listProduct.data?.total}
          pageSizeOptions={defaultPageSizeOptions}
          handleChangePageSize={handleChangePageSize}
        />
      </LayoutDefault>
    </>
  );
};

export default ProductList;
