import { useMemo } from 'react';

const useErrorCodePartnerWebsiteOverView = () => {
  const ERROR_CODE_PARTNER_OVER_VIEW = useMemo(() => {
    return {
      '400_VALIDATION_ERROR': {
        message: '無効なメールアドレスです。形式を正しく入力してください。',
        statusCode: 400,
        type: 'error',
      },
      '400_DETAIL_NOT_EXISTED': {
        message: '問題が発生しました。この操作は実施できておりません。',
        statusCode: 400,
        type: 'error',
      },
      'modifyPartnerPageInfo.solitonEmail: 400_EMAIL_WRONG_FORMAT': {
        message: '無効なメールアドレスです。形式を正しく入力してください。',
        statusCode: 400,
        type: 'error',
      },
    };
  }, []);
  return {
    ERROR_CODE_PARTNER_OVER_VIEW,
  };
};

export default useErrorCodePartnerWebsiteOverView;
