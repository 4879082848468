import styled from 'styled-components';
import { Checkbox } from 'antd';

export const CheckboxStyled = styled(Checkbox)`
  color: ${(props) => (props?.color ? props.color : 'var(--CHECKBOX-default)')};
  font-family: 'inter-regular', sans-serif;

  span {
    font-size: ${(props) => (props?.size ? props.size : '12px')};
  }
`;
