import React, { Fragment, useEffect, useMemo, useState } from 'react';
import { Cascader, Form, Select } from 'antd';
import { getAllMenuProduct, getMenuProduct } from 'api/product/product.api';
import FormMessage from 'components/Form/FormMessage/index';
import { FormItemStyled } from 'components/Form2/styled';
import useCustomQuery from 'hook/useQuery/useCustomQuery';
import {
  ButtonStyle,
  Grid3,
} from 'page/Application/ManageCompanyList/component/Form/styled';
import FormLabelGrid from 'page/Application/ManageProjectRegistration/component/FormLabelGrid';
import {
  Grid1,
  Grid2,
} from 'page/Application/ManageProjectRegistration/component/styled';

const FormProductMenu = ({
  name,
  required,
  listProduct,
  form,
  isEdit,
  lstIdProduct,
}) => {
  const { useQueryAPI } = useCustomQuery();
  const [listMenu, setListMenu] = useState([]);
  const [, setListIdProduct] = useState([]);
  const [idProductSelect, setIdProductSelect] = useState(null);
  const [isOnlyOne, setIsOnlyOne] = useState(true);

  const [isAvailable, setIsAvailable] = useState(true);

  const { refetch } = useQueryAPI({
    api: getMenuProduct,
    key: 'listMenuProduct',
    params: { productId: idProductSelect },
    enabled: !!idProductSelect,
  });

  const { data: listMenuDetail = [] } = useQueryAPI({
    api: getAllMenuProduct,
    key: 'listMenuProduct',
    params: { productIds: lstIdProduct },
    enabled: !!lstIdProduct,
    options: {
      refetchOnWindowFocus: false,
    },
  });

  const listProductOptions = useMemo(() => {
    return listProduct?.data?.map((item) => ({
      value: item?.productId,
      title: '',
      label: item?.productName,
    }));
  }, [listProduct]);

  const handleChangeMenu = async (value, key) => {
    if (value) {
      await setListIdProduct((prevState) => {
        const existingItemIndex = prevState.findIndex(
          (item) => item.key === key,
        );
        if (existingItemIndex !== -1) {
          prevState[existingItemIndex].value = value;
          return [...prevState];
        } else {
          return [...prevState, { key: key, value: value }];
        }
      });
    }

    await setListIdProduct((prevState) => {
      prevState.forEach((value) => {
        if (value?.key === key) {
          setIdProductSelect(value?.value);
        }
      });
      return prevState;
    });

    await refetch().then((value) => {
      setListMenu((prevState) => {
        const findIndex = prevState.findIndex((item) => item.key === key);
        if (findIndex !== -1) {
          let valueForm = form.getFieldValue('menuIdLst');
          if (valueForm[findIndex]) {
            valueForm[findIndex].menuId = [];
          }
          form.setFieldValue('menuIdLst', valueForm);
          prevState[findIndex].data = value?.data?.data;
          return [...prevState];
        } else {
          return [
            ...prevState,
            {
              key: key,
              data: value?.data?.data,
            },
          ];
        }
      });
    });
    setIsAvailable(true);
  };

  const handleChangeDetail = (value, key) => {
    setListMenu((prevState) => {
      const existingItemIndex = prevState?.findIndex(
        (item) => item?.key === key,
      );
      if (existingItemIndex !== -1) {
        let valueForm = form.getFieldValue('menuIdLst');
        if (valueForm[key]) {
          valueForm[key].menuName = '';
        }
        form.setFieldValue('menuIdLst', valueForm);
        return [...prevState];
      }
    });
  };

  useEffect(() => {
    if (isEdit && isOnlyOne && lstIdProduct?.[0]) {
      listMenuDetail?.data?.forEach((values, index) => {
        setListMenu((prevState) => {
          return [
            ...prevState,
            {
              key: index,
              productId: values.productId,
              data: values.children,
            },
          ];
        });
        setIsOnlyOne(false);
      });
      setIsAvailable(false);
    }
  }, [isEdit, isOnlyOne, listMenuDetail?.data, lstIdProduct]);

  return (
    <>
      <Grid1>
        <FormLabelGrid
          labelText={'表示プロダクト・メニュー'}
          required={required}
        />
      </Grid1>
      <Form.List name={name} initialValue={[{}]}>
        {(fields, { add, remove }) => (
          <>
            {fields.map(({ key, name, ...restField }, index) => (
              <Fragment key={key}>
                <Grid2>
                  <FormItemStyled
                    marginbottom="1px"
                    name={[name, 'productId']}
                    rules={[
                      {
                        validator(_, value) {
                          setIsAvailable(true);
                          let data = form.getFieldValue('menuIdLst')[index];
                          if (data?.productId && data?.menuId) {
                            setIsAvailable(false);
                          }
                          return Promise.resolve();
                        },
                      },
                    ]}
                  >
                    <Select
                      onChange={(value) => {
                        handleChangeMenu(value, key);
                      }}
                      options={listProductOptions}
                      style={{ marginBottom: 10 }}
                      placeholder={'プロダクト'}
                      allowClear
                    />
                  </FormItemStyled>
                  <FormItemStyled
                    marginbottom="10px"
                    name={[name, 'menuId']}
                    rules={[
                      {
                        validator(_, value) {
                          setIsAvailable(true);
                          let data = form.getFieldValue('menuIdLst')[index];
                          if (data?.productId && !data?.menuId?.length) {
                            return Promise.reject(
                              <FormMessage
                                text1={'メニュー名を選択してください。'}
                                marginBottom={'12px'}
                              />,
                            );
                          }
                          if (data?.productId && data?.menuId) {
                            setIsAvailable(false);
                          }
                          return Promise.resolve();
                        },
                      },
                    ]}
                  >
                    <Cascader
                      onChange={(value) => {
                        handleChangeDetail(value, key);
                      }}
                      placeholder={'メニュー'}
                      options={listMenu?.[index]?.data}
                      notFoundContent={'データがありません'}
                      changeOnSelect
                    />
                  </FormItemStyled>
                </Grid2>
                <Grid3>
                  <ButtonStyle
                    style={{ marginLeft: '15px' }}
                    onClick={() => {
                      remove(index);
                      listMenu.splice(index, 1);
                      const menuIdLst = form.getFieldValue('menuIdLst');
                      let data = menuIdLst[menuIdLst.length - 1];
                      if (data?.productId && data?.menuId) {
                        setIsAvailable(false);
                      } else {
                        if (!menuIdLst.length) {
                          setIsAvailable(false);
                        } else {
                          setIsAvailable(true);
                        }
                      }
                    }}
                  >
                    <span> {'削除'}</span>
                  </ButtonStyle>
                </Grid3>
              </Fragment>
            ))}
            {/* Nut Them */}
            <Grid2>
              <FormItemStyled>
                <ButtonStyle
                  disabled={isAvailable}
                  onClick={() => {
                    add();
                    setIsAvailable(true);
                  }}
                >
                  <span> {'追加'}</span>
                </ButtonStyle>
              </FormItemStyled>
            </Grid2>
          </>
        )}
      </Form.List>
    </>
  );
};
export default FormProductMenu;
