import React from 'react';
import { ExclamationCircleFilled } from '@ant-design/icons';
import { Button, Modal } from 'antd';
import useMultiLanguage from 'hook/useMultiLanguage';
import { FooterModal } from 'style/styleComponent/styled';

const useModalConfirmAddDomainList = () => {
  const [modal, contextHolder] = Modal.useModal();

  const { APPLICATION_OK_TEXT, APPLICATION_CANCEL_TEXT } = useMultiLanguage();

  const handleConfirmAction = ({
    confirmFn: handleFn,
    title,
    okText,
    cancelText,
    params,
    setIsDisable,
    form,
    setIsAvailable,
  }) => {
    const handleKeyDown = (event) => {
      if (event.keyCode === 27) {
        // Check if the pressed key is Esc (keyCode = 27)
        setIsDisable(false);
      }
    };

    document.addEventListener('keyup', handleKeyDown);

    modal.confirm({
      title: (
        <span
          style={{
            color: '#656464',
            fontSize: 16,
            fontWeight: 500,
          }}
        >
          {title ? title : '選択されている企業を削除しますか？'}
        </span>
      ),
      width: 480,
      styles: {
        body: {
          minHeight: 100, // Set the desired max height of the modal content
        },
      },
      icon: <ExclamationCircleFilled style={{ color: '#9d1c49' }} />,
      centered: true,
      footer: (
        <FooterModal>
          <Button
            type={'primary'}
            style={{ width: 80, height: 40 }}
            onClick={() => {
              handleFn(params);
              Modal.destroyAll();
              setIsDisable(false);
              let data = form?.getFieldValue('domainList');
              let value = data[data.length - 1];
              if (!value?.domainName && value !== undefined) {
                setIsAvailable(true);
              } else {
                setIsAvailable(false);
              }
            }}
          >
            <span> {okText ?? APPLICATION_OK_TEXT}</span>
          </Button>
          <Button
            style={{ width: 120, height: 40, color: '#656464' }}
            onClick={() => {
              Modal.destroyAll();
              setIsDisable(false);
            }}
          >
            <span> {cancelText ?? APPLICATION_CANCEL_TEXT}</span>
          </Button>
        </FooterModal>
      ),
    });
  };

  return { handleConfirmAction, contextHolder };
};

export default useModalConfirmAddDomainList;
