import { atom } from 'recoil';
import { localStorageEffect } from 'recoil/localStorage/localStorageEffect';

export const authenticationToken = atom({
  key: 'authenticationToken',
  default: {
    accessToken: '',
    passwordExpiredRemainingDay: 0,
  },
  effects: [localStorageEffect('user_token')],
});

export const userInformation = atom({
  key: 'userInformation',
  default: {
    email: '',
    firstName: '',
    lastName: '',
    role: '',
    approveStatus: '',
    userId: 0,
    partnerId: '',
    partnerName: '',
  },
  effects: [localStorageEffect('user_information')],
});

export const userAccount = atom({
  key: 'userAccount',
  default: {
    email: '',
    password: '',
    remember: false,
  },
  effects: [localStorageEffect('user_account')],
});

export const messageFeedback = atom({
  key: 'messageFeedback',
  default: {
    display: false,
    content: '',
    type: 'error',
  },
});

export const passwordExpire = atom({
  key: 'passwordExpire',
  default: false,
  effects: [localStorageEffect('password_expire')],
});

export const partnerIdRecoil = atom({
  key: 'partnerIdRecoil',
  default: {
    partnerId: '',
  },
});
