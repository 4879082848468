import axiosClient from 'api/config/axiosClient';

const getListProject = ({
  pageNumber,
  pageSize,
  keyword,
  displayedDateFrom,
  displayedDateTo,
  category,
  status,
  sortField,
  sortDirection,
  isViewMode,
}) => {
  const url = `v1/project?pageNumber=${pageNumber}&pageSize=${pageSize}&keyword=${keyword}&displayedDateFrom=${displayedDateFrom}&displayedDateTo=${displayedDateTo}&category=${category}&status=${status}&sortField=${sortField}&sortDirection=${sortDirection}&isViewMode=${isViewMode}`;
  return axiosClient.get(url);
};

const getDetailProject = ({projectId}) => {
  const url = `v1/project/${projectId}`
  return axiosClient.get(url);
}

const getDetailPublicProject = ({projectId}) => {
  const url = `v1/project/public/${projectId}`
  return axiosClient.get(url)
}

const registerProject = (payload) => {
  const url = 'v1/project';
  return axiosClient.post(url, payload, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
};

const updateProject = ({ projectId, payload }) => {
  const url = `v1/project/${projectId}`;
  return axiosClient.put(url, payload, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
};

const deleteProject = ({ projectId }) => {
  const url = `v1/project/${projectId}`;
  return axiosClient.delete(url);
};

export {
  getListProject,
  registerProject,
  updateProject,
  deleteProject,
  getDetailProject,
  getDetailPublicProject
};
