import { useMemo } from 'react';
import useMultiLanguageRegisterAccount from 'page/Application/ManageAccountSoliton/RegisterAccountSoliton/hook/useMultiLanguageRegisterAccount';

const useErrorCodeAccountPartner = () => {
  const {
    // REGISTER_ACCOUNT_EMAIL_ALREADY_EXISTED,

    DETAIL_ACCOUNT_NEW_PASSWORD_MUST_DIFFERENT_FROM_OLD_PASSWORD,
    REGISTER_ACCOUNT_MESSAGE_EMAIL_INPUT_TYPE,
  } = useMultiLanguageRegisterAccount();
  const ERROR_CODE_ACCOUNT_PARTNER = useMemo(() => {
    return {
      '401_EMAIL_ALREADY_EXISTED': {
        message: 'メールアドレスはすでに登録されています。',
        // message: REGISTER_ACCOUNT_EMAIL_ALREADY_EXISTED,
        statusCode: 400,
        type: 'error',
      },
      '400_NEW_PASSWORD_MUST_DIFFERENT_FROM_OLD_PASSWORD': {
        message: DETAIL_ACCOUNT_NEW_PASSWORD_MUST_DIFFERENT_FROM_OLD_PASSWORD,
        statusCode: 400,
        type: 'error',
      },
      '400_DOMAIN_NAME_HAS_BEEN_USED': {
        message: '「ドメイン名」がすでに登録されています。',
        statusCode: 400,
        type: 'error',
      },
      '400_VALIDATION_ERROR': {
        message: REGISTER_ACCOUNT_MESSAGE_EMAIL_INPUT_TYPE,
        statusCode: 400,
        type: 'error',
      },
      '404_DOMAIN_NOT_FOUND': {
        message: 'このアカウントは未登録企業です。',
        statusCode: 400,
        type: 'error',
      },
      '400_DOMAIN_NOT_BELONG_TO_SOLITON': {
        message: 'このドメインはソリトンに該当していません。',
        statusCode: 400,
        type: 'error',
      },
    };
  }, [
    DETAIL_ACCOUNT_NEW_PASSWORD_MUST_DIFFERENT_FROM_OLD_PASSWORD,
    REGISTER_ACCOUNT_MESSAGE_EMAIL_INPUT_TYPE,
    // REGISTER_ACCOUNT_EMAIL_ALREADY_EXISTED,
  ]);
  return {
    ERROR_CODE_ACCOUNT_PARTNER,
  };
};

export default useErrorCodeAccountPartner;
