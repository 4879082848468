import styled from 'styled-components';
import { Divider } from 'antd';

export const CategoryTopPageSpan = styled.div`
  font-family: 'inter-regular', sans-serif;
  font-weight: 700;
  max-width: 500px;
  text-align: left;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  color: #656464;
  margin-left: 10px;
  font-size: 16px;
  @media all and (min-width: 0px) and (max-width: 320px) {
    font-size: 14px;
    max-width: 100px;
  }
  @media all and (min-width: 320px) and (max-width: 430px) {
    font-size: 14px;
    max-width: 200px;
  }
`;

export const DividerStyled = styled(Divider)`
  &.ant-divider-horizontal.ant-divider-with-text::before,
  &.ant-divider-horizontal.ant-divider-with-text::after {
    border-block-start: 0.5px solid #d2d3d4 !important;
    transform: none;
  }
`;
