import { useMemo } from 'react';
import useMultiLanguageRegisterAccount from 'page/Application/ManageAccountSoliton/RegisterAccountSoliton/hook/useMultiLanguageRegisterAccount';
import useMultiLanguage from 'hook/useMultiLanguage';

const useErrorCodeManageAccountSoliton = () => {
  const {
    REGISTER_ACCOUNT_EMAIL_ALREADY_EXISTED,
    REGISTER_ACCOUNT_MESSAGE_EMAIL_INPUT_TYPE,
    DETAIL_ACCOUNT_NEW_PASSWORD_MUST_DIFFERENT_FROM_OLD_PASSWORD,
  } = useMultiLanguageRegisterAccount();
  const {
    APPLICATION_DOMAIN_NOT_REGISTERED,
    APPLICATION_DOMAIN_NOT_BELONG_TO_PARTNER,
  } = useMultiLanguage();
  const ERROR_CODE_MANAGE_ACCOUNT_SOLITON = useMemo(() => {
    return {
      '401_EMAIL_ALREADY_EXISTED': {
        message: REGISTER_ACCOUNT_EMAIL_ALREADY_EXISTED,
        statusCode: 400,
        type: 'error',
      },
      '404_DOMAIN_NOT_FOUND': {
        message: APPLICATION_DOMAIN_NOT_REGISTERED,
        statusCode: 400,
        type: 'error',
      },
      '400_VALIDATION_ERROR': {
        message: REGISTER_ACCOUNT_MESSAGE_EMAIL_INPUT_TYPE,
        statusCode: 400,
        type: 'error',
      },
      '400_NEW_PASSWORD_MUST_DIFFERENT_FROM_OLD_PASSWORD': {
        message: DETAIL_ACCOUNT_NEW_PASSWORD_MUST_DIFFERENT_FROM_OLD_PASSWORD,
        statusCode: 400,
        type: 'error',
      },
      '400_DOMAIN_NOT_BELONG_TO_PARTNER': {
        message: APPLICATION_DOMAIN_NOT_BELONG_TO_PARTNER,
        statusCode: 400,
        type: 'error',
      },
    };
  }, [
    APPLICATION_DOMAIN_NOT_REGISTERED,
    DETAIL_ACCOUNT_NEW_PASSWORD_MUST_DIFFERENT_FROM_OLD_PASSWORD,
    REGISTER_ACCOUNT_EMAIL_ALREADY_EXISTED,
    REGISTER_ACCOUNT_MESSAGE_EMAIL_INPUT_TYPE,
    APPLICATION_DOMAIN_NOT_BELONG_TO_PARTNER,
  ]);
  return {
    ERROR_CODE_MANAGE_ACCOUNT_SOLITON,
  };
};

export default useErrorCodeManageAccountSoliton;
