const useStatusProject = () => {
  const STATUS_FOR_LIST = {
    SHOW: '表示',
    HIDE: '非表示',
  };
  const STATUS_FOR_FORM = [
    {
      id: 1,
      label: '表示',
      value: true,
    },
    {
      id: 2,
      label: '非表示',
      value: false,
    },
  ];

  return {
    STATUS_FOR_FORM,
    STATUS_FOR_LIST,
  };
};

export default useStatusProject;
