import React from 'react';
import { PlusOutlined } from '@ant-design/icons';
import { BtnBreadcrumb } from 'components/Button/styled';

const ButtonBreadcrumb = ({
  text,
  onClick,
  icon,
  type,
  width,
  marginRight,
  color,
}) => {
  return (
    <BtnBreadcrumb
      type={type}
      size="large"
      style={{ width: width, marginRight: marginRight }}
      onClick={onClick}
      icon={icon === 'none' ? null : icon ?? <PlusOutlined />}
    >
      <span style={{ color: color }}> {text ? text : '新規登録'}</span>
    </BtnBreadcrumb>
  );
};

export default ButtonBreadcrumb;
