import {
  Grid1,
  Grid2,
} from 'page/Application/ManageProjectRegistration/component/styled';
import { FormLabelGrid } from 'page/Application/ManageCompanyList/component/Form';
import AntdTable from 'components/AntdTable';
import React, { useEffect, useMemo } from 'react';
import { ButtonAction, GroupButtonAction } from 'style/styleComponent/styled';
import { DeleteOutlined } from '@ant-design/icons';
import useModalConfirm from 'hook/useModalConfirm';
import FormAddTitleFile from 'page/Application/ManageProjectRegistration/component/FormAddTitleFile';
import { useRecoilState } from 'recoil';
import { fileDetailsNew, listFileDetailsRecoil } from 'recoil/atom/fileRequest';
import dayjs from 'dayjs';

const FormPermission = ({ isLoading, height, isDisable }) => {
  const { contextHolder } = useModalConfirm();
  const [newFileDetails] = useRecoilState(fileDetailsNew);
  const [listFileDetails, setListFileDetails] = useRecoilState(
    listFileDetailsRecoil,
  );
  const dataSource = useMemo(() => {
    if (listFileDetails?.length === 0 || listFileDetails?.title === '') return;
    return listFileDetails?.map((item, index) => ({
      ...item,
      key: index,
    }));
  }, [listFileDetails]);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleDeleteProject = (id) => {
    // Xóa phần tử có id tương ứng trong mảng dataRawTest
    const newDataRaw = dataSource?.filter((item) => item.key !== id);
    // Cập nhật lại dataRawTest
    setListFileDetails(newDataRaw);
  };

  const columns = useMemo(() => {
    // define columns
    return [
      {
        key: 'title',
        title: 'タイトル',
        dataIndex: 'title',
        ellipsis: true,
      },
      {
        key: 'displayDate',
        title: '表示日付',
        dataIndex: 'displayDate',
        ellipsis: true,
        render: (displayDate) => {
          const formattedDate = dayjs(displayDate)
            .locale('ja')
            .format('YYYY年MM月DD日');
          return <>{formattedDate !== 'Invalid Date' ? formattedDate : ''}</>;
        },
      },
      {
        key: 'displayStatus',
        title: 'ステータス',
        dataIndex: 'displayStatus',
        ellipsis: true,
        render: (approveStatus) => {
          return <>{approveStatus === true ? '表示' : '非表示'}</>;
        },
      },
      {
        key: 'key',
        title: <span style={{ paddingLeft: 22 }}> 操作</span>,
        dataIndex: 'key',
        render: (userId, record) => (
          <GroupButtonAction>
            <FormAddTitleFile
              userId={userId}
              dataSource={dataSource[userId]}
              data={dataSource}
              isEdit={true}
              formName={`editForm`}
            />
            <ButtonAction
              type="link"
              disabled={isDisable}
              onClick={() => {
                handleDeleteProject(userId);
              }}
            >
              <DeleteOutlined />
            </ButtonAction>
          </GroupButtonAction>
        ),
        align: 'left',
        width: 120,
      },
    ];
  }, [dataSource, handleDeleteProject, isDisable]);

  useEffect(() => {
    setListFileDetails(
      newFileDetails?.title !== ''
        ? (prevList) => [...prevList, newFileDetails]
        : [],
    );
  }, [newFileDetails, setListFileDetails]);

  return (
    <>
      <Grid1>
        <FormLabelGrid labelText={'ファイル'} />
      </Grid1>
      <Grid2>
        {contextHolder}
        {listFileDetails?.length ? (
          <div style={{ marginBottom: 24 }}>
            <AntdTable
              height={height}
              columns={columns}
              dataSource={dataSource}
              isLoading={isLoading}
            />
          </div>
        ) : (
          ''
        )}
        <FormAddTitleFile isEdit={false} formName="addForm" />
      </Grid2>
    </>
  );
};
export default FormPermission;
