import { useMemo } from 'react';

const useErrorCodeManageContent = () => {
  const ERROR_CODE_MANAGE_CONTENT = useMemo(() => {
    return {
      '401_USER_NOT_FOUND': {
        message: 'USER_NOT_FOUND ',
        statusCode: 401,
        type: 'error',
      },
      '400_DETAIL_NOT_EXISTED': {
        message: '問題が発生しました。この操作は実施できておりません。',
        statusCode: 400,
        type: 'error',
      },
      '400_PUBLISHED_TO_MUST_AFTER_NOW': {
        message: '「非公開日時予約」は現在時点後よりの日時を入力してください。',
        statusCode: 400,
        type: 'error',
      },
      '400_PUBLISHED_FROM_MUST_AT_LEAST_FROM_NOW': {
        message: '「公開日時予約」は現在時点後よりの日時を入力してください。',
        statusCode: 400,
        type: 'error',
      },
      '400_PUBLISHED_FROM_MUST_BEFORE_PUBLISHED_TO': {
        message:
          '「公開日時予約」を「非公開日時予約」以降の日時で選択してください。 ',
        statusCode: 400,
        type: 'error',
      },
      '400_PUBLISHED_FROM_AND_PUBLISHED_TO_MUST_AFTER_NOW': {
        message: '公開日時予約・非公開日時予約が無効です。',
        statusCode: 400,
        type: 'error',
      },
      '400_DETAIL_MUST_HAS_FILE': {
        message: 'コンテンツにはファイルが必要です',
        statusCode: 400,
        type: 'error',
      },
      '400_MENU_NOT_EXISTED': {
        message: '問題が発生しました。この操作は実施できておりません。',
        statusCode: 400,
        type: 'error',
      },
      '400_PRODUCT_NOT_EXIST': {
        message: '問題が発生しました。この操作は実施できておりません。',
        statusCode: 400,
        type: 'error',
      },
      '400_MENU_ID_IS_DUPLICATED': {
        message: '重複するメニュー名があります。',
        statusCode: 400,
        type: 'error',
      },
    };
  }, []);
  return {
    ERROR_CODE_MANAGE_CONTENT,
  };
};

export default useErrorCodeManageContent;
