import React from 'react';
import { Modal } from 'antd';
import { ExclamationCircleFilled } from '@ant-design/icons';
import { ButtonGroup, TitleStyle } from 'components/AntdModal/styled';
import useMultiLanguageRegisterCompany from 'page/Application/ManageCompanyList/RegisterCompany/hook/useMultiLanguageRegisterCompany';
import { ButtonOutline, ButtonPrimary } from 'components/Button';

const AntdModal = ({
  isModalOpen,
  handleOk,
  onCancel,
  loadingSubmit,
  title,
  children,
  width,
  textSubmit,
  isDisabled,
}) => {
  const { REGISTER_COMPANY_CANCEL } = useMultiLanguageRegisterCompany();
  return (
    <Modal
      forceRender
      title={<TitleStyle>{title ?? 'Basic Modal'}</TitleStyle>}
      open={isModalOpen}
      onOk={handleOk}
      onCancel={onCancel}
      centered={true}
      width={width ?? 430}
      maskClosable={false}
      icon={<ExclamationCircleFilled />}
      destroyOnClose={true}
      closable={!isDisabled}
      footer={[
        <ButtonGroup key="footerModal">
          <ButtonPrimary
            key="submit"
            type="primary"
            loading={loadingSubmit}
            onClick={handleOk}
            text={textSubmit ?? '保存'}
            width={80}
            disabled={isDisabled}
          />
          <ButtonOutline
            key="back"
            onClick={onCancel}
            text={REGISTER_COMPANY_CANCEL}
            isDisabled={isDisabled}
          />
        </ButtonGroup>,
      ]}
    >
      {children}
    </Modal>
  );
};
export default AntdModal;
