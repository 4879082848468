import React, { useEffect, useMemo, useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { BreadCrumbDetail } from 'page/Application/ManageProductAndService/TopPageProduct/TopPageProductDetail/styled';
import { Alert, Spin, Tooltip, message } from 'antd';
import { network } from 'recoil/atom/network';
import { useRecoilValue } from 'recoil';
import {
  getTopPageProduct,
  getTopPageProductEditMode,
  updateProductPagePosition,
} from 'api/product/topPage.api';
import { downloadNotifyFile } from 'api/storage/storage.api';
import { ButtonOutline, ButtonPrimary } from 'components/Button/index';
import Breadcrumb from 'components/Layout/ApplicationLayout/Breadcrumb';
import useModalConfirm from 'hook/useModalConfirm';
import useCustomMutateRenderMessage from 'hook/useQuery/useCustomMutateRenderMessage';
import useCustomQuery from 'hook/useQuery/useCustomQuery';
import NewestTable from 'page/Application/ManageProductAndService/TopPageProduct/DetailContentEditMode/component/NewestTable/index';
import OtherNotifyTable from 'page/Application/ManageProductAndService/TopPageProduct/DetailContentEditMode/component/OtherNotifyTable/index';
import PinnedTable from 'page/Application/ManageProductAndService/TopPageProduct/DetailContentEditMode/component/PinnedTable/index';
import ProductContent from 'page/Application/ManageProductAndService/TopPageProductPartnerAdmin/TopPageProductDetailPartnerAdmin/component/ProductContent';
import LayoutTopPage from 'page/Application/ManageProductAndService/TopPageProductPartnerAdmin/TopPagePartnerAdmin/component/LayoutTopPage';
import useErrorCodeTopPage from 'page/Application/ManageProductAndService/TopPageProduct/hook/useErrorCodeTopPage';
import HeaderTopPageCollapse from 'page/Application/ManageProductAndService/TopPageProduct/TopPage/component/HeaderTopPageCollapse';
import { ERROR_MESSAGES_RESPONSE } from 'constant/topPageConstants';

const TopPageEditProduct = () => {
  const isOnline = useRecoilValue(network);
  const [messageErrorNetWork, contextErrorNetWork] = message.useMessage();
  const { useQueryAPI } = useCustomQuery();
  const { productId } = useParams();
  const { ERROR_CODE_TOP_PAGE } = useErrorCodeTopPage();
  const navigate = useNavigate();
  const { contextHolder, handleConfirmAction } = useModalConfirm();
  const [isCancel, setIsCancel] = useState(false);
  const [dataSource, setDataSource] = useState([]);
  const [isDisabledPin, setIsDisabledPin] = useState(false);
  const [showPinnedTable, setShowPinnedTable] = useState(true);
  const [showNewestTable, setShowNewestTable] = useState(true);
  const [showOtherNotifyTable, setShowOtherNotifyTable] = useState(true);

  const { errorMessage, useMutationAPIHandleErrorCode } =
    useCustomMutateRenderMessage({
      errorCode: ERROR_CODE_TOP_PAGE,
    });
  const [notifyId, setNotifyId] = useState(null);

  const {
    data: productDetail = [],
    isLoading,
    error,
  } = useQueryAPI({
    api: getTopPageProduct,
    key: 'productDetail',
    params: {
      productId: productId,
    },
    options: {
      refetchOnWindowFocus: false, // cancel fetch data when out focus
    },
  });

  const { data: topPageProductNotifyDetail = [], isLoading: isNotifyLoading } =
    useQueryAPI({
      api: getTopPageProductEditMode,
      key: 'topPageProductEditMode',
      params: {
        productId: productId,
      },
      options: {
        refetchOnWindowFocus: false,
      },
    });

  const {
    mutate: updateNotifyPosition,
    isLoading: isUpdateLoading,
    isSuccess,
    isError,
  } = useMutationAPIHandleErrorCode({
    api: updateProductPagePosition,
    messageSuccess: '変更した情報を保存しました。',
    path: `/top-page/top-page-product/${productId}`,
    refetchKey: 'productDetail',
  });

  const listItemTopPage = useMemo(() => {
    return [
      {
        title: <Link to={'/manage-company-list'}>企業管理</Link>,
      },
      {
        title: <Link to={'/top-page'}>製品一覧</Link>,
      },
      {
        title: (
          <Tooltip
            placement={'topLeft'}
            title={productDetail?.data?.product?.productName}
          >
            <Link to={`/top-page/top-page-product/${productId}`}>
              <BreadCrumbDetail>
                {productDetail?.data?.product?.productName ?? ''}
              </BreadCrumbDetail>
            </Link>
          </Tooltip>
        ),
      },
      {
        title: <BreadCrumbDetail>編集</BreadCrumbDetail>,
      },
    ];
  }, [productDetail?.data?.product?.productName, productId]);

  const errorNetWork = () => {
    messageErrorNetWork
      .open({
        type: 'error',
        content:
          '問題が発生しました。インターネット接続を確認して、もう一度やり直してください。',
      })
      .then();
  };

  const handleUpdate = () => {
    if (isOnline) {
      updateNotifyPosition({
        productId: productId,
        payload: dataSource,
      });
    } else {
      errorNetWork();
    }
  };

  const handleCancel = () => {
    if (isOnline) {
      handleConfirmAction({
        confirmFn: navigate,
        params: `/top-page/top-page-product/${productId}`,
        title: '変更をキャンセルしてもよろしいでしょうか？',
        okText: 'はい',
        cancelText: 'キャンセル',
        setIsDisable: setIsCancel,
      });
      setIsCancel(true);
    } else {
      errorNetWork();
    }
  };

  const { refetch } = useQueryAPI({
    api: downloadNotifyFile,
    params: {
      notifyId: notifyId,
    },
    enabled: !!notifyId,
  });

  const dataSourcePinned = useMemo(() => {
    if (dataSource && dataSource !== [] && dataSource !== undefined) {
      const dataPinned = dataSource
        .filter((item) => item.isPinned)
        .sort((o1, o2) =>
          o1.notifyOrderId > o2.notifyOrderId
            ? 1
            : o1.notifyOrderId < o2.notifyOrderId
              ? -1
              : 0,
        );
      if (dataPinned?.length >= 2) {
        setIsDisabledPin(true);
      } else {
        setIsDisabledPin(false);
      }
      return dataPinned;
    }
  }, [dataSource, setIsDisabledPin]);

  const handleToggleTable = (type) => {
    switch (type) {
      case 'pinned-table':
        setShowPinnedTable(!showPinnedTable);
        break;
      case 'newest-table':
        setShowNewestTable(!showNewestTable);
        break;
      case 'other-notifyTable':
        setShowOtherNotifyTable(!showOtherNotifyTable);
        break;
      default:
        break;
    }
  };


  useEffect(() => {
    if (topPageProductNotifyDetail) {
      setDataSource(() => {
        return topPageProductNotifyDetail?.data?.listFullData;
      });
    }
    if (ERROR_MESSAGES_RESPONSE[error?.response?.data?.message]) {
      navigate('/top-page-not-found');
    }
  }, [topPageProductNotifyDetail, error?.response?.data?.message, navigate]);

  return (
    <Spin spinning={isLoading || isUpdateLoading}>
      {contextHolder}
      {contextErrorNetWork}
      <Breadcrumb
        listItem={listItemTopPage}
        titlePage={productDetail?.data?.product?.productName}
      >
        {/*<ButtonBreadcrumb text={'編集'} />*/}
        <ButtonPrimary
          disabled={isLoading || isSuccess}
          text={'保存'}
          width={'79px'}
          marginRight={'8px'}
          onClick={handleUpdate}
        />
        <ButtonOutline
          text={'キャンセル'}
          width={'123px'}
          isDisabled={isCancel}
          onClick={handleCancel}
        />
      </Breadcrumb>
      {(isError || errorMessage) && (
        <LayoutTopPage>
          <div>
            <Alert
              message={errorMessage}
              type="error"
              style={{ margin: '12px 0' }}
            />
          </div>
        </LayoutTopPage>
      )}
      {productDetail?.data?.product?.productContent ||
      productDetail?.data?.product?.image ? (
        <LayoutTopPage>
          <ProductContent
            isLoading={isLoading}
            dataNotify={productDetail?.data}
          />
        </LayoutTopPage>
      ) : (
        <></>
      )}

      <LayoutTopPage>
        <HeaderTopPageCollapse
          titleScreen={'お知らせトップ'}
          handleToggleTable={handleToggleTable}
          type={'pinned-table'}
          collapse={true}
        />
        {
          showPinnedTable && (
            <PinnedTable
              errorNetWork={errorNetWork}
              isLoading={isLoading}
              dataSourcePinned={dataSourcePinned}
              setDataSource={setDataSource}
              setIsDisabledPin={setIsDisabledPin}
              refetch={refetch}
              setNotifyId={setNotifyId}
              productId={productId}
            />
          )
        }
      </LayoutTopPage>

      <LayoutTopPage>
        <HeaderTopPageCollapse
          titleScreen={'最新なお知らせ'}
          handleToggleTable={handleToggleTable}
          type={'newest-table'}
          collapse={true}
        />

        {showNewestTable && (
          <NewestTable
            errorNetWork={errorNetWork}
            isLoading={isNotifyLoading}
            dataSource={dataSource}
            setDataSource={setDataSource}
            isDisabledPin={isDisabledPin}
            refetch={refetch}
            setNotifyId={setNotifyId}
            productId={productId}
          />
        )}

      </LayoutTopPage>
      <LayoutTopPage>
        <HeaderTopPageCollapse
          titleScreen={'他のお知らせ'}
          handleToggleTable={handleToggleTable}
          type={'other-notifyTable'}
          collapse={true}
        />
        {showOtherNotifyTable && (
          <OtherNotifyTable
            errorNetWork={errorNetWork}
            isLoading={isNotifyLoading}
            dataSource={dataSource}
            setDataSource={setDataSource}
            isDisabledPin={isDisabledPin}
            refetch={refetch}
            setNotifyId={setNotifyId}
            productId={productId}
          />

        )}

      </LayoutTopPage>
    </Spin>
  );
};

export default TopPageEditProduct;
