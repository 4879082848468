import React from 'react';
import useMultiLanguageSignUp from 'page/Authentication/SignUp/hook/useMultiLanguageSignUp';
import { FormItem } from 'components/Form2/index';
import { SelectStyled } from 'components/Form2/styled';
import useMultiLanguage from 'hook/useMultiLanguage';
import FormMessage from "../FormMessage";

const FormSelect = ({ name, label, required, data, isLoading, isDisable }) => {
  const { FORM_SIGN_UP_REQUIRED_FIELD } = useMultiLanguage();
  const { SIGN_UP_CHOOSE_PARTNER_DESCRIBE } = useMultiLanguageSignUp();

  return (
    <FormItem
      name={name}
      label={label}
      required={required}
      rules={[
        {
          required: required,
          message: <FormMessage text1={FORM_SIGN_UP_REQUIRED_FIELD} />,
        },
      ]}
      extra={
        <FormMessage
          text1={SIGN_UP_CHOOSE_PARTNER_DESCRIBE}
          marginBottom={'10px'}
        />
      }
    >
      <SelectStyled
        options={data}
        loading={isLoading}
        allowClear={true}
        notFoundContent={'データがありません'}
        disabled={isDisable}
      />
    </FormItem>
  );
};

export default FormSelect;
