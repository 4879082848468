import React, { useMemo } from 'react';
import AntdTable from 'components/AntdTable';
import { useNavigate } from 'react-router-dom';
import { ButtonAction } from 'style/styleComponent/styled';
import { EditOutlined } from '@ant-design/icons';
import { EDIT_PATH, MANAGE_CATEGORY_PATH } from 'constant/appPath';
import { Tooltip } from 'antd';
import { TooltipHide } from 'page/Application/ManageCompanyList/CompanyList/styled';

const TableCategory = ({
  isOnline,
  errorNetWork,
  dataRaw,
  isLoading,
  emptyText,
  onChange,
  sortedInfo,
}) => {
  const navigate = useNavigate();
  const columns = useMemo(() => {
    const handleEdit = (categoryId) => {
      if (isOnline) {
        navigate(`/${MANAGE_CATEGORY_PATH}/${EDIT_PATH}/${categoryId}`);
      } else {
        errorNetWork();
      }
    };
    return [
      {
        key: 'title',
        title: (
          <Tooltip title={'カテゴリ名'} placement={'topLeft'}>
            カテゴリ名
          </Tooltip>
        ),
        dataIndex: 'title',
        align: 'left',
        ellipsis: true,
        showSorterTooltip: false,
        sorter: true,
        render: (title) => (
          <TooltipHide>
            <Tooltip title={title} placement={'topLeft'}>
              {title}
            </Tooltip>
          </TooltipHide>
        ),
        sortOrder: sortedInfo.columnKey === 'title' ? sortedInfo.order : null,
      },
      {
        key: 'isDisplayed',
        title: (
          <Tooltip title={'ステータス'} placement={'topLeft'}>
            ステータス
          </Tooltip>
        ),
        dataIndex: 'isDisplayed',
        align: 'left',
        width: '20%',
        ellipsis: true,
        render: (text) => <span title={''}>{text ? '表示' : '非表示'}</span>,
        showSorterTooltip: false,
        sorter: true,
        sortOrder:
          sortedInfo.columnKey === 'isDisplayed' ? sortedInfo.order : null,
      },
      {
        key: 'categoryId',
        title: <span style={{ paddingLeft: 22 }}>{'操作'}</span>,
        dataIndex: 'categoryId',
        render: (categoryId) => (
          <ButtonAction type="link" onClick={() => handleEdit(categoryId)}>
            <EditOutlined />
          </ButtonAction>
        ),
        align: 'left',
        width: 120,
      },
    ];
  }, [navigate, sortedInfo.columnKey, sortedInfo.order, isOnline]);

  const dataSource = useMemo(() => {
    if (isLoading && !dataRaw) return [];
    return dataRaw?.list.map((item) => ({
      ...item,
      key: item.categoryId,
    }));
  }, [dataRaw, isLoading]);

  return (
    <AntdTable
      columns={columns}
      isLoading={isLoading}
      dataSource={dataSource}
      emptyText={emptyText}
      onChange={onChange}
    />
  );
};

export default TableCategory;
