import React, { useRef, useState } from 'react';
import { TableStyled } from 'components/AntdTable/styled';

/**
 * columns: is columns for table.
 * dataSource: is data for render table, type array, every object in dataSource NEED UNIQUE KEY.
 * rowSelection: render checkbox per row, selected record.
 * isLoading: render loading when API fetching.
 * expandable: row collapse, render columns expand
 * scroll: take value FALSE | UNDEFINED, with value === false, turn off scroll bar
 */
import { InboxOutlined } from '@ant-design/icons';
import { Empty } from 'antd';
import useMultiLanguageCompanyList from 'page/Application/ManageCompanyList/CompanyList/hook/useMultiLanguageCompanyList';

const AntdTable = ({
  columns,
  dataSource,
  rowSelection,
  isLoading,
  expandable,
  emptyText,
  onChange,
  height,
  showHeader,
  highLightedRows,
  isBoldBottomBorder,
}) => {
  const { COMPANY_LIST_NO_DATA } = useMultiLanguageCompanyList();
  const tableRef = useRef();
  const CustomEmptyText = () => {
    return (
      <Empty
        image={<InboxOutlined />}
        imageStyle={{ fontSize: 80 }}
        description={null}
        style={{ fontSize: '16px' }}
      >
        {emptyText || COMPANY_LIST_NO_DATA}
      </Empty>
    );
  };
  return (
    <TableStyled
      highLightedRows={highLightedRows}
      isBoldBottomBorder={isBoldBottomBorder}
      key={dataSource?.length}
      style={{ minHeight: height }} // set min height for list table when data null or data length < 10
      ref={tableRef}
      columns={columns}
      rowSelection={rowSelection}
      expandable={expandable}
      dataSource={dataSource}
      pagination={false}
      showSizeChanger={true}
      onChange={onChange}
      loading={isLoading}
      showHeader={showHeader}
      locale={{ emptyText: CustomEmptyText }}
    />
  );
};

export default AntdTable;
