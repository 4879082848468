import styled from 'styled-components';

export const GridForm = styled.div`
  display: grid;
  grid-template-columns: 200px 640px;
  column-gap: 15px;
`;

export const Grid1 = styled.div`
  padding-top: 9px;
  grid-column: 1 / 2;
`;

export const Grid2 = styled.div`
  grid-column: 2 / 3;
`;

export const Grid3 = styled.div`
  grid-column: 3/4;
`;
