import { useIntl } from 'react-intl';

const useProjectStatusMultiLanguage = () => {
  const intl = useIntl();

  const PROJECT_STATUS_SHOW = intl.formatMessage({
    id: 'project.status.show',
  });

  const PROJECT_STATUS_HIDDEN = intl.formatMessage({
    id: 'project.status.hidden',
  });

  const STATUS_FOR_PROJECT = {
    true: PROJECT_STATUS_SHOW,
    false: PROJECT_STATUS_HIDDEN,
  };

  return {
    STATUS_FOR_PROJECT,
  };
};

export default useProjectStatusMultiLanguage;
