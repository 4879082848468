import { useIntl } from 'react-intl';

const useMultiLanguagePasswordExpire = () => {
  const intl = useIntl();

  const TITLE_WARNING_USER_1 = intl.formatMessage({
    id: 'passwordExpireDate.title.warningUser1',
  });

  const TITLE_WARNING_USER_2 = intl.formatMessage({
    id: 'passwordExpireDate.title.warningUser2',
  });
  const TITLE_WARNING_USER_3 = intl.formatMessage({
    id: 'passwordExpireDate.title.warningUser3',
  });

  const TITLE_CHANGE_PASSWORD_1 = intl.formatMessage({
    id: 'passwordExpireDate.title.changePassword1',
  });
  const TITLE_CHANGE_PASSWORD_2 = intl.formatMessage({
    id: 'passwordExpireDate.title.changePassword2',
  });

  const BUTTON_CHANGE_PASSWORD = intl.formatMessage({
    id: 'passwordExpireDate.button.submit',
  });

  const BUTTON_SKIP = intl.formatMessage({
    id: 'passwordExpireDate.button.skip',
  });

  const MESSAGE_CHANGE_PASSWORD_SUCCESS = intl.formatMessage({
    id: "changePassword.message.success"
  })

  return {
    TITLE_WARNING_USER_1,
    TITLE_WARNING_USER_2,
    TITLE_WARNING_USER_3,
    TITLE_CHANGE_PASSWORD_1,
    TITLE_CHANGE_PASSWORD_2,
    BUTTON_CHANGE_PASSWORD,
    BUTTON_SKIP,
    MESSAGE_CHANGE_PASSWORD_SUCCESS
  };
};

export default useMultiLanguagePasswordExpire;