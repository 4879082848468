import { Link } from 'react-router-dom';
import styled from 'styled-components';

export const LinkStyled = styled(Link)`
  text-decoration: ${(props) => props?.decoration || null};
  font-family: 'inter-regular', sans-serif;
  color: var(--SECONDARY-default);
  font-size: ${(props) => props?.fontSize || '12px'};
  max-width: 200px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  &:hover {
    color: var(--PRIMARY-hover);
  }
`;
