import React from 'react';
import {
  FooterText,
  FooterWrapper,
} from 'components/Layout/ApplicationLayout/Footer/styled';

const Footer = () => {
  return (
    <FooterWrapper>
      <FooterText>
        Copyright(C) Soliton Systems K.K., All rights reserved.
      </FooterText>
    </FooterWrapper>
  );
};

export default Footer;