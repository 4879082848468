import React, { useEffect, useState } from 'react';
import { getListCategorySelectBox } from 'api/product/category.api';
import { registerProduct } from 'api/product/product.api';
import { MANAGE_PRODUCT_PATH } from 'constant/appPath';
import useErrorCodeManageProduct from '../hook/useErrorCodeManageProduct';
import { PRODUCT_STATUS_DATA } from 'constant/productConstants';
import useMultiLanguageRegisterProduct from './hook/useMultiLanguageRegisterProduct';
import useCustomMutateRenderMessage from 'hook/useQuery/useCustomMutateRenderMessage';
import useCustomQuery from 'hook/useQuery/useCustomQuery';
import FormProduct from '../component/FormProduct';
import { defaultData } from 'components/Tree/treeData';

const RegisterProduct = () => {
  const { APPLICATION_PRODUCT_MESSAGE_ADD_SUCCESS, APPLICATION_PRODUCT_TITLE } =
    useMultiLanguageRegisterProduct();
  const [isDisable, setIsDisable] = useState(false);

  const { ERROR_CODE_MANAGE_PRODUCT } = useErrorCodeManageProduct();
  const { errorMessage, useMutationAPIHandleErrorCode } =
    useCustomMutateRenderMessage({
      errorCode: ERROR_CODE_MANAGE_PRODUCT,
    });
  const [isDisplayed, setIsDisplayed] = useState(PRODUCT_STATUS_DATA[0].value);
  const { useQueryWithoutParams } = useCustomQuery();
  const [, setIdFile] = useState(null);
  const [content, setContent] = useState();
  const [treeData, setTreeData] = useState(defaultData);
  const [fileObject, setFileObject] = useState(null);
  const [plainText, setPlainText] = useState(null);

  const initialFormDetailData = {
    productMenus: [],
  };
  const [formDetailData] = useState(initialFormDetailData);

  const { data: listCategoryProduct = [] } = useQueryWithoutParams({
    api: getListCategorySelectBox,
    key: 'categoryList',
  });

  const {
    mutate: registerProductMutate,
    isError,
    isLoading,
    isSuccess,
  } = useMutationAPIHandleErrorCode({
    api: registerProduct,
    refetchKey: 'listProduct',
    messageSuccess: APPLICATION_PRODUCT_MESSAGE_ADD_SUCCESS,
    path: `/${MANAGE_PRODUCT_PATH}`,
  });

  const onFinish = (value) => {
    const avatar = value?.avatar;
    const payload = {
      productContent: content === '<p>&nbsp;</p>' ? '' : content,
      productSearchContent: plainText,
      productName: value?.productName?.trim(),
      isDisplayed: value?.isDisplayed,
      categoryId: value?.categoryId,
      menuRequests: treeData?.children,
      fileStorageRequest: fileObject && {
        fileName: fileObject?.fileName,
        keyFileName: fileObject?.keyFileName,
        contentType: fileObject?.contentType,
        size: fileObject?.size,
      },
    };
    let formData = new FormData();
    formData.append(
      'productRequest',
      new Blob([JSON.stringify(payload)], { type: 'application/json' }),
    );
    formData.append('avatar', avatar && avatar[0]?.originFileObj);
    registerProductMutate(formData);
  };

  useEffect(() => {
    // clear cache tree data
    setTreeData({ ...treeData, children: [] });
  }, []);

  return (
    <FormProduct
      onFinish={onFinish}
      formDetailData={formDetailData}
      isEdit={false}
      isLoading={isLoading}
      listCategoryProduct={listCategoryProduct?.data}
      titleForm={APPLICATION_PRODUCT_TITLE}
      isDisable={isDisable}
      setIsDisable={setIsDisable}
      isError={isError}
      errorMessage={errorMessage}
      isSuccess={isSuccess}
      setIsDisplayed={setIsDisplayed}
      isDisplayed={isDisplayed}
      setIdFile={setIdFile}
      content={content}
      setContent={setContent}
      treeData={treeData}
      setTreeData={setTreeData}
      setFileObject={setFileObject}
      fileObject={fileObject}
      setPlainText={setPlainText}
    />
  );
};

export default RegisterProduct;
