import styled from 'styled-components';
import { Button } from 'antd';

// base style applied for all button, don't overwrite this
export const BtnBaseStyled = styled(Button)`
  font-family: 'inter-regular', sans-serif;
  font-size: 14px !important;
  box-shadow: none !important;
  span {
    font-weight: 500;
  }
`;

export const BtnTextIcon = styled(BtnBaseStyled)`
  span {
    color: var(--BTN-TEXT-SECONDARY-default);
  }
`;

export const BtnNavigation = styled(BtnBaseStyled)`
  span {
    color: var(--BTN-TEXT-PRIMARY-default);
  }
`;

export const BtnBasic = styled(BtnBaseStyled)`
  span {
    color: var(--BTN-TEXT-SECONDARY-default);
  }
`;

export const BtnPrimary = styled(BtnBaseStyled)``;

export const BtnIcon = styled(BtnBaseStyled)`
  span {
    color: var(--BTN-ICON-SECONDARY-default);
  }
`;

export const BtnBreadcrumb = styled(BtnBaseStyled)`
  min-width: 100px;
  span {
    color: var(--BTN-TEXT-PRIMARY-default);
  }
`;

export const BtnSecondary = styled(BtnBaseStyled)`
  background: var(--BTN-SECONDARY-BACKGROUND--default);
  span {
    color: var(--BTN-SECONDAY-TEXT-default);
  }
`;
