export const ERROR_CODE_RESPONSE = {
  '400_INVALID_JWT_SIGNATURE': true,
  '400_INVALID_JWT_TOKEN': true,
  '400_JWT_TOKEN_IS_EXPIRED': true,
  '400_JWT_TOKEN_IS_UNSUPPORTED': true,
  '400_JWT_CLAIMS_STRING_IS_EMPTY': true,
  '404_ACCOUNT_HAS_BEEN_SUSPENDED_OR_UNAPPROVED': true,
  '404_ACCOUNT_NOT_EXIST': true,
  '400_ROLE_HAS_BEEN_CHANGED': true,
  // '410_PASSWORD_EXPIRED': true,
};
