import styled from 'styled-components';
import { Upload } from 'antd';

export const GridForm = styled.div`
  display: grid;
  grid-template-columns: 210px 630px 100px;
  column-gap: 15px;
`;

export const Grid1 = styled.div`
  padding-top: 9px;
  grid-column: 1 / 2;
`;

export const Grid2 = styled.div`
  grid-column: 2 / 3;
`;

export const Grid3 = styled.div`
  grid-column: 3/4;
`;

export const UploadImg = styled(Upload)`
  .ant-upload-list-item-name {
    width: 550px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-family: 'Roboto';
    color: #1890ff;
  }
`;

export const FormMessage = styled.div`
  font-family: 'inter-regular', sans-serif;
  position: absolute;
  width: 640px;
  margin: 4px;
`;

export const TextNote = styled.div`
  color: #a5a5a5;
  font-family: 'inter-regular', sans-serif;
  font-weight: 400;
  font-size: 14px;
  margin-top: 5px;
`;
