import React, { Suspense, useEffect, useState } from 'react';
import { Alert, Form, Spin, Tooltip } from 'antd';
import {
  getDetailSolitonUser,
  updateSolitonUser,
} from 'api/myPage/solitonUser.api';
import { ButtonBasic, ButtonPrimary } from 'components/Button';
import { FormItem } from 'components/Form2';
import Breadcrumb from 'components/Layout/ApplicationLayout/Breadcrumb';
import LayoutDefault from 'components/Layout/LayoutDefault';
import RowButton from 'components/Layout/RowButton';
import useModalConfirm from 'hook/useModalConfirm';
import useMultiLanguage from 'hook/useMultiLanguage';
import useCustomMutateRenderMessage from 'hook/useQuery/useCustomMutateRenderMessage';
import useCustomQuery from 'hook/useQuery/useCustomQuery';
import {
  EmailPasswordAccountType,
  LastNameFirstName,
} from 'page/Application/MyPage/component/FormMyPage';
import LabelFormMyPage from 'page/Application/MyPage/component/FormMyPage/LabelFormMyPage';
import {
  FormMyPageWrapper,
  GridFormAccount,
  GridFormAccount1to2,
  GridFormAccount2to4,
} from 'page/Application/MyPage/component/FormMyPage/styled';
import useErrorCodeMyPage from 'page/Application/MyPage/hook/useErrorCodeMyPage';
import {
  LinkToPartnerSite,
  ListPartnerWrapper,
  PartnerNameItems,
} from 'page/Application/MyPage/MyPageOverview/MyPageSolitonUser/styled';

import { useLocation, useNavigate } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import { authenticationToken } from 'recoil/atom/authentication';
import { FormTitle } from 'page/Application/ManageCompanyList/component/Form';

const MyPageEditSolitonUser = () => {
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const { handleConfirmAction, contextHolder } = useModalConfirm();
  const { APPLICATION_OK_TEXT, APPLICATION_CANCEL_TEXT } = useMultiLanguage();
  const location = useLocation();
  const [editPassword, setEditPassword] = useState(true);
  const { ERROR_CODE_MY_PAGE } = useErrorCodeMyPage();
  const { errorMessage, useMutationAPIHandleErrorCode } =
    useCustomMutateRenderMessage({
      errorCode: ERROR_CODE_MY_PAGE,
    });
  const { useQueryAPI } = useCustomQuery();
  const [data, setData] = useState(location.state);
  const [isDisable, setIsDisable] = useState(false);
  const { accessToken } = useRecoilValue(authenticationToken);
  const { data: getMyPageDetail = [], isLoading } = useQueryAPI({
    api: getDetailSolitonUser,
    key: 'getDetailSolitonUser',
    params: {
      token: accessToken,
    },
  });

  const {
    mutate: updateMyPageDetail,
    isLoading: isLoadingUpdate,
    isSuccess,
    isError,
  } = useMutationAPIHandleErrorCode({
    api: updateSolitonUser,
    refetchKey: 'getDetailSolitonUser',
    path: `/`,
    messageSuccess: '変更した情報を保存しました。',
  });

  const listItem = [
    {
      title: 'マイページ',
    },
  ];

  const handleBack = () => {
    navigate('/my-page');
  };

  const onFinish = (values) => {
    const payload = {
      ...values,
      password: values?.password ? values?.password : null,
    };
    updateMyPageDetail({
      payload: payload,
    });
  };

  useEffect(() => {
    if (!data) {
      setData(getMyPageDetail?.data);
    }
    form.setFieldsValue(data);
  }, [data, form, getMyPageDetail?.data]);

  return (
    <>
      {contextHolder}
      <Suspense fallback={data}>
        <Breadcrumb listItem={listItem} titlePage={'マイページ'} />
        <LayoutDefault>
          <FormTitle
            notice={'注意：'}
            title={
              '変更した内容は画面下部の「保存」ボタンを押すと保存されます。'
            }
            type={'text'}
            styles={{ color: '#9D1C49' }}
          />
          <Spin spinning={isLoading || isLoadingUpdate || isSuccess}>
            <FormMyPageWrapper>
              {(isError || errorMessage) && (
                <GridFormAccount>
                  <GridFormAccount2to4>
                    <Alert
                      message={errorMessage}
                      type="error"
                      style={{ marginBottom: '14px' }}
                    />
                  </GridFormAccount2to4>
                </GridFormAccount>
              )}
              <Form
                size={'Large'}
                form={form}
                initialValues={data}
                onFinish={onFinish}
              >
                <LastNameFirstName editMode={true} isEdit={true} />
                <EmailPasswordAccountType
                  data={data}
                  disabled={true}
                  setEditPassword={setEditPassword}
                  editPassword={editPassword}
                  isEdit={true}
                />
                <GridFormAccount>
                  <GridFormAccount1to2>
                    <LabelFormMyPage
                      labelText={'担当パートナー'}
                      required={false}
                    />
                  </GridFormAccount1to2>
                  <GridFormAccount2to4>
                    <FormItem>
                      <ListPartnerWrapper>
                        {data?.partnerInCharge?.map((item, index) => (
                          <PartnerNameItems
                            key={index}
                            style={{ textDecoration: 'unset' }}
                          >
                            <Tooltip
                              title={item.partnerName}
                              placement={'topLeft'}
                            >
                              <LinkToPartnerSite>
                                {item.partnerName}
                              </LinkToPartnerSite>
                            </Tooltip>
                          </PartnerNameItems>
                        ))}
                      </ListPartnerWrapper>
                    </FormItem>
                  </GridFormAccount2to4>
                </GridFormAccount>
                <GridFormAccount>
                  <GridFormAccount2to4>
                    <RowButton>
                      <ButtonPrimary
                        text={'保存'}
                        width={86}
                        isLoadingUpdate={isLoadingUpdate || isSuccess}
                      />
                      <ButtonBasic
                        disable={isDisable}
                        text={'キャンセル'}
                        onClick={() => {
                          handleConfirmAction({
                            confirmFn: handleBack,
                            title: (
                              <span>
                                変更をキャンセルしてもよろしいでしょうか？
                              </span>
                            ),
                            cancelText: <span> {APPLICATION_CANCEL_TEXT}</span>,
                            okText: <span> {APPLICATION_OK_TEXT}</span>,
                            setIsDisable: setIsDisable,
                          });
                          setIsDisable(true);
                        }}
                      />
                    </RowButton>
                  </GridFormAccount2to4>
                </GridFormAccount>
              </Form>
            </FormMyPageWrapper>
          </Spin>
        </LayoutDefault>
      </Suspense>
    </>
  );
};

export default MyPageEditSolitonUser;
