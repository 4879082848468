import React, { useState } from 'react';
import Icon from 'components/Icon';
import LabelForm from 'components/Form/LabelForm';
import useMultiLanguage from 'hook/useMultiLanguage';
import FormMessage from 'components/Form/FormMessage';
import { Input } from 'antd';
import { LockOutlined } from '@ant-design/icons';
import { FormItemStyled } from 'components/Form/styled';
import { MAX_LENGTH_PASSWORD } from 'constant/inputMaxlength';
import { CHECK_LATIN_ALPHABET, CHECK_STRONG_PASSWORD } from 'constant/regex';

const FormItemChangePassword = ({ name, maxLength, labelText, className }) => {
  const {
    FORM_VERIFY_PASSWORD_PLACEHOLDER,
    FORM_PASSWORD_PLACEHOLDER_NEW_PASSWORD,
    FORM_PASSWORD_ERROR_MESSAGE_EMPTY,
    FORM_PASSWORD_ERROR_MESSAGE_VALIDATE_1,
    FORM_PASSWORD_ERROR_MESSAGE_VALIDATE_2,
    FORM_PASSWORD_EXTRA_TEXT,
    FORM_PASSWORD_ERROR_MESSAGE_CHECK_SAME_PASSWORD,
    FORM_PASSWORD_LABEL_NEW_PASSWORD,
    FORM_PASSWORD_LABEL_VERIFY_PASSWORD,
  } = useMultiLanguage();

  const [currentPassword, setCurrentPassword] = useState('');

  return (
    <>
      <FormItemStyled
        className={className}
        name={name ? name : 'password'}
        label={
          <LabelForm
            text={labelText ? labelText : FORM_PASSWORD_LABEL_NEW_PASSWORD}
          />
        }
        extra={<FormMessage text1={FORM_PASSWORD_EXTRA_TEXT} />}
        rules={[
          {
            required: true,
            message: <FormMessage text1={FORM_PASSWORD_ERROR_MESSAGE_EMPTY} />,
          },
          () => ({
            validator(_, value) {
              if (!value || value.match(CHECK_LATIN_ALPHABET) === null) {
                if (CHECK_STRONG_PASSWORD.test(value)) {
                  return Promise.resolve();
                }
              }
              return Promise.reject(
                <FormMessage
                  text1={FORM_PASSWORD_ERROR_MESSAGE_VALIDATE_1}
                  text2={FORM_PASSWORD_ERROR_MESSAGE_VALIDATE_2}
                />,
              );
            },
          }),
        ]}
      >
        <Input.Password
          prefix={
            <Icon>
              <LockOutlined />
            </Icon>
          }
          value={currentPassword}
          autoComplete="on"
          size="large"
          type="password"
          placeholder={FORM_PASSWORD_PLACEHOLDER_NEW_PASSWORD}
          name={name}
          onChange={(e) => setCurrentPassword(e.target.value)}
          maxLength={maxLength ? maxLength : 20}
        />
      </FormItemStyled>
      <FormItemStyled
        className={className}
        name={name ? name : 'verifyPassword'}
        label={
          <LabelForm
            text={labelText ? labelText : FORM_PASSWORD_LABEL_VERIFY_PASSWORD}
          />
        }
        dependencies={['password']}
        rules={[
          {
            required: true,
            message: (
              <FormMessage
                text1={FORM_PASSWORD_ERROR_MESSAGE_EMPTY}
                marginBottom={'12px'}
              />
            ),
          },
          ({ getFieldValue }) => ({
            validator(_, value) {
              if (!value || getFieldValue('password') === value) {
                return Promise.resolve();
              }
              return Promise.reject(
                <FormMessage
                  text1={FORM_PASSWORD_ERROR_MESSAGE_CHECK_SAME_PASSWORD}
                  marginBottom={'12px'}
                />,
              );
            },
          }),
        ]}
      >
        <Input.Password
          prefix={
            <Icon>
              <LockOutlined />
            </Icon>
          }
          autoComplete="on"
          size="large"
          type="password"
          placeholder={FORM_VERIFY_PASSWORD_PLACEHOLDER}
          name={name}
          maxLength={maxLength ? maxLength : MAX_LENGTH_PASSWORD}
        />
      </FormItemStyled>
    </>
  );
};

export default FormItemChangePassword;
