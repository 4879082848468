import useSearchKeyword from 'hook/useSearchKeyword';
import { Col, Empty, Row } from 'antd';
import { InboxOutlined } from '@ant-design/icons';
import useMultiLanguageCompanyList from 'page/Application/ManageCompanyList/CompanyList/hook/useMultiLanguageCompanyList';
import { ButtonVersion } from '../../styled';
import { useNavigate, useParams } from 'react-router-dom';

const RootMenuItem = ({ productDetail, listProductRootMenu }) => {
  const navigate = useNavigate();
  const { emptyText } = useSearchKeyword();
  const { productId } = useParams();

  const handleMoveToSuperDetailProduct = (menuId, menuName) => {
    navigate(
      `/top-page/top-page-product/${productId}/top-page-detail-product/${menuId}`,
      {
        state: {
          dataProduct: productDetail,
          menuId: menuId,
          menuName: menuName,
        },
      },
    );
  };
  const { COMPANY_LIST_NO_DATA } = useMultiLanguageCompanyList();
  return (
    <>
      {listProductRootMenu === [] ||
      listProductRootMenu === undefined ||
      listProductRootMenu.length === 0 ? (
        <Empty
          image={<InboxOutlined />}
          imageStyle={{ fontSize: 80 }}
          description={null}
          style={{ fontSize: '16px', minHeight: 250 }}
        >
          {emptyText || COMPANY_LIST_NO_DATA}
        </Empty>
      ) : (
        <Row gutter={20} style={{ margin: '0 10px' }}>
          {listProductRootMenu.map((item, index) => (
            <Col xs={24} sm={24} md={12} lg={8} xl={8} xxl={6} key={index}>
              <ButtonVersion
                block={true}
                onClick={() =>
                  handleMoveToSuperDetailProduct(item?.menuId, item?.menuName)
                }
                style={{
                  color: '#656464',
                }}
              >
                {item.menuName}
              </ButtonVersion>
            </Col>
          ))}
        </Row>
      )}
    </>
  );
};
export default RootMenuItem;
