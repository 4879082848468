import styled from 'styled-components';
import { Form, Input } from 'antd';
import { TextCenter } from 'style/styleComponent/styled';

export const FormItemStyled = styled(Form.Item)`
  margin-bottom: ${(props) => props?.marginbottom || '30px'};
  .ant-form-item-explain-error {
    &:not(:first-child) {
      display: none;
    }
  }
`;

export const TextAlignCenter = styled(TextCenter)`
  font-family: 'inter-regular', sans-serif;
  margin-top: 15px;
  @media (min-width: 0px) and (max-width: 400px) {
    margin-top: 10px;
  }
  @media (min-width: 401px) and (max-width: 1200px) {
    margin-top: 10px;
  }
`;

export const InputStyled = styled(Input)`
  height: 40px;
`;
