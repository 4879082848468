import axiosClient from 'api/config/axiosClient';

const getListProduct = ({
  pageNumber,
  pageSize,
  keyword,
  displayedDateFrom,
  displayedDateTo,
  category,
  status,
  sortField,
  sortDirection,
}) => {
  const url = `v1/product?pageNumber=${pageNumber}&pageSize=${pageSize}&keyword=${encodeURIComponent(
    keyword,
  )}&displayedDateFrom=${displayedDateFrom}&displayedDateTo=${displayedDateTo}&category=${category}&status=${status}&sortField=${sortField}&sortDirection=${sortDirection}`;
  return axiosClient.get(url);
};

const registerProduct = (payload) => {
  const url = 'v1/product';
  return axiosClient.post(url, payload, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
};

const getDetailProduct = ({ productId }) => {
  const url = `v1/product/${productId}`;
  return axiosClient.get(url);
};

const updateProduct = ({ productId, payload }) => {
  const url = `v1/product/${productId}`;
  return axiosClient.put(url, payload, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
};

const deleteProduct = ({ productId }) => {
  const url = `v1/product/${productId}`;
  return axiosClient.delete(url);
};

const getProduct = () => {
  const url = 'v1/product/list';
  return axiosClient.get(url);
};
const getMenuProduct = ({ productId }) => {
  const url = `v1/product/${productId}/menu`;
  return axiosClient.get(url);
};

const getAllMenuProduct = ({ productIds }) => {
  const url = '/v1/product/menus';
  return axiosClient.post(url, { productIds });
};

const getAllMenuProductWithPath = ({ productId }) => {
  const url = `/v1/product/${productId}/menus/path`;
  return axiosClient.get(url);
};

const getPublicProject = () => {
  const url = 'v1/project/public';
  return axiosClient.get(url);
};

const updateProjectPosition = ({ payload }) => {
  const url = `v1/project`;
  return axiosClient.put(url, payload);
};

export {
  getListProduct,
  registerProduct,
  getDetailProduct,
  updateProduct,
  deleteProduct,
  getProduct,
  getMenuProduct,
  getAllMenuProduct,
  getAllMenuProductWithPath,
  getPublicProject,
  updateProjectPosition,
};
