import { ExclamationCircleFilled } from '@ant-design/icons';
import { Modal } from 'antd';
import React from 'react';
import { TitleStyle } from './styled';

const AntdModal3 = ({
  isModalOpen,
  handleOk,
  onCancel,
  title,
  children,
  width,
  footer,
  closable,
}) => {
  return (
    <Modal
      title={<TitleStyle>{title ?? 'Basic Modal'}</TitleStyle>}
      open={isModalOpen}
      onOk={handleOk}
      onCancel={onCancel}
      centered={true}
      width={width ?? 430}
      icon={<ExclamationCircleFilled />}
      destroyOnClose={true}
      maskClosable={false}
      closable={closable}
      footer={[footer]}
    >
      {children}
    </Modal>
  );
};
export default AntdModal3;
