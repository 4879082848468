import styled from 'styled-components';
import { Button } from 'antd';

export const GridFormPreview = styled.div`
  font-size: 14px;
  min-height: 18px;
  padding: 18px 12px;
  display: grid;
  grid-template-columns: 140px 70px 200px;
  column-gap: 30px;
  color: #656464;
  background: #ebebeb;
  border-radius: 8px;
  align-items: center;
`;

export const FormGrid = styled.div`
  display: grid;
  grid-template-columns: 30px auto;
  column-gap: 20px;
`;

export const Grid1 = styled.div`
  grid-column: 1 / 2;
  display: inline-grid;
`;

export const Grid2 = styled.div`
  grid-column: 2 / 3;

  ol {
    color: #656464;
    list-style: decimal;
  }

  ul {
    color: #656464;
    list-style: initial;
  }

  li {
    list-style-position: inside;
    word-wrap: normal;
  }
`;

export const Grid3 = styled.div`
  padding-left: 5px;
  grid-column: 3/4;
`;
export const Grid4 = styled.div`
  grid-column: 2 / 3;
  color: #9d1c49;
`;

export const LayoutPreview = styled.div`
  display: grid;
  grid-template-columns: auto ${(props) => {
      return props?.content === 'true' ? '700px' : '0px';
    }};
  column-gap: 20px;
  height: auto;
`;

export const ButtonPreview = styled(Button)`
  padding: 0;
  color: #656464;
  width: 480px;

  &:hover {
    color: rgb(128, 127, 127) !important;
  }
`;

export const SpanPreview = styled.span`
  text-decoration-thickness: 0.8px !important;
  text-decoration-skip-ink: none;
  text-underline-offset: 4px;
  text-decoration: underline;
  font-size: 15px;
  text-overflow: ellipsis;
`;

export const GridTitle = styled.div`
  display: grid;
  grid-template-columns: auto auto;
  align-items: center;
`;

export const FormWrapper = styled.div`
  //height: calc(100% - 59px);
  //overflow-y: auto;

  &::-webkit-scrollbar {
    width: 6px;
  }

  /* Track */

  &::-webkit-scrollbar-track {
    background: transparent;
  }

  /* Handle */

  &::-webkit-scrollbar-thumb {
    background: #d9d9d9;
    border-radius: 2px;
  }

  /* Handle on hover */

  &::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
`;

export const ContainerBlock = styled.div`
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  width: 100%;
  align-items: center;
  overflow: hidden;
  word-break: break-all;
  text-overflow: ellipsis;

  @media all and (min-width: 0px) and (max-width: 320px) {
    font-size: 14px;
  }
  @media all and (min-width: 320px) and (max-width: 430px) {
    font-size: 14px;
  }

  ol {
    color: #656464;
    list-style: decimal;
  }

  ul {
    color: #656464;
    list-style: initial;
  }
  li {
    list-style-position: inside;
    word-wrap: normal;
  }
`;
export const TitleBlock = styled.div`
  color: #656464;
  font-size: 16px;
  font-weight: 600;
  margin: 20px 0;
  word-wrap: break-word;
`;
export const ContentBlock = styled.div`
  color: #656464;
  font-size: 12px;
  font-weight: 400;
  font-family: inherit;
  line-height: 1.5;
  margin-bottom: 5px;
  word-wrap: break-word;
  ol {
    list-style: decimal inside;
    padding-left: 20px;
  }

  ul {
    list-style: initial;
    padding-left: 10px;
    margin-left: 10px;
  }
  li {
    word-wrap: normal;
  }
`;
