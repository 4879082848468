import React, { useEffect } from 'react';
import useMultiLanguageEditCompany from 'page/Application/ManageCompanyList/EditCompany/hook/useMultiLanguageEditCompany';
import {
  ButtonDelete,
  FormFooterWrapper,
} from 'page/Application/ManageNotificationList/component/Form/FormFooterSubmit/styled';
import { ButtonStyle } from 'page/Application/ManageNotificationList/component/Form/styled';
import {Button} from 'antd'
const FormFooterSubmit = ({
  textOk,
  textCancel,
  textDelete,
  onCancel,
  onDelete,
  isDelete,
  isLoading,
  isDisable,
  isCancel,
}) => {
  const { DETAIL_COMPANY_BUTTON_DELETE } = useMultiLanguageEditCompany();

  return (
    <FormFooterWrapper>
      <ButtonStyle
        type="primary"
        htmlType="submit"
        loading={isLoading}
        disabled={isDisable}
      >
        {/*must have a span tag so there are no spaces between letters*/}
        <span> {textOk}</span>
      </ButtonStyle>
      {isDelete && (
        <ButtonDelete
          disabled={isDisable}
          onClick={onDelete}
          loading={isLoading}
        >
          {/*must have a span tag so there are no spaces between letters*/}
          <span> {textDelete ?? DETAIL_COMPANY_BUTTON_DELETE}</span>
        </ButtonDelete>
      )}
      <ButtonStyle
        style={{ color: '#656464' }}
        disabled={isCancel}
        onClick={onCancel}
      >
        {/*must have a span tag so there are no spaces between letters*/}
        <span> {textCancel}</span>
      </ButtonStyle>
    </FormFooterWrapper>
  );
};

export default FormFooterSubmit;
