import { useRecoilState } from 'recoil';
import { messageFeedback } from 'recoil/atom/authentication';
import { useMutation } from 'react-query';
import { queryClient } from 'api/config/queryClient';
import { useNavigate } from 'react-router-dom';
import useCheckErrorCode from 'hook/useCheckErrorCode';
import useMultiLanguage from 'hook/useMultiLanguage';
import { domainNameExistedState } from 'recoil/atom/domainNameExistedAtom';

const useCustomMutateRenderMessage = ({ errorCode }) => {
  const navigate = useNavigate();
  const [, setShowMessageFeedback] = useRecoilState(messageFeedback);
  const { APPLICATION_CANCEL_TEXT } = useMultiLanguage();
  const { handleClearErrorMessage, handleRenderMessage, errorMessage } =
    useCheckErrorCode({
      errorCode: errorCode,
    });
  const [, setDomainNameExisted] = useRecoilState(domainNameExistedState);

  /**
   * api: from axios define in folder api.
   * refetchKey: is queryKey you define for refetch api.
   * messageSuccess: content for message component, trigger when fetch api SUCCESS.
   * errorCode: content for alert component, trigger when fetch api ERROR, render message.
   * useMutate: return mutate, isLoading, isError... read doc.
   */

  const getDomainExisted = (message) => {
    const index = message.indexOf(':');
    if (index !== -1) {
      return message.split(':')[1];
    } else {
      return undefined;
    }
  };

  const getNewMessage = (message) => {
    const index = message.indexOf(':');
    if (index !== -1) {
      return message.split(':')[0];
    } else {
      return message;
    }
  };

  const useMutationAPIHandleErrorCode = ({
    api,
    refetchKey,
    messageSuccess,
    path,
  }) => {
    return useMutation({
      mutationFn: api,
      onSuccess: () => {
        handleClearErrorMessage();
        queryClient.refetchQueries((refetchKey ??= '')).then(() => {
          setShowMessageFeedback({
            display: true,
            type: 'success',
            content: messageSuccess ?? APPLICATION_CANCEL_TEXT,
          });
          navigate(path);
        });
      },
      onError: (response) => {
        const domainExisted = getDomainExisted(response.response.data.message);
        setDomainNameExisted(domainExisted);

        response.response.data.message = getNewMessage(
          response.response.data.message,
        );
        if (response.response.data.message === '404_PRODUCT_NOT_FOUND') {
          navigate('/404')
        }

        handleRenderMessage(response);
      },
    });
  };
  return { useMutationAPIHandleErrorCode, errorMessage };
};

export default useCustomMutateRenderMessage;
