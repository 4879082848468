import React from 'react';
import {
  Container,
  ContentWrapper,
  BlockFooter,
  BlockHeader,
} from 'style/styleComponent/styled2';

const LayoutDefault2 = ({ children }) => {
  return (
    <ContentWrapper>
      <Container>
        <BlockHeader />
        {children}
        <BlockFooter />
      </Container>
    </ContentWrapper>
  );
};

export default LayoutDefault2;
