import styled from 'styled-components';
import { Radio } from 'antd';

export const GridLayout = styled.div`
  display: grid;
  grid-template-columns: 150px 400px 110px 200px;
  column-gap: 24px;
`;

export const Grid1 = styled.div`
  grid-column: 1 / 2;
  margin-top: 8px;
`;

export const Grid2 = styled.div`
  grid-column: 2 / 3;
`;

export const Grid3 = styled.div`
  grid-column: 3/ 4;
`;

export const Grid1to3 = styled.div`
  grid-column: 1 / 3;
`;

export const Grid2to4 = styled.div`
  grid-column: 2 / 5;
  padding-top: 9px;
`;

export const RadioStyled = styled(Radio)`
  color: var(--FORM-LABEL-default);
  span {
    font-family: 'inter-regular', sans-serif;
  }
`;
