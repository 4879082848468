import React from 'react';
import { HeaderTable, SelectStyled, TitleScreen } from './styled';
import { defaultSelectPageSizeOption } from 'constant/Pagination/pageSizeOption';

const HeaderOpenSearch = ({ titleScreen, handleChangePageSize, pageSize }) => {
  return (
    <HeaderTable>
      <TitleScreen>{titleScreen}</TitleScreen>
      <SelectStyled
        value={pageSize}
        onChange={handleChangePageSize}
        options={defaultSelectPageSizeOption}
      />
    </HeaderTable>
  );
};

export default HeaderOpenSearch;
