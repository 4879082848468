import styled from 'styled-components';
import { Button } from 'antd';

export const ButtonIconStyled = styled(Button)`
  min-width: 44px;
  width: 44px;
  height: 40px;
  .icon {
    color: var(--PRIMARY-default);
  }
`;
