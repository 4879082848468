import TopPageProductPartnerUser from 'page/Application/ManageProductAndService/TopPageProductPartnerUser/index';
import NotFound from 'page/NotFound';
import {
  MANAGE_PRODUCT_AND_SERVICE_PATH,
  OPENSEARCH_RESULT,
  PROJECT_REGISTRATION_PATH,
} from 'constant/appPath';
import ManageProductAndService from 'page/Application/ManageProductAndService';
import Application from 'page/Application';

import PartnerWebsite, {
  PartnerWebsiteOverView,
} from 'page/Application/PartnerWebsite';
import MyPage, { MyPageEdit, MyPageOverview } from 'page/Application/MyPage';
import ProjectRegistration, {
  ProjectRegistrationDetail,
  ProjectRegistrationOverview,
} from 'page/Application/ProjectRegistration';
import TopPageViewSuperDetailProduct from 'page/Application/ManageProductAndService/TopPageProduct/TopPageViewProductSuperDetail';
import PartnerWebsiteDetail from 'page/Application/PartnerWebsite/PartnerWebsiteDetail';
import {
  DetailNotifyPartnerAdmin,
  TopPageNotifyDetailPartnerAdmin,
  TopPagePartnerAdmin,
  TopPageProductDetailPartnerAdmin,
  TopPageProductSuperDetailPartnerAdmin,
} from 'page/Application/ManageProductAndService/TopPageProductPartnerAdmin';
import OpensearchResult from 'page/Application/Opensearch/OpensearchResult';
import { RESET_PASSWORD_PATH } from 'constant/authenticationPath';
import ResetPassword from 'page/Authentication/ForgotPassword/ResetPassword';

export const partnerUserRouterData = [
  {
    path: '/',
    element: <Application />,
    errorElement: <NotFound />,
    children: [
      {
        path: '/partner-page',
        element: <PartnerWebsite />,
        children: [
          {
            index: true,
            element: <PartnerWebsiteOverView />,
          },
          {
            path: `/partner-page/detail/:noticeId`,
            element: <PartnerWebsiteDetail />,
          },
        ],
      },
      {
        path: 'my-page',
        element: <MyPage />,
        children: [
          {
            index: true,
            element: <MyPageOverview />,
          },
          {
            path: `edit`,
            element: <MyPageEdit />,
          },
        ],
      },
      {
        path: MANAGE_PRODUCT_AND_SERVICE_PATH,
        element: <ManageProductAndService />,
      },
      {
        path: PROJECT_REGISTRATION_PATH,
        element: <ProjectRegistration />,
        children: [
          {
            index: true,
            element: <ProjectRegistrationOverview />,
          },
          {
            path: 'detail/:projectId',
            element: <ProjectRegistrationDetail />,
          },
        ],
      },
      {
        path: 'top-page',
        element: <TopPageProductPartnerUser />,
        children: [
          {
            index: true,
            element: <TopPagePartnerAdmin />,
          },
          {
            path: 'top-page-product/:productId',
            element: <TopPageProductDetailPartnerAdmin />,
          },
          {
            path: 'top-page-product/list-notify/:productId',
            element: <DetailNotifyPartnerAdmin />,
          },
          {
            path: 'top-page-product/list-notify/:productId/notify-detail/:notifyId',
            element: <TopPageNotifyDetailPartnerAdmin />,
          },
          {
            path: 'top-page-product/:productId/notify-detail/:notifyId',
            element: <TopPageNotifyDetailPartnerAdmin />,
          },
          {
            path: 'top-page-product/:productId/top-page-detail-product/:menuId',
            element: <TopPageProductSuperDetailPartnerAdmin />,
          },
          {
            path: 'top-page-product/:productId/top-page-detail-product/:menuId/detail/:detailId',
            element: <TopPageViewSuperDetailProduct />,
          },
          {
            path: 'list-notify',
            element: <DetailNotifyPartnerAdmin />,
          },
          {
            path: 'notify-detail/:notifyId',
            element: <TopPageNotifyDetailPartnerAdmin />,
          },
          {
            path: 'list-notify/notify-detail/:notifyId',
            element: <TopPageNotifyDetailPartnerAdmin />,
          },
        ],
      },
      {
        path: OPENSEARCH_RESULT,
        element: <OpensearchResult roleType={'partnerUser'} />,
      },
    ],
  },
  {
    path: RESET_PASSWORD_PATH,
    element: <ResetPassword />,
  },
  {
    path: '404',
    element: <NotFound/>
  }
];
