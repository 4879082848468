import React from 'react';
import { FormItem } from 'components/Form2';
import { InputStyled } from 'components/Form2/styled';
import { MAX_LENGTH_EMAIL } from 'constant/inputMaxlength';
import LabelForm from 'components/Form/LabelForm';
import FormMessage from 'components/Form/FormMessage';
import { CHECK_VALIDATE_EMAIL } from 'constant/regex';

const FormValidateEmail = ({
  maxLength,
  name,
  label,
  prefix,
  placeholder,
  textRequired,
  textValidate,
  required,
}) => {
  return (
    <FormItem
      name={name}
      label={label ? <LabelForm text={'form label validate email'} /> : false}
      rules={[
        {
          required: !!required,
          message: (
            <FormMessage
              text1={textRequired ? textRequired : 'empty error'}
              marginBottom={'12px'}
            />
          ),
        },
        {
          pattern: CHECK_VALIDATE_EMAIL,
          message: (
            <FormMessage
              text1={textValidate ? textValidate : 'wrong validate'}
              marginBottom={'12px'}
            />
          ),
        },
      ]}
    >
      <InputStyled
        prefix={prefix ? prefix : null}
        placeholder={placeholder}
        size="large"
        maxLength={maxLength ? maxLength : MAX_LENGTH_EMAIL}
      />
    </FormItem>
  );
};

export default FormValidateEmail;
