import styled from 'styled-components';

export const SkeletonContainer = styled.div`
  padding: 12px;
`;

export const BaseSkeletonItem = styled.div`
  display: flex;
  align-items: center;
  gap: 2px;
  &:not(&:last-child) {
    margin-bottom: 10px;
  }
`;

export const SkeletonParent = styled(BaseSkeletonItem)``;

export const SkeletonChildren = styled(BaseSkeletonItem)`
  margin-left: 38px;
`;
export const BaseSkeletonEffect = styled.div`
  border-radius: 6px;
  height: 36px;
  animation: skeleton-loading 1s linear infinite alternate;

  @keyframes skeleton-loading {
    0% {
      //background-color: hsl(200, 20%, 82%);
      background-color: rgb(229, 229, 229);
    }
    100% {
      //background-color: hsl(200, 20%, 95%);
      background-color: #f2f2f2;
    }
  }
`;

export const Arrow = styled(BaseSkeletonEffect)`
  width: 36px;
`;

export const Title = styled(BaseSkeletonEffect)`
  width: 226px;
`;
