import { CaretRightOutlined } from '@ant-design/icons';
import {
  ChildrenMenuWrapper,
  ContentMenuChildren,
  ContentMenuParent,
  ExpandButton,
  IconDot,
  IconMenu,
  MenuItemChildren,
  MenuItemParent,
  TitleMenu,
  TitleTopPage,
} from 'components/Layout/ApplicationLayout/SidebarMenu/Desktop/Menu/MenuItem/styled';
import React, { useMemo, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useResetRecoilState } from 'recoil';
import { filterProject } from 'recoil/atom/filterProject';

const MenuItem = ({ menu }) => {
  const navigate = useNavigate();
  const [expand, setExpand] = useState(true);
  const { pathname } = useLocation();

  const resetFilterProject = useResetRecoilState(filterProject);

  // check user click parent menu or children menu
  const selectMenu = () => {
    resetFilterProject();
    if (menu.children.length > 0) {
      setExpand(!expand);
      handleNavigateScreen();
    } else {
      handleNavigateScreen();
    }
  };

  // navigate to menu path when user click menu
  const handleNavigateScreen = () => {
    if (menu.path !== '') {
      navigate(menu.path);
    }
  };

  // split path from url
  const CheckLocation = useMemo(() => {
    const regex = /\/[a-z1-9-]+/g;
    if (pathname === '' || pathname === '/') {
      return '/';
    } else {
      let check = pathname.match(regex);
      return check[0];
    }
  }, [pathname]);

  // render top page with role Partner Admin, Partner User
  if (menu.isTopPage) {
    return (
      <MenuItemParent
        onClick={selectMenu}
        className={menu.path === CheckLocation ? 'selected' : ''}
      >
        <ContentMenuParent className="content-menu-responsive">
          <TitleTopPage>{menu.label}</TitleTopPage>
        </ContentMenuParent>
      </MenuItemParent>
    );
  }
  // render parent menu with icon arrow
  else if (menu.isParent && menu.isTopPage === false) {
    return (
      <>
        <MenuItemParent
          onClick={selectMenu}
          className={menu.path === CheckLocation ? 'selected' : ''}
        >
          <ContentMenuParent className="content-menu-responsive">
            <IconMenu>{menu.icon}</IconMenu>
            <TitleMenu>{menu.label}</TitleMenu>
          </ContentMenuParent>
          {menu.children.length > 0 && (
            <ExpandButton className={expand ? 'open' : 'close'}>
              <CaretRightOutlined />
            </ExpandButton>
          )}
        </MenuItemParent>

        <ChildrenMenuWrapper className={expand ? 'show' : 'hide'}>
          {menu.children.map((item) => {
            return <MenuItem menu={item} key={item.id} />;
          })}
        </ChildrenMenuWrapper>
      </>
    );
  }
  // render children menu with icon dot
  else if (menu.isParent === false && menu.isTopPage === false) {
    return (
      <MenuItemChildren
        onClick={selectMenu}
        className={menu.path === CheckLocation ? 'selected' : ''}
      >
        <TitleMenu>
          <ContentMenuChildren className="content-menu-responsive">
            <IconDot
              className={menu.path === CheckLocation ? 'selected' : ''}
            />
            <TitleMenu>{menu.label}</TitleMenu>
          </ContentMenuChildren>
        </TitleMenu>
      </MenuItemChildren>
    );
  }
};

export default MenuItem;
