import { useIntl } from 'react-intl';
const useMultiLanguageAccountSolitonList = () => {
  const intl = useIntl();
  const ACCOUNT_LIST_TITLE = intl.formatMessage({
    id: 'authentication.accountList.title',
  });
  const ACCOUNT_EXPORT_FILE = intl.formatMessage({
    id: 'authentication.accountList.exportFile',
  });
  const ACCOUNT_PLACEHOLDER_SEARCH = intl.formatMessage({
    id: 'authentication.accountSoliton.placeholderSearch',
  });
  const ACCOUNT_LIST_MULTI_DELETE = intl.formatMessage({
    id: 'authentication.accountList.multiDelete',
  });
  const ACCOUNT_LIST_DELETE_SUCCESS = intl.formatMessage({
    id: 'authentication.accountList.delete.success',
  });
  const ACCOUNT_LIST_MULTI_DELETE_CONFIRM1 = intl.formatMessage({
    id: 'authentication.accountList.multiDeleteConfirm1',
  });
  const ACCOUNT_LIST_MULTI_DELETE_CONFIRM2 = intl.formatMessage({
    id: 'authentication.accountList.multiDeleteConfirm2',
  });
  const ACCOUNT_LIST_MULTI_DELETE_SUCCESS1 = intl.formatMessage({
    id: 'authentication.accountList.multiDeleteSuccess1',
  });
  const ACCOUNT_LIST_MULTI_DELETE_SUCCESS2 = intl.formatMessage({
    id: 'authentication.accountList.multiDeleteSuccess2',
  });
  const ACCOUNT_LIST_PLACEHOLDER_SEARCH = intl.formatMessage({
    id: 'authentication.accountList.placeholderSearch',
  });
  const ACCOUNT_LIST_PLACEHOLDER_SEARCH_ROLE_SA = intl.formatMessage({
    id: 'authentication.accountList.placeholderSearchRoleSA',
  });
  const ACCOUNT_LIST_TABLE_TITLE_ACCOUNT_NAME = intl.formatMessage({
    id: 'authentication.accountList.table.title.accountName',
  });
  const ACCOUNT_LIST_TABLE_TITLE_COMPANY_NAME = intl.formatMessage({
    id: 'authentication.accountList.table.title.companyName',
  });
  const ACCOUNT_LIST_TABLE_TITLE_EMAIL = intl.formatMessage({
    id: 'authentication.accountList.table.title.email',
  });
  const ACCOUNT_LIST_TABLE_TITLE_ACCOUNT_TYPE = intl.formatMessage({
    id: 'authentication.accountList.table.title.accountType',
  });
  const ACCOUNT_LIST_TABLE_ROLE_PARTNER_USER = intl.formatMessage({
    id: 'authentication.accountList.table.title.accountType.partnerUser',
  });
  const ACCOUNT_LIST_TABLE_ROLE_PARTNER_ADMIN = intl.formatMessage({
    id: 'authentication.accountList.table.title.accountType.partnerAdmin',
  });
  const ACCOUNT_LIST_TABLE_TITLE_ACCOUNT_STATUS = intl.formatMessage({
    id: 'authentication.accountList.table.title.accountStatus',
  });
  const ACCOUNT_LIST_TABLE_TITLE_STATUS_DELETE = intl.formatMessage({
    id: 'authentication.accountList.table.action.delete',
  });
  const ACCOUNT_LIST_TABLE_TITLE_ACTION = intl.formatMessage({
    id: 'authentication.accountList.table.title.action',
  });
  const ACCOUNT_LIST_TABLE_STATUS_STATUS_VALID = intl.formatMessage({
    id: 'authentication.accountList.table.status.valid',
  });
  const ACCOUNT_LIST_TABLE_STATUS_STATUS_NON = intl.formatMessage({
    id: 'authentication.accountList.table.status.non',
  });
  const ACCOUNT_LIST_TABLE_STATUS_STATUS_BANNED = intl.formatMessage({
    id: 'authentication.accountList.table.status.banned',
  });
  const ACCOUNT_LIST_TABLE_PAGINATION_TOTAL = intl.formatMessage({
    id: 'authentication.accountList.table.paginationTotal',
  });
  const ACCOUNT_LIST_NO_DATA = intl.formatMessage({
    id: 'authentication.accountList.noData',
  });
  const ACCOUNT_LIST_PLACEHOLDER_SEARCH_NODATA = intl.formatMessage({
    id: 'authentication.accountList.placeholderSearch.noData',
  });
  return {
    ACCOUNT_LIST_TITLE,
    ACCOUNT_EXPORT_FILE,
    ACCOUNT_PLACEHOLDER_SEARCH,
    ACCOUNT_LIST_MULTI_DELETE,
    ACCOUNT_LIST_DELETE_SUCCESS,
    ACCOUNT_LIST_MULTI_DELETE_CONFIRM1,
    ACCOUNT_LIST_MULTI_DELETE_CONFIRM2,
    ACCOUNT_LIST_MULTI_DELETE_SUCCESS1,
    ACCOUNT_LIST_MULTI_DELETE_SUCCESS2,
    ACCOUNT_LIST_PLACEHOLDER_SEARCH,
    ACCOUNT_LIST_TABLE_TITLE_ACCOUNT_NAME,
    ACCOUNT_LIST_TABLE_TITLE_EMAIL,
    ACCOUNT_LIST_TABLE_TITLE_COMPANY_NAME,
    ACCOUNT_LIST_TABLE_TITLE_ACCOUNT_TYPE,
    ACCOUNT_LIST_TABLE_ROLE_PARTNER_USER,
    ACCOUNT_LIST_TABLE_ROLE_PARTNER_ADMIN,
    ACCOUNT_LIST_TABLE_TITLE_ACCOUNT_STATUS,
    ACCOUNT_LIST_TABLE_TITLE_STATUS_DELETE,
    ACCOUNT_LIST_TABLE_TITLE_ACTION,
    ACCOUNT_LIST_TABLE_STATUS_STATUS_VALID,
    ACCOUNT_LIST_TABLE_STATUS_STATUS_NON,
    ACCOUNT_LIST_TABLE_STATUS_STATUS_BANNED,
    ACCOUNT_LIST_TABLE_PAGINATION_TOTAL,
    ACCOUNT_LIST_NO_DATA,
    ACCOUNT_LIST_PLACEHOLDER_SEARCH_NODATA,
    ACCOUNT_LIST_PLACEHOLDER_SEARCH_ROLE_SA,
  };
};
export default useMultiLanguageAccountSolitonList;
