import { MenuOutlined } from '@ant-design/icons';
import { DndContext } from '@dnd-kit/core';
import { restrictToVerticalAxis } from '@dnd-kit/modifiers';
import {
  arrayMove,
  SortableContext,
  useSortable,
  verticalListSortingStrategy,
} from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import React, { useMemo } from 'react';

import AntdTableDraggable from 'components/AntdTableDraggable';

const Row = ({ children, ...props }) => {
  const {
    attributes,
    listeners,
    setNodeRef,
    setActivatorNodeRef,
    transform,
    transition,
    isDragging,
  } = useSortable({
    id: props['data-row-key'],
  });
  const style = {
    ...props.style,
    transform: CSS.Transform.toString(
      transform && {
        ...transform,
        scaleY: 1,
      },
    ),
    transition,
    ...(isDragging
      ? {
          position: 'relative',
          zIndex: 1,
        }
      : {}),
  };
  return (
    <tr {...props} ref={setNodeRef} style={style} {...attributes}>
      {React.Children.map(children, (child) => {
        if (child.key === 'sort') {
          return React.cloneElement(child, {
            children: (
              <MenuOutlined
                ref={setActivatorNodeRef}
                style={{
                  touchAction: 'none',
                  cursor: 'move',
                }}
                {...listeners}
              />
            ),
          });
        }
        return child;
      })}
    </tr>
  );
};

const DragSortingTable = ({
  dataSource,
  setDataSource,
  columns,
  isLoading,
}) => {
  const onDragEnd = ({ active, over }) => {
    if (active.id !== over?.id) {
      setDataSource((previous) => {
        const activeIndex = previous.findIndex((i) => i.key === active.id);
        const overIndex = previous.findIndex((i) => i.key === over?.id);
        // reverse order
        const activeElement = previous[activeIndex];
        const overElement = previous[overIndex];

        if (
          activeElement &&
          activeElement != undefined &&
          overElement &&
          overElement != undefined
        ) {
          let temp = activeElement.notifyOrderId;
          activeElement.notifyOrderId = overElement.notifyOrderId;
          overElement.notifyOrderId = temp;
        }
        return arrayMove(previous, activeIndex, overIndex);
      });
    }
  };

  return (
    <>
      {dataSource && dataSource != [] && dataSource != undefined && (
        <DndContext modifiers={[restrictToVerticalAxis]} onDragEnd={onDragEnd}>
          <SortableContext
            // rowKey array
            items={dataSource.map((i) => i.key)}
            strategy={verticalListSortingStrategy}
          >
            <AntdTableDraggable
              components={{
                body: {
                  row: Row,
                },
              }}
              columns={columns}
              isLoading={isLoading}
              dataSource={dataSource}
              showHeader={false}
              isBoldBottomBorder={true}
            />
          </SortableContext>
        </DndContext>
      )}
    </>
  );
};

export default DragSortingTable;
