import styled from 'styled-components';
import { Button } from 'antd';

export const ElementCenter = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const ElementVerticalCenter = styled.div`
  display: flex;
  align-items: center;
`;

export const TextCenter = styled.div`
  text-align: center;
`;

export const GroupButtonAction = styled.div`
  display: flex;
  justify-content: center;
  gap: 12px;
`;

export const ButtonAction = styled(Button)`
  width: 38px;
  text-align: center;
  border-radius: 50%;
  cursor: pointer;
  color: #a5a5a5;

  &:hover {
    color: #656464 !important;
  }
`;

export const FooterModal = styled.div`
  margin-top: 32px;
  display: flex;
  justify-content: end;
  gap: 15px;
`;

export const Container = styled.div`
  //min-height: calc(100dvh - 309px);
  margin: 0 15px;
`;

export const ContentWrapper = styled.div`
  background: white;
  border-radius: 8px;
  font-family: 'inter-regular', sans-serif;
`;

export const Block = styled.div`
  height: 8px;
`;

export const BlockHeader = styled(Block)`
  border-radius: 8px 8px 0 0;
`;

export const BlockFooter = styled(Block)`
  border-radius: 0 0 8px 8px;
`;

export const BaseTitle = styled.div`
  font-size: 16px;
  font-family: 'inter-semibold', sans-serif;
  font-weight: 600;
  color: #656464;
`;
