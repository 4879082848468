import { Button, Modal } from 'antd';
import {
  ButtonGroup,
  FooterModal,
  TitleStyle,
} from 'components/AntModalTitleFile/styled';
import { ExclamationCircleFilled } from '@ant-design/icons';
import React from 'react';
import useMultiLanguageRegisterCompany from 'page/Application/ManageCompanyList/RegisterCompany/hook/useMultiLanguageRegisterCompany';

const AntModalTitleFile2 = ({
  isModalOpen,
  handleOk,
  handleCancel,
  loadingSubmit,
  title,
  content,
  width,
  paddingRight,
}) => {
  const { REGISTER_COMPANY_SAVE, REGISTER_COMPANY_CANCEL } =
    useMultiLanguageRegisterCompany();

  return (
    <Modal
      forceRender
      title={<TitleStyle>{title ?? 'Basic Modal'}</TitleStyle>}
      open={isModalOpen}
      onOk={handleOk}
      onCancel={handleCancel}
      centered={true}
      maskClosable={false}
      width={width ?? 430}
      // style={{ padding: '80px 0' }}/**/
      icon={<ExclamationCircleFilled />}
      closable={false}
      footer={[
        <FooterModal key="footerModal">
          <ButtonGroup style={{ paddingRight: paddingRight }}>
            <Button
              key="submit"
              type="primary"
              loading={loadingSubmit}
              onClick={handleOk}
              style={{ minWidth: '78px' }}
            >
              <span> {REGISTER_COMPANY_SAVE}</span>
            </Button>
            <Button
              key="back"
              onClick={handleCancel}
              style={{
                marginLeft: '20px',
                width: '128px',
                background: 'white',
                color: '#656464',
              }}
            >
              <span> {REGISTER_COMPANY_CANCEL}</span>
            </Button>
          </ButtonGroup>
        </FooterModal>,
      ]}
    >
      {content}
    </Modal>
  );
};
export default AntModalTitleFile2;
