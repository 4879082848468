import React, { useEffect, useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { useRecoilState } from 'recoil';
import { listFileDetailsRecoil } from 'recoil/atom/fileRequest';
import { Form } from 'antd';
import {
  deleteProject,
  getDetailProject,
  updateProject,
} from 'api/project/solitonAdmin.api';
import { MANAGE_PROJECT_REGISTRATION_PATH } from 'constant/appPath';
import { TIME_ZONE } from 'constant/notificationConstants';
import { CHECK_SPECIAL_CHARACTER } from 'constant/regex';
import dayjs from 'dayjs';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';
import useModalConfirm from 'hook/useModalConfirm';
import useCustomMutate from 'hook/useQuery/useCustomMutate';
import useCustomMutateRenderMessage from 'hook/useQuery/useCustomMutateRenderMessage';
import useCustomQuery from 'hook/useQuery/useCustomQuery';
import FormProject from 'page/Application/ManageProjectRegistration/component/FormProject';
import useErrorCodeManageProject from 'page/Application/ManageProjectRegistration/hook/useErrorCodeManageProject';
import { network } from 'recoil/atom/network';
import { message } from 'antd';

dayjs.extend(timezone);
dayjs.extend(utc);

const EditProject = () => {
  const [isDisplayed, setIsDisplayed] = useState(true);
  const { useQueryAPI } = useCustomQuery();
  const { ERROR_CODE_MANAGE_PROJECT } = useErrorCodeManageProject();
  const { errorMessage, useMutationAPIHandleErrorCode } =
    useCustomMutateRenderMessage({
      errorCode: ERROR_CODE_MANAGE_PROJECT,
    });
  const { projectId } = useParams();
  const [isDisable, setIsDisable] = useState(false);
  const [formProject] = Form.useForm();
  const [content, setContent] = useState();
  const [listFileDetails, setListFileDetails] = useRecoilState(
    listFileDetailsRecoil,
  );
  const [idFile, setIdFile] = useState(null);
  const navigate = useNavigate();
  const { contextHolder, handleConfirmAction } = useModalConfirm();
  const { useMutationAPI } = useCustomMutate();
  const [isCancel, setIsCancel] = useState(false);
  const [plainText, setPlainText] = useState(null);
  const [imageObject, setImageObject] = useState(null);
  const [isOnline] = useRecoilState(network);
  const [messageErrorNetwork, contextErrorNetwork] = message.useMessage();

  const listItem = [
    {
      title: <Link to={'/'}>企業管理</Link>,
    },
    {
      title: (
        <Link to={'/manage-project-registration'}>案件登録お申込み管理</Link>
      ),
    },
    {
      title: '案件登録お申し込み詳細',
    },
  ];

  const {
    data: getProjectDetail = [],
    isLoading,
    error,
  } = useQueryAPI({
    api: getDetailProject,
    key: 'projectDetail',
    params: {
      projectId: projectId,
    },
    options: {
      refetchOnWindowFocus: false,
    },
  });

  const {
    mutate: updateProjectMutate,
    isError: isErrorUpdate,
    isLoading: isLoadingUpdate,
    isSuccess: isSuccessUpdate,
  } = useMutationAPIHandleErrorCode({
    api: updateProject,
    refetchKey: 'projectDetail',
    path: `/${MANAGE_PROJECT_REGISTRATION_PATH}`,
    messageSuccess: '案件登録お申し込みの情報を変更しました。',
  });

  const {
    mutate: deleteProjectMutate,
    isError: isErrorDelete,
    isLoading: isLoadingDelete,
    isSuccess: isSuccessDelete,
  } = useMutationAPI({
    api: deleteProject,
    refetchKey: 'projectList',
    path: `/${MANAGE_PROJECT_REGISTRATION_PATH}`,
    messageSuccess: '正常に削除されました',
  });

  const handleDeleteProject = () => {
    handleConfirmAction({
      confirmFn: deleteProjectMutate,
      params: {
        projectId: projectId,
      },
      setIsDisable: setIsDisable,
      title: 'この案件登録お申込みを削除してもよろしいでしょうか？',
      okText: '削除',
      cancelText: 'キャンセル',
    });
    setIsDisable(true);
  };

  useEffect(() => {
    const timeZoneFromClient = Intl.DateTimeFormat().resolvedOptions().timeZone;
    if (
      error?.response?.data?.message === '400_PROJECT_NOT_EXISTED' ||
      error?.response?.data?.message === '400_ARGUMENT_IS_NOT_VALID' ||
      CHECK_SPECIAL_CHARACTER.test(projectId)
    ) {
      navigate('/project-not-found');
    }
    if (
      getProjectDetail &&
      getProjectDetail.ec === false &&
      getProjectDetail.data
    ) {
      const project = getProjectDetail?.data;
      const lstFileResponse = project?.detailResponses;
      setIsDisplayed(project?.projectIsDisplayed);
      setContent(project?.projectContent);
      setPlainText(project?.projectSearchContent);
      const lstFile = lstFileResponse?.map((item, index) => ({
        mainIndex: index,
        title: item?.detailTitle?.trim(),
        displayStatus: item.detailDisplayStatus === 'SHOW',
        explanation: item?.detailExplanation,
        displayDate: item.detailDisplayDate ?? null,
        publishedFrom:
          item.detailPublishedFrom &&
          dayjs.utc(item.detailPublishedFrom).tz(timeZoneFromClient),
        publishedTo:
          item.detailPublishedTo &&
          dayjs.utc(item.detailPublishedTo).tz(timeZoneFromClient),
        multipartFile: {
          uid: item?.fileResponse?.fileUuid,
          id: item?.fileResponse?.fileId,
          name: item?.fileResponse?.fileName,
          status: 'done',
          type: item?.fileResponse?.fileType,
          size: item?.fileResponse?.fileSize,
        },
        detailId: item?.detailId,
      }));
      setListFileDetails(lstFile);
      setIdFile(project?.fileId);
    }
  }, [
    error?.response?.data?.message,
    getProjectDetail,
    navigate,
    projectId,
    setListFileDetails,
  ]);
  const errorNetwork = () => {
    messageErrorNetwork.open({
      type: 'error',
      content:
        '問題が発生しました。インターネット接続を確認して、もう一度やり直してください。',
    }).then();
  };
  const onFinish = (values) => {
    if (isOnline === true) {
      let formData = new FormData();
      const lstDetails = listFileDetails.map((value) => {
        const dateFrom = value?.publishedFrom
          ? new Date(value?.publishedFrom).setSeconds(0)
          : null;
        const dateTo = value?.publishedTo
          ? new Date(value?.publishedTo).setSeconds(0)
          : null;

        const object = {
          title: value.title,
          explanation: value.explanation,
          displayStatus: value.displayStatus === false ? 'HIDE' : 'SHOW',
          displayDate: value.displayDate ?? null,
          publishedFrom:
            value.displayStatus === false
              ? null
              : dayjs.tz(
                  dateTo && dateFrom > dateTo ? dateTo : dateFrom,
                  TIME_ZONE,
                ),
          publishedTo:
            value.displayStatus === false
              ? null
              : dateTo &&
                dayjs.tz(dateFrom > dateTo ? dateFrom : dateTo, TIME_ZONE),
          fileStorageRequest: value?.fileStorageRequest,
        };
        if (value.multipartFile instanceof File) {
          Object.assign(object, { multipartFile: value.multipartFile });
        }
        if (value.detailId) {
          Object.assign(object, { detailId: value.detailId });
        }
        return object;
      });

      const projectRequest = {
        projectName: values.projectName?.trim(),
        projectContent: content === '<p>&nbsp;</p>' ? '' : content,
        projectSearchContent: plainText,
        projectUrl: values?.projectUrl?.trim(),
        projectUrlTitle: values?.projectUrlTitle?.trim(),
        projectIsDisplayed: values.projectIsDisplayed,
        fileId: idFile,
        fileDetails: lstDetails,
        avatarRequest: imageObject && {
          fileName: imageObject?.fileName,
          keyFileName: imageObject?.keyFileName,
          contentType: imageObject?.contentType,
          size: imageObject?.size,
        },
      };

      formData.append(
        'projectRequest',
        new Blob([JSON.stringify(projectRequest)], {
          type: 'application/json',
        }),
      );

      const avatar = values?.avatar;
      if (avatar && avatar[0]) {
        formData.append('avatar', avatar[0].originFileObj);
      }

      lstDetails.forEach((object, index) => {
        Object.keys(object).forEach((property) => {
          if (object[property]) {
            formData.append(
              `fileDetails[${index}].${property}`,
              object[property],
            );
          }
        });
      });

      updateProjectMutate({
        projectId: projectId,
        payload: formData,
      });
    } else {
      errorNetwork();
    }
  };

  const handleCancel = () => {
    handleConfirmAction({
      confirmFn: navigate,
      params: `/${MANAGE_PROJECT_REGISTRATION_PATH}`,
      title: '変更をキャンセルしてもよろしいでしょうか？',
      okText: 'はい',
      cancelText: 'キャンセル',
      setIsDisable: setIsCancel,
    });
    setIsCancel(true);
  };

  return (
    <>
      {contextErrorNetwork}
      {contextHolder}
      <FormProject
        setIsDisplayed={setIsDisplayed}
        isDisplayed={isDisplayed}
        isEdit={true}
        listItem={listItem}
        form={formProject}
        data={getProjectDetail.data}
        isLoading={
          isOnline === false
            ? isOnline
            : isLoading || isLoadingUpdate || isLoadingDelete
        }
        handleDeleteProject={handleDeleteProject}
        isDisable={isDisable}
        setIsDisable={setIsDisable}
        isSuccess={isSuccessUpdate || isSuccessDelete}
        content={content}
        setContent={setContent}
        onFinish={onFinish}
        setIdFile={setIdFile}
        isError={isErrorUpdate || isErrorDelete}
        errorMessage={errorMessage}
        handleCancel={handleCancel}
        isCancel={isCancel}
        setIsCancel={setIsCancel}
        imageObject={imageObject}
        setImageObject={setImageObject}
        setPlainText={setPlainText}
      />
    </>
  );
};
export default EditProject;
