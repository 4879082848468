import React, { useMemo } from 'react';
import {
  Grid1,
  Grid2to4,
} from 'page/Application/ManageCompanyList/component/Form/styled';
import { FormLabelGrid } from 'page/Application/ManageCompanyList/component/Form';
import { RadioStyle } from 'page/Application/ManageCompanyList/component/Form/FormPermision/styled';
import { Radio, Tooltip } from 'antd';
import AntdTable from 'components/AntdTable';
import useMultiLanguageEditCompany from 'page/Application/ManageCompanyList/EditCompany/hook/useMultiLanguageEditCompany';
import { TooltipHide } from 'page/Application/ManageCompanyList/CompanyList/styled';

/**
 * dataRaw: data from API, need format for add key.
 * isLoading: render loading when api fetching
 * setListPermissionUser: setState list data user permission.
 */

const FormPermission = ({
  dataRaw,
  isLoading,
  setListPermissionUser,
  height,
}) => {
  // format data, add key === id for render table
  const dataSource = useMemo(() => {
    if (dataRaw?.length === 0) return;
    return dataRaw?.map((item) => ({
      ...item,
      key: item?.userId,
    }));
  }, [dataRaw]);
  const {
    DETAIL_COMPANY_USER_ACCOUNT,
    DETAIL_COMPANY_USER_NAME,
    DETAIL_COMPANY_USER_EMAIL,
    DETAIL_COMPANY_USER_ACCOUNT_STATUS,
    DETAIL_COMPANY_USER_ACCOUNT_STATUS_ENABLED,
    DETAIL_COMPANY_USER_ACCOUNT_STATUS_UNAPPROVED,
    DETAIL_COMPANY_USER_ACCOUNT_STATUS_SUSPENDED,
  } = useMultiLanguageEditCompany();
  // initial columns
  const columns = useMemo(() => {
    // setState data user permission when user onChange Radio button
    const onChange = (recordKey, event) => {
      const value = event?.target?.value;
      const updatedData = dataSource?.map((item) => {
        if (item['userId'] === recordKey['userId']) {
          return {
            ...item,
            approveStatus: value,
          };
        }
        return item;
      });
      setListPermissionUser(updatedData);
    };

    // define columns
    return [
      {
        key: 'name',
        title: (
          <TooltipHide>
            <Tooltip title={DETAIL_COMPANY_USER_NAME} placement={'topLeft'}>
              {DETAIL_COMPANY_USER_NAME}
            </Tooltip>
          </TooltipHide>
        ),
        ellipsis: true,
        render: (data) => {
          return (
            <TooltipHide>
              <Tooltip title={data?.fullName} placement={'topLeft'}>
                {data?.fullName}
              </Tooltip>
            </TooltipHide>
          );
        },
        width: '160px',
      },
      {
        key: 'email',
        title: (
          <TooltipHide>
            <Tooltip title={DETAIL_COMPANY_USER_EMAIL} placement={'topLeft'}>
              {DETAIL_COMPANY_USER_EMAIL}
            </Tooltip>
          </TooltipHide>
        ),
        dataIndex: 'email',
        width: '160px',
        ellipsis: true,
        render: (email) => {
          return (
            <TooltipHide>
              <Tooltip title={email} placement={'topLeft'}>
                {email}
              </Tooltip>
            </TooltipHide>
          );
        },
      },
      {
        key: 'approveStatus',
        title: (
          <TooltipHide>
            <Tooltip
              title={DETAIL_COMPANY_USER_ACCOUNT_STATUS}
              placement={'topLeft'}
            >
              {DETAIL_COMPANY_USER_ACCOUNT_STATUS}
            </Tooltip>
          </TooltipHide>
        ),
        dataIndex: 'approveStatus',
        ellipsis: true,
        render: (type, record) => {
          return (
            <Radio.Group
              onChange={(event) => onChange(record, event)}
              value={type}
              style={{ display: 'flex' }}
            >
              <RadioStyle value="ENABLED">
                {DETAIL_COMPANY_USER_ACCOUNT_STATUS_ENABLED}
              </RadioStyle>
              <RadioStyle value="UNAPPROVED">
                {DETAIL_COMPANY_USER_ACCOUNT_STATUS_UNAPPROVED}
              </RadioStyle>
              <RadioStyle value="SUSPENDED">
                {DETAIL_COMPANY_USER_ACCOUNT_STATUS_SUSPENDED}
              </RadioStyle>
            </Radio.Group>
          );
        },
      },
    ];
    // re-render when dataSource change
  }, [
    dataSource,
    setListPermissionUser,
    DETAIL_COMPANY_USER_NAME,
    DETAIL_COMPANY_USER_EMAIL,
    DETAIL_COMPANY_USER_ACCOUNT_STATUS,
    DETAIL_COMPANY_USER_ACCOUNT_STATUS_ENABLED,
    DETAIL_COMPANY_USER_ACCOUNT_STATUS_UNAPPROVED,
    DETAIL_COMPANY_USER_ACCOUNT_STATUS_SUSPENDED,
  ]);

  return (
    <>
      <Grid1>
        <FormLabelGrid labelText={DETAIL_COMPANY_USER_ACCOUNT} />
      </Grid1>
      <Grid2to4>
        <div style={{ marginBottom: 24 }}>
          <AntdTable
            height={height}
            columns={columns}
            dataSource={dataSource}
            isLoading={isLoading}
          />
        </div>
      </Grid2to4>
    </>
  );
};

export default FormPermission;
