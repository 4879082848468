import React from 'react';

const Logo = () => {
  return (
    <svg
      width="120"
      height="30"
      viewBox="0 0 100 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0.0099965 6.63103C0.079972 6.58267 0.0749738 6.51012 0.079972 6.43757C0.219923 5.22845 0.604788 4.10639 1.29955 3.09072C2.2842 1.65912 3.63873 0.711173 5.35313 0.266217C5.9879 0.101776 6.63768 0.0340652 7.29745 0.0340652C9.92153 0.0340652 12.5456 0.0340652 15.1647 0.0340652C15.2197 0.0340652 15.2747 0.0389017 15.3446 0.0437382C15.3446 0.106613 15.3546 0.159814 15.3546 0.213015C15.3546 2.00735 15.3546 3.80169 15.3546 5.60086C15.3546 5.62988 15.3546 5.65406 15.3546 5.68308C15.3546 5.76046 15.3096 5.78948 15.2347 5.78465C15.1847 5.78465 15.1347 5.78465 15.0847 5.78465C12.7205 5.78465 10.3564 5.78465 7.9922 5.78465C7.64732 5.78465 7.30744 5.81366 6.97756 5.93458C6.34778 6.17156 6.0079 6.62136 5.92293 7.25977C5.88794 7.52578 5.90793 7.79179 5.9879 8.05296C6.15285 8.58497 6.52772 8.91869 7.05753 9.11215C7.30244 9.1992 7.55735 9.25241 7.81726 9.27175C8.572 9.32979 9.32174 9.43135 10.0515 9.61514C11.466 9.9682 12.7505 10.5679 13.8052 11.5691C14.6749 12.3913 15.2647 13.3779 15.6345 14.4952C15.9994 15.5979 16.0994 16.7296 15.9344 17.8759C15.5496 20.6181 13.7452 23.0122 10.9762 24.0956C9.84156 24.5405 8.66197 24.7485 7.4424 24.7485C5.04323 24.7533 2.64907 24.7485 0.249913 24.7485C0.169941 24.7485 0.0849703 24.7243 0.00499825 24.7098C0.00499825 22.8236 0.00499825 20.9325 0.00499825 19.0463C0.099965 19.0318 0.189934 19.0076 0.2849 19.0076C2.59909 19.0076 4.91328 19.0076 7.22247 19.0076C7.45739 19.0076 7.69731 18.9931 7.92723 18.9399C9.24177 18.6255 9.82656 17.3293 9.4217 16.2266C9.23677 15.7188 8.83691 15.4141 8.33208 15.2206C7.96221 15.0755 7.57235 15.0127 7.17749 14.9788C6.55271 14.9256 5.93792 14.8144 5.33813 14.6306C3.32384 14.0067 1.82936 12.7879 0.874694 10.9597C0.42985 10.1133 0.174939 9.21371 0.0649773 8.2706C0.059979 8.2174 0.019993 8.1642 0 8.111V6.63587L0.0099965 6.63103Z"
        fill="#9D1C49"
      />
      <path
        d="M70.8252 25C70.6503 24.971 70.4703 24.942 70.2954 24.9178C68.7459 24.7001 67.3364 24.1488 66.0819 23.2492C64.5424 22.1513 63.4328 20.7342 62.773 18.9979C62.3182 17.7985 62.1333 16.5603 62.2232 15.2835C62.3932 12.8604 63.4028 10.8146 65.2322 9.15084C66.4817 8.00943 67.9612 7.26945 69.6406 6.91155C70.7352 6.67456 71.8449 6.6407 72.9545 6.78096C76.1933 7.19206 79.0573 9.2911 80.3519 12.1978C80.9767 13.6004 81.2266 15.0659 81.1066 16.5942C80.8167 20.3038 78.1926 23.4233 74.6539 24.555C74.0041 24.763 73.3393 24.9033 72.6546 24.9661C72.5996 24.971 72.5446 24.9903 72.4896 25H70.8252ZM71.6899 12.1398C69.5807 12.1204 67.8313 13.7842 67.8263 15.8397C67.8163 17.9774 69.6156 19.5638 71.6299 19.5783C73.7042 19.5976 75.4636 17.9871 75.5136 15.9461C75.5636 13.8954 73.8691 12.1543 71.6899 12.1398Z"
        fill="#9D1C49"
      />
      <path
        d="M81.9413 24.7388C81.9413 24.676 81.9313 24.6179 81.9313 24.5599C81.9313 21.1647 81.9313 17.7743 81.9313 14.3791C81.9313 13.049 82.2212 11.7819 82.891 10.6066C83.6357 9.30077 84.7053 8.31896 86.0799 7.65153C87.4744 6.97442 88.9539 6.70358 90.5083 6.81965C92.4376 6.95991 94.1271 7.65637 95.5516 8.92352C96.7311 9.97304 97.4959 11.2595 97.8558 12.7734C97.9907 13.3441 98.0507 13.9244 98.0507 14.5097C98.0507 17.8565 98.0507 21.1985 98.0507 24.5454C98.0507 24.6082 98.0507 24.676 98.0507 24.7292C97.9107 24.7727 92.3527 24.7872 92.0978 24.7485C92.0978 24.6953 92.0878 24.6373 92.0878 24.5792C92.0878 24.1439 92.0878 23.7038 92.0878 23.2685C92.0878 20.5504 92.0878 17.8323 92.0878 15.1142C92.0878 14.5242 91.8978 13.997 91.483 13.5617C90.9582 13.0104 90.2984 12.8169 89.5437 12.9765C88.7489 13.1458 88.2391 13.6343 87.9892 14.3791C87.9142 14.6064 87.8942 14.8385 87.8942 15.0755C87.8942 18.2192 87.8942 21.363 87.8942 24.5067V24.7146C87.8942 24.7146 87.8692 24.7388 87.8643 24.7437C87.8643 24.7437 87.8543 24.7533 87.8493 24.7533C87.8193 24.7533 87.7943 24.763 87.7643 24.763C85.845 24.763 83.9306 24.763 82.0113 24.763C81.9963 24.763 81.9813 24.7533 81.9363 24.7437L81.9413 24.7388Z"
        fill="#9D1C49"
      />
      <path
        d="M58.5245 13.0007C58.5245 13.0926 58.5245 13.1506 58.5245 13.2087C58.5295 14.795 58.5345 16.3862 58.5395 17.9726C58.5395 18.137 58.5545 18.3015 58.5745 18.4611C58.6595 19.0221 59.0143 19.38 59.5941 19.4671C59.7991 19.4961 60.009 19.5106 60.2189 19.5154C60.6988 19.5203 61.1786 19.5154 61.6584 19.5154C61.8284 19.5154 61.8334 19.5203 61.8733 19.6799C62.3082 21.2759 62.743 22.8719 63.1829 24.4728C63.2029 24.5405 63.2179 24.6082 63.2379 24.6953C63.1829 24.7001 63.1329 24.7146 63.0879 24.7146C61.9633 24.7146 60.8437 24.7292 59.7191 24.705C58.5695 24.6808 57.4399 24.5067 56.3653 24.0811C55.1257 23.5926 54.1311 22.8236 53.4413 21.7015C53.0014 20.9906 52.7365 20.2167 52.6466 19.3897C52.6066 19.0318 52.5866 18.6739 52.5866 18.3111C52.5866 12.2994 52.5866 6.28764 52.5866 0.275889V0.0485747C52.6666 0.0437382 52.7315 0.0340652 52.7965 0.0340652C54.5859 0.0340652 56.3703 0.0340652 58.1596 0.0340652C58.2446 0.0340652 58.3296 0.0340652 58.4146 0.0340652C58.4895 0.0340652 58.5295 0.0679206 58.5245 0.145304C58.5245 0.188833 58.5245 0.232361 58.5245 0.275889C58.5245 2.52485 58.5245 4.77382 58.5245 7.01795V7.2501C58.6045 7.2501 58.6695 7.25977 58.7344 7.25977C59.5841 7.25977 60.4388 7.25977 61.2885 7.25977C61.3535 7.25977 61.4185 7.25977 61.4785 7.25977C61.5135 7.40003 61.5285 12.7589 61.4935 12.9862C61.4335 12.9862 61.3685 13.0007 61.3035 13.0007C60.4538 13.0007 59.5991 13.0007 58.7494 13.0007C58.6845 13.0007 58.6195 13.0007 58.5245 13.0007Z"
        fill="#9D1C49"
      />
      <path
        d="M42.4052 24.705H36.6672C36.6672 24.705 36.6522 24.6905 36.6472 24.6856C36.6472 24.6856 36.6372 24.676 36.6372 24.6711C36.6372 24.6518 36.6272 24.6276 36.6272 24.6082C36.6272 16.4539 36.6272 8.30445 36.6272 0.150141C36.6272 0.130795 36.6272 0.106613 36.6322 0.0872666C36.6322 0.0775936 36.6422 0.0679206 36.6522 0.0485747H42.4002V24.705H42.4052Z"
        fill="#9D1C49"
      />
      <path
        d="M35.6825 16.4297C35.6175 16.4297 35.5676 16.4394 35.5176 16.4394C34.203 16.4394 32.8885 16.4394 31.5739 16.4346C31.364 16.4346 31.1591 16.4104 30.9492 16.3862C30.5793 16.3379 30.2794 16.1589 30.0595 15.8687C29.9345 15.6994 29.8196 15.5253 29.7196 15.3415C29.2048 14.3936 28.695 13.4456 28.1851 12.4977C27.9902 12.1349 27.7503 11.8109 27.4104 11.5594C26.7007 11.0371 25.846 11.0709 25.1812 11.6465C24.9113 11.8835 24.7114 12.1736 24.5414 12.4832C24.1665 13.1893 23.7867 13.9003 23.4068 14.6064C23.2719 14.8627 23.1369 15.1239 22.987 15.3754C22.882 15.5495 22.762 15.7188 22.6321 15.8736C22.3372 16.2169 21.9423 16.3911 21.4825 16.4249C21.3175 16.4394 21.1576 16.4394 20.9927 16.4394C19.7281 16.4394 18.4635 16.4394 17.204 16.4394C17.174 16.4394 17.149 16.4394 17.119 16.4394C16.9841 16.4394 16.9691 16.4298 16.9691 16.2992C16.9641 16.0138 16.9541 15.7333 16.9691 15.4479C17.169 11.7819 19.5482 8.64301 23.1019 7.37101C24.4065 6.90187 25.756 6.7326 27.1455 6.85351C29.5447 7.06631 31.569 8.03361 33.2134 9.73605C34.273 10.8339 34.9928 12.1156 35.3926 13.5665C35.6225 14.4081 35.7225 15.269 35.6975 16.1444C35.6975 16.2363 35.6825 16.3282 35.6725 16.4249L35.6825 16.4297Z"
        fill="#9D1C49"
      />
      <path
        d="M17.2889 18.1225C17.3589 18.1225 17.4239 18.1128 17.4889 18.1128C18.6635 18.1128 19.8331 18.1128 21.0076 18.1128C21.4475 18.1128 21.8873 18.0887 22.3172 17.9726C23.0869 17.7695 23.6967 17.3342 24.1615 16.7151C24.3115 16.5168 24.4464 16.3088 24.5664 16.0912C25.0312 15.2496 25.4811 14.3984 25.9409 13.5569C25.9659 13.5133 25.9859 13.4698 26.0159 13.4263C26.2058 13.1554 26.5907 13.1651 26.7556 13.4505C26.8956 13.6923 27.0205 13.9438 27.1555 14.1905C27.5254 14.8724 27.8952 15.5543 28.2701 16.2315C28.46 16.5797 28.695 16.8989 28.9799 17.1842C29.4647 17.6679 30.0645 17.9484 30.7492 18.0355C31.1091 18.0838 31.479 18.1032 31.8439 18.1032C32.9685 18.1128 34.0881 18.1032 35.2127 18.1032C35.2777 18.1032 35.3426 18.108 35.4126 18.1128C34.6279 21.5274 31.0991 25.0822 26.0659 24.9323C21.1276 24.7872 17.9737 21.1695 17.2989 18.108L17.2889 18.1225Z"
        fill="#9D1C49"
      />
      <path
        d="M50.5073 24.705H44.5894C44.5844 24.6566 44.5744 24.6179 44.5694 24.5744C44.5694 24.526 44.5694 24.4777 44.5694 24.4293C44.5694 18.7319 44.5694 13.0345 44.5694 7.34199C44.5694 7.07115 44.5644 7.09533 44.8293 7.09533C46.6437 7.09533 48.463 7.09533 50.2774 7.09533C50.4307 7.09533 50.5073 7.1711 50.5073 7.32265C50.5073 13.0539 50.5073 18.7803 50.5073 24.5115V24.7146V24.705Z"
        fill="#9D1C49"
      />
      <path
        d="M50.7472 3.11974C50.7472 4.82702 49.3027 6.21509 47.5284 6.21026C45.759 6.21026 44.3295 4.81735 44.3295 3.1004C44.3295 1.30122 45.8989 -0.0191361 47.5733 0.000209848C49.1678 0.0147193 50.7522 1.28188 50.7472 3.11974Z"
        fill="#9D1C49"
      />
      <path
        d="M99.995 4.71578C99.975 5.83301 99.3552 6.7858 98.3056 7.18239C96.5012 7.86433 94.8668 6.72292 94.5819 5.2478C94.282 3.70496 95.2667 2.61191 96.2813 2.25401C98.0057 1.63978 99.5751 2.6893 99.925 4.07253C99.98 4.2805 99.975 4.50298 100 4.72062L99.995 4.71578ZM99.5951 4.72546C99.6351 3.5115 98.5505 2.44747 97.266 2.44747C96.0014 2.44747 94.9118 3.48248 94.9218 4.72062C94.9318 5.99261 95.9464 6.99377 97.246 7.00828C98.5905 7.02279 99.6251 5.91523 99.5951 4.73029V4.72546Z"
        fill="#9D1C49"
      />
      <path
        d="M97.8857 4.80768C97.9457 4.83669 97.9657 4.8512 97.9907 4.86088C98.2556 4.97695 98.4006 5.18492 98.4405 5.45576C98.4705 5.68308 98.4655 5.91039 98.4755 6.16189C98.3356 6.16189 98.2006 6.16189 98.0707 6.16189C98.0007 6.16189 97.9807 6.10385 97.9757 6.04582C97.9757 5.94909 97.9757 5.84752 97.9707 5.75079C97.9607 5.62988 97.9457 5.50897 97.9107 5.39773C97.8508 5.20427 97.7108 5.08819 97.5009 5.05434C97.246 5.01081 96.9861 5.02048 96.7062 5.02532C96.7062 5.40256 96.7012 5.77014 96.6962 6.16189C96.5312 6.16189 96.3863 6.16189 96.2413 6.16189C96.1763 6.16189 96.1514 6.1232 96.1514 6.06516C96.1514 6.03614 96.1514 6.01196 96.1514 5.98294C96.1514 5.13172 96.1514 4.2805 96.1514 3.42928C96.1514 3.38091 96.1513 3.33255 96.1613 3.27935C96.1963 3.26967 96.2263 3.25033 96.2613 3.25033C96.7561 3.25516 97.256 3.21647 97.7458 3.28418C97.8258 3.29386 97.9007 3.30837 97.9757 3.33255C98.2756 3.43411 98.4555 3.63241 98.4855 3.94194C98.4955 4.03384 98.4955 4.12573 98.4855 4.21762C98.4605 4.45461 98.3256 4.61905 98.1057 4.72062C98.0457 4.7448 97.9807 4.76898 97.8857 4.80768ZM96.7112 4.64807C97.0061 4.64807 97.2859 4.67225 97.5609 4.60938C97.6158 4.59971 97.6708 4.58036 97.7208 4.55618C97.9257 4.44978 97.9857 4.27083 97.9657 4.06286C97.9507 3.85489 97.8507 3.73398 97.6458 3.6711C97.3459 3.58888 97.036 3.6179 96.7161 3.6179V4.64807H96.7112Z"
        fill="#9D1C49"
      />
    </svg>
  );
};

export default Logo;
