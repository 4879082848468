import React from 'react';
import {
  Arrow,
  SkeletonBlock,
  SkeletonChildren,
  SkeletonContainer,
  SkeletonItem,
  SkeletonParent,
  Title,
} from 'components/Tree/components/TreeSkeleton/styled';

const listItem = [1, 2];

const TreeSkeleton = () => {
  return (
    <SkeletonContainer>
      <SkeletonParent>
        <Arrow />
        <Title />
      </SkeletonParent>
      {listItem.map((item) => (
        <SkeletonChildren key={item}>
          <Arrow />
          <Title />
        </SkeletonChildren>
      ))}
    </SkeletonContainer>
  );
};

export default TreeSkeleton;
