import styled from 'styled-components';
import { ElementCenter } from 'style/styleComponent/styled';

export const FromWrapper = styled(ElementCenter)`
  width: 100%;
`;

export const GridForm = styled.div`
  padding-top: 5px;
  display: grid;
  grid-template-columns: 280px 280px;
  column-gap: 40px;
  //min-height: 320px;
`;
export const Grid1 = styled.div`
  grid-column-start: 1;
`;

export const Grid2 = styled.div`
  grid-column-end: 3;
`;
