import styled from 'styled-components';

export const Container = styled.div`
  width: 1140px;
  margin: 0 auto;
  height: 100dvh;
`;

export const ContentCenter = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background: white;
`;

export const MarginTop = styled.div`
  margin-top: 15px;
`;
