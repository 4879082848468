import React from 'react';
import { Outlet } from 'react-router-dom';
import MyPageOverview from 'page/Application/MyPage/MyPageOverview';
import MyPageEdit from 'page/Application/MyPage/MyPageEdit';

const MyPage = () => {
  return <Outlet />;
};

export default MyPage;

export { MyPageOverview, MyPageEdit };
