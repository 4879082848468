import React, { useEffect, useMemo } from 'react';
import { Spin, Tooltip } from 'antd';
import ButtonBreadcrumb from 'page/Application/ManageProductAndService/TopPageProduct/TopPage/component/ButtonBreadCrumb/index';
import { getTopPageProduct } from 'api/product/topPage.api';
import Breadcrumb from 'components/Layout/ApplicationLayout/Breadcrumb';
import useCustomQuery from 'hook/useQuery/useCustomQuery';
import TableNotifyTopPage from 'page/Application/ManageProductAndService/TopPageProductPartnerAdmin/TopPagePartnerAdmin/component/TableNotifyTopPage';
import RootMenuItem from 'page/Application/ManageProductAndService/TopPageProductPartnerAdmin/TopPageProductDetailPartnerAdmin/component/RootMenuItem';
import { useParams, useNavigate, Link } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import { userInformation } from 'recoil/atom/authentication';
import HeaderTopPage from '../TopPagePartnerAdmin/component/HeaderTopPage';
import LayoutTopPage from '../TopPagePartnerAdmin/component/LayoutTopPage';
import ProductContent from './component/ProductContent';
import {
  BreadCrumbDetail,
  TopPageProductMenuWrapper,
  TopPageProductNotifyWrapper,
} from 'page/Application/ManageProductAndService/TopPageProduct/TopPageProductDetail/styled';
import { ERROR_MESSAGES_RESPONSE } from 'constant/topPageConstants';

const TopPageProductDetailPartnerAdmin = () => {
  const { useQueryAPI } = useCustomQuery();
  const { productId } = useParams();
  const navigate = useNavigate();
  const { role } = useRecoilValue(userInformation);

  const {
    data: productDetail = [],
    isLoading,
    error,
  } = useQueryAPI({
    api: getTopPageProduct,
    key: 'productDetail',
    params: {
      productId: productId,
    },
    options: {
      cacheTime: 0,
    },
  });

  const listItemTopPageSA = useMemo(() => {
    return [
      {
        title: <Link to={'/manage-company-list'}>企業管理</Link>,
      },
      {
        title: <Link to={'/top-page'}>製品一覧</Link>,
      },
      {
        title: (
          <Tooltip
            placement={'topLeft'}
            title={productDetail?.data?.product?.productName}
          >
            <BreadCrumbDetail>
              {productDetail?.data?.product?.productName ?? ''}
            </BreadCrumbDetail>
          </Tooltip>
        ),
      },
    ];
  }, [productDetail]);

  const listItemTopPageSU = useMemo(() => {
    return [
      {
        title: <Link to={'/my-page'}>マイページ</Link>,
      },
      {
        title: <Link to={'/top-page'}>製品一覧</Link>,
      },
      {
        title: (
          <Tooltip
            placement={'topLeft'}
            title={productDetail?.data?.product?.productName}
          >
            <BreadCrumbDetail>
              {productDetail?.data?.product?.productName ?? ''}
            </BreadCrumbDetail>
          </Tooltip>
        ),
      },
    ];
  }, [productDetail?.data?.product?.productName]);

  const listItemTopPagePartner = useMemo(() => {
    return [
      {
        title: <Link to={'/'}>パートナー専用ページ</Link>,
      },
      {
        title: <Link to={'/top-page'}>製品一覧</Link>,
      },
      {
        title: (
          <Tooltip
            placement={'topLeft'}
            title={productDetail?.data?.product?.productName}
          >
            <BreadCrumbDetail>
              {productDetail?.data?.product?.productName ?? ''}
            </BreadCrumbDetail>
          </Tooltip>
        ),
      },
    ];
  }, [productDetail?.data?.product?.productName]);

  const listItemByRole = useMemo(() => {
    return {
      ROLE_SOLITON_ADMIN: listItemTopPageSA,
      ROLE_SOLITON_USER: listItemTopPageSU,
      ROLE_PARTNER_ADMIN: listItemTopPagePartner,
      ROLE_PARTNER_USER: listItemTopPagePartner,
    };
  }, [listItemTopPagePartner, listItemTopPageSA, listItemTopPageSU]);

  const handleNavigate = () => {
    navigate('edit/');
  };

  useEffect(() => {
    if (ERROR_MESSAGES_RESPONSE[error?.response?.data?.message]) {
      navigate('/top-page-not-found');
    }
  }, [error?.response?.data?.message, navigate]);

  return (
    <Spin spinning={isLoading}>
      <Breadcrumb
        listItem={listItemByRole[role]}
        titlePage={productDetail?.data?.product?.productName}
      >
        {role === 'ROLE_SOLITON_ADMIN' && (
          <ButtonBreadcrumb text={'編集'} onClick={handleNavigate} />
        )}
      </Breadcrumb>
      {(productDetail?.data?.product?.productContent ||
        productDetail?.data?.product?.image) && (
        <LayoutTopPage>
          <ProductContent
            isLoading={isLoading}
            dataNotify={productDetail?.data}
          />
        </LayoutTopPage>
      )}
      <LayoutTopPage>
        <TopPageProductNotifyWrapper>
          <HeaderTopPage
            titleScreen={'お知らせ'}
            loading={isLoading}
            isDisplayNotifyList={true}
            productId={productId}
            isNotifyDetail={false}
          />

          <TableNotifyTopPage
            isLoading={isLoading}
            isTopPage={true}
            dataNotify={productDetail?.data?.listNotify}
            productId={productId}
          />
        </TopPageProductNotifyWrapper>
      </LayoutTopPage>
      <LayoutTopPage>
        <TopPageProductMenuWrapper>
          <HeaderTopPage
            titleScreen={'メニュー'}
            loading={isLoading}
            isDisplayNotifyList={false}
            productId={productId}
            productName={productDetail?.data?.product?.productName}
          />
          <RootMenuItem
            productDetail={productDetail}
            listProductRootMenu={productDetail?.data?.product?.productMenus}
          />
        </TopPageProductMenuWrapper>
      </LayoutTopPage>
    </Spin>
  );
};

export default TopPageProductDetailPartnerAdmin;
