import styled from 'styled-components';

export const ProductItem = styled.div`
  font-family: 'inter-regular', sans-serif;
  font-weight: 400;
  font-size: 14px;
  color: #9d1c49;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  padding: 8px 0 0px 0;
  min-height: 30px;
`;

export const ProductItemWrapper = styled.div`
  padding-bottom: 16px;
`;
