import React, { Suspense, useEffect, useMemo, useState } from 'react';
import Breadcrumb from 'components/Layout/ApplicationLayout/Breadcrumb';
import LayoutDefault from 'components/Layout/LayoutDefault';
import useCustomQuery from 'hook/useQuery/useCustomQuery';
import TableListFile from 'page/Application/ProjectRegistration/ProjectRegistrationDetail/component/TableListFile';
import ErrorImage from 'assets/images/errorImage.jpg';
import { downloadContentFile } from 'api/storage/storage.api';
import { getDetailPublicProject } from 'api/project/partnerAdmin.api';
import { useRecoilValue } from 'recoil';
import { userInformation } from 'recoil/atom/authentication';
import { Button, Col, Empty, Image, Row, Spin, Tooltip } from 'antd';
import { Link, useNavigate, useParams } from 'react-router-dom';
import {
  ButtonPrimaryOutline,
  FirstSubTitle,
  HtmlRaw,
  ProjectDetailTitle,
  SubTitle,
  TextButton,
} from 'page/Application/ProjectRegistration/ProjectRegistrationDetail/styled';

const titlePage = {
  ROLE_SOLITON_ADMIN: '案件登録お申込み詳細',
  ROLE_SOLITON_USER: '案件登録お申込み詳細',
  ROLE_PARTNER_ADMIN: '案件登録お申込み詳細',
  ROLE_PARTNER_USER: '案件登録お申込み詳細',
};

const ProjectRegistrationDetail = () => {
  // hook
  const navigate = useNavigate();
  const { projectId } = useParams();
  const { useQueryAPI } = useCustomQuery();

  // recoil
  const { role } = useRecoilValue(userInformation);

  // state
  const [detailId, setDetailId] = useState(null);

  // call API
  const {
    data: projectDetailData = {},
    isLoading,
    error,
  } = useQueryAPI({
    api: getDetailPublicProject,
    params: {
      projectId: projectId,
    },
    key: 'projectDetail',
  });

  // call API
  const { refetch } = useQueryAPI({
    api: downloadContentFile,
    params: {
      detailId: detailId,
    },
    options: {
      refetchOnWindowFocus: false,
    },
    enabled: !!detailId,
  });

  // data breadcrumb
  const listItemSoliton = useMemo(() => {
    return [
      {
        title: <Link to={'/'}>パートナー専用ページ</Link>,
      },
      {
        title: <Link to={'/project-registration'}>案件登録お申し込み</Link>,
      },
      {
        title: '案件登録お申込み詳細',
      },
    ];
  }, []);

  // data breadcrumb
  const listItemPartner = useMemo(() => {
    return [
      {
        title: <Link to={'/'}>パートナー専用ページ</Link>,
      },
      {
        title: <Link to={'/project-registration'}>案件登録お申し込み</Link>,
      },
      {
        title: '案件登録お申込み詳細',
      },
    ];
  }, []);

  // data breadcrumb
  const listItem = {
    ROLE_SOLITON_ADMIN: listItemSoliton,
    ROLE_SOLITON_USER: listItemSoliton,
    ROLE_PARTNER_ADMIN: listItemPartner,
    ROLE_PARTNER_USER: listItemPartner,
  };

  // move back
  const handleMoveBack = () => {
    navigate(-1);
  };

  // open new tab
  const handleMoveToUrl = () => {
    const company = projectDetailData?.data?.urlCompany;
    const familyName = projectDetailData?.data?.urlFamilyName;
    const name = projectDetailData?.data?.urlName;
    const mail = projectDetailData?.data?.urlMail;

    const url = new URL(projectDetailData?.data?.projectUrl);
    url.searchParams.append('familyName', familyName);
    url.searchParams.append('company', company);
    url.searchParams.append('name', name);
    url.searchParams.append('mail', mail);
    window.open(url, '_blank');
  };

  // re-format data from API
  const checkData = useMemo(() => {
    if (projectDetailData && projectDetailData.ec === false) {
      return projectDetailData.data;
    } else return {};
  }, [projectDetailData]);

  // download file
  const handleDownloadFile = async (detailId) => {
    await setDetailId(detailId);
    refetch().then((value) => {
      window.open(value?.data, '_self');
    });
  };

  useEffect(() => {
    if (error?.response?.data?.message === '400_CAN_NOT_ACCESS_DATA') {
      navigate('/project-not-found');
    }
  }, [error?.response?.data?.message, navigate]);

  return (
    <>
      <Breadcrumb listItem={listItem[role]} titlePage={titlePage[role]} />
      <LayoutDefault>
        <Suspense fallback={isLoading}>
          {/* Start remove max height spin to set spin center vertical layout */}
          <Spin spinning={isLoading} style={{ maxHeight: 'unset' }}>
            {/* Start render empty element with height = 670px for space layout */}
            {isLoading === true && <div style={{ height: 670 }} />}
            {/* End render empty element with height = 670px for space layout */}

            {/* Start render title project with tooltip */}
            <Tooltip title={checkData.projectName} placement={'topLeft'}>
              <ProjectDetailTitle>{checkData.projectName}</ProjectDetailTitle>
            </Tooltip>
            {/* End render title project with tooltip */}

            {/* Start render content project */}
            {checkData.detailResponse !== [] && isLoading === false && (
              <Row gutter={16}>
                {/* Start render image */}
                <Col xs={24} sm={24} md={10} lg={8} xl={8}>
                  <div style={{ marginBottom: 12 }}>
                    <Image
                      src={checkData.image}
                      fallback={ErrorImage}
                      height={297}
                      width={'100%'}
                      preview={false}
                      style={{
                        objectPosition: 'center',
                        objectFit: 'cover',
                      }}
                    />
                  </div>
                </Col>

                <Col xs={24} sm={24} md={14} lg={16} xl={16}>
                  {/* Start render project content */}
                  {checkData.projectContent && (
                    <HtmlRaw
                      dangerouslySetInnerHTML={{
                        __html: checkData.projectContent,
                      }}
                      style={{
                        marginBottom: checkData.projectContent ? 20 : 0,
                      }}
                    />
                  )}
                  {/* End render project content */}

                  {/* Start render project url */}
                  {checkData?.projectUrl && (
                    <>
                      <FirstSubTitle>案件登録お申し込み</FirstSubTitle>
                      <ButtonPrimaryOutline onClick={handleMoveToUrl}>
                        <Tooltip
                          title={projectDetailData?.data?.projectUrl}
                          placement={'topLeft'}
                        >
                          <TextButton>
                            {projectDetailData?.data?.projectUrlTitle ===
                              undefined ||
                            projectDetailData?.data?.projectUrlTitle === ''
                              ? '案件登録お申し込みはこちら'
                              : projectDetailData?.data?.projectUrlTitle}
                          </TextButton>
                        </Tooltip>
                      </ButtonPrimaryOutline>
                    </>
                  )}
                  {/* End render project url */}

                  {/* Start render detail url */}
                  {checkData.detailResponses?.length > 0 && (
                    <div style={{ paddingBottom: 20 }}>
                      <SubTitle>関連情報</SubTitle>
                      <TableListFile
                        dataRaw={checkData.detailResponses}
                        handleDownloadFile={handleDownloadFile}
                      />
                    </div>
                  )}
                  {/* End render detail url */}

                  {/* Start button move back */}
                  <div style={{ marginBottom: 30 }}>
                    <Button
                      type="primary"
                      onClick={handleMoveBack}
                      style={{
                        width: 80,
                        height: 40,
                        boxShadow: 'none',
                      }}
                    >
                      <span> 戻る</span>
                    </Button>
                  </div>
                  {/* End button move back */}
                </Col>
              </Row>
            )}
            {/* End render content project */}

            {/* Start if content project is empty render empty component */}
            {checkData.detailResponse === [] && isLoading === false && (
              <Empty />
            )}
            {/* End if content project is empty render empty component */}
          </Spin>
          {/* End remove max height spin to set spin center vertical layout */}
        </Suspense>
      </LayoutDefault>
    </>
  );
};

export default ProjectRegistrationDetail;
