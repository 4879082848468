import React, { useEffect, useState } from 'react';
import { registerNotification } from 'api/notification/notification.api';
import { MANAGE_NOTIFICATION_PATH } from 'constant/appPath';
import {
  DATE_FORMAT_LIST,
  NOTIFICATION_DESTINATION_TYPE_DATA,
  NOTIFICATION_STATUS_DATA,
  TIME_ZONE,
} from 'constant/notificationConstants';
import { FormNotification } from 'page/Application/ManageNotificationList/component/Form';
import { useNotification } from 'page/Application/ManageNotificationList/component/NotifcationContext';
import { useRecoilState, useRecoilValue } from 'recoil';
import { listProduct } from 'recoil/atom/selectProduct';
import { network } from 'recoil/atom/network';
import { message } from 'antd';
import dayjs from 'dayjs';
import useCustomMutateRenderMessage from 'hook/useQuery/useCustomMutateRenderMessage';
import useErrorCodeManageNotification
  from 'page/Application/ManageNotificationList/hook/useErrorCodeManageNotification';
import useMultiLanguageRegisterCompany from './hook/useMultiLanguageRegisterNotification';

const RegisterNotification = () => {
  const [messageApi, contextHolder] = message.useMessage();

  const { REGISTER_NOTIFICATION_TITLE, REGISTER_NOTIFICATION_MESSAGE_SUCCESS } =
    useMultiLanguageRegisterCompany();
  const { currentNotification, setCurrentNotification } = useNotification();
  const { ERROR_CODE_MANAGE_NOTIFICATION } = useErrorCodeManageNotification();
  const { errorMessage, useMutationAPIHandleErrorCode } =
    useCustomMutateRenderMessage({
      errorCode: ERROR_CODE_MANAGE_NOTIFICATION,
    });

  const [listSelected] = useRecoilState(listProduct);
  const isOnline = useRecoilValue(network);

  const [content, setContent] = useState(null);
  const [fileObject, setFileObject] = useState(null);
  const [plainText, setPlainText] = useState(null);
  const [status, setStatus] = useState(NOTIFICATION_STATUS_DATA[0].value);
  const [destinationType, setDestinationType] = useState(
    NOTIFICATION_DESTINATION_TYPE_DATA[0].value,
  );

  const {
    mutate: registerNotificationMutate,
    isError,
    isLoading,
    isSuccess,
  } = useMutationAPIHandleErrorCode({
    api: registerNotification,
    refetchKey: 'listNotification',
    messageSuccess: REGISTER_NOTIFICATION_MESSAGE_SUCCESS,
    path: `/${MANAGE_NOTIFICATION_PATH}`,
  });

  const [isDisable, setIsDisable] = useState(false);
  const [, setIdFile] = useState(null);

  useEffect(() => {
    setContent(currentNotification?.content);
    setPlainText(currentNotification?.plainText);
  }, [currentNotification]);

  const onFinish = (value) => {
    if (isOnline) {
      const dateFrom =
        value?.publishedFromToDate && value?.publishedFromToDate[0]
          ? new Date(value?.publishedFromToDate[0]).setSeconds(0)
          : null;
      const dateTo =
        value?.publishedFromToDate && value?.publishedFromToDate[1]
          ? new Date(value?.publishedFromToDate[1]).setSeconds(0)
          : null;
      const payload = {
        ...value,
        title: value.title.trim(),
        content: content === '<p>&nbsp;</p>' ? '' : content,
        notifySearchContent: plainText,
        displayedDate: dayjs(value.displayedDate).format(DATE_FORMAT_LIST[3]),
        publishedFrom:
          value.status === 'HIDE'
            ? null
            : dayjs.tz(
              dateTo && dateFrom > dateTo ? dateTo : dateFrom,
              TIME_ZONE,
            ),
        publishedTo:
          value.status === 'HIDE'
            ? null
            : dateTo &&
            dayjs.tz(dateFrom > dateTo ? dateFrom : dateTo, TIME_ZONE),
        lstProductId: listSelected.listIdProduct,
        fileStorageRequest: fileObject && {
          fileName: fileObject?.fileName,
          keyFileName: fileObject?.keyFileName,
          contentType: fileObject?.contentType,
          size: fileObject?.size,
        },
      };

      // call API register notification
      registerNotificationMutate({
        payload: payload,
      });
    } else {
      messageApi.open({
        type: 'error',
        content: '問題が発生しました。インターネット接続を確認して、もう一度やり直してください。',
      }).then();
    }
  };

  return (
    <>
      {contextHolder}
      <FormNotification
        onFinish={onFinish}
        isEdit={false}
        isLoading={isLoading}
        titleForm={REGISTER_NOTIFICATION_TITLE}
        isDisable={isDisable}
        setIsDisable={setIsDisable}
        setIdFile={setIdFile}
        isError={isError}
        errorMessage={errorMessage}
        isSuccess={isSuccess}
        content={content}
        setContent={setContent}
        currentNotification={currentNotification}
        setCurrentNotification={setCurrentNotification}
        setDestinationType={setDestinationType}
        setStatus={setStatus}
        status={status}
        destinationType={destinationType}
        fileObject={fileObject}
        setFileObject={setFileObject}
        setPlainText={setPlainText}
        plainText={plainText}
        idFile={null}
      />
    </>
  );
};

export default RegisterNotification;
