import styled from 'styled-components';
import { Select, Button } from 'antd';

export const HeaderTable = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 8px 0 16px 0;

  .ant-btn {
    height: 36px;
  }

  .ant-input {
    height: 36px;
    font-size: 14px;
    &::placeholder {
      font-size: 14px;
    }
  }
`;

export const TitleScreen = styled.h2`
  font-family: 'inter-semibold', sans-serif;
  font-size: 16px;
  font-weight: 600;
  color: var(--FORM-LABEL-default);
`;

export const TooltipHide = styled.div`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const SelectStyled = styled(Select)`
  width: 120px;
  height: 36px;
  span {
    font-family: 'inter-regular', sans-serif;
    color: var(--FORM-LABEL-default);
    font-weight: 500;
    max-width: 96px;
  }
`;
export const ButtonStyled = styled(Button)`
  font-family: 'inter-regular', sans-serif;
  font-size: 14px;
  font-weight: 400;
  color: var(--FORM-LABEL-default);
`;
