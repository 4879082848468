import styled from 'styled-components';

export const ProjectItem = styled.div`
  margin-bottom: 20px;

  &:hover {
    cursor: pointer;
    transform: scale(1.03);
    transition: 0.25s;
  }
`;

export const TitleProject = styled.p`
  &:hover {
    color: #656464;
  }
  font-family: 'inter-semibold', sans-serif;
  font-size: 14px;
  font-weight: 600;
  line-height: 1.5;
  color: #9d1c49;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

export const DescProject = styled.div`
  height: 46px;
  padding: 12px;
  background: #f1f1f1;
  border-radius: 0 0 6px 6px;
`;
