import React from 'react';
import Header from 'components/Layout/ApplicationLayout/Header';
import Footer from 'components/Layout/ApplicationLayout/Footer';
import { LayoutWrapper } from 'components/Layout/ApplicationLayout/styled';
import {
  SidebarMobile,
  SidebarDesktop,
} from 'components/Layout/ApplicationLayout/SidebarMenu';

const ApplicationLayout = ({ children }) => {
  return (
    <LayoutWrapper>
      <SidebarDesktop />
      <SidebarMobile />
      {/*<div className="placeholder" />*/}
      <div className="application-container">
        <Header />
        <div className="content">{children}</div>
        <Footer />
      </div>
    </LayoutWrapper>
  );
};

export default ApplicationLayout;
