import { useRecoilState } from 'recoil';
import { messageFeedback } from 'recoil/atom/authentication';
import { useMutation } from 'react-query';
import { queryClient } from 'api/config/queryClient';
import { useNavigate } from 'react-router-dom';
import useCheckErrorCode from 'hook/useCheckErrorCode';
import useMultiLanguage from 'hook/useMultiLanguage';

const useCustomMutateRenderMessageWithSate = ({ errorCode }) => {
  const navigate = useNavigate();
  const [, setShowMessageFeedback] = useRecoilState(messageFeedback);
  const { APPLICATION_CANCEL_TEXT } = useMultiLanguage();
  const { handleClearErrorMessage, handleRenderMessage, errorMessage } =
    useCheckErrorCode({
      errorCode: errorCode,
    });

  /**
   * api: from axios define in folder api.
   * refetchKey: is queryKey you define for refetch api.
   * messageSuccess: content for message component, trigger when fetch api SUCCESS.
   * errorCode: content for alert component, trigger when fetch api ERROR, render message.
   * useMutate: return mutate, isLoading, isError... read doc.
   */

  const useMutationAPIHandleErrorCode = ({
    api,
    refetchKey,
    messageSuccess,
    path,
    state,
  }) => {
    return useMutation({
      mutationFn: api,
      onSuccess: () => {
        handleClearErrorMessage();
        queryClient.refetchQueries((refetchKey ??= '')).then(() => {
          setShowMessageFeedback({
            display: true,
            type: 'success',
            content: messageSuccess ?? APPLICATION_CANCEL_TEXT,
          });
          navigate(path, state);
        });
      },
      onError: (response) => {
        handleRenderMessage(response);
      },
    });
  };
  return { useMutationAPIHandleErrorCode, errorMessage };
};

export default useCustomMutateRenderMessageWithSate;
