import React from 'react';
import RowButton from 'components/Layout/RowButton';
import { ButtonPrimary } from 'components/Button';
import { Grid2to3, GridForm } from 'page/Application/MyPagePartnerAdmin/styled';

const ButtonSwitchMode = ({ onClick, text }) => {
  return (
    <GridForm>
      <Grid2to3>
        <RowButton>
          <ButtonPrimary text={text} width={120} onClick={onClick} />
        </RowButton>
      </Grid2to3>
    </GridForm>
  );
};

export default ButtonSwitchMode;
