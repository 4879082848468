/*
 * id range from 0 - 100
 * */
import {
  AuditOutlined,
  LayoutOutlined,
  // NotificationOutlined,
  ProjectOutlined,
} from '@ant-design/icons';
import React from 'react';
import {
  // MANAGE_NOTIFICATION_PATH,
  PROJECT_REGISTRATION_PATH,
} from 'constant/appPath';

export const partnerUserMenu = [
  {
    id: 1,
    label: 'パートナー専用ページ',
    labelMobile: 'パートナー専用ページ',
    path: '/partner-page',
    icon: '',
    isParent: true,
    isTopPage: true,
    parentId: 0,
    children: [],
  },
  {
    id: 3,
    label: 'マイページ', // my page
    path: '/my-page',
    icon: <LayoutOutlined />,
    isParent: true,
    isTopPage: false,
    parentId: 0,
    children: [],
  },
  {
    id: 4,
    label: '製品一覧', // manage product & service
    path: '/top-page',
    icon: <AuditOutlined />,
    isParent: true,
    isTopPage: false,
    parentId: 0,
    children: [],
  },
  {
    id: 5,
    label: '案件登録お申し込み', // project registration
    path: `/${PROJECT_REGISTRATION_PATH}`,
    icon: <ProjectOutlined />,
    isParent: true,
    isTopPage: false,
    parentId: 0,
    children: [],
  },
];
