import React from 'react';
import LabelFormMyPage from 'page/Application/MyPage/component/FormMyPage/LabelFormMyPage';
import { FormItem } from 'components/Form2';
import { InputStyled } from 'components/Form2/styled';
import {
  GridFormAccount,
  GridFormAccount1to2,
  GridFormAccount2to3,
} from 'page/Application/MyPage/component/FormMyPage/styled';

const InformationCompany = ({ disabled, editMode }) => {
  return (
    <GridFormAccount>
      <GridFormAccount1to2>
        <LabelFormMyPage labelText={'会社名'} />
      </GridFormAccount1to2>
      <GridFormAccount2to3>
        <FormItem name="partnerName">
          <InputStyled
            maxLength={256}
            placeholder={editMode ? '株式会社Notilosシステムズ' : ''}
            disabled={disabled}
          />
        </FormItem>
      </GridFormAccount2to3>
    </GridFormAccount>
  );
};

export default InformationCompany;
