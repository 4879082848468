import styled from 'styled-components';

export const FormTitleWrapper = styled.div`
  padding: 20px 0 24px 0;
  font-weight: 600;
  font-size: 16px;
  color: #656464;
  border-bottom: 1px solid #d2d3d4;
`;

export const FormTextWrapper = styled.div`
  padding: 20px 0 24px 0;
  font-weight: 400;
  font-size: 14px;
  color: var(--FORM-LABEL-default);
  border-bottom: 1px solid #d2d3d4;
  font-family: 'inter-regular', sans-serif;
`;
