import React from 'react';
import {
  FormLabelGrid,
  FormListDomain,
  FormAddProduct,
  FormFooterSubmit,
} from 'page/Application/ManageCompanyList/component/Form';
import {
  FormValidateSaleForceId,
  FormValidateEmpty,
  FormValidateEmail,
} from 'components/Form2';
import useMultiLanguageEditCompany from 'page/Application/ManageCompanyList/EditCompany/hook/useMultiLanguageEditCompany';
import useMultiLanguageRegisterCompany from 'page/Application/ManageCompanyList/RegisterCompany/hook/useMultiLanguageRegisterCompany';
import { useRecoilState } from 'recoil';
import { Alert } from 'antd';
import { domainNameExistedState } from 'recoil/atom/domainNameExistedAtom';
import {
  Grid1,
  Grid2,
  GridForm,
} from 'page/Application/ManageCompanyList/component/Form/styled';

const FormPartner = ({
  onCancel,
  onFinish,
  children,
  formPartnerName,
  onDelete,
  isDelete,
  isError,
  errorMessage,
  isDisable,
  setIsDisable,
  form,
  setIsDomainAvailable,
  isDomainAvailable,
}) => {
  const {
    REGISTER_COMPANY_NAME,
    REGISTER_COMPANY_PARTNER_DOMAIN,
    REGISTER_COMPANY_MANAGER_EMAIL,
    REGISTER_COMPANY_SUBMIT,
    REGISTER_COMPANY_CANCEL,
    REGISTER_COMPANY_MESSAGE_ERROR_MISSING_ENTER_COMPANY_NAME,
    REGISTER_COMPANY_MESSAGE_ERROR_ENTER_PARTNER_OR_MANAGER_EMAIL,
    REGISTER_COMPANY_COMPANY_NAME_PLACEHOLDER,
    REGISTER_COMPANY_SOLITON_CONTACT_PLACEHOLDER,
  } = useMultiLanguageRegisterCompany();

  const { DETAIL_COMPANY_SALES_FORCE_ID_EXISTED } =
    useMultiLanguageEditCompany();

  // render domain name existed when call API Error
  const [domainNameExisted] = useRecoilState(domainNameExistedState);

  return (
    // start grid form
    <GridForm>
      {/* Start error message */}
      <Grid2>
        {(isError || errorMessage) && (
          <Alert
            message={
              errorMessage === DETAIL_COMPANY_SALES_FORCE_ID_EXISTED
                ? errorMessage.replace('ID', form.getFieldValue('saleForceId'))
                : domainNameExisted
                  ? `${domainNameExisted}` + errorMessage
                  : '' + errorMessage
            }
            type="error"
            style={{ marginBottom: '24px' }}
          />
        )}
      </Grid2>
      {/* End error message */}

      {/* Start company name */}
      <Grid1>
        <FormLabelGrid labelText={REGISTER_COMPANY_NAME} required={true} />
      </Grid1>

      <Grid2>
        <FormValidateEmpty
          formName={formPartnerName}
          required={true}
          message={REGISTER_COMPANY_MESSAGE_ERROR_MISSING_ENTER_COMPANY_NAME}
          placeholder={REGISTER_COMPANY_COMPANY_NAME_PLACEHOLDER}
        />
      </Grid2>
      {/* End company name */}

      {/* Start sale force id */}
      <Grid1>
        <FormLabelGrid labelText={'Salesforce ID'} required={false} />
      </Grid1>

      <Grid2>
        <FormValidateSaleForceId formName="saleForceId" />
      </Grid2>
      {/* End sale force id */}

      {/* Start list domain */}
      <Grid1>
        <FormLabelGrid
          labelText={REGISTER_COMPANY_PARTNER_DOMAIN}
          required={false}
        />
      </Grid1>

      <FormListDomain
        form={form}
        setIsDomainAvailable={setIsDomainAvailable}
        isDomainAvailable={isDomainAvailable}
      />
      {/* End list domain */}

      {/* Start list email */}
      {/*<Grid1>*/}
      {/*  <FormLabelGrid*/}
      {/*    labelText={REGISTER_COMPANY_PARTNER_EMAIL}*/}
      {/*    required={false}*/}
      {/*  />*/}
      {/*</Grid1>*/}

      {/*<FormListEmail*/}
      {/*  form={form}*/}
      {/*  setIsAvailable={setIsAvailable}*/}
      {/*  isAvailable={isAvailable}*/}
      {/*/>*/}
      {/* End list email */}

      {/* Start button and modal add product */}
      <FormAddProduct />
      {/* End button and modal add product */}

      {/* Start other form, check it on parent */}
      {children}
      {/* End other form */}

      {/* Start soliton email */}
      <Grid1>
        <FormLabelGrid
          labelText={REGISTER_COMPANY_MANAGER_EMAIL}
          required={false}
        />
      </Grid1>

      <Grid2>
        <FormValidateEmail
          name={'solitonEmail'}
          textValidate={
            REGISTER_COMPANY_MESSAGE_ERROR_ENTER_PARTNER_OR_MANAGER_EMAIL
          }
          placeholder={REGISTER_COMPANY_SOLITON_CONTACT_PLACEHOLDER}
        />
      </Grid2>
      {/* End soliton email */}

      {/* Start button submit, cancel, delete */}
      <Grid2>
        <FormFooterSubmit
          textOk={REGISTER_COMPANY_SUBMIT}
          textCancel={REGISTER_COMPANY_CANCEL}
          onCancel={onCancel}
          onFinish={onFinish}
          onDelete={onDelete}
          isDelete={isDelete}
          isDisable={isDisable}
          setIsDisable={setIsDisable}
        />
      </Grid2>
      {/* End button submit, cancel, delete */}
    </GridForm>
    // End grid form
  );
};

export default FormPartner;
