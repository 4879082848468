import React from 'react';
import isPropValid from '@emotion/is-prop-valid';
import { StyleSheetManager } from 'styled-components';
import { ButtonOutlineStyled } from 'components/Button/ButtonOutline/styled';

// This implements the default behavior from styled-components v5
const shouldForwardProp = (propName, target) => {
  if (typeof target === 'string') {
    // For HTML elements, forward the prop if it is a valid HTML attribute
    return isPropValid(propName);
  }
  // For other elements, forward all props
  return true;
};

const ButtonOutline = ({ text, onClick, width, isDisabled }) => {
  return (
    <StyleSheetManager shouldForwardProp={shouldForwardProp}>
      <ButtonOutlineStyled
        disabled={isDisabled}
        size="large"
        onClick={onClick}
        width={width}
      >
        {''}
        {text}
      </ButtonOutlineStyled>
    </StyleSheetManager>
  );
};

export default ButtonOutline;
