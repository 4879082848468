import React, { useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useRecoilState } from 'recoil';
import { deletePartner, getListPartner } from 'api/partner/partner.api';
import { ButtonBreadcrumb } from 'components/Button';
import Breadcrumb from 'components/Layout/ApplicationLayout/Breadcrumb';
import HeaderListDataLayout from 'components/Layout/HeaderListDataLayout';
import LayoutDefault from 'components/Layout/LayoutDefault';
import Pagination from 'components/Pagination';
import useCustomMutate from 'hook/useQuery/useCustomMutate';
import useCustomQuery from 'hook/useQuery/useCustomQuery';
import useSearchKeyword from 'hook/useSearchKeyword';
import TableData from 'page/Application/ManageCompanyList/CompanyList/component/TableData';
import useMultiLanguageCompanyList from 'page/Application/ManageCompanyList/CompanyList/hook/useMultiLanguageCompanyList';
import { PlusOutlined } from '@ant-design/icons';
import {
  defaultPageSizeOptions,
  defaultSelectPageSizeOption,
} from 'constant/Pagination/pageSizeOption';
import { SORT_DIRECTION } from 'constant/sortConstants';
import { idProduct, listProduct, product } from 'recoil/atom/selectProduct';
import useErrorNetworkMessage from 'hook/useErrorNetworkMessage';

const CompanyList = () => {
  const {
    COMPANY_LIST_TITLE,
    ACCOUNT_LIST_DELETE_SUCCESS,
    COMPANY_LIST_PLACEHOLDER_SEARCH,
  } = useMultiLanguageCompanyList();
  const [itemDelete, setItemDelete] = useState('');
  const [sortedInfo, setSortedInfo] = useState({});
  const [page, setPage] = useState(10);
  const [current, setCurrent] = useState(1);
  const [searchValue, setSearchValue] = useState('');
  const [, setListSelectedProduct] = useRecoilState(listProduct);
  const [, setProductSelected] = useRecoilState(product);
  const [, setIdProductSelected] = useRecoilState(idProduct);

  const navigate = useNavigate();
  const { useQueryAPI } = useCustomQuery();
  const { useMutationAPI } = useCustomMutate();
  const { searchKeyword, onSearch, emptyText } = useSearchKeyword();
  const { isOnline, errorNetWork, contextErrorNetWork } =
    useErrorNetworkMessage();

  const listItem = useMemo(() => {
    return [
      {
        title: '企業管理',
      },
    ];
  }, []);

  const { data: companyList = [], isLoading } = useQueryAPI({
    api: getListPartner,
    key: 'companyList',
    params: {
      pageNumber: current,
      pageSize: page,
      keyword: encodeURIComponent(searchKeyword),
      displayedDateFrom: '',
      displayedDateTo: '',
      category: '',
      status: '',
      sortField: sortedInfo?.sortField ?? '',
      sortDirection: sortedInfo?.sortDirection ?? '',
    },
  });

  const { mutate: deleteSinglePartner } = useMutationAPI({
    api: deletePartner,
    refetchKey: 'companyList',
    messageSuccess: `${itemDelete} ${ACCOUNT_LIST_DELETE_SUCCESS}`,
  });

  const handlePagingChange = (pageNumber, pageSize) => {
    if (isOnline) {
      setCurrent(pageNumber);
      setPage(pageSize);
    } else {
      errorNetWork();
    }
  };

  const handleChangePageSize = (value) => {
    if (isOnline) {
      setPage(value);
      setCurrent(1);
    } else {
      errorNetWork();
    }
  };

  const handleChange = (pagination, filters, sorter) => {
    if (isOnline) {
      setSortedInfo({
        sortField: sorter.order && sorter.field,
        sortDirection: sorter.order && SORT_DIRECTION[sorter.order],
        order: sorter.order,
        columnKey: sorter.columnKey,
      });
    } else {
      errorNetWork();
    }
  };

  const handleDeleteCompany = (id) => {
    if (isOnline) {
      deleteSinglePartner(id);
    } else {
      errorNetWork();
    }
  };

  const handleNavigate = () => {
    if (isOnline) {
      navigate('/manage-company-list/register');
      setProductSelected([])
      setIdProductSelected([])
        } else {
      errorNetWork();
    }
  };

  useEffect(() => {
    if (searchKeyword) {
      setCurrent(1);
    }
    setListSelectedProduct({});
  }, [searchKeyword, setListSelectedProduct]);

  return (
    <>
      {contextErrorNetWork}
      <Breadcrumb listItem={listItem} titlePage="企業管理">
        <ButtonBreadcrumb
          text={'新規登録'}
          icon={<PlusOutlined />}
          onClick={handleNavigate}
        />
      </Breadcrumb>
      <LayoutDefault>
        <HeaderListDataLayout
          onSearch={onSearch}
          loading={isLoading}
          value={page}
          handleChangePageSize={handleChangePageSize}
          pageSizeOptions={defaultSelectPageSizeOption}
          searchValue={searchValue}
          setSearchValue={setSearchValue}
          titleScreen={COMPANY_LIST_TITLE}
          searchPlaceholder={COMPANY_LIST_PLACEHOLDER_SEARCH}
        />
        <TableData
          dataRaw={companyList.data}
          isLoading={isLoading}
          handleDeleteCompany={handleDeleteCompany}
          setItemDelete={setItemDelete}
          emptyText={emptyText}
          onChange={handleChange}
          sortedInfo={sortedInfo}
        />

        <Pagination
          currentPage={current}
          handlePagingChange={handlePagingChange}
          total={companyList.data?.total}
          pageSize={page}
          pageSizeOptions={defaultPageSizeOptions}
          handleChangePageSize={handleChangePageSize}
        />
      </LayoutDefault>
    </>
  );
};
export default CompanyList;
