import LogoViewer from '../LogoViewer';
import { Text, TextWrapper } from './styled';

const ProductContent = ({ dataNotify }) => {
  return (
    <>
      {dataNotify?.product?.image && (
        <LogoViewer
          imageUrl={dataNotify?.product?.image}
          content={`${dataNotify?.product?.productContent !== ' '}`}
        />
      )}
      {dataNotify?.product?.productContent && (
        <TextWrapper image={`${!!dataNotify?.product?.image}`}>
          <Text
            dangerouslySetInnerHTML={{
              __html: dataNotify?.product?.productContent,
            }}
          />
        </TextWrapper>
      )}
    </>
  );
};
export default ProductContent;
