import {
  DASHBOARD_PATH,
  EDIT_ACCOUNT_PARTNER_PATH,
  EDIT_COMPANY_ABSOLUTE_PATH,
  MANAGE_ACCOUNT_PARTNER_PATH,
  MANAGE_COMPANY_LIST_PATH,
  OPENSEARCH_RESULT,
  PROJECT_REGISTRATION_PATH,
  REGISTER_PATH,
} from 'constant/appPath';
import Application from 'page/Application';
import TopPageProductPartnerAdmin, {
  DetailNotifyPartnerAdmin,
  TopPageNotifyDetailPartnerAdmin,
  TopPagePartnerAdmin,
  TopPageProductDetailPartnerAdmin,
  TopPageProductSuperDetailPartnerAdmin,
} from 'page/Application/ManageProductAndService/TopPageProductPartnerAdmin/index';
import NotFound from 'page/NotFound';
import ManageCompanyList, {
  CompanyList,
  EditCompany,
  RegisterCompany,
} from 'page/Application/ManageCompanyList';
import ManagerAccountPartner, {
  AccountPartnerList,
  EditAccountPartner,
  RegisterAccountPartner,
} from 'page/Application/ManagerAccountPartner';

import PartnerWebsite, {
  PartnerWebsiteEdit,
  PartnerWebsiteOverView,
} from 'page/Application/PartnerWebsite';
import AccountInformation, {
  AccountInformationEdit,
  AccountInformationOverview,
} from 'page/Application/MyPagePartnerAdmin/AccountInformation';
import CompanyInformation, {
  CompanyInformationEdit,
  CompanyInformationOverview,
} from 'page/Application/MyPagePartnerAdmin/CompanyInformation';
import ProjectRegistration, {
  ProjectRegistrationDetail,
  ProjectRegistrationOverview,
} from 'page/Application/ProjectRegistration';
import PartnerWebsiteDetail from 'page/Application/PartnerWebsite/PartnerWebsiteDetail';
import TopPageViewSuperDetailProduct from 'page/Application/ManageProductAndService/TopPageProduct/TopPageViewProductSuperDetail';
import OpensearchResult from 'page/Application/Opensearch/OpensearchResult';
import { RESET_PASSWORD_PATH } from 'constant/authenticationPath';
import ResetPassword from 'page/Authentication/ForgotPassword/ResetPassword';

export const partnerAdminRouterData = [
  {
    path: DASHBOARD_PATH,
    element: <Application />,
    errorElement: <NotFound />,
    children: [
      {
        path: '/partner-page',
        element: <PartnerWebsite />,
        children: [
          {
            index: true,
            element: <PartnerWebsiteOverView />,
          },
          {
            path: `/partner-page/edit`,
            element: <PartnerWebsiteEdit />,
          },
          {
            path: `/partner-page/detail/:noticeId`,
            element: <PartnerWebsiteDetail />,
          },
        ],
      },
      {
        path: 'company-information',
        element: <CompanyInformation />,
        children: [
          {
            index: true,
            element: <CompanyInformationOverview />,
          },
          {
            path: 'edit',
            element: <CompanyInformationEdit />,
          },
        ],
      },
      {
        path: 'account-information',
        element: <AccountInformation />,
        children: [
          {
            index: true,
            element: <AccountInformationOverview />,
          },
          {
            path: 'edit',
            element: <AccountInformationEdit />,
          },
        ],
      },
      {
        path: MANAGE_ACCOUNT_PARTNER_PATH,
        element: <ManagerAccountPartner />,
        children: [
          {
            index: true,
            element: <AccountPartnerList />,
          },
          {
            path: REGISTER_PATH,
            element: <RegisterAccountPartner />,
          },
          {
            path: EDIT_ACCOUNT_PARTNER_PATH,
            element: <EditAccountPartner />,
          },
        ],
      },
      {
        path: MANAGE_COMPANY_LIST_PATH,
        element: <ManageCompanyList />,
        children: [
          {
            index: true,
            element: <CompanyList />,
          },
          {
            path: REGISTER_PATH,
            element: <RegisterCompany />,
          },
          {
            path: EDIT_COMPANY_ABSOLUTE_PATH,
            element: <EditCompany />,
          },
        ],
      },
      {
        path: 'top-page',
        element: <TopPageProductPartnerAdmin />,
        children: [
          {
            index: true,
            element: <TopPagePartnerAdmin />,
          },
          {
            path: 'top-page-product/:productId',
            element: <TopPageProductDetailPartnerAdmin />,
          },
          {
            path: 'top-page-product/list-notify/:productId',
            element: <DetailNotifyPartnerAdmin />,
          },
          {
            path: 'top-page-product/list-notify/:productId/notify-detail/:notifyId',
            element: <TopPageNotifyDetailPartnerAdmin />,
          },
          {
            path: 'top-page-product/:productId/notify-detail/:notifyId',
            element: <TopPageNotifyDetailPartnerAdmin />,
          },
          {
            path: 'top-page-product/:productId/top-page-detail-product/:menuId',
            element: <TopPageProductSuperDetailPartnerAdmin />,
          },
          {
            path: 'top-page-product/:productId/top-page-detail-product/:menuId/detail/:detailId',
            element: <TopPageViewSuperDetailProduct />,
          },
          {
            path: 'list-notify',
            element: <DetailNotifyPartnerAdmin />,
          },
          {
            path: 'notify-detail/:notifyId',
            element: <TopPageNotifyDetailPartnerAdmin />,
          },
          {
            path: 'list-notify/notify-detail/:notifyId',
            element: <TopPageNotifyDetailPartnerAdmin />,
          },
        ],
      },
      {
        path: PROJECT_REGISTRATION_PATH,
        element: <ProjectRegistration />,
        children: [
          {
            index: true,
            element: <ProjectRegistrationOverview />,
          },
          {
            path: 'detail/:projectId',
            element: <ProjectRegistrationDetail />,
          },
        ],
      },
      {
        path: OPENSEARCH_RESULT,
        element: <OpensearchResult roleType={'partnerAdmin'} />,
      },
    ],
  },
  {
    path: RESET_PASSWORD_PATH,
    element: <ResetPassword />,
  },
  {
    path: '404',
    element: <NotFound/>
  }
];
