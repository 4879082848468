import React from 'react';
import App from './App';
import { createRoot } from 'react-dom/client';
import { IntlProvider } from 'react-intl';
import { RecoilRoot } from 'recoil';
import { QueryClientProvider } from 'react-query';
import { queryClient } from 'api/config/queryClient';
import { ColorVariable } from 'style/styleComponent/color';
import { TypographyVariable } from 'style/styleComponent/typography';
import { ConfigProvider } from 'antd';
import 'style/style.scss';

const loadLocaleData = (location) => {
  switch (location) {
    case 'en':
      return import('resource/language/en.json');
    case 'ja':
      return import('resource/language/ja.json');
    default:
      return import('resource/language/en.json');
  }
};

const RootApp = async () => {
  const location = 'ja';
  // const location = navigator.language;
  const message = await loadLocaleData(location);
  const container = document.getElementById('root');
  const root = createRoot(container);

  root.render(
    <RecoilRoot>
      <QueryClientProvider client={queryClient}>
        <IntlProvider locale={location} messages={message.default}>
          <ConfigProvider
            theme={{
              token: {
                colorPrimary: '#9D1C49',
              },
              components: {
                App: {
                  fontFamily: '',
                },
                Button: {
                  colorPrimary: '#9D1C49',
                  algorithm: true, // Enable algorithm
                },
                Checkbox: {
                  colorPrimary: '#333333',
                  colorPrimaryHover: '#9D1C49',
                },
                Input: {
                  colorPrimaryHover: '#9D1C49',
                  activeBorderColor: '#9D1C49',
                  activeShadow: '0 0 0 2px rgba(183,24,64,0.1)',
                  colorError: '#9D1C49',
                  colorErrorBorderHover: '#DA8B9F',
                },
                DatePicker: {
                  colorPrimaryHover: '#9D1C49',
                  activeBorderColor: '#9D1C49',
                  colorError: '#9D1C49',
                  colorErrorBorderHover: '#DA8B9F',
                },

                Select: {
                  colorError: '#9D1C49',
                  colorErrorBorderHover: '#DA8B9F',
                },
                Form: {
                  colorError: '#9D1C49',
                  colorTextDescription: '#A5A5A5',
                  colorText: '#656464',
                },
                Menu: {
                  colorText: 'white',
                  itemSelectedBg: '#9D1C49',
                  itemActiveBg: 'rgba(255,95,132,0.6)',
                  itemSelectedColor: 'white',
                  itemBg: 'unset',
                  // subMenuItemBg: 'green',
                  // subMenuItemBg
                  // subMenuItemBg: 'rgba(255, 255, 255, 0.1)',
                },
                Table: {
                  headerBg: '#EBEBEB',
                  headerBorderRadius: '8px',
                  headerColor: '#656464',
                },
                Notification: {
                  colorWarning: '#9D1C49',
                },
              },
            }}
          >
            <App />
            <ColorVariable />
            <TypographyVariable />
          </ConfigProvider>
        </IntlProvider>
      </QueryClientProvider>
    </RecoilRoot>,
  );
};
RootApp().then();
