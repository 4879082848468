import { Form, Radio } from 'antd';
import useDestinationTypeMultiLanguage from 'hook/useDestinationTypeMultiLanguage';
import { RadioStyled } from 'page/Application/ManageNotificationList/component/Form/FormRadio1/styled';
import React from 'react';

const FormRadio2 = ({ name, setDestinationType }) => {
  const { NOTIFICATION_DESTINATION_TYPE } = useDestinationTypeMultiLanguage();

  const onChangeDestinationType = (e) => {
    const destinationTypeValue = e.target.value;
    setDestinationType(destinationTypeValue);
  };

  return (
    <Form.Item name={name}>
      <Radio.Group
        name="destinationTypeRadio"
        onChange={onChangeDestinationType}
      >
        <RadioStyled value={NOTIFICATION_DESTINATION_TYPE[0].value}>
          {NOTIFICATION_DESTINATION_TYPE[0].label}
        </RadioStyled>
        <RadioStyled value={NOTIFICATION_DESTINATION_TYPE[1].value}>
          {NOTIFICATION_DESTINATION_TYPE[1].label}
        </RadioStyled>
        <RadioStyled value={NOTIFICATION_DESTINATION_TYPE[2].value}>
          {NOTIFICATION_DESTINATION_TYPE[2].label}
        </RadioStyled>
        <RadioStyled value={NOTIFICATION_DESTINATION_TYPE[3].value}>
          {NOTIFICATION_DESTINATION_TYPE[3].label}
        </RadioStyled>
        <RadioStyled value={NOTIFICATION_DESTINATION_TYPE[4].value}>
          {NOTIFICATION_DESTINATION_TYPE[4].label}
        </RadioStyled>
      </Radio.Group>
    </Form.Item>
  );
};
export default FormRadio2;
