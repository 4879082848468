import React, { useEffect, useMemo, useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { ButtonOutline, ButtonPrimary } from 'components/Button';
import {
  getTopPageMenuParent,
  getTopPageMenuProduct,
  getTopPageProduct,
  updateTopPageMenuProduct,
} from 'api/product/topPage.api';
import { downloadContentFile } from 'api/storage/storage.api';
import { StyleSheetManager } from 'styled-components';
import isPropValid from '@emotion/is-prop-valid';
import Breadcrumb from 'components/Layout/ApplicationLayout/Breadcrumb';
import ProductContent from 'page/Application/ManageProductAndService/TopPageProductPartnerAdmin/TopPageProductDetailPartnerAdmin/component/ProductContent';
import LayoutTopPage from 'page/Application/ManageProductAndService/TopPageProductPartnerAdmin/TopPagePartnerAdmin/component/LayoutTopPage';
import FormDragTable from 'page/Application/ManageProductAndService/TopPageProduct/FormDragTable';
import LoadingSpin from 'components/LoadingSpin';
import useErrorCodeTopPage from 'page/Application/ManageProductAndService/TopPageProduct/hook/useErrorCodeTopPage';
import useCustomQuery from 'hook/useQuery/useCustomQuery';
import useModalConfirm from 'hook/useModalConfirm';
import useCustomMutateRenderMessage from 'hook/useQuery/useCustomMutateRenderMessage';
import dayjs from 'dayjs';
import {
  BodyProductDetail,
  BreadCrumbDetail,
  ButtonStyle,
  FormDate,
  FormDetailTitle,
  FormDownloadFile,
  FormHashCode,
  TableListProductDetail,
  TitleFormProductDetail,
  TitleSubmenu,
} from 'page/Application/ManageProductAndService/TopPageProduct/TopPageProductDetail/styled';
import { Alert, Col, Divider, Empty, Row, Spin, Tooltip } from 'antd';
import { InboxOutlined } from '@ant-design/icons';
import {
  TagContainer,
  TagStyled,
  TagTitle,
  TagWrapper,
} from 'page/Application/ManageProductAndService/TopPageProductPartnerAdmin/TopPageProductSuperDetailPartnerAdmin/styled';
import ShowMoreTextSpanItem from 'page/Application/ManageProductAndService/TopPageProduct/components/ShowMoreTextSpanItem';

// This implements the default behavior from styled-components v5
const shouldForwardProp = (propName, target) => {
  if (typeof target === 'string') {
    // For HTML elements, forward the prop if it is a valid HTML attribute
    return isPropValid(propName);
  }
  // For other elements, forward all props
  return true;
};

const TopPageEditSuperDetailProduct = () => {
  const { useQueryAPI } = useCustomQuery();
  const [detailId, setDetailId] = useState(null);
  const { menuId, productId } = useParams();
  const { ERROR_CODE_TOP_PAGE } = useErrorCodeTopPage();
  const [isDisable, setIsDisable] = useState(false);
  const [isDisableDownload, setIsDisableDownload] = useState(null);
  const { errorMessage, useMutationAPIHandleErrorCode } =
    useCustomMutateRenderMessage({
      errorCode: ERROR_CODE_TOP_PAGE,
    });
  const { contextHolder, handleConfirmAction } = useModalConfirm();
  const navigate = useNavigate();
  const [listMenu, setListMenu] = useState([]);

  const { data: productDetail = [], isLoading: loadingProductDetail } =
    useQueryAPI({
      api: getTopPageMenuProduct,
      key: 'productDetail',
      params: {
        menuId: menuId,
      },
      options: {
        refetchOnWindowFocus: false, // cancel fetch data when out focus
      },
    });

  const { data: menuParent = [], isLoading: menuPartnerLoading } = useQueryAPI({
    api: getTopPageMenuParent,
    key: 'menuParent',
    params: {
      menuId: menuId,
    },
  });

  const { data: dataProduct = [], isLoading: loadingDataProduct } = useQueryAPI(
    {
      api: getTopPageProduct,
      key: 'dataProduct',
      params: {
        productId: productId,
      },
      options: {
        refetchOnWindowFocus: false, // cancel fetch data when out focus
      },
    },
  );
  const {
    mutate: updateProductDetail,
    isLoading,
    isSuccess,
    isError,
  } = useMutationAPIHandleErrorCode({
    api: updateTopPageMenuProduct,
    key: 'updateDetailProductDetail',
    path: `/top-page/top-page-product/${productId}/top-page-detail-product/${menuId}`,
    messageSuccess: '変更した情報を保存しました。',
  });

  const formatData = useMemo(() => {
    return productDetail?.data?.listDetail?.map((item, index) => ({
      ...item,
      key: index + 1,
    }));
  }, [productDetail]);

  const [dataSource = [], setDataSource] = useState(formatData);

  const {
    refetch,
    isRefetching,
    isSuccess: downloadSuccess,
    isError: downloadError,
  } = useQueryAPI({
    api: downloadContentFile,
    params: {
      detailId: detailId,
    },
    options: {
      refetchOnWindowFocus: false,
    },
    enabled: !!detailId,
  });
  const handleNavigateGoBack = () => {
    handleConfirmAction({
      okText: 'はい',
      cancelText: 'キャンセル',
      title: '変更をキャンセルしてもよろしいでしょうか？',
      setIsDisable: setIsDisable,
      confirmFn: () => {
        navigate(
          `/top-page/top-page-product/${productId}/top-page-detail-product/${menuId}`,
        );
      },
    });
  };

  const handleDownLoad = async (fileName, detailId) => {
    await setDetailId(detailId);
    await refetch().then(async (value) => {
      await window.open(value?.data, '_self');
    });
  };

  const handleGoToViewPage = (data) => {
    navigate(
      `/top-page/top-page-product/${productId}/top-page-detail-product/${menuId}/detail/${data?.detailId}`,
    );
  };
  const handleMoveToSuperChildDetailProduct = (menuId) => {
    navigate(
      `/top-page/top-page-product/${productId}/top-page-detail-product/${menuId}`,
    );
  };
  const onSubmitEdit = () => {
    const dataSourceUpdate = dataSource?.map((object, index) => {
      return { detailId: object?.detailId, detailOrderId: index };
    });

    updateProductDetail({
      menuId: menuId,
      payload: dataSourceUpdate,
    });
  };

  const listItemTopPage = useMemo(() => {
    return [
      {
        title: <Link to={'/manage-company-list'}>企業管理</Link>,
      },
      {
        title: <Link to={'/top-page'}>製品一覧</Link>,
      },
      {
        title: (
          <Tooltip
            title={dataProduct?.data?.product?.productName}
            placement={'topLeft'}
          >
            <Link to={`/top-page/top-page-product/${productId}`}>
              <BreadCrumbDetail>
                {dataProduct?.data?.product?.productName}{' '}
              </BreadCrumbDetail>
            </Link>
          </Tooltip>
        ),
      },
      ...listMenu,
      {
        title: <BreadCrumbDetail>編集</BreadCrumbDetail>,
      },
    ];
  }, [dataProduct?.data?.product?.productName, listMenu, productId]);

  const handleNavigateOpenSearch = (tag) => {
    const currentUrl = window.location.origin;
    window.open(
      `${currentUrl}/#/opensearch?keyword=${encodeURIComponent(tag)}`,
      '_self',
    );
  };

  useEffect(() => {
    if (downloadSuccess || downloadError || !isRefetching) {
      setTimeout(() => setIsDisableDownload(null), 1000);
    }
    if (productDetail) {
      setDataSource(
        productDetail?.data?.listDetail?.map((item, index) => ({
          ...item,
          key: index + 1,
        })),
      );
    }

    // Set list breadcrumb menu top page
    setListMenu(
      menuParent?.data && !menuPartnerLoading
        ? menuParent?.data?.map((item) => {
            return {
              title: (
                <Tooltip title={item?.menuName} placement={'topLeft'}>
                  <Link
                    to={`/top-page/top-page-product/${productId}/top-page-detail-product/${item?.menuId}`}
                  >
                    <BreadCrumbDetail>{item?.menuName}</BreadCrumbDetail>
                  </Link>
                </Tooltip>
              ),
            };
          })
        : '',
    );
  }, [
    productDetail,
    downloadSuccess,
    isRefetching,
    downloadError,
    menuParent?.data,
    menuPartnerLoading,
    productId,
  ]);

  const columns = [
    {
      key: 'sort',
      width: 46,
    },
    {
      ellipsis: true,
      render: (item, index) => {
        return (
          <StyleSheetManager shouldForwardProp={shouldForwardProp}>
            <TableListProductDetail
              background={'none'}
              paddingedit={'editMode'}
              hashtags={item?.detailTagLst?.length}
              detailType={'FILE'}
              key={index}
            >
              {contextHolder ?? ''}
              <TitleFormProductDetail className={'maxWidthTitleEdit'}>
                {item?.detailDisplayType !== 'FILE' ? (
                  item?.detailDisplayType === 'LINK' ? (
                    <Tooltip title={item?.detailTitle} placement={'topLeft'}>
                      <FormDetailTitle
                        href={item?.detailUrl}
                        target={item?.isNewTab ? '_blank' : ''}
                        className={'link'}
                        rel="noreferrer"
                      >
                        {item?.detailTitle}
                      </FormDetailTitle>
                    </Tooltip>
                  ) : (
                    <Tooltip title={item?.detailTitle} placement={'topLeft'}>
                      <FormDetailTitle
                        onClick={() => handleGoToViewPage(index)}
                        className={'link'}
                        rel="noreferrer"
                      >
                        {item?.detailTitle}
                      </FormDetailTitle>
                    </Tooltip>
                  )
                ) : (
                  <Tooltip title={item?.detailTitle} placement={'topLeft'}>
                    <FormDetailTitle
                      cursor={'initial'}
                      color={'var(--BTN-TEXT-SECONDARY-default)'}
                    >
                      {item?.detailTitle}
                    </FormDetailTitle>
                  </Tooltip>
                )}
                <FormDate className={'date-padding'}>
                  更新日:{' '}
                  <span style={{ fontWeight: 400 }}>
                    {dayjs(item?.detailDisplayDate).format('YYYY年MM月DD日')}
                  </span>
                </FormDate>
              </TitleFormProductDetail>
              <Divider style={{ margin: '8px 0 20px', color: '#656464' }} />
              {item.detailExplanation && <ShowMoreTextSpanItem item={item} />}
              {item?.detailDisplayType === 'FILE' && (
                <>
                  {item?.showHash && (
                    <FormHashCode>
                      SHA256：{item?.fileResponse?.fileHash ?? '計算中'}
                    </FormHashCode>
                  )}
                  <FormDownloadFile
                    onClick={() => {
                      handleDownLoad(
                        item?.fileResponse?.fileName,
                        item?.detailId,
                      ).then();
                      setIsDisableDownload(item?.detailId);
                    }}
                    disabled={
                      item?.detailDisplayStatus === 'DOWNLOADABLE_STOPPED' ||
                      isDisableDownload === item?.detailId
                    }
                  >
                    ダウンロード
                  </FormDownloadFile>
                </>
              )}
              {item?.detailTagLst?.length > 0 && (
                <TagWrapper>
                  <TagTitle>タグ</TagTitle>
                  <TagContainer>
                    {item?.detailTagLst?.map((itemTag) => (
                      <TagStyled
                        onClick={() => handleNavigateOpenSearch(itemTag)}
                      >
                        {itemTag}
                      </TagStyled>
                    ))}
                  </TagContainer>
                </TagWrapper>
              )}
            </TableListProductDetail>
          </StyleSheetManager>
        );
      },
    },
  ];

  return (
    <Spin spinning={isLoading || loadingDataProduct || loadingProductDetail}>
      <Breadcrumb
        maxWidthEditMode={'maxWidthTitleEdit'}
        listItem={listItemTopPage}
        titlePage={dataProduct?.data?.product?.productName}
      >
        <div style={{ display: 'flex', gap: '15px' }}>
          <ButtonPrimary
            disabled={isLoading || isSuccess}
            text={!isLoading ? '保存' : <LoadingSpin />}
            width={80}
            onClick={onSubmitEdit}
          />

          <ButtonOutline
            isDisabled={isDisable}
            text={'キャンセル'}
            onClick={() => {
              setIsDisable(true);
              if (dataSource.length) {
                handleNavigateGoBack();
              } else {
                navigate(
                  `/top-page/top-page-product/${productId}/top-page-detail-product/${menuId}`,
                );
              }

              setTimeout(() => setIsDisable(false), 1000);
            }}
          />
        </div>
      </Breadcrumb>
      {(dataProduct?.data?.product?.productContent ||
        dataProduct?.data?.product?.image) && (
        <LayoutTopPage>
          <ProductContent
            isLoading={loadingProductDetail && loadingDataProduct}
            dataNotify={dataProduct?.data}
          />
        </LayoutTopPage>
      )}
      <div style={{ background: '#ebebeb', padding: '20px 35px 0' }}>
        <TitleSubmenu>{productDetail?.data?.menu?.menuName}</TitleSubmenu>
        {productDetail?.data?.menu?.children?.length > 0 && (
          <Row gutter={20} style={{ padding: '0 0 20px' }}>
            {productDetail?.data?.menu?.children?.map((item) => (
              <Col
                xs={24}
                sm={24}
                md={12}
                lg={8}
                xl={8}
                xxl={6}
                key={item?.menuId}
              >
                <ButtonStyle
                  block={true}
                  onClick={() =>
                    handleMoveToSuperChildDetailProduct(item?.menuId)
                  }
                >
                  <Tooltip title={item?.menuName} placement={'topLeft'}>
                    <div
                      style={{
                        whiteSpace: 'nowrap',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        fontWeight: 700,
                      }}
                    >
                      {item?.menuName}
                    </div>
                  </Tooltip>
                </ButtonStyle>
              </Col>
            ))}
          </Row>
        )}
      </div>
      <div>
        <div>
          {(isError || errorMessage) && (
            <Alert
              message={errorMessage}
              type="error"
              style={{ margin: '12px 0' }}
            />
          )}
        </div>

        <BodyProductDetail>
          {dataSource?.length ? (
            <FormDragTable
              dataSource={dataSource}
              setDataSource={setDataSource}
              columns={columns}
            />
          ) : (
            <Empty
              image={<InboxOutlined />}
              imageStyle={{ fontSize: 80 }}
              description={null}
              style={{ fontSize: '16px', paddingBottom: 20 }}
            >
              データがありません
            </Empty>
          )}
        </BodyProductDetail>
      </div>
    </Spin>
  );
};
export default TopPageEditSuperDetailProduct;
