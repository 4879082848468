import React from 'react';
import LabelFormMyPage from 'page/Application/MyPage/component/FormMyPage/LabelFormMyPage';
import { FormItem } from 'components/Form2';
import {
  GridFormAccount,
  GridFormAccount1to2,
  GridFormAccount2to3,
} from 'page/Application/MyPagePartnerAdmin/styled';
import { InputStyled } from 'components/Form2/styled';
import { MAX_LENGTH_TEXT } from 'constant/inputMaxlength';
import {
  CHECK_HAS_NUMBER,
  CHECK_SPECIAL_CHARACTER_WITHOUT_SPACE,
} from 'constant/regex';
import { FormMessage } from 'components/Form';

const Position = ({ editMode }) => {
  const rulesValidateText = [
    () => ({
      validator(_, value) {
        if (
          value &&
          (!CHECK_HAS_NUMBER.test(value) || // check has a number
            CHECK_SPECIAL_CHARACTER_WITHOUT_SPACE.test(value) ||
            (value && [...value].some((char) => char.codePointAt(0) > 65535))) // check 4-byte characters
        ) {
          return Promise.reject(
            <FormMessage
              text1={'無効な文字列です。全角半角文字のみで入力してください。'}
              marginBottom={'12px'}
            />,
          );
        }
        return Promise.resolve();
      },
    }),
  ];

  return (
    <GridFormAccount>
      <GridFormAccount1to2>
        <LabelFormMyPage labelText={'役職'} />
      </GridFormAccount1to2>

      <GridFormAccount2to3>
        <FormItem name={'position'} rules={rulesValidateText}>
          <InputStyled
            maxLength={MAX_LENGTH_TEXT}
            placeholder={editMode ? '役職を入力してください。' : ''}
          />
        </FormItem>
      </GridFormAccount2to3>
    </GridFormAccount>
  );
};

export default Position;
