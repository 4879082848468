import { Typography } from 'antd';
import styled from 'styled-components';

export const CustomRowTable = styled.div`
  display: grid;
  grid-template-columns: 34.6% 14%;
  align-items: end;

  &:not(:last-child) {
    padding-top: 5px;
    padding-bottom: 5px;
  }

  &:not(:first-child) {
    padding-top: 15px;
    padding-bottom: 5px;
  }
`;

export const TableGrid2 = styled(Typography.Text)`
  grid-column: 2 / 3;
  padding-left: 4px;
  color: var(--FORM-LABEL-default);
  font-family: 'inter-regular', sans-serif;
  width: 150px;
`;

export const TableGrid3 = styled(Typography.Text)`
  grid-column: 3 / 4;
  padding-left: 4px;
  color: var(--FORM-LABEL-default);
  font-family: 'inter-regular', sans-serif;
  width: 150px;
`;
