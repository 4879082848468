import axiosClient from 'api/config/axiosClient';
import axiosClientNotTimeOut from 'api/config/axiosClientNotTimeOut';

const getListNotification = (props) => {
  const url = `v1/notify?pageNumber=${props.pageNumber}&pageSize=${props.pageSize}&keyword=${props?.keyword}&displayedDateFrom=${props.displayedDateFrom}&displayedDateTo=${props.displayedDateTo}&category=${props.category}&status=${props.status}&sortField=${props.sortField}&sortDirection=${props.sortDirection}`;
  return axiosClient.get(url);
};

const deleteMultipleNotification = (payload) => {
  const url = `v1/notify`;
  return axiosClient.delete(url, { data: payload });
};

const registerNotification = ({ payload }) => {
  const url = `v1/notify`;
  return axiosClient.post(url, payload);
};

const getDetailNotification = ({ notifyId }) => {
  const url = `v1/notify/${notifyId}`;
  return axiosClient.get(url);
};

const deleteNotification = ({ notifyId }) => {
  const url = `v1/notify/${notifyId}`;
  return axiosClient.delete(url);
};

const updateNotification = ({ notifyId, payload }) => {
  const url = `v1/notify/${notifyId}`;
  return axiosClient.put(url, payload);
};

const getListProductForNotification = ({ keyword, isNotify }) => {
  const url = `v1/partner/product?keyword=${encodeURIComponent(
    keyword,
  )}&isNotify=${isNotify}`;
  return axiosClient.get(url);
};

export {
  getListNotification,
  deleteMultipleNotification,
  registerNotification,
  getDetailNotification,
  deleteNotification,
  updateNotification,
  getListProductForNotification,
};
