import React, { useEffect, useMemo } from 'react';
import { useSearchParams } from 'react-router-dom';
import { getAllMenuProductWithPath, getProduct } from 'api/product/product.api';
import {
  DETAIL_CONTENT_DISPLAY_STATUS,
  DETAIL_CONTENT_TYPE_DROPDOWN,
} from 'constant/contentConstants';
import { DATE_FORMAT_LIST } from 'constant/notificationConstants';
import useCustomQuery from 'hook/useQuery/useCustomQuery';
import {
  FormDateRangePicker,
  FormDropDown,
  FormLabelGrid,
  FormCascader,
} from 'page/Application/ManageProductAndService/Content/component';
import {
  Grid1,
  Grid2,
} from 'page/Application/ManageProductAndService/Content/component/styled';
import { GridModal } from 'page/Application/ManageProductAndService/Content/ContentList/component/FormModalFilter/styled';

const FormModalFilter = ({ handleSelectedDate, form }) => {
  const { useQueryWithoutParams, useQueryAPI } = useCustomQuery();
  const [searchParams] = useSearchParams();
  const menuId = searchParams.get('menuId');
  const productId = searchParams.get('productId');

  const {
    data: listProduct,
    isLoading: isProductLoading,
    isFetched: isProductFetched,
  } = useQueryWithoutParams({
    api: getProduct,
    key: 'listProduct',
    options: {
      refetchOnWindowFocus: false,
    },
  });

  const { data: listMenu, isFetched: isMenuFetched } = useQueryAPI({
    api: getAllMenuProductWithPath,
    key: 'listMenu',
    params: {
      productId: form.getFieldValue('productId'),
    },
    enabled: !!form.getFieldValue('productId'),
    options: {
      refetchOnWindowFocus: false,
    },
  });

  const productData = useMemo(() => {
    return listProduct?.data.map((value) => ({
      label: value?.productName,
      value: value?.productId,
    }));
  }, [listProduct?.data]);

  const menuData = useMemo(() => {
    return listMenu?.data;
  }, [listMenu?.data]);

  const handleReset = () => {
    form.resetFields(['menuIds']);
  };

  let isData = true;
  const recursionMenu = (menuId, oldArr, newArr) => {
    oldArr?.forEach((value) => {
      if (value?.isParent && isData) {
        if (value?.isRoot) {
          newArr.length = 0;
        }
        newArr.push(value?.menuId);
      }
      if (!value?.isParent && value?.isRoot && isData) {
        newArr.length = 0;
        newArr.push(value?.menuId);
      }
      if (value?.menuId === Number(menuId)) {
        isData = false;
        newArr.push(value?.menuId);
      }
      if (value?.children) {
        recursionMenu(menuId, value?.children, newArr);
      }
    });
  };

  useEffect(() => {
    if (productId && isProductFetched) {
      const product = productData?.find(
        (product) => product?.value === Number(productId),
      );
      form.setFieldValue('productId', product?.value);
    }
  }, [form, productId, isProductFetched, productData]);

  useEffect(() => {
    if (menuId && isMenuFetched) {
      let newArr = [];
      recursionMenu(menuId, menuData, newArr);
      form.setFieldValue('menuIds', Array.from(new Set(newArr)));
    }
  }, [form, isMenuFetched, menuData, menuId]);

  return (
    <GridModal style={{ paddingTop: '20px' }}>
      <Grid1>
        <FormLabelGrid labelText={'プロダクト名'} />
      </Grid1>
      <Grid2>
        <FormDropDown
          name={'productId'}
          data={productData}
          isLoading={isProductLoading}
          placeHolder={'カテゴリ名を入力してください。'}
          onChange={handleReset}
        />
      </Grid2>
      <Grid1>
        <FormLabelGrid labelText={'メニュー'} />
      </Grid1>
      <Grid2>
        <FormCascader
          name={'menuIds'}
          menuData={menuData}
          notFoundContent={'データがありません'}
          changeOnSelect
        />
      </Grid2>
      <Grid1>
        <FormLabelGrid labelText={'コンテンツタイプ'} />
      </Grid1>
      <Grid2>
        <FormDropDown
          name={'displayType'}
          data={DETAIL_CONTENT_TYPE_DROPDOWN}
        />
      </Grid2>
      <Grid1>
        <FormLabelGrid labelText={'表示日付'} />
      </Grid1>
      <Grid2>
        <FormDateRangePicker
          handleSelectedDate={handleSelectedDate}
          name={'displayedDate'}
          width={'500px'}
          dateFormat={DATE_FORMAT_LIST[1]}
          isList={true}
          isShowTime={false}
          isUpdateStatus={false}
        />
      </Grid2>
      <Grid1>
        <FormLabelGrid labelText={'ステータス'} />
      </Grid1>
      <Grid2>
        <FormDropDown
          name={'displayStatus'}
          data={DETAIL_CONTENT_DISPLAY_STATUS}
        />
      </Grid2>
    </GridModal>
  );
};

export default FormModalFilter;
