import styled from 'styled-components';

export const TitlePage = styled.div`
  padding: 12px 0;
  color: #656464;
  font-size: 14px;
  font-family: 'inter-regular', sans-serif;
  border-bottom: 1px solid #c5c5c5;
`;

export const FormContainer = styled.div`
  width: 570px;
  margin: 56px auto;
`;

export const HeaderSelectDomain = styled.div`
  display: flex;
  justify-content: space-between;
  background: #ebebeb;
  padding: 12px 16px;
  border-radius: 8px 8px 0 0;
  margin-bottom: 8px;
`;
