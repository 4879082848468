import { useMemo } from 'react';

const useErrorCodeManageNotification = () => {
  const ERROR_CODE_MANAGE_NOTIFICATION = useMemo(() => {
    return {
      '404_NOTIFY_NOT_FOUND': {
        message: 'お知らせが見つかりませんでした。',
        statusCode: 404,
        type: 'error',
      },
      '404_PRODUCT_NOT_FOUND': {
        message: '製品が見つかりませんでした。',
        statusCode: 404,
        type: 'error',
      },
      '400_CONTENT_ONLY': {
        message: '400_CONTENT_ONLY',
        statusCode: 400,
        type: 'error',
      },
      '400_PDF_ONLY': {
        message: '400_PDF_ONLY',
        statusCode: 400,
        type: 'error',
      },
      '400_URL_ONLY': {
        message: '400_URL_ONLY',
        statusCode: 400,
        type: 'error',
      },
      '400_NO_DETAILS_CANT_HAVE_CONTENT_URL_AND_PDF': {
        message: '400_NO_DETAILS_CANT_HAVE_CONTENT_URL_AND_PDF',
        statusCode: 400,
        type: 'error',
      },
      '400_PUBLISHED_FROM_MUST_BEFORE_PUBLISHED_TO': {
        message:
          '「公開日時予約」を「非公開日時予約」以降の日時で選択してください。 ',
        statusCode: 400,
        type: 'error',
      },
      '400_ONLY_PDF_CSV_AND_EXCEL_FILES_ARE_ALLOWED': {
        message: 'ファイル形式が正しくありません。',
        statusCode: 400,
        type: 'error',
      },
      '404_THIS_FILE_SETTING_NOT_FOUND': {
        message: '404_THIS_FILE_SETTING_NOT_FOUND',
        statusCode: 404,
        type: 'error',
      },
      '400_FILE_SIZE_HAS_EXCEEDED_IT_MAX_LIMIT_OF_10MB': {
        message: 'アップロードファイルの容量制限から10MBを超えました。',
        statusCode: 400,
        type: 'error',
      },
      '404_FILE_SETTING_NOT_FOUND': {
        message: 'ファイル設定が見つかりませんでした。',
        statusCode: 404,
        type: 'error',
      },
      '400_FILE_TYPE_NOT_ALLOW': {
        message: '400_FILE_TYPE_NOT_ALLOW',
        statusCode: 400,
        type: 'error',
      },
      '400_FILE_NAME_TOO_LONG': {
        message: 'ファイル名を100文字以内で入力してください。',
        statusCode: 400,
        type: 'error',
      },
      '400_NOTIFY_IS_DUPLICATED': {
        message: '400_NOTIFY_IS_DUPLICATED',
        statusCode: 400,
        type: 'error',
      },
      '400_PUBLISHED_FROM_OR_PUBLISHED_TO_MUST_NOT_BE_NULL': {
        message: '400_PUBLISHED_FROM_OR_PUBLISHED_TO_MUST_NOT_BE_NULL',
        statusCode: 400,
        type: 'error',
      },
      '400_PUBLISHED_TO_MUST_NOT_BE_NULL': {
        message: '「公開日時予約」は必須項目です。',
        statusCode: 400,
        type: 'error',
      },
      '400_PUBLISHED_FROM_MUST_AFTER_NOW': {
        message: '「公開日時予約」は現在時点後よりの日時を入力してください。',
        statusCode: 400,
        type: 'error',
      },
      '400_PUBLISHED_TO_MUST_AFTER_NOW': {
        message: '「非公開日時予約」は現在時点後よりの日時を入力してください。',
        statusCode: 400,
        type: 'error',
      },
      '400_PUBLISHED_FROM_AND_PUBLISHED_TO_MUST_AFTER_NOW': {
        message: '公開日時予約・非公開日時予約が無効です。',
        statusCode: 400,
        type: 'error',
      },
    };
  }, []);
  return {
    ERROR_CODE_MANAGE_NOTIFICATION,
  };
};

export default useErrorCodeManageNotification;
