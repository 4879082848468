import React, { useEffect, useMemo } from 'react';
import { Tooltip } from 'antd';
import {
  getProductNotify,
  getTopPageNotify,
  getTopPageProduct,
} from 'api/product/topPage.api';
import { useRecoilValue } from 'recoil';
import Breadcrumb from 'components/Layout/ApplicationLayout/Breadcrumb';
import useCustomQuery from 'hook/useQuery/useCustomQuery';
import HeaderTopPage from 'page/Application/ManageProductAndService/TopPageProductPartnerAdmin/TopPagePartnerAdmin/component/HeaderTopPage';
import LayoutTopPage from 'page/Application/ManageProductAndService/TopPageProductPartnerAdmin/TopPagePartnerAdmin/component/LayoutTopPage';
import TableNotifyTopPage from 'page/Application/ManageProductAndService/TopPageProductPartnerAdmin/TopPagePartnerAdmin/component/TableNotifyTopPage';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { userInformation } from 'recoil/atom/authentication';
import { ERROR_MESSAGES_RESPONSE } from 'constant/topPageConstants';
import { BreadCrumbDetail } from 'page/Application/ManageProductAndService/TopPageProduct/TopPageProductDetail/styled';

const DetailNotifyPartnerAdmin = () => {
  const { useQueryAPI, useQueryWithoutParams } = useCustomQuery();
  const { role } = useRecoilValue(userInformation);
  const { productId } = useParams();

  const navigate = useNavigate();

  const { data: topPageNotifyDetail = [], isLoading } = useQueryWithoutParams({
    api: getTopPageNotify,
    key: 'topPageNotifyDetail',
  });

  const {
    data: productNotifyData = [],
    isLoading: isProductNotifyLoading,
    error,
  } = useQueryAPI({
    api: getProductNotify,
    key: 'topPageProductNotify',
    params: {
      productId: productId,
    },
    enabled: !!productId,
  });

  const { data: productData = [], error: errorProduct } = useQueryAPI({
    api: getTopPageProduct,
    key: 'topPageProduct',
    params: {
      productId: productId,
    },
    enabled: !!productId,
  });

  const breadCrumbNotifyProduct = useMemo(() => {
    return (
      productId !== undefined && {
        title: (
          <Tooltip
            placement={'topLeft'}
            title={productData?.data?.product?.productName}
          >
            <Link to={`/top-page/top-page-product/${productId}`}>
              <BreadCrumbDetail>
                {productData?.data?.product?.productName}
              </BreadCrumbDetail>
            </Link>
          </Tooltip>
        ),
      }
    );
  }, [productData?.data?.product?.productName, productId]);

  const listItemTopPageSA = useMemo(() => {
    return [
      {
        title: <Link to={'/manage-company-list'}>企業管理</Link>,
      },
      {
        title: <Link to={'/top-page'}>製品一覧</Link>,
      },
      breadCrumbNotifyProduct,
      {
        title: <BreadCrumbDetail>お知らせ一覧</BreadCrumbDetail>,
      },
    ];
  }, [breadCrumbNotifyProduct]);

  const listItemTopPageSU = useMemo(() => {
    return [
      {
        title: <Link to={'/my-page'}>マイページ</Link>,
      },
      {
        title: <Link to={'/top-page'}>製品一覧</Link>,
      },
      breadCrumbNotifyProduct,
      {
        title: <BreadCrumbDetail>お知らせ一覧</BreadCrumbDetail>,
      },
    ];
  }, [breadCrumbNotifyProduct]);

  const listItemTopPagePartner = useMemo(() => {
    return [
      {
        title: <Link to={'/partner-page'}>パートナー専用ページ</Link>,
      },
      {
        title: <Link to={'/top-page'}>製品一覧</Link>,
      },
      breadCrumbNotifyProduct,
      {
        title: <BreadCrumbDetail>お知らせ一覧</BreadCrumbDetail>,
      },
    ];
  }, [breadCrumbNotifyProduct]);

  const listItemByRole = useMemo(() => {
    return {
      ROLE_SOLITON_ADMIN: listItemTopPageSA,
      ROLE_SOLITON_USER: listItemTopPageSU,
      ROLE_PARTNER_ADMIN: listItemTopPagePartner,
      ROLE_PARTNER_USER: listItemTopPagePartner,
    };
  }, [listItemTopPagePartner, listItemTopPageSA, listItemTopPageSU]);

  useEffect(() => {
    if (
      ERROR_MESSAGES_RESPONSE[errorProduct?.response?.data?.message] ||
      ERROR_MESSAGES_RESPONSE[error?.response?.data?.message]
    ) {
      navigate('/top-page-not-found');
    }
  }, [
    errorProduct?.response?.data?.message,
    navigate,
    error?.response?.data?.message,
  ]);

  return (
    <>
      <Breadcrumb
        listItem={listItemByRole[role]}
        titlePage="お知らせ一覧"
      ></Breadcrumb>
      <LayoutTopPage>
        <HeaderTopPage
          titleScreen={'お知らせ'}
          loading={isLoading || isProductNotifyLoading}
          isDisplayNotifyList={false}
          isNotifyDetail={true}
        />

        <TableNotifyTopPage
          isLoading={isLoading}
          dataNotify={
            productId ? productNotifyData?.data : topPageNotifyDetail?.data
          }
          isNotifyOnly={true}
          productId={productId}
        />
      </LayoutTopPage>
    </>
  );
};

export default DetailNotifyPartnerAdmin;
