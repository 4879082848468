import React from 'react';
import { LabelFrom } from 'page/Application/ManageCompanyList/component/Form/styled';

const FormLabelGrid = ({ labelText, required }) => {
  return (
    <LabelFrom>
      <span>{labelText}</span>
      {required && <span className="mark">*</span>}
    </LabelFrom>
  );
};

export default FormLabelGrid;
