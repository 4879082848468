const CHECK_LATIN_ALPHABET =
  /(([^a-zA-Z0-9]+)([^-._!"`'#%&,:;<>=@{}~$()*+/\\?[]^|]\s\b)*)/g;
// const CHECK_STRONG_PASSWORD_2 = /(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{8,20})/g;
const CHECK_LATIN_JAPANESE = /^[A-Za-zぁ-んァ-ヶー一-龠々〆ヵヶ]+$/;
const CHECK_LATIN_JAPANESE_SPACE = /^[A-Za-zぁ-んァ-ヶー一-龠々〆ヵヶ\s]+$/;
const CHECK_LATIN_JAPANESE_NUMBER = /^[A-Za-zぁ-んァ-ヶー一-龠々〆ヵヶ0-9\s]+$/;
const CHECK_STRONG_PASSWORD =
  /(?=(?:.*[a-z])+)(?=(?:.*[A-Z])+)(?=(?:.*[0-9])+)^[a-zA-Z0-9]{8,20}$/;
const CHECK_PASSWORD = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d]{8,20}$/;
// const CHECK_EMAIL = /^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/;
const CHECK_EMAIL = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
const CHECK_EMAIL_NOT_TRIM = /^\s*[^\s@]+@[^\s@]+\.[^\s@]+\s*$/;
const CHECK_VALIDATE_EMAIL = /^\s*[\w-.]+@([\w-]+\.)+[\w-]{2,10}\s*$/g;
const CHECK_CHARACTER_EMAIL = /^.{1,64}@/;
const CHECK_SPECIAL_CHARACTER = /[-._!"`'#%&,:;<>=@{}~$()*+/\\?[\]^|\s]+/;

const CHECK_SPECIAL_CHARACTER_WITHOUT_SPACE =
  /[-._!"`'#%&,:;<>=@{}~$()*+/\\?[\]^|]+/;
const CHECK_SPECIAL_CHARACTER_WITHOUT_DOT_AND_DASH =
  /[_!"`'#%&,:;<>=@{}~$()*+/\\?[\]^|]+/;
const CHECK_CHARACTER = /[@#$%^&*()_+\-={}[\];:'"<>,.?/]/g;
const CHECK_NUMBER_ONLY = /^[0-9]+$/;
const CHECK_NUMBER_AFTER_BLANK = /^\s*\d/;
const CHECK_BLANK = /^\S+$/;
const CHECK_LOCAL_PATH = /@+/g;
const CHECK_TEXT_NUMBER = /\W+/;
const CHECK_HAS_NUMBER = /^\D+$/;
const CHECK_NAME_TAGS = /^[A-Za-zぁ-んァ-ヶー一-龠々〆ヵヶｦ-ﾟ_-]+$/;
const CHECK_MOBILE_NUMBER = /^(\d{10,12})$/;

export {
  CHECK_LATIN_ALPHABET,
  CHECK_STRONG_PASSWORD,
  CHECK_EMAIL,
  CHECK_VALIDATE_EMAIL,
  CHECK_LATIN_JAPANESE,
  CHECK_PASSWORD,
  CHECK_CHARACTER_EMAIL,
  CHECK_SPECIAL_CHARACTER,
  CHECK_SPECIAL_CHARACTER_WITHOUT_SPACE,
  CHECK_SPECIAL_CHARACTER_WITHOUT_DOT_AND_DASH,
  CHECK_NUMBER_ONLY,
  CHECK_NUMBER_AFTER_BLANK,
  CHECK_BLANK,
  CHECK_LOCAL_PATH,
  CHECK_TEXT_NUMBER,
  CHECK_CHARACTER,
  CHECK_HAS_NUMBER,
  CHECK_LATIN_JAPANESE_SPACE,
  CHECK_LATIN_JAPANESE_NUMBER,
  CHECK_NAME_TAGS,
  CHECK_MOBILE_NUMBER,
  CHECK_EMAIL_NOT_TRIM,
};
