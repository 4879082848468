import { useEffect, useMemo, useState } from 'react';
import { Link } from 'react-router-dom';
import SearchContentHighLight from '../SearchContentHighLight';
import {
  ContentSearchStyle,
  HightlightContentSearchStyle,
  SearchStyleWrapper,
  TitleSearchStyle,
  TypeSearchStyle,
} from './styled';

const ItemOpenSearch = ({ itemData, keyword, roleType }) => {
  const [dataContentBefore, setDataContentBefore] = useState('');
  const [dataContentHighLight, setDataContentHighLight] = useState('');
  const [dataContentAfter, setDataContentAfter] = useState('');
  const [isShowDataContentHighLight, setIsShowDataContentHighLight] =
    useState(false);
  const [dataContentBrief, setDataContentBrief] = useState('');

  const createItemTag = (itemData) => {
    const itemTag = itemData.tag;
    // Check if tag contains keyword
    let highlightedTag = '';
    let keywordList = keyword?.split(' ');
    if (itemTag && itemTag.length != 0) {
      itemTag?.forEach((tag) => {
        keywordList?.forEach((keywordItem) => {
          if (
            highlightedTag == '' &&
            tag?.toLowerCase().includes(keywordItem.toLowerCase())
          ) {
            highlightedTag = tag;
          }
        });
      });
    }
    return highlightedTag;
  };

  const hightlightContent = (itemContent, isShowElipsis) => {
    setIsShowDataContentHighLight(true);
    const keywordIndex = itemContent
      .toLowerCase()
      .indexOf(keyword.toLowerCase());

    const cutPoint = keywordIndex + keyword.length;
    if (keywordIndex > 100) {
      const contentBeforeToDisplay = itemContent.substring(
        keywordIndex - 100,
        keywordIndex,
      );
      setDataContentBefore('...'.concat(contentBeforeToDisplay));
    } else {
      setDataContentBefore(itemContent.substring(0, keywordIndex));
    }
    setDataContentHighLight(
      <HightlightContentSearchStyle>
        {itemContent.substring(keywordIndex, cutPoint)}
      </HightlightContentSearchStyle>,
    );

    if (isShowElipsis) {
      setDataContentAfter(
        itemContent.substring(cutPoint, cutPoint + 100).concat(' ...'),
      );
    } else {
      setDataContentAfter(itemContent.substring(cutPoint, cutPoint + 100));
    }
  };

  // I.5. if no data match, show 1 part of content
  const createBriefContent = (itemContent) => {
    // I.5.1. create brief content
    setIsShowDataContentHighLight(false);
    if (itemContent.length > 200) {
      setDataContentBrief(itemContent.substring(0, 200).concat(' ...'));
    } else {
      setDataContentBrief(itemContent);
    }
  };

  // I.CONTENT
  const createResultLinkContent = (itemData) => {
    // I.1. PRODUCT
    if (itemData?.detailType == 'PRODUCT') {
      itemData.showType = 'コンテンツ';
      if (itemData?.detailDisplayType === 'DETAIL') {
        return `/top-page/top-page-product/${itemData.productId}/top-page-detail-product/${itemData.menuId}/detail/${itemData?.id}`;
      } else {
        return `/top-page/top-page-product/${itemData.productId}/top-page-detail-product/${itemData.menuId}`;
      }
    }
    // I.2. PARTNER PAGE
    else if (itemData?.detailType == 'PARTNER_PAGE') {
      if (
        itemData?.detailDisplayType == 'NOTICE' &&
        itemData?.content &&
        itemData?.content != ''
      ) {
        itemData.showType = 'パートナー専用ページ';
        if (roleType == 'solitonAdmin' || roleType == 'solitonUser') {
          return `/partner-page/${itemData?.partnerId}/detail/${itemData?.id}`;
        } else {
          return `/partner-page/detail/${itemData?.id}`;
        }
      } else {
        itemData.showType = 'パートナー専用ページ';
        if (roleType == 'solitonAdmin' || roleType == 'solitonUser') {
          return `/partner-page/${itemData?.partnerId}`;
        } else {
          return `/partner-page`;
        }
      }
    }
  };

  // II. NOTIFY - TOPPAGE
  const createResultLinkNotifyToppage = (itemData) => {
    if (itemData?.notifyDestination == 'DETAILS_AVAILABLE') {
      itemData.showType = 'お知らせ';
      return `/top-page/list-notify/notify-detail/${itemData?.id}`;
    } else {
      itemData.showType = 'お知らせ';
      return `/top-page/list-notify`;
    }
  };

  // III. NOTIFY - PRODUCT
  const createResultLinkNotifyProduct = (itemData) => {
    if (itemData?.notifyDestination == 'DETAILS_AVAILABLE') {
      itemData.showType = 'お知らせ';
      return `/top-page/list-notify/notify-detail/${itemData?.id}`;
    } else {
      itemData.showType = 'お知らせ';
      return `/top-page/list-notify/${itemData?.productId}`;
    }
  };

  // IV. PROJECT
  const createResultLinkProject = () => {
    itemData.showType = '案件登録お申込み';
    if (roleType == 'solitonAdmin') {
      return `/manage-project-registration/edit/${itemData?.id}`;
    } else {
      return `/project-registration/detail/${itemData?.id}`;
    }
  };

  const showExplaination = (itemData) => {
    return (
      itemData?.type == 'CONTENT' &&
      itemData?.detailType == 'PRODUCT' &&
      itemData?.detailDisplayType != 'DETAIL'
    );
  };

  useEffect(() => {
    // Check if tag contains keyword
    let highlightedTag = createItemTag(itemData);
    const itemContent = itemData.content;
    const itemExplaination = itemData.explanation;
    const itemDisplayDate = itemData.displayDate;
    const itemFileName = itemData.fileName;
    // I. bold the match string
    // I.1. if explanation contains keyword and show explaination
    if (
      itemExplaination &&
      itemExplaination.toLowerCase().includes(keyword.toLowerCase()) &&
      showExplaination(itemData)
    ) {
      hightlightContent(itemExplaination, true);
    }
    // I.2. if content contains keyword and not show explaination
    else if (
      itemContent &&
      itemContent.toLowerCase().includes(keyword.toLowerCase()) &&
      !showExplaination(itemData)
    ) {
      hightlightContent(itemContent, true);
    }
    // I.3. if tag contains keyword
    else if (highlightedTag != '') {
      hightlightContent('タグ: ' + highlightedTag, false);
    }
    // I.4. if display_date contains keyword
    else if (
      itemDisplayDate &&
      itemDisplayDate.toLowerCase().includes(keyword.toLowerCase())
    ) {
      hightlightContent('表示日付: ' + itemDisplayDate, false);
    }
    // I.5. if file title contains keyword
    else if (
      itemFileName &&
      itemFileName.toLowerCase().includes(keyword.toLowerCase())
    ) {
      hightlightContent('ファイル名: ' + itemFileName, false);
    }
    // I.6. if no data match and not show explanation, show 1 part of content
    else if (itemContent && !showExplaination(itemData)) {
      createBriefContent(itemContent);
    }
    // I.7. else if no data match and show explanation, show 1 part of explanation
    else if (itemExplaination && showExplaination(itemData)) {
      createBriefContent(itemExplaination);
    }
    else {
      setIsShowDataContentHighLight(false);
      setDataContentBrief('');
    }
  }, [itemData]);

  const searhResultLink = useMemo(() => {
    // I.CONTENT
    if (itemData?.type == 'CONTENT') {
      return createResultLinkContent(itemData);
      // II. NOTIFY - TOPPAGE
    } else if (itemData?.type == 'NOTIFY_TOPPAGE') {
      return createResultLinkNotifyToppage(itemData);
      // III. NOTIFY - PRODUCT
    } else if (itemData?.type == 'NOTIFY_PRODUCT') {
      return createResultLinkNotifyProduct(itemData);
      // IV. PROJECT
    } else if (itemData?.type == 'PROJECT') {
      return createResultLinkProject(itemData);
      // V. MENU
    } else if (itemData?.type == 'MENU') {
      itemData.showType = 'メニュー';
      return `/top-page/top-page-product/${itemData?.productId}/top-page-detail-product/${itemData?.id}`;
    }
  }, [itemData]);

  return (
    <SearchStyleWrapper>
      <Link to={searhResultLink}>
        <TitleSearchStyle>
          {itemData.notifyTitleWithCategory || itemData.name}
        </TitleSearchStyle>
      </Link>
      <TypeSearchStyle>{itemData.showType}</TypeSearchStyle>

      {isShowDataContentHighLight ? (
        <SearchContentHighLight
          dataContentBefore={dataContentBefore}
          dataContentHighLight={dataContentHighLight}
          dataContentAfter={dataContentAfter}
        />
      ) : (
        <ContentSearchStyle>{dataContentBrief}</ContentSearchStyle>
      )}
    </SearchStyleWrapper>
  );
};
export default ItemOpenSearch;
