import React, { useEffect, useState } from 'react';
import {
  ButtonStyle,
  Grid1,
  Grid2,
} from 'page/Application/ManageCompanyList/component/Form/styled';
import FormLabelGrid from 'page/Application/ManageCompanyList/component/Form/FormLabelGrid';
import {
  FormAddPermissionWrapper,
  PartnerNameItems,
} from 'page/Application/ManageCompanyList/component/Form/FormAddProduct/styled';
import { listPartner, listSelectPartner } from 'recoil/atom/selectPartner';
import { useRecoilState } from 'recoil';
import useMultiLanguageRegisterAccount from 'page/Application/ManageAccountSoliton/RegisterAccountSoliton/hook/useMultiLanguageRegisterAccount';
import ModalListPartner from 'page/Application/ManageAccountSoliton/RegisterAccountSoliton/component/FormAddPartner/ModalListPartner';
import { Tooltip } from 'antd';

const FormAddPartner = ({ disableAddPartner }) => {
  const { REGISTER_ACCOUNT_ADD_PARTNER, REGISTER_ACCOUNT_ACCOUNT_ADD_PARTNER } =
    useMultiLanguageRegisterAccount();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [listSelectedPartner, setListSelectedPartner] =
    useRecoilState(listPartner);
  const [newListSelectedPartner, setNewListSelectedPartner] =
    useRecoilState(listSelectPartner);

  const handleOpenModal = () => {
    setIsModalOpen(true);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
    setNewListSelectedPartner(listSelectedPartner);
  };
  const compareRecordUpdatedTime = (a, b) => {
    if (a < b) return -1;
    if (a > b) return 1;
    return 0;
  };
  const handleAddPartner = () => {
    setIsModalOpen(false);
    const newRecordUpdatedTime = [...newListSelectedPartner?.listRecordPartner];
    newRecordUpdatedTime.sort((a, b) => {
      const recordUpdatedTimeA = new Date(Date.parse(a.updatedTime));
      const recordUpdatedTimeB = new Date(Date.parse(b.updatedTime));
      return compareRecordUpdatedTime(recordUpdatedTimeB, recordUpdatedTimeA);
    });
    setListSelectedPartner({
      ...newListSelectedPartner,
      listRecordPartner: newRecordUpdatedTime,
      display: true,
    });
  };

  // logic handle reset partner when chose role ソリトン管理者
  useEffect(() => {
    if (disableAddPartner) {
      setListSelectedPartner({
        display: false,
        listIdPartner: [],
        listRecordPartner: [],
      });

      setNewListSelectedPartner({
        display: false,
        listIdPartner: [],
        listRecordPartner: [],
      });
    }
  }, [disableAddPartner, setListSelectedPartner, setNewListSelectedPartner]);

  return (
    <>
      {
        <ModalListPartner
          isModalOpen={isModalOpen}
          handleCancel={handleCancel}
          handleOk={handleAddPartner}
        />
      }
      <Grid1>
        <FormLabelGrid
          labelText={REGISTER_ACCOUNT_ACCOUNT_ADD_PARTNER}
          required={false}
        />
      </Grid1>
      {listSelectedPartner?.listRecordPartner?.length > 0 && (
        <>
          <Grid2>
            <div
              style={{
                marginBottom: 20,
                paddingTop: 8,
                wordWrap: 'break-word',
                fontFamily: 'inter-regular, sans-serif',
              }}
            >
              {listSelectedPartner?.listRecordPartner.map((item) => {
                return (
                  <Tooltip
                    title={item.partnerName}
                    placement={'topLeft'}
                    key={item.key}
                    style={{ backgroundColor: 'white' }}
                  >
                    <PartnerNameItems>{item.partnerName}</PartnerNameItems>
                  </Tooltip>
                );
              })}
            </div>
          </Grid2>
        </>
      )}
      <Grid2>
        <FormAddPermissionWrapper>
          <ButtonStyle onClick={handleOpenModal} disabled={disableAddPartner}>
            {' '}
            {/*must have a span tag so there are no spaces between letters*/}
            <span>{REGISTER_ACCOUNT_ADD_PARTNER}</span>
          </ButtonStyle>
        </FormAddPermissionWrapper>
      </Grid2>
    </>
  );
};

export default FormAddPartner;
