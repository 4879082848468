import FormMessage from 'components/Form/FormMessage/index';
import React from 'react';
import { Form, Select } from 'antd';
import { SelectStyled } from 'page/Application/ManageProductAndService/Product/component/FormDropDown/styled';

const FormDropDown = ({
  name,
  required,
  data,
  messageRequired,
  width,
  onchange,
}) => {
  return (
    <Form.Item
      name={name}
      rules={[
        {
          required: required,
          message: (
            <FormMessage
              text1={messageRequired}
              marginBottom={'12px'}
            ></FormMessage>
          ),
        },
      ]}
    >
      <SelectStyled
        popupMatchSelectWidth={false}
        options={data}
        onChange={onchange}
        allowClear
        // style={{ width: width ?? '250px' }}
      />
    </Form.Item>
  );
};

export default FormDropDown;
