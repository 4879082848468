import styled from 'styled-components';

export const TitleModal = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
`;

export const IconTitle = styled.span`
  .anticon {
    font-size: 22px;
    color: #9d1c49;
  }
`;

export const Title = styled.span`
  font-weight: 400;
  font-family: inherit;
  color: #656464;
`;
