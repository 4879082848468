import { useIntl } from 'react-intl';

const useMultiLanguageEditCategory = () => {
  const intl = useIntl();

  const APPLICATION_REGISTERCATEGORY_EDIT_TITLE = intl.formatMessage({
    id: 'application.registerCategory.edit.title',
  });

  const APPLICATION_REGISTERCATEGORY_MESSAGE_EDIT_SUCCESS = intl.formatMessage({
    id: 'application.registerCategory.message.edit.success',
  });

  const APPLICATION_REGISTERCATEGORY_MESSAGE_DELETE_SUCCESS =
    intl.formatMessage({
      id: 'application.registerCategory.message.delete.success',
    });

  return {
    APPLICATION_REGISTERCATEGORY_EDIT_TITLE,
    APPLICATION_REGISTERCATEGORY_MESSAGE_EDIT_SUCCESS,
    APPLICATION_REGISTERCATEGORY_MESSAGE_DELETE_SUCCESS,
  };
};

export default useMultiLanguageEditCategory;
