import React from 'react';
import { FormItemStyled } from 'components/Form2/styled';
import isPropValid from '@emotion/is-prop-valid';
import { StyleSheetManager } from 'styled-components';

const FormItem = ({
  children,
  name,
  label,
  rules,
  width,
  marginBottom,
  extra,
  required,
}) => {
  // This implements the default behavior from styled-component v5
  const shouldForwardProp = (propName, target) => {
    if (typeof target === 'string') {
      // For HTML elements, forward the prop if it is a valid HTML attribute
      return isPropValid(propName);
    }
    // For other elements, forward all props
    return true;
  };

  return (
    <StyleSheetManager shouldForwardProp={shouldForwardProp}>
      <FormItemStyled
        extra={extra}
        name={name}
        label={label}
        rules={rules}
        colon={false}
        marginbottom={marginBottom}
        required={required ?? false}
        formwidth={width}
      >
        {children}
      </FormItemStyled>
    </StyleSheetManager>
  );
};

export default FormItem;
