import { useIntl } from 'react-intl';

const BtnSubmitFullSizeLanguage = () => {
  const intl = useIntl();

  const FORM_SIGN_IN_BUTTON_SUBMIT = intl.formatMessage({
    id: 'authentication.signIn.buttonSubmit',
  });

  const FORM_SIGN_IN_MOVE_TO_SIGN_UP = intl.formatMessage({
    id: 'authentication.signIn.moveToSignUp',
  });

  const FORM_FORGOT_PASSWORD_BUTTON_SUBMIT = intl.formatMessage({
    id: 'authentication.forgotPassword.buttonSubmit',
  });

  const FORM_FORGOT_PASSWORD_MOVE_TO_SIGN_IN = intl.formatMessage({
    id: 'authentication.forgotPassword.return',
  });

  const FORM_SIGN_UP_BUTTON_SUBMIT = intl.formatMessage({
    id: 'authentication.signUp.buttonRegistration',
  });

  const FORM_SIGN_UP_NAVIGATE_TO_LOGIN = intl.formatMessage({
    id: 'authentication.signUp.navigateToLogin',
  });

  const FORM_PASSWORD_BUTTON_SUBMIT = intl.formatMessage({
    id: 'changePassword.button.submit',
  });

  return {
    FORM_SIGN_IN_BUTTON_SUBMIT,
    FORM_SIGN_IN_MOVE_TO_SIGN_UP,

    FORM_FORGOT_PASSWORD_BUTTON_SUBMIT,
    FORM_FORGOT_PASSWORD_MOVE_TO_SIGN_IN,

    FORM_SIGN_UP_BUTTON_SUBMIT,
    FORM_SIGN_UP_NAVIGATE_TO_LOGIN,

    FORM_PASSWORD_BUTTON_SUBMIT,
  };
};

export default BtnSubmitFullSizeLanguage;
