import { useMemo } from 'react';

const useErrorCodeManageCategory = () => {
  const ERROR_CODE_MANAGE_CATEGORY = useMemo(() => {
    return {
      '400_CATEGORY_CANNOT_BE_DUPLICATED': {
        message: '重複するカテゴリ名があります。',
        statusCode: 400,
        type: 'error',
      },
      '400_CATEGORY_NOT_FOUND': {
        message: 'カテゴリが見つかりませんでした。',
        statusCode: 400,
        type: 'error',
      },
      '400_PRODUCT_EXISTED_IN_CATEGORY': {
        message: 'カテゴリに付くプロダクトがあるため、削除できません。',
        statusCode: 400,
        type: 'error',
      },
      '400_ARGUMENT_IS_NOT_VALID': {
        message: '入力データを正しく渡していません。',
        statusCode: 400,
        type: 'error',
      },
    };
  }, []);
  return {
    ERROR_CODE_MANAGE_CATEGORY,
  };
};

export default useErrorCodeManageCategory;
