import styled from 'styled-components';
import Background404Notfound from 'assets/images/backgroud404Notfound.jpg';
import { Button } from 'antd';
export const NotfoundWrapper = styled.div`
  background-image: url(${Background404Notfound});
  background-repeat: no-repeat;
  background-position: center;
  width: 100dvw;
  height: 100dvh;
  background-size: cover;
`;
export const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
`;
export const ButtonStyled = styled(Button)`
  margin: auto auto 70px auto;
`;
