import { useNavigate } from 'react-router-dom';
import { Dot, ProductContainer, ProductItem, TitleProduct } from './styled';
import { Col, Row, Tooltip } from 'antd';
import { useRecoilValue } from 'recoil';
import { network } from 'recoil/atom/network';


const ProductListTopPage = ({ listProduct, errorNetWork }) => {
  const navigate = useNavigate();
  const isOnline = useRecoilValue(network);

  const handleMoveToDetailProduct = (id) => {
    navigate(`/top-page/top-page-product/${id}`);
  };

  return (
    <ProductContainer>
      {!listProduct || listProduct === [] || listProduct.length === 0 ? (
        <div></div>
      ) : (
        <Row gutter={20}>
          {listProduct.map((item) => (
            <Col xs={24} sm={24} md={12} lg={8} xl={8} key={item.productId}>
              <ProductItem>
                <Dot />
                <TitleProduct
                  onClick={() => {
                    if (isOnline) {
                      handleMoveToDetailProduct(item.productId);
                    } else {
                      errorNetWork();
                    }
                  }}
                >
                  <Tooltip title={item.productName} placement={'topLeft'}>
                    <span>{item.productName}</span>
                  </Tooltip>
                </TitleProduct>
              </ProductItem>
            </Col>
          ))}
        </Row>
      )}
    </ProductContainer>
  );
};
export default ProductListTopPage;
