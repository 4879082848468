import FormMessage from 'components/Form/FormMessage/index';
import React from 'react';
import { Form, Input } from 'antd';

const FormTextRequired = ({
  message,
  required,
  maxLength,
  name,
  placeHolder,
  isDisabled,
}) => {
  return (
    <Form.Item
      name={name ?? 'name'}
      rules={[
        {
          validator: (_, value) => {
            const trimmedString = value?.trim();
            if (
              required &&
              (!value || trimmedString.length === 0 || value.trim() === '')
            ) {
              return Promise.reject(
                <FormMessage
                  text1={'情報を入力してください。'}
                  marginBottom={'12px'}
                ></FormMessage>,
              );
            }
            return Promise.resolve();
          },
        },
      ]}
    >
      <Input
        disabled={isDisabled}
        maxLength={maxLength}
        placeholder={placeHolder}
      />
    </Form.Item>
  );
};

export default FormTextRequired;
