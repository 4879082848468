import React from 'react';
import { BtnBreadcrumb } from './style';

const ButtonBreadcrumb = ({ text, onClick, icon, type }) => {
  return (
    <BtnBreadcrumb type={type} size="large" onClick={onClick} icon={icon}>
      <span> {text ? text : '編集'}</span>
    </BtnBreadcrumb>
  );
};

export default ButtonBreadcrumb;
