import React from 'react';
import { LabelFormResponsive } from 'page/Application/MyPage/component/FormMyPage/styled';

const LabelFormMyPage = ({ labelText, required }) => {
  return (
    <LabelFormResponsive>
      <span>{labelText}</span>
      {required && <span className="mark">*</span>}
    </LabelFormResponsive>
  );
};

export default LabelFormMyPage;
