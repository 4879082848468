import { useIntl } from 'react-intl';

const useMultiLanguageRegisterAccount = () => {
  const intl = useIntl();
  const SOLITON_ACCOUNT_LIST_TITLE = intl.formatMessage({
    id: 'application.RegisterAccountSoliton.title',
  });
  const REGISTER_ACCOUNT_TITLE = intl.formatMessage({
    id: 'application.RegisterAccount.title',
  });
  const REGISTER_ACCOUNT_COMPANY_NAME = intl.formatMessage({
    id: 'application.RegisterAccount.companyName',
  });
  const REGISTER_ACCOUNT_FIRSTNAME = intl.formatMessage({
    id: 'application.RegisterAccount.firstname',
  });
  const REGISTER_ACCOUNT_LASTNAME = intl.formatMessage({
    id: 'application.RegisterAccount.lastname',
  });
  const REGISTER_ACCOUNT_PHONE_NUMBER = intl.formatMessage({
    id: 'application.RegisterAccount.phoneNumber',
  });
  const REGISTER_ACCOUNT_DEPARTMENT = intl.formatMessage({
    id: 'application.RegisterAccount.department',
  });
  const REGISTER_ACCOUNT_POSITION = intl.formatMessage({
    id: 'application.RegisterAccount.position',
  });
  const REGISTER_ACCOUNT_DUTY = intl.formatMessage({
    id: 'application.RegisterAccount.duty',
  });
  const REGISTER_ACCOUNT_EMAIL = intl.formatMessage({
    id: 'application.RegisterAccount.email',
  });
  const REGISTER_ACCOUNT_PASSWORD = intl.formatMessage({
    id: 'application.RegisterAccount.password',
  });
  const REGISTER_ACCOUNT_ACCOUNT_ROLE = intl.formatMessage({
    id: 'application.RegisterAccount.accountRole',
  });
  const REGISTER_ACCOUNT_LOCATION = intl.formatMessage({
    id: 'application.RegisterAccount.location',
  });
  const REGISTER_ACCOUNT_ACCOUNT_ROLE_PARTNER_ADMIN = intl.formatMessage({
    id: 'application.RegisterAccount.accountRole.partnerAdmin',
  });
  const REGISTER_ACCOUNT_ACCOUNT_ROLE_PARTNER_USER = intl.formatMessage({
    id: 'application.RegisterAccount.accountRole.partnerUser',
  });
  const REGISTER_ACCOUNT_ACCOUNT_STATUS = intl.formatMessage({
    id: 'application.RegisterAccount.accountStatus',
  });
  const REGISTER_ACCOUNT_ACCOUNT_STATUS_APPROVED = intl.formatMessage({
    id: 'application.RegisterAccount.accountStatus.approved',
  });
  const REGISTER_ACCOUNT_ACCOUNT_STATUS_UNAPPROVED = intl.formatMessage({
    id: 'application.RegisterAccount.accountStatus.unapproved',
  });
  const REGISTER_ACCOUNT_ACCOUNT_STATUS_SUSPENDED = intl.formatMessage({
    id: 'application.RegisterAccount.accountStatus.suspended',
  });
  const REGISTER_ACCOUNT_ACCOUNT_ADD_PARTNER = intl.formatMessage({
    id: 'application.RegisterAccount.partnerAdd',
  });
  const REGISTER_ACCOUNT_ADD_PARTNER = intl.formatMessage({
    id: 'application.RegisterAccount.add',
  });
  const REGISTER_ACCOUNT_SUBMIT_SIGNUP = intl.formatMessage({
    id: 'application.RegisterAccount.submit.register',
  });
  const REGISTER_ACCOUNT_SUBMIT_EDIT = intl.formatMessage({
    id: 'application.RegisterAccount.submit.edit',
  });
  const REGISTER_ACCOUNT_SUBMIT = intl.formatMessage({
    id: 'application.RegisterAccount.submit',
  });
  const REGISTER_ACCOUNT_CHANGE = intl.formatMessage({
    id: 'application.RegisterAccount.change',
  });
  const REGISTER_ACCOUNT_DELETE = intl.formatMessage({
    id: 'application.RegisterAccount.delete',
  });
  const REGISTER_ACCOUNT_CANCEL = intl.formatMessage({
    id: 'application.RegisterAccount.cancel',
  });
  const REGISTER_ACCOUNT_PLACEHOLDER_EMAIL = intl.formatMessage({
    id: 'application.RegisterAccount.placeholder.email',
  });
  const REGISTER_ACCOUNT_PLACEHOLDER_LAST_NAME = intl.formatMessage({
    id: 'application.RegisterAccount.placeholder.lastName',
  });
  const REGISTER_ACCOUNT_PLACEHOLDER_FIRST_NAME = intl.formatMessage({
    id: 'application.RegisterAccount.placeholder.firstName',
  });
  const REGISTER_ACCOUNT_PLACEHOLDER_PHONE_NUMBER = intl.formatMessage({
    id: 'application.RegisterAccount.placeholder.phoneNumber',
  });
  const REGISTER_ACCOUNT_PLACEHOLDER_DEPARTMENT = intl.formatMessage({
    id: 'application.RegisterAccount.placeholder.department',
  });
  const REGISTER_ACCOUNT_PLACEHOLDER_POSITION = intl.formatMessage({
    id: 'application.RegisterAccount.placeholder.position',
  });
  const REGISTER_ACCOUNT_PLACEHOLDER_DUTY = intl.formatMessage({
    id: 'application.RegisterAccount.placeholder.duty',
  });
  const REGISTER_ACCOUNT_PLACEHOLDER_PASSWORD = intl.formatMessage({
    id: 'application.RegisterAccount.placeholder.password',
  });
  const REGISTER_ACCOUNT_CATEGORY_ENTRY_REQUIRED = intl.formatMessage({
    id: 'application.RegisterAccount.category.entry.required',
  });
  const REGISTER_ACCOUNT_EMAIL_ALREADY_EXISTED = intl.formatMessage({
    id: 'application.RegisterAccount.email.already.existed',
  });
  const REGISTER_ACCOUNT_REGISTER_SUCCESS = intl.formatMessage({
    id: 'application.RegisterAccount.register.success',
  });
  const REGISTER_ACCOUNT_REGISTER_EXPORT_FILE_NAME = intl.formatMessage({
    id: 'application.RegisterAccount.exportFile.name',
  });
  const REGISTER_ACCOUNT_REGISTER_EXPORT_FILE_MESSAGE_SUCCESS =
    intl.formatMessage({
      id: 'application.RegisterAccount.exportFile.message',
    });
  const REGISTER_ACCOUNT_INPUT_TYPE_VALIDATION = intl.formatMessage({
    id: 'application.RegisterAccount.inputType.validation.message',
  });
  const REGISTER_ACCOUNT_INPUT_TYPE_VALIDATION_EMAIL = intl.formatMessage({
    id: 'application.RegisterAccount.message.error.enter.partner.or.manager.email',
  });
  const REGISTER_ACCOUNT_MESSAGE_EMAIL_INPUT_TYPE = intl.formatMessage({
    id: 'application.RegisterAccount.inputTypeEmail.validation.message',
  });
  const REGISTER_ACCOUNT_CONFIRM_CANCEL_REGISTER = intl.formatMessage({
    id: 'application.RegisterAccount.confirm.cancelRegister',
  });
  const REGISTER_ACCOUNT_CONFIRM_CANCEL_EDIT = intl.formatMessage({
    id: 'application.EditCompany.confirm.cancelEdit',
  });
  const REGISTER_ACCOUNT_CONFIRM_DELETE_EDIT = intl.formatMessage({
    id: 'application.RegisterAccount.confirm.deleteEdit',
  });
  const DETAIL_ACCOUNT_TITLE = intl.formatMessage({
    id: 'application.DetailAccount.title',
  });
  const DETAIL_ACCOUNT_ROLE_PARTNER_TITLE = intl.formatMessage({
    id: 'application.DetailAccount.RolePartner.title',
  });
  const DETAIL_ACCOUNT_SOLITON_TITLE = intl.formatMessage({
    id: 'application.DetailAccountSoliton.title',
  });
  const DETAIL_ACCOUNT_COMPANY_NAME = intl.formatMessage({
    id: 'application.DetailAccount.companyName',
  });
  const DETAIL_ACCOUNT_EDIT_SUCCESS = intl.formatMessage({
    id: 'application.DetailAccount.edit.success',
  });
  const DETAIL_ACCOUNT_NEW_PASSWORD_MUST_DIFFERENT_FROM_OLD_PASSWORD =
    intl.formatMessage({
      id: 'application.DetailAccount.checkNewPassword.message',
    });
  const DETAIL_ACCOUNT_DELETE_ACCOUNT_SUCCESS = intl.formatMessage({
    id: 'application.DetailAccount.deleteAccount.message',
  });
  const REGISTER_ACCOUNT_MODAL_PARTNER_TITLE = intl.formatMessage({
    id: 'application.RegisterAccount.modalPartner',
  });
  const REGISTER_ACCOUNT_MODAL_SEARCH = intl.formatMessage({
    id: 'application.RegisterAccount.modalSearch',
  });
  const REGISTER_ACCOUNT_MODAL_PARTNER_TABLE = intl.formatMessage({
    id: 'application.RegisterAccount.modalTable.title',
  });
  const REGISTER_ACCOUNT_MODAL_SAVE = intl.formatMessage({
    id: 'application.RegisterAccount.modalSave',
  });
  const REGISTER_ACCOUNT_MODAL_CANCEL = intl.formatMessage({
    id: 'application.RegisterAccount.modalCancel',
  });

  return {
    REGISTER_ACCOUNT_MODAL_PARTNER_TITLE,
    DETAIL_ACCOUNT_DELETE_ACCOUNT_SUCCESS,
    REGISTER_ACCOUNT_MODAL_PARTNER_TABLE,
    REGISTER_ACCOUNT_MODAL_SEARCH,
    REGISTER_ACCOUNT_MODAL_SAVE,
    REGISTER_ACCOUNT_MODAL_CANCEL,
    SOLITON_ACCOUNT_LIST_TITLE,
    REGISTER_ACCOUNT_TITLE,
    DETAIL_ACCOUNT_SOLITON_TITLE,
    DETAIL_ACCOUNT_ROLE_PARTNER_TITLE,
    DETAIL_ACCOUNT_TITLE,
    REGISTER_ACCOUNT_COMPANY_NAME,
    REGISTER_ACCOUNT_FIRSTNAME,
    REGISTER_ACCOUNT_LASTNAME,
    REGISTER_ACCOUNT_PHONE_NUMBER,
    REGISTER_ACCOUNT_DEPARTMENT,
    REGISTER_ACCOUNT_POSITION,
    REGISTER_ACCOUNT_DUTY,
    REGISTER_ACCOUNT_EMAIL,
    REGISTER_ACCOUNT_PASSWORD,
    REGISTER_ACCOUNT_ACCOUNT_ROLE,
    REGISTER_ACCOUNT_LOCATION,
    REGISTER_ACCOUNT_ACCOUNT_ROLE_PARTNER_ADMIN,
    REGISTER_ACCOUNT_ACCOUNT_ROLE_PARTNER_USER,
    REGISTER_ACCOUNT_ACCOUNT_STATUS,
    REGISTER_ACCOUNT_ACCOUNT_STATUS_APPROVED,
    REGISTER_ACCOUNT_ACCOUNT_STATUS_UNAPPROVED,
    REGISTER_ACCOUNT_ACCOUNT_STATUS_SUSPENDED,
    REGISTER_ACCOUNT_ACCOUNT_ADD_PARTNER,
    REGISTER_ACCOUNT_ADD_PARTNER,
    REGISTER_ACCOUNT_CHANGE,
    REGISTER_ACCOUNT_SUBMIT,
    REGISTER_ACCOUNT_SUBMIT_EDIT,
    REGISTER_ACCOUNT_SUBMIT_SIGNUP,
    REGISTER_ACCOUNT_DELETE,
    REGISTER_ACCOUNT_CANCEL,
    REGISTER_ACCOUNT_PLACEHOLDER_EMAIL,
    REGISTER_ACCOUNT_PLACEHOLDER_LAST_NAME,
    REGISTER_ACCOUNT_PLACEHOLDER_FIRST_NAME,
    REGISTER_ACCOUNT_PLACEHOLDER_PHONE_NUMBER,
    REGISTER_ACCOUNT_PLACEHOLDER_DEPARTMENT,
    REGISTER_ACCOUNT_PLACEHOLDER_POSITION,
    REGISTER_ACCOUNT_PLACEHOLDER_DUTY,
    REGISTER_ACCOUNT_PLACEHOLDER_PASSWORD,
    REGISTER_ACCOUNT_CATEGORY_ENTRY_REQUIRED,
    REGISTER_ACCOUNT_EMAIL_ALREADY_EXISTED,
    REGISTER_ACCOUNT_REGISTER_SUCCESS,
    DETAIL_ACCOUNT_EDIT_SUCCESS,
    DETAIL_ACCOUNT_COMPANY_NAME,
    REGISTER_ACCOUNT_REGISTER_EXPORT_FILE_NAME,
    REGISTER_ACCOUNT_REGISTER_EXPORT_FILE_MESSAGE_SUCCESS,
    REGISTER_ACCOUNT_INPUT_TYPE_VALIDATION,
    REGISTER_ACCOUNT_INPUT_TYPE_VALIDATION_EMAIL,
    REGISTER_ACCOUNT_MESSAGE_EMAIL_INPUT_TYPE,
    REGISTER_ACCOUNT_CONFIRM_CANCEL_REGISTER,
    REGISTER_ACCOUNT_CONFIRM_CANCEL_EDIT,
    REGISTER_ACCOUNT_CONFIRM_DELETE_EDIT,
    DETAIL_ACCOUNT_NEW_PASSWORD_MUST_DIFFERENT_FROM_OLD_PASSWORD,
  };
};

export default useMultiLanguageRegisterAccount;
