import styled from 'styled-components';
import { Button } from 'antd';

export const FormFooterWrapper = styled.div`
  display: flex;
  gap: 24px;
`;

export const ButtonDelete = styled(Button)`
    background: #656464;
    color: rgba(255, 255, 255, 1);
    min-width: 88px;

    &:hover {
        color: white !important;
        border: 1px solid rgba(155, 155, 155, 0.1) !important;
        background: #656464 !important;
    }
`;

