import styled from 'styled-components';
import { Button } from 'antd';
import Search from 'antd/es/input/Search';
import { Upload } from 'antd';

export const GridForm = styled.div`
  display: grid;
  grid-template-columns: 210px 640px 100px;
  column-gap: 15px;
`;

export const GridForm2 = styled.div`
  display: grid;
  grid-template-columns: 0px 0px 113px;
`;

export const Grid1 = styled.div`
  padding-top: 9px;
  grid-column: 1 / 2;
`;

export const Grid2 = styled.div`
  grid-column: 2 / 3;
`;

export const Grid3 = styled.div`
  grid-column: 3/4;
`;

export const Grid4 = styled.div`
  padding-top: 9px;
  grid-column: 3/4;
`;

export const SearchStyled = styled(Search)`
  .ant-input-lg {
    line-height: 1.5;
  }
`;
export const ButtonStyle = styled(Button)`
  min-width: 88px;
  word-spacing: -3px;
  border: #656464 1px solid;
`;

export const ButtonPreview = styled(Button)`
  color: #9d1c49;
  min-width: 88px;
  &:hover {
    color: #ab3a5e !important;
  }
`;

export const SpanPreview = styled.span`
  text-decoration: underline;
  &:hover {
    text-decoration: none;
  }
`;

export const LabelFrom = styled.div`
  text-align: right;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-family: 'inter-regular', sans-serif;
  color: var(--FORM-LABEL-default);
  .mark {
    margin-left: 6px;
    //margin-right: 6px;
    color: #9d1c49;
  }
`;

export const UploadImg = styled(Upload)`
  .ant-upload-list-item-name {
    width: 550px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-family: 'Roboto', serif;
    color: #1890ff;
  }

  img {
    object-fit: contain;
  }

  .ant-upload-list-item-thumbnail {
    cursor: default;
  }
`;

export const FormMessage = styled.div`
  position: absolute;
  width: 640px;
  margin: 4px;
`;

export const TextNote = styled.div`
  color: #a5a5a5;
  font-family: 'inter-regular', sans-serif;
  font-weight: 400;
  font-size: 14px;
  margin-top: 5px;
`;
