import FormMessage from 'components/Form/FormMessage/index';
import React from 'react';
import { Form } from 'antd';
import { SelectStyled } from 'page/Application/ManageProductAndService/Product/component/FormDropDown/styled';

const FormDropDown = ({
  name,
  data,
  messageRequired,
  onchange,
  placeHolder,
}) => {
  return (
      <Form.Item
          name={name}
          rules={[
            {
              required: data.length > 0,
              message: (
                  <FormMessage
                      text1={messageRequired}
                      marginBottom={'12px'}
                  ></FormMessage>
              ),
            },
          ]}
          extra={
            <FormMessage
                text1={'所属されている企業を選択する前に、メールアドレスを入力してください。'}
            />
          }
      >
        <SelectStyled
            popupMatchSelectWidth={false}
            options={data}
            placeholder={placeHolder}
            onChange={onchange}
            disabled={data.length < 2}
            allowClear
        />
      </Form.Item>
  );
};

export default FormDropDown;
