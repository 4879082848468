import React from 'react';
import {
  GridFormMyPage,
  GridMyPageColumn2,
} from 'page/Application/MyPage/component/FormMyPage/styled';
import RowButton from 'components/Layout/RowButton';
import { ButtonOutline, ButtonPrimary } from 'components/Button';
import { Grid2to3, GridForm } from 'page/Application/MyPagePartnerAdmin/styled';

const ButtonSubmitForm = ({
  handleBack,
  isDisable,
  setIsDisable,
  handleConfirmAction,
}) => {
  return (
    <GridForm>
      <Grid2to3>
        <RowButton>
          <ButtonPrimary text={'保存'} width={120} />
          <ButtonOutline
            isDisabled={isDisable}
            text={'キャンセル'}
            onClick={() => {
              handleConfirmAction({
                confirmFn: handleBack,
                title: '変更をキャンセルしてもよろしいでしょうか？',
                cancelText: 'キャンセル',
                okText: 'はい',
                setIsDisable: setIsDisable,
              });
              setIsDisable(!isDisable);
            }}
          />
        </RowButton>
      </Grid2to3>
    </GridForm>
  );
};

export default ButtonSubmitForm;
