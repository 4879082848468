import styled from 'styled-components';
import { Table } from 'antd';

export const TableStyled = styled(Table)`

  .ant-table-container {
    min-height: calc(100dvh - 413px);
    // fix bug SPS-513 style font, weight, color for text
    font-family: 'inter-semibold', sans-serif;
    font-weight: 400;
    font-size: 14px;
    color: var(--CHECKBOX-default);
  }
  ::-webkit-scrollbar {
    width: 6px !important;
  }

  /* Track */

  ::-webkit-scrollbar-track {
    background: transparent !important;
  }

  /* Handle */

  ::-webkit-scrollbar-thumb {
    background: #d9d9d9 !important;
    border-radius: 2px !important;
  }

  /* Handle on hover */

  ::-webkit-scrollbar-thumb:hover {
    background: #555 !important;
  }
`;
