import { useIntl } from 'react-intl';

const useAccountType = () => {
  const intl = useIntl();
  const ROLE_SOLITON_ADMIN = intl.formatMessage({
    id: 'role.soliton.admin',
  });
  const ROLE_SOLITON_USER = intl.formatMessage({
    id: 'role.soliton.user',
  });
  const ROLE_PARTNER_ADMIN = intl.formatMessage({
    id: 'role.partner.admin',
  });
  const ROLE_PARTNER_USER = intl.formatMessage({
    id: 'role.partner.user',
  });

  const ACCOUNT_TYPE_SOLITON = [
    {
      value: 'ROLE_SOLITON_ADMIN',
      label: ROLE_SOLITON_ADMIN,
      title: '',
    },
    {
      value: 'ROLE_SOLITON_USER',
      label: ROLE_SOLITON_USER,
      title: '',
    },
  ];

  const ACCOUNT_TYPE_PARTNER = [
    {
      value: 'ROLE_PARTNER_ADMIN',
      label: ROLE_PARTNER_ADMIN,
      title: '',
    },
    {
      value: 'ROLE_PARTNER_USER',
      label: ROLE_PARTNER_USER,
      title: '',
    },
  ];

  return {
    ACCOUNT_TYPE_SOLITON,
    ACCOUNT_TYPE_PARTNER,
  };
};

export default useAccountType;
