import React, { useMemo } from 'react';
import { FormItem } from 'components/Form2';
import {
  FormWrapper,
  InputStyled,
  SelectStyled,
} from 'components/Form2/FormEmailSelectDomain/styled';
import {
  CHECK_CHARACTER_EMAIL,
  CHECK_EMAIL_NOT_TRIM,
  CHECK_LOCAL_PATH,
} from 'constant/regex';
import FormMessage from 'components/Form/FormMessage';
import useMultiLanguageRegisterAccount from 'page/Application/ManageAccountSoliton/RegisterAccountSoliton/hook/useMultiLanguageRegisterAccount';
import { useRecoilValue } from 'recoil';
import { userInformation } from 'recoil/atom/authentication';
import useMultiLanguage from 'hook/useMultiLanguage';

const FormEmailSelectDomain = ({ isEdit, domainList, onChange }) => {
  const { role } = useRecoilValue(userInformation);
  const FormatDomainList = useMemo(() => {
    if (!domainList && domainList.data.length === 0) return;
    return domainList.data?.map((item) => ({
      value: item.domainName,
      label: item.domainName,
    }));
  }, [domainList]);
  const {
    REGISTER_ACCOUNT_PLACEHOLDER_EMAIL,
    REGISTER_ACCOUNT_CATEGORY_ENTRY_REQUIRED,
    REGISTER_ACCOUNT_INPUT_TYPE_VALIDATION_EMAIL,
  } = useMultiLanguageRegisterAccount();

  const { FORM_EMAIL_ADDRESS_ERROR_MESSAGE_EMPTY } = useMultiLanguage();

  const checkRole = {
    ROLE_PARTNER_ADMIN: (
      <FormWrapper>
        <FormItem
          name="localPart"
          rules={[
            {
              required: true,
              message: (
                <FormMessage text1={REGISTER_ACCOUNT_CATEGORY_ENTRY_REQUIRED} />
              ),
            },
            () => ({
              validator(_, value) {
                if (
                  // !CHECK_BLANK.test(value) ||
                  // value.trim() === '' || // check blank
                  value.trim().includes(' ') === true || // only check blank between input values not check blank start or end,
                  value?.match(CHECK_LOCAL_PATH) !== null ||
                  (value &&
                    [...value].some((char) => char.codePointAt(0) > 65535)) // check 4-byte characters
                ) {
                  return Promise.reject(
                    <FormMessage
                      text1={REGISTER_ACCOUNT_INPUT_TYPE_VALIDATION_EMAIL}
                    />,
                  );
                } else {
                  return Promise.resolve();
                }
              },
            }),
          ]}
        >
          <InputStyled
            style={{ width: '210px' }}
            size={'Large'}
            placeholder={REGISTER_ACCOUNT_PLACEHOLDER_EMAIL}
            maxLength={64}
            autoComplete="off"
          />
        </FormItem>
        <FormItem>
          <div style={{ fontSize: '16px' }}>@</div>
        </FormItem>
        <FormItem
          name="domain"
          rules={[
            {
              required: true,
              message: (
                <FormMessage text1={REGISTER_ACCOUNT_CATEGORY_ENTRY_REQUIRED} />
              ),
            },
          ]}
        >
          <SelectStyled
            style={{ width: '150px' }}
            showSearch={true}
            disabled={isEdit}
            // placeholder="ドメインの選択"
            allowClear
            size={'Large'}
            autoComplete="off"
            options={FormatDomainList}
          />
        </FormItem>
      </FormWrapper>
    ),
    ROLE_SOLITON_ADMIN: (
      <FormItem
        name="email"
        rules={[
          {
            required: true,
            message: (
              <FormMessage
                text1={FORM_EMAIL_ADDRESS_ERROR_MESSAGE_EMPTY}
                marginBottom={'12px'}
              />
            ),
          },
          {
            pattern: CHECK_EMAIL_NOT_TRIM,
            message: (
              <FormMessage
                text1={REGISTER_ACCOUNT_INPUT_TYPE_VALIDATION_EMAIL}
                marginBottom={'12px'}
              />
            ),
          },
          {
            pattern: CHECK_CHARACTER_EMAIL,
            message: (
              <FormMessage
                text1={REGISTER_ACCOUNT_INPUT_TYPE_VALIDATION_EMAIL}
                marginBottom={'12px'}
              />
            ),
          },
        ]}
      >
        <InputStyled
          size={'Large'}
          placeholder={REGISTER_ACCOUNT_PLACEHOLDER_EMAIL}
          maxLength={256}
          autoComplete="off"
          onChange={onChange}
        />
      </FormItem>
    ),
  };

  return <>{checkRole[role]}</>;
};

export default FormEmailSelectDomain;
