import React, { useEffect, useMemo } from 'react';
import { listProduct, listSelectProduct } from 'recoil/atom/selectProduct';
import { useRecoilState } from 'recoil';
import AntdTableModal from 'components/AntdTableModal';
import useMultiLanguageRegisterNotification from 'page/Application/ManageNotificationList/RegisterNotification/hook/useMultiLanguageRegisterNotification';
import { listProductType } from 'page/Application/ManageCompanyList/component/ListProduct';
import { Tooltip } from 'antd';

/**
 * dataRaw: data get from api.
 * isLoading: render loading when api fetching.
 */

const TableListProduct = ({ dataRaw, isLoading, emptyText }) => {
  const [listSelectedProduct] = useRecoilState(listProduct);
  const [newListSelectedProduct, setNewListSelectedProduct] =
    useRecoilState(listSelectProduct);
  const { MODAL_PRODUCTS_TABLE_TITLE } = useMultiLanguageRegisterNotification();

  const columns = [
    {
      title: (
        <span style={{ fontSize: 16, fontWeight: 600 }}>
          {MODAL_PRODUCTS_TABLE_TITLE}
        </span>
      ),
      dataIndex: 'productName',
      render: (text, record) => {
        return (
          <div
            title={text}
            style={{
              whiteSpace: 'nowrap',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
            }}
          >
            <span style={{ marginRight: 4 }}>
              【{listProductType[record?.type]}】
            </span>
            <span>
              <Tooltip title={text} placement={'topLeft'}>
                {text}
              </Tooltip>
            </span>
          </div>
        );
      },
    },
  ];

  const rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      setNewListSelectedProduct({
        display: false,
        listIdProduct: selectedRowKeys,
        listRecordProduct: selectedRows,
      });
    },
    selectedRowKeys: newListSelectedProduct.listIdProduct,
    preserveSelectedRowKeys: true,
  };

  const dataSource = useMemo(() => {
    if (dataRaw && dataRaw.data && dataRaw.ec === false) {
      const listProduct = (dataRaw.data.listProduct ??= []);
      return listProduct.map((item) => ({
        ...item,
        key: item.id,
      }));
    }
  }, [dataRaw]);

  useEffect(() => {
    setNewListSelectedProduct(listSelectedProduct);
  }, [setNewListSelectedProduct, listSelectedProduct]);

  return (
    <AntdTableModal
      columns={columns}
      dataSource={dataSource}
      rowSelection={rowSelection}
      emptyText={emptyText}
      isLoading={isLoading}
    />
  );
};

export default TableListProduct;
