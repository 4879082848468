import styled from 'styled-components';
import { BaseTitle } from 'style/styleComponent/styled';
import { Button } from 'antd';
export const HtmlRaw = styled.div`
  width: auto;
  overflow-y: hidden;
  overflow-x: scroll;
  line-height: 20px;
  color: #656464;
  font-family: 'inter-regular', sans-serif;
  word-break: break-word;
  &::-webkit-scrollbar {
    width: 2px;
    height: 6px;
    cursor: grab;
  }

  &::-webkit-scrollbar-track {
    background: transparent;
  }

  &::-webkit-scrollbar-thumb {
    background: #ecececff;
    border-radius: 2px;
  }

  &::-webkit-scrollbar-thumb:hover {
    background: #c2c2c2ff;
  }
  ol {
    list-style: decimal;
    padding-left: 20px;
  }

  ul {
    list-style: initial;
    padding-left: 10px;
    margin-left: 10px;
  }
  li {
    list-style-position: inside;
    word-wrap: normal;
  }

  @media all and (min-width: 0px) and (max-width: 320px) {
    margin-top: 15px;
  }
  @media all and (min-width: 320px) and (max-width: 430px) {
    margin-top: 15px;
  }
`;

export const ProjectDetailTitle = styled(BaseTitle)`
  margin: 7px 0 15px 0;
  font-size: 20px;
  line-height: 1.4;
  word-break: break-word;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  @media all and (min-width: 0px) and (max-width: 320px) {
    font-size: 16px;
    -webkit-line-clamp: unset;
    -webkit-box-orient: unset;
    overflow: unset;
    margin-bottom: 12px;
  }
  @media all and (min-width: 320px) and (max-width: 430px) {
    font-size: 16px;
    margin-bottom: 12px;
    -webkit-line-clamp: unset;
    -webkit-box-orient: unset;
    overflow: unset;
  }
`;

export const FirstSubTitle = styled(BaseTitle)`
  margin-bottom: 20px;
`;

export const SubTitle = styled(BaseTitle)`
  margin-bottom: 20px;
`;

export const ButtonPrimaryOutline = styled(Button)`
  width: 300px;
  max-width: 300px;
  margin-bottom: 20px;
  height: 40px;
  border-radius: 8px;
  border: var(--PRIMARY-default) 1px solid;
  color: var(--BTN-TEXT-SECONDARY-default);
  font-size: 14px;
  padding: 7px 15px;
  font-family: 'inter-regular ', sans-serif;

  @media all and (min-width: 0px) and (max-width: 320px) {
    width: 100%;
    max-width: 100%;
  }
  @media all and (min-width: 320px) and (max-width: 430px) {
    width: 100%;
    max-width: 100%;
  }
`;

export const TextButton = styled.span`
  max-width: 250px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  line-height: 24px;
`;

export const TextFileTitleStyle = styled.div`
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;
