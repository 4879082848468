import React, { useEffect, useMemo, useState } from 'react';
import FormAccountSoliton from 'components/Form/Template/FormAccountSoliton';
import useAccountStatusMultiLanguage from 'hook/useAccountStatusMultiLanguage';
import useCustomMutateRenderMessage from 'hook/useQuery/useCustomMutateRenderMessage';
import {
  getDetailAccountPartner,
  deleteAccountPartner,
  updateAccountPartner,
} from 'api/account/partnerAccount.api';
import useCustomQuery from 'hook/useQuery/useCustomQuery';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { useRecoilState } from 'recoil';
import { listPartner } from 'recoil/atom/selectPartner';
import useMultiLanguageRegisterAccount from 'page/Application/ManageAccountSoliton/RegisterAccountSoliton/hook/useMultiLanguageRegisterAccount';
import LayoutDefault from 'components/Layout/LayoutDefault';
import Breadcrumb from 'components/Layout/ApplicationLayout/Breadcrumb';
import useErrorCodeManageAccountSoliton from 'page/Application/ManageAccountSoliton/hook/useErrorCodeManageAccountSoliton';

const EditAccountSoliton = () => {
  const { ACCOUNT_STATUS } = useAccountStatusMultiLanguage();
  const [toggleChangePassword, setToggleChangePassword] = useState(true);
  const { accountId } = useParams();
  const [role, setRole] = useState(ACCOUNT_STATUS.enable.value);
  const navigate = useNavigate();
  const [listSelected, setListSelectedPartner] = useRecoilState(listPartner);
  const { ERROR_CODE_MANAGE_ACCOUNT_SOLITON } =
    useErrorCodeManageAccountSoliton();
  const { errorMessage, useMutationAPIHandleErrorCode } =
    useCustomMutateRenderMessage({
      errorCode: ERROR_CODE_MANAGE_ACCOUNT_SOLITON,
    });
  const [isDisable, setIsDisable] = useState(false);
  const [isDisableDelete, setIsDisableDelete] = useState(false);
  const {
    DETAIL_ACCOUNT_EDIT_SUCCESS,
    DETAIL_ACCOUNT_SOLITON_TITLE,
    DETAIL_ACCOUNT_DELETE_ACCOUNT_SUCCESS,
  } = useMultiLanguageRegisterAccount();
  const { useQueryAPI } = useCustomQuery();

  const {
    data: accountSolitonDetail = [],
    isLoading,
    error,
  } = useQueryAPI({
    api: getDetailAccountPartner,
    key: 'accountSolitonDetail',
    params: {
      userId: accountId,
    },
    options: {
      refetchOnWindowFocus: false, // cancel fetch data when out focus
    },
  });
  const { mutate: deleteAccountSolitonMutate, isError } =
    useMutationAPIHandleErrorCode({
      api: deleteAccountPartner,
      refetchKey: 'accountListSoliton',
      path: '/manage-account-soliton',
      messageSuccess: DETAIL_ACCOUNT_DELETE_ACCOUNT_SUCCESS,
    });

  const {
    mutate: updateAccountSolitonMutate,
    isLoading: isLoadingSubmit,
    isSuccess: isSuccessSubmit,
  } = useMutationAPIHandleErrorCode({
    api: updateAccountPartner,
    refetchKey: 'accountListSoliton',
    path: '/manage-account-soliton',
    messageSuccess: DETAIL_ACCOUNT_EDIT_SUCCESS,
  });
  const handleDeleteAccountSoliton = () => {
    deleteAccountSolitonMutate({
      listUserId: [accountId],
      isPartner: false,
      isAll: false,
    });
  };

  useEffect(() => {
    if (
      error?.response?.data?.message === '401_USER_NOT_FOUND_TO_GET' ||
      error?.response?.data?.message === '400_ARGUMENT_IS_NOT_VALID'
    ) {
      navigate('/user-not-found');
    }
    if (accountSolitonDetail && accountSolitonDetail.data) {
      setRole(accountSolitonDetail.data.approveStatus);
      const listSelectPartner = accountSolitonDetail?.data?.partnerInCharge;
      const listId = listSelectPartner?.map((item) => item?.partnerId);
      const listPartner = listSelectPartner?.map((item) => ({
        // format list partner for render table
        ...item,
        key: item?.partnerId,
      }));
      setListSelectedPartner({
        listIdPartner: listId ?? [],
        listRecordPartner: listPartner ?? [],
      });
    }
  }, [
    accountSolitonDetail,
    error?.response?.data?.message,
    navigate,
    setListSelectedPartner,
  ]);
  const onFinish = (value) => {
    const payload = {
      ...value,
      approveStatus: role,
      partnerInCharge: listSelected?.listIdPartner,
    };
    updateAccountSolitonMutate({
      userId: accountId,
      payload: payload,
    });
  };

  const listItem = useMemo(() => {
    return [
      {
        title: <Link to={'/'}>企業管理</Link>,
      },
      {
        title: (
          <Link to={'/manage-account-soliton'}>ソリトンアカウント管理</Link>
        ),
      },
      {
        title: 'ソリトンアカウント詳細',
      },
    ];
  }, []);

  return (
    <>
      <Breadcrumb listItem={listItem} titlePage="ソリトンアカウント詳細" />
      <LayoutDefault>
        <FormAccountSoliton
          titleForm={DETAIL_ACCOUNT_SOLITON_TITLE}
          isLoading={isLoading}
          isEdit={true}
          domainList={[]}
          onFinish={onFinish}
          role={role}
          isDisable={isDisable}
          setIsDisable={setIsDisable}
          isDisableDelete={isDisableDelete}
          setIsDisableDelete={setIsDisableDelete}
          isLoadingSubmit={isLoadingSubmit}
          setRole={setRole}
          errorMessage={errorMessage}
          isError={isError}
          setListSelectedPartner={setListSelectedPartner}
          formDetailData={accountSolitonDetail}
          toggleChangePassword={toggleChangePassword}
          setToggleChangePassword={setToggleChangePassword}
          handleDeleteAccountPartner={handleDeleteAccountSoliton}
          type={'text'}
          isSuccessSubmit={isSuccessSubmit}
        />
      </LayoutDefault>
    </>
  );
};

export default EditAccountSoliton;
