import { Form } from 'antd';
import { CheckboxStyled } from 'components/Checkbox/styled';
import React from 'react';

const FormCheckBox = ({ name, text }) => {
  return (
    <Form.Item
      name={name}
      valuePropName="checked"
      style={{ marginBottom: '4px' }}
    >
      <CheckboxStyled size={'14px'}>{text}</CheckboxStyled>
    </Form.Item>
  );
};

export default FormCheckBox;
