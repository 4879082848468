import React from 'react';
import ItemOpenSearch from '../ItemOpenSearch';
import { Empty, List } from 'antd';
import { ListHover } from './styled';
import { InboxOutlined } from '@ant-design/icons';

const TableNotifyOpenSearch = ({ dataOpenSearch, keyword, roleType }) => {
  return dataOpenSearch?.length === 0 ? (
    <Empty
      image={<InboxOutlined />}
      imageStyle={{ fontSize: 80 }}
      description={null}
      style={{
        fontSize: '16px',
        minHeight: 532,
        color: 'var(--FORM-LABEL-default)',
      }}
    >
      データが見つかりませんでした。
    </Empty>
  ) : (
    <List
      size="large"
      dataSource={dataOpenSearch}
      style={{ minHeight: 'calc(100dvh - 413px)' }}
      renderItem={(item) => (
        <ListHover>
          <List.Item
            style={{
              padding: '10px 0',
            }}
          >
            <ItemOpenSearch
              itemData={item}
              keyword={keyword}
              roleType={roleType}
            />
          </List.Item>
        </ListHover>
      )}
    />
  );
};

export default TableNotifyOpenSearch;
