import React from 'react';
import { LabelFrom } from 'page/Application/ManageAccountSoliton/RegisterAccountSoliton/styled';

const Index = ({ labelText, required }) => {
  return (
    <LabelFrom>
      <span>{labelText}</span>
      {required && <span className="mark">*</span>}
    </LabelFrom>
  );
};

export default Index;
