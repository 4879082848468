import React, { useEffect, useState } from 'react';
import TreeSkeleton from 'components/Tree/components/TreeSkeleton';
import useTraverseTree from 'components/Tree/hook/useTraverseTree';
import TreeItem from 'components/Tree/TreeItem';
import { useRecoilState } from 'recoil';
import { Alert } from 'antd';
import { TreeContainer, TreeWrapper } from 'components/Tree/styled';
import { errorMessage } from 'recoil/atom/tree';

const Tree = ({
  treeData,
  setTreeData,
  isLoading,
  // width,
  background,
  masterDoomWidth,
  showDropdown,
  setShowDropdown,
}) => {
  const [error, setError] = useRecoilState(errorMessage);
  const [widthDragParent, setWidthDragParent] = useState(false);
  const [widthDragChildren, setWidthDragChildren] = useState(false);
  const [dragMode, setDragMode] = useState(true);
  const [parentUuIdSelected, setParentUuIdSelected] = useState(null);

  const {
    insertNode,
    updateNode,
    insertAndUpdateNode,
    deleteNode,
    updatePositionNode,
  } = useTraverseTree();

  const handleInsertNode = (itemId, itemName, isParent) => {
    const finalTree = insertNode(treeData, itemId, itemName, isParent);
    setTreeData(finalTree);
  };

  const handleUpdateNode = (item, newData) => {
    const finalTree = updateNode(treeData, item, newData);
    setTreeData(finalTree);
  };

  const handleDeleteNode = (item) => {
    const finalTree = deleteNode(treeData, item);
    setTreeData(finalTree);
  };

  const handleInsertAndUpdateNode = (itemParent, itemChildren) => {
    const finalTree = insertAndUpdateNode(treeData, itemParent, itemChildren);
    setTreeData(finalTree);
  };

  const handleUpdatePositionNode = (item, index) => {
    const finalTree = updatePositionNode(
      treeData,
      item,
      index,
      parentUuIdSelected,
    );
    setTreeData(finalTree);
  };

  useEffect(() => {
    // clear error tree data
    setError(false);
  }, [setError]);

  return (
    <>
      {isLoading ? (
        <TreeSkeleton />
      ) : (
        <>
          {error && (
            <Alert message="重複するメニュー名があります。" type="error" />
          )}
          <TreeWrapper>
            <TreeContainer>
              <TreeItem
                item={treeData}
                setItem={setTreeData}
                background={background}
                masterDoomWidth={masterDoomWidth}
                showDropdown={showDropdown}
                setShowDropdown={setShowDropdown}
                widthDragParent={widthDragParent}
                setWidthDragParent={setWidthDragParent}
                widthDragChildren={widthDragChildren}
                setWidthDragChildren={setWidthDragChildren}
                dragMode={dragMode}
                setDragMode={setDragMode}
                setParentUuIdSelected={setParentUuIdSelected}
                handleInsertNode={handleInsertNode}
                handleUpdateNode={handleUpdateNode}
                handleDeleteNode={handleDeleteNode}
                handleInsertAndUpdateNode={handleInsertAndUpdateNode}
                handleUpdatePositionNode={handleUpdatePositionNode}
              />
            </TreeContainer>
          </TreeWrapper>
        </>
      )}
    </>
  );
};

export default Tree;
