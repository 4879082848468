import React, { useEffect } from 'react';
import useMultiLanguageRegisterProduct from '../../RegisterProduct/hook/useMultiLanguageRegisterProduct';
import { treeStatus } from 'recoil/atom/tree';
import { ButtonDelete, FormFooterWrapper } from './styled';
import { ButtonStyle } from '../styled';
import { useRecoilState } from 'recoil';

const FormFooterSubmit = ({
  textOk,
  textCancel,
  textDelete,
  onCancel,
  onFinish,
  onDelete,
  isDelete,
  isLoading,
  isDisable,
  isCancel,
}) => {
  const [, setStatus] = useRecoilState(treeStatus);

  const { APPLICATION_PRODUCT_BUTTON_DELETE } =
    useMultiLanguageRegisterProduct();

  useEffect(() => {
    setStatus({
      editing: false,
    });
  }, []);

  return (
    <FormFooterWrapper>
      <ButtonStyle
        type="primary"
        htmlType={'submit'}
        onClick={onFinish}
        loading={isLoading}
        disabled={isDisable}
      >
        {/*must have a span tag so there are no spaces between letters*/}
        <span> {textOk}</span>
      </ButtonStyle>
      {isDelete && (
        <ButtonDelete
          disabled={isDisable}
          onClick={onDelete}
          loading={isLoading}
        >
          {/*must have a span tag so there are no spaces between letters*/}
          <span> {textDelete ?? APPLICATION_PRODUCT_BUTTON_DELETE}</span>
        </ButtonDelete>
      )}
      <ButtonStyle disabled={isCancel} onClick={onCancel}>
        {/*must have a span tag so there are no spaces between letters*/}
        <span> {textCancel}</span>
      </ButtonStyle>
    </FormFooterWrapper>
  );
};

export default FormFooterSubmit;
