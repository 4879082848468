import React, { useMemo, useState } from 'react';
import { FormItemChangePassword } from 'components/Form';
import { useForm } from 'antd/es/form/Form';
import LoadingSpin from 'components/LoadingSpin';
import { useMutation } from 'react-query';
import { changePassword } from 'api/authentication/authen.api';
import useErrorCodeForgotPassword from 'page/Authentication/ForgotPassword/hook/useErrorCodeForgotPassword';
import useCheckErrorCode from 'hook/useCheckErrorCode';
import Logo from 'components/Logo';
import useMultiLanguagePasswordExpire from 'page/Application/PasswordExpire/hook/useMultiLanguagePasswordExpire';
import { useRecoilState, useRecoilValue } from 'recoil';
import { Alert, Form } from 'antd';
import {
  BackgroundAuthentication,
  ButtonSubmit,
  TitleAuthentication,
} from 'page/Authentication/styled';
import {
  ContentCenter,
  MarginTop,
} from 'page/Application/PasswordExpire/styled';
import {
  authenticationToken,
  messageFeedback,
  passwordExpire,
} from 'recoil/atom/authentication';
import { FormItemStyled } from 'components/Form/styled';

const PasswordExpire = () => {
  const {
    TITLE_WARNING_USER_1,
    TITLE_WARNING_USER_2,
    TITLE_WARNING_USER_3,
    TITLE_CHANGE_PASSWORD_1,
    TITLE_CHANGE_PASSWORD_2,
    BUTTON_CHANGE_PASSWORD,
    BUTTON_SKIP,
    MESSAGE_CHANGE_PASSWORD_SUCCESS,
  } = useMultiLanguagePasswordExpire();
  const [formPasswordExpire] = useForm();
  const [loading, setLoading] = useState(false);
  const { passwordExpiredRemainingDay } = useRecoilValue(authenticationToken);
  const [, setShowChangePassword] = useRecoilState(passwordExpire);
  const [, setShowMessageFeedback] = useRecoilState(messageFeedback);
  const { ERROR_CODE_FORGOT_PASSWORD } = useErrorCodeForgotPassword();
  const { handleClearErrorMessage, handleRenderMessage, errorMessage } =
    useCheckErrorCode({ errorCode: ERROR_CODE_FORGOT_PASSWORD });

  // call API submit data from form
  const { mutate, isError } = useMutation({
    mutationFn: changePassword,
    onSuccess: () => {
      setLoading(false);
      handleClearErrorMessage();
      setShowMessageFeedback({
        display: true,
        type: 'success',
        content: MESSAGE_CHANGE_PASSWORD_SUCCESS,
      });
      setShowChangePassword(false);
      localStorage.clear();
      window.location.reload();
    },
    onError: (response) => {
      setLoading(false);
      handleRenderMessage(response);
    },
  });

  // submit form
  const onFinish = ({ password, verifyPassword }) => {
    setLoading(true);
    if (password === verifyPassword) {
      const payload = {
        newPassword: verifyPassword,
      };
      mutate(payload);
    }
  };

  const calcRemainingDay = useMemo(() => {
    if (passwordExpiredRemainingDay < 10 && passwordExpiredRemainingDay > 0) {
      return `0` + passwordExpiredRemainingDay.toString();
    } else return passwordExpiredRemainingDay;
  }, [passwordExpiredRemainingDay]);

  // check password expire date for render title and button skip
  const RenderUIChangePassword = useMemo(() => {
    // expire date = 0 day or expire date < 0 days
    // server can return negative number, exp: 0, -23, -34, -6...
    if (passwordExpiredRemainingDay <= 0) {
      return {
        skip: false, // hide button skip
        title: (
          <TitleAuthentication className="title-password-expire">
            {TITLE_CHANGE_PASSWORD_1}
            <br />
            {TITLE_CHANGE_PASSWORD_2}
          </TitleAuthentication>
        ),
      };
    } else {
      // 14 > expire date > 0 days
      return {
        skip: true, // show button skip
        title: (
          <TitleAuthentication className="title-password-expire">
            {TITLE_WARNING_USER_1 + calcRemainingDay + TITLE_WARNING_USER_2}
            <br />
            {TITLE_WARNING_USER_3}
          </TitleAuthentication>
        ),
      };
    }
  }, [
    passwordExpiredRemainingDay,
    TITLE_WARNING_USER_1,
    TITLE_WARNING_USER_2,
    TITLE_WARNING_USER_3,
    TITLE_CHANGE_PASSWORD_1,
    TITLE_CHANGE_PASSWORD_2,
  ]);

  return (
    <BackgroundAuthentication>
      <div className="container">
        <ContentCenter className="row">
          <div>
            <div className="logo-soliton">
              <Logo />
            </div>
            <div className="form-responsive">
              {RenderUIChangePassword.title}
              {isError && (
                <Alert
                  className="alert"
                  message={errorMessage}
                  type="error"
                  style={{ marginBottom: '24px' }}
                />
              )}
              <Form
                className="size"
                name="password-expire"
                layout={'vertical'}
                onFinish={onFinish}
                form={formPasswordExpire}
              >
                <FormItemChangePassword
                  className="password-expire"
                  form={formPasswordExpire}
                />
                <FormItemStyled>
                  <ButtonSubmit
                    className="button-responsive"
                    type="primary"
                    block
                    htmlType="submit"
                    disabled={loading}
                  >
                    {loading ? <LoadingSpin /> : BUTTON_CHANGE_PASSWORD}
                  </ButtonSubmit>
                  {RenderUIChangePassword.skip && (
                    <MarginTop>
                      <ButtonSubmit
                        className="button-responsive"
                        block
                        onClick={() => setShowChangePassword(false)}
                      >
                        {BUTTON_SKIP}
                      </ButtonSubmit>
                    </MarginTop>
                  )}
                </FormItemStyled>
              </Form>
            </div>
          </div>
        </ContentCenter>
      </div>
    </BackgroundAuthentication>
  );
};

export default PasswordExpire;
