import React from 'react';
import { Outlet } from 'react-router-dom';
import {
  AuthSidebar,
  BackgroundAuthentication,
  Container,
  TextOnSidebar,
} from 'page/Authentication/styled';
import { Col, Row } from 'antd';
import Logo from 'components/Logo';
import 'page/Authentication/style/style.scss';

const Authentication = () => {
  return (
    <BackgroundAuthentication className="background-authentication">
      <Container className="container">
        <Row className="row">
          <Col xs={0} sm={0} md={0} lg={14} xl={15}>
            <AuthSidebar className="authSidebar">
              <TextOnSidebar className="textOnSidebar">
                <span className="textStyle1">we are</span>
                <br />
                soliton
              </TextOnSidebar>
            </AuthSidebar>
          </Col>
          <Col xs={24} sm={24} md={24} lg={10} xl={9} className="form-scroll">
            <div className="form-container">
              <div className="logo-soliton">
                <Logo />
              </div>
              <Outlet />
            </div>
          </Col>
        </Row>
      </Container>
    </BackgroundAuthentication>
  );
};

export default Authentication;
