import React, { useRef, useState } from 'react';
import isPropValid from '@emotion/is-prop-valid';
import ChildrenMenu from 'components/Tree/components/Dropdown/ChildrenMenu';
import RootMenu from 'components/Tree/components/Dropdown/RootMenu';
import ModalConfirmDelete from 'components/Tree/components/ModalConfirmDelete';
import { useParams } from 'react-router-dom';
import { useRecoilState, useRecoilValue } from 'recoil';
import { StyleSheetManager } from 'styled-components';
import { CaretRightOutlined, HolderOutlined } from '@ant-design/icons';
import {
  errorMessage,
  positionItemDragEnter,
  treeStatus,
  inputChange,
} from 'recoil/atom/tree';
import { Tooltip } from 'antd';
import {
  ButtonForm,
  DragButton,
  DragChildrenContainer,
  DragParentContainer,
  ExpandButton,
  FormChildren,
  FormParent,
  InputCreate,
  InputEdit,
  ItemChildren,
  ItemCreateUpdateMode,
  ItemParent,
  TitleTree,
  TreeItemChildren,
  TreeItemParent,
} from 'components/Tree/styled';

// This implements the default behavior from styled-components v5
const shouldForwardProp = (propName, target) => {
  if (typeof target === 'string') {
    // For HTML elements, forward the prop if it is a valid HTML attribute
    return isPropValid(propName);
  }
  // For other elements, forward all props
  return true;
};

const PLACEHOLDER = 'メニュー';
const BUTTON_ADD = '保存';
const BUTTON_UPDATE = '保存';
const BUTTON_CANCEL = 'キャンセル';

const TreeItem = ({
                    item,
                    index,
                    handleInsertNode,
                    handleUpdateNode,
                    handleDeleteNode,
                    handleInsertAndUpdateNode,
                    handleUpdatePositionNode,
                    showDropdown,
                    setShowDropdown,
                    widthDragParent,
                    setWidthDragParent,
                    widthDragChildren,
                    setWidthDragChildren,
                    dragMode,
                    setDragMode,
                    // width,
                    background,
                    // masterDoomWidth,
                    setParentUuIdSelected,
                  }) => {
  const [expand, setExpand] = useState(true);
  const [editMode, setEditMode] = useState(false);
  const [createMode, setCreateMode] = useState(false);
  const [inputValue, setInputValue] = useState('');

  const [isDragging, setIsDragging] = useState(false);
  const [isDragOVer, setIsDragOVer] = useState(false);
  const [isHover, setIsHover] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const { productId } = useParams();

  const error = useRecoilValue(errorMessage);
  const [, setPositionItemEnter] = useRecoilState(positionItemDragEnter);
  const [, setStatus] = useRecoilState(treeStatus);
  const [, setValueChange] = useRecoilState(inputChange);

  let treeItemDrag = useRef();

  const handleExpandItem = () => {
    setExpand(!expand);
  };

  const handleEnableEditMode = () => {
    setEditMode(true);
    setCreateMode(false);
    setStatus({
      editing: true, // set editing === true to cancel submit form when user press enter on keyboard
    });
    setInputValue(item.menuName);
    setShowDropdown(false);
    setDragMode(false);
  };

  const handleEnableCreateMode = () => {
    setEditMode(false);
    setCreateMode(true);
    setStatus({
      editing: true, // set editing === true to cancel submit form when user press enter on keyboard
    });
    setShowDropdown(false);
    setDragMode(false);
  };

  const handleCancel = () => {
    setEditMode(false);
    setCreateMode(false);
    setValueChange(false);
    setStatus({
      editing: false, // set editing === false to submit form when user press enter on keyboard
    });
    setShowDropdown(true);
    setDragMode(true);
    setInputValue('');
    setIsHover(false);
  };

  const handleSubmitItem = () => {
    let isParent = false;
    if (inputValue.length > 0) {
      handleInsertNode(item.menuUuid, inputValue.trim(), isParent);
      handleCancel();
      setIsHover(false);
      setInputValue('');
    }
  };

  const handleUpdateItem = () => {
    if (inputValue.length > 0) {
      handleUpdateNode(item, inputValue.trim());
      handleCancel();
      setIsHover(false);
      setInputValue('');
    }
  };

  const handleInsertAndUpdateChildrenToParent = () => {
    handleInsertAndUpdateNode(item, inputValue);
    handleCancel();
  };

  const showModalConfirm = () => {
    setIsModalOpen(true);
  };
  const handleOk = () => {
    handleDeleteNode(item);
    setIsModalOpen(false);
  };
  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const handleSetValueToInput = (event) => {
    setInputValue(event.target.value);
    setValueChange(true);
  };

  const dragStart = (event, index) => {
    treeItemDrag.current = index;
    setIsDragging(true);
  };

  const dragOver = (event) => {
    event.preventDefault();
  };

  const dragEnter = (event, index) => {
    setParentUuIdSelected(item.parentUuid);
    setPositionItemEnter(index);
    setIsDragOVer(true);
  };

  const dragLeave = () => {
    setIsDragOVer(false);
  };

  const dragEnd = () => {
    setIsDragging(false);
    handleUpdatePositionNode(item, index);
  };

  const showMenuDropdown = () => {
    if (createMode === false || editMode === false) {
      setIsHover(true);
    }
  };

  const hideMenuDropdown = () => {
    setIsHover(false);
  };

  const showDragOverlay = () => {
    if (createMode || editMode) return;
    setWidthDragParent(true);
    setWidthDragChildren(false);
  };

  const hideDragOverlay = () => {
    if (isDragOVer || isDragging) return;
    setWidthDragParent(false);
  };

  const showDragOverlayChildren = () => {
    if (createMode || editMode) return;
    setWidthDragChildren(true);
    setWidthDragParent(false);
  };

  const hideDragOverlayChildren = () => {
    if (isDragOVer || isDragging) return;
    setWidthDragChildren(false);
  };

  const handleFilterMenu = () => {
    const currentUrl = window.location.origin;
    window.open(
      `${currentUrl}/#/content?productId=${productId}&menuId=${encodeURIComponent(
        item.menuId,
      )}`,
      '_blank',
    );
  };

  if (item && item.isParent) {
    return (
      // parent item
      <>
        <ModalConfirmDelete
          handleOk={handleOk}
          isModalOpen={isModalOpen}
          handleCloseModal={handleCloseModal}
        />
        <StyleSheetManager shouldForwardProp={shouldForwardProp}>
          {/* start tree item */}
          <TreeItemParent
            className={isDragging ? 'dragging' : ''}
            left={item.level !== 'MasterDoom' ? '46px' : '0'}
            // width={
            //   width
            //     ? item.level === 'MasterDoom' && masterDoomWidth
            //       ? masterDoomWidth
            //       : width
            //     : null
            // }
          >
            {/* start drag and drop */}
            {/* only children can drag and drop */}
            {item.level !== 'MasterDoom' && (
              <>
                {dragMode && (
                  <DragParentContainer
                    draggable={true}
                    onMouseOver={showDragOverlay}
                    onMouseLeave={hideDragOverlay}
                    className={`drag-element menuUuid--${item.menuUuid} parentUuid--${item.parentUuid} menuName--${item.menuName}`}
                    widthOverlay={widthDragParent ? '372px' : '40px'}
                    onDragStart={(event) => dragStart(event, index)}
                    onDragOver={(event) => dragOver(event, index)}
                    onDragLeave={(event) => dragLeave(event, index)}
                    onDragEnter={(event) => dragEnter(event, index)}
                    onDragEnd={(event) => dragEnd(event, index)}
                  />
                )}
                <DragButton className={isDragging ? 'dragging' : ''}>
                  <HolderOutlined />
                </DragButton>
              </>
            )}

            {/* end drag and drop */}

            {/* start expand children */}
            <ExpandButton
              onClick={handleExpandItem}
              onMouseEnter={showDragOverlay}
            >
              {item.children?.length > 0 ? (
                <CaretRightOutlined
                  className={expand ? 'open' : 'close'}
                  style={{ color: '#505050' }}
                />
              ) : (
                <CaretRightOutlined style={{ color: '#cdcdcd' }} />
              )}
            </ExpandButton>

            {/* end expand children */}

            {/* start show / hide input update */}
            {editMode ? (
              // <div style={{ marginLeft: '38px' }}>
                <ItemCreateUpdateMode className='edit-parent'>
                  <InputEdit
                    autoFocus={true}
                    placeholder={PLACEHOLDER}
                    value={inputValue}
                    maxLength={40}
                    onChange={(event) => handleSetValueToInput(event)}
                  />
                  <ButtonForm
                    onClick={handleUpdateItem}
                    disabled={inputValue.trim().length === 0}
                  >
                    {''}
                    {BUTTON_UPDATE}
                  </ButtonForm>
                  <ButtonForm onClick={handleCancel}>
                    {''}
                    {BUTTON_CANCEL}
                  </ButtonForm>
                </ItemCreateUpdateMode>
              // </div>
            ) : (
              <ItemParent
                onMouseEnter={showMenuDropdown}
                onMouseLeave={hideMenuDropdown}
                className={isDragging ? 'dragging' : 'no-drag'}
                background={background}
              >
                <Tooltip title={item.menuName} placement="topLeft">
                  <TitleTree>{item.menuName}</TitleTree>
                </Tooltip>
                {showDropdown && (
                  <>
                    {item.level === 'MasterDoom' ? (
                      <RootMenu
                        onCreateMode={handleEnableCreateMode}
                        showMenu={isHover}
                        onFilterMenu={handleFilterMenu}
                      />
                    ) : (
                      <ChildrenMenu
                        filterEnable={!!item.menuId}
                        showMenu={isHover}
                        onCreateMode={handleEnableCreateMode}
                        onEditMode={handleEnableEditMode}
                        onDelete={showModalConfirm}
                        onFilterMenu={handleFilterMenu}
                      />
                    )}
                  </>
                )}
              </ItemParent>
            )}
          </TreeItemParent>

          {/* placeholder when drag item*/}
          {/*<ItemParentDropPosition*/}
          {/*  className={isDragOVer ? 'drag-over' : 'drag-leave'}*/}
          {/*  width={width}*/}
          {/*/>*/}
          {/* end tree item */}

          {/* start show / hide input create */}
          {createMode ? (
            <FormParent className={item.level === 'MasterDoom' ? 'create-first-item' : 'create'}>
              <ItemCreateUpdateMode>
                <InputCreate
                  autoFocus={true}
                  placeholder={PLACEHOLDER}
                  maxLength={40}
                  onChange={(event) => handleSetValueToInput(event)}
                />
                <ButtonForm
                  onClick={handleSubmitItem}
                  disabled={inputValue.trim().length === 0}
                >
                  {''}
                  {BUTTON_ADD}
                </ButtonForm>
                <ButtonForm onClick={handleCancel}>
                  {''}
                  {BUTTON_CANCEL}
                </ButtonForm>
              </ItemCreateUpdateMode>
            </FormParent>
          ) : null}
          {/* end show / hide input create */}

          <div
            style={{
              display:
                expand && item?.children?.length !== 0 ? 'block' : 'none',
              marginLeft: '46px',
            }}
          >
            {item.children?.map((itemChildren, index) => {
              return (
                <TreeItem
                  item={itemChildren}
                  index={index}
                  showDropdown={showDropdown}
                  setShowDropdown={setShowDropdown}
                  widthDragParent={widthDragParent}
                  setWidthDragParent={setWidthDragParent}
                  widthDragChildren={widthDragChildren}
                  setWidthDragChildren={setWidthDragChildren}
                  dragMode={dragMode}
                  setDragMode={setDragMode}
                  key={itemChildren.menuUuid}
                  handleInsertNode={handleInsertNode}
                  handleUpdateNode={handleUpdateNode}
                  handleDeleteNode={handleDeleteNode}
                  handleInsertAndUpdateNode={handleInsertAndUpdateNode}
                  handleUpdatePositionNode={handleUpdatePositionNode}
                  background={background}
                  setParentUuIdSelected={setParentUuIdSelected}
                />
              );
            })}
          </div>
        </StyleSheetManager>
      </>
    );
  } else {
    return (
      // children item
      <>
        <ModalConfirmDelete
          handleOk={handleOk}
          isModalOpen={isModalOpen}
          handleCloseModal={handleCloseModal}
        />

        <StyleSheetManager shouldForwardProp={shouldForwardProp}>
          <TreeItemChildren
            key={item.menuUuid}
            className={isDragging ? 'dragging' : ''}
            left={item.level !== 'MasterDoom' ? '46px' : '0'}
          >
            {/* start drag icon */}
            <>
              {dragMode && (
                // drag position, hover icon to change width from 36px to 216px
                <DragChildrenContainer
                  className={`drag-element menuUuid--${item.menuUuid} parentUuid--${item.parentUuid} menuName--${item.menuName}`}
                  draggable={true}
                  onMouseOver={showDragOverlayChildren}
                  onMouseLeave={hideDragOverlayChildren}
                  widthOverlay={widthDragChildren ? '326px' : '40px'}
                  onDragStart={(event) => dragStart(event, index)}
                  onDragOver={(event) => dragOver(event, index)}
                  onDragLeave={(event) => dragLeave(event, index)}
                  onDragEnter={(event) => dragEnter(event, index)}
                  onDragEnd={(event) => dragEnd(event, index)}
                />
              )}
              <DragButton className={isDragging ? 'dragging' : ''}>
                <HolderOutlined />
              </DragButton>
            </>
            {/* end drag icon */}
            {editMode ? (
              <>
                <ItemCreateUpdateMode>
                  <InputEdit
                    autoFocus={true}
                    maxLength={40}
                    placeholder={PLACEHOLDER}
                    defaultValue={item.menuName}
                    onChange={(event) => handleSetValueToInput(event)}
                  />
                  <ButtonForm
                    onClick={handleUpdateItem}
                    disabled={inputValue.trim().length === 0}
                  >
                    {''}
                    {BUTTON_UPDATE}
                  </ButtonForm>
                  <ButtonForm onClick={handleCancel}>
                    {BUTTON_CANCEL}
                  </ButtonForm>
                </ItemCreateUpdateMode>
                {error.show && <div>{error.message}</div>}
              </>
            ) : (
              <ItemChildren
                className={isDragging ? 'dragging' : 'no-drag'}
                onMouseEnter={showMenuDropdown}
                onMouseLeave={hideMenuDropdown}
                background={background}
              >
                <Tooltip title={item.menuName} placement="topLeft">
                  <TitleTree>{item.menuName}</TitleTree>
                </Tooltip>
                {showDropdown && (
                  <ChildrenMenu
                    showMenu={isHover}
                    onCreateMode={handleEnableCreateMode}
                    onEditMode={handleEnableEditMode}
                    onDelete={showModalConfirm}
                    onFilterMenu={handleFilterMenu}
                    filterEnable={!!item.menuId}
                  />
                )}
              </ItemChildren>
            )}
          </TreeItemChildren>

          {/* placeholder when drag item*/}
          {/*<ItemChildrenDropPosition*/}
          {/*  className={isDragOVer ? 'drag-over' : 'drag-leave'}*/}
          {/*  width={width}*/}
          {/*/>*/}

          {/* start show / hide input create */}
          {createMode ? (
            <FormChildren>
              <ItemCreateUpdateMode>
                <InputCreate
                  autoFocus={true}
                  maxLength={40}
                  placeholder={PLACEHOLDER}
                  onChange={(event) => handleSetValueToInput(event)}
                />
                <ButtonForm
                  onClick={handleInsertAndUpdateChildrenToParent}
                  disabled={inputValue.trim().length === 0}
                >
                  {''}
                  {BUTTON_ADD}
                </ButtonForm>
                <ButtonForm onClick={handleCancel}>{BUTTON_CANCEL}</ButtonForm>
              </ItemCreateUpdateMode>
            </FormChildren>
          ) : null}
          {/* end show / hide input create */}
        </StyleSheetManager>
      </>
    );
  }
};

export default TreeItem;
