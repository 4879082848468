import React from 'react';
import { BtnTextIcon } from 'components/Button/styled';

const ButtonTextIcon = ({ icon, text, onClick, disabled, size }) => {
  return (
    <BtnTextIcon
      type="text"
      icon={icon ?? null}
      size={size ?? 'Large'}
      onClick={onClick}
      disabled={disabled}
    >
      {''}
      {text}
    </BtnTextIcon>
  );
};

export default ButtonTextIcon;
