import axiosClient from 'api/config/axiosClient';

const getListProject = ({
  pageNumber,
  pageSize,
  keyword,
  sortField,
  sortDirection,
}) => {
  const url = `v1/project?pageNumber=${pageNumber}&pageSize=${pageSize}&keyword=${keyword}&sortField=${sortField}&sortDirection=${sortDirection}`;
  return axiosClient.get(url);
};

const getDetailPublicProject = ({projectId}) => {
  const url = `v1/project/public/${projectId}`
  return axiosClient.get(url)
}

export { getListProject, getDetailPublicProject };
