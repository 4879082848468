import React from 'react';
import {
  ClockCircleOutlined,
  EyeInvisibleOutlined,
  EyeOutlined,
  FilterOutlined,
  RedoOutlined,
  StopOutlined,
} from '@ant-design/icons';
import { ButtonTextIcon, LineVertical } from 'components/Button/index';
import { IconWrapper } from 'components/Icon/styled';

const HeaderButtons = ({
  handleMultipleSearch,
  handleResetData,
  handleOpenModalDisplay,
  handleConfirmUpdateStatus,
  listIdSelected,
  isCancel,
}) => {
  return (
    <>
      <ButtonTextIcon
        text={'絞り込み検索'}
        icon={
          <IconWrapper>
            <FilterOutlined style={{ color: '#9d1c49' }} />
          </IconWrapper>
        }
        onClick={handleMultipleSearch}
        size={'small'}
      />
      <LineVertical />
      <ButtonTextIcon
        text={'リフレッシュ'}
        icon={<RedoOutlined />}
        onClick={handleResetData}
        size={'small'}
      />
      <LineVertical />
      <ButtonTextIcon
        disabled={!listIdSelected?.length || isCancel}
        text={'表示'}
        icon={<EyeOutlined style={{ color: '#9d1c49' }} />}
        onClick={() => handleOpenModalDisplay(true)}
        size={'small'}
      />
      <LineVertical />
      <ButtonTextIcon
        disabled={!listIdSelected?.length || isCancel}
        text={'非表示'}
        icon={<EyeInvisibleOutlined style={{ color: '#9d1c49' }} />}
        onClick={() => handleConfirmUpdateStatus('非表示')}
        size={'small'}
      />
      <LineVertical />
      <ButtonTextIcon
        disabled={!listIdSelected?.length || isCancel}
        text={'予約'}
        icon={<ClockCircleOutlined style={{ color: '#9d1c49' }} />}
        onClick={() => handleOpenModalDisplay(false)}
        size={'small'}
      />
      <LineVertical />
      <ButtonTextIcon
        disabled={!listIdSelected?.length || isCancel}
        text={'DL停止'}
        icon={<StopOutlined style={{ color: '#9d1c49' }} />}
        onClick={() => handleConfirmUpdateStatus('DL停止')}
        size={'small'}
      />
    </>
  );
};

export default HeaderButtons;
