import axiosClient from 'api/config/axiosClient';

const getResultFuzzySearch = ({ keyword, pageSize }) => {
  const url = `v1/opensearch/fuzzySearch?keyword=${encodeURIComponent(
    keyword,
  )}&pageSize=${pageSize}`;
  return axiosClient.get(url);
};

export { getResultFuzzySearch };
