import styled from 'styled-components';

export const TitleScreen = styled.h2`
  font-family: 'inter-semibold', sans-serif;
  font-size: 16px;
  font-weight: 600;
  color: var(--FORM-LABEL-default);
  padding: 10px;
`;

export const IconWrapperTopPage = styled.div`
  margin-right: 32px;
`;

export const HeaderTable = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  align-items: center;
  margin: 7px 0 5px 0;
  background: #d9d9d94d;
  border-radius: 6px;
`;
