import { useIntl } from 'react-intl';

const useMultiLanguagePagePartner = () => {
  const intl = useIntl();

  const PAGE_PARTNER_TITLE_WARNING = intl.formatMessage({
    id: 'application.pagePartner.title.warning',
  });

  const PAGE_PARTNER_TITLE_INFORMATION = intl.formatMessage({
    id: 'application.pagePartner.title.information',
  });

  const PAGE_PARTNER_TITLE_NOTICE = intl.formatMessage({
    id: 'application.pagePartner.title.notice',
  });

  const PAGE_PARTNER_TITLE_DEDICATED_FILE_LIST = intl.formatMessage({
    id: 'application.pagePartner.title.dedicated.fileList',
  });

  return {
    PAGE_PARTNER_TITLE_WARNING,
    PAGE_PARTNER_TITLE_INFORMATION,
    PAGE_PARTNER_TITLE_NOTICE,
    PAGE_PARTNER_TITLE_DEDICATED_FILE_LIST,
  };
};
export default useMultiLanguagePagePartner;
