import { ButtonGroup } from 'components/AntdModal3/styled';
import { ButtonOutline, ButtonPrimary } from 'components/Button/index';
import useMultiLanguageNotificationList from 'page/Application/ManageNotificationList/NotificationList/hook/useMultiLanguageNotificationList';
import React from 'react';

const ButtonModalSearch = ({
  modalKey,
  loadingSubmit,
  handleOk,
  okText,
  submittable,
  handleReset,
  cancelText,
  size,
}) => {
  const {
    NOTIFICATION_LIST_MODAL_BUTTON_SEARCH,
    // NOTIFICATION_LIST_MODAL_BUTTON_RESET,
    NOTIFICATION_LIST_MODAL_BUTTON_CANCEL,
  } = useMultiLanguageNotificationList();
  return (
    <ButtonGroup key={modalKey}>
      <ButtonPrimary
        key="submit"
        loading={loadingSubmit}
        onClick={handleOk}
        text={okText ?? NOTIFICATION_LIST_MODAL_BUTTON_SEARCH}
        disabled={submittable}
        width={80}
        size={size}
      />
      {/*<ButtonDelete*/}
      {/*  key="reset"*/}
      {/*  onClick={handleReset}*/}
      {/*  style={{ height: '40px' }}*/}
      {/*>*/}
      {/*  <span>{NOTIFICATION_LIST_MODAL_BUTTON_RESET}</span>*/}
      {/*</ButtonDelete>*/}
      <ButtonOutline
        key="back"
        onClick={handleReset}
        text={cancelText ?? NOTIFICATION_LIST_MODAL_BUTTON_CANCEL}
      />
    </ButtonGroup>
  );
};

export default ButtonModalSearch;
