import React, { useMemo, useState } from 'react';
import Breadcrumb from 'components/Layout/ApplicationLayout/Breadcrumb';
import LayoutDefault from 'components/Layout/LayoutDefault';
import { TitlePage, FormContainer } from 'page/Application/MyPage/styled';
import { Form, Button } from 'antd';
import {
  FromGrid,
  Grid1,
  Grid2,
  Grid3,
  LabelGrid,
} from 'page/Application/MyPagePartnerAdmin/component/FormListEmail/styled';
import { FormItem } from 'components/Form2';
import { InputStyled } from 'components/Form2/styled';
import { ButtonIconStyled } from 'page/Application/ManageCompanyList/component/Form/styled';
import { CloseOutlined } from '@ant-design/icons';
import { ButtonOutline } from 'components/Button';
import { CHECK_VALIDATE_EMAIL } from 'constant/regex';
import { Link } from 'react-router-dom';

const ManageSolution = () => {
  const [form] = Form.useForm();
  const [disabled, setDisabled] = useState(true);
  const listItem = useMemo(() => {
    return [
      {
        title: <Link to={'/'}>ダッシュボード</Link>,
      },
      {
        title: '企業情報',
      },
    ];
  }, []);

  const initialValue = {
    partnerEmail: [''],
  };

  const editMode = true;

  return (
    <>
      <Breadcrumb listItem={listItem} titlePage={'企業情報'} />
      <LayoutDefault>
        <TitlePage>
          注意：変更した内容は画面の下部に「保存」ボタンを押下した場合のみに、保存される。
        </TitlePage>
        <FormContainer>
          <Form
            labelCol={{
              flex: '170px',
            }}
            size={'Large'}
            form={form}
            initialValues={initialValue}
            disabled={false}
            // onFinish={onFinish}
          >
            <Form.List name="partnerEmail">
              {(fields, { add, remove }) => (
                <>
                  {fields.map((field, index) => (
                    <FromGrid key={field.key}>
                      {index === 0 && (
                        <Grid1>
                          <LabelGrid>配信用メールアドレス</LabelGrid>
                        </Grid1>
                      )}
                      <Grid2>
                        <FormItem
                          {...field}
                          required={true}
                          validateTrigger={['onChange', 'onBlur']}
                          rules={[
                            {
                              required: true,
                              whitespace: true,
                              message:
                                "Please input passenger's name or delete this field.",
                            },
                            {
                              validator: async (_, value) => {
                                if (
                                  !value ||
                                  value.match(CHECK_VALIDATE_EMAIL) === null
                                ) {
                                  setDisabled(true);
                                  return Promise.reject(
                                    new Error('Email khong hop le'),
                                  );
                                } else {
                                  let data = form.getFieldValue('partnerEmail');
                                  if (data.slice(-1)[0] === undefined) {
                                    setDisabled(true);
                                  } else {
                                    setDisabled(false);
                                  }

                                  return Promise.resolve();
                                }
                              },
                            },
                          ]}
                          noStyle
                        >
                          <InputStyled placeholder="passenger name" />
                        </FormItem>
                      </Grid2>
                      {editMode && fields.length > 1 ? (
                        <Grid3>
                          <ButtonIconStyled
                            icon={<CloseOutlined className="icon" />}
                            onClick={() => {
                              remove(field.name);
                              if (field.name === fields.length - 1) {
                                setDisabled(false);
                              }
                            }}
                          />
                        </Grid3>
                      ) : null}
                    </FromGrid>
                  ))}
                  {editMode && (
                    <FromGrid>
                      <Grid2>
                        <FormItem>
                          <ButtonOutline
                            text={'追加'}
                            onClick={() => {
                              setDisabled(true);
                              add();
                            }}
                            width={88}
                            isDisabled={disabled}
                            // isDisabled={fields.length === 1}
                          />
                        </FormItem>
                      </Grid2>
                    </FromGrid>
                  )}
                </>
              )}
            </Form.List>
            <Button htmlType={'submit'}>submit</Button>
          </Form>
        </FormContainer>
      </LayoutDefault>
    </>
  );
};

export default ManageSolution;
