import styled from 'styled-components';
import { Input, Select } from 'antd';

export const FormWrapper = styled.div`
  display: flex;
  //align-items: center;
  justify-content: space-between;
  gap: 10px;
`;

export const InputStyled = styled(Input)`
  height: 40px;
  font-size: 14px;
  input {
    &::placeholder {
      font-size: 14px;
    }
  }
`;

export const SelectStyled = styled(Select)`
  height: 40px;

  .ant-select-selection-item {
    font-size: 14px;
  }

  .ant-select-selection-placeholder {
    font-size: 14px;
  }

  .ant-select-dropdown {
    overflow: auto;
  }
`;
