import React from 'react';
import Icon from 'components/Icon';
import LabelForm from 'components/Form/LabelForm';
import useMultiLanguage from 'hook/useMultiLanguage';
import FormMessage from 'components/Form/FormMessage';
import { Input } from 'antd';
import { LockOutlined } from '@ant-design/icons';
import { FormItemStyled } from 'components/Form/styled';
import { MAX_LENGTH_PASSWORD } from 'constant/inputMaxlength';
import { CHECK_STRONG_PASSWORD } from 'constant/regex';

const FormItemValidateStrongPassword = ({
  name,
  onChange,
  maxLength,
  labelText,
  className,
}) => {
  const {
    FORM_PASSWORD_LABEL,
    FORM_PASSWORD_PLACEHOLDER,
    FORM_PASSWORD_ERROR_MESSAGE_EMPTY,
    FORM_PASSWORD_ERROR_MESSAGE_VALIDATE_1,
    FORM_PASSWORD_ERROR_MESSAGE_VALIDATE_2,
    FORM_PASSWORD_EXTRA_TEXT,
  } = useMultiLanguage();

  return (
    <FormItemStyled
      className={className}
      name={name ? name : 'password'}
      label={<LabelForm text={labelText ? labelText : FORM_PASSWORD_LABEL} />}
      extra={<FormMessage text1={FORM_PASSWORD_EXTRA_TEXT} />}
      rules={[
        {
          required: true,
          message: <FormMessage text1={FORM_PASSWORD_ERROR_MESSAGE_EMPTY} />,
        },
        () => ({
          validator(_, value) {
            if (value?.match(CHECK_STRONG_PASSWORD) === null) {
              // reject
              return Promise.reject(
                <FormMessage
                  text1={FORM_PASSWORD_ERROR_MESSAGE_VALIDATE_1}
                  text2={FORM_PASSWORD_ERROR_MESSAGE_VALIDATE_2}
                />,
              );
            } else {
              // resolve
              return Promise.resolve();
            }
          },
        }),
      ]}
    >
      <Input.Password
        prefix={
          <Icon>
            <LockOutlined className="icon" />
          </Icon>
        }
        autoComplete="on"
        size="large"
        type="password"
        placeholder={FORM_PASSWORD_PLACEHOLDER}
        name={name}
        onChange={onChange}
        maxLength={maxLength ? maxLength : MAX_LENGTH_PASSWORD}
        style={{
          height: 40,
        }}
      />
    </FormItemStyled>
  );
};

export default FormItemValidateStrongPassword;
