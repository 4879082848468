import React from 'react';
import LabelFormMyPage from 'page/Application/MyPage/component/FormMyPage/LabelFormMyPage';
import { FormItem } from 'components/Form2';
import { InputStyled } from 'components/Form2/styled';
import {
  Grid1to2,
  Grid2to3,
  GridForm,
} from 'page/Application/MyPagePartnerAdmin/styled';

const PartnerName = ({ disabled, editMode }) => {
  return (
    <GridForm>
      <Grid1to2>
        <LabelFormMyPage labelText={'会社名'} />
      </Grid1to2>
      <Grid2to3>
        <FormItem name="partnerName">
          <InputStyled
            maxLength={256}
            placeholder={editMode ? '株式会社Notilosシステムズ' : ''}
            disabled={disabled}
          />
        </FormItem>
      </Grid2to3>
    </GridForm>
  );
};

export default PartnerName;
