import { useIntl } from 'react-intl';

const useRoleMultiLanguage = () => {
  const intl = useIntl();

  const ROLE_SOLITON_ADMIN = intl.formatMessage({
    id: 'role.soliton.admin',
  });
  const ROLE_SOLITON_USER = intl.formatMessage({
    id: 'role.soliton.user',
  });
  const ROLE_PARTNER_ADMIN = intl.formatMessage({
    id: 'role.partner.admin',
  });
  const ROLE_PARTNER_USER = intl.formatMessage({
    id: 'role.partner.user',
  });

  const ROLE = {
    ROLE_SOLITON_ADMIN: ROLE_SOLITON_ADMIN,
    ROLE_SOLITON_USER: ROLE_SOLITON_USER,
    ROLE_PARTNER_ADMIN: ROLE_PARTNER_ADMIN,
    ROLE_PARTNER_USER: ROLE_PARTNER_USER,
  };

  return {
    ROLE,
  };
};

export default useRoleMultiLanguage;
