import styled from 'styled-components';

export const LinkNotifyDetail = styled.span`
  word-break: break-word;
  ${({ type }) =>
    type !== 'NO_DETAILS' &&
    `
    text-decoration: underline;
  &:hover {
    color: #1677ff;
    cursor: pointer;
  `}
`;
