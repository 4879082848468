import React, {Suspense, useCallback, useEffect, useMemo, useState} from 'react';
import FormMessage from 'components/Form/FormMessage';
import useAccountStatusMultiLanguage from 'hook/useAccountStatusMultiLanguage';
import useAccountType from 'hook/useAccountType';
import useModalConfirm from 'hook/useModalConfirm';
import useMultiLanguage from 'hook/useMultiLanguage';
import useCustomQuery from 'hook/useQuery/useCustomQuery';
import useMultiLanguageRegisterAccount from 'page/Application/ManageAccountSoliton/RegisterAccountSoliton/hook/useMultiLanguageRegisterAccount';
import { getListDutyExisted } from 'api/account/account.api';
import { ButtonBasic } from 'components/Button';
import { FormFooterSubmit, FormLabelGrid } from 'components/Form';
import {
  FormEmailSelectDomain,
  FormValidateStrongPassword,
  FormValidateText,
  FormValidateTextAndNumber,
  FormValidateTextOnly,
} from 'components/Form2';
import { useNavigate } from 'react-router-dom';
import TextBoxGroupJapanArea from '../TextBoxGroupJapanArea';
import { SelectStyled } from 'components/Form2/FormEmailSelectDomain/styled';
import {
  Grid1,
  Grid2,
  Grid3,
  GridLayout,
  RadioStyled,
} from 'components/Form/Template/FormAccount/styled';
import {
  Container,
  FooterButtonContainer,
  FormWrapper,
} from 'components/Form/Template/styled';
import { Alert, Form, Input, Radio, Spin } from 'antd';
import FormItem from 'components/Form2/FormItem';
import { InputStyled } from 'components/Form2/styled';
import { FormTitle } from 'page/Application/ManageCompanyList/component/Form';
import { getListPartnerForRegister } from "api/authentication/authen.api";
import { useRecoilValue } from "recoil";
import { userInformation } from "recoil/atom/authentication";
import FormDropDown
  from "page/Application/ManagerAccountPartner/EditAccountPartner/components/FormDropDown";
import {debounce} from "lodash";

const FormAccount = ({
  isEdit,
  titleForm,
  domainList,
  setRole,
  onFinish,
  isDisable,
  setIsDisable,
  isDisableDelete,
  setIsDisableDelete,
  role,
  isError,
  formDetailData,
  isLoading,
  isLoadingSubmit,
  errorMessage,
  setToggleChangePassword,
  toggleChangePassword,
  handleDeleteAccountPartner,
  type,
  checkedListAll,
  setCheckedListAll,
  isSuccessSubmit,
  email,
  setEmail,
}) => {
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const { handleConfirmAction, contextHolder } = useModalConfirm();
  const {role: loginRole} = useRecoilValue(userInformation);
  const {
    REGISTER_ACCOUNT_EMAIL,
    REGISTER_ACCOUNT_FIRSTNAME,
    REGISTER_ACCOUNT_LASTNAME,
    REGISTER_ACCOUNT_DEPARTMENT,
    REGISTER_ACCOUNT_POSITION,
    REGISTER_ACCOUNT_DUTY,
    REGISTER_ACCOUNT_PASSWORD,
    REGISTER_ACCOUNT_ACCOUNT_ROLE,
    REGISTER_ACCOUNT_ACCOUNT_STATUS,
    REGISTER_ACCOUNT_PHONE_NUMBER,
    REGISTER_ACCOUNT_PLACEHOLDER_PHONE_NUMBER,
    REGISTER_ACCOUNT_LOCATION,
    REGISTER_ACCOUNT_CHANGE,
    REGISTER_ACCOUNT_SUBMIT_SIGNUP,
    REGISTER_ACCOUNT_SUBMIT_EDIT,
    REGISTER_ACCOUNT_DELETE,
    REGISTER_ACCOUNT_CANCEL,
    REGISTER_ACCOUNT_PLACEHOLDER_LAST_NAME,
    REGISTER_ACCOUNT_PLACEHOLDER_FIRST_NAME,
    REGISTER_ACCOUNT_PLACEHOLDER_DEPARTMENT,
    REGISTER_ACCOUNT_PLACEHOLDER_POSITION,
    REGISTER_ACCOUNT_PLACEHOLDER_PASSWORD,
    REGISTER_ACCOUNT_CATEGORY_ENTRY_REQUIRED,
    REGISTER_ACCOUNT_CONFIRM_CANCEL_REGISTER,
    REGISTER_ACCOUNT_CONFIRM_CANCEL_EDIT,
    REGISTER_ACCOUNT_CONFIRM_DELETE_EDIT,
    DETAIL_ACCOUNT_COMPANY_NAME,
  } = useMultiLanguageRegisterAccount();
  const { APPLICATION_OK_TEXT, APPLICATION_CANCEL_TEXT } = useMultiLanguage();
  const { ACCOUNT_STATUS } = useAccountStatusMultiLanguage();
  const { ACCOUNT_TYPE_PARTNER } = useAccountType();
  const { useQueryWithoutParams, useQueryAPI } = useCustomQuery();
  const [isSearchPartner, setIsSearchPartner] = useState(false);

  const { data: listDuty = [] } = useQueryWithoutParams({
    api: getListDutyExisted,
    key: 'listDuty',
    options: {
      refetchOnWindowFocus: false,
    },
  });

  const { data: listPartner } = useQueryAPI({
    api: getListPartnerForRegister,
    params: {
      email: email,
    },
    key: 'listDuty',
    enabled: !!isSearchPartner,
  });

  const debounceDropDown = useCallback(debounce((email) => {
    setEmail(email);
    setIsSearchPartner(true);
  }, 500), [])

  const onChangeEmail = (e) => {
    const { value } = e.target;
    debounceDropDown(value);
  };

  const onChange = (e) => {
    setRole(e.target.value);
  };
  const handleToggleChangePassword = () => {
    setToggleChangePassword(!toggleChangePassword);
  };

  const handleCancel = () => {
    navigate('/manage-account-partner');
  };

  const dutyOptions = useMemo(() => {
    return listDuty?.data?.map((value) => ({
      label: value?.name,
      value: value?.id,
      title: '',
    }));
  }, [listDuty?.data]);


  const dataListPartner = useMemo(() => {
    if (!listPartner || listPartner?.data === []) {
      return [];
    }
    return listPartner?.data?.map((item) => ({
      label: item.partnerName,
      value: item.partnerId,
      title: '',
    }));
  }, [listPartner]);

  useEffect(() => {
    if (isEdit && formDetailData?.data) {
      const detailData = formDetailData?.data;
      form.setFieldsValue({
        ...detailData,
        phoneNumber:
          detailData?.phoneNumber && detailData?.phoneNumber !== ''
            ? detailData?.phoneNumber
            : detailData?.mobileNumber,
        duty: detailData?.dutyId,
      });
    }
    if (dataListPartner) {
      if (dataListPartner.length === 1) {
        form.setFieldValue('partnerId', dataListPartner[0].value);
      } else {
        form.resetFields(['partnerId']);
      }
    }
  }, [isEdit, formDetailData?.data, form, dataListPartner]);

  const onChangePhoneNumber = (value) => {
    const valuePhoneNumber = value?.target?.value;
    form.setFieldValue('phoneNumber', valuePhoneNumber);
  };

  return (
    <Suspense fallback={isLoading}>
      {contextHolder}
      <FormTitle title={titleForm} type={type} />
      <FormWrapper>
        <Container>
          <Spin spinning={isLoading || isLoadingSubmit || isSuccessSubmit}>
            <Form
              name="register-account-partner"
              form={form}
              layout="horizontal"
              size="large"
              onFinish={onFinish}
            >
              <GridLayout>
                <Grid2>
                  {(isError || errorMessage)
                    && !(dataListPartner.length !== 0
                        && errorMessage === 'このアカウントは未登録企業です。')
                    && (
                      <Alert
                        message={errorMessage}
                        type="error"
                        style={{ marginBottom: '24px' }}
                      />
                    )}
                </Grid2>
                {/* start company name */}
                {isEdit && (
                  <>
                    <Grid1>
                      <FormLabelGrid
                        labelText={DETAIL_ACCOUNT_COMPANY_NAME}
                        required={false}
                      />
                    </Grid1>
                    <Grid2>
                      <FormItem name="partnerName">
                        <InputStyled
                          onChange={onChange}
                          size="large"
                          type="text"
                          disabled={true}
                        />
                      </FormItem>
                    </Grid2>
                  </>
                )}
                {/* end company name */}

                {/* start last name */}
                <Grid1>
                  <FormLabelGrid
                    labelText={REGISTER_ACCOUNT_LASTNAME}
                    required={true}
                  />
                </Grid1>
                <Grid2>
                  <FormValidateText
                    name="lastName"
                    required={true}
                    placeholder={REGISTER_ACCOUNT_PLACEHOLDER_LAST_NAME}
                  />
                </Grid2>
                {/* end last name */}

                {/* start first name */}
                <Grid1>
                  <FormLabelGrid
                    labelText={REGISTER_ACCOUNT_FIRSTNAME}
                    required={true}
                  />
                </Grid1>
                <Grid2>
                  <FormValidateText
                    name="firstName"
                    required={true}
                    placeholder={REGISTER_ACCOUNT_PLACEHOLDER_FIRST_NAME}
                  />
                </Grid2>
                {/* end first name */}

                {isEdit ? (
                  <>
                    <Grid1>
                      <FormLabelGrid
                        labelText={REGISTER_ACCOUNT_EMAIL}
                        required={true}
                      />
                    </Grid1>
                    <Grid2>
                      <Form.Item name={'email'}>
                        <Input disabled />
                      </Form.Item>
                    </Grid2>
                  </>
                ) : (
                  <>
                    <Grid1>
                      <FormLabelGrid
                        labelText={REGISTER_ACCOUNT_EMAIL}
                        required={true}
                      />
                    </Grid1>
                    <Grid2>
                      <FormEmailSelectDomain
                        isEdit={false}
                        domainList={domainList}
                        onChange={onChangeEmail}
                      />
                    </Grid2>
                  </>
                )}

                {/* start company name */}
                {!isEdit && loginRole === 'ROLE_SOLITON_ADMIN' && (
                    <>
                      <Grid1>
                        <FormLabelGrid
                            labelText={DETAIL_ACCOUNT_COMPANY_NAME}
                            required={true}
                        />
                      </Grid1>
                      <Grid2>
                        <FormDropDown
                            required={true}
                            name="partnerId"
                            data={dataListPartner}
                            messageRequired={REGISTER_ACCOUNT_CATEGORY_ENTRY_REQUIRED}
                        ></FormDropDown>
                      </Grid2>
                    </>
                )}
                {/* end company name */}
                {/* start change password */}
                <Grid1>
                  <FormLabelGrid
                    labelText={REGISTER_ACCOUNT_PASSWORD}
                    required={true}
                  />
                </Grid1>
                <Grid2>
                  {toggleChangePassword ? (
                    <Form.Item
                      style={{
                        marginBottom: 30,
                      }}
                    >
                      <InputStyled
                        value={'パスワードパスワード'}
                        autoComplete="new-password"
                        disabled
                        type={'password'}
                      />
                    </Form.Item>
                  ) : (
                    <FormValidateStrongPassword
                      placeholder={REGISTER_ACCOUNT_PLACEHOLDER_PASSWORD}
                      name="password"
                      maxLength={256}
                      message={REGISTER_ACCOUNT_CATEGORY_ENTRY_REQUIRED}
                      required={true}
                    />
                  )}
                </Grid2>

                {toggleChangePassword && (
                  <Grid3>
                    {isEdit && (
                      <ButtonBasic
                        block={true}
                        text={
                          /*must have a span tag so there are no spaces between letters*/
                          <span>
                            {' '}
                            {toggleChangePassword
                              ? REGISTER_ACCOUNT_CHANGE
                              : REGISTER_ACCOUNT_CANCEL}
                          </span>
                        }
                        onClick={handleToggleChangePassword}
                      />
                    )}
                  </Grid3>
                )}

                {/* end change password */}

                {/* start account type */}
                <Grid1>
                  <FormLabelGrid
                    labelText={REGISTER_ACCOUNT_ACCOUNT_ROLE}
                    required={true}
                  />
                </Grid1>
                <Grid2>
                  <Form.Item
                    name="role"
                    required={true}
                    rules={[
                      {
                        required: true,
                        message: (
                          <FormMessage
                            text1={REGISTER_ACCOUNT_CATEGORY_ENTRY_REQUIRED}
                          />
                        ),
                      },
                    ]}
                  >
                    <SelectStyled options={ACCOUNT_TYPE_PARTNER} />
                  </Form.Item>
                </Grid2>
                {/* end account type */}

                <Grid1>
                  <FormLabelGrid
                    labelText={REGISTER_ACCOUNT_ACCOUNT_STATUS}
                    required={false}
                  />
                </Grid1>
                <Grid2>
                  <Form.Item>
                    <Radio.Group
                      onChange={onChange}
                      value={role}
                      name="approveStatus"
                    >
                      <RadioStyled value={ACCOUNT_STATUS.enable.value}>
                        {ACCOUNT_STATUS.enable.label}
                      </RadioStyled>
                      <RadioStyled value={ACCOUNT_STATUS.unapproved.value}>
                        {ACCOUNT_STATUS.unapproved.label}
                      </RadioStyled>
                      <RadioStyled value={ACCOUNT_STATUS.suspended.value}>
                        {ACCOUNT_STATUS.suspended.label}
                      </RadioStyled>
                    </Radio.Group>
                  </Form.Item>
                </Grid2>

                {/* start phone-number */}
                <Grid1>
                  <FormLabelGrid
                    labelText={REGISTER_ACCOUNT_PHONE_NUMBER}
                    required={true}
                  />
                </Grid1>
                <Grid2>
                  <FormValidateTextAndNumber
                    name="phoneNumber"
                    extra={
                      <FormMessage
                        text1={
                          '携帯電話をお持ちの方は、携帯電話番号をご記入ください。'
                        }
                        marginBottom={'12px'}
                      />
                    }
                    required={true}
                    maxLength={12}
                    isPhone={true}
                    placeholder={REGISTER_ACCOUNT_PLACEHOLDER_PHONE_NUMBER}
                    onChange={onChangePhoneNumber}
                  />
                </Grid2>
                {/* end phone-number */}

                {/* start department */}
                <Grid1>
                  <FormLabelGrid
                    labelText={REGISTER_ACCOUNT_DEPARTMENT}
                    required={false}
                  />
                </Grid1>
                <Grid2>
                  <FormValidateTextAndNumber
                    name="department"
                    required={false}
                    placeholder={REGISTER_ACCOUNT_PLACEHOLDER_DEPARTMENT}
                  />
                </Grid2>
                {/* end department */}

                {/* start position */}
                <Grid1>
                  <FormLabelGrid
                    labelText={REGISTER_ACCOUNT_POSITION}
                    required={false}
                  />
                </Grid1>
                <Grid2>
                  <FormValidateTextOnly
                    name="position"
                    required={false}
                    placeholder={REGISTER_ACCOUNT_PLACEHOLDER_POSITION}
                  />
                </Grid2>
                {/* end position */}

                {/* start duty */}
                <Grid1>
                  <FormLabelGrid
                    labelText={REGISTER_ACCOUNT_DUTY}
                    required={false}
                  />
                </Grid1>
                <Grid2>
                  <Form.Item name="duty" required={false}>
                    <SelectStyled virtual={false} options={dutyOptions} />
                  </Form.Item>
                </Grid2>
                {/* end duty */}

                {/* start account type */}
                <Grid1>
                  <FormLabelGrid
                    labelText={REGISTER_ACCOUNT_LOCATION}
                    required={false}
                  />
                </Grid1>

                <TextBoxGroupJapanArea
                  isEdit={isEdit}
                  formDetailData={formDetailData}
                  checkedListAll={checkedListAll}
                  setCheckedListAll={setCheckedListAll}
                  disabled={false}
                />

                {/* end account type */}

                <Grid2>
                  <FooterButtonContainer>
                    <FormFooterSubmit
                      isDisable={isDisable}
                      isDisableDelete={isDisableDelete}
                      textOk={
                        isEdit
                          ? REGISTER_ACCOUNT_SUBMIT_EDIT
                          : REGISTER_ACCOUNT_SUBMIT_SIGNUP
                      }
                      textCancel={REGISTER_ACCOUNT_CANCEL}
                      textDelete={REGISTER_ACCOUNT_DELETE}
                      isDelete={isEdit}
                      onDelete={() => {
                        handleConfirmAction({
                          confirmFn: handleDeleteAccountPartner,
                          title:
                            formDetailData?.data.fullName +
                            REGISTER_ACCOUNT_CONFIRM_DELETE_EDIT,
                          cancelText: <span> {REGISTER_ACCOUNT_CANCEL}</span>,
                          okText: <span> {REGISTER_ACCOUNT_DELETE}</span>,
                          setIsDisable: setIsDisableDelete,
                        });
                        setIsDisableDelete(true);
                      }}
                      onCancel={() => {
                        handleConfirmAction({
                          confirmFn: handleCancel,
                          title: (
                            <span>
                              {' '}
                              {isEdit
                                ? REGISTER_ACCOUNT_CONFIRM_CANCEL_EDIT
                                : REGISTER_ACCOUNT_CONFIRM_CANCEL_REGISTER}
                            </span>
                          ),
                          cancelText: <span> {APPLICATION_CANCEL_TEXT}</span>,
                          okText: <span> {APPLICATION_OK_TEXT}</span>,
                          setIsDisable: setIsDisable,
                        });
                        setIsDisable(true);
                      }}
                    />
                  </FooterButtonContainer>
                </Grid2>
              </GridLayout>
            </Form>
          </Spin>
        </Container>
      </FormWrapper>
    </Suspense>
  );
};
export default FormAccount;
