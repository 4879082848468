import {useIntl} from "react-intl";

const useMultiLanguageNotificationList = () => {

  const intl = useIntl();

  const NOTIFICATION_LIST_TITLE = intl.formatMessage({
    id: 'authentication.notificationList.title',
  });

  const NOTIFICATION_LIST_PLACE_HOLDER = intl.formatMessage({
    id: 'authentication.notificationList.placeholderSearch',
  });

  const NOTIFICATION_LIST_FILTER_DATA = intl.formatMessage({
    id: 'authentication.notificationList.filterData',
  });

  const NOTIFICATION_LIST_MULTIPLE_DELETE = intl.formatMessage({
    id: 'authentication.notificationList.multipleDelete',
  });

  const NOTIFICATION_LIST_TABLE_TITLE_DISPLAYED_DATE = intl.formatMessage({
    id: 'authentication.notificationList.table.title.displayedDate',
  });

  const NOTIFICATION_LIST_TABLE_TITLE_TYPE = intl.formatMessage({
    id: 'authentication.notificationList.table.title.type',
  });

  const NOTIFICATION_LIST_TABLE_TITLE = intl.formatMessage({
    id: 'authentication.notificationList.table.title',
  });

  const NOTIFICATION_LIST_TABLE_TITLE_STATUS = intl.formatMessage({
    id: 'authentication.notificationList.table.title.status',
  });

  const NOTIFICATION_LIST_TABLE_TITLE_ACTION = intl.formatMessage({
    id: 'authentication.notificationList.table.title.action',
  });

  const NOTIFICATION_LIST_TABLE_PAGINATION_TOTAL = intl.formatMessage({
    id: 'authentication.notificationList.table.paginationTotal',
  });

  const NOTIFICATION_LIST_MODAL_TITLE_FILTER_DATA = intl.formatMessage({
    id: 'authentication.notificationList.modal.title.filterData',
  });

  const NOTIFICATION_LIST_MODAL_BUTTON_SEARCH = intl.formatMessage({
    id: 'authentication.notificationList.modal.button.search',
  });

  const NOTIFICATION_LIST_MODAL_BUTTON_RESET = intl.formatMessage({
    id: 'authentication.notificationList.modal.button.reset',
  });

  const NOTIFICATION_LIST_MODAL_BUTTON_CANCEL = intl.formatMessage({
    id: 'authentication.notificationList.modal.button.cancel',
  });

  const NOTIFICATION_LIST_MODAL_DISPLAYED_DATE = intl.formatMessage({
    id: 'authentication.notificationList.modal.displayedDate',
  });

  const NOTIFICATION_LIST_MODAL_TYPE = intl.formatMessage({
    id: 'authentication.notificationList.modal.type',
  });

  const NOTIFICATION_LIST_MODAL_STATUS = intl.formatMessage({
    id: 'authentication.notificationList.modal.status',
  });

  const NOTIFICATION_LIST_MODAL_CONFIRM_MULTIPLE_DELETE_1 = intl.formatMessage({
    id: 'authentication.notificationList.modal.confirmMultipleDelete1',
  });

  const NOTIFICATION_LIST_MODAL_CONFIRM_MULTIPLE_DELETE_2 = intl.formatMessage({
    id: 'authentication.notificationList.modal.confirmMultipleDelete2',
  });

  const NOTIFICATION_LIST_MODAL_DELETE_SUCCESS = intl.formatMessage({
    id: 'authentication.notificationList.modal.success',
  });

  return {
    NOTIFICATION_LIST_TITLE,
    NOTIFICATION_LIST_PLACE_HOLDER,
    NOTIFICATION_LIST_FILTER_DATA,
    NOTIFICATION_LIST_MULTIPLE_DELETE,
    NOTIFICATION_LIST_TABLE_TITLE_DISPLAYED_DATE,
    NOTIFICATION_LIST_TABLE_TITLE_TYPE,
    NOTIFICATION_LIST_TABLE_TITLE,
    NOTIFICATION_LIST_TABLE_TITLE_STATUS,
    NOTIFICATION_LIST_TABLE_TITLE_ACTION,
    NOTIFICATION_LIST_TABLE_PAGINATION_TOTAL,
    NOTIFICATION_LIST_MODAL_TITLE_FILTER_DATA,
    NOTIFICATION_LIST_MODAL_DISPLAYED_DATE,
    NOTIFICATION_LIST_MODAL_TYPE,
    NOTIFICATION_LIST_MODAL_STATUS,
    NOTIFICATION_LIST_MODAL_BUTTON_SEARCH,
    NOTIFICATION_LIST_MODAL_BUTTON_RESET,
    NOTIFICATION_LIST_MODAL_BUTTON_CANCEL,
    NOTIFICATION_LIST_MODAL_CONFIRM_MULTIPLE_DELETE_1,
    NOTIFICATION_LIST_MODAL_CONFIRM_MULTIPLE_DELETE_2,
    NOTIFICATION_LIST_MODAL_DELETE_SUCCESS
  }

}
export default useMultiLanguageNotificationList;