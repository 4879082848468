import useMultiLanguageRegisterCategory from '../../RegisterCategory/hook/useMultiLanguageRegisterCategory';
import React from 'react';
import { Form, Input } from 'antd';

const FormTextRequired = ({
  message,
  required,
  maxLength,
  name,
  placeHolder,
  isDisabled,
}) => {
  const { APPLICATION_REGISTERCATEGORY_FIELD_REQUIRED } =
    useMultiLanguageRegisterCategory();

  return (
    <Form.Item
      name={name ?? 'name'}
      rules={[
        {
          required: required,
          message: message,
          validator: (object, value) => {
            const field = object.field;
            if (field === 'title') {
              const trimmedString = value?.trim();
              if (trimmedString.length === 0 || value.trim() === '') {
                return Promise.reject(
                  APPLICATION_REGISTERCATEGORY_FIELD_REQUIRED,
                );
              }
            }
            return Promise.resolve();
          },
        },
      ]}
    >
      <Input
        disabled={isDisabled}
        maxLength={maxLength ?? 256}
        placeholder={placeHolder}
      />
    </Form.Item>
  );
};

export default FormTextRequired;
