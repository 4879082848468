import React from 'react';
import { Outlet } from 'react-router-dom';
import AccountPartnerList from 'page/Application/ManagerAccountPartner/AccountPartnerList';
import RegisterAccountPartner from 'page/Application/ManagerAccountPartner/RegisterAccountPartner';
import EditAccountPartner from 'page/Application/ManagerAccountPartner/EditAccountPartner';
import useSetTitlePage from 'hook/useSetTitlePage';

const ManagerAccountPartner = () => {
  useSetTitlePage('パートナーアカウント管理');

  return <Outlet />;
};

export default ManagerAccountPartner;

export { AccountPartnerList, RegisterAccountPartner, EditAccountPartner };
