import useMultiLanguageRegisterProduct from 'page/Application/ManageProductAndService/Product/RegisterProduct/hook/useMultiLanguageRegisterProduct';

const useStatusProduct = () => {
  const { APPLICATION_PRODUCT_STATUS_SHOW, APPLICATION_PRODUCT_STATUS_HIDE } =
    useMultiLanguageRegisterProduct();
  const STATUS_FOR_LIST = {
    SHOW: APPLICATION_PRODUCT_STATUS_SHOW,
    HIDE: APPLICATION_PRODUCT_STATUS_HIDE,
  };
  const STATUS_FOR_FORM = [
    {
      id: 1,
      label: APPLICATION_PRODUCT_STATUS_SHOW,
      value: true,
    },
    {
      id: 2,
      label: APPLICATION_PRODUCT_STATUS_HIDE,
      value: false,
    },
  ];

  return {
    STATUS_FOR_FORM,
    STATUS_FOR_LIST,
  };
};

export default useStatusProduct;
