import { useRecoilState, useRecoilValue } from 'recoil';
import {
  errorMessage,
  positionItemDragEnter,
  inputChange,
} from 'recoil/atom/tree';

const UseTraverseTree = () => {
  const positionItem = useRecoilValue(positionItemDragEnter);
  const valueChange = useRecoilValue(inputChange);
  const [, setError] = useRecoilState(errorMessage);

  // check menu name exist return true | false
  const handleCheckExistMenuName = (listMenu, menuName) => {
    return !!listMenu.children.find((item) => item.menuName === menuName);
  };

  const insertNode = (tree, itemId, menuName, isParent) => {
    if (tree.menuUuid === itemId && tree.isParent) {
      if (handleCheckExistMenuName(tree, menuName)) {
        setError(true);
      } else {
        setError(false);
        let newItem = {
          menuUuid: new Date().getTime().toString(),
          parentUuid: tree.menuUuid,
          menuName: menuName,
          isParent: isParent,
          isRoot: true,
          orderId: tree.children?.length,
          children: [],
        };

        tree.children.unshift(newItem);
        let arrayCopy = [...tree.children];
        let finalArray = arrayCopy.map((item, index) => {
          return {
            ...item,
            orderId: index,
          };
        });

        return {
          ...tree,
          children: finalArray,
        };
      }
    }
    let latestNode;
    latestNode = tree?.children?.map((ob) => {
      return insertNode(ob, itemId, menuName, isParent);
    });

    return { ...tree, children: latestNode };
  };

  const updateNode = (tree, item, newData) => {
    if (tree.menuUuid === item.parentUuid) {
      // menu name exist
      if (handleCheckExistMenuName(tree, newData) && valueChange) {
        setError(true);
        return tree;
      } else {
        // update menu name
        const updateChildren = tree.children.map((menuItem) => {
          if (menuItem.menuUuid === item.menuUuid) {
            return {
              ...menuItem,
              menuName: newData,
            };
          } else {
            return menuItem;
          }
        });
        setError(false);
        return {
          ...tree,
          children: updateChildren,
        };
      }
    }

    // cho nay de quy goi lai
    let latestNode;
    latestNode = tree?.children?.map((ob) => {
      return updateNode(ob, item, newData);
    });

    return { ...tree, children: latestNode };
  };

  const insertAndUpdateNode = (tree, itemParent, menuName) => {
    // find current item in tree
    if (tree.menuUuid === itemParent.menuUuid) {
      if (handleCheckExistMenuName(tree, menuName)) {
        setError(true);
      } else {
        setError(false);
        let newItem = {
          menuUuid: new Date().getTime().toString(),
          parentUuid: tree.menuUuid,
          menuName: menuName,
          isParent: false,
          isRoot: false,
          children: [],
        };
        return {
          ...itemParent,
          isParent: true,
          children: [...itemParent.children, newItem],
        };
      }
    }

    // run in every level, children for find current item
    let latestNode;
    latestNode = tree?.children?.map((ob) => {
      return insertAndUpdateNode(ob, itemParent, menuName);
    });

    return { ...tree, children: latestNode };
  };

  const deleteNode = (tree, item) => {
    if (tree.menuUuid === item.parentUuid) {
      if (tree.children.length === 1 && tree.level !== 'MasterDoom') {
        return {
          ...tree,
          isParent: false,
          children: tree.children.filter(
            (treeItem) => treeItem.menuUuid !== item.menuUuid,
          ),
        };
      } else {
        return {
          ...tree,
          children: tree.children.filter(
            (treeItem) => treeItem.menuUuid !== item.menuUuid,
          ),
        };
      }
    }

    // cho nay de quy goi lai
    let latestNode;
    latestNode = tree?.children?.map((ob) => {
      return deleteNode(ob, item);
    });

    return { ...tree, children: latestNode };
  };

  const updatePositionNode = (tree, item, index, parentUuIdSelected) => {
    if (tree.menuUuid === item.parentUuid) {
      // same parent
      if (item.parentUuid === parentUuIdSelected) {
        const arrayCopy = [...tree.children];
        const dragItem = arrayCopy.splice(index, 1)[0];
        arrayCopy.splice(positionItem, 0, dragItem);

        // format orderId for backend sort
        let finalArray = [];
        arrayCopy.forEach((item, index) => {
          finalArray.push({
            ...item,
            orderId: index,
          });
        });

        return {
          ...tree,
          children: finalArray,
        };
      }
      // not same parent
      else {
        return tree;
      }
    }

    // cho nay de quy goi lai
    let latestNode;
    latestNode = tree?.children?.map((ob) => {
      return updatePositionNode(ob, item, index, parentUuIdSelected);
    });

    return { ...tree, children: latestNode };
  };

  return {
    insertNode,
    updateNode,
    insertAndUpdateNode,
    deleteNode,
    updatePositionNode,
  };
};
export default UseTraverseTree;

// 500k to explain one function
// techcombank: 19038778552017
