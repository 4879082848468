import React from 'react';
import Icon from 'components/Icon';
import LabelForm from 'components/Form/LabelForm';
import FormMessage from 'components/Form/FormMessage';
import ValidatePasswordEmptyLanguage from 'page/Authentication/component/Input/language/validatePasswordEmptyLanguage';
import { Input } from 'antd';
import { LockOutlined } from '@ant-design/icons';
import { FormItemStyled } from 'page/Authentication/component/Input/styled';
import { MAX_LENGTH_PASSWORD } from 'constant/inputMaxlength';

const ValidatePasswordEmpty = ({ onChange, maxLength, name, className }) => {
  const {
    FORM_PASSWORD_LABEL,
    FORM_PASSWORD_PLACEHOLDER,
    FORM_PASSWORD_ERROR_MESSAGE_EMPTY,
  } = ValidatePasswordEmptyLanguage();

  return (
    <FormItemStyled
      className={className}
      marginbottom="15px"
      name={name}
      label={<LabelForm text={FORM_PASSWORD_LABEL} />}
      rules={[
        {
          required: true,
          message: (
            <FormMessage
              text1={FORM_PASSWORD_ERROR_MESSAGE_EMPTY}
              marginBottom={'12px'}
            />
          ),
        },
      ]}
    >
      <Input.Password
        prefix={
          <Icon>
            <LockOutlined className="icon" />
          </Icon>
        }
        autoComplete="on"
        type="password"
        placeholder={FORM_PASSWORD_PLACEHOLDER}
        onChange={onChange}
        maxLength={maxLength ? maxLength : MAX_LENGTH_PASSWORD}
        style={{ height: 40 }}
      />
    </FormItemStyled>
  );
};

export default ValidatePasswordEmpty;
