import React, { Suspense, useEffect, useMemo, useState } from 'react';
import { Form, Spin } from 'antd';
import { getDetailPartnerUser } from 'api/myPage/partnerUser.api';
import { ButtonPrimary } from 'components/Button';
import Breadcrumb from 'components/Layout/ApplicationLayout/Breadcrumb';
import LayoutDefault from 'components/Layout/LayoutDefault';
import RowButton from 'components/Layout/RowButton';
import useCustomQuery from 'hook/useQuery/useCustomQuery';
import FormMessage from 'components/Form/FormMessage';
import { FormTitle } from 'page/Application/ManageCompanyList/component/Form';
import { Link, useNavigate } from 'react-router-dom';
import {
  FormWrapper,
  GridFormAccount,
  GridFormAccount2to3,
} from 'page/Application/MyPagePartnerAdmin/styled';

import {
  FullName,
  AccountEmail,
  ChangePassword,
  AccountType,
  PhoneNumber,
  Department,
  Position,
  Duty,
  TextBoxGroupJapanArea,
} from 'page/Application/MyPagePartnerAdmin/component/Form';

const AccountInformationOverview = () => {
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const { useQueryAPI } = useCustomQuery();
  const [data, setData] = useState();
  const [checkedListAll, setCheckedListAll] = useState([]);

  const listItem = useMemo(() => {
    return [
      {
        title: <Link to={'/'}>パートナー専用ページ</Link>,
      },
      {
        title: 'アカウント情報',
      },
    ];
  }, []);

  const { data: partnerDetail = [], isLoading } = useQueryAPI({
    api: getDetailPartnerUser,
    key: 'myPagePA',
    params: {},
  });

  const handleSwitchEditMode = () => {
    navigate('/account-information/edit', {
      state: {
        ...partnerDetail?.data,
      },
    });
  };

  useEffect(() => {
    setData(partnerDetail?.data);
    form.setFieldsValue({
      ...data,
      companyName: data?.partnerName,
      password: 'パスワードパスワード',
    });
    const detailData = partnerDetail?.data;
    form.setFieldsValue({
      duty: detailData?.dutyId,
      phoneNumber:
        detailData?.phoneNumber && detailData?.phoneNumber !== ''
          ? detailData?.phoneNumber
          : detailData?.mobileNumber,
    });
  }, [partnerDetail?.data, form, data]);

  return (
    <Suspense fallback={isLoading}>
      <Breadcrumb listItem={listItem} titlePage={'アカウント情報'} />
      <LayoutDefault>
        <FormTitle
          notice={'注意：'}
          title={'変更した内容は画面下部の「保存」ボタンを押すと保存されます。'}
          type={'text'}
          styles={{ color: '#9D1C49' }}
        />

        <Spin spinning={isLoading}>
          <FormWrapper>
            <Form size={'Large'} form={form} disabled={true}>
              <FullName isEdit={false} />
              <AccountEmail disabled={true} />
              <ChangePassword
                disabled={true}
                editPassword={false}
                data={data}
                isEdit={false}
              />
              <AccountType data={data} disabled={true} />
              <PhoneNumber
                isEdit={false}
                maxLength={14}
                isPhone={true}
                extra={
                  <FormMessage
                    text1={
                      '携帯電話をお持ちの方は、携帯電話番号をご記入ください。'
                    }
                  />
                }
              />
              <Department editMode={false} />
              <Position editMode={false} />
              <Duty editMode={false} />

              <TextBoxGroupJapanArea
                isEdit={true}
                formDetailData={partnerDetail}
                checkedListAll={checkedListAll}
                setCheckedListAll={setCheckedListAll}
                disabled={true}
              />
            </Form>

            <GridFormAccount className="margin-top-20">
              <GridFormAccount2to3>
                <RowButton>
                  <ButtonPrimary
                    text={'編集'}
                    width={120}
                    onClick={handleSwitchEditMode}
                  />
                </RowButton>
              </GridFormAccount2to3>
            </GridFormAccount>
          </FormWrapper>
        </Spin>
      </LayoutDefault>
    </Suspense>
  );
};

export default AccountInformationOverview;
