import { useIntl } from 'react-intl';

const useStatusNotifyMultiLanguage = () => {
  const intl = useIntl();

  const STATUS_NOTIFICATION_SHOW = intl.formatMessage({
    id: 'status.notification.show',
  });

  const STATUS_NOTIFICATION_HIDE = intl.formatMessage({
    id: 'status.notification.hide',
  });

  const STATUS_NOTIFICATION_SCHEDULED = intl.formatMessage({
    id: 'status.notification.scheduled',
  });
  const STATUS_FOR_LIST = {
    SHOW: STATUS_NOTIFICATION_SHOW,
    HIDE: STATUS_NOTIFICATION_HIDE,
    SCHEDULED: STATUS_NOTIFICATION_SCHEDULED,
  };

  const STATUS_FOR_FORM = [
    {
      id: 1,
      label: STATUS_NOTIFICATION_SHOW,
      value: 'SHOW',
      title: '',
    },
    {
      id: 2,
      label: STATUS_NOTIFICATION_HIDE,
      value: 'HIDE',
      title: '',
    },
    {
      id: 3,
      label: STATUS_NOTIFICATION_SCHEDULED,
      value: 'SCHEDULED',
      title: '',
    },
  ];

  return {
    STATUS_FOR_LIST,
    STATUS_FOR_FORM,
  };
};

export default useStatusNotifyMultiLanguage;
