import { atom } from 'recoil';

export const signUpUser = atom({
  key: 'signUpUser',
  default: {
    email: '',
    firstName: '',
    lastName: '',
    password: '',
    phoneNumber: '',
    isPrivacy: false,
  },
});
