import React from 'react';
import LabelFormMyPage from 'page/Application/MyPage/component/FormMyPage/LabelFormMyPage';
import FormMessage from 'components/Form/FormMessage';
import useMultiLanguageRegisterAccount from 'page/Application/ManageAccountSoliton/RegisterAccountSoliton/hook/useMultiLanguageRegisterAccount';
import { FormItem } from 'components/Form2';
import { InputStyled } from 'components/Form2/styled';
import { MAX_LENGTH_TEXT } from 'constant/inputMaxlength';
import {
  CHECK_CHARACTER,
  CHECK_LATIN_JAPANESE_NUMBER,
  CHECK_MOBILE_NUMBER,
} from 'constant/regex';
import {
  GridFormAccount,
  GridFormAccount1to2,
  GridFormAccount2to3,
} from 'page/Application/MyPagePartnerAdmin/styled';

const PhoneNumber = ({ isEdit, maxLength, isPhone, onChange, extra }) => {
  const {
    REGISTER_ACCOUNT_PHONE_NUMBER,
    REGISTER_ACCOUNT_PLACEHOLDER_PHONE_NUMBER,
    REGISTER_ACCOUNT_INPUT_TYPE_VALIDATION,
    REGISTER_ACCOUNT_CATEGORY_ENTRY_REQUIRED,
  } = useMultiLanguageRegisterAccount();

  const validateRule = [
    {
      required: isEdit,
      message: <FormMessage text1={REGISTER_ACCOUNT_CATEGORY_ENTRY_REQUIRED} />,
    },
    () => ({
      validator(_, value) {
        if (isPhone) {
          if (!CHECK_MOBILE_NUMBER.test(value)) {
            return Promise.reject(
              <FormMessage
                text1={'無効な文字列です。電話番号は0～9で入力してください。'}
              />,
            );
          }
        }

        if (!isPhone) {
          if (
            value &&
            (CHECK_CHARACTER === null ||
              !CHECK_LATIN_JAPANESE_NUMBER.test(value) || // check japanese, latin
              value.trim() === '' || // check blank
              (value && [...value].some((char) => char.codePointAt(0) > 65535))) // check 4-byte characters
          ) {
            return Promise.reject(
              <FormMessage text1={REGISTER_ACCOUNT_INPUT_TYPE_VALIDATION} />,
            );
          }
        }

        return Promise.resolve();
      },
    }),
  ];

  return (
    <GridFormAccount>
      <GridFormAccount1to2>
        <LabelFormMyPage
          labelText={REGISTER_ACCOUNT_PHONE_NUMBER}
          required={isEdit}
        />
      </GridFormAccount1to2>
      <GridFormAccount2to3>
        <FormItem name={'phoneNumber'} rules={validateRule} extra={extra}>
          <InputStyled
            onChange={onChange}
            maxLength={maxLength ? maxLength : MAX_LENGTH_TEXT}
            placeholder={REGISTER_ACCOUNT_PLACEHOLDER_PHONE_NUMBER}
          />
        </FormItem>
      </GridFormAccount2to3>
    </GridFormAccount>
  );
};

export default PhoneNumber;
