import React, { useMemo } from 'react';
import { MoreOutlined } from '@ant-design/icons';
import isPropValid from '@emotion/is-prop-valid';
import { StyleSheetManager } from 'styled-components';
import { DropDownStyled } from 'components/Tree/components/Dropdown/styled';

// This implements the default behavior from styled-components v5
const shouldForwardProp = (propName, target) => {
  if (typeof target === 'string') {
    // For HTML elements, forward the prop if it is a valid HTML attribute
    return isPropValid(propName);
  }
  // For other elements, forward all props
  return true;
};

const RootMenu = ({ onCreateMode, showMenu }) => {
  const items = useMemo(() => {
    return [
      {
        key: '1',
        label: <div onClick={onCreateMode}>追加</div>,
      },
      // {
      //   key: '3',
      //   label: <div onClick={onFilterMenu}>コンテンツ</div>,
      // },
    ];
  }, [onCreateMode]);
  return (
    <StyleSheetManager shouldForwardProp={shouldForwardProp}>
      <DropDownStyled
        menu={{ items }}
        placement="bottom"
        opacity={showMenu ? '1' : '0'}
      >
        <MoreOutlined />
      </DropDownStyled>
    </StyleSheetManager>
  );
};

export default RootMenu;
