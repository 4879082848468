import React from 'react';
import { BtnIcon } from 'components/Button/styled';

const ButtonIcon = ({ icon, onClick, disabled, width, margin }) => {
  return (
    <BtnIcon
      disabled={disabled}
      type={'text'}
      icon={icon ?? null}
      onClick={onClick}
      style={{ width: width, margin: margin }}
    />
  );
};

export default ButtonIcon;
