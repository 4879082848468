import React, { Suspense, useEffect, useMemo, useState } from 'react';
import Breadcrumb from 'components/Layout/ApplicationLayout/Breadcrumb';
import LayoutDefault from 'components/Layout/LayoutDefault';
import useModalConfirm from 'hook/useModalConfirm';
import useCustomQuery from 'hook/useQuery/useCustomQuery';
import useCustomMutateRenderMessage from 'hook/useQuery/useCustomMutateRenderMessage';
import useErrorCodePartnerWebsiteOverView from 'page/Application/PartnerWebsite/hook/useErrorCodePartnerWebsiteOverView';
import {
  getDetailMyPageCompanyInformation,
  updateMyPageCompanyInformation,
} from 'api/myPage/partnerAdmin.api';
import { Link, useNavigate } from 'react-router-dom';
import { Alert, Form, Spin } from 'antd';
import {
  FormMyPageWrapper,
  GridFormMyPage,
  GridMyPageColumn2,
} from 'page/Application/MyPage/component/FormMyPage/styled';
import {
  AccessibleProduct,
  ButtonSubmitForm,
  CheckboxDomain,
  PartnerName,
} from 'page/Application/MyPagePartnerAdmin/component/Form';
import { FormTitle } from 'page/Application/ManageCompanyList/component/Form';

const CompanyInformationEdit = () => {
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const [domainSelected, setDomainSelected] = useState([]);
  const [listProduct, setListProduct] = useState([]);
  const [, setAddEmailDisabled] = useState(true);

  const [isDisable, setIsDisable] = useState(false);
  const { handleConfirmAction, contextHolder } = useModalConfirm();
  const { useQueryWithoutParams } = useCustomQuery();
  const { ERROR_CODE_PARTNER_OVER_VIEW } = useErrorCodePartnerWebsiteOverView();
  const { useMutationAPIHandleErrorCode, errorMessage } =
    useCustomMutateRenderMessage({ errorCode: ERROR_CODE_PARTNER_OVER_VIEW });

  const { data: companyInfoData = {}, isLoading } = useQueryWithoutParams({
    key: 'companyInformation',
    api: getDetailMyPageCompanyInformation,
    options: {
      refetchOnWindowFocus: false, // cancel fetch data when out focus
    },
  });

  const {
    mutate: updateMyPageCompanyInformationMutate,
    isError,
    isLoading: isLoadingUpdate,
    isSuccess,
  } = useMutationAPIHandleErrorCode({
    api: updateMyPageCompanyInformation,
    messageSuccess: '変更した情報を保存しました。',
    path: '/company-information',
    refetchKey: 'companyInformation',
  });

  const listItem = useMemo(() => {
    return [
      {
        title: <Link to={'/'}>パートナー専用ページ</Link>,
      },
      {
        title: '企業情報',
      },
    ];
  }, []);

  const onFinish = (value) => {
    const newDomain = domainSelected.map((item) => {
      return {
        domainId: item.domainId,
        isAutoApprove: item.isAutoApprove,
      };
    });
    let checkPartnerEmail = value.partnerEmail
      ?.filter((item) => item !== undefined)
      .map((item) => item?.trim());
    const payload = {
      ...value,
      domainRequest: newDomain,
      partnerEmail: checkPartnerEmail,
    };
    updateMyPageCompanyInformationMutate(payload);
  };

  const handleBack = () => {
    navigate('/company-information');
  };

  useEffect(() => {
    if (companyInfoData && companyInfoData.ec === false) {
      form.setFieldsValue({
        partnerName: companyInfoData.data.partnerName,
        partnerEmail: companyInfoData.data.partnerEmail,
      });
      setListProduct(companyInfoData.data.lstProductResponses);
      setDomainSelected(companyInfoData.data.lstDomainResponses);
      setAddEmailDisabled(false);
    }
  }, [companyInfoData, form, setListProduct]);

  return (
    <>
      {contextHolder}
      <Suspense fallback={isLoading}>
        <Breadcrumb listItem={listItem} titlePage={'企業情報'} />
        <LayoutDefault>
          <FormTitle
            notice={'注意：'}
            title={
              '変更した内容は画面下部の「保存」ボタンを押すと保存されます。'
            }
            type={'text'}
            styles={{ color: '#9D1C49' }}
          />
          <Spin spinning={isLoading || isLoadingUpdate || isSuccess}>
            <FormMyPageWrapper>
              {(isError || errorMessage) && (
                <GridFormMyPage style={{ marginBottom: 24 }}>
                  <GridMyPageColumn2>
                    <Alert message={errorMessage} type="error" />
                  </GridMyPageColumn2>
                </GridFormMyPage>
              )}
              <Form
                size={'Large'}
                form={form}
                disabled={false}
                onFinish={onFinish}
              >
                {/*disable value of 会社名 text box at company-information-edit screen */}
                <PartnerName editMode={true} disabled={true} />

                <CheckboxDomain
                  setDomainSelected={setDomainSelected}
                  domainSelected={domainSelected}
                />
                <AccessibleProduct productData={listProduct} />

                {/*<GridForm>*/}
                {/*  <Grid1to2>*/}
                {/*    <LabelFormMyPage labelText={'配信用メールアドレス'} />*/}
                {/*  </Grid1to2>*/}
                {/*  <Grid2to3>*/}
                {/*    <ListEmail*/}
                {/*      editMode={true}*/}
                {/*      form={form}*/}
                {/*      addEmailDisabled={addEmailDisabled}*/}
                {/*      setAddEmailDisabled={setAddEmailDisabled}*/}
                {/*    />*/}
                {/*  </Grid2to3>*/}
                {/*</GridForm>*/}

                <ButtonSubmitForm
                  setIsDisable={setIsDisable}
                  isDisable={isDisable}
                  handleBack={handleBack}
                  handleConfirmAction={handleConfirmAction}
                />
              </Form>
            </FormMyPageWrapper>
          </Spin>
        </LayoutDefault>
      </Suspense>
    </>
  );
};

export default CompanyInformationEdit;
