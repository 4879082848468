import React from 'react';
import useMultiLanguageCompanyList from 'page/Application/ManageCompanyList/CompanyList/hook/useMultiLanguageCompanyList';
import {
  PaginationStyled,
  PaginationWrapper,
  SelectStyled,
  TotalRecord,
} from 'components/Pagination/styled';
import { defaultSelectPageSizeOption } from 'constant/Pagination/pageSizeOption';

const Pagination = ({
  total,
  currentPage,
  handlePagingChange,
  onClick,
  totalTitle,
  pageSize,
  selectPageSizeOptions,
  handleChangePageSize,
}) => {
  const {
    COMPANY_LIST_TABLE_PAGINATION_TOTAL,
    COMPANY_LIST_TABLE_PAGE_SIZE_TEXT,
  } = useMultiLanguageCompanyList();

  const customLocale = {
    items_per_page: COMPANY_LIST_TABLE_PAGE_SIZE_TEXT,
  };

  return (
    <>
      <PaginationWrapper onClick={onClick}>
        <TotalRecord>
          <span>
            {totalTitle ? totalTitle : COMPANY_LIST_TABLE_PAGINATION_TOTAL}:{' '}
            {total}
          </span>
        </TotalRecord>
        <PaginationStyled
          total={total}
          hideOnSinglePage={false}
          current={currentPage}
          pageSize={pageSize}
          onChange={handlePagingChange}
          showSizeChanger={false}
          locale={customLocale}
          showTitle={false}
        />
        <SelectStyled
          value={pageSize}
          onChange={handleChangePageSize}
          options={selectPageSizeOptions ?? defaultSelectPageSizeOption}
          title={''}
        />
      </PaginationWrapper>
    </>
  );
};

export default Pagination;
