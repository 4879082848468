import React, { useRef } from 'react';

/**
 * columns: is columns for table.
 * dataSource: is data for render table, type array, every object in dataSource NEED UNIQUE KEY.
 * rowSelection: render checkbox per row, selected record.
 * isLoading: render loading when API fetching.
 * expandable: row collapse, render columns expand
 * scroll: take value FALSE | UNDEFINED, with value === false, turn off scroll bar
 */
import { InboxOutlined } from '@ant-design/icons';
import { Empty } from 'antd';
import useMultiLanguageCompanyList from 'page/Application/ManageCompanyList/CompanyList/hook/useMultiLanguageCompanyList';
import { TableStyled } from './styled';

const AntdTableDraggable = ({
  columns,
  dataSource,
  rowSelection,
  isLoading,
  expandable,
  emptyText,
  onChange,
  height,
  showHeader,
  components,
  key,
  isBoldBottomBorder,
}) => {
  const { COMPANY_LIST_NO_DATA } = useMultiLanguageCompanyList();
  const tableRef = useRef();
  const CustomEmptyText = () => {
    return (
      <Empty
        image={<InboxOutlined />}
        imageStyle={{ fontSize: 60, height: 62 }}
        description={null}
        style={{ fontSize: 16 }}
      >
        {emptyText || COMPANY_LIST_NO_DATA}
      </Empty>
    );
  };
  return (
    <TableStyled
      key={dataSource?.length}
      rowKey="key"
      style={{ height: height }} // set min height for list table when data null or data length < 10
      ref={tableRef}
      columns={columns}
      rowSelection={rowSelection}
      // scroll={
      components={components}
      //   dataSource?.length > 8 ? scroll ?? { y: `calc(100dvh - 468px` } : 0
      // }
      expandable={expandable}
      dataSource={dataSource}
      pagination={false}
      showSizeChanger={true}
      onChange={onChange}
      loading={isLoading}
      showHeader={showHeader}
      locale={{ emptyText: CustomEmptyText }}
      isBoldBottomBorder={isBoldBottomBorder}
    />
  );
};

export default AntdTableDraggable;
