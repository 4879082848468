import styled from 'styled-components';
import { Input, Select } from 'antd';

const { Search } = Input;
export const HeaderTable = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  align-items: center;
  margin: 7px 0 15px 0;
  background: #d9d9d94d;
  border-radius: 6px;
  padding: 10px;
`;

export const TitleScreen = styled.h2`
  font-family: 'inter-semibold', sans-serif;
  font-size: 16px;
  font-weight: 600;
  color: var(--FORM-LABEL-default);
  //padding: 10px;
  ${(props) => props?.shortenTitle && 'text-overflow: ellipsis;'}
  ${(props) => props?.shortenTitle && 'white-space: nowrap;'}
  ${(props) => props?.shortenTitle && 'overflow: hidden;'}
  ${(props) => props?.shortenTitle && 'width: 69%;'}
`;

export const GroupButton = styled.div`
  display: flex;
  align-items: center;

  gap: 14px;

  @media all and (min-width: 0px) and (max-width: 320px) {
    flex-wrap: wrap;
  }
  @media all and (min-width: 320px) and (max-width: 430px) {
    flex-wrap: wrap;
  }

  .ant-input {
    height: 36px;
    font-size: 14px;

    &::placeholder {
      font-size: 14px;
    }
  }

  .ant-btn {
    height: 36px;
  }
`;
export const SelectStyled = styled(Select)`
  width: 120px;
  min-width: 120px;
  height: 36px;
  flex-grow: 1;
  span {
    font-family: 'inter-regular', sans-serif;
    font-weight: 500;
    color: var(--FORM-LABEL-default);
  }
`;

export const SearchStyled = styled(Search)`
  flex-grow: 1;
`;
