import React, { useMemo } from 'react';
import { Tooltip } from 'antd';
import {
  product,
  idProduct,
} from 'recoil/atom/selectProduct';
import { useRecoilState } from 'recoil';
import AntdTableModal from 'components/AntdTableModal';
import useMultiLanguageRegisterCompany from 'page/Application/ManageCompanyList/RegisterCompany/hook/useMultiLanguageRegisterCompany';
import { listProductType } from 'page/Application/ManageCompanyList/component/ListProduct';

/**
 * dataRaw: data get from api.
 * isLoading: render loading when api fetching.
 */

const TableListProduct = ({ dataRaw, isLoading, emptyText }) => {
  const { MODAL_PRODUCTS_TABLE } = useMultiLanguageRegisterCompany();

  const [productSelected, setProductSelected] = useRecoilState(product);
  const [idProductSelected, setIdProductSelected] = useRecoilState(idProduct);

  const columns = [
    {
      title: MODAL_PRODUCTS_TABLE,
      dataIndex: 'productName',
      render: (text, record) => {
        return (
          <div
            title={text}
            style={{
              whiteSpace: 'nowrap',
              overflow: 'hidden',
              width: 450,
              textOverflow: 'ellipsis',
            }}
          >
            <span style={{ marginRight: 4 }}>
              【{listProductType[record?.type]}】
            </span>
            <span>
              <Tooltip title={text} placement={'topLeft'}>
                {text}
              </Tooltip>
            </span>
          </div>
        );
      },
    },
  ];

  const dataSource = useMemo(() => {
    if (dataRaw && dataRaw?.data && dataRaw?.ec === false) {
      const listProduct = (dataRaw.data.listProduct ??= []);
      return listProduct?.map((item) => ({
        ...item,
        key: item.id,
        productId: item.id,
      }));
    } else return [];
  }, [dataRaw]);

  const rowSelection = {
    onSelectAll: (selected, selectedRows, changeRows) => {

      // chua co product
      if(idProductSelected === undefined || idProductSelected.length === 0 ) {
        if(selected) {
          setProductSelected(selectedRows);
          const getListId = selectedRows.map((item) => {
            return item.productId;
          });
          setIdProductSelected(getListId);
        } else {
          setProductSelected([]);
          setIdProductSelected([]);
        }
      } else {
        // da co product
        if (selected) {
          setProductSelected([...productSelected, ...changeRows]);
          const getListId = changeRows.map((item) => {
            return item.productId;
          });
          setIdProductSelected([...idProductSelected, ...getListId]);
        } else {
          setProductSelected([]);
          setIdProductSelected([]);
        }
      }
    },

    onSelect: (record) => {
      // select product
      if (idProductSelected === undefined || idProductSelected?.length === 0) {
        setProductSelected([record]);
        setIdProductSelected([record.productId]);
      } else {
        // product not selected
        const checkDuplicate = productSelected.find(
          (item) => item.productId === record.productId);
        // check duplicate product
        if (!!checkDuplicate === false) {
          setProductSelected([...productSelected, record]);
          setIdProductSelected([...idProductSelected, record.productId]);
        } else {
          // remove product
          const removeItem = productSelected.filter(
            (item) => item.productId !== record.productId,
          );
          const removeId = idProductSelected.filter(
            (item) => item !== record.productId,
          );
          setProductSelected(removeItem);
          setIdProductSelected(removeId);
        }
      }
    },
    selectedRowKeys: idProductSelected,
    preserveSelectedRowKeys: true,
  };

  return (
    <AntdTableModal
      columns={columns}
      dataSource={dataSource}
      rowSelection={rowSelection}
      emptyText={emptyText}
      isLoading={isLoading}
    />
  );
};

export default TableListProduct;