import React, { Suspense, useEffect, useState } from 'react';
import { Container, FormWrapper } from 'components/Form/Template/styled';
import { FormLabelGrid, FormFooterSubmit } from 'components/Form';
import { Alert, Form, Input, Radio, Spin } from 'antd';
import {
  Grid1,
  Grid2,
  Grid3,
  GridLayout,
} from 'components/Form/Template/FormAccountSoliton/styled';
import {
  FormValidateText,
  FormEmailSelectDomain,
  FormValidateStrongPassword,
} from 'components/Form2';
import FormMessage from 'components/Form/FormMessage';
import { InputStyled, SelectStyled } from 'components/Form2/styled';
import useAccountStatusMultiLanguage from 'hook/useAccountStatusMultiLanguage';
import useAccountType from 'hook/useAccountType';
import { useNavigate } from 'react-router-dom';
import useModalConfirm from 'hook/useModalConfirm';
import { ButtonBasic } from 'components/Button';
import useMultiLanguage from 'hook/useMultiLanguage';
import useMultiLanguageRegisterAccount from 'page/Application/ManageAccountSoliton/RegisterAccountSoliton/hook/useMultiLanguageRegisterAccount';
import FormAddPartner from 'page/Application/ManageAccountSoliton/RegisterAccountSoliton/component/FormAddPartner';
import { FormTitle } from 'page/Application/ManageCompanyList/component/Form';
const FormAccountSoliton = ({
  isEdit,
  titleForm,
  domainList,
  setRole,
  onFinish,
  role,
  isError,
  isDisable,
  setIsDisable,
  isDisableDelete,
  setIsDisableDelete,
  formDetailData,
  isLoading,
  isLoadingSubmit,
  errorMessage,
  toggleChangePassword,
  setToggleChangePassword,
  handleDeleteAccountPartner,
  setListSelectedPartner,
  type,
  isSuccessSubmit,
}) => {
  const navigate = useNavigate();
  const {
    REGISTER_ACCOUNT_EMAIL,
    REGISTER_ACCOUNT_FIRSTNAME,
    REGISTER_ACCOUNT_LASTNAME,
    REGISTER_ACCOUNT_PASSWORD,
    REGISTER_ACCOUNT_ACCOUNT_ROLE,
    REGISTER_ACCOUNT_ACCOUNT_STATUS,
    REGISTER_ACCOUNT_CHANGE,
    REGISTER_ACCOUNT_SUBMIT_SIGNUP,
    REGISTER_ACCOUNT_SUBMIT_EDIT,
    REGISTER_ACCOUNT_DELETE,
    REGISTER_ACCOUNT_CANCEL,
    REGISTER_ACCOUNT_PLACEHOLDER_LAST_NAME,
    REGISTER_ACCOUNT_PLACEHOLDER_FIRST_NAME,
    REGISTER_ACCOUNT_PLACEHOLDER_PASSWORD,
    REGISTER_ACCOUNT_CATEGORY_ENTRY_REQUIRED,
    REGISTER_ACCOUNT_CONFIRM_CANCEL_REGISTER,
    REGISTER_ACCOUNT_CONFIRM_CANCEL_EDIT,
    REGISTER_ACCOUNT_CONFIRM_DELETE_EDIT,
    REGISTER_ACCOUNT_EMAIL_ALREADY_EXISTED,
  } = useMultiLanguageRegisterAccount();
  const { APPLICATION_OK_TEXT, APPLICATION_CANCEL_TEXT } = useMultiLanguage();
  const { ACCOUNT_STATUS } = useAccountStatusMultiLanguage();
  const { ACCOUNT_TYPE_SOLITON } = useAccountType();
  const { handleConfirmAction, contextHolder } = useModalConfirm();
  const [form] = Form.useForm();
  const [disableAddPartner, setDisableAddPartner] = useState(true);

  const onChange = (e) => {
    setRole(e.target.value);
  };

  const handleCancel = () => {
    navigate('/manage-account-soliton');
  };

  const handleToggleChangePassword = () => {
    setToggleChangePassword(!toggleChangePassword);
  };

  useEffect(() => {
    if (isEdit && formDetailData?.data) {
      form.setFieldsValue(formDetailData?.data);
    }
    if (formDetailData && formDetailData.ec === false && formDetailData?.data) {
      const { partnerInCharge } = formDetailData?.data;
      // form.setFieldsValue({
      //   partnerInCharge: partnerInCharge?.partnerId,
      // });
      if (partnerInCharge?.length > 0) {
        const listId = partnerInCharge?.map((item) => item?.partnerId);
        const listPartner = partnerInCharge?.map((item) => ({
          // format list partner for render table
          ...item,
          key: item?.partnerId,
        }));
        setListSelectedPartner({
          listIdPartner: listId,
          listRecordPartner: listPartner,
        });
      }
    }
  }, [isEdit, formDetailData, form, setListSelectedPartner]);

  // set disable add partner when change role
  const handleOnchangeRole = (value) => {
    if (value === 'ROLE_SOLITON_ADMIN') {
      setDisableAddPartner(true);
    } else {
      setDisableAddPartner(false);
    }
  };

  // check role and set disable add partner for
  useEffect(() => {
    const role = formDetailData?.data?.role;

    if (role === undefined || role === 'ROLE_SOLITON_ADMIN') {
      setDisableAddPartner(true);
    } else {
      setDisableAddPartner(false);
    }
  }, [formDetailData?.data?.role]);

  return (
    <Suspense fallback={isLoading}>
      {contextHolder}
      <FormTitle
        notice={'注意：'}
        title={titleForm}
        type={type}
        styles={{ color: isEdit ? '#9D1C49' : '#656464' }}
      />
      <FormWrapper>
        <Container>
          <Spin spinning={isLoading || isLoadingSubmit || isSuccessSubmit}>
            <Form
              name="register-account-soliton"
              form={form}
              layout="horizontal"
              size="large"
              onFinish={onFinish}
            >
              <GridLayout>
                <Grid2>
                  {(isError || errorMessage) && (
                    <Alert
                      //Change the error display because the user wants to return messages containing duplicate emails
                      message={
                        errorMessage === REGISTER_ACCOUNT_EMAIL_ALREADY_EXISTED
                          ? `{${
                              form.getFieldValue('email') ??
                              'このメールアドレス'
                            }} ${REGISTER_ACCOUNT_EMAIL_ALREADY_EXISTED}`
                          : errorMessage
                      }
                      type="error"
                      style={{ marginBottom: '24px' }}
                    />
                  )}
                </Grid2>
                {/* start last name */}
                <Grid1>
                  <FormLabelGrid
                    labelText={REGISTER_ACCOUNT_LASTNAME}
                    required={true}
                  />
                </Grid1>
                <Grid2>
                  <FormValidateText
                    name="lastName"
                    required={true}
                    placeholder={REGISTER_ACCOUNT_PLACEHOLDER_LAST_NAME}
                  />
                </Grid2>
                {/* end last name */}
                {/* start first name */}
                <Grid1>
                  <FormLabelGrid
                    labelText={REGISTER_ACCOUNT_FIRSTNAME}
                    required={true}
                  />
                </Grid1>
                <Grid2>
                  <FormValidateText
                    name="firstName"
                    required={true}
                    placeholder={REGISTER_ACCOUNT_PLACEHOLDER_FIRST_NAME}
                  />
                </Grid2>
                {/* end first name */}
                {/* start email */}
                {isEdit ? (
                  <>
                    <Grid1>
                      <FormLabelGrid
                        labelText={REGISTER_ACCOUNT_EMAIL}
                        required={true}
                      />
                    </Grid1>
                    <Grid2>
                      <Form.Item name={'email'}>
                        <Input disabled />
                      </Form.Item>
                    </Grid2>
                  </>
                ) : (
                  <>
                    <Grid1>
                      <FormLabelGrid
                        labelText={REGISTER_ACCOUNT_EMAIL}
                        required={true}
                      />
                    </Grid1>
                    <Grid2>
                      <FormEmailSelectDomain
                        isEdit={false}
                        domainList={domainList}
                      />
                    </Grid2>
                  </>
                )}
                {/* end email */}
                {/* start password */}
                <Grid1>
                  <FormLabelGrid
                    labelText={REGISTER_ACCOUNT_PASSWORD}
                    required={true}
                  />
                </Grid1>
                <Grid2>
                  {toggleChangePassword ? (
                    <Form.Item
                      style={{
                        marginBottom: 30,
                      }}
                    >
                      <InputStyled
                        value={'パスワードパスワード'}
                        autoComplete="new-password"
                        disabled
                        type={'password'}
                      />
                    </Form.Item>
                  ) : (
                    <FormValidateStrongPassword
                      placeholder={REGISTER_ACCOUNT_PLACEHOLDER_PASSWORD}
                      name="password"
                      maxLength={256}
                      message={REGISTER_ACCOUNT_CATEGORY_ENTRY_REQUIRED}
                      required={true}
                    />
                  )}
                </Grid2>
                {toggleChangePassword && (
                  <Grid3>
                    {isEdit && (
                      <ButtonBasic
                        block={true}
                        text={
                          /*must have a span tag so there are no spaces between letters*/
                          <span>
                            {' '}
                            {toggleChangePassword
                              ? REGISTER_ACCOUNT_CHANGE
                              : REGISTER_ACCOUNT_CANCEL}
                          </span>
                        }
                        onClick={handleToggleChangePassword}
                      />
                    )}
                  </Grid3>
                )}
                {/* end password */}

                {/* start account type */}
                <Grid1>
                  <FormLabelGrid
                    labelText={REGISTER_ACCOUNT_ACCOUNT_ROLE}
                    required={true}
                  />
                </Grid1>
                <Grid2>
                  <Form.Item
                    name="role"
                    required={true}
                    rules={[
                      {
                        required: true,
                        message: (
                          <FormMessage
                            text1={REGISTER_ACCOUNT_CATEGORY_ENTRY_REQUIRED}
                          />
                        ),
                      },
                    ]}
                  >
                    <SelectStyled
                      options={ACCOUNT_TYPE_SOLITON}
                      onChange={(value) => {
                        handleOnchangeRole(value);
                      }}
                    />
                  </Form.Item>
                </Grid2>
                {/* end account type */}

                {/* start account status */}
                <Grid1>
                  <FormLabelGrid
                    labelText={REGISTER_ACCOUNT_ACCOUNT_STATUS}
                    required={false}
                  />
                </Grid1>
                <Grid2>
                  <Form.Item>
                    <Radio.Group
                      onChange={onChange}
                      value={role}
                      name="approveStatus"
                    >
                      <Radio value={ACCOUNT_STATUS.enable.value}>
                        {ACCOUNT_STATUS.enable.label}
                      </Radio>
                      {/*<Radio value={ACCOUNT_STATUS.unapproved.value}>*/}
                      {/*  {ACCOUNT_STATUS.unapproved.label}*/}
                      {/*</Radio>*/}
                      <Radio value={ACCOUNT_STATUS.suspended.value}>
                        {ACCOUNT_STATUS.suspended.label}
                      </Radio>
                    </Radio.Group>
                  </Form.Item>
                </Grid2>
                {/* end account status */}

                <FormAddPartner disableAddPartner={disableAddPartner} />

                <Grid2>
                  {/*must have a span tag so there are no spaces between letters*/}
                  <FormFooterSubmit
                    isLoadingSubmit={isLoadingSubmit}
                    isDisable={isDisable}
                    isDisableDelete={isDisableDelete}
                    onDelete={() => {
                      handleConfirmAction({
                        confirmFn: handleDeleteAccountPartner,
                        title:
                          formDetailData?.data?.fullName +
                          REGISTER_ACCOUNT_CONFIRM_DELETE_EDIT,
                        cancelText: <span> {REGISTER_ACCOUNT_CANCEL}</span>,
                        okText: <span> {REGISTER_ACCOUNT_DELETE}</span>,
                        setIsDisable: setIsDisableDelete,
                      });
                      setIsDisableDelete(true);
                    }}
                    onCancel={() => {
                      handleConfirmAction({
                        confirmFn: handleCancel,
                        title: (
                          <span>
                            {isEdit
                              ? REGISTER_ACCOUNT_CONFIRM_CANCEL_EDIT
                              : REGISTER_ACCOUNT_CONFIRM_CANCEL_REGISTER}
                          </span>
                        ),
                        cancelText: <span> {APPLICATION_CANCEL_TEXT}</span>,
                        okText: <span> {APPLICATION_OK_TEXT}</span>,
                        setIsDisable: setIsDisable,
                      });
                      setIsDisable(true);
                    }}
                    isDelete={isEdit}
                    textOk={
                      isEdit
                        ? REGISTER_ACCOUNT_SUBMIT_EDIT
                        : REGISTER_ACCOUNT_SUBMIT_SIGNUP
                    }
                    textCancel={REGISTER_ACCOUNT_CANCEL}
                    textDelete={REGISTER_ACCOUNT_DELETE}
                  />
                </Grid2>
              </GridLayout>
            </Form>
          </Spin>
        </Container>
      </FormWrapper>
    </Suspense>
  );
};

export default FormAccountSoliton;
