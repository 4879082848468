import React, { useEffect, useMemo, useState } from 'react';
import LayoutDefault from 'components/Layout/LayoutDefault';
import Breadcrumb from 'components/Layout/ApplicationLayout/Breadcrumb';
import FormAccountSoliton from 'components/Form/Template/FormAccountSoliton';
import useCustomQuery from 'hook/useQuery/useCustomQuery';
import useAccountStatusMultiLanguage from 'hook/useAccountStatusMultiLanguage';
import useCustomMutateRenderMessage from 'hook/useQuery/useCustomMutateRenderMessage';
import useMultiLanguageRegisterAccount
  from 'page/Application/ManageAccountSoliton/RegisterAccountSoliton/hook/useMultiLanguageRegisterAccount';
import useErrorCodeManageAccountSoliton
  from 'page/Application/ManageAccountSoliton/hook/useErrorCodeManageAccountSoliton';
import useMessageApi from 'hook/useMessageApi';
import {
  getListDomain,
  registerAccountPartner,
} from 'api/account/partnerAccount.api';
import { useRecoilState, useRecoilValue } from 'recoil';
import { Link } from 'react-router-dom';
import { listPartner } from 'recoil/atom/selectPartner';
import { network } from 'recoil/atom/network';

const RegisterAccountSoliton = () => {
  const { useQueryAPI } = useCustomQuery();
  const { ACCOUNT_STATUS } = useAccountStatusMultiLanguage();
  const { ERROR_CODE_MANAGE_ACCOUNT_SOLITON } =
    useErrorCodeManageAccountSoliton();
  const { errorMessage, useMutationAPIHandleErrorCode } =
    useCustomMutateRenderMessage({
      errorCode: ERROR_CODE_MANAGE_ACCOUNT_SOLITON,
    });
  const { SOLITON_ACCOUNT_LIST_TITLE, REGISTER_ACCOUNT_REGISTER_SUCCESS } =
    useMultiLanguageRegisterAccount();
  const {handleErrorNetwork,contextHolderMessageApi} = useMessageApi()

  // const [messageApi, contextHolder] = message.useMessage();

  const isOnline = useRecoilValue(network);
  const [listSelected, setListSelectedPartner] = useRecoilState(listPartner);

  const [isDisable, setIsDisable] = useState(false);
  const [role, setRole] = useState(ACCOUNT_STATUS.enable.value);

// call API
  const { data: domainList = [], isLoading } = useQueryAPI({
    api: getListDomain,
    key: 'domain',
    params: {
      isPartner: false,
    },
  });

  const {
    mutate: registerAccountSolitonMutate,
    isError,
    isLoading: isLoadingSubmit,
    isSuccess: isSuccessSubmit,
  } = useMutationAPIHandleErrorCode({
    api: registerAccountPartner,
    messageSuccess: REGISTER_ACCOUNT_REGISTER_SUCCESS,
    refetchKey: 'accountListSoliton',
    path: '/manage-account-soliton',
  });

  const onFinish = (value) => {
    if (isOnline) {
      const payload = {
        email: value.email,
        password: value.password,
        firstName: value.firstName,
        lastName: value.lastName,
        position: value.position,
        department: value.department,
        duty: value.duty,
        role: value.role,
        approveStatus: role,
        partnerInCharge: listSelected.listIdPartner,
      partnerId: 0,
      };
      registerAccountSolitonMutate(payload);

    } else {
      handleErrorNetwork()
    }

  };


  useEffect(() => {
    setListSelectedPartner({
      display: false,
      listIdPartner: [],
      listRecordPartner: [],
    });
  }, [setListSelectedPartner]);

  const listItem = useMemo(() => {
    return [
      {
        title: <Link to={'/'}>企業管理</Link>,
      },
      {
        title: (
          <Link to={'/manage-account-soliton'}>ソリトンアカウント管理</Link>
        ),
      },
      {
        title: 'ソリトンアカウント登録',
      },
    ];
  }, []);

  return (
    <>
      {contextHolderMessageApi}
      <Breadcrumb listItem={listItem} titlePage="ソリトンアカウント登録" />
      <LayoutDefault>
        <FormAccountSoliton
          titleForm={SOLITON_ACCOUNT_LIST_TITLE}
          isLoading={isLoading}
          isLoadingSubmit={isLoadingSubmit}
          isEdit={false}
          domainList={domainList}
          onFinish={onFinish}
          role={role}
          setRole={setRole}
          errorMessage={errorMessage}
          isError={isError}
          isDisable={isDisable}
          setIsDisable={setIsDisable}
          type={'title'}
          isSuccessSubmit={isSuccessSubmit}
        />
      </LayoutDefault>
    </>
  );
};

export default RegisterAccountSoliton;
