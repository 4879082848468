import React from 'react';
import ProjectList from 'page/Application/ManageProjectRegistration/ProjectList';
import RegisterProject from 'page/Application/ManageProjectRegistration/RegisterProject';
import EditProject from 'page/Application/ManageProjectRegistration/EditProject';

import { Outlet } from 'react-router-dom';
import useSetTitlePage from 'hook/useSetTitlePage';

const ManageProjectRegistration = () => {
  useSetTitlePage('案件登録管理');

  return <Outlet />;
};

export default ManageProjectRegistration;

export { ProjectList, RegisterProject, EditProject };
