import React from 'react';
import {
  ButtonDelete,
  FormFooterWrapper,
} from 'page/Application/ManageNotificationList/component/Form/FormFooterSubmit/styled';
import {
  ButtonPreview,
  ButtonStyle,
  SpanPreview,
} from 'page/Application/ManageNotificationList/component/Form/styled';
import useMultiLanguageEditCompany from 'page/Application/ManageCompanyList/EditCompany/hook/useMultiLanguageEditCompany';

const FormFooterSubmit = ({
  textOk,
  textCancel,
  textDelete,
  onCancel,
  onFinish,
  onDelete,
  isDelete,
  isLoading,
  isDisable,
  onPreview,
  isPreview,
  isCancel,
}) => {
  const { DETAIL_COMPANY_BUTTON_DELETE } = useMultiLanguageEditCompany();
  return (
    <FormFooterWrapper>
      <ButtonStyle
        type="primary"
        htmlType="submit"
        onClick={onFinish}
        loading={isLoading}
        disabled={isDisable}
      >
        {/*must have a span tag so there are no spaces between letters*/}
        <span> {textOk}</span>
      </ButtonStyle>
      {/*<ButtonPreview onClick={onPreview} disabled={isPreview}>*/}
      {/*  <span>Preview</span>*/}
      {/*</ButtonPreview>*/}
      {isDelete && (
        <ButtonDelete
          disabled={isDisable}
          onClick={onDelete}
          loading={isLoading}
        >
          {/*must have a span tag so there are no spaces between letters*/}
          <span> {textDelete ?? DETAIL_COMPANY_BUTTON_DELETE}</span>
        </ButtonDelete>
      )}
      <ButtonStyle disabled={isCancel} onClick={onCancel}>
        {/*must have a span tag so there are no spaces between letters*/}
        <span> {textCancel}</span>
      </ButtonStyle>
      <ButtonPreview
        type="link"
        onClick={onPreview}
        disabled={isPreview}
        style={{ paddingLeft: isDelete ? '60px' : '180px' }}
      >
        <SpanPreview>{'プレビュー'}</SpanPreview>
      </ButtonPreview>
    </FormFooterWrapper>
  );
};

export default FormFooterSubmit;
