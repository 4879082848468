import styled from 'styled-components';

export const ProductContainer = styled.div`
  color: var(--FORM-LABEL-default);
  padding: 25px 10px 0;
  @media all and (min-width: 0px) and (max-width: 320px) {
    padding: 8px 8px 0;
  }
  @media all and (min-width: 320px) and (max-width: 430px) {
    padding: 8px 8px 0;
  }
`;

export const Dot = styled.div`
    width: 4px;
    height: 4px;
    border-radius: 50%;
    background-color: #d9d9d9;
    margin: 10px
`
export const TitleProduct = styled.p`
    font-family: 'inter-regular', sans-serif;
    line-height: 1.5;
    font-size: 16px;
    font-weight: 400;
    color: #656464;
    word-break: break-word;

    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;

    @media all and (min-width: 0px) and (max-width: 320px) {
        font-size: 12px;
        text-underline-offset: 2px;
    }
    @media all and (min-width: 320px) and (max-width: 430px) {
        font-size: 12px;
        text-underline-offset: 2px;
    }

    span {
        text-underline-offset: 4px;
        text-decoration: underline;

        &:hover {
            color: #1677ff;
            cursor: pointer;
        }
    }
`;

export const ProductItem = styled.div`
  margin-bottom: 20px;
  display: flex;
    
  @media all and (min-width: 0px) and (max-width: 320px) {
    margin-bottom: 10px;
  }
  @media all and (min-width: 320px) and (max-width: 430px) {
    margin-bottom: 10px;
  }
`;
