import { useIntl } from 'react-intl';

const useMultiLanguageDetailContentEditMode = () => {
  const intl = useIntl();

  const APPLICATION_TOPPAGE_MESSAGE_EDIT_SUCCESS = intl.formatMessage({
    id: 'application.toppage.message.edit.success',
  });

  return {
    APPLICATION_TOPPAGE_MESSAGE_EDIT_SUCCESS,
  };
};

export default useMultiLanguageDetailContentEditMode;
