import React from 'react';
import {
  BreakDownContentText,
  ItemNotify,
  ItemWrapper,
  TextDateNotify,
  TextNotify,
} from 'page/Application/PartnerWebsite/styled';

import dayjs from 'dayjs';
import { useNavigate } from 'react-router-dom';
import { Empty } from 'antd';
import { InboxOutlined } from '@ant-design/icons';

const ListOverViewNotification = ({
  partnerId,
  formatData,
  displayForOverview,
  marginDateForOverview,
  isOnline,
  errorNetWork,
}) => {
  const navigate = useNavigate();
  const handleNavigateToDetail = (id) => {
    if (isOnline) {
      let dataDetail;
      formatData.forEach((data) => {
        if (data.noticeId === id) {
          dataDetail = data;
        }
      });
      navigate(`detail/${dataDetail.noticeId}`, {
        state: { noticeId: dataDetail.noticeId, partnerId: partnerId },
      });
    } else {
      errorNetWork();
    }
  };

  return formatData?.length > 0 ? (
    <ItemWrapper>
      {formatData?.map((item) => {
        return (
          <ItemNotify
            key={item.noticeId}
            style={{ display: displayForOverview, marginBottom: 20 }}
          >
            <TextNotify>
              <BreakDownContentText
                hasdetail={item?.noticeContent?.length}
                onClick={() => {
                  item?.noticeContent && handleNavigateToDetail(item.noticeId);
                }}
              >
                {item.noticeTitle}
              </BreakDownContentText>
            </TextNotify>
            <TextDateNotify style={{ marginLeft: marginDateForOverview }}>
              {item.noticeDisplayDate
                ? dayjs(item.noticeDisplayDate).format('YYYY年MM月DD日')
                : ''}
            </TextDateNotify>
          </ItemNotify>
        );
      })}
    </ItemWrapper>
  ) : (
    <Empty
      image={<InboxOutlined />}
      imageStyle={{ fontSize: 80 }}
      description={null}
      style={{
        fontSize: '16px',
        fontFamily: 'inter-regular, sans-serif',
        color: '#656464',
        marginTop: 40,
      }}
    >
      データがありません
    </Empty>
  );
};

export default ListOverViewNotification;
