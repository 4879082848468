import axiosClient from 'api/config/axiosClient';

// get list account soliton
const getListAccountSoliton = ({
  pageNumber,
  pageSize,
  keyword,
  isPartner,
  displayedDateFrom,
  displayedDateTo,
  category,
  status,
  sortField,
  sortDirection,
}) => {
  const url = `v1/account?pageNumber=${pageNumber}&pageSize=${pageSize}&keyword=${keyword}&displayedDateFrom=${displayedDateFrom}&displayedDateTo=${displayedDateTo}&category=${category}&status=${status}&sortField=${sortField}&sortDirection=${sortDirection}&isPartner=${isPartner}`;
  return axiosClient.get(url);
};

export { getListAccountSoliton };
