import styled from 'styled-components';

export const ListPartnerWrapper = styled.ul`
  padding-top: 8px;
`;

export const LinkToPartnerSite = styled.span`
  max-width: 430px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  &:hover {
    cursor: ${(props) => props?.enablenavigation && 'pointer'};
    opacity: ${(props) => props?.enablenavigation && '0.7'};
  }
`;

export const PartnerNameItems = styled.div`
  //use overflow and type of text ellipsis, set max width
  font-family: inter-regular, sans-serif;
  text-decoration: underline;
  color: #0066ff;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  @media all and (min-width: 1025px) and (max-width: 1200px) {
    max-width: 240px;
  }
  @media all and (min-width: 1201px) and (max-width: 1440px) {
    max-width: 340px;
  }
`;

export const PartnerItem = styled.li`
  &:not(&:last-child) {
    margin-bottom: 6px;
  }

  a {
    min-height: 12px;
    display: block;
    padding: 8px 0 0;
    border-radius: 6px;
    color: #0066ff;
    text-decoration: underline;

    &:hover {
      //background: #f9f9f9;
      color: #4f92ff;
    }
  }
`;
