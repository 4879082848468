import React, { Suspense, useEffect, useMemo, useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import {
  getDetailPartner,
  updateDetailPartner,
  deletePartner,
} from 'api/partner/partner.api';
import { useRecoilState } from 'recoil';
import { idProduct, listProduct, product } from 'recoil/atom/selectProduct';
import { partnerIdRecoil } from 'recoil/atom/authentication';
import {
  FormPermission,
  FormTitle,
} from 'page/Application/ManageCompanyList/component/Form';
import { ButtonNavigate } from 'components/Button';
import useCustomQuery from 'hook/useQuery/useCustomQuery';
import useCustomMutate from 'hook/useQuery/useCustomMutate';
import useModalConfirm from 'hook/useModalConfirm';
import FormPartner from 'page/Application/ManageCompanyList/component/FormPartner';
import useCustomMutateRenderMessage from 'hook/useQuery/useCustomMutateRenderMessage';
import useErrorCodeManageCompany from 'page/Application/ManageCompanyList/hook/useErrorCodeManageCompany';
import useMultiLanguageEditCompany from 'page/Application/ManageCompanyList/EditCompany/hook/useMultiLanguageEditCompany';
import useMultiLanguageAccountSolitonList from 'page/Application/ManageAccountSoliton/AccountSolitonList/hook/useMultiLanguageManageAccountSolitonList';
import LayoutDefault from 'components/Layout/LayoutDefault';
import Breadcrumb from 'components/Layout/ApplicationLayout/Breadcrumb';
import { Form, Spin } from 'antd';
import { Container, FormWrapper } from 'components/Form/Template/styled';
import useErrorNetworkMessage from 'hook/useErrorNetworkMessage';
import { queryClient } from '../../../../api/config/queryClient';

const checkSpace = /\s/g;

const EditCompany = () => {
  // recoil
  const [listSelectedProduct, setListSelectedProduct] =
    useRecoilState(listProduct);
  const [, setProductSelected] = useRecoilState(product);
  const [, setIdProductSelected] = useRecoilState(idProduct);

  const [, setPartnerId] = useRecoilState(partnerIdRecoil);
  const { isOnline, errorNetWork, contextErrorNetWork } =
    useErrorNetworkMessage();
  // useState
  const [listPermissionUser, setListPermissionUser] = useState([]);
  const [isDisable, setIsDisable] = useState(false);
  const [isAvailable, setIsAvailable] = useState(true);
  const [isDomainAvailable, setIsDomainAvailable] = useState(true);

  // hook
  const navigate = useNavigate();
  const { partnerId } = useParams();
  const [form] = Form.useForm();

  // custom hook
  const { handleConfirmAction, contextHolder } = useModalConfirm();
  const { ERROR_CODE_MANAGE_COMPANY } = useErrorCodeManageCompany();
  const { ACCOUNT_LIST_DELETE_SUCCESS } = useMultiLanguageAccountSolitonList();
  const { useQueryAPI } = useCustomQuery();
  const { useMutationAPI } = useCustomMutate();
  const { useMutationAPIHandleErrorCode, errorMessage } =
    useCustomMutateRenderMessage({ errorCode: ERROR_CODE_MANAGE_COMPANY });
  const {
    DETAIL_COMPANY_TITLE,
    DETAIL_COMPANY_MESSAGE_SUCCESS,
    DETAIL_COMPANY_CONFIRM_CANCEL,
    DETAIL_COMPANY_CONFIRM_SAVE,
    DETAIL_COMPANY_CONFIRM_DELETE,
  } = useMultiLanguageEditCompany();

  // breadcrumb item
  const listItem = useMemo(() => {
    return [
      {
        title: <Link to={'/manage-company-list'}>企業管理</Link>,
      },
      {
        title: '企業詳細',
      },
    ];
  }, []);

  // call API fetch data partner detail
  const {
    data: partnerDetail,
    isLoading,
    error,
  } = useQueryAPI({
    api: getDetailPartner,
    params: {
      partnerId: partnerId,
    },
    key: 'partnerDetail',
    enabled: !!partnerId,
    options: {
      refetchOnWindowFocus: false, // cancel fetch data when out focus
      cacheTime: 0,
    },
  });

  // call API delete partner
  const { mutate: deletePartnerMutate } = useMutationAPI({
    api: deletePartner,
    refetchKey: 'companyList',
    path: '/manage-company-list',
    messageSuccess: `${partnerDetail?.data?.partnerName} ${ACCOUNT_LIST_DELETE_SUCCESS}`,
  });

  // call API update partner
  const {
    mutate: updatePartnerMutate,
    isError,
    isLoading: isLoadingSubmit,
    isSuccess,
  } = useMutationAPIHandleErrorCode({
    api: updateDetailPartner,
    refetchKey: 'companyList',
    messageSuccess: DETAIL_COMPANY_MESSAGE_SUCCESS,
    path: '/manage-company-list',
  });
  // move to screen company list

  // confirm cancel, back and navigate to list partner
  const handleCancel = () => {
    if (isOnline) {
      handleConfirmAction({
        confirmFn: navigate,
        params: '/manage-company-list',
        title: DETAIL_COMPANY_CONFIRM_CANCEL,
        setIsDisable: setIsDisable,
      });
      setIsDisable(true);
    } else {
      errorNetWork();
    }
  };

  // navigate to page partner
  const handleNavigate = () => {
    if (isOnline) {
      setPartnerId({
        partnerId: partnerId,
      });
      navigate(`/partner-page/${partnerId}`, {
        state: { partnerId: partnerId },
      });
    } else {
      errorNetWork();
    }
  };

  // check list email empty or undefined => return []
  const checkEmailValid = (emailList) => {
    if (emailList[0] !== '') {
      return emailList.filter((item) => item !== undefined && item !== '');
    } else return [];
  };

  // format data, open modal confirm, pass function mutate, params for call API update
  const handleUpdatePartner = () => {
    if (isOnline) {
      form
        .validateFields()
        .then(() => {
          // get all value from form instance
          const value = form.getFieldsValue();

          // format data, return new array object
          let listUser = [];
          if (listPermissionUser?.length > 0) {
            listUser = listPermissionUser?.map((item) => ({
              userId: item?.userId,
              status: item?.approveStatus,
            }));
          }

          // format data, return new array object, handle value isAutoApprove === true | false
          let domain = [];
          if (value?.domainList?.length > 0) {
            domain = value?.domainList
              ?.filter(
                (item) =>
                  item?.domainName !== undefined && item?.domainName !== '',
              )
              ?.map((item) => ({
                domainId: item?.domainId,
                domainName: item?.domainName?.replace(checkSpace, ''),
                isAutoApprove:
                  item?.isAutoApprove === '' ||
                  item?.isAutoApprove === undefined
                    ? false
                    : item?.isAutoApprove,
              }));
          }
          const checkEmailList = checkEmailValid(value.partnerEmail ?? []);
          const emailListReplaceSpace = checkEmailList.map((item) => {
            return item.replace(checkSpace, '');
          });

          // merge data, from form, list domain, list product, list user permission to payload
          const payload = {
            partnerRequest: {
              saleForceId: value?.saleForceId ? value?.saleForceId : undefined,
              partnerName: value?.partnerName,
              partnerEmail: emailListReplaceSpace,
              solitonEmail: value?.solitonEmail?.replace(checkSpace, ''),
            },
            domainRequests: domain,
            listProduct: listSelectedProduct?.listRecordProduct?.map(
              (item) => ({
                productId: item?.productId ?? item?.id, // because response of api GET ListProduct is id, but response api getDetailPartner and updateDetailPartner is productId
                type: item?.type,
                productName: item?.productName,
              }),
            ),
            approvalRequests: listUser,
          };

          // open modal confirm, pass function call api update partner & payload
          handleConfirmAction({
            confirmFn: updatePartnerMutate,
            params: { partnerId: partnerId, payload: payload },
            title: DETAIL_COMPANY_CONFIRM_SAVE,
            setIsDisable: setIsDisable,
          });
          // queryClient.invalidateQueries('partnerDetail').then();
          // queryClient.invalidateQueries('productList').then();
          setIsDisable(true);
        })
        .catch(() => {
          return false;
        });
    } else {
      errorNetWork();
    }
  };

  // open modal confirm, pass function call api delete partner & payload
  const handleDeletePartner = () => {
    if (isOnline) {
      handleConfirmAction({
        confirmFn: deletePartnerMutate,
        params: partnerId,
        title: DETAIL_COMPANY_CONFIRM_DELETE,
        setIsDisable: setIsDisable,
      });
      setIsDisable(true);
    } else {
      errorNetWork();
    }
  };

  // set data from api to form
  useEffect(() => {
    if (error?.response?.data?.message === '404_PARTNER_NOT_FOUND') {
      navigate('/partner-not-found');
    }
    if (partnerDetail && partnerDetail.ec === false && partnerDetail.data) {
      const {
        lstDomainResponses,
        lstProductResponses,
        lstUserResponses,
        partnerEmail,
        partnerName,
        solitonEmail,
        saleForceId,
      } = partnerDetail?.data;

      setIsAvailable(!partnerEmail?.length > 0);
      setIsDomainAvailable(!lstDomainResponses?.length > 0);

      form.setFieldsValue({
        saleForceId: saleForceId,
        partnerEmail: partnerEmail?.length > 0 ? partnerEmail : [''],
        partnerName: partnerName,
        solitonEmail: solitonEmail,
        domainList:
          lstDomainResponses?.length > 0
            ? lstDomainResponses
            : [{ name: '', isAutoApprove: '' }], // change value from [] to [{name: '', isAutoApprove: ''},] for render form
      });
      if (lstProductResponses?.length > 0) {
        const listId = lstProductResponses?.map((item) => item?.productId);
        const listProduct = lstProductResponses?.map((item) => ({
          // format list product for render table
          ...item,
          key: item?.productId,
        }));
        setListSelectedProduct({
          listIdProduct: listId,
          listRecordProduct: listProduct,
        });
        setProductSelected(listProduct);
        setIdProductSelected(listId);
      }
      setListPermissionUser(lstUserResponses);
    }
  }, [
    error?.response?.data?.message,
    form,
    navigate,
    partnerDetail,
    setIdProductSelected,
    setListSelectedProduct,
    setProductSelected,
  ]);

  return (
    <>
      {contextHolder}
      {contextErrorNetWork}
      <Breadcrumb listItem={listItem} titlePage="企業詳細">
        <ButtonNavigate text={'パートナーページ'} onClick={handleNavigate} />
      </Breadcrumb>

      <LayoutDefault>
        <Suspense fallback={isLoading}>
          <Spin spinning={isLoading || isLoadingSubmit || isSuccess}>
            <FormTitle
              notice={'注意：'}
              title={DETAIL_COMPANY_TITLE}
              type={'text'}
              styles={{ color: '#9D1C49' }}
            />

            <FormWrapper>
              <Container>
                <Form form={form} layout="horizontal" size="large">
                  <FormPartner
                    formPartnerName={'partnerName'}
                    onCancel={handleCancel}
                    onFinish={handleUpdatePartner}
                    onDelete={handleDeletePartner}
                    isDelete={true}
                    isDisable={isDisable}
                    setIsDisable={setIsDisable}
                    isError={isError}
                    errorMessage={errorMessage}
                    form={form}
                    setIsAvailable={setIsAvailable}
                    isAvailable={isAvailable}
                    setIsDomainAvailable={setIsDomainAvailable}
                    isDomainAvailable={isDomainAvailable}
                  >
                    <FormPermission
                      height={'fit-content'}
                      isLoading={isLoading}
                      dataRaw={listPermissionUser}
                      setListPermissionUser={setListPermissionUser}
                    />
                  </FormPartner>
                </Form>
              </Container>
            </FormWrapper>
          </Spin>
        </Suspense>
      </LayoutDefault>
    </>
  );
};

export default EditCompany;
