import React from 'react';
import { Outlet } from 'react-router-dom';
import ContentList from 'page/Application/ManageProductAndService/Content/ContentList';
import EditContent from 'page/Application/ManageProductAndService/Content/EditContent';
import RegisterContent from 'page/Application/ManageProductAndService/Content/RegisterContent';

const Content = () => {
  return (
    <>
      <Outlet />
    </>
  );
};

export default Content;

export { ContentList, EditContent, RegisterContent };
