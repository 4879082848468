import React, { useMemo } from 'react';
import AntdTable from 'components/AntdTable';
import { useNavigate } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import { EDIT_PATH, MANAGE_PRODUCT_PATH } from 'constant/appPath';
import { ButtonAction } from 'style/styleComponent/styled';
import { EditOutlined } from '@ant-design/icons';
import { Tooltip } from 'antd';
import { TooltipHide } from 'page/Application/ManageCompanyList/CompanyList/styled';
import { network } from 'recoil/atom/network';

const TableProduct = ({
  dataRaw,
  isLoading,
  emptyText,
  onChange,
  sortedInfo,
  errorNetWork,
}) => {
  const isOnline = useRecoilValue(network);
  const navigate = useNavigate();
  const columns = useMemo(() => {
    const handleEdit = (productId) => {
      if (isOnline) {
        navigate(`/${MANAGE_PRODUCT_PATH}/${EDIT_PATH}/${productId}`);
      } else {
        errorNetWork();
      }
    };

    return [
      {
        key: 'productName',
        title: (
          <Tooltip title={'プロダクト名'} placement={'topLeft'}>
            {'プロダクト名'}
          </Tooltip>
        ),
        dataIndex: 'productName',
        align: 'left',
        ellipsis: true,
        showSorterTooltip: false,
        sorter: true,
        width: '30%',
        render: (productName) => (
          <TooltipHide>
            <Tooltip title={productName} placement={'topLeft'}>
              {productName}
            </Tooltip>
          </TooltipHide>
        ),
        sortOrder:
          sortedInfo.columnKey === 'productName' ? sortedInfo.order : null,
      },
      {
        key: 'categoryName',
        title: (
          <Tooltip title={'カテゴリ名'} placement={'topLeft'}>
            {'カテゴリ名'}
          </Tooltip>
        ),
        dataIndex: 'categoryName',
        align: 'left',
        ellipsis: true,
        showSorterTooltip: false,
        sorter: true,
        width: '10%',
        render: (categoryName) => (
          <TooltipHide>
            <Tooltip title={categoryName} placement={'topLeft'}>
              {categoryName}
            </Tooltip>
          </TooltipHide>
        ),
        sortOrder:
          sortedInfo.columnKey === 'categoryName' ? sortedInfo.order : null,
      },
      {
        key: 'isDisplayed',
        title: (
          <Tooltip title={'ステータス'} placement={'topLeft'}>
            {'ステータス'}
          </Tooltip>
        ),
        dataIndex: 'isDisplayed',
        align: 'left',
        width: '10%',
        ellipsis: true,
        render: (text) => <span title={''}>{text ? '表示' : '非表示'}</span>,
        showSorterTooltip: false,
        sorter: true,
        sortOrder:
          sortedInfo.columnKey === 'isDisplayed' ? sortedInfo.order : null,
      },
      {
        key: 'productId',
        title: <span style={{ paddingLeft: 15 }}>{'操作'}</span>,
        dataIndex: 'productId',
        render: (productId) => (
          <ButtonAction type="link" onClick={() => handleEdit(productId)}>
            <EditOutlined />
          </ButtonAction>
        ),
        align: 'left',
        width: '5%',
      },
    ];
  }, [navigate, sortedInfo.columnKey, sortedInfo.order, isOnline]);

  const dataSource = useMemo(() => {
    if (isLoading && !dataRaw) return [];
    return dataRaw?.list.map((item) => ({
      ...item,
      key: item.productId,
      categoryName: item?.category?.title ?? '',
    }));
  }, [dataRaw, isLoading]);

  return (
    <>
      <AntdTable
        columns={columns}
        isLoading={isLoading}
        dataSource={dataSource}
        emptyText={emptyText}
        onChange={onChange}
      />
    </>
  );
};

export default TableProduct;
