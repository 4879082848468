import React from 'react';
import { Outlet } from 'react-router-dom';
import PartnerWebsiteEdit from 'page/Application/PartnerWebsite/PartnerWebsiteEdit';
import PartnerWebsiteOverView from 'page/Application/PartnerWebsite/PartnerWebsiteOverView';
import 'page/Application/PartnerWebsite/style/style.scss';

const PartnerWebsite = () => {
  return <Outlet />;
};

export default PartnerWebsite;

export { PartnerWebsiteOverView, PartnerWebsiteEdit };
