import React, { useEffect, useMemo, useState } from 'react';
import FormAccount from 'components/Form/Template/FormAccount';
import useCustomQuery from 'hook/useQuery/useCustomQuery';
import {
  getDetailAccountPartner,
  updateAccountPartner,
  deleteAccountPartner,
} from 'api/account/partnerAccount.api';
import { Link, useNavigate, useParams } from 'react-router-dom';
import useCustomMutateRenderMessage from 'hook/useQuery/useCustomMutateRenderMessage';
import useCustomMutate from 'hook/useQuery/useCustomMutate';
import { ACCOUNT_STATUS } from 'constant/accountStatus';
import useMultiLanguageRegisterAccount from 'page/Application/ManageAccountSoliton/RegisterAccountSoliton/hook/useMultiLanguageRegisterAccount';
import useErrorCodeAccountPartner from 'page/Application/ManagerAccountPartner/hook/useErrorCodeAccountPartner';
import LayoutDefault from 'components/Layout/LayoutDefault';
import Breadcrumb from 'components/Layout/ApplicationLayout/Breadcrumb';
import { useRecoilValue } from 'recoil';
import { userInformation } from 'recoil/atom/authentication';

const EditAccountPartner = () => {
  const { accountId } = useParams();
  const [toggleChangePassword, setToggleChangePassword] = useState(true);
  const [role, setRole] = useState(ACCOUNT_STATUS.enable.value);
  // const { role: roleAccount } = useRecoilValue(userInformation);
  const { role: SigningRole } = useRecoilValue(userInformation);
  const { useQueryAPI } = useCustomQuery();
  const { ERROR_CODE_ACCOUNT_PARTNER } = useErrorCodeAccountPartner();
  const [isDisable, setIsDisable] = useState(false);
  const [isDisableDelete, setIsDisableDelete] = useState(false);
  const [checkedListAll, setCheckedListAll] = useState([]);
  const [email, setEmail] = useState('');
  const navigate = useNavigate();

  const listItemSA = useMemo(() => {
    return [
      {
        title: <Link to={'/'}>企業管理</Link>,
      },
      {
        title: (
          <Link to={'/manage-account-partner'}>パートナーアカウント管理</Link>
        ),
      },
      {
        title: 'パートナーアカウント詳細',
      },
    ];
  }, []);

  const listItemPA = useMemo(() => {
    return [
      {
        title: <Link to={'/'}>パートナー専用ページ</Link>,
      },
      {
        title: <Link to={'/manage-account-partner'}>アカウント管理</Link>,
      },
      {
        title: 'アカウント詳細',
      },
    ];
  }, []);

  const listItemByRole = useMemo(() => {
    return {
      ROLE_SOLITON_ADMIN: listItemSA,
      ROLE_SOLITON_USER: listItemSA,
      ROLE_PARTNER_ADMIN: listItemPA,
      ROLE_PARTNER_USER: listItemPA,
    };
  }, [listItemPA, listItemSA]);
  const listTitleByRole = useMemo(() => {
    return {
      ROLE_SOLITON_ADMIN: 'パートナーアカウント詳細',
      ROLE_SOLITON_USER: 'パートナーアカウント詳細',
      ROLE_PARTNER_ADMIN: 'アカウント詳細',
      ROLE_PARTNER_USER: 'アカウント詳細',
    };
  }, []);

  const { useMutationAPIHandleErrorCode, errorMessage } =
    useCustomMutateRenderMessage({ errorCode: ERROR_CODE_ACCOUNT_PARTNER });
  const { useMutationAPI } = useCustomMutate();
  const {
    // DETAIL_ACCOUNT_TITLE,
    DETAIL_ACCOUNT_ROLE_PARTNER_TITLE,
    DETAIL_ACCOUNT_EDIT_SUCCESS,
  } = useMultiLanguageRegisterAccount();
  const {
    data: accountPartnerDetail = {},
    isLoading,
    error,
  } = useQueryAPI({
    api: getDetailAccountPartner,
    key: 'accountPartnerDetail',
    params: {
      userId: accountId,
    },
    options: {
      refetchOnWindowFocus: false, // cancel fetch data when out focus
    },
  });

  const {
    mutate: updateAccountPartnerMutate,
    isLoading: isLoadingSubmit,
    isSuccess: isSuccessSubmit,
  } = useMutationAPIHandleErrorCode({
    api: updateAccountPartner,
    messageSuccess: DETAIL_ACCOUNT_EDIT_SUCCESS,
    path: '/manage-account-partner',
    refetchKey: 'accountListPartner',
  });
  const { mutate: deleteAccountPartnerMutate } = useMutationAPI({
    api: deleteAccountPartner,
    path: '/manage-account-partner',
    refetchKey: 'accountListPartner',
    messageSuccess: 'アカウントを削除しました。',
  });

  const onFinish = (value) => {
    const trimedPosition = value.position?.trim();
    const trimedDepartment = value.department?.trim();
    const payload = {
      ...value,
      position: trimedPosition,
      department: trimedDepartment,
      dutyId: value?.duty,
      approveStatus: role,
      listAreaId: checkedListAll,
      phoneNumber: value.phoneNumber.replaceAll('-', ''),
    };
    updateAccountPartnerMutate({
      userId: accountId,
      payload: payload,
    });
    // setIsDisable(true);
  };
  const handleDeleteAccountPartner = () => {
    deleteAccountPartnerMutate({
      listUserId: [accountId],
      isPartner: true,
      isAll: false,
    });
  };

  useEffect(() => {
    if (
      error?.response?.data?.message === '401_USER_NOT_FOUND_TO_GET' ||
      error?.response?.data?.message === '400_ARGUMENT_IS_NOT_VALID'
    ) {
      navigate('/user-not-found');
    }
    if (accountPartnerDetail && accountPartnerDetail.data) {
      setRole(accountPartnerDetail.data.approveStatus);
    }
  }, [accountPartnerDetail, error?.response?.data?.message, navigate]);

  return (
    <>
      <Breadcrumb
        listItem={listItemByRole[SigningRole]}
        titlePage={listTitleByRole[SigningRole]}
      />
      <LayoutDefault>
        <FormAccount
          errorMessage={errorMessage}
          isEdit={true}
          titleForm={
            DETAIL_ACCOUNT_ROLE_PARTNER_TITLE
            // roleAccount === 'ROLE_SOLITON_ADMIN' ||
            // roleAccount === 'ROLE_SOLITON_USER'
            //   ? DETAIL_ACCOUNT_TITLE
            //   : DETAIL_ACCOUNT_ROLE_PARTNER_TITLE
          }
          domainList={[]}
          onFinish={onFinish}
          setIsDisable={setIsDisable}
          isDisable={isDisable}
          isDisableDelete={isDisableDelete}
          setIsDisableDelete={setIsDisableDelete}
          formDetailData={accountPartnerDetail}
          isLoading={isLoading}
          isLoadingSubmit={isLoadingSubmit}
          setToggleChangePassword={setToggleChangePassword}
          toggleChangePassword={toggleChangePassword}
          handleDeleteAccountPartner={handleDeleteAccountPartner}
          role={role}
          setRole={setRole}
          checkedListAll={checkedListAll}
          setCheckedListAll={setCheckedListAll}
          isSuccessSubmit={isSuccessSubmit}
          type={'title'}
          email={email}
          setEmail={setEmail}
        />
      </LayoutDefault>
    </>
  );
};

export default EditAccountPartner;
