import { useIntl } from 'react-intl';

const useMultiLanguageRegisterCompany = () => {
  const intl = useIntl();
  const REGISTER_COMPANY_TITLE = intl.formatMessage({
    id: 'application.RegisterCompany.title',
  });
  const MODAL_PRODUCTS_TITLE = intl.formatMessage({
    id: 'application.RegisterCompany.modalProducts',
  });
  const MODAL_PRODUCTS_SEARCH = intl.formatMessage({
    id: 'application.RegisterCompany.modalSearch',
  });
  const MODAL_PRODUCTS_TABLE = intl.formatMessage({
    id: 'application.RegisterCompany.modalTable',
  });
  const REGISTER_COMPANY_TOTAL = intl.formatMessage({
    id: 'application.RegisterCompany.total',
  });
  const REGISTER_COMPANY_NAME = intl.formatMessage({
    id: 'application.RegisterCompany.name',
  });
  const REGISTER_COMPANY_PARTNER_DOMAIN = intl.formatMessage({
    id: 'application.RegisterCompany.partnerDomain',
  });
  const REGISTER_COMPANY_AUTO_APPROVE = intl.formatMessage({
    id: 'application.RegisterCompany.autoApprove',
  });
  const REGISTER_COMPANY_ADD = intl.formatMessage({
    id: 'application.RegisterCompany.add',
  });
  const REGISTER_COMPANY_PARTNER_EMAIL = intl.formatMessage({
    id: 'application.RegisterCompany.partnerEmail',
  });
  const REGISTER_COMPANY_PERMISSION_SETTINGS = intl.formatMessage({
    id: 'application.RegisterCompany.permission.setting',
  });
  const REGISTER_COMPANY_EDIT = intl.formatMessage({
    id: 'application.RegisterCompany.edit',
  });
  const REGISTER_COMPANY_MANAGER_EMAIL = intl.formatMessage({
    id: 'application.RegisterCompany.managerEmail',
  });
  const REGISTER_COMPANY_SUBMIT = intl.formatMessage({
    id: 'application.RegisterCompany.submit',
  });
  const REGISTER_COMPANY_SAVE = intl.formatMessage({
    id: 'application.RegisterCompany.save',
  });
  const REGISTER_COMPANY_CANCEL = intl.formatMessage({
    id: 'application.RegisterCompany.cancel',
  });
  const REGISTER_COMPANY_OK = intl.formatMessage({
    id: 'application.RegisterCompany.ok',
  });
  const REGISTER_COMPANY_MESSAGE_CONFIRM = intl.formatMessage({
    id: 'application.RegisterCompany.message.confirm',
  });
  const REGISTER_COMPANY_MESSAGE_CANCEL = intl.formatMessage({
    id: 'application.RegisterCompany.message.cancel',
  });
  const REGISTER_COMPANY_MESSAGE_DELETE_DOMAIN_OR_PARTNER_EMAIL =
    intl.formatMessage({
      id: 'application.RegisterCompany.message.delete.domainOrEmail',
    });
  const REGISTER_COMPANY_MESSAGE_ERROR_MISSING_ENTER_COMPANY_NAME =
    intl.formatMessage({
      id: 'application.RegisterCompany.message.error.missing.enter.company.name',
    });
  const REGISTER_COMPANY_MESSAGE_ERROR_ENTER_DOMAIN_NAME = intl.formatMessage({
    id: 'application.RegisterCompany.message.error.domain.company.name',
  });
  const REGISTER_COMPANY_MESSAGE_ERROR_MISSING_ENTER_DOMAIN_NAME =
    intl.formatMessage({
      id: 'application.RegisterCompany.message.error.missing.domain.company.name',
    });
  const REGISTER_COMPANY_MESSAGE_ERROR_ENTER_PARTNER_OR_MANAGER_EMAIL =
    intl.formatMessage({
      id: 'application.RegisterCompany.message.error.enter.partner.or.manager.email',
    });
  const REGISTER_COMPANY_MESSAGE_ERROR_MISSING_ENTER_PARTNER_OR_MANAGER_EMAIL =
    intl.formatMessage({
      id: 'application.RegisterCompany.message.error.missing.enter.partner.or.manager.email',
    });
  const REGISTER_COMPANY_COMPANY_NAME_PLACEHOLDER = intl.formatMessage({
    id: 'application.RegisterCompany.companyName.placeholder',
  });
  const REGISTER_COMPANY_DELIVERY_EMAIL_PLACEHOLDER = intl.formatMessage({
    id: 'application.RegisterCompany.deliveryEmail.placeholder',
  });
  const REGISTER_COMPANY_SOLITON_CONTACT_PLACEHOLDER = intl.formatMessage({
    id: 'application.RegisterCompany.solitonContact.placeholder',
  });
  const REGISTER_COMPANY_MESSAGE_SUCCESS_CREATE_PARTNER = intl.formatMessage({
    id: 'registerCompany.message.success',
  });

  return {
    REGISTER_COMPANY_TITLE,
    MODAL_PRODUCTS_TITLE,
    MODAL_PRODUCTS_SEARCH,
    MODAL_PRODUCTS_TABLE,
    REGISTER_COMPANY_TOTAL,
    REGISTER_COMPANY_NAME,
    REGISTER_COMPANY_PARTNER_DOMAIN,
    REGISTER_COMPANY_AUTO_APPROVE,
    REGISTER_COMPANY_PARTNER_EMAIL,
    REGISTER_COMPANY_PERMISSION_SETTINGS,
    REGISTER_COMPANY_EDIT,
    REGISTER_COMPANY_MANAGER_EMAIL,
    REGISTER_COMPANY_ADD,
    REGISTER_COMPANY_SUBMIT,
    REGISTER_COMPANY_SAVE,
    REGISTER_COMPANY_CANCEL,
    REGISTER_COMPANY_OK,
    REGISTER_COMPANY_MESSAGE_CONFIRM,
    REGISTER_COMPANY_MESSAGE_CANCEL,
    REGISTER_COMPANY_MESSAGE_DELETE_DOMAIN_OR_PARTNER_EMAIL,
    REGISTER_COMPANY_MESSAGE_ERROR_MISSING_ENTER_COMPANY_NAME,
    REGISTER_COMPANY_MESSAGE_ERROR_ENTER_DOMAIN_NAME,
    REGISTER_COMPANY_MESSAGE_ERROR_MISSING_ENTER_DOMAIN_NAME,
    REGISTER_COMPANY_MESSAGE_ERROR_ENTER_PARTNER_OR_MANAGER_EMAIL,
    REGISTER_COMPANY_MESSAGE_ERROR_MISSING_ENTER_PARTNER_OR_MANAGER_EMAIL,
    REGISTER_COMPANY_COMPANY_NAME_PLACEHOLDER,
    REGISTER_COMPANY_DELIVERY_EMAIL_PLACEHOLDER,
    REGISTER_COMPANY_SOLITON_CONTACT_PLACEHOLDER,
    REGISTER_COMPANY_MESSAGE_SUCCESS_CREATE_PARTNER,
  };
};
export default useMultiLanguageRegisterCompany;
