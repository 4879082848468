import DetailNotifyPartnerAdmin from 'page/Application/ManageProductAndService/TopPageProductPartnerAdmin/DetailNotifyPartnerAdmin';
import TopPageProductDetailPartnerAdmin from 'page/Application/ManageProductAndService/TopPageProductPartnerAdmin/TopPageProductDetailPartnerAdmin';
import TopPageProductSuperDetailPartnerAdmin from 'page/Application/ManageProductAndService/TopPageProductPartnerAdmin/TopPageProductSuperDetailPartnerAdmin';
import TopPagePartnerAdmin from 'page/Application/ManageProductAndService/TopPageProductPartnerAdmin/TopPagePartnerAdmin';
import TopPageNotifyDetailPartnerAdmin from 'page/Application/ManageProductAndService/TopPageProductPartnerAdmin/TopPageNotifyDetailPartnerAdmin';
import React from 'react';
import { Outlet } from 'react-router-dom';
import useSetTitlePage from "../../../../hook/useSetTitlePage";

const TopPageProductPartnerAdmin = () => {
  useSetTitlePage('製品一覧');
  return <Outlet />;
};

export default TopPageProductPartnerAdmin;

export {
  DetailNotifyPartnerAdmin,
  TopPageProductDetailPartnerAdmin,
  TopPageProductSuperDetailPartnerAdmin,
  TopPagePartnerAdmin,
  TopPageNotifyDetailPartnerAdmin,
};
