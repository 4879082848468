import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import { authenticationToken } from 'recoil/atom/authentication';
import {NotfoundWrapper, ButtonStyled, Container} from 'page/NotFound/styled';
import useSetTitlePage from "../../hook/useSetTitlePage";


const NotFound = () => {
  const navigate = useNavigate();
  const { accessToken } = useRecoilValue(authenticationToken);
  useSetTitlePage('NOT FOUND');

  useEffect(() => {
    if (accessToken.length === 0) {
      navigate('/', { replace: true });
    }
  }, [accessToken, navigate]);

  const handleNavigateToHomePage = () => {
    navigate('/');
  };

  return (
    <NotfoundWrapper>
      <Container>
      <ButtonStyled size='large'  onClick={handleNavigateToHomePage}>Back to Home Page</ButtonStyled>
      </Container>
    </NotfoundWrapper>
  );
};

export default NotFound;
