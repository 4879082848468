import React from 'react';
import FooterLarge from 'components/Logo/FooterDrawer/Large';

const Footer = () => {
  return (
    <div className="footer-large">
      <FooterLarge />
    </div>
  );
};

export default Footer;
