import React, { Suspense, useEffect, useMemo, useState } from 'react';
import { Alert, Form, Spin } from 'antd';
import { getDetailTag } from 'api/product/content.api';
import { getProduct } from 'api/product/product.api';
import CKEditorBuilder from 'components/CKEditorBuilder';
import { Container, FormWrapper } from 'components/Form/Template/styled';
import TextForm from 'components/Form/TextForm/index';
import Breadcrumb from 'components/Layout/ApplicationLayout/Breadcrumb';
import LayoutDefault from 'components/Layout/LayoutDefault';
import Link from 'components/Link/index';
import { DATE_FORMAT_LIST } from 'constant/notificationConstants';
import dayjs from 'dayjs';
import useCustomQuery from 'hook/useQuery/useCustomQuery';
import useSetTitlePage from 'hook/useSetTitlePage';
import useStatusContent from 'hook/useStatusContent';
import FormTitle from 'page/Application/ManageNotificationList/component/Form/FormTitle';
import FormDatePicker from 'page/Application/ManageProductAndService/Content/component/form/FormDatePicker';
import FormDateRangePicker from 'page/Application/ManageProductAndService/Content/component/form/FormDateRangePicker';
import FormIconWarning from 'page/Application/ManageProductAndService/Content/component/form/FormIconWarning';
import FormProductMenu from 'page/Application/ManageProductAndService/Content/component/form/FormProductMenu';
import FormRadioContent from 'page/Application/ManageProductAndService/Content/component/form/FormRadioContent';
import FormRadioContentType from 'page/Application/ManageProductAndService/Content/component/form/FormRadioContentType';
import FormRadioValueHash from 'page/Application/ManageProductAndService/Content/component/form/FormRadioValueHash';
import {
  FormCheckBox,
  FormFileUpload,
  FormTags,
  FormTextRequired,
} from 'page/Application/ManageProductAndService/Content/component/index';
import FormFooterSubmit from 'page/Application/ManageProjectRegistration/component/FormFooterSubmit';
import FormLabelGrid from 'page/Application/ManageProjectRegistration/component/FormLabelGrid';
import {
  Grid1,
  Grid2,
  GridForm,
} from 'page/Application/ManageProjectRegistration/component/styled';
import FormNote from 'page/Application/ManageProductAndService/Product/component/FormNote/index';

const FormContent = ({
  isLoading,
  isEdit,
  isSuccess,
  dataSelect,
  setDataSelect,
  isShowHash,
  setIsShowHash,
  onFinish,
  isError,
  errorMessage,
  contentDetail,
  form,
  handleCancel,
  handleDeleteContent,
  isCancel,
  explanation,
  setExplanation,
  content,
  setContent,
  setIsCancel,
  fileObject,
  setFileObject,
  setContentPlainText,
  setExplanationPlainText,
}) => {
  const { useQueryWithoutParams } = useCustomQuery();
  const [, setIdFile] = useState(null);
  const { STATUS_FOR_FORM, STATUS_TYPE_FOR_FORM } = useStatusContent();
  const [isShowHashForm, setIsShowHashForm] = useState(false);
  const [isOnlyOne, setIsOnlyOne] = useState(true);
  const values = Form.useWatch([], form);
  const [isDisable, setIsDisable] = useState(false);
  const [lstIdProduct, setLstIdProduct] = useState([]);

  const listBreadCrumb = useMemo(() => {
    return [
      {
        title: (
          <Link fontSize={14} text={'企業管理'} to={'/manage-company-list'} />
        ),
      },
      {
        title: <Link fontSize={14} text={'コンテンツ管理'} to={'/content'} />,
      },
      {
        title: isEdit ? 'コンテンツ詳細' : 'コンテンツ登録',
      },
    ];
  }, [isEdit]);

  const initialValues = {
    detailDisplayStatus: STATUS_FOR_FORM[0].value,
    detailDisplayType: STATUS_TYPE_FOR_FORM[0].value,
    showHash: isShowHash,
  };

  const { data: listTags } = useQueryWithoutParams({
    api: getDetailTag,
    key: 'listTags',
  });

  const { data: listProduct } = useQueryWithoutParams({
    api: getProduct,
    key: 'listProduct',
  });

  const optionsSelected = useMemo(() => {
    return listTags?.data?.map((item) => ({
      value: item,
      title: '',
      label: item,
    }));
  }, [listTags?.data]);

  const handleChange = (value) => {
    setDataSelect([...value]);
  };

  const onChangeShowHash = (e) => {
    const isDisplayedValue = e.target.value;
    form.setFieldValue('showHash', isDisplayedValue);
    setIsShowHash(isDisplayedValue);
  };

  const checkStatus = (value) => {
    if (value === 'SHOW') {
      return [true, false];
    } else if (value === 'HIDE') {
      return [true, true];
    }
    return [false, false];
  };

  const handleClearDate = () => {
    form.setFieldValue('publishedFromToDate', []);
  };

  useEffect(() => {
    const timeZoneFromClient = Intl.DateTimeFormat().resolvedOptions().timeZone;
    if (isEdit && contentDetail && isOnlyOne) {
      const fileResponse = contentDetail?.fileResponse;
      const productMenuLst = contentDetail?.productMenuLst;
      let fileList = [];
      if (fileResponse) {
        setIsShowHashForm(true);
        fileList = [
          {
            uuid: fileResponse.fileUuid,
            name: fileResponse.fileName,
            status: 'done',
            type: fileResponse.fileType,
          },
        ];
      }
      form.setFieldsValue({
        ...contentDetail,
        file: fileList,
        publishedFromToDate: [
          contentDetail?.detailPublishedFrom &&
            dayjs
              .utc(contentDetail?.detailPublishedFrom)
              .tz(timeZoneFromClient),
          contentDetail?.detailPublishedTo &&
            dayjs.utc(contentDetail?.detailPublishedTo).tz(timeZoneFromClient),
        ],
        tags: contentDetail?.detailTagLst,
        menuIdLst: productMenuLst?.length
          ? productMenuLst?.map((values) => {
              return {
                productId: values.productId,
                menuId:
                  values?.menuIds?.length > 1
                    ? values?.menuIds
                    : [values?.menuId],
                menuName: values.menuName,
              };
            })
          : [{}],
      });
      const productIds = form
        .getFieldValue('menuIdLst')
        ?.map((value) => value?.productId);
      if (productIds[0]) {
        setLstIdProduct(productIds);
      }
      setIsOnlyOne(false);
    }
  }, [contentDetail, form, isEdit, isOnlyOne]);

  useSetTitlePage(isEdit ? 'コンテンツ詳細' : 'コンテンツ登録');

  return (
    <>
      <Breadcrumb
        titlePage={listBreadCrumb[2].title}
        listItem={listBreadCrumb}
      />
      <Suspense fallback={isLoading || isSuccess}>
        <LayoutDefault>
          <FormTitle title={!isEdit ? 'コンテンツ登録' : 'コンテンツ詳細'} />
          <FormWrapper>
            <Container>
              <Spin spinning={isLoading || isSuccess}>
                <Form
                  encType="multipart/form-data"
                  form={form}
                  layout="horizontal"
                  size="large"
                  onFinish={onFinish}
                  initialValues={initialValues}
                >
                  <GridForm>
                    <Grid2>
                      {(isError || errorMessage) && (
                        <Alert
                          message={errorMessage}
                          type="error"
                          style={{ marginBottom: '24px' }}
                        />
                      )}
                    </Grid2>
                    {/*title*/}
                    <Grid1>
                      <FormLabelGrid labelText={'タイトル'} required={true} />
                    </Grid1>
                    <Grid2>
                      <FormTextRequired
                        placeHolder={'タイトルを入力してください。'}
                        name="detailTitle"
                        required={true}
                        maxLength={256}
                      />
                    </Grid2>
                    {/*Check box content*/}
                    <FormRadioContent
                      name={'detailDisplayStatus'}
                      required={true}
                      onChange={handleClearDate}
                    />
                    {/*Date picker*/}
                    <Grid1>
                      <FormLabelGrid labelText={'表示日付'} required={true} />
                    </Grid1>
                    <Grid2>
                      <FormDatePicker
                        name="detailDisplayDate"
                        width={'100%'}
                        dateFormat={DATE_FORMAT_LIST[1]}
                        placeHolder={'日時選択'}
                        message={'情報を入力してください。'}
                        required={true}
                      />
                    </Grid2>
                    {/*Date range picker*/}
                    <Grid1>
                      <FormLabelGrid
                        labelText={`公開日時予約・非公開日時予約`}
                      />
                    </Grid1>
                    <Grid2 style={{ display: 'flex' }}>
                      <FormDateRangePicker
                        name="publishedFromToDate"
                        message={
                          '終了日時を入力しない場合は、公開期間は無期限になります。'
                        }
                        width={'640px'}
                        isShowTime={true}
                        required={false}
                        dateFormat={DATE_FORMAT_LIST[0]}
                        form={form}
                        listDisable={checkStatus(values?.detailDisplayStatus)}
                      />
                      <FormIconWarning />
                    </Grid2>
                    {/*Check box type*/}
                    <FormRadioContentType
                      required={false}
                      name={'detailDisplayType'}
                    />
                    {/*Menu product*/}
                    <FormProductMenu
                      listProduct={listProduct}
                      name={'menuIdLst'}
                      required={false}
                      form={form}
                      productMenuLst={form.getFieldValue('menuIdLst')}
                      isEdit={isEdit}
                      lstIdProduct={lstIdProduct}
                    />
                    {/*List tags*/}
                    <Grid1>
                      <FormLabelGrid labelText={'タグ'} />
                    </Grid1>
                    <Grid2>
                      <FormTags
                        dataSelect={dataSelect}
                        handleChange={handleChange}
                        optionsSelected={optionsSelected}
                      />
                    </Grid2>
                    {/*CKEditor */}
                    <Grid1>
                      <FormLabelGrid labelText={'説明'} />
                    </Grid1>
                    <Grid2>
                      <Form.Item>
                        <CKEditorBuilder
                          data={explanation}
                          setData={setExplanation}
                          setPlainText={setExplanationPlainText}
                        />
                        <FormNote
                          text={
                            'ソースコードモードで編集した内容は、ソースコードモードをOFFにしないと反映しません。'
                          }
                        />
                      </Form.Item>
                    </Grid2>
                    {/*Upload file*/}
                    <Grid1>
                      <FormLabelGrid
                        required={false}
                        labelText={'登録ファイル'}
                      />
                    </Grid1>
                    <Grid2>
                      <FormFileUpload
                        name={'file'}
                        multipleUpload={false}
                        maxCount={1}
                        text={'アップロード可能なファイル形式とサイズ：'}
                        hint={
                          'pdf, pptx, ppt, xlsx, xls, xlsm, docx, doc - 50 MB; txt - 1MB; zip, bin, exe, nabin, efm - 1GB; iso - 4GB; csv - 10MB'
                        }
                        isPreview={false}
                        setIdFile={setIdFile}
                        form={form}
                        setIsShowHash={setIsShowHashForm}
                        fileObject={fileObject}
                        setFileObject={setFileObject}
                        setIsCancel={setIsCancel}
                        setIsDisable={setIsDisable}
                        isDisabled={isDisable}
                      />
                      {isShowHashForm && (
                        <TextForm
                          text={`SHA256: ${
                            contentDetail?.fileResponse?.fileHash ?? '計算中'
                          }`}
                        />
                      )}
                    </Grid2>
                    <Grid1 />
                    {/*Check box value hash*/}
                    <FormRadioValueHash
                      required={false}
                      name={'showHash'}
                      onChange={onChangeShowHash}
                    />
                    {/*URL*/}
                    <Grid1>
                      <FormLabelGrid required={false} labelText={'URL'} />
                    </Grid1>
                    <Grid2>
                      <FormTextRequired
                        marginBottom={'3px'}
                        name={'detailUrl'}
                        required={false}
                        placeHolder={'URL'}
                        isUrl={true}
                      />
                      <FormCheckBox text={'別のタブで開く'} name={'isNewTab'} />
                    </Grid2>
                    {/*CKEditor */}
                    <Grid1>
                      <FormLabelGrid labelText={'詳細'} />
                    </Grid1>
                    <Grid2>
                      <Form.Item>
                        <CKEditorBuilder
                          data={content}
                          setData={setContent}
                          setPlainText={setContentPlainText}
                        />
                        <FormNote
                          text={
                            'ソースコードモードで編集した内容は、ソースコードモードをOFFにしないと反映しません。'
                          }
                        />
                      </Form.Item>
                    </Grid2>
                    <Grid2>
                      <FormFooterSubmit
                        isDisable={isDisable}
                        onCancel={handleCancel}
                        onDelete={handleDeleteContent}
                        textOk={isEdit ? '保存' : '登録'}
                        textCancel={'キャンセル'}
                        textDelete={'削除'}
                        isDelete={isEdit}
                        isCancel={isCancel}
                        form={form}
                      />
                    </Grid2>
                  </GridForm>
                </Form>
              </Spin>
            </Container>
          </FormWrapper>
        </LayoutDefault>
      </Suspense>
    </>
  );
};

export default FormContent;
