import styled from 'styled-components';

export const ListProduct = styled.ul`
  margin-top: 10px;
`;

export const ItemProduct = styled.li`
  margin-top: -10px;
  padding: 8px 12px;
  border-radius: 6px;
  transition: 0.15s;
  transition-timing-function: ease-out;
  cursor: pointer;
  text-decoration: underline;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 400px;

  &:not(&:last-child) {
    margin-bottom: 8px;
  }

  &:hover {
    background: #f9f9f9;
  }
`;
