import styled from 'styled-components';
import { Button } from 'antd';

export const TitleScreen = styled.h2`
  font-family: 'inter-semibold', sans-serif;
  font-size: 16px;
  font-weight: 600;
  color: var(--FORM-LABEL-default);
  padding: 8px 10px;
  word-break: break-word;
`;

export const HeaderTable = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  align-items: center;
  background: #d9d9d94d;
  border-radius: 6px;
  margin: 7px 0 5px 0;
  @media all and (min-width: 0px) and (max-width: 320px) {
    margin: 2px 0 5px 0;
  }
  @media all and (min-width: 320px) and (max-width: 430px) {
    margin: 2px 0 5px 0;
  }
`;

export const ButtonHeader = styled(Button)`
  font-family: inter-regular, sans-serif;
  color: #656464;

  span {
    font-size: 14px;
  }

  &.btn-desktop {
    margin-right: 16px;
  }

  &.btn-mobile {
    display: none;
    margin-right: 10px;
  }

  @media all and (min-width: 0px) and (max-width: 320px) {
    &.btn-desktop {
      display: none;
    }

    &.btn-mobile {
      display: inline-block;
    }
  }
  @media all and (min-width: 320px) and (max-width: 430px) {
    &.btn-desktop {
      display: none;
    }

    &.btn-mobile {
      display: inline-block;
    }
  }
`;
