import React from 'react';
import Header from 'components/Layout/ApplicationLayout/SidebarMenu/Desktop/Header';
import Menu from 'components/Layout/ApplicationLayout/SidebarMenu/Desktop/Menu/';
import Footer from 'components/Layout/ApplicationLayout/SidebarMenu/Desktop/Footer';
import 'components/Layout/ApplicationLayout/SidebarMenu/Desktop/style/style.scss';

const SidebarDesktop = () => {
  return (
    <div className="sidebar-responsive">
      <Header />
      <Menu />
      <Footer />
    </div>
  );
};

export default SidebarDesktop;
