import React from 'react';
import { Outlet } from 'react-router-dom';
import AccountInformationEdit from 'page/Application/MyPagePartnerAdmin/AccountInformation/AccountInformationEdit';
import AccountInformationOverview from 'page/Application/MyPagePartnerAdmin/AccountInformation/AccountInformationOverview';
import useSetTitlePage from 'hook/useSetTitlePage';

const AccountInformation = () => {
  useSetTitlePage('アカウント情報');

  return <Outlet />;
};

export default AccountInformation;

export { AccountInformationEdit, AccountInformationOverview };
