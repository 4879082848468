import React from 'react';
import { ButtonStyle } from 'page/Application/MyPage/component/FormMyPage/ButtonPrimary/styled';
import LoadingSpin from 'components/LoadingSpin';

const ButtonPrimary = ({ text, onClick, isLoadingUpdate }) => {
  return (
    <ButtonStyle
      type="primary"
      size="large"
      htmlType="submit"
      onClick={onClick}
      disabled={isLoadingUpdate}
    >
      <span> {isLoadingUpdate ? <LoadingSpin /> : text}</span>
    </ButtonStyle>
  );
};

export default ButtonPrimary;
