import styled from 'styled-components';

export const LogoStyled = styled.img`
  height: 50px;
  margin: 25px 10px;
  @media all and (min-width: 0px) and (max-width: 320px) {
    margin: 0;
  }
  @media all and (min-width: 320px) and (max-width: 430px) {
    margin: 0;
  }
`;
