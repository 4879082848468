import React from 'react';
import LogoWhite from 'components/Logo/LogoWhite';

const Header = () => {
  return (
    <div className="header-sidebar-desktop">
      <LogoWhite width={120} height={32} className="logo" />
      <p className="hero-text-desktop">株式会社ソリトンシステムズ</p>
    </div>
  );
};

export default Header;
