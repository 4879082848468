import React, { useEffect, useMemo, useState } from 'react';
import { getListNotification } from 'api/notification/notification.api';
import { useResetRecoilState } from 'recoil';
import { listProduct } from 'recoil/atom/selectProduct';
import { Link, useNavigate } from 'react-router-dom';
import { FilterOutlined, RedoOutlined } from '@ant-design/icons';
import {
  ButtonBreadcrumb,
  ButtonTextIcon,
  LineVertical,
} from 'components/Button';
import Breadcrumb from 'components/Layout/ApplicationLayout/Breadcrumb';
import HeaderListDataLayout from 'components/Layout/HeaderListDataLayout';
import LayoutDefault from 'components/Layout/LayoutDefault';
import Pagination from 'components/Pagination';
import { REGISTER_PATH } from 'constant/appPath';
import {
  defaultPageSizeOptions,
  defaultSelectPageSizeOption,
} from 'constant/Pagination/pageSizeOption';
import { SORT_DIRECTION } from 'constant/sortConstants';
import useCustomQuery from 'hook/useQuery/useCustomQuery';
import useSearchKeyword from 'hook/useSearchKeyword';
import { useNotification } from 'page/Application/ManageNotificationList/component/NotifcationContext';
import ModalSearchNotification from 'page/Application/ManageNotificationList/NotificationList/component/ModalSearchNotification';
import TableData from 'page/Application/ManageNotificationList/NotificationList/component/TableData';
import useMultiLanguageNotificationList from './hook/useMultiLanguageNotificationList';
import { IconWrapper } from 'components/Icon/styled';

const initFilterData = {
  category: '',
  displayedDateTo: '',
  displayedDateFrom: '',
  status: '',
};

const NotificationList = () => {
  const navigate = useNavigate();
  const resetProductList = useResetRecoilState(listProduct);
  const { setCurrentNotification } = useNotification();
  const { useQueryAPI } = useCustomQuery();

  const {
    NOTIFICATION_LIST_TITLE,
    NOTIFICATION_LIST_PLACE_HOLDER,
    NOTIFICATION_LIST_FILTER_DATA,
    NOTIFICATION_LIST_TABLE_PAGINATION_TOTAL,
  } = useMultiLanguageNotificationList();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [page, setPage] = useState(10);
  const [current, setCurrent] = useState(1);
  const [filterData, setFilterData] = useState(initFilterData);
  const [resetStatusFilter, setResetStatusFilter] = useState(false);
  const [sortedInfo, setSortedInfo] = useState({});
  const { searchKeyword, onSearch, emptyText } = useSearchKeyword();
  const [searchValue, setSearchValue] = useState('');

  const listItem = useMemo(() => {
    return [
      {
        title: <Link to={'/'}>企業管理</Link>,
      },
      {
        title: 'お知らせ管理',
      },
    ];
  }, []);

  const { data: listNotification = [], isLoading } = useQueryAPI({
    api: getListNotification,
    key: 'listNotification',
    params: {
      pageNumber: current,
      pageSize: page,
      keyword: encodeURIComponent(searchKeyword),
      status: filterData.status,
      category: filterData.category,
      displayedDateFrom: filterData.displayedDateFrom,
      displayedDateTo: filterData.displayedDateTo,
      sortField: sortedInfo.sortField ?? '',
      sortDirection: sortedInfo.sortDirection ?? '',
    },
  });

  const handleResetData = () => {
    setPage(10);
    setCurrent(1);
    setSearchValue('');
    setSortedInfo({});
    setFilterData(initFilterData);
    setResetStatusFilter(true);
  };

  const handleOpenModal = () => {
    setIsModalOpen(true);
  };

  const handlePagingChange = (pageNumber, pageSize) => {
    setCurrent(pageNumber);
    setPage(pageSize);
  };

  const handleChangePageSize = (value) => {
    setPage(value);
    setCurrent(1);
  };

  const handleNavigate = () => {
    setCurrentNotification(null);
    navigate(`${REGISTER_PATH}`);
  };

  const handleChange = (pagination, filters, sorter) => {
    setSortedInfo({
      sortField: sorter.order && sorter.field,
      sortDirection: sorter.order && SORT_DIRECTION[sorter.order],
      order: sorter.order,
      columnKey: sorter.columnKey,
    });
  };

  // reset page number to 1 if keyword changed when user in page > 1
  useEffect(() => {
    if (searchKeyword) {
      setCurrent(1);
      setSortedInfo({});
    }
    resetProductList();
  }, [searchKeyword, resetProductList]);

  return (
    <>
      <Breadcrumb listItem={listItem} titlePage={listItem[1].title}>
        <ButtonBreadcrumb onClick={handleNavigate}></ButtonBreadcrumb>
      </Breadcrumb>
      <ModalSearchNotification
        modalKey={'footerModalNotification'}
        isModalOpen={isModalOpen}
        setIsModalOpen={setIsModalOpen}
        setFilterData={setFilterData}
        setResetStatusFilter={setResetStatusFilter}
        resetStatusFilter={resetStatusFilter}
        setSortedInfo={setSortedInfo}
      />
      <LayoutDefault>
        <HeaderListDataLayout
          titleScreen={NOTIFICATION_LIST_TITLE}
          searchPlaceholder={NOTIFICATION_LIST_PLACE_HOLDER}
          onSearch={onSearch}
          resetStatus={resetStatusFilter}
          loading={isLoading}
          searchValue={searchValue}
          setSearchValue={setSearchValue}
          value={page}
          handleChangePageSize={handleChangePageSize}
          pageSizeOptions={defaultSelectPageSizeOption}
        >
          <ButtonTextIcon
            text={NOTIFICATION_LIST_FILTER_DATA}
            icon={
              <IconWrapper>
                <FilterOutlined style={{ color: '#9d1c49' }} />
              </IconWrapper>
            }
            onClick={handleOpenModal}
          />
            <LineVertical />
          <ButtonTextIcon
            text={'リフレッシュ'}
            icon={<RedoOutlined />}
            onClick={handleResetData}
          />
          <LineVertical />
        </HeaderListDataLayout>
        <TableData
          dataRaw={listNotification.data}
          isLoading={isLoading}
          emptyText={emptyText}
          setCurrentNotification={setCurrentNotification}
          sortedInfo={sortedInfo}
          onChange={handleChange}
        />
        <Pagination
          totalTitle={NOTIFICATION_LIST_TABLE_PAGINATION_TOTAL}
          currentPage={current}
          handlePagingChange={handlePagingChange}
          total={listNotification.data?.total}
          pageSize={page}
          pageSizeOptions={defaultPageSizeOptions}
          handleChangePageSize={handleChangePageSize}
        />
      </LayoutDefault>
    </>
  );
};
export default NotificationList;
