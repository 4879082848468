import { ContentSearchStyle } from '../ItemOpenSearch/styled';

const SearchContentHighLight = ({
  dataContentBefore,
  dataContentHighLight,
  dataContentAfter,
}) => {
  return (
    <ContentSearchStyle>
      <div style={{ maxWidth: '100%', wordBreak: 'break-all' }}>
        {dataContentBefore}
        {dataContentHighLight}
        {dataContentAfter}
      </div>
    </ContentSearchStyle>
  );
};
export default SearchContentHighLight;
