import axiosClient from 'api/config/axiosClient';

const getTopPage = () => {
  const url = `v1/page/top`;
  return axiosClient.get(url);
};

const getTopPageNotify = () => {
  const url = `v1/page/notify`;
  return axiosClient.get(url);
};

const getTopPageProduct = ({ productId }) => {
  const url = `v1/page/product/${productId}`;
  return axiosClient.get(url);
};

const getTopPageMenuProduct = ({ menuId }) => {
  const url = `v1/page/menu/${menuId}`;
  return axiosClient.get(url);
};

const getTopPageMenuParent = ({ menuId }) => {
  const url = `v1/page/parent/${menuId}`;
  return axiosClient.get(url);
};

const getTopPageDetailProduct = ({ productId, menuId, detailId }) => {
  const url = `v1/page/product/${productId}/menu/${menuId}/detail/${detailId}`;
  return axiosClient.get(url);
};

const updateTopPageMenuProduct = ({ menuId, payload }) => {
  const url = `v1/page/menu/${menuId}`;
  return axiosClient.put(url, payload);
};

const getTopPageEditMode = () => {
  const url = `v1/page/editMode`;
  return axiosClient.get(url);
};

const getTopPageProductEditMode = ({ productId }) => {
  const url = `v1/page/editMode/${productId}`;
  return axiosClient.get(url);
};

const updateProductPagePosition = ({ productId, payload }) => {
  const url = `v1/page/product/${productId}`;
  return axiosClient.put(url, payload);
};

const getProductNotify = ({ productId }) => {
  const url = `v1/page/product/${productId}/notify`;
  return axiosClient.get(url);
};

const updateTopPagePosition = ({ payload }) => {
  const url = `v1/page/top`;
  return axiosClient.put(url, payload);
};

const getDetailNotificationPublic = ({ notifyId, forProduct }) => {
  const url = `v1/notify/public?notifyId=${notifyId}&forProduct=${forProduct}`;
  return axiosClient.get(url);
};

export {
  getTopPage,
  getTopPageNotify,
  getTopPageProduct,
  getTopPageMenuProduct,
  getTopPageMenuParent,
  updateTopPageMenuProduct,
  getTopPageEditMode,
  getTopPageProductEditMode,
  updateProductPagePosition,
  getProductNotify,
  updateTopPagePosition,
  getTopPageDetailProduct,
  getDetailNotificationPublic,
};
