// main app router
const MAIN_APP_BASE_URL = '/';
const DASHBOARD_PATH = MAIN_APP_BASE_URL;
const MANAGE_ACCOUNT_PATH = 'manage-account';

// MANAGE COMPANY LIST
const MANAGE_COMPANY_LIST_PATH = 'manage-company-list';
const PARTNER_ID = 'partnerId';
const ACCOUNT_ID = 'accountId';
const NOTIFICATION_ID = 'notifyId';
const EDIT_PATH = 'edit';
const REGISTER_PATH = `register`;
const EDIT_COMPANY_ABSOLUTE_PATH = `${EDIT_PATH}/:${PARTNER_ID}`;

// MANAGE ACCOUNT SOLITON
const MANAGE_ACCOUNT_SOLITON_PATH = 'manage-account-soliton';

const EDIT_ACCOUNT_SOLITON_PATH = `${EDIT_PATH}/:${ACCOUNT_ID}`;
const EDIT_ACCOUNT_SOLITON_ABSOLUTE_PATH = `/${MANAGE_ACCOUNT_SOLITON_PATH}/${EDIT_PATH}`;

// MANAGE ACCOUNT PARTNER
const MANAGE_ACCOUNT_PARTNER_PATH = 'manage-account-partner';
const EDIT_ACCOUNT_PARTNER_PATH = `${EDIT_PATH}/:${ACCOUNT_ID}`;

const EDIT_ACCOUNT_PARTNER_ABSOLUTE_PATH = `/${MANAGE_ACCOUNT_PARTNER_PATH}/${EDIT_PATH}`;

// MANAGE NOTIFICATION

const MANAGE_NOTIFICATION_PATH = 'manage-notification-list';
const EDIT_NOTIFICATION_PATH = `${EDIT_PATH}/:${NOTIFICATION_ID}`;
const PREVIEW_NOTIFICATION_PATH = `preview`;
const MANAGE_PRODUCT_AND_SERVICE_PATH = 'manage-product-and-service';
const MANAGE_PROJECT_REGISTRATION_PATH = 'manage-project-registration';
const PROJECT_REGISTRATION_PATH = 'project-registration';
const MANAGE_SOLUTION_PATH = 'manage-solution';
const WEBSITE_SETTING_PATH = 'website-setting';

// MANAGE CATEGORY
const MANAGE_CATEGORY_PATH = 'category';

// MANAGE PRODUCT
const MANAGE_PRODUCT_PATH = 'product';

// MANAGE PRODUCT
const TOP_PAGE_PATH = 'top-page';

//TOP PAGE
const MANAGE_TOP_PAGE_DETAIL_PRODUCT_PATH = '/top-page-detail-product/';
//OPEN SEARCH
const OPENSEARCH_RESULT = 'opensearch';
export {
  DASHBOARD_PATH,
  MANAGE_ACCOUNT_PATH,
  MANAGE_COMPANY_LIST_PATH,
  REGISTER_PATH,
  EDIT_PATH,
  EDIT_COMPANY_ABSOLUTE_PATH,
  MANAGE_NOTIFICATION_PATH,
  MANAGE_PRODUCT_AND_SERVICE_PATH,
  MANAGE_PROJECT_REGISTRATION_PATH,
  PROJECT_REGISTRATION_PATH,
  MANAGE_SOLUTION_PATH,
  WEBSITE_SETTING_PATH,
  MANAGE_ACCOUNT_SOLITON_PATH,
  EDIT_ACCOUNT_SOLITON_PATH,
  EDIT_ACCOUNT_SOLITON_ABSOLUTE_PATH,
  MANAGE_ACCOUNT_PARTNER_PATH,
  EDIT_ACCOUNT_PARTNER_ABSOLUTE_PATH,
  EDIT_ACCOUNT_PARTNER_PATH,
  EDIT_NOTIFICATION_PATH,
  PREVIEW_NOTIFICATION_PATH,
  MANAGE_CATEGORY_PATH,
  MANAGE_PRODUCT_PATH,
  MANAGE_TOP_PAGE_DETAIL_PRODUCT_PATH,
  TOP_PAGE_PATH,
  OPENSEARCH_RESULT,
};
