import styled from 'styled-components';

export const ListHover = styled.div`
  &:not(:last-child) {
    border-block-end: 1px solid rgba(5, 5, 5, 0.06);
  }
  &:hover {
    background: #fafafa;
  }
`;
