import styled from 'styled-components';

export const FromGrid = styled.div`
  display: grid;
  grid-template-columns: 170px 400px 52px;
`;

export const LabelGrid = styled.div`
  height: 40px;
  line-height: 40px;
  text-align: right;
  font-size: 14px;
  font-family: 'inter-regular', sans-serif;
  padding-right: 14px;
`;

export const Grid1 = styled.div`
  grid-column: 1/2;
`;
export const Grid2 = styled.div`
  grid-column: 2/3;
`;

export const Grid3 = styled.div`
  grid-column: 3/4;
  margin-left: 12px;
`;
