import axiosClient from 'api/config/axiosClient';

// get list account soliton
const getListAccount = ({
  pageNumber,
  pageSize,
  keyword,
  isPartner,
  displayedDateFrom,
  displayedDateTo,
  category,
  status,
  sortField,
  sortDirection,
}) => {
  const url = `v1/account?pageNumber=${pageNumber}&pageSize=${pageSize}&keyword=${keyword}&displayedDateFrom=${displayedDateFrom}&displayedDateTo=${displayedDateTo}&category=${category}&status=${status}&sortField=${sortField}&sortDirection=${sortDirection}&isPartner=${isPartner}`;
  return axiosClient.get(url);
};

const deleteMultipleUser = (payload) => {
  const url = `v1/account/delete`;
  return axiosClient.delete(url, { data: payload });
};

const getMyPage = () => {
  const url = `v1/account/mypage`;
  return axiosClient.get(url);
};

const updateMyPageAccount = ({ payload }) => {
  const url = `v1/account/mypage`;
  return axiosClient.put(url, payload);
};

const getListDutyExisted = () => {
  const url = `v1/account/duty`;
  return axiosClient.get(url);
};

export {
  getListAccount,
  deleteMultipleUser,
  getMyPage,
  updateMyPageAccount,
  getListDutyExisted,
};
