import styled from 'styled-components';
import { Button, Input } from 'antd';

export const TreeWrapper = styled.div`
  padding: 12px 12px 4px 12px;
  border-radius: 4px;
`;

export const TreeContainer = styled.div`
  overflow-y: hidden;
  width: auto;

  &::-webkit-scrollbar {
    width: 2px;
    height: 6px;
    cursor: grab;
  }

  &::-webkit-scrollbar-track {
    background: transparent;
  }

  &::-webkit-scrollbar-thumb {
    background: #ecececff;
    border-radius: 2px;
  }

  &::-webkit-scrollbar-thumb:hover {
    background: #c2c2c2ff;
  }
`;
// ========== START TREE ITEM ========== //
export const BaseTreeItem = styled.div`
  display: flex;
  align-items: center;
    width: fit-content;

  &.dragging {
    border: 1px solid #ebd1da;
    border-radius: 6px;
    opacity: 0.5;
  }
`;
export const TreeItemParent = styled(BaseTreeItem)`
  margin-bottom: 10px;
  display: flex;
  align-items: center;
  position: relative;
  height: 40px;
  left: ${(props) => props.left};

  &.dragging {
    width: ${(props) => props.width || 260}px;
    cursor: move;
  }
`;
export const TreeItemChildren = styled(BaseTreeItem)`
  margin-bottom: 10px;
  position: relative;
  left: ${(props) => props.left};

  &.dragging {
    width: ${(props) => props.width || 222}px;
    cursor: move;
  }
`;
export const BaseStyleItemTree = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 40px;
  transition: 0.15s;
  transition-timing-function: ease-in;
  border-radius: 6px;
  width: 320px;

  &.dragging {
    background: unset;
  }

  // background color for tree item

  &.no-drag {
    background: ${(props) => props.background || '#f9f9f9ff'};
  }

  &:hover {
    background: #f1f1f1;
  }
`;
export const ItemParent = styled(BaseStyleItemTree)`
  margin-left: 46px;
`;
export const ItemChildren = styled(BaseStyleItemTree)``;
export const DragButton = styled.div`
  width: 40px;
  height: 40px;
  min-width: 40px;
  min-height: 40px;
  display: flex;
  font-size: 16px;
  justify-content: center;
  color: gray;
  position: absolute;
  left: -46px;
  cursor: grab;
`;

export const ExpandButton = styled.div`
  position: absolute;
  width: 40px;
  height: 40px;
  font-size: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 2;
  cursor: pointer;
  border-radius: 6px;
  background: rgba(249, 249, 249);

  &:hover {
    background: #f1f1f1;
  }

  .open {
    transform: rotate(90deg);
    transition: 0.25s;
  }

  .close {
    transition: 0.25s;
    transform: unset;
  }
`;
export const TitleTree = styled.div`
  padding-left: 12px;
  font-size: 14px;
  line-height: 1.5;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  width: fit-content;
  max-width: 258px;
`;
// ========== END TREE ITEM ========== //

// ========== START DRAG AND DROP ========== //
export const BaseDragItem = styled.div`
  position: absolute;
  z-index: 1;
  height: 40px;
  width: 40px;
  display: flex;
  align-items: center;

  &.drag-element {
    cursor: grab;

    &:active {
      cursor: grabbing;
    }
  }
`;
export const DragParentContainer = styled(BaseDragItem)`
  left: -46px;
  width: ${(props) => props.widthOverlay};
`;
export const DragChildrenContainer = styled(BaseDragItem)`
  left: -46px;
  border-radius: 6px 0 0 6px;
  width: ${(props) => props.widthOverlay};
`;

// export const BaseItemDropPosition = styled.div`
//   transition: 0.15s;
//   transition-timing-function: ease-in;
//   position: relative;
//   border-radius: 1px;
//   width: ${(props) => props.width || 185}px;
//   margin-left: 38px;
//   &:before {
//     content: '';
//     position: absolute;
//     top: 1px;
//     left: 5px;
//     transform: translate(-50%, -50%);
//     width: 6px;
//     height: 6px;
//     border-radius: 50%;
//     border: 2px solid #e55c91;
//     background: white;
//   }
//
//   &.drag-over {
//     background: #e55c91;
//     height: 2px;
//     margin-bottom: 8px;
//   }
//
//   &.drag-leave {
//     height: 0;
//     opacity: 0;
//   }
// `;

// export const ItemParentDropPosition = styled(BaseItemDropPosition)``;
// export const ItemChildrenDropPosition = styled(BaseItemDropPosition)``;
// ========== END DRAG AND DROP ========== //

// ========== START FORM ADD / EDIT ========== //
export const BaseFormStyled = styled.div`
  //width: 224px;
  margin-bottom: 10px;
`;

export const FormParent = styled(BaseFormStyled)`
  &.create-first-item {
    margin-left: 46px;
  }

  &.create {
    margin-left: 92px;
  }
`;
export const FormChildren = styled(BaseFormStyled)`
  margin-left: 46px;
`;

export const ButtonForm = styled(Button)`
  font-size: 14px;
  font-family: inherit;
  height: 40px;
`;

export const ItemCreateUpdateMode = styled.div`
  display: flex;
  align-items: center;
  gap: 6px;

  &.edit-parent {
    margin-left: 46px;
  }

  width: fit-content;
`;

export const BaseInputStyle = styled(Input)`
  height: 36px;
  font-size: 14px;
  font-family: inherit;
`;

export const InputEdit = styled(BaseInputStyle)`
  width: 320px;
  height: 40px;
`;

export const InputCreate = styled(BaseInputStyle)`
  width: 320px;
  height: 40px;
`;
// ========== END FORM ADD / EDIT ========== //
