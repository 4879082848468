import styled from 'styled-components';

export const ThumbnailStyled = styled.div`
  width: inherit;
  height: 217px;
  border-radius: 6px 6px 0 0;
  overflow: hidden;
  background: #f9f9f9 url(${(props) => props?.image}) no-repeat center;
  background-size: cover;
`;
