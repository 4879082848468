import { atom } from 'recoil';

export const listProduct = atom({
  key: 'listProduct',
  default: {
    display: false,
    listIdProduct: [],
    listRecordProduct: [],
  },
});

export const listSelectProduct = atom ( {
  key: 'listSelectProduct',
  default: {
    display: false,
    listIdProduct: [],
    listRecordProduct: [],
  }
})

export const product = atom({
  key: 'product',
  default: []
})

export const idProduct = atom({
  key: 'idProduct',
  default: []
})


