const defaultSelectPageSizeOption = [
  {
    value: 10,
    label: '10 /ページ',
    title: '',
  },
  {
    value: 25,
    label: '25 /ページ',
    title: '',
  },
  {
    value: 50,
    label: '50 /ページ',
    title: '',
  },
  {
    value: 100,
    label: '100 /ページ',
    title: '',
  },
];
const defaultPageSizeOptions = ['10', '25', '50', '100'];

// only for path project-registration, with Role Partner Admin, Partner User, Soliton Admin
const projectRegisterSelectPageSizeOption = [
  {
    value: 18,
    label: '18 /ページ',
    title: '',
  },
  {
    value: 30,
    label: '30 /ページ',
    title: '',
  },
  {
    value: 60,
    label: '60 /ページ',
    title: '',
  },
  {
    value: 300,
    label: '300 /ページ',
    title: '',
  },
];

// only for path project-registration, with Role Partner Admin, Partner User, Soliton Admin
const projectRegisterPageSizeOptions = ['18', '30', '60', '300'];

export {
  defaultSelectPageSizeOption,
  defaultPageSizeOptions,
  projectRegisterSelectPageSizeOption,
  projectRegisterPageSizeOptions,
};
