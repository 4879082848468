import React, { useMemo, useState } from 'react';
import { registerProject } from 'api/project/solitonAdmin.api';
import { useRecoilState } from 'recoil';
import { listFileDetailsRecoil } from 'recoil/atom/fileRequest';
import { network } from 'recoil/atom/network';
import { TIME_ZONE } from 'constant/notificationConstants';
import dayjs from 'dayjs';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';
import useModalConfirm from 'hook/useModalConfirm';
import FormProject from 'page/Application/ManageProjectRegistration/component/FormProject';
import { Link, useNavigate } from 'react-router-dom';
import { Form, message } from 'antd';
import useCustomMutateRenderMessage from 'hook/useQuery/useCustomMutateRenderMessage';
import { MANAGE_PROJECT_REGISTRATION_PATH } from 'constant/appPath';
import useErrorCodeManageProject from 'page/Application/ManageProjectRegistration/hook/useErrorCodeManageProject';

dayjs.extend(timezone);
dayjs.extend(utc);

const RegisterProject = () => {
  const [isOnline] = useRecoilState(network);
  const [isDisplayed, setIsDisplayed] = useState(true);
  const [listFileDetails, setListFileDetails] = useRecoilState(
    listFileDetailsRecoil,
  );
  const [, setIdFile] = useState(null);
  const [form] = Form.useForm();
  const [content, setContent] = useState();
  const { ERROR_CODE_MANAGE_PROJECT } = useErrorCodeManageProject();
  const { errorMessage, useMutationAPIHandleErrorCode } =
    useCustomMutateRenderMessage({
      errorCode: ERROR_CODE_MANAGE_PROJECT,
    });
  const navigate = useNavigate();
  const { contextHolder, handleConfirmAction } = useModalConfirm();
  const [isCancel, setIsCancel] = useState(false);
  const [imageObject, setImageObject] = useState(null);
  const [plainText, setPlainText] = useState(null);
  const [messageErrorNetwork, contextErrorNetwork] = message.useMessage();
  const listItem = useMemo(() => {
    return [
      {
        title: <Link to={'/'}>企業管理</Link>,
      },
      {
        title: (
          <Link to={'/manage-project-registration'}>案件登録お申込み管理</Link>
        ),
      },
      {
        title: '案件登録お申し込み',
      },
    ];
  }, []);

  const {
    mutate: registerProjectMutate,
    isError,
    isLoading,
    isSuccess,
  } = useMutationAPIHandleErrorCode({
    api: registerProject,
    refetchKey: 'listProject',
    messageSuccess: '案件登録お申込みの登録が完了しました。',
    path: `/${MANAGE_PROJECT_REGISTRATION_PATH}`,
  });
  const errorNetWork = () => {
    messageErrorNetwork
      .open({
        type: 'error',
        content:
          '問題が発生しました。インターネット接続を確認して、もう一度やり直してください。',
      })
      .then();
  };

  const onFinish = (values) => {
    if (isOnline) {
      const avatar = values?.avatar;
      const listDetails = listFileDetails.map((value) => {
        const dateFrom = value?.publishedFrom
          ? new Date(value?.publishedFrom).setSeconds(0)
          : null;
        const dateTo = value?.publishedTo
          ? new Date(value?.publishedTo).setSeconds(0)
          : null;
        return {
          ...value,
          publishedFrom:
            value.displayStatus === false
              ? null
              : dayjs.tz(
                  dateTo && dateFrom > dateTo ? dateTo : dateFrom,
                  TIME_ZONE,
                ),
          publishedTo:
            value.displayStatus === false
              ? null
              : dateTo &&
                dayjs.tz(dateFrom > dateTo ? dateFrom : dateTo, TIME_ZONE),
          displayStatus: value.displayStatus === false ? 'HIDE' : 'SHOW',
        };
      });
      const payload = {
        projectName: values?.projectName?.trim(),
        projectIsDisplayed: values?.projectIsDisplayed,
        projectUrlTitle: values?.projectUrlTitle?.trim(),
        projectUrl: values?.projectUrl?.trim(),
        projectContent: content === '<p>&nbsp;</p>' ? '' : content,
        projectSearchContent: plainText,
        fileDetails: listDetails,
        avatarRequest: imageObject && {
          fileName: imageObject?.fileName,
          keyFileName: imageObject?.keyFileName,
          contentType: imageObject?.contentType,
          size: imageObject?.size,
        },
      };

      let formData = new FormData();
      formData.append(
        'projectRequest',
        new Blob([JSON.stringify(payload)], { type: 'application/json' }),
      );

      formData.append('avatar', avatar && avatar[0]?.originFileObj);
      registerProjectMutate(formData);
      if (isSuccess) {
        setListFileDetails([]);
      }
    } else {
      errorNetWork()
    }
  };

  const handleCancel = () => {
    handleConfirmAction({
      confirmFn: navigate,
      params: `/${MANAGE_PROJECT_REGISTRATION_PATH}`,
      title: 'この案件登録お申込みをキャンセルしてもよろしいでしょうか？',
      okText: 'はい',
      cancelText: 'キャンセル',
      setIsDisable: setIsCancel,
    });
    setIsCancel(true);
  };

  return (
    <>
      {contextErrorNetwork}
      {contextHolder}
      <FormProject
        isDisplayed={isDisplayed}
        setIsDisplayed={setIsDisplayed}
        onFinish={onFinish}
        form={form}
        listItem={listItem}
        content={content}
        setContent={setContent}
        errorMessage={errorMessage}
        isError={isError}
        isSuccess={isSuccess}
        isLoading={isLoading}
        setIdFile={setIdFile}
        handleCancel={handleCancel}
        isCancel={isCancel}
        setIsCancel={setIsCancel}
        imageObject={imageObject}
        setImageObject={setImageObject}
        setPlainText={setPlainText}
      />
    </>
  );
};

export default RegisterProject;
