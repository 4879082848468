import { TextFileTitleStyle } from 'page/Application/ProjectRegistration/ProjectRegistrationDetail/styled';
import React, { useMemo } from 'react';
import AntdTable from 'components/AntdTable';
import dayjs from 'dayjs';
import { Tooltip } from 'antd';

const TableListFile = ({ dataRaw, handleDownloadFile }) => {
  const dataSource = useMemo(() => {
    if (!dataRaw && dataRaw?.length === 0) return;
    return dataRaw?.map((item) => ({
      ...item,
      key: item.detailId,
    }));
  }, [dataRaw]);

  const columns = useMemo(() => {
    return [
      {
        title: (
          <Tooltip title={'タイトル'} placement={'topLeft'}>
            <div>タイトル</div>
          </Tooltip>
        ),
        dataIndex: 'detailTitle',
        key: 'detailTitle',
        ellipsis: true,
        width: '40%',
        render: (detailTitle) => (
          <TextFileTitleStyle>
            <Tooltip title={detailTitle} placement={'topLeft'}>
              {detailTitle}
            </Tooltip>
          </TextFileTitleStyle>
        ),
      },
      {
        title: (
          <Tooltip title={'ファイル'} placement={'topLeft'}>
            <div>ファイル</div>
          </Tooltip>
        ),
        dataIndex: 'detailUrl',
        key: 'detailUrl',
        ellipsis: true,
        width: '30%' > 100 ? '30%' : 100,
        render: (url, record) => (
          <span
            style={{ color: '#7B61FF', cursor: 'pointer' }}
            onClick={() => handleDownloadFile(record.detailId)}
          >
            <Tooltip title={record.fileResponse.fileName} placement={'topLeft'}>
              {record.fileResponse.fileName}
            </Tooltip>
          </span>
        ),
      },
      {
        title: (
          <Tooltip title={'更新日'} placement={'topLeft'}>
            {'更新日'}
          </Tooltip>
        ),
        dataIndex: 'detailDisplayDate',
        key: 'detailDisplayDate',
        width: '30%' > 100 ? '30%' : 100,
        render: (item) => (
          <div>
            <Tooltip
              title={item && dayjs(item).format('YYYY年MM月DD日')}
              placement={'topLeft'}
            >
              {item && dayjs(item).format('YYYY年MM月DD日')}
            </Tooltip>
          </div>
        ),
      },
    ];
  }, [handleDownloadFile]);
  return (
    <AntdTable isLoading={false} columns={columns} dataSource={dataSource} />
  );
};

export default TableListFile;
