import { useIntl } from 'react-intl';

const useMultiLanguageRegisterNotification = () => {
  const intl = useIntl();

  const REGISTER_NOTIFICATION_TITLE = intl.formatMessage({
    id: 'application.registerNotification.title',
  });

  const REGISTER_NOTIFICATION_FORM_TITLE = intl.formatMessage({
    id: 'application.registerNotification.form.title',
  });

  const REGISTER_NOTIFICATION_FORM_STATUS = intl.formatMessage({
    id: 'application.registerNotification.form.status',
  });

  const REGISTER_NOTIFICATION_FORM_PUBLISHED_FROM_DATE = intl.formatMessage({
    id: 'application.registerNotification.form.publishedFrom',
  });

  const REGISTER_NOTIFICATION_FORM_PUBLISHED_TO_DATE = intl.formatMessage({
    id: 'application.registerNotification.form.publishedTo',
  });

  const REGISTER_NOTIFICATION_FORM_DISPLAYED_DATE = intl.formatMessage({
    id: 'application.registerNotification.form.displayedDate',
  });

  const REGISTER_NOTIFICATION_FORM_CATEGORY = intl.formatMessage({
    id: 'application.registerNotification.form.category',
  });

  const REGISTER_NOTIFICATION_FORM_POSITIONS = intl.formatMessage({
    id: 'application.registerNotification.form.positions',
  });

  const REGISTER_NOTIFICATION_FORM_DESTINATION_TYPE = intl.formatMessage({
    id: 'application.registerNotification.form.destinationType',
  });

  const MODAL_PRODUCTS_TITLE = intl.formatMessage({
    id: 'application.registerNotification.modalProducts',
  });

  const MODAL_PRODUCTS_TABLE_TITLE = intl.formatMessage({
    id: 'application.registerNotification.modalTable',
  });

  const REGISTER_NOTIFICATION_FORM_CONTENT = intl.formatMessage({
    id: 'application.registerNotification.form.content',
  });

  const REGISTER_NOTIFICATION_FORM_URL = intl.formatMessage({
    id: 'application.registerNotification.form.url',
  });

  const REGISTER_NOTIFICATION_FORM_PDF = intl.formatMessage({
    id: 'application.registerNotification.form.pdf',
  });

  const REGISTER_NOTIFICATION_FORM_SUBMIT = intl.formatMessage({
    id: 'application.registerNotification.form.submit',
  });

  const REGISTER_NOTIFICATION_FORM_CANCEL = intl.formatMessage({
    id: 'application.registerNotification.form.cancel',
  });

  const REGISTER_NOTIFICATION_FORM_TOP_PAGE = intl.formatMessage({
    id: 'application.registerNotification.form.topPage',
  });

  const REGISTER_NOTIFICATION_MESSAGE_ERROR_MISSING_ENTER_NOTIFICATION_TITLE =
    intl.formatMessage({
      id: 'application.registerNotification.message.error.missing.enter.notification.title',
    });

  const REGISTER_NOTIFICATION_MESSAGE_CANCEL = intl.formatMessage({
    id: 'application.registerNotification.message.cancel',
  });

  const REGISTER_NOTIFICATION_OK = intl.formatMessage({
    id: 'application.registerNotification.ok',
  });

  const REGISTER_NOTIFICATION_ADD_PRODUCT = intl.formatMessage({
    id: 'application.registerNotification.addProduct',
  });

  const REGISTER_NOTIFICATION_EDIT_PRODUCT = intl.formatMessage({
    id: 'application.registerNotification.editProduct',
  });

  const REGISTER_NOTIFICATION_CANCEL = intl.formatMessage({
    id: 'application.registerNotification.cancel',
  });

  const REGISTER_NOTIFICATION_FORM_PLACEHOLDER_DISPLAYED_DATE =
    intl.formatMessage({
      id: 'application.registerNotification.form.placeHolderDisplayedDate',
    });

  const REGISTER_NOTIFICATION_FORM_PLACEHOLDER_TO_DATE = intl.formatMessage({
    id: 'application.registerNotification.form.placeHolderToDate',
  });

  const REGISTER_NOTIFICATION_FORM_UPLOAD_TEXT = intl.formatMessage({
    id: 'application.registerNotification.form.uploadText',
  });
  const REGISTER_NOTIFICATION_FORM_UPLOAD_HINT = intl.formatMessage({
    id: 'application.registerNotification.form.uploadHint',
  });

  const REGISTER_NOTIFICATION_MESSAGE_SUCCESS = intl.formatMessage({
    id: 'application.registerNotification.messageSuccess',
  });

  const REGISTER_NOTIFICATION_FIELD_REQUIRED = intl.formatMessage({
    id: 'application.registerNotification.field.required',
  });

  const REGISTER_NOTIFICATION_MODAL_ADD_PRODUCT_PRODUCT = intl.formatMessage({
    id: 'application.registerNotification.modal.addProduct.product',
  });

  const REGISTER_NOTIFICATION_MODAL_ADD_PRODUCT_SERVICE = intl.formatMessage({
    id: 'application.registerNotification.modal.addProduct.service',
  });

  const REGISTER_NOTIFICATION_MODAL_ADD_PRODUCT_SOLUTION = intl.formatMessage({
    id: 'application.registerNotification.modal.addProduct.solution',
  });

  const REGISTER_NOTIFICATION_MODAL_ADD_PROJECT_SOLUTION = intl.formatMessage({
    id: 'application.registerNotification.modal.addProduct.project',
  });

  const REGISTER_NOTIFICATION_POPOVER_PUBLISHED_DATE_TO = intl.formatMessage({
    id: 'application.registerNotification.popover.publishedDateTo',
  });

  const REGISTER_NOTIFICATION_FILE_UPLOAD_WITHOUT_INTERNET = intl.formatMessage(
    {
      id: 'application.registerNotification.file.uploadWithoutInternet',
    },
  );

  const REGISTER_NOTIFICATION_FILE_ALLOWED_TO_UPLOAD = intl.formatMessage({
    id: 'application.registerNotification.file.allowedToUpload',
  });

  return {
    REGISTER_NOTIFICATION_TITLE,
    REGISTER_NOTIFICATION_FORM_TITLE,
    REGISTER_NOTIFICATION_FORM_STATUS,
    REGISTER_NOTIFICATION_FORM_PUBLISHED_FROM_DATE,
    REGISTER_NOTIFICATION_FORM_PUBLISHED_TO_DATE,
    REGISTER_NOTIFICATION_FORM_DISPLAYED_DATE,
    REGISTER_NOTIFICATION_FORM_CATEGORY,
    REGISTER_NOTIFICATION_FORM_TOP_PAGE,
    REGISTER_NOTIFICATION_FORM_POSITIONS,
    REGISTER_NOTIFICATION_MESSAGE_ERROR_MISSING_ENTER_NOTIFICATION_TITLE,
    REGISTER_NOTIFICATION_FORM_DESTINATION_TYPE,
    REGISTER_NOTIFICATION_FORM_CONTENT,
    REGISTER_NOTIFICATION_FORM_URL,
    REGISTER_NOTIFICATION_FORM_PDF,
    REGISTER_NOTIFICATION_FORM_SUBMIT,
    REGISTER_NOTIFICATION_FORM_CANCEL,
    MODAL_PRODUCTS_TITLE,
    MODAL_PRODUCTS_TABLE_TITLE,
    REGISTER_NOTIFICATION_MESSAGE_CANCEL,
    REGISTER_NOTIFICATION_CANCEL,
    REGISTER_NOTIFICATION_OK,
    REGISTER_NOTIFICATION_ADD_PRODUCT,
    REGISTER_NOTIFICATION_EDIT_PRODUCT,
    REGISTER_NOTIFICATION_FORM_PLACEHOLDER_DISPLAYED_DATE,
    REGISTER_NOTIFICATION_FORM_PLACEHOLDER_TO_DATE,
    REGISTER_NOTIFICATION_FORM_UPLOAD_TEXT,
    REGISTER_NOTIFICATION_FORM_UPLOAD_HINT,
    REGISTER_NOTIFICATION_MESSAGE_SUCCESS,
    REGISTER_NOTIFICATION_FIELD_REQUIRED,
    REGISTER_NOTIFICATION_MODAL_ADD_PRODUCT_PRODUCT,
    REGISTER_NOTIFICATION_MODAL_ADD_PRODUCT_SOLUTION,
    REGISTER_NOTIFICATION_MODAL_ADD_PROJECT_SOLUTION,
    REGISTER_NOTIFICATION_MODAL_ADD_PRODUCT_SERVICE,
    REGISTER_NOTIFICATION_POPOVER_PUBLISHED_DATE_TO,
    REGISTER_NOTIFICATION_FILE_UPLOAD_WITHOUT_INTERNET,
    REGISTER_NOTIFICATION_FILE_ALLOWED_TO_UPLOAD,
  };
};
export default useMultiLanguageRegisterNotification;
