export const DETAIL_CONTENT_TYPE_DROPDOWN = [
  {
    key: 0,
    label: 'ファイル',
    value: 'FILE',
    title: '',
  },
  {
    key: 1,
    label: 'リンク',
    value: 'LINK',
    title: '',
  },
  {
    key: 2,
    label: '詳細あり',
    value: 'DETAIL',
    title: '',
  },
  // {
  //   key: 3,
  //   label: 'NOTICE',
  //   value: 'NOTICE',
  // },
];

export const DETAIL_CONTENT_TYPE_LIST = {
  FILE: 'ファイル',
  LINK: 'リンク',
  DETAIL: '詳細あり',
  title: '',
};

export const DETAIL_CONTENT_DISPLAY_STATUS = [
  {
    key: 0,
    label: '表示',
    value: 'SHOW',
    title: '',
  },
  {
    key: 1,
    label: '非表示',
    value: 'HIDE',
    title: '',
  },
  {
    key: 2,
    label: '予約',
    value: 'SCHEDULED',
    title: '',
  },
  {
    key: 3,
    label: 'DL停止',
    value: 'DOWNLOADABLE_STOPPED',
    title: '',
  },
];

export const STATUS_FOR_LIST_CONTENT = {
  SHOW: '表示',
  HIDE: '非表示',
  SCHEDULED: '予約',
  DOWNLOADABLE_STOPPED: 'DL停止',
};

export const STATUS_FOR_MODAL = {
  表示: 'SHOW',
  非表示: 'HIDE',
  予約: 'SCHEDULED',
  DL停止: 'DOWNLOADABLE_STOPPED',
};
