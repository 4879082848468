import AntdModal3 from 'components/AntdModal3';
import ButtonModalSearch from 'page/Application/ManageNotificationList/component/ButtonModalSearch/index';
import useMultiLanguageNotificationList from 'page/Application/ManageNotificationList/NotificationList/hook/useMultiLanguageNotificationList';
import { FromWrapper, Grid1, Grid2, GridForm } from './styled';
import { Form } from 'antd';
import { useForm } from 'antd/es/form/Form';
import FormLabelGrid from 'page/Application/ManageNotificationList/NotificationList/component/FormLabelGrid';
import FormDateRangePicker from 'page/Application/ManageNotificationList/component/Form/FormDateRangePicker';
import FormDropDown from 'page/Application/ManageNotificationList/component/Form/FormDropDown';
import { DATE_FORMAT_LIST } from 'constant/notificationConstants';
import useNotifyCategoryMultiLanguage from 'hook/useNotifyCategoryMultiLanguage';
import useStatusNotifyMultiLanguage from 'hook/useStatusNotifyMultiLanguage';
import dayjs from 'dayjs';
import { useEffect, useState } from 'react';

const ModalSearchNotification = ({
  modalKey,
  isModalOpen,
  setIsModalOpen,
  setFilterData,
  setResetStatusFilter,
  resetStatusFilter,
  setSortedInfo,
}) => {
  const [form] = useForm();
  const { NOTIFICATION_CATEGORY_FOR_FORM } = useNotifyCategoryMultiLanguage();
  const { STATUS_FOR_FORM } = useStatusNotifyMultiLanguage();
  const [submittable, setSubmittable] = useState(false);
  const values = Form.useWatch([], form);

  const {
    NOTIFICATION_LIST_MODAL_TITLE_FILTER_DATA,
    NOTIFICATION_LIST_MODAL_DISPLAYED_DATE,
    NOTIFICATION_LIST_MODAL_TYPE,
    NOTIFICATION_LIST_MODAL_STATUS,
    NOTIFICATION_LIST_MODAL_BUTTON_RESET,
  } = useMultiLanguageNotificationList();

  const handleOk = (values) => {
    setSortedInfo({});
    setIsModalOpen(false);
    setFilterData({
      category: values.category ?? '',
      status: values.status ?? '',
      displayedDateFrom: values?.displayedDate?.[0]
        ? dayjs(values?.displayedDate?.[0]).format(DATE_FORMAT_LIST[3])
        : '',
      displayedDateTo: values?.displayedDate?.[1]
        ? dayjs(values?.displayedDate?.[1]).format(DATE_FORMAT_LIST[3])
        : '',
    });
    setResetStatusFilter(false);
  };

  const onReset = () => {
    form.resetFields();
  };

  const handleCancel = () => {
    form.resetFields();
    setIsModalOpen(false);
  };

  const handleSelectedDate = (dates) => {
    if (dates) {
      form.setFieldValue('displayedDate', [
        dayjs(dates[0]).isValid() ? dayjs(dates[0]) : null,
        dayjs(dates[1]).isValid() ? dayjs(dates[1]) : null,
      ]);
    }
  };

  useEffect(() => {
    if (resetStatusFilter) {
      form.resetFields();
    }
  }, [resetStatusFilter, form]);

  useEffect(() => {
    if (isModalOpen) {
      form.validateFields().then((values) => {
        if (values.displayedDate || values.category || values.status) {
          setSubmittable(true);
        } else {
          setSubmittable(false);
        }
      });
    }
  }, [values, form, isModalOpen]);

  return (
    <AntdModal3
      title={NOTIFICATION_LIST_MODAL_TITLE_FILTER_DATA}
      width={640}
      handleOk={() => {
        handleOk(values);
      }}
      isModalOpen={isModalOpen}
      handleReset={onReset}
      onCancel={handleCancel}
      justifyContent="flex-end"
      footer={
        <ButtonModalSearch
          modalKey={modalKey}
          cancelText={NOTIFICATION_LIST_MODAL_BUTTON_RESET}
          handleReset={onReset}
          handleOk={() => {
            handleOk(values);
          }}
          submittable={!submittable}
          size={'large'}
        />
      }
    >
      <FromWrapper>
        <Form form={form} layout="horizontal" size="large" autoComplete={'off'}>
          <GridForm>
            <Grid1 style={{ marginTop: '8px' }}>
              <FormLabelGrid
                labelText={NOTIFICATION_LIST_MODAL_DISPLAYED_DATE}
              />
              <FormDateRangePicker
                width={'auto'}
                isShowTime={false}
                name="displayedDate"
                dateFormat={DATE_FORMAT_LIST[1]}
                handleSelectedDate={handleSelectedDate}
                isList={true}
              />
            </Grid1>
            <Grid2 style={{ marginTop: '8px' }}>
              <FormLabelGrid
                labelText={NOTIFICATION_LIST_MODAL_TYPE}
              ></FormLabelGrid>
              <FormDropDown
                name="category"
                data={NOTIFICATION_CATEGORY_FOR_FORM}
              />
            </Grid2>
            <Grid1>
              <FormLabelGrid labelText={NOTIFICATION_LIST_MODAL_STATUS} />
              <FormDropDown name="status" data={STATUS_FOR_FORM} />
            </Grid1>
          </GridForm>
        </Form>
      </FromWrapper>
    </AntdModal3>
  );
};

export default ModalSearchNotification;
