import { createGlobalStyle } from 'styled-components';

export const ColorVariable = createGlobalStyle`
    :root {
        //----------SIDEBAR----------//
        --SIDEBAR-background-default--LIGHT: #303030;
        --CHECKBOX-default: #656464;

        --PRIMARY-default: #9D1C49;
        --PRIMARY-hover: #9D1C49;

        --SECONDARY-default: #626262;
        --FORM-LABEL-default: #656464;

        --ICON-default: #A5A5A5;

        --ERROR-MESSAGE-PRIMARY-default: #9D1C49;
        --ERROR-MESSAGE-SECONDARY-default: #A5A5A5;

        //----------BUTTON----------//
        --BTN-TEXT-PRIMARY-default: #9D1C49;
        --BTN-TEXT-SECONDARY-default: #656464;
        --BTN-ICON-SECONDARY-default: #A5A5A5;

        //----------BUTTON SECONDARY----------//
        --BTN-SECONDARY-BACKGROUND--default : #656464;
        --BTN-SECONDAY-TEXT-default : #FFFFFF;

        //----------BUTTON ICON----------//


   
    }`;
