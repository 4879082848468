import React, { useMemo } from 'react';
import { EditOutlined } from '@ant-design/icons';
import { EDIT_PATH, MANAGE_COMPANY_LIST_PATH } from 'constant/appPath';
import { ButtonAction, GroupButtonAction } from 'style/styleComponent/styled';
import { Table, Tooltip } from 'antd';
import { useNavigate } from 'react-router-dom';
import AntdTable from 'components/AntdTable';
import useModalConfirm from 'hook/useModalConfirm';
import useMultiLanguageCompanyList from 'page/Application/ManageCompanyList/CompanyList/hook/useMultiLanguageCompanyList';
import {
  CustomRowTable,
  TableGrid2,
  TableGrid3,
} from 'page/Application/ManageCompanyList/CompanyList/component/TableData/styled';
import { TooltipHide } from 'page/Application/ManageCompanyList/CompanyList/styled';
import useErrorNetworkMessage from 'hook/useErrorNetworkMessage';

/*
 * component table in screen company list.
 * dataRaw: data from API, need format for add key.
 * isLoading: render loading when api fetching.
 * setListItemDelete: setState list item for delete multiple item.
 * setItemDelete: setState single item for delete item.
 * handleDeleteCompany:.
 */

const TableData = ({ dataRaw, isLoading, emptyText, onChange, sortedInfo }) => {
  const {
    COMPANY_LIST_TABLE_TITLE_COMPANY_NAME,
    COMPANY_LIST_TABLE_TITLE_COMPANY_DOMAIN,
    COMPANY_LIST_TABLE_TITLE_AUTO_APPROVE,
    COMPANY_LIST_TABLE_TITLE_ACTION,
    COMPANY_LIST_TABLE_STATUS_APPROVE_AUTO,
    COMPANY_LIST_TABLE_STATUS_APPROVE_NON,
  } = useMultiLanguageCompanyList();
  const navigate = useNavigate();
  const { contextHolder } = useModalConfirm();
  const { isOnline, errorNetWork, contextErrorNetWork } =
    useErrorNetworkMessage();
  // initial columns for table
  const columns = useMemo(() => {
    // move user to edit screen
    const handleEdit = (id) => {
      if (isOnline) {
        navigate(`/${MANAGE_COMPANY_LIST_PATH}/${EDIT_PATH}/${id}`);
      } else {
        errorNetWork();
      }
    };

    return [
      // Table.SELECTION_COLUMN,
      Table.EXPAND_COLUMN,
      {
        key: 'partnerName',
        title: (
          <Tooltip
            title={COMPANY_LIST_TABLE_TITLE_COMPANY_NAME}
            placement={'topLeft'}
          >
            {COMPANY_LIST_TABLE_TITLE_COMPANY_NAME}
          </Tooltip>
        ),
        dataIndex: 'partnerName',
        ellipsis: true,
        align: 'left',
        showSorterTooltip: false,
        sorter: true,
        render: (partnerName) => (
          <TooltipHide>
            <Tooltip title={partnerName} placement={'topLeft'}>
              {partnerName}
            </Tooltip>
          </TooltipHide>
        ),
        sortOrder:
          sortedInfo.columnKey === 'partnerName' ? sortedInfo.order : null,
      },
      {
        key: 'name',
        title: (
          <TooltipHide>
            <Tooltip
              title={COMPANY_LIST_TABLE_TITLE_COMPANY_DOMAIN}
              placement={'topLeft'}
            >
              {COMPANY_LIST_TABLE_TITLE_COMPANY_DOMAIN}
            </Tooltip>
          </TooltipHide>
        ),
        dataIndex: 'name',
        align: 'left',
        ellipsis: true,
      },
      {
        key: 'isAutoApprove',
        title: (
          <TooltipHide>
            <Tooltip
              title={COMPANY_LIST_TABLE_TITLE_AUTO_APPROVE}
              placement={'topLeft'}
            >
              {COMPANY_LIST_TABLE_TITLE_AUTO_APPROVE}
            </Tooltip>
          </TooltipHide>
        ),
        dataIndex: 'isAutoApprove',
        align: 'left',
        ellipsis: true,
      },
      {
        key: 'partnerId',
        title: (
          <TooltipHide style={{ paddingLeft: 40 }}>
            <Tooltip
              title={COMPANY_LIST_TABLE_TITLE_ACTION}
              placement={'topLeft'}
            >
              {COMPANY_LIST_TABLE_TITLE_ACTION}
            </Tooltip>
          </TooltipHide>
        ),
        dataIndex: 'partnerId',
        ellipsis: true,
        render: (partnerId) => (
          <GroupButtonAction>
            <ButtonAction type="link" onClick={() => handleEdit(partnerId)}>
              <EditOutlined />
            </ButtonAction>
          </GroupButtonAction>
        ),
        align: 'left',
        width: 120,
      },
    ];
  }, [
    COMPANY_LIST_TABLE_TITLE_ACTION,
    COMPANY_LIST_TABLE_TITLE_AUTO_APPROVE,
    COMPANY_LIST_TABLE_TITLE_COMPANY_DOMAIN,
    COMPANY_LIST_TABLE_TITLE_COMPANY_NAME,
    navigate,
    sortedInfo?.columnKey,
    sortedInfo?.order,
    isOnline,
    errorNetWork
  ]);

  // format data for render table
  const dataSource = useMemo(() => {
    if (isLoading && !dataRaw) return [];
    return dataRaw?.list?.map((item) => ({
      ...item,
      key: item.partnerId,
      lstDomainResponses: item.lstDomainResponses?.map((childrenItem) => {
        childrenItem.domainId ??= '';
        return {
          ...childrenItem,
          key: childrenItem?.domainId.toString() + childrenItem.name,
        };
      }),
    }));
  }, [dataRaw, isLoading]);

  // render expand row, style like a row in table
  const expandable = {
    expandedRowRender: (record) => {
      return record.lstDomainResponses?.map((item) => (
        <CustomRowTable key={item.key}>
          <TableGrid2>{item.domainName}</TableGrid2>
          <TableGrid3>
            {item?.isAutoApprove
              ? COMPANY_LIST_TABLE_STATUS_APPROVE_AUTO
              : COMPANY_LIST_TABLE_STATUS_APPROVE_NON}
          </TableGrid3>
        </CustomRowTable>
      ));
    },
    rowExpandable: (record) => record.lstDomainResponses?.length > 0,
  };

  return (
    <>
      {contextHolder}
      {contextErrorNetWork}
      <AntdTable
        height={532}
        columns={columns}
        isLoading={isLoading}
        dataSource={dataSource}
        expandable={expandable}
        emptyText={emptyText}
        onChange={onChange}
      />
    </>
  );
};

export default TableData;
