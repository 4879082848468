import { useState } from 'react';
import useMultiLanguage from 'hook/useMultiLanguage';

const useCheckErrorCode = ({ errorCode }) => {
  const [errorMessage, setErrorMessage] = useState('');
  const { APPLICATION_CHECK_NETWORK_CONNECT } = useMultiLanguage();
  // step 1. check network error => return true || false
  const handleCheckNetWork = (response) => {
    if (response?.code && response.code === 'ERR_NETWORK') {
      return true;
    }
  };

  // step 2. check error message => return true || false
  const handleCheckErrorCode = (response) => {
    if (response.response.data?.ec && response.response.data.message !== '') {
      return true;
    }
  };

  // step 3. if error message not valid in object errorCode => return default message
  const handleCheckMessageExists = (response) => {
    if (
      errorCode[response.response.data.message] &&
      errorCode[response.response.data.message].message
    ) {
      return true;
    }
  };

  // call function check error step 1, 2, 3
  const handleRenderMessage = (response) => {
    // check network
    if (handleCheckNetWork(response)) {
      setErrorMessage(APPLICATION_CHECK_NETWORK_CONNECT);
      // check jwt expired
    } else if (handleCheckErrorCode(response)) {
      // check error code exists
      if (handleCheckMessageExists(response)) {
        if (response.response.data.message?.errorName) {
          let selectedErrorMessage = response.response.data?.messageWithName;
          setErrorMessage(selectedErrorMessage);
        } else {
          let selectedErrorMessage =
            errorCode[response.response.data.message].message;
          setErrorMessage(selectedErrorMessage);
        }
      } else {
        // if error code not exists
        setErrorMessage('問題が発生しました。この操作は実施できておりません。'); //default message | An unexpected error has occurred.
      }
    }
  };

  const handleClearErrorMessage = () => {
    setErrorMessage('');
  };

  return {
    handleRenderMessage,
    handleClearErrorMessage,
    errorMessage,
  };
};

export default useCheckErrorCode;
