import { useIntl } from 'react-intl';

const useMultiLanguageSignUp = () => {
  const intl = useIntl();

  const SIGN_UP_TITLE = intl.formatMessage({
    id: 'authentication.signUp.title',
  });

  const SIGN_UP_EMAIL_ADDRESS = intl.formatMessage({
    id: 'authentication.emailAddress',
  });

  const SIGN_UP_EMAIL_ADDRESS_PLACEHOLDER = intl.formatMessage({
    id: 'authentication.emailAddress.placeholder',
  });

  const SIGN_UP_EMAIL_SUCCESS_MESSAGE = intl.formatMessage({
    id: 'authentication.signUp.success',
  });

  const SIGN_UP_CHOOSE_PARTNER_TITLE = intl.formatMessage({
    id: 'authentication.signUp.choosePartner.title',
  });

  const SIGN_UP_CHOOSE_PARTNER_DESCRIBE = intl.formatMessage({
    id: 'authentication.signUp.choosePartner.describe',
  });

  return {
    SIGN_UP_TITLE,
    SIGN_UP_EMAIL_ADDRESS,
    SIGN_UP_EMAIL_ADDRESS_PLACEHOLDER,
    SIGN_UP_EMAIL_SUCCESS_MESSAGE,
    SIGN_UP_CHOOSE_PARTNER_TITLE,
    SIGN_UP_CHOOSE_PARTNER_DESCRIBE,
  };
};

export default useMultiLanguageSignUp;
