import React from 'react';
import { Outlet } from 'react-router-dom';
import ProjectRegistrationDetail from 'page/Application/ProjectRegistration/ProjectRegistrationDetail';
import ProjectRegistrationOverview from 'page/Application/ProjectRegistration/ProjectRegistrationOverview';
import useSetTitlePage from 'hook/useSetTitlePage';

const ProjectRegistration = () => {
  useSetTitlePage('案件登録お申込み');
  return (
    <>
      <Outlet />
    </>
  );
};

export default ProjectRegistration;

export { ProjectRegistrationDetail, ProjectRegistrationOverview };
