import React from 'react';
import { CaretRightOutlined } from '@ant-design/icons';
import { BtnNavigation } from 'components/Button/styled';

const ButtonNavigate = ({ text, onClick }) => {
  return (
    <BtnNavigation size={'Large'} onClick={onClick}>
      {text}
      <CaretRightOutlined />
    </BtnNavigation>
  );
};

export default ButtonNavigate;
