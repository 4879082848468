import { useIntl } from 'react-intl';

const useMultiLanguage = () => {
  const intl = useIntl();

  const FORM_EMAIL_ADDRESS_LABEL = intl.formatMessage({
    id: 'authentication.emailAddress',
  });
  const FORM_EMAIL_ADDRESS_PLACEHOLDER = intl.formatMessage({
    id: 'authentication.emailAddress.placeholder',
  });

  const FORM_EMAIL_ADDRESS_ERROR_MESSAGE_EMPTY = intl.formatMessage({
    id: 'authentication.email.errorMessage.empty',
  });

  const FORM_EMAIL_ADDRESS_ERROR_MESSAGE_VALIDATE = intl.formatMessage({
    id: 'authentication.email.errorMessage.validate',
  });

  const FORM_PASSWORD_LABEL = intl.formatMessage({
    id: 'authentication.password',
  });

  const FORM_PASSWORD_PLACEHOLDER = intl.formatMessage({
    id: 'authentication.password.placeholder',
  });
  const FORM_VERIFY_PASSWORD_PLACEHOLDER = intl.formatMessage({
    id: 'authentication.verifyPassword.placeholder',
  });

  const FORM_PASSWORD_ERROR_MESSAGE_EMPTY = intl.formatMessage({
    id: 'authentication.password.errorMessage.empty',
  });

  const FORM_PASSWORD_ERROR_MESSAGE_VALIDATE_1 = intl.formatMessage({
    id: 'authentication.password.errorMessage.validate1',
  });

  const FORM_PASSWORD_ERROR_MESSAGE_VALIDATE_2 = intl.formatMessage({
    id: 'authentication.password.errorMessage.validate2',
  });

  const FORM_SIGNUP_SUCCESS_MESSAGE = intl.formatMessage({
    id: 'authentication.signUp.success',
  });

  const FORM_LAST_NAME_LABEL = intl.formatMessage({
    id: 'authentication.signUp.lastName',
  });

  const FORM_LAST_NAME_LABEL_PLACEHOLDER = intl.formatMessage({
    id: 'authentication.signUp.lastName.placeholder',
  });

  const FORM_NAME_ERROR_MESSAGE_EMPTY = intl.formatMessage({
    id: 'authentication.signUp.name.error.empty',
  });
  const FORM_NAME_ERROR_MESSAGE = intl.formatMessage({
    id: 'authentication.signUp.error.name',
  });

  const FORM_FIRST_NAME_LABEL = intl.formatMessage({
    id: 'authentication.signUp.firstName',
  });
  const FORM_FIRST_NAME_PLACEHOLDER = intl.formatMessage({
    id: 'authentication.signUp.firstName.placeholder',
  });

  const FORM_SIGN_IN_BUTTON_SUBMIT = intl.formatMessage({
    id: 'authentication.signIn.buttonSubmit',
  });

  const FORM_SIGN_IN_MOVE_TO_SIGN_UP = intl.formatMessage({
    id: 'authentication.signIn.moveToSignUp',
  });

  const FORM_FORGOT_PASSWORD_BUTTON_SUBMIT = intl.formatMessage({
    id: 'authentication.forgotPassword.buttonSubmit',
  });

  const FORM_FORGOT_PASSWORD_MOVE_TO_SIGN_IN = intl.formatMessage({
    id: 'authentication.forgotPassword.return',
  });

  const FORM_SIGN_UP_BUTTON_SUBMIT = intl.formatMessage({
    id: 'authentication.signUp.buttonRegistration',
  });

  const FORM_SIGN_UP_NAVIGATE_TO_LOGIN = intl.formatMessage({
    id: 'authentication.signUp.navigateToLogin',
  });

  const FORM_PASSWORD_EXTRA_TEXT = intl.formatMessage({
    id: 'authentication.password.extraText',
  });

  const FORM_PHONE_NUMBER_EXTRA_TEXT = intl.formatMessage({
    id: 'authentication.phoneNumber.extraText',
  });

  const FORM_PASSWORD_ERROR_MESSAGE_CHECK_SAME_PASSWORD = intl.formatMessage({
    id: 'authentication.password.errorMessage.checkSamePassword',
  });

  const FORM_PASSWORD_LABEL_NEW_PASSWORD = intl.formatMessage({
    id: 'changePassword.label.newPassword',
  });

  const FORM_PASSWORD_PLACEHOLDER_NEW_PASSWORD = intl.formatMessage({
    id: 'changePassword.newPassword.placeholder',
  });

  const FORM_PASSWORD_LABEL_VERIFY_PASSWORD = intl.formatMessage({
    id: 'changePassword.label.verifyPassword',
  });

  const FORM_PASSWORD_BUTTON_SUBMIT = intl.formatMessage({
    id: 'changePassword.button.submit',
  });

  const FORM_SIGN_UP_REQUIRED_FIELD = intl.formatMessage({
    id: 'authentication.signUp.required.field',
  });

  const APPLICATION_OK_TEXT = intl.formatMessage({
    id: 'application.okText',
  });

  const APPLICATION_CANCEL_TEXT = intl.formatMessage({
    id: 'application.cancelText',
  });

  const APPLICATION_REGISTER = intl.formatMessage({
    id: 'application.register',
  });

  const APPLICATION_PARTNER_PAGE = intl.formatMessage({
    id: 'application.partnerPage',
  });

  const FORM_SALE_FORCE_VALIDATE_MESSAGE = intl.formatMessage({
    id: 'application.RegisterCompany.message.error.saleForceNotValid',
  });

  const APPLICATION_CHECK_NETWORK_CONNECT = intl.formatMessage({
    id: 'application.checkNetworkConnect',
  });
  const APPLICATION_DOMAIN_NOT_REGISTERED = intl.formatMessage({
    id: 'application.errorMessage.401_Domain_Not_Registered',
  });

  const APPLICATION_DOMAIN_NOT_BELONG_TO_PARTNER = intl.formatMessage({
    id: 'application.errorMessage.400_Domain_Not_Belong_To_Partner',
  });

  return {
    FORM_EMAIL_ADDRESS_LABEL,
    FORM_EMAIL_ADDRESS_PLACEHOLDER,
    FORM_EMAIL_ADDRESS_ERROR_MESSAGE_EMPTY,
    FORM_EMAIL_ADDRESS_ERROR_MESSAGE_VALIDATE,
    FORM_PASSWORD_LABEL,
    FORM_PASSWORD_PLACEHOLDER,
    FORM_VERIFY_PASSWORD_PLACEHOLDER,
    FORM_PASSWORD_ERROR_MESSAGE_EMPTY,
    FORM_PASSWORD_ERROR_MESSAGE_VALIDATE_1,
    FORM_PASSWORD_ERROR_MESSAGE_VALIDATE_2,
    FORM_SIGNUP_SUCCESS_MESSAGE,

    FORM_LAST_NAME_LABEL,
    FORM_LAST_NAME_LABEL_PLACEHOLDER,
    FORM_FIRST_NAME_LABEL,
    FORM_FIRST_NAME_PLACEHOLDER,
    FORM_NAME_ERROR_MESSAGE,
    FORM_NAME_ERROR_MESSAGE_EMPTY,

    FORM_SIGN_IN_BUTTON_SUBMIT,
    FORM_SIGN_IN_MOVE_TO_SIGN_UP,

    FORM_FORGOT_PASSWORD_BUTTON_SUBMIT,
    FORM_FORGOT_PASSWORD_MOVE_TO_SIGN_IN,

    FORM_SIGN_UP_BUTTON_SUBMIT,
    FORM_SIGN_UP_NAVIGATE_TO_LOGIN,

    FORM_PASSWORD_EXTRA_TEXT,
    FORM_PASSWORD_ERROR_MESSAGE_CHECK_SAME_PASSWORD,
    FORM_PHONE_NUMBER_EXTRA_TEXT,

    FORM_PASSWORD_LABEL_NEW_PASSWORD,
    FORM_PASSWORD_LABEL_VERIFY_PASSWORD,
    FORM_PASSWORD_PLACEHOLDER_NEW_PASSWORD,
    FORM_PASSWORD_BUTTON_SUBMIT,

    APPLICATION_OK_TEXT,
    APPLICATION_CANCEL_TEXT,
    APPLICATION_REGISTER,
    APPLICATION_PARTNER_PAGE,
    APPLICATION_CHECK_NETWORK_CONNECT,

    FORM_SALE_FORCE_VALIDATE_MESSAGE,
    APPLICATION_DOMAIN_NOT_REGISTERED,
    APPLICATION_DOMAIN_NOT_BELONG_TO_PARTNER,

    FORM_SIGN_UP_REQUIRED_FIELD,
  };
};

export default useMultiLanguage;
