import React from 'react';
import LabelFormMyPage from 'page/Application/MyPage/component/FormMyPage/LabelFormMyPage';
import useRoleMultiLanguage from 'hook/useRoleMultiLanguage';
import { FormItem } from 'components/Form2';
import {
  Grid1to2,
  Grid2to3,
  GridForm,
  GridFormAccount,
  GridFormAccount1to2,
  GridFormAccount2to3,
} from 'page/Application/MyPagePartnerAdmin/styled';
import { InputStyled } from 'components/Form2/styled';

const AccountType = ({ disabled, data }) => {
  const { ROLE } = useRoleMultiLanguage();

  return (
    <GridFormAccount>
      <GridFormAccount1to2>
        <LabelFormMyPage labelText={'アカウントタイプ'} />
      </GridFormAccount1to2>
      <GridFormAccount2to3>
        <FormItem marginBottom="16px">
          <InputStyled disabled={disabled} value={ROLE[data?.role]} />
        </FormItem>
      </GridFormAccount2to3>
    </GridFormAccount>
  );
};

export default AccountType;
