import { message } from 'antd';
import { useEffect, useState } from 'react';
import useMultiLanguageCompanyList from 'page/Application/ManageCompanyList/CompanyList/hook/useMultiLanguageCompanyList';

const useSearchKeyword = () => {
  const [searchKeyword, setSearchKeyword] = useState('');
  const [emptyText, setEmptyText] = useState('');

  const { COMPANY_LIST_PLACEHOLDER_SEARCH_NODATA, COMPANY_LIST_NO_DATA } =
    useMultiLanguageCompanyList();
  const [isOnline, setIsOnline] = useState(true);

  const handleNetworkStatus = () => {
    if (!isOnline) {
      message.error({
        key: 'error',
        content:
          '問題が発生しました。インターネット接続を確認して、もう一度やり直してください。',
        duration: 2,
      });
      return false;
    }
    return true;
  };

  useEffect(() => {
    window.addEventListener('offline', () => setIsOnline(false));
    window.addEventListener('online', () => setIsOnline(true));
  }, []);

  const onSearch = (value, _e) => {
    if (handleNetworkStatus()) {
      const trimmedValue = value.trim();
      setSearchKeyword(trimmedValue);
      if (trimmedValue && trimmedValue.length > 0) {
        setEmptyText(COMPANY_LIST_PLACEHOLDER_SEARCH_NODATA);
      } else {
        setEmptyText(COMPANY_LIST_NO_DATA);
      }
    }
  };

  return {
    onSearch,
    searchKeyword,
    setSearchKeyword,
    emptyText,
  };
};

export default useSearchKeyword;
