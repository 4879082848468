import React from 'react';
import {
  ItemPartner,
  ItemWrapper,
  TextOverflowHidden,
  TextDate,
  ContentAndDateFile,
  ButtonDownload,
} from 'page/Application/PartnerWebsite/styled';
import { ButtonIcon } from 'components/Button';
import { DownloadOutlined, InboxOutlined } from '@ant-design/icons';

import dayjs from 'dayjs';
import { Empty, Tooltip } from 'antd';

const ListOverViewFilePartner = ({
  formatData,
  handleDownLoad,
  isDisableDownload,
  setIsDisableDownload,
}) => {
  return formatData?.length > 0 ? (
    <ItemWrapper>
      {formatData?.map((item, index) => {
        const typeFile = item?.fileResponse?.name?.split('.').pop();
        return (
          <ItemPartner key={index}>
            <ContentAndDateFile>
              <Tooltip
                title={item.detailTitle + `（${typeFile} ファイル）`}
                placement={'topLeft'}
              >
                <TextOverflowHidden>
                  {item.detailTitle + `（${typeFile} ファイル）`}
                </TextOverflowHidden>
              </Tooltip>
              <TextDate>
                {item.detailDisplayDate
                  ? dayjs(item.detailDisplayDate).format('YYYY年MM月DD日')
                  : ''}
              </TextDate>
            </ContentAndDateFile>
            <ButtonDownload>
              <ButtonIcon
                disabled={isDisableDownload === item?.detailId}
                margin={'0px 20px'}
                icon={<DownloadOutlined />}
                onClick={() => {
                  handleDownLoad(item?.fileResponse?.name, item?.detailId);
                  setIsDisableDownload(item?.detailId);
                }}
              />
            </ButtonDownload>
          </ItemPartner>
        );
      })}
    </ItemWrapper>
  ) : (
    <Empty
      image={<InboxOutlined />}
      imageStyle={{ fontSize: 80 }}
      description={null}
      style={{
        fontSize: '16px',
        fontFamily: 'inter-regular, sans-serif',
        color: '#656464',
        marginTop: 40,
      }}
    >
      データがありません
    </Empty>
  );
};

export default ListOverViewFilePartner;
