import React from 'react';
import { Outlet } from 'react-router-dom';
import CompanyList from 'page/Application/ManageCompanyList/CompanyList';
import EditCompany from 'page/Application/ManageCompanyList/EditCompany';
import RegisterCompany from 'page/Application/ManageCompanyList/RegisterCompany';
import useSetTitlePage from 'hook/useSetTitlePage';

/*
 * include 3 screen
 * company list
 * register company
 * edit company
 */

const ManageCompanyList = () => {
  useSetTitlePage('企業管理');

  return <Outlet />;
};
export default ManageCompanyList;
export { CompanyList, EditCompany, RegisterCompany };
