export const CATEGORY_STATUS_DATA = [
  {
    value: true,
    label: 'Show',
  },
  {
    value: false,
    label: 'Hide',
  },
];
