import { Form, Radio } from 'antd';
import useStatusNotifyMultiLanguage from 'hook/useStatusNotifyMultiLanguage';
import React from 'react';
import { RadioStyled } from 'page/Application/ManageNotificationList/component/Form/FormRadio1/styled';

const FormRadio1 = ({ name, onChange, required }) => {
  const { STATUS_FOR_FORM } = useStatusNotifyMultiLanguage();
  return (
    <Form.Item name={name} required={required}>
      <Radio.Group name="statusRadio" onChange={onChange}>
        <RadioStyled value={STATUS_FOR_FORM[0].value}>
          {STATUS_FOR_FORM[0].label}
        </RadioStyled>
        <RadioStyled value={STATUS_FOR_FORM[1].value}>
          {STATUS_FOR_FORM[1].label}
        </RadioStyled>
        <RadioStyled value={STATUS_FOR_FORM[2].value}>
          {STATUS_FOR_FORM[2].label}
        </RadioStyled>
      </Radio.Group>
    </Form.Item>
  );
};
export default FormRadio1;
