import styled from 'styled-components';

export const Text = styled.div`
  color: VAR(--FORM-LABEL-default);
  width: auto;
  overflow-y: hidden;
  overflow-x: scroll;

  &::-webkit-scrollbar {
    width: 2px;
    height: 6px;
    cursor: grab;
  }

  &::-webkit-scrollbar-track {
    background: transparent;
  }

  &::-webkit-scrollbar-thumb {
    background: #ecececff;
    border-radius: 2px;
  }

  ol {
    list-style: decimal inside;
  }

  ul {
    list-style-position: inside;
  }

  li {
    word-wrap: normal;
    list-style-position: inside;
  }
`;
