import { useIntl } from 'react-intl';

const useChangePasswordLanguage = () => {
  const intl = useIntl();

  const REGISTER_ACCOUNT_CATEGORY_ENTRY_REQUIRED = intl.formatMessage({
    id: 'application.RegisterAccount.category.entry.required',
  });

  const FORM_PASSWORD_ERROR_MESSAGE_VALIDATE_1 = intl.formatMessage({
    id: 'authentication.password.errorMessage.validate1',
  });

  const FORM_PASSWORD_ERROR_MESSAGE_VALIDATE_2 = intl.formatMessage({
    id: 'authentication.password.errorMessage.validate2',
  });

  return {
    REGISTER_ACCOUNT_CATEGORY_ENTRY_REQUIRED,
    FORM_PASSWORD_ERROR_MESSAGE_VALIDATE_1,
    FORM_PASSWORD_ERROR_MESSAGE_VALIDATE_2,
  };
};

export default useChangePasswordLanguage;
