import React, { useMemo } from 'react';
import { menuData } from 'constant/menuData';
import { userInformation } from 'recoil/atom/authentication';
import { useRecoilValue } from 'recoil';
import MenuItem from 'components/Layout/ApplicationLayout/SidebarMenu/Desktop/Menu/MenuItem';

const Menu = () => {
  const { role } = useRecoilValue(userInformation);
  const listMenu = useMemo(() => {
    return menuData[role];
  }, [role]);

  return (
    <div className="menu-wrapper">
      {listMenu.map((itemMenu, index) => {
        return <MenuItem key={index} menu={itemMenu} />;
      })}
    </div>
  );
};

export default Menu;
