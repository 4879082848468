import axiosClient from 'api/config/axiosClient';

const getListDomain = ({ isPartner }) => {
  const url = `/v1/domain?isPartner=${isPartner}`;
  return axiosClient.get(url);
};

const registerAccountPartner = ({
  email,
  password,
  firstName,
  lastName,
  position,
  department,
  dutyId,
  role,
  approveStatus,
  partnerInCharge,
  phoneNumber,
  listAreaId,
  partnerId,
}) => {
  const url = 'v1/account';
  return axiosClient.post(url, {
    email,
    password,
    firstName,
    lastName,
    position,
    department,
    dutyId,
    role,
    approveStatus,
    partnerInCharge,
    phoneNumber,
    listAreaId,
    partnerId,
  });
};

const getDetailAccountPartner = ({ userId }) => {
  const url = `/v1/account/${userId}`;
  return axiosClient.get(url);
};

const updateAccountPartner = ({ userId, payload }) => {
  const url = `/v1/account/${userId}`;
  return axiosClient.put(url, payload);
};

const exportAccountPartnerCSV = (payload) => {
  const url = '/v1/account/csv';
  return axiosClient.post(url, payload);
};

const deleteAccountPartner = (payload) => {
  const url = '/v1/account/delete';
  return axiosClient.delete(url, { data: payload });
};

export {
  getListDomain,
  registerAccountPartner,
  getDetailAccountPartner,
  updateAccountPartner,
  exportAccountPartnerCSV,
  deleteAccountPartner,
};
