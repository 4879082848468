import styled from 'styled-components';
import { Select } from 'antd';

export const SelectStyled = styled(Select)`
  color: green;
  &.ant-select-dropdown {
    width: 200px;

    overflow: hidden;
  }
`;
