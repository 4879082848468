import React, { Fragment, Suspense, useEffect, useMemo, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { createPartner } from 'api/partner/partner.api';
import { useRecoilState } from 'recoil';
import { FormTitle } from 'page/Application/ManageCompanyList/component/Form';
import useSetTitlePage from 'hook/useSetTitlePage';
import useMultiLanguageRegisterCompany from 'page/Application/ManageCompanyList/RegisterCompany/hook/useMultiLanguageRegisterCompany';
import FormPartner from 'page/Application/ManageCompanyList/component/FormPartner';
import useModalConfirm from 'hook/useModalConfirm';
import useErrorCodeManageCompany from 'page/Application/ManageCompanyList/hook/useErrorCodeManageCompany';
import useCustomMutateRenderMessage from 'hook/useQuery/useCustomMutateRenderMessage';
import LayoutDefault from 'components/Layout/LayoutDefault';
import Breadcrumb from 'components/Layout/ApplicationLayout/Breadcrumb';
import { MANAGE_COMPANY_LIST_PATH } from 'constant/appPath';
import { Form, Spin } from 'antd';
import { Container, FormWrapper } from 'components/Form/Template/styled';
import { listProduct } from 'recoil/atom/selectProduct';
import useErrorNetworkMessage from 'hook/useErrorNetworkMessage';

/*
 * screen register partner
 */

const checkSpace = /\s/g;
const RegisterCompany = () => {
  const navigate = useNavigate();
  const [listSelected, setListSelectedProduct] = useRecoilState(listProduct);
  const [isDisable, setIsDisable] = useState(false);
  const [isAvailable, setIsAvailable] = useState(true);
  const [isDomainAvailable, setIsDomainAvailable] = useState(true);
  const { isOnline, errorNetWork, contextErrorNetWork } =
    useErrorNetworkMessage();
  const { contextHolder, handleConfirmAction } = useModalConfirm();
  const { ERROR_CODE_MANAGE_COMPANY } = useErrorCodeManageCompany();
  const [form] = Form.useForm();

  const {
    REGISTER_COMPANY_TITLE,
    REGISTER_COMPANY_MESSAGE_SUCCESS_CREATE_PARTNER,
    REGISTER_COMPANY_MESSAGE_CANCEL,
    REGISTER_COMPANY_OK,
    REGISTER_COMPANY_CANCEL,
  } = useMultiLanguageRegisterCompany();

  const listItem = useMemo(() => {
    return [
      {
        title: <Link to={'/manage-company-list'}>企業管理</Link>,
      },
      {
        title: '新規登録',
      },
    ];
  }, []);

  const { useMutationAPIHandleErrorCode, errorMessage } =
    useCustomMutateRenderMessage({
      errorCode: ERROR_CODE_MANAGE_COMPANY,
    });

  useSetTitlePage(REGISTER_COMPANY_TITLE);

  // call API create partner
  const {
    mutate: createPartnerMutate,
    isError,
    isLoading,
    isSuccess,
  } = useMutationAPIHandleErrorCode({
    api: createPartner,
    refetchKey: 'companyList',
    messageSuccess: REGISTER_COMPANY_MESSAGE_SUCCESS_CREATE_PARTNER,
    path: `/${MANAGE_COMPANY_LIST_PATH}`,
  });

  // format data from list to return isAutoApprove: true | false
  const checkDomainValid = (domainList) => {
    if (domainList[0]?.domainName !== '') {
      let dataValid = domainList.filter(
        (item) => item?.domainName !== undefined && item?.domainName !== '',
      );
      return dataValid?.map((item, key) => ({
        ...item,
        domainId: key,
        isAutoApprove:
          item.isAutoApprove === '' || item.isAutoApprove === undefined
            ? false
            : item.isAutoApprove,
      }));
    } else return [];
  };

  const checkEmailValid = (emailList) => {
    if (emailList[0] !== '') {
      return emailList.filter((item) => item !== undefined && item !== '');
    } else return [];
  };

  // function submit form, create partner
  const onFinish = (value) => {
    if (isOnline) {
      const domainList = checkDomainValid(value.domainList ?? []);
      const checkEmailList = checkEmailValid(value.partnerEmail ?? []);
      const domainListReplaceSpace = domainList.map((item) => {
        return {
          ...item,
          domainName: item.domainName.replace(checkSpace, ''),
        };
      });
      const emailListReplaceSpace = checkEmailList.map((item) => {
        return item.replace(checkSpace, '');
      });

      const payload = {
        saleForceId: value.saleForceId,
        solitonEmail: value?.solitonEmail?.replace(checkSpace, ''),
        partnerName: value.partnerName,
        domainList: domainListReplaceSpace,
        partnerEmail: emailListReplaceSpace,
        productList: listSelected.listRecordProduct.map((item) => ({
          productId: item.id,
          type: item.type,
          productName: item.productName,
        })),
      };
      const cleanPayload = Object.entries(payload)
        .filter(([value]) => value !== undefined)
        .reduce((obj, [key, value]) => {
          obj[key] = value;
          return obj;
        }, {});
      createPartnerMutate(cleanPayload);
    } else {
      errorNetWork();
    }
  };

  // open modal confirm, navigate to screen company list
  const handleCancel = () => {
    if (isOnline) {
      handleConfirmAction({
        confirmFn: navigate,
        params: `/${MANAGE_COMPANY_LIST_PATH}`,
        title: REGISTER_COMPANY_MESSAGE_CANCEL,
        okText: REGISTER_COMPANY_OK,
        cancelText: REGISTER_COMPANY_CANCEL,
        setIsDisable: setIsDisable,
      });
      setIsDisable(true);
    } else {
      errorNetWork();
    }
  };

  // clear data select in modal product when user come this screen first time
  useEffect(() => {
    setListSelectedProduct({
      display: false,
      listIdProduct: [],
      listRecordProduct: [],
    });
  }, [setListSelectedProduct]);

  return (
    <>
      {contextErrorNetWork}
      <Breadcrumb listItem={listItem} titlePage="企業管理" />
      <Suspense fallback={isLoading || isSuccess}>
        <LayoutDefault>
          {contextHolder}
          <FormTitle title={REGISTER_COMPANY_TITLE} type={'title'} />
          <FormWrapper>
            <Container>
              <Spin spinning={isLoading || isSuccess}>
                <Form
                  form={form}
                  layout="horizontal"
                  size="large"
                  onFinish={onFinish}
                >
                  <FormPartner
                    formPartnerName={'partnerName'}
                    errorMessage={errorMessage}
                    isError={isError}
                    form={form}
                    onCancel={handleCancel}
                    isDisable={isDisable}
                    setIsDisable={setIsDisable}
                    isEdit={false}
                    setIsAvailable={setIsAvailable}
                    isAvailable={isAvailable}
                    setIsDomainAvailable={setIsDomainAvailable}
                    isDomainAvailable={isDomainAvailable}
                  />
                </Form>
              </Spin>
            </Container>
          </FormWrapper>
        </LayoutDefault>
      </Suspense>
    </>
  );
};

export default RegisterCompany;
