import React from 'react';
import Icon from 'components/Icon';
import LabelForm from 'components/Form/LabelForm';
import FormMessage from 'components/Form/FormMessage';
import ValidateEmailLanguage from 'page/Authentication/component/Input/language/validateEmailLanguage';
import { MailOutlined } from '@ant-design/icons';
import {
  FormItemStyled,
  InputStyled,
} from 'page/Authentication/component/Input/styled';
import { MAX_LENGTH_EMAIL } from 'constant/inputMaxlength';
import { CHECK_EMAIL } from 'constant/regex';

const ValidateEmail = ({ maxLength, onChange, name }) => {
  const {
    FORM_EMAIL_ADDRESS_LABEL,
    FORM_EMAIL_ADDRESS_PLACEHOLDER,
    FORM_EMAIL_ADDRESS_ERROR_MESSAGE_EMPTY,
    FORM_EMAIL_ADDRESS_ERROR_MESSAGE_VALIDATE,
  } = ValidateEmailLanguage();

  return (
    <>
      <FormItemStyled
        name={name}
        label={<LabelForm text={FORM_EMAIL_ADDRESS_LABEL} />}
        rules={[
          {
            required: true,
            message: (
              <FormMessage
                text1={FORM_EMAIL_ADDRESS_ERROR_MESSAGE_EMPTY}
                marginBottom={'12px'}
              />
            ),
          },
          {
            pattern: CHECK_EMAIL,
            message: (
              <FormMessage
                text1={FORM_EMAIL_ADDRESS_ERROR_MESSAGE_VALIDATE}
                marginBottom={'12px'}
              />
            ),
          },
        ]}
      >
        <InputStyled
          className="input-responsive"
          prefix={
            <Icon>
              <MailOutlined className="icon" />
            </Icon>
          }
          placeholder={FORM_EMAIL_ADDRESS_PLACEHOLDER}
          maxLength={maxLength ? maxLength : MAX_LENGTH_EMAIL}
          onChange={onChange}
        />
      </FormItemStyled>
    </>
  );
};

export default ValidateEmail;
