import axiosClient from 'api/config/axiosClient';

const getDetailPartnerUser = () => {
  const url = `v1/account/mypage`;
  return axiosClient.get(url);
};

const updatePartnerUser = ({ payload }) => {
  const url = `v1/account/mypage`;
  return axiosClient.put(url, payload);
};

export { getDetailPartnerUser, updatePartnerUser };
