import { Checkbox } from 'antd';
import styled from 'styled-components';

export const CheckboxStyled = styled(Checkbox)`
  color: ${(props) => (props?.color ? props.color : 'var(--CHECKBOX-default)')};
  font-family: 'Roboto', sans-serif;
  span {
    font-size: 14px;
  }
`;
