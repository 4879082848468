import React, { useEffect, useMemo, useState } from 'react';
import {
  BlockTitle,
  BlockWrapper,
  ContentText,
  DateTable,
  EditMode,
  Grid1,
  GroupButton,
  TitleTable,
} from 'page/Application/PartnerWebsite/styled';
import { ButtonIcon, ButtonOutline } from 'components/Button';
import { ModalNotification } from 'page/Application/PartnerWebsite/component';
import { DeleteOutlined, EditOutlined } from '@ant-design/icons';
import { Form, Tooltip } from 'antd';
import useModalConfirm from 'hook/useModalConfirm';
import useMultiLanguagePagePartner from 'page/Application/PartnerWebsite/useMultiLanguagePagePartner';
import ListOverViewNotification from 'page/Application/PartnerWebsite/component/ListOverViewNotification';
import ListEditDragTable from 'page/Application/PartnerWebsite/component/ListEditDragTable';
import dayjs from 'dayjs';

const ListNotification = ({
  dataRaw,
  isEdit,
  partnerId,
  gridColumnForEdit,
  marginDateForOverview,
  displayForOverview,
  setDataNotification,
  isLoading,
  isOnline,
  errorNetWork,
}) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isEditPopup, setIsEditPopup] = useState(false);
  const [dataSource, setDataSource] = useState([]);
  const [dataEdit, setDataEdit] = useState({});
  const [form] = Form.useForm();
  const { contextHolder, handleConfirmAction } = useModalConfirm();
  const [isDisable, setIsDisable] = useState(false); // sau thay bang isLoading tu mutate
  const { PAGE_PARTNER_TITLE_NOTICE } = useMultiLanguagePagePartner();

  const formatData = useMemo(() => {
    return (
      dataRaw &&
      dataRaw?.map((item, index) => ({
        ...item,
        key: index + 1,
      }))
    );
  }, [dataRaw]);

  const handleOpenModal = () => {
    if (isOnline) {
      setDataEdit('');
      setIsModalOpen(true);
      setIsEditPopup(false);
      form.resetFields();
    } else {
      errorNetWork();
    }
  };

  const onCancel = () => {
    if (isOnline) {
      setDataEdit('');
      setIsModalOpen(false);
    } else {
      errorNetWork();
    }
  };

  const handleOpenEditModal = (item) => {
    if (isOnline) {
      setIsModalOpen(true);
      setIsEditPopup(true);
      setDataEdit(item);
    } else {
      errorNetWork();
    }
  };
  const handleDeleteNotify = (item) => {
    if (isOnline) {
      handleConfirmAction({
        okText: '削除',
        cancelText: 'キャンセル',
        title: 'このお知らせを削除してもよろしいでしょうか？',
        params: item.key,
        setIsDisable: setIsDisable,
        confirmFn: () => {
          const newDataRaw = dataSource?.filter(
            (value) => value.key !== item.key,
          );
          const newFormatData = newDataRaw?.map((item, index) => ({
            ...item,
            key: index + 1,
          }));
          setDataSource(newFormatData);
        },
      });
    } else {
      errorNetWork();
    }
  };

  const columns = [
    {
      key: 'sort',
      width: 40,
    },
    {
      dataIndex: 'noticeTitle',
      ellipsis: true,
      width: '65%',
      render: (title) => {
        return (
          <TitleTable>
            <Tooltip placement="topLeft" title={title}>
              <ContentText>{title}</ContentText>
            </Tooltip>
          </TitleTable>
        );
      },
    },
    {
      dataIndex: 'noticeDisplayDate',
      align: 'center',
      width: '20%',
      ellipsis: true,
      render: (date) => {
        return (
          <DateTable>
            <Tooltip
              placement="topLeft"
              title={dayjs(date).format('YYYY年MM月DD日')}
            >
              {date ? dayjs(date).format('YYYY年MM月DD日') : ''}
            </Tooltip>
          </DateTable>
        );
      },
    },
    {
      dataIndex: 'key',
      width: 80,
      render: (userId, item) => (
        <GroupButton>
          <ButtonIcon
            icon={<EditOutlined />}
            onClick={() => handleOpenEditModal(item)}
          />
          <ButtonIcon
            icon={<DeleteOutlined />}
            disabled={isDisable}
            onClick={() => {
              setIsDisable(true);
              handleDeleteNotify(item);
              setTimeout(() => setIsDisable(false), 1000);
            }}
          />
        </GroupButton>
      ),
    },
  ];

  // Not merged this useEffect!!!
  // Set, change data on drag table
  useEffect(() => {
    if (dataRaw && dataRaw.length > 0 && isLoading === false) {
      setDataSource(formatData);
    } else {
      setDataSource([]);
    }
  }, [isLoading, formatData, dataRaw]);

  useEffect(() => {
    if (dataSource?.length > 0 && setDataNotification) {
      setDataNotification(dataSource);
    } else if (dataSource?.length === 0 && setDataNotification) {
      setDataNotification([]);
    }
    if (dataEdit) {
      form.setFieldsValue({
        noticeTitle: dataEdit?.noticeTitle,
        noticeDisplayDate: dataEdit?.noticeDisplayDate
          ? dayjs(dataEdit?.noticeDisplayDate)
          : '',
        noticeContent: dataEdit?.noticeContent,
      });
    }
  }, [dataEdit, dataSource, form, setDataNotification]);

  return (
    <>
      {contextHolder}
      <ModalNotification
        totalData={dataSource?.length}
        dataSource={dataSource}
        setDataSource={setDataSource}
        isModalOpen={isModalOpen}
        onCancel={onCancel}
        isEditPopup={isEditPopup}
        dataEdit={dataEdit}
        setIsModalOpen={setIsModalOpen}
        form={form}
      />
      <Grid1 style={{ gridColumn: gridColumnForEdit }}>
        <BlockWrapper>
          <BlockTitle>{PAGE_PARTNER_TITLE_NOTICE}</BlockTitle>
          {!isEdit ? (
            <ListOverViewNotification
              formatData={dataSource}
              partnerId={partnerId}
              displayForOverview={displayForOverview}
              marginDateForOverview={marginDateForOverview}
              isOnline={isOnline}
              errorNetWork={errorNetWork}
            />
          ) : (
            <ListEditDragTable
              dataSource={dataSource}
              setDataSource={setDataSource}
              columns={columns}
            />
          )}
        </BlockWrapper>
        {isEdit && (
          <EditMode>
            <ButtonOutline
              text={'追加'}
              onClick={handleOpenModal}
              width={100}
            />
          </EditMode>
        )}
      </Grid1>
    </>
  );
};

export default ListNotification;
