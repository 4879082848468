import React, { useEffect, useMemo, useState } from 'react';
import {
  BlockTitle,
  BlockWrapper,
  ContentText,
  DateTable,
  EditMode,
  Grid2,
  GroupButton,
  TitleTable,
} from 'page/Application/PartnerWebsite/styled';
import { DeleteOutlined, EditOutlined } from '@ant-design/icons';
import { ButtonIcon, ButtonOutline } from 'components/Button';
import useModalConfirm from 'hook/useModalConfirm';
import ModalFile from 'page/Application/PartnerWebsite/component/ModalFile';
import { Form, Tooltip } from 'antd';
import useMultiLanguagePagePartner from 'page/Application/PartnerWebsite/useMultiLanguagePagePartner';
import ListOverViewFilePartner from 'page/Application/PartnerWebsite/component/ListOverViewFilePartner';
import ListEditDragTable from 'page/Application/PartnerWebsite/component/ListEditDragTable';
import dayjs from 'dayjs';
import useCustomQuery from 'hook/useQuery/useCustomQuery';
import { downloadContentFile } from 'api/storage/storage.api';
import { useRecoilState } from 'recoil';
import { messageFeedback } from 'recoil/atom/authentication';
import useMultiLanguageRegisterAccount from 'page/Application/ManageAccountSoliton/RegisterAccountSoliton/hook/useMultiLanguageRegisterAccount';

const ListFilePartner = ({
  gridColumnForEdit,
  isEdit,
  setDataFilePartner,
  dataRaw,
  isLoading,
  isOnline,
  errorNetWork,
}) => {
  const [form] = Form.useForm();
  const [dataEdit, setDataEdit] = useState('');
  const [isDisable, setIsDisable] = useState(false); // sau thay bang isLoading tu mutate
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isEditPopup, setIsEditPopup] = useState(false);
  const [detailId, setDetailId] = useState(null);
  const [, setShowMessageFeedback] = useRecoilState(messageFeedback);
  const [isDisableDownload, setIsDisableDownload] = useState(null);
  const { PAGE_PARTNER_TITLE_DEDICATED_FILE_LIST } =
    useMultiLanguagePagePartner();
  const { useQueryAPI } = useCustomQuery();
  const { handleConfirmAction, contextHolder } = useModalConfirm();
  const { REGISTER_ACCOUNT_REGISTER_EXPORT_FILE_MESSAGE_SUCCESS } =
    useMultiLanguageRegisterAccount();
  const [dataSource, setDataSource] = useState([]);

  const formatData = useMemo(() => {
    return (
      dataRaw &&
      dataRaw.map((item, index) => ({
        ...item,
        key: index + 1,
        fileResponse: {
          fileId: item?.fileResponse?.fileId,
          fileHash: item?.fileResponse?.fileHash,
          name: item?.fileResponse?.fileName,
          type: item?.fileResponse?.fileType,
          uid: item?.fileResponse?.fileUuid,
        },
      }))
    );
  }, [dataRaw]);

  const { refetch, isSuccess, isError, isFetching } = useQueryAPI({
    api: downloadContentFile,
    params: {
      detailId: detailId,
    },
    options: {
      refetchOnWindowFocus: false,
    },
    enabled: !!detailId,
  });

  const handleOpenModal = () => {
    if (isOnline) {
      setDataEdit('');
      setIsModalOpen(true);
      setIsEditPopup(false);
      form.resetFields();
    } else {
      errorNetWork();
    }
  };

  const handleOpenEditModal = (item) => {
    if (isOnline) {
      setIsModalOpen(true);
      setIsEditPopup(true);
      setDataEdit(item);
    } else {
      errorNetWork();
    }
  };

  const onCancel = () => {
    if (isOnline) {
      setDataEdit('');
      setIsModalOpen(false);
    } else {
      errorNetWork();
    }
  };

  const handleDownLoad = async (fileName, detailId) => {
    if (isOnline) {
      await setDetailId(detailId);
      await refetch().then(async (value) => {
        await window.open(value?.data, '_self');
        setShowMessageFeedback({
          display: true,
          type: 'success',
          content: REGISTER_ACCOUNT_REGISTER_EXPORT_FILE_MESSAGE_SUCCESS,
        });
      });
    } else {
      errorNetWork();
    }
  };

  const handleDeleteFile = (item) => {
    if (isOnline) {
      handleConfirmAction({
        okText: '削除',
        cancelText: 'キャンセル',
        title: 'このファイルを削除してもよろしいでしょうか？',
        params: item.key,
        setIsDisable: setIsDisable,
        confirmFn: () => {
          const newDataRaw = dataSource?.filter(
            (value) => value.key !== item.key,
          );
          const newFormatData = newDataRaw.map((item, index) => ({
            ...item,
            key: index + 1,
          }));
          setDataSource(newFormatData);
        },
      });
    } else {
      errorNetWork();
    }
  };

  const columns = [
    {
      key: 'sort',
      width: 40,
    },
    {
      dataIndex: 'detailTitle',
      ellipsis: true,
      width: '65%',
      render: (title, value) => {
        const typeFile = value.fileResponse?.name?.split('.').pop();
        return (
          <TitleTable>
            <Tooltip
              placement="topLeft"
              title={title + `（${typeFile} ファイル）`}
            >
              <ContentText>{title + `（${typeFile} ファイル）`}</ContentText>
            </Tooltip>
          </TitleTable>
        );
      },
    },
    {
      dataIndex: 'detailDisplayDate',
      align: 'center',
      width: '20%',
      ellipsis: true,
      render: (date) => {
        return (
          <DateTable>
            <Tooltip
              placement="topLeft"
              title={dayjs(date).format('YYYY年MM月DD日')}
            >
              {date ? dayjs(date).format('YYYY年MM月DD日') : ''}
            </Tooltip>
          </DateTable>
        );
      },
    },
    {
      dataIndex: 'key',
      width: 80,
      render: (userId, item) => (
        <GroupButton>
          <ButtonIcon
            icon={<EditOutlined />}
            onClick={() => handleOpenEditModal(item)}
          />
          <ButtonIcon
            icon={<DeleteOutlined />}
            disabled={isDisable}
            onClick={() => {
              setIsDisable(true);
              handleDeleteFile(item);
              setTimeout(() => setIsDisable(false), 2000);
            }}
          />
        </GroupButton>
      ),
    },
  ];

  // Not merged this useEffect!!!
  // Set, change data on drag table
  useEffect(() => {
    if (dataRaw && dataRaw?.length > 0 && isLoading === false) {
      setDataSource(formatData);
    } else {
      setDataSource([]);
    }
  }, [isLoading, formatData, dataRaw]);

  useEffect(() => {
    if (isSuccess || isError) {
      setTimeout(() => setIsDisableDownload(null), 2000);
    }
    if (dataSource?.length > 0 && setDataFilePartner) {
      setDataFilePartner(dataSource);
    } else if (dataSource?.length === 0 && setDataFilePartner) {
      setDataFilePartner([]);
    }
    const dataFileResponse = dataEdit?.fileResponse;
    if (dataEdit) {
      form.setFieldsValue({
        detailTitle: dataEdit?.detailTitle,
        detailDisplayDate: dataEdit?.detailDisplayDate
          ? dayjs(dataEdit?.detailDisplayDate)
          : '',
        fileResponse: [
          {
            name: dataFileResponse?.name,
            type: dataFileResponse?.type,
            uid: dataFileResponse?.uid,
            status: 'done',
          },
        ],
      });
    }
  }, [
    dataEdit,
    dataSource,
    isError,
    form,
    isSuccess,
    setDataFilePartner,
    setShowMessageFeedback,
    isFetching,
    formatData,
  ]);

  return (
    <>
      {contextHolder}
      <ModalFile
        totalData={dataSource?.length}
        isModalOpen={isModalOpen}
        dataSource={dataSource}
        setDataSource={setDataSource}
        onCancel={onCancel}
        dataEdit={dataEdit}
        isEditPopup={isEditPopup}
        setIsModalOpen={setIsModalOpen}
        form={form}
      />
      <Grid2 style={{ gridColumn: gridColumnForEdit }}>
        <BlockWrapper>
          <BlockTitle>{PAGE_PARTNER_TITLE_DEDICATED_FILE_LIST}</BlockTitle>
          {!isEdit ? (
            <ListOverViewFilePartner
              formatData={formatData}
              isDisableDownload={isDisableDownload}
              setIsDisableDownload={setIsDisableDownload}
              handleDownLoad={handleDownLoad}
            />
          ) : (
            <ListEditDragTable
              dataSource={dataSource}
              setDataSource={setDataSource}
              columns={columns}
            />
          )}
        </BlockWrapper>
        {isEdit && (
          <EditMode>
            <ButtonOutline
              text={'追加'}
              onClick={handleOpenModal}
              width={100}
            />
          </EditMode>
        )}
      </Grid2>
    </>
  );
};

export default ListFilePartner;
