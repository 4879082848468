import React, { useEffect, useState } from 'react';
import {
  deleteNotification,
  getDetailNotification,
  updateNotification,
} from 'api/notification/notification.api';
import { useRecoilState, useRecoilValue, useResetRecoilState } from 'recoil';
import { listProduct } from 'recoil/atom/selectProduct';
import { useNavigate, useParams } from 'react-router-dom';
import { ERROR_MESSAGES_RESPONSE } from 'constant/topPageConstants';
import { MANAGE_NOTIFICATION_PATH } from 'constant/appPath';
import {
  DATE_FORMAT_LIST,
  NOTIFICATION_DESTINATION_TYPE_DATA,
  NOTIFICATION_STATUS_DATA,
  TIME_ZONE,
} from 'constant/notificationConstants';
import dayjs from 'dayjs';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';
import useCustomMutateRenderMessage from 'hook/useQuery/useCustomMutateRenderMessage';
import useCustomQuery from 'hook/useQuery/useCustomQuery';
import { FormNotification } from 'page/Application/ManageNotificationList/component/Form/index';
import { useNotification } from 'page/Application/ManageNotificationList/component/NotifcationContext';
import useMultiLanguageEditNotification
  from 'page/Application/ManageNotificationList/EditNotification/hook/useMultiLanguageEditNotification';
import useErrorCodeManageNotification
  from 'page/Application/ManageNotificationList/hook/useErrorCodeManageNotification';
import { network } from 'recoil/atom/network';
import { message } from 'antd';

dayjs.extend(timezone);
dayjs.extend(utc);

const EditNotification = () => {
  const navigate = useNavigate();
  const { useQueryAPI } = useCustomQuery();
  const { notifyId } = useParams();
  const { currentNotification, setCurrentNotification } = useNotification();
  const { ERROR_CODE_MANAGE_NOTIFICATION } = useErrorCodeManageNotification();
  const { errorMessage, useMutationAPIHandleErrorCode } =
    useCustomMutateRenderMessage({
      errorCode: ERROR_CODE_MANAGE_NOTIFICATION,
    });
  const {
    EDIT_NOTIFICATION_TITLE,
    EDIT_NOTIFICATION_MESSAGE_SUCCESS,
    EDIT_NOTIFICATION_DELETE_MESSAGE_SUCCESS,
  } = useMultiLanguageEditNotification();
  const [messageApi, contextHolder] = message.useMessage();

  const resetProductList = useResetRecoilState(listProduct);
  const [listSelectedProduct, setListSelectedProduct] =
    useRecoilState(listProduct);
  const isOnline = useRecoilValue(network);

  const [isDisable, setIsDisable] = useState(false);
  const [idFile, setIdFile] = useState(null);
  const [content, setContent] = useState(null);
  const [destinationType, setDestinationType] = useState(
    NOTIFICATION_DESTINATION_TYPE_DATA[0].value,
  );
  const [status, setStatus] = useState(NOTIFICATION_STATUS_DATA[0].value);
  const [fileObject, setFileObject] = useState(null);
  const [plainText, setPlainText] = useState(null);

  const {
    data: notificationDetail = [],
    isLoading,
    error,
  } = useQueryAPI({
    api: getDetailNotification,
    key: 'notificationDetail',
    params: {
      notifyId: notifyId,
    },
    options: {
      refetchOnWindowFocus: false, // cancel fetch data when out focus
    },
  });

  const {
    mutate: deleteNotificationMutate,
    isError,
    isLoading: isLoadingDelete,
    isSuccess: isSuccessDelete,
  } = useMutationAPIHandleErrorCode({
    api: deleteNotification,
    refetchKey: 'listNotification',
    path: `/${MANAGE_NOTIFICATION_PATH}`,
    messageSuccess: `${EDIT_NOTIFICATION_DELETE_MESSAGE_SUCCESS}`,
  });

  const handleDeleteNotification = () => {
    deleteNotificationMutate({
      notifyId: notifyId,
    });
  };

  const {
    mutate: updateNotificationMutate,
    isLoading: isLoadingUpdate,
    isSuccess,
  } = useMutationAPIHandleErrorCode({
    api: updateNotification,
    refetchKey: 'notificationDetail',
    path: `/${MANAGE_NOTIFICATION_PATH}`,
    messageSuccess: EDIT_NOTIFICATION_MESSAGE_SUCCESS,
  });

  useEffect(() => {
    if (ERROR_MESSAGES_RESPONSE[error?.response?.data?.message]) {
      navigate('/notification-not-found');
    }
    if (
      notificationDetail &&
      notificationDetail?.data &&
      !notificationDetail?.data?.ec
    ) {
      const data = notificationDetail?.data;
      const lstProductResponse = data?.lstProductResponses;
      if (lstProductResponse?.length > 0) {
        const listProduct = lstProductResponse.map((item) => ({
          ...item,
          key: item?.productId,
        }));
        const listId = listProduct.map((item) => item?.productId);
        setListSelectedProduct(
          currentNotification?.listSelectedProduct ?? {
            listIdProduct: listId,
            listRecordProduct: listProduct,
          },
        );
      } else {
        resetProductList();
      }

      setStatus(currentNotification?.status ?? data?.status);
      setDestinationType(
        currentNotification?.destinationType ?? data?.destinationType,
      );
      setContent(currentNotification?.content ?? data?.content);
      setPlainText(currentNotification?.plainText ?? data?.notifySearchContent);

      const fileStorage = data?.fileStorageResponse;
      setIdFile(fileStorage?.id);
    }
  }, [
    currentNotification,
    error?.response?.data?.message,
    navigate,
    notificationDetail,
    notificationDetail?.data,
    resetProductList,
    setListSelectedProduct,
  ]);

  const handleShowErrorNetwork = () => {
    messageApi.open({
      type: 'error',
      content: '問題が発生しました。インターネット接続を確認して、もう一度やり直してください。',
    }).then();
  };

  const handleUpdate = (value) => {

    if (isOnline) {
      const dateFrom =
        value?.publishedFromToDate && value?.publishedFromToDate[0]
          ? new Date(value?.publishedFromToDate[0]).setSeconds(0)
          : null;
      const dateTo =
        value?.publishedFromToDate && value?.publishedFromToDate[1]
          ? new Date(value?.publishedFromToDate[1]).setSeconds(0)
          : null;
      const payload = {
        ...value,
        title: value.title.trim(),
        content: content === '<p>&nbsp;</p>' ? '' : content,
        notifySearchContent: plainText,
        displayedDate: dayjs(value.displayedDate).format(DATE_FORMAT_LIST[3]),
        publishedFrom:
          value.status === 'HIDE'
            ? null
            : dayjs.tz(
              dateTo && dateFrom > dateTo ? dateTo : dateFrom,
              TIME_ZONE,
            ),
        publishedTo:
          value.status === 'HIDE'
            ? null
            : dateTo &&
            dayjs.tz(dateFrom > dateTo ? dateFrom : dateTo, TIME_ZONE),
        lstProductId: listSelectedProduct.listIdProduct,
        pdf: idFile,
        fileStorageRequest: fileObject && {
          fileName: fileObject?.fileName,
          keyFileName: fileObject?.keyFileName,
          contentType: fileObject?.contentType,
          size: fileObject?.size,
        },
      };

      // call API update notification
      updateNotificationMutate({
        notifyId: notifyId,
        payload: payload,
      });
    } else {
      handleShowErrorNetwork()
    }
  };

  return (
    <>
      {contextHolder}
      <FormNotification
        titleForm={EDIT_NOTIFICATION_TITLE}
        formDetailData={notificationDetail.data}
        isEdit={true}
        isLoading={isLoading || isLoadingUpdate || isLoadingDelete}
        onFinish={handleUpdate}
        isDisable={isDisable}
        setIsDisable={setIsDisable}
        handleDeleteNotification={handleDeleteNotification}
        idFile={idFile}
        setIdFile={setIdFile}
        errorMessage={errorMessage}
        isError={isError}
        isSuccess={isSuccess || isSuccessDelete}
        content={content}
        setContent={setContent}
        currentNotification={currentNotification}
        setCurrentNotification={setCurrentNotification}
        setDestinationType={setDestinationType}
        setStatus={setStatus}
        status={status}
        destinationType={destinationType}
        notifyId={notifyId}
        fileObject={fileObject}
        setFileObject={setFileObject}
        setPlainText={setPlainText}
        plainText={plainText}
      />
    </>

  );
};

export default EditNotification;
