import React, { useEffect } from 'react';
import { getListProduct } from 'api/partner/partner.api';
import TableListProduct from 'page/Application/ManageCompanyList/component/Form/FormAddProduct/TableListProduct';
import useCustomQuery from 'hook/useQuery/useCustomQuery';
import { SearchStyled } from 'page/Application/ManageCompanyList/component/Form/styled';
import useMultiLanguageRegisterCompany from 'page/Application/ManageCompanyList/RegisterCompany/hook/useMultiLanguageRegisterCompany';
import AntdModal2 from 'components/AntdModal2';
import useSearchKeyword from 'hook/useSearchKeyword';

/**
 * isModalOpen: trigger open modal.
 * handleCancel: close modal.
 * handleOk: trigger function with button ok in modal.
 */

const ModalListProduct = ({ isModalOpen, handleCancel, handleOk }) => {
  const { searchKeyword, onSearch, emptyText, setSearchKeyword } =
    useSearchKeyword();
  const { useQueryAPI } = useCustomQuery();
  const { MODAL_PRODUCTS_TITLE, MODAL_PRODUCTS_SEARCH } =
    useMultiLanguageRegisterCompany();

  const { data: productList, isLoading } = useQueryAPI({
    api: getListProduct,
    params: {
      keyword: searchKeyword,
      isNotify: false,
    },
    key: 'productList',
    enabled: isModalOpen, // call api when modal add product open
  });

  useEffect(() => {
    if (isModalOpen === false) {
      setSearchKeyword('');
    }
  }, [isModalOpen, setSearchKeyword]);



  return (
    <AntdModal2
      title={MODAL_PRODUCTS_TITLE}
      handleOk={handleOk}
      handleCancel={handleCancel}
      isModalOpen={isModalOpen}
      //edit size of modal box to match with design
      width={596}
      total={productList?.data?.count}
      content={
        <>
          <SearchStyled
            placeholder={MODAL_PRODUCTS_SEARCH}
            onSearch={onSearch}
            loading={isLoading}
            maxLength={256}
          />
          <div style={{ marginTop: 11 }}>
            <TableListProduct
              emptyText={emptyText}
              dataRaw={productList ?? []}
              isLoading={isLoading}
            />
          </div>
        </>
      }
    />
  );
};

export default ModalListProduct;
