import styled from 'styled-components';
import { Breadcrumb } from 'antd';
import { ElementVerticalCenter } from 'style/styleComponent/styled';
export const BreadcrumbWrapper = styled(ElementVerticalCenter)`
  padding: 20px 0;
  justify-content: space-between;
`;
export const BreadcrumbTitle = styled.h1`
  max-width: 1440px;
  line-height: normal;
  @media all and (min-width: 0px) and (max-width: 320px) {
    width: 170px;
  }
  @media all and (min-width: 321px) and (max-width: 430px) {
    max-width: 170px;
  }
  @media all and (min-width: 431px) and (max-width: 768px) {
    max-width: 280px;
  }
  @media all and (min-width: 769px) and (max-width: 1024px) {
    max-width: 600px;
  }
  @media all and (min-width: 1025px) and (max-width: 1200px) {
    max-width: 550px;
  }
  @media all and (min-width: 1201px) and (max-width: 1440px) {
    max-width: 700px;
  }

  // Tach ra cho 2 truong hop hien thi o view va o edit mode
  @media all and (min-width: 1441px) and (max-width: 1720px) {
    max-width: 900px;
    &.maxWidthTitleEdit {
      max-width: 700px;
    }
  }
  // Tach ra cho 2 truong hop hien thi o view va o edit mode
  @media all and (min-width: 1721px) and (max-width: 1921px) {
    max-width: 1320px;
    &.maxWidthTitleEdit {
      max-width: 1300px;
    }
  }
`;
export const BreadcrumbCustom = styled(Breadcrumb)`
  font-size: 14px;
  font-family: 'inter-regular', sans-serif;
  font-weight: 400;
  span {
    color: var(--FORM-LABEL-default);
  }
`;
