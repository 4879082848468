import React from 'react';
import { InputStyled } from 'components/Form2/styled';
import { MAX_LENGTH_TEXT } from 'constant/inputMaxlength';
import {
  CHECK_CHARACTER,
  CHECK_LATIN_JAPANESE_NUMBER,
  CHECK_MOBILE_NUMBER,
} from 'constant/regex';
import LabelForm from 'components/Form/LabelForm';
import FormMessage from 'components/Form/FormMessage';
import FormItem from 'components/Form2/FormItem';
import useMultiLanguageRegisterAccount from 'page/Application/ManageAccountSoliton/RegisterAccountSoliton/hook/useMultiLanguageRegisterAccount';

const FormValidateTextAndNumber = ({
  maxLength,
  disabled,
  name,
  label,
  required,
  placeholder,
  textRequired,
  textValidate,
  isPhone,
  onChange,
  extra,
}) => {
  const {
    REGISTER_ACCOUNT_INPUT_TYPE_VALIDATION,
    REGISTER_ACCOUNT_CATEGORY_ENTRY_REQUIRED,
  } = useMultiLanguageRegisterAccount();
  return (
    <FormItem
      extra={extra}
      name={name}
      label={label ? <LabelForm text={'form label validate text'} /> : false}
      rules={[
        {
          required: required,
          message: (
            <FormMessage
              text1={
                textRequired
                  ? textRequired
                  : REGISTER_ACCOUNT_CATEGORY_ENTRY_REQUIRED
              }
              marginBottom={'12px'}
            />
          ),
        },
        () => ({
          validator(_, value) {
            if (isPhone) {
              if (!CHECK_MOBILE_NUMBER.test(value)) {
                return Promise.reject(
                  <FormMessage
                    text1={
                      '無効な文字列です。電話番号は0～9で入力してください。'
                    }
                    marginBottom={'12px'}
                  />,
                );
              }
            }

            if (!isPhone) {
              if (
                value &&
                (CHECK_CHARACTER === null ||
                  !CHECK_LATIN_JAPANESE_NUMBER.test(value) || // check japanese, latin
                  // value.trim() === '' || // check blank
                  (value &&
                    [...value].some((char) => char.codePointAt(0) > 65535))) // check 4-byte characters
              ) {
                return Promise.reject(
                  <FormMessage
                    text1={
                      textValidate
                        ? textValidate
                        : REGISTER_ACCOUNT_INPUT_TYPE_VALIDATION
                    }
                    marginBottom={'12px'}
                  />,
                );
              }
            }

            return Promise.resolve();
          },
        }),
      ]}
    >
      <InputStyled
        onChange={onChange}
        size="large"
        type="text"
        disabled={disabled}
        maxLength={maxLength ? maxLength : MAX_LENGTH_TEXT}
        placeholder={placeholder}
      />
    </FormItem>
  );
};

export default FormValidateTextAndNumber;
