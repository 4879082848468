import React from 'react';
import { ButtonBasic } from 'components/Button';
import { FormItem } from 'components/Form2';
import { InputPasswordStyled, InputStyled } from 'components/Form2/styled';
import { CHECK_STRONG_PASSWORD } from 'constant/regex';
import useMultiLanguageRegisterAccount from 'page/Application/ManageAccountSoliton/RegisterAccountSoliton/hook/useMultiLanguageRegisterAccount';
import useRoleMultiLanguage from 'hook/useRoleMultiLanguage';
import FormMessage from 'components/Form/FormMessage';
import useMultiLanguage from 'hook/useMultiLanguage';
import LabelFormMyPage from 'page/Application/MyPage/component/FormMyPage/LabelFormMyPage';
import {
  GridFormAccount,
  GridFormAccount1to2,
  GridFormAccount2to3,
  GridFormAccount3to4,
} from 'page/Application/MyPage/component/FormMyPage/styled';
import { InputPasswordWrapper } from 'page/Application/MyPage/component/FormMyPage/EmailPasswordAccountType/styled';

const EmailPasswordAccountType = ({
  disabled,
  editPassword,
  setEditPassword,
  data,
  isEdit,
}) => {
  const { ROLE } = useRoleMultiLanguage();
  const {
    FORM_PASSWORD_ERROR_MESSAGE_VALIDATE_1,
    FORM_PASSWORD_ERROR_MESSAGE_VALIDATE_2,
  } = useMultiLanguage();
  const { REGISTER_ACCOUNT_CATEGORY_ENTRY_REQUIRED } =
    useMultiLanguageRegisterAccount();

  const handleEnableEditPassword = () => {
    setEditPassword(false);
  };

  return (
    <GridFormAccount>
      <GridFormAccount1to2>
        <LabelFormMyPage labelText={'メールアドレス'} required={false} />
      </GridFormAccount1to2>
      <GridFormAccount2to3>
        <FormItem name={'email'}>
          <InputStyled disabled={disabled} />
        </FormItem>
      </GridFormAccount2to3>

      {editPassword ? (
        <>
          <GridFormAccount1to2>
            <LabelFormMyPage labelText={'パスワード'} required={false} />
          </GridFormAccount1to2>
          <GridFormAccount2to3>
            <InputPasswordWrapper>
              <InputStyled
                type="password"
                disabled={disabled}
                value={'パスワードパスワード'}
                autoComplete="new-password"
              />
            </InputPasswordWrapper>
          </GridFormAccount2to3>
          <GridFormAccount3to4>
            <ButtonBasic
              text={'変更'}
              onClick={handleEnableEditPassword}
              width={'120px'}
            />
          </GridFormAccount3to4>
        </>
      ) : (
        <>
          <GridFormAccount1to2>
            <LabelFormMyPage labelText={'パスワード'} required={isEdit} />
          </GridFormAccount1to2>
          <GridFormAccount2to3>
            <FormItem
              name={'password'}
              rules={[
                {
                  required: true,
                  message: (
                    <FormMessage
                      text1={REGISTER_ACCOUNT_CATEGORY_ENTRY_REQUIRED}
                      marginBottom={'12px'}
                    />
                  ),
                },
                () => ({
                  validator(_, value) {
                    if (
                      value?.match(CHECK_STRONG_PASSWORD) === null &&
                      value.length > 0
                    ) {
                      return Promise.reject(
                        <FormMessage
                          text1={FORM_PASSWORD_ERROR_MESSAGE_VALIDATE_1}
                          text2={FORM_PASSWORD_ERROR_MESSAGE_VALIDATE_2}
                          marginBottom={'12px'}
                        />,
                      );
                    } else {
                      // resolve
                      return Promise.resolve();
                    }
                  },
                }),
              ]}
            >
              {editPassword || !isEdit ? (
                <InputStyled type="password" value={'パスワードパスワード'} />
              ) : (
                <InputPasswordStyled
                  placeholder={'パスワードを入力してください。'}
                />
              )}
            </FormItem>
          </GridFormAccount2to3>
        </>
      )}

      <GridFormAccount1to2>
        <LabelFormMyPage labelText={'アカウントタイプ'} />
      </GridFormAccount1to2>
      <GridFormAccount2to3>
        <FormItem>
          <InputStyled disabled={disabled} value={ROLE[data?.role]} />
        </FormItem>
      </GridFormAccount2to3>
    </GridFormAccount>
  );
};

export default EmailPasswordAccountType;
