const useRemoveBlankSpace = () => {
  const handleTrimInput = (form, field, value) => {
    form.setFieldsValue({ [field]: value.trim() });
  };

  return {
    handleTrimInput,
  };
};

export default useRemoveBlankSpace;
