import { Upload } from 'antd';
import styled from 'styled-components';

export const CustomUpload = styled(Upload.Dragger)`
  .ant-upload-list-item-actions .ant-btn-icon-only {
    display: contents;
  }

  font-family: 'inter-regular', sans-serif;
  color: var(--FORM-LABEL-default);

  //edit font,size for アップロード at screen manage-project-registration/edit
  .ant-upload-hint {
    margin: 0 50px 0 50px;
    color: var(--FORM-LABEL-default) !important;
  }

  .ant-upload-list-item-name {
    text-decoration: underline;
    text-decoration-thickness: 0.5px !important;
    text-decoration-skip-ink: none;
    text-underline-offset: 3px;
  }
`;
