import { ProductItem, ProductItemWrapper } from './styled';
import { Tooltip } from 'antd';

const FormListProduct = ({ lstProductData }) => {
  return (
    <>
      {!lstProductData ||
      typeof lstProductData == 'undefined' ||
      lstProductData === [] ||
      lstProductData.length === 0 ? (
        <ProductItem></ProductItem>
      ) : (
        <ProductItemWrapper>
          {lstProductData.map((item) => (
            <ProductItem key={item.productName}>
              <Tooltip title={item.productName} placement={'topLeft'}>
                {item.productName}
              </Tooltip>
            </ProductItem>
          ))}
        </ProductItemWrapper>
      )}
    </>
  );
};
export default FormListProduct;
