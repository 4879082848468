import React from 'react';
import {
  ButtonDelete,
  FormFooterWrapper,
} from 'page/Application/ManageCompanyList/component/Form/FormFooterSubmit/styled';
import {
  ButtonStyle,
  ButtonOkStyle,
} from 'page/Application/ManageCompanyList/component/Form/styled';
import useMultiLanguageEditCompany from 'page/Application/ManageCompanyList/EditCompany/hook/useMultiLanguageEditCompany';
import LoadingSpin from 'components/LoadingSpin';

const FormFooterSubmit = ({
  textOk,
  isDisable,
  isDisableDelete,
  textCancel,
  textDelete,
  onCancel,
  isLoadingSubmit,
  onFinish,
  onDelete,
  isDelete,
}) => {
  const { DETAIL_COMPANY_BUTTON_DELETE } = useMultiLanguageEditCompany();

  return (
    <FormFooterWrapper>
      <ButtonOkStyle
        type="primary"
        htmlType="submit"
        disabled={isLoadingSubmit}
        onClick={onFinish}
      >
        {/*must have a span tag so there are no spaces between letters*/}
        <span> {isLoadingSubmit ? <LoadingSpin /> : textOk}</span>
      </ButtonOkStyle>
      {isDelete && (
        <ButtonDelete disabled={isDisableDelete} onClick={onDelete}>
          {/*must have a span tag so there are no spaces between letters*/}
          <span> {textDelete ?? DETAIL_COMPANY_BUTTON_DELETE}</span>
        </ButtonDelete>
      )}
      <ButtonStyle
        //fixbug:514
        style={{ width: '128px' }}
        disabled={isDisable}
        onClick={onCancel}
      >
        {textCancel}
      </ButtonStyle>
    </FormFooterWrapper>
  );
};

export default FormFooterSubmit;
