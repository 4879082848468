import styled from 'styled-components';

export const BaseMenuItem = styled.div`
  display: flex;
  justify-content: space-between;
  border-radius: 6px;
  color: white;
  font-family: 'inter-regular', sans-serif;
  font-size: 16px;
  //width: 264px;
  height: 48px;
  margin-bottom: 4px;
  cursor: pointer;
  transition: 0.15s;
  transition-timing-function: ease-in-out;

  &:hover {
    background: #9d1c49;
  }
`;

export const BaseTitleMenu = styled.div`
  width: 100%;
  line-height: normal;
  white-space: nowrap;
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const BaseContentMenu = styled.div`
  align-items: center;
  display: flex;
  gap: 10px;
  padding-left: 20px;
`;
export const TitleMenu = styled(BaseTitleMenu)``;

export const TitleTopPage = styled(BaseTitleMenu)`
  font-weight: 600;
`;
export const MenuItemParent = styled(BaseMenuItem)`
  &.selected {
    background: #9d1c49;
  }
`;
export const MenuItemChildren = styled(BaseMenuItem)`
  &.selected {
    background: rgba(255, 255, 255, 0.1);
  }
`;

export const ContentMenuParent = styled(BaseContentMenu)`
  width: calc(100% - 72px);
`;

export const ContentMenuChildren = styled(BaseContentMenu)`
  width: calc(100% - 36px);
`;

export const ChildrenMenuWrapper = styled.div`
  &.show {
    display: block;
  }

  &.hide {
    display: none;
  }
`;

export const IconMenu = styled.div`
  width: 20px;
  //height: 48px;
  .anticon {
    font-size: 20px;
  }
`;

export const ExpandButton = styled.div`
  width: 48px;
  height: 48px;
  text-align: center;
  line-height: 48px;
  transition: 0.15s;
  transition-timing-function: ease-in;

  &.open {
    transform: rotate(90deg);
  }
  &.close {
    transform: rotate(0);
  }
`;

export const IconDot = styled.div`
  width: 20px;
  min-width: 20px;
  height: 48px;
  position: relative;
  &.selected {
    &:before {
      content: '';
      position: absolute;
      width: 6px;
      height: 6px;
      border-radius: 50%;
      background: rgba(217, 217, 217, 1);
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }
`;
