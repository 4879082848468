import styled from 'styled-components';

export const TitleStyle = styled.div`
  color: #656464;
  font-size: 18px;
  font-family: 'inter-medium', sans-serif;
  font-weight: 600;
`;
export const ButtonGroup = styled.div`
  display: flex;
  justify-content: end;
  align-items: center;
  gap: 10px;
  padding-bottom: 20px;
`;
