import DetailNotifyPartnerUser from 'page/Application/ManageProductAndService/TopPageProductPartnerUser/DetailNotifyPartnerUser';
import TopPageProductDetailPartnerUser from 'page/Application/ManageProductAndService/TopPageProductPartnerUser/TopPageProductDetailPartnerUser';
import TopPageProductSuperDetailPartnerUser from 'page/Application/ManageProductAndService/TopPageProductPartnerUser/TopPageProductSuperDetailPartnerUser';
import TopPagePartnerUser from 'page/Application/ManageProductAndService/TopPageProductPartnerUser/TopPagePartnerUser';
import TopPageNotifyDetailPartnerUser from 'page/Application/ManageProductAndService/TopPageProductPartnerUser/TopPageNotifyDetailPartnerUser';
import React from 'react';
import { Outlet } from 'react-router-dom';
import useSetTitlePage from "../../../../hook/useSetTitlePage";

const TopPageProductPartnerUser = () => {
  useSetTitlePage('製品一覧');
  return <Outlet />;
};

export default TopPageProductPartnerUser;

export {
  DetailNotifyPartnerUser,
  TopPageProductDetailPartnerUser,
  TopPageProductSuperDetailPartnerUser,
  TopPagePartnerUser,
  TopPageNotifyDetailPartnerUser,
};
