import styled from 'styled-components';
import { Input } from 'antd';

export const SearchStyled = styled(Input.Search)`
  .ant-input-search-button {
    background: white;
    border: 1px solid #d9d9d9;
    padding: 0;
  }

  .ant-input-search-button:hover {
    background: white !important;
    border: 1px solid VAR(--PRIMARY-default);
    padding: 0;
  }
`;

export const HeaderButtonSearchStyled = styled.div`
  color: rgba(0, 0, 0, 0.45);
  border-radius: 0 6px 6px 0;
  width: 40px;
  height: 38px;
  line-height: 38px;
  font-size: 18px;
  background: #f5f5f5;
  &:hover {
    background: white;
    color: #9d1c49;
  }
`;
