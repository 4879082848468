import React from 'react';
import FooterLarge from 'components/Logo/FooterDrawer/Large';
import FooterSmall from 'components/Logo/FooterDrawer/Small';

const Footer = () => {
  return (
    <div className="footer">
      <svg
        width="320"
        height="74"
        viewBox="0 0 320 74"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M320 40.8056C292.948 40.8056 77.319 40.8056 75.5341 40.7858C74.4174 40.7858 73.3006 40.6673 72.1938 40.5192C70.1996 40.2525 68.6042 39.2847 67.4276 37.6848C66.7596 36.7663 66.1514 35.7886 65.613 34.7912C62.851 29.5965 60.1189 24.3919 57.3768 19.1874C56.3299 17.2023 55.0435 15.4049 53.2188 14.0322C49.4199 11.1682 44.8232 11.3361 41.2735 14.5062C39.8078 15.8099 38.7409 17.39 37.8534 19.1084C35.8393 22.9994 33.8051 26.8806 31.781 30.7618C31.0432 32.1741 30.3352 33.5962 29.5375 34.9689C28.9791 35.9269 28.341 36.8454 27.6331 37.6947C26.0477 39.5908 23.9338 40.5488 21.4709 40.7365C20.5935 40.8056 19.716 40.8253 18.8386 40.8253C17.5423 40.8253 1.7948 40.8154 0 40.8253V50C5.2747 50 17.5323 50 18.8286 50C21.1917 50 23.5449 49.8617 25.8483 49.2395C29.9863 48.1236 33.2368 45.7435 35.7495 42.3462C36.5472 41.2599 37.2751 40.1143 37.9133 38.9193C40.3961 34.2875 42.829 29.636 45.2819 24.9944C45.4115 24.7574 45.5311 24.5104 45.6807 24.2932C46.6878 22.8019 48.7518 22.8513 49.6393 24.4216C50.3871 25.7548 51.0651 27.1275 51.7831 28.4805C53.7673 32.2136 55.7515 35.9566 57.7458 39.6896C58.7728 41.6055 60.0192 43.3634 61.5447 44.9337C64.1472 47.6002 67.3479 49.121 71.0272 49.6049C72.9716 49.8617 74.9459 49.9704 76.9102 49.9802C78.3959 49.9901 293.307 50 320 50V40.8056Z"
          fill="#EBEBEB"
        />
      </svg>
    </div>
  );
};

export default Footer;
