import React from 'react';
import { ContentWrapper, Container, BlockHeader, BlockFooter } from './styled';

const LayoutOpenSearch = ({ children }) => {
  return (
    <ContentWrapper>
      <Container>
        <BlockHeader />
        {children}
        <BlockFooter />
      </Container>
    </ContentWrapper>
  );
};

export default LayoutOpenSearch;
