import React, { useEffect } from 'react';
import { getListProductForNotification } from 'api/notification/notification.api';
import useSearchKeyword from 'hook/useSearchKeyword';
import TableListProduct from 'page/Application/ManageNotificationList/component/Form/FormAddProduct/TableListProduct';
import useCustomQuery from 'hook/useQuery/useCustomQuery';
import { SearchStyled } from 'page/Application/ManageNotificationList/component/Form/styled';
import useMultiLanguageRegisterCompany from 'page/Application/ManageCompanyList/RegisterCompany/hook/useMultiLanguageRegisterCompany';
import useMultiLanguageRegisterNotification from 'page/Application/ManageNotificationList/RegisterNotification/hook/useMultiLanguageRegisterNotification';
import AntdModal2 from 'components/AntdModal2';

/**
 * isModalOpen: trigger open modal.
 * handleCancel: close modal.
 * handleOk: trigger function with button ok in modal.
 */

const ModalListProduct = ({ isModalOpen, handleCancel, handleOk }) => {
  const { searchKeyword, onSearch, emptyText, setSearchKeyword } =
    useSearchKeyword();
  const { useQueryAPI } = useCustomQuery();
  const { MODAL_PRODUCTS_SEARCH } = useMultiLanguageRegisterCompany();
  const { MODAL_PRODUCTS_TITLE } = useMultiLanguageRegisterNotification();

  const { data: productList, isLoading } = useQueryAPI({
    api: getListProductForNotification,
    params: {
      keyword: searchKeyword,
      isNotify: true,
    },
    key: 'productList',
    enabled: isModalOpen, // call api when modal add product open
  });

  useEffect(() => {
    if (isModalOpen === false) {
      setSearchKeyword('');
    }
  }, [isModalOpen, setSearchKeyword]);

  return (
    <AntdModal2
      title={
        <span style={{ fontSize: 18, fontWeight: 600, lineHeight: '22px' }}>
          {MODAL_PRODUCTS_TITLE}
        </span>
      }
      handleOk={handleOk}
      handleCancel={handleCancel}
      isModalOpen={isModalOpen}
      width={600}
      total={productList?.data?.count || 0}
      content={
        <>
          <SearchStyled
            placeholder={MODAL_PRODUCTS_SEARCH}
            onSearch={onSearch}
            loading={isLoading}
            maxLength={256}
          />
          <div style={{ marginTop: 12 }}>
            <TableListProduct
              emptyText={emptyText}
              dataRaw={productList}
              isLoading={isLoading}
            />
          </div>
        </>
      }
    />
  );
};

export default ModalListProduct;
