import React, { useEffect, useMemo, useState, Suspense } from 'react';
import { Alert, Form, Spin } from 'antd';
import {
  getDetailPartnerUser,
  updatePartnerUser,
} from 'api/myPage/partnerUser.api';
import { ButtonOutline, ButtonPrimary } from 'components/Button';
import Breadcrumb from 'components/Layout/ApplicationLayout/Breadcrumb';
import LayoutDefault from 'components/Layout/LayoutDefault';
import RowButton from 'components/Layout/RowButton';
import useCustomMutateRenderMessage from 'hook/useQuery/useCustomMutateRenderMessage';
import useCustomQuery from 'hook/useQuery/useCustomQuery';
import useErrorCodeAccountPartner from 'page/Application/ManagerAccountPartner/hook/useErrorCodeAccountPartner';
import useModalConfirm from 'hook/useModalConfirm';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { FormTitle } from 'page/Application/ManageCompanyList/component/Form';
import {
  FormWrapper,
  GridFormAccount,
  GridFormAccount2to3,
} from 'page/Application/MyPagePartnerAdmin/styled';
import {
  FullName,
  AccountEmail,
  ChangePassword,
  AccountType,
  PhoneNumber,
  Department,
  Position,
  Duty,
  TextBoxGroupJapanArea,
} from 'page/Application/MyPagePartnerAdmin/component/Form';
import FormMessage from 'components/Form/FormMessage';

const AccountInformationEdit = () => {
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const { handleConfirmAction, contextHolder } = useModalConfirm();
  const [isDisable, setIsDisable] = useState(false);
  const [editPassword, setEditPassword] = useState(true);
  const { ERROR_CODE_ACCOUNT_PARTNER } = useErrorCodeAccountPartner();
  const { useMutationAPIHandleErrorCode, errorMessage } =
    useCustomMutateRenderMessage({ errorCode: ERROR_CODE_ACCOUNT_PARTNER });
  const location = useLocation();
  const { useQueryWithoutParams } = useCustomQuery();
  const [data, setData] = useState(location.state);
  const [checkedListAll, setCheckedListAll] = useState([]);

  const { data: partnerDetail = [], isLoading } = useQueryWithoutParams({
    api: getDetailPartnerUser,
    key: 'myPagePA',
    options: {
      refetchOnWindowFocus: false, // cancel fetch data when out focus
    },
  });

  const listItem = useMemo(() => {
    return [
      {
        title: <Link to={'/'}>パートナー専用ページ</Link>,
      },
      {
        title: 'アカウント情報',
      },
    ];
  }, []);

  const onFinish = (values) => {
    const trimPosition = values?.position?.trim();
    const trimDepartment = values?.department?.trim();
    const payload = {
      ...values,
      position: trimPosition,
      department: trimDepartment,
      listAreaId: checkedListAll,
      phoneNumber: values.phoneNumber.replaceAll('-', ''),
      dutyId: values.dutyId,
    };
    updateAccount({
      payload: payload,
    });
  };

  const handleBack = () => {
    navigate('/account-information');
  };
  const onChangePhoneNumber = (value) => {
    const valuePhoneNumber = value?.target?.value;
    form.setFieldValue('phoneNumber', valuePhoneNumber);
  };

  useEffect(() => {
    const detailData = partnerDetail?.data;
    form.setFieldsValue({
      duty: detailData?.duty,
      dutyId: detailData?.dutyId,
      phoneNumber:
        detailData?.phoneNumber && detailData?.phoneNumber !== ''
          ? detailData?.phoneNumber
          : detailData?.mobileNumber,
    });
    if (!data) {
      setData(partnerDetail?.data);
    }
    form.setFieldsValue({
      ...data,
    });
  }, [form, data, partnerDetail?.data, partnerDetail]);

  const {
    mutate: updateAccount,
    isError,
    isLoading: isLoadingUpdate,
    isSuccess,
  } = useMutationAPIHandleErrorCode({
    api: updatePartnerUser,
    messageSuccess: '変更した情報を保存しました。',
    path: '/account-information',
    refetchKey: 'myPagePA',
  });

  return (
    <>
      {contextHolder}
      <Suspense fallback={isLoading}>
        <Breadcrumb listItem={listItem} titlePage={'アカウント情報'} />
        <LayoutDefault>
          <FormTitle
            notice={'注意：'}
            title={
              '変更した内容は画面下部の「保存」ボタンを押すと保存されます。'
            }
            type={'text'}
            styles={{ color: '#9D1C49' }}
          />
          <Spin spinning={isLoading || isLoadingUpdate || isSuccess}>
            <FormWrapper>
              {(isError || errorMessage) && (
                <GridFormAccount>
                  <GridFormAccount2to3>
                    <Alert message={errorMessage} type="error" />
                  </GridFormAccount2to3>
                </GridFormAccount>
              )}
              <Form
                size={'Large'}
                form={form}
                disabled={false}
                onFinish={onFinish}
              >
                <FullName editMode={true} isEdit={true} />
                <AccountEmail
                  data={data}
                  disabled={true}
                  setEditPassword={setEditPassword}
                  editPassword={editPassword}
                  isEdit={true}
                />
                <ChangePassword
                  data={data}
                  disabled={true}
                  isEdit={true}
                  editPassword={editPassword}
                  setEditPassword={setEditPassword}
                />
                <AccountType data={data} disabled={true} />
                <PhoneNumber
                  onChange={onChangePhoneNumber}
                  maxLength={12}
                  isEdit={true}
                  isPhone={true}
                  extra={
                    <FormMessage
                      text1={
                        '携帯電話をお持ちの方は、携帯電話番号をご記入ください。'
                      }
                      marginBottom={12}
                    />
                  }
                />
                <Department editMode={true} />
                <Position editMode={true} />
                <Duty editMode={true} />
                <TextBoxGroupJapanArea
                  isEdit={true}
                  formDetailData={partnerDetail}
                  checkedListAll={checkedListAll}
                  setCheckedListAll={setCheckedListAll}
                  disabled={false}
                />

                <GridFormAccount className="margin-top-20">
                  <GridFormAccount2to3>
                    <RowButton>
                      <ButtonPrimary text={'保存'} width={120} />
                      <ButtonOutline
                        isDisabled={isDisable}
                        text={'キャンセル'}
                        onClick={() => {
                          handleConfirmAction({
                            confirmFn: handleBack,
                            title: '変更をキャンセルしてもよろしいでしょうか？',
                            okText: 'はい',
                            cancelText: 'キャンセル',
                            setIsDisable: setIsDisable,
                          });
                          setIsDisable(true);
                        }}
                      />
                    </RowButton>
                  </GridFormAccount2to3>
                </GridFormAccount>
              </Form>
            </FormWrapper>
          </Spin>
        </LayoutDefault>
      </Suspense>
    </>
  );
};

export default AccountInformationEdit;
