import styled from 'styled-components';
import { Form, Input } from 'antd';

export const FormItemStyled = styled(Form.Item)`
  margin-bottom: ${(props) => props?.marginbottom || '30px'};
  .ant-form-item-explain-error {
    &:not(:first-child) {
      display: none;
    }
  }
`;

export const InputStyled = styled(Input)`
  height: 40px;
`;
