import React, { useEffect, useMemo, useState } from 'react';
import { Alert, Form, Input, message, Spin } from 'antd';
import { network } from 'recoil/atom/network';
import { CHECK_VALIDATE_EMAIL } from 'constant/regex';
import { getPartnerPage, updatePartnerPage } from 'api/partner/partner.api';
import { ButtonOutline, ButtonPrimary } from 'components/Button';
import FormMessage from 'components/Form/FormMessage';
import Breadcrumb from 'components/Layout/ApplicationLayout/Breadcrumb';
import useModalConfirm from 'hook/useModalConfirm';
import useCustomMutateRenderMessageWithSate from 'hook/useQuery/useCustomMutateRenderMessageWithState';
import useCustomQuery from 'hook/useQuery/useCustomQuery';
import {
  ListFilePartner,
  ListNotification,
} from 'page/Application/PartnerWebsite/component';
import useErrorCodePartnerWebsiteOverView from 'page/Application/PartnerWebsite/hook/useErrorCodePartnerWebsiteOverView';
import {
  GridLayout,
  HeaderEditMode,
  HeaderTitleName,
  WarningTitleHeader,
} from 'page/Application/PartnerWebsite/styled';
import useMultiLanguagePagePartner from 'page/Application/PartnerWebsite/useMultiLanguagePagePartner';
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import { partnerIdRecoil, userInformation } from 'recoil/atom/authentication';
import useSetTitlePage from 'hook/useSetTitlePage';
import { BreadCrumbDetail } from 'page/Application/ManageProductAndService/TopPageProduct/TopPageProductDetail/styled';

const PartnerWebsiteEdit = () => {
  const isOnline = useRecoilValue(network);
  const [messageErrorNetWork, contextErrorNetWork] = message.useMessage();
  useSetTitlePage('パートナー専用ページの編集');
  const navigate = useNavigate();
  const { useQueryAPI } = useCustomQuery();
  const { PAGE_PARTNER_TITLE_INFORMATION } = useMultiLanguagePagePartner();
  const [form] = Form.useForm();
  const { state } = useLocation();
  const { role } = useRecoilValue(userInformation);
  const { partnerId: partnerIdParam } = useParams();
  const { partnerId } = useRecoilValue(partnerIdRecoil);
  const [mainPartnerId, setPartnerId] = useState(
    state?.partnerId || partnerIdParam || partnerId,
  );
  const [dataNotification, setDataNotification] = useState([]);
  const [dataFilePartner, setDataFilePartner] = useState([]);
  const { ERROR_CODE_PARTNER_OVER_VIEW } = useErrorCodePartnerWebsiteOverView();
  const [isDisable, setIsDisable] = useState(false);
  const { contextHolder, handleConfirmAction } = useModalConfirm();
  const { errorMessage, useMutationAPIHandleErrorCode } =
    useCustomMutateRenderMessageWithSate({
      errorCode: ERROR_CODE_PARTNER_OVER_VIEW,
    });

  const {
    data: partnerPageDetail,
    isLoading,
    error,
  } = useQueryAPI({
    api: getPartnerPage,
    key: 'partnerPageDetail',
    params: {
      partnerId: mainPartnerId,
    },
    options: {
      refetchOnWindowFocus: false, // cancel fetch data when out focus
    },
  });

  const {
    mutate: updatePartnerPageMutate,
    isLoading: loadingUpdate,
    isError,
  } = useMutationAPIHandleErrorCode({
    api: updatePartnerPage,
    refetchKey: 'getDetailSolitonUser',
    path:
      role === 'ROLE_PARTNER_ADMIN' || role === 'ROLE_PARTNER_USER'
        ? '/partner-page'
        : `/partner-page/${mainPartnerId}`,
    state: {
      state: {
        partnerId: mainPartnerId,
      },
    },
    messageSuccess: '変更した情報を保存しました。',
  });

  const listItem_SA = useMemo(() => {
    return [
      {
        title: <Link to={`/manage-company-list`}>企業管理</Link>,
      },
      {
        title: (
          <Link to={`/manage-company-list/edit/${mainPartnerId}`}>
            企業詳細
          </Link>
        ),
      },
      {
        title: (
          <Link to={`/partner-page/${mainPartnerId}`}>
            パートナー専用ページ
          </Link>
        ),
      },
      {
        title: <BreadCrumbDetail>パートナー専用ページの編集</BreadCrumbDetail>,
      },
    ];
  }, [mainPartnerId]);

  const listItem_SU = useMemo(() => {
    return [
      {
        title: <Link to={`/my-page`}>マイページ</Link>,
      },
      {
        title: (
          <Link to={`/partner-page/${mainPartnerId}`}>
            パートナー専用ページ
          </Link>
        ),
      },
      {
        title: <BreadCrumbDetail>パートナー専用ページの編集</BreadCrumbDetail>,
      },
    ];
  }, [mainPartnerId]);

  const listItem_PA_PU = useMemo(() => {
    return [
      {
        title: <Link to={`/partner-page`}>パートナー専用ページ</Link>,
      },
      {
        title: <BreadCrumbDetail>パートナー専用ページの編集</BreadCrumbDetail>,
      },
    ];
  }, []);

  const listItemByRole = useMemo(() => {
    return {
      ROLE_SOLITON_ADMIN: listItem_SA,
      ROLE_SOLITON_USER: listItem_SU,
      ROLE_PARTNER_ADMIN: listItem_PA_PU,
      ROLE_PARTNER_USER: listItem_PA_PU,
    };
  }, [listItem_SA, listItem_SU, listItem_PA_PU]);
  const errorNetWork = () => {
    messageErrorNetWork
      .open({
        type: 'error',
        content:
          '問題が発生しました。インターネット接続を確認して、もう一度やり直してください',
      })
      .then();
  };
  const handleNavigate = () => {
    if (isOnline) {
      handleConfirmAction({
        okText: 'はい',
        cancelText: 'キャンセル',
        title: '変更をキャンセルしてもよろしいでしょうか？',
        setIsDisable: setIsDisable,
        confirmFn: () => {
          navigate(
            role === 'ROLE_PARTNER_ADMIN' || role === 'ROLE_PARTNER_USER'
              ? '/partner-page'
              : `/partner-page/${mainPartnerId}`,
            {
              state: { partnerId: mainPartnerId },
            },
          );
        },
      });
    } else {
      errorNetWork();
    }
  };
  const onSubmitEdit = () => {
    form
      .validateFields()
      .then(() => {
        if (isOnline) {
          const dataUpdateNotification = dataNotification.map(
            (object, index) => {
              const obj = {
                noticeTitle: object?.noticeTitle?.trim(),
                noticeDisplayDate: object?.noticeDisplayDate,
                noticeContent: object.noticeContent
                  ? object.noticeContent?.trim()
                  : '',
                noticeOrderId: `${index}`,
              };
              if (object.noticeId) {
                Object.assign(obj, { noticeId: object?.noticeId });
              }
              return obj;
            },
          );

          const dataUpdateFilePartner = dataFilePartner.map((object, index) => {
            const obj = {
              detailTitle: object?.detailTitle?.trim(),
              detailDisplayDate: object?.detailDisplayDate
                ? object?.detailDisplayDate
                : '',
              detailOrderId: `${index}`,
              fileStorageRequest: object?.fileStorageRequest,
            };
            if (object.fileResponse && !object.fileResponse?.fileId) {
              Object.assign(obj, { multipartFile: object.fileResponse });
            }
            if (object.detailId) {
              Object.assign(obj, { detailId: object.detailId });
            }

            return obj;
          });

          const payload = {
            noticeRequests: dataUpdateNotification,
            partnerPageDetails: dataUpdateFilePartner,
          };
          if (form.getFieldValue('solitonEmail')) {
            Object.assign(payload, {
              solitonEmail: form.getFieldValue('solitonEmail')?.trim(),
            });
          }

          updatePartnerPageMutate({
            partnerId: mainPartnerId,
            payload: payload,
          });
        } else {
          errorNetWork();
        }
      })
      .catch(() => {
        return false;
      });
  };

  useEffect(() => {
    if (state?.partnerId || partnerId) {
      setPartnerId(state?.partnerId || partnerId);
    }
    if (
      partnerPageDetail?.ec ||
      error?.response?.data?.message === '400_PARTNER_NOT_EXISTS' ||
      error?.response?.data?.message === '400_ARGUMENT_IS_NOT_VALID'
    ) {
      navigate('/partner-not-found');
    }
    form.setFieldValue('solitonEmail', partnerPageDetail?.data?.solitonEmail);
  }, [
    form,
    navigate,
    mainPartnerId,
    partnerId,
    partnerPageDetail?.data?.solitonEmail,
    role,
    state?.partnerId,
    error?.response?.data?.message,
    partnerPageDetail?.ec,
  ]);

  return (
    <Spin spinning={isLoading || loadingUpdate}>
      {contextHolder}
      {contextErrorNetWork}
      <Breadcrumb
        listItem={listItemByRole[role]}
        titlePage="パートナー専用ページの編集"
      >
        <div style={{ display: 'flex', gap: '15px' }}>
          <ButtonPrimary text={'公開'} width={80} onClick={onSubmitEdit} />
          <ButtonOutline
            text={'キャンセル'}
            isDisabled={isDisable}
            onClick={() => {
              if (isOnline) {
                setIsDisable(true);
                handleNavigate();
                setTimeout(() => setIsDisable(false), 1000);
              } else {
                errorNetWork();
              }
            }}
          />
        </div>
      </Breadcrumb>
      <div className="information-wrapper">
        <div>
          {(isError || errorMessage) && (
            <Alert
              message={errorMessage}
              type="error"
              style={{ marginBottom: '24px' }}
            />
          )}
        </div>
        <HeaderTitleName className="base-text information-wrapper__title">
          {partnerPageDetail?.data?.partnerName}
        </HeaderTitleName>
        <HeaderEditMode className="base-text">
          <Form size="large" form={form}>
            <Form.Item
              name="solitonEmail"
              content={' '}
              label={
                <span
                  style={{
                    fontSize: 16,
                    fontFamily: 'inter-regular, sans-serif',
                    color: '#656464',
                    fontWeight: 400,
                    content: 'none !important',
                  }}
                >
                  {PAGE_PARTNER_TITLE_INFORMATION}
                </span>
              }
              rules={[
                {
                  pattern: CHECK_VALIDATE_EMAIL,
                  message: (
                    <FormMessage
                      text1={'メールアドレスの形式を正しく入力してください。'}
                      marginBottom={'12px'}
                    />
                  ),
                },
              ]}
              style={{ width: 560, marginBottom: 14 }}
            >
              <Input
                maxLength={256}
                size="large"
                style={{ height: 40 }}
                disabled={!(role === 'ROLE_SOLITON_ADMIN')}
                placeholder={'メールアドレスを入力してください。'}
              />
            </Form.Item>
          </Form>
          <WarningTitleHeader>
            変更した内容は上部の「公開」ボタンを押すと反映されます。
          </WarningTitleHeader>
        </HeaderEditMode>
      </div>
      <GridLayout>
        <ListNotification
          gridColumnForEdit={'1 / 3'}
          setDataNotification={setDataNotification}
          dataRaw={partnerPageDetail?.data?.noticeResponses}
          isLoading={isLoading}
          isEdit={true}
          isOnline={isOnline}
          errorNetWork={errorNetWork}
        />
        <ListFilePartner
          gridColumnForEdit={'1 / 3'}
          setDataFilePartner={setDataFilePartner}
          dataRaw={partnerPageDetail?.data?.detailResponses}
          isLoading={isLoading}
          isEdit={true}
          isOnline={isOnline}
          errorNetWork={errorNetWork}
        />
      </GridLayout>
    </Spin>
  );
};

export default PartnerWebsiteEdit;
