export const defaultData = {
  value: 0,
  label: 0,
  isRoot: true,
  level: 'MasterDoom',
  isParent: true,
  parentUuid: '',
  menuId: 0,
  menuUuid: '0',
  menuName: 'TOP',
  orderId: 0,
  isDragging: false,
  children: [],
};

export const listItemTree = [
  {
    value: 1,
    label: '1',
    isRoot: true,
    isParent: true,
    parentUuid: '0',
    menuId: 1,
    menuUuid: '1',
    menuName: 'root 1',
    orderId: 0,
    isDrag: false,
    children: [
      {
        value: 2,
        label: '2',
        isRoot: true,
        isParent: true,
        parentUuid: '1',
        menuId: 2,
        menuUuid: '2',
        menuName: '2',
        orderId: 0,
        isDrag: false,
        children: [
          {
            value: 3,
            label: '2.1',
            isRoot: false,
            isParent: false,
            menuId: 3,
            parentUuid: '2',
            menuUuid: '3',
            menuName: '2.1',
            orderId: 0,
            isDrag: false,
            children: [],
          },
          {
            value: 4,
            label: '2.2',
            isRoot: false,
            isParent: false,
            menuId: 4,
            parentUuid: '2',
            menuUuid: '4',
            menuName: '2.2',
            orderId: 1,
            isDrag: false,
            children: [],
          },
          {
            value: 5,
            label: '2.3',
            isRoot: false,
            isParent: false,
            menuId: 5,
            parentUuid: '2',
            menuUuid: '5',
            menuName: '2.3',
            orderId: 2,
            isDrag: false,
            children: [],
          },
        ],
      },
    ],
  },
  {
    value: 6,
    label: '6',
    isRoot: true,
    isParent: true,
    parentUuid: '0',
    menuId: 6,
    menuUuid: '6',
    menuName: 'root 2',
    orderId: 0,
    isDrag: false,
    children: [
      {
        value: 7,
        label: '7',
        isRoot: false,
        isParent: true,
        parentUuid: '6',
        menuId: 7,
        menuUuid: '7',
        menuName: '7',
        orderId: 0,
        isDrag: false,
        children: [
          {
            value: 8,
            label: '7.1',
            isRoot: false,
            isParent: false,
            menuId: 8,
            parentUuid: '7',
            menuUuid: '8',
            menuName: '7.1',
            orderId: 0,
            isDrag: false,
            children: [],
          },
          {
            value: 9,
            label: '8.2',
            isRoot: false,
            isParent: false,
            menuId: 9,
            parentUuid: '7',
            menuUuid: '9',
            menuName: '8.2',
            orderId: 1,
            isDrag: false,
            children: [],
          },
        ],
      },
    ],
  },
];
