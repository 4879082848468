import styled from 'styled-components';

export const CustomRowTable = styled.div`
  display: grid;
  grid-template-columns: 26% 26% 26% 21%;
  //grid-template-columns: 10% 20% 25% 25% 20%;
  align-items: center;
  &:not(:last-child) {
    border-bottom: 1px solid #f0f0f0;
    padding-bottom: 20px;
  }
  &:not(:first-child) {
    padding-top: 20px;
  }
`;

export const TableGrid2 = styled.div`
  grid-column: 2 / 3;
  text-align: center;
`;

export const TableGrid3 = styled.div`
  grid-column: 3/ 4;
  text-align: center;
`;
