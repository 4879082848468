import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Form } from 'antd';
import {
  deleteDetail,
  getDetailContent,
  updateDetail,
} from 'api/product/content.api';
import { DATE_FORMAT_LIST, TIME_ZONE } from 'constant/notificationConstants';
import { CHECK_SPECIAL_CHARACTER } from 'constant/regex';
import dayjs from 'dayjs';
import useModalConfirm from 'hook/useModalConfirm';
import useCustomMutate from 'hook/useQuery/useCustomMutate';
import useCustomMutateRenderMessage from 'hook/useQuery/useCustomMutateRenderMessage';
import useCustomQuery from 'hook/useQuery/useCustomQuery';
import FormContent from 'page/Application/ManageProductAndService/Content/component/form/FormContent';
import useErrorCodeManageContent from 'page/Application/ManageProductAndService/Content/hook/useErrorCodeManageContent';
import useErrorNetworkMessage from 'hook/useErrorNetworkMessage';

const EditContent = () => {
  const [form] = Form.useForm();
  const { contextHolder, handleConfirmAction } = useModalConfirm();
  const { useQueryAPI } = useCustomQuery();
  const { contentId } = useParams();
  const [explanation, setExplanation] = useState('');
  const [content, setContent] = useState('');
  const [isShowHash, setIsShowHash] = useState(true);
  const navigate = useNavigate();
  const [isCancel, setIsCancel] = useState(false);
  const { ERROR_CODE_MANAGE_CONTENT } = useErrorCodeManageContent();
  const { useMutationAPIHandleErrorCode, errorMessage } =
    useCustomMutateRenderMessage({
      errorCode: ERROR_CODE_MANAGE_CONTENT,
    });
  const { useMutationAPI } = useCustomMutate();
  const [isDisable, setIsDisable] = useState(false);
  const [dataSelect, setDataSelect] = useState([]);
  const [fileObject, setFileObject] = useState(null);
  const [contentPlainText, setContentPlainText] = useState(null);
  const [explanationPlainText, setExplanationPlainText] = useState(null);
  const { isOnline, errorNetWork, contextErrorNetWork } =
    useErrorNetworkMessage();

  const {
    data: contentData = {},
    isLoading,
    error,
  } = useQueryAPI({
    api: getDetailContent,
    key: 'contentManagement',
    params: {
      detailId: contentId,
    },
    options: {
      refetchOnWindowFocus: false,
    },
  });

  const {
    mutate: updateContentMutate,
    isError: isUpdateError,
    isLoading: isUpdateLoading,
    isSuccess: isUpdateSuccess,
  } = useMutationAPIHandleErrorCode({
    api: updateDetail,
    refetchKey: 'contentManagement',
    path: '/content',
    messageSuccess: 'コンテンツ情報を変更しました。',
  });

  const {
    mutate: deleteContentMutate,
    isError: isDeleteError,
    isLoading: isDeleteLoading,
    isSuccess: isDeleteSuccess,
  } = useMutationAPI({
    api: deleteDetail,
    path: `/content`,
    messageSuccess: 'コンテンツを削除しました。',
  });

  const handleCancel = () => {
    if (isOnline) {
      handleConfirmAction({
        confirmFn: navigate,
        params: `/content`,
        title: '変更をキャンセルしてもよろしいでしょうか？',
        okText: 'はい',
        cancelText: 'キャンセル',
        setIsDisable: setIsCancel,
      });
      setIsCancel(true);
    } else {
      errorNetWork();
    }
  };

  const handleDelete = () => {
    if (isOnline) {
      handleConfirmAction({
        confirmFn: deleteContentMutate,
        params: contentId,
        setIsDisable: setIsDisable,
        title: 'このコンテンツを削除してもよろしいでしょうか？',
        okText: '削除 ',
        cancelText: 'キャンセル',
      });
      setIsDisable(true);
    } else {
      errorNetWork();
    }
  };

  useEffect(() => {
    if (
      error?.response?.data?.message === '400_DETAIL_NOT_EXISTED' ||
      error?.response?.data?.message === '400_ARGUMENT_IS_NOT_VALID' ||
      CHECK_SPECIAL_CHARACTER.test(contentId)
    ) {
      navigate('/content-not-found');
    }
    if (contentData && contentData.ec === false && contentData.data) {
      const content = contentData?.data;
      setExplanation(content?.detailExplanation);
      setExplanationPlainText(content?.searchExplanation);
      setContent(content?.detailContent);
      setContentPlainText(content?.searchContent);
      setIsShowHash(content?.showHash);
      setDataSelect(content?.detailTagLst);
    }
  }, [error?.response?.data?.message, navigate, contentData, contentId]);

  const onFinish = (values) => {
    if (isOnline) {
      let menuIdList = [];
      menuIdList = values?.menuIdLst
        ?.filter((values) => values?.productId)
        .map((values) => {
          return values?.menuId?.[values?.menuId?.length - 1];
        });

      const dateFrom =
        values?.publishedFromToDate && values?.publishedFromToDate[0]
          ? new Date(values?.publishedFromToDate[0]).setSeconds(0)
          : null;
      const dateTo =
        values?.publishedFromToDate && values?.publishedFromToDate[1]
          ? new Date(values?.publishedFromToDate[1]).setSeconds(0)
          : null;
      const payload = {
        ...values,
        displayDate: dayjs(values.detailDisplayDate).format(
          DATE_FORMAT_LIST[3],
        ),
        publishedFrom:
          values.detailDisplayStatus === 'HIDE'
            ? null
            : dayjs.tz(
                dateTo && dateFrom > dateTo ? dateTo : dateFrom,
                TIME_ZONE,
              ),
        publishedTo:
          values.detailDisplayStatus === 'HIDE'
            ? null
            : dateTo &&
              dayjs.tz(dateFrom > dateTo ? dateFrom : dateTo, TIME_ZONE),
        displayStatus: values.detailDisplayStatus,
        displayType: values.detailDisplayType,
        detailExplanation: explanation === '<p>&nbsp;</p>' ? '' : explanation,
        detailSearchExplanation: explanationPlainText,
        detailContent: content === '<p>&nbsp;</p>' ? '' : content,
        detailSearchContent: contentPlainText,
        tagLst: values?.tags ?? [],
        menuIdLst: menuIdList,
        detailMenuIdLst: values?.menuIdLst?.map((values) => values?.menuId),
        fileUuid: values?.file[0]?.uuid,
        fileStorageRequest: fileObject && {
          fileName: fileObject?.fileName,
          keyFileName: fileObject?.keyFileName,
          contentType: fileObject?.contentType,
          size: fileObject?.size,
        },
      };

      updateContentMutate({
        detailId: contentId,
        payload: payload,
      });
    } else {
      errorNetWork();
    }
  };

  return (
    <>
      {contextHolder}
      {contextErrorNetWork}
      <FormContent
        contentDetail={contentData?.data}
        form={form}
        isEdit={true}
        isLoading={isLoading || isUpdateLoading || isDeleteLoading}
        explanation={explanation}
        setExplanation={setExplanation}
        content={content}
        setContent={setContent}
        isCancel={isCancel}
        handleCancel={handleCancel}
        handleDeleteContent={handleDelete}
        setIsShowHash={setIsShowHash}
        isShowHash={isShowHash}
        onFinish={onFinish}
        isDisable={isDisable}
        isSuccess={isUpdateSuccess || isDeleteSuccess}
        isError={isUpdateError || isDeleteError}
        errorMessage={errorMessage}
        dataSelect={dataSelect}
        setDataSelect={setDataSelect}
        fileObject={fileObject}
        setFileObject={setFileObject}
        setIsCancel={setIsCancel}
        setContentPlainText={setContentPlainText}
        setExplanationPlainText={setExplanationPlainText}
      />
    </>
  );
};

export default EditContent;
