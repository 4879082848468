import React from 'react';
import {
  FormTitleWrapper,
  FormTextWrapper,
  Notice,
  FormNoticeWrapper,
} from 'page/Application/ManageCompanyList/component/Form/FormTitle/styled';

const FormTitle = ({ title, styles, type, notice }) => {
  return type === 'title' ? (
    <FormTitleWrapper style={styles}>{title}</FormTitleWrapper>
  ) : (
    <FormNoticeWrapper>
      <Notice style={styles}>{notice}</Notice>
      <FormTextWrapper style={styles}>{title}</FormTextWrapper>
    </FormNoticeWrapper>
  );
};

export default FormTitle;
