import { useIntl } from 'react-intl';
const useMultiLanguageCompanyList = () => {
  const intl = useIntl();
  const COMPANY_LIST_TITLE = intl.formatMessage({
    id: 'authentication.companyList.title',
  });
  const COMPANY_LIST_MULTI_DELETE = intl.formatMessage({
    id: 'authentication.companyList.multiDelete',
  });
  const COMPANY_LIST_PLACEHOLDER_SEARCH = intl.formatMessage({
    id: 'authentication.companyList.placeholderSearch',
  });
  const COMPANY_LIST_PLACEHOLDER_SEARCH_NODATA = intl.formatMessage({
    id: 'authentication.companyList.placeholderSearch.noData',
  });
  const COMPANY_LIST_NO_DATA = intl.formatMessage({
    id: 'authentication.companyList.noData',
  });
  const COMPANY_LIST_TABLE_TITLE_COMPANY_NAME = intl.formatMessage({
    id: 'authentication.companyList.table.title.companyName',
  });
  const COMPANY_LIST_TABLE_TITLE_COMPANY_DOMAIN = intl.formatMessage({
    id: 'authentication.companyList.table.title.companyDomain',
  });
  const COMPANY_LIST_TABLE_TITLE_AUTO_APPROVE = intl.formatMessage({
    id: 'authentication.companyList.table.title.autoApprove',
  });
  const COMPANY_LIST_TABLE_TITLE_ACTION = intl.formatMessage({
    id: 'authentication.companyList.table.title.action',
  });
  const COMPANY_LIST_TABLE_STATUS_APPROVE_AUTO = intl.formatMessage({
    id: 'authentication.companyList.table.approve.auto',
  });
  const COMPANY_LIST_TABLE_STATUS_APPROVE_NON = intl.formatMessage({
    id: 'authentication.companyList.table.approve.non',
  });
  const COMPANY_LIST_TABLE_PAGINATION_TOTAL = intl.formatMessage({
    id: 'authentication.companyList.table.paginationTotal',
  });
  const COMPANY_LIST_TABLE_PAGE_SIZE_TEXT = intl.formatMessage({
    id: 'authentication.companyList.table.pageSizeText',
  });
  return {
    COMPANY_LIST_TITLE,
    COMPANY_LIST_MULTI_DELETE,
    COMPANY_LIST_PLACEHOLDER_SEARCH,
    COMPANY_LIST_PLACEHOLDER_SEARCH_NODATA,
    COMPANY_LIST_NO_DATA,
    COMPANY_LIST_TABLE_TITLE_COMPANY_NAME,
    COMPANY_LIST_TABLE_TITLE_COMPANY_DOMAIN,
    COMPANY_LIST_TABLE_TITLE_AUTO_APPROVE,
    COMPANY_LIST_TABLE_TITLE_ACTION,
    COMPANY_LIST_TABLE_PAGINATION_TOTAL,
    COMPANY_LIST_TABLE_STATUS_APPROVE_AUTO,
    COMPANY_LIST_TABLE_STATUS_APPROVE_NON,
    COMPANY_LIST_TABLE_PAGE_SIZE_TEXT,
  };
};
export default useMultiLanguageCompanyList;
