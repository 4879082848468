import React, { Suspense, useEffect, useMemo, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useRecoilState } from 'recoil';
import { listProduct } from 'recoil/atom/selectProduct';
import { InfoCircleOutlined } from '@ant-design/icons';
import { Alert, Button, Form, Popover, Spin } from 'antd';
import CKEditorBuilder from 'components/CKEditorBuilder';
import { FormMessage } from 'components/Form/index';
import { Container, FormWrapper } from 'components/Form/Template/styled';
import Breadcrumb from 'components/Layout/ApplicationLayout/Breadcrumb';
import LayoutDefault from 'components/Layout/LayoutDefault';
import {
  MANAGE_NOTIFICATION_PATH,
  PREVIEW_NOTIFICATION_PATH,
} from 'constant/appPath';
import { DATE_FORMAT_LIST } from 'constant/notificationConstants';
import dayjs from 'dayjs';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';
import useModalConfirm from 'hook/useModalConfirm';
import useNotifyCategoryMultiLanguage from 'hook/useNotifyCategoryMultiLanguage';
import {
  FormCheckBox,
  FormFooterSubmit,
  FormNote,
  FormRadio1,
} from 'page/Application/ManageNotificationList/component/Form';
import FormRadio2 from 'page/Application/ManageNotificationList/component/Form/FormRadio2/index';
import {
  Grid1,
  Grid2,
  Grid3,
  GridForm,
} from 'page/Application/ManageNotificationList/component/Form/styled';
import useMultiLanguageEditNotification from 'page/Application/ManageNotificationList/EditNotification/hook/useMultiLanguageEditNotification';
import useMultiLanguageRegisterNotification from 'page/Application/ManageNotificationList/RegisterNotification/hook/useMultiLanguageRegisterNotification';
import FormDateRangePicker from '../FormDateRangePicker';
import {
  FormAddProduct,
  FormDatePicker,
  FormDropDown,
  FormFileUpload,
  FormLabelGrid,
  FormTextRequired,
} from '../index';
import useMultiLanguageEditCompany from 'page/Application/ManageCompanyList/EditCompany/hook/useMultiLanguageEditCompany';
import { FormTitle } from 'page/Application/ManageCompanyList/component/Form';

dayjs.extend(timezone);
dayjs.extend(utc);

const FormNotification = ({
  onFinish,
  formDetailData,
  isEdit,
  isLoading,
  handleDeleteNotification,
  isDisable,
  setIsDisable,
  idFile,
  setIdFile,
  isError,
  errorMessage,
  isSuccess,
  content,
  setContent,
  currentNotification,
  setCurrentNotification,
  setDestinationType,
  destinationType,
  setStatus,
  status,
  notifyId,
  fileObject,
  setFileObject,
  plainText,
  setPlainText,
}) => {
  const [form] = Form.useForm();
  const {
    REGISTER_NOTIFICATION_FORM_TITLE,
    REGISTER_NOTIFICATION_FORM_STATUS,
    REGISTER_NOTIFICATION_FORM_PUBLISHED_FROM_DATE,
    REGISTER_NOTIFICATION_FORM_PUBLISHED_TO_DATE,
    REGISTER_NOTIFICATION_FORM_DISPLAYED_DATE,
    REGISTER_NOTIFICATION_FORM_CATEGORY,
    REGISTER_NOTIFICATION_FORM_DESTINATION_TYPE,
    REGISTER_NOTIFICATION_FORM_CONTENT,
    REGISTER_NOTIFICATION_FORM_URL,
    REGISTER_NOTIFICATION_FORM_PDF,
    REGISTER_NOTIFICATION_FORM_SUBMIT,
    REGISTER_NOTIFICATION_FORM_CANCEL,
    REGISTER_NOTIFICATION_MESSAGE_CANCEL,
    REGISTER_NOTIFICATION_OK,
    REGISTER_NOTIFICATION_CANCEL,
    REGISTER_NOTIFICATION_FORM_PLACEHOLDER_DISPLAYED_DATE,
    REGISTER_NOTIFICATION_FIELD_REQUIRED,
    REGISTER_NOTIFICATION_TITLE,
    REGISTER_NOTIFICATION_POPOVER_PUBLISHED_DATE_TO,
  } = useMultiLanguageRegisterNotification();
  const { DETAIL_COMPANY_TITLE } = useMultiLanguageEditCompany();
  const {
    EDIT_NOTIFICATION_BUTTON_KEEP,
    EDIT_NOTIFICATION_BUTTON_DELETE,
    EDIT_NOTIFICATION_CONFIRM_DELETE_EDIT,
    EDIT_NOTIFICATION_DELETE,
  } = useMultiLanguageEditNotification();
  const { contextHolder, handleConfirmAction } = useModalConfirm();
  const { NOTIFICATION_CATEGORY_FOR_FORM } = useNotifyCategoryMultiLanguage();
  const [isPreview, setIsPreview] = useState(false);
  const [isCancel, setIsCancel] = useState(false);
  const navigate = useNavigate();
  const [listSelectedProduct] = useRecoilState(listProduct);

  const listItem = useMemo(() => {
    return [
      {
        title: <Link to={'/'}>企業管理</Link>,
      },
      {
        title: <Link to={'/manage-notification-list'}>お知らせ管理</Link>,
      },
      {
        title: isEdit ? 'お知らせ詳細' : 'お知らせ登録',
      },
    ];
  }, [isEdit]);

  const initialValues = {
    status: status,
    destinationType: destinationType,
    publishedFromToDate: [null],
  };
  const onChangeStatus = (e) => {
    const statusValue = e.target.value;
    if (statusValue !== 'SCHEDULED') {
      form
        .validateFields(['publishedFromToDate'], {
          validateOnly: false,
        })
        .then();
    }
    form.setFieldValue('publishedFromToDate', []);
    form.setFieldValue('status', statusValue);
    setStatus(statusValue);
  };

  useEffect(() => {
    const timeZoneFromClient = Intl.DateTimeFormat().resolvedOptions().timeZone;
    const fileStorageResponse = formDetailData?.fileStorageResponse;
    if (isEdit && formDetailData) {
      form.setFieldsValue({
        ...formDetailData,
        publishedFromToDate: [
          formDetailData?.publishedFrom &&
            dayjs.utc(formDetailData?.publishedFrom).tz(timeZoneFromClient),
          formDetailData?.publishedTo &&
            dayjs.utc(formDetailData?.publishedTo).tz(timeZoneFromClient),
        ],
        file: fileStorageResponse && [
          {
            uid: fileStorageResponse.id,
            name: fileStorageResponse.name,
            status: 'done',
            type: fileStorageResponse.contentType,
            size: fileStorageResponse.size,
          },
        ],
      });
    }
    if (currentNotification) {
      form.setFieldsValue({
        ...currentNotification,
      });
      form.setFields([
        {
          name: 'file',
          errors: [
            <FormMessage
              text1={currentNotification?.fileMessage}
              marginBottom={'12px'}
            />,
          ],
        },
      ]);
      setStatus(currentNotification.status);
    }
  }, [isEdit, formDetailData, form, currentNotification, setStatus]);

  const handleDelete = () => {
    handleConfirmAction({
      confirmFn: handleDeleteNotification,
      title: EDIT_NOTIFICATION_CONFIRM_DELETE_EDIT,
      okText: EDIT_NOTIFICATION_DELETE,
      cancelText: REGISTER_NOTIFICATION_CANCEL,
      setIsDisable: setIsDisable,
    });
    setIsDisable(true);
  };

  const handleCancel = () => {
    handleConfirmAction({
      confirmFn: navigate,
      params: `/${MANAGE_NOTIFICATION_PATH}`,
      title: REGISTER_NOTIFICATION_MESSAGE_CANCEL,
      okText: REGISTER_NOTIFICATION_OK,
      cancelText: REGISTER_NOTIFICATION_CANCEL,
      setIsDisable: setIsCancel,
    });
    setIsCancel(true);
  };

  const checkStatus = (value) => {
    if (value === 'SHOW') {
      return [true, false];
    } else if (value === 'HIDE') {
      return [true, true];
    } else {
      return [false, false];
    }
  };

  const handlePreview = () => {
    const fileMessage = form.getFieldError('file')[0]?.props?.text1;
    form
      .validateFields()
      .then((values) => {
        setIsPreview(true);
        setCurrentNotification({
          ...values,
          content: content,
          plainText: plainText,
          fileMessage: fileMessage,
          listSelectedProduct: listSelectedProduct,
        });
        navigate(`/${MANAGE_NOTIFICATION_PATH}/${PREVIEW_NOTIFICATION_PATH}`, {
          state: {
            notifyId: notifyId,
          },
        });
      })
      .catch(() => {
        setIsPreview(false);
        return false;
      });
  };

  const onChangeDate = (props) => {
    if (!props) {
      form.setFieldValue('publishedFromToDate', []);
    }
  };

  return (
    <>
      <Breadcrumb titlePage={listItem[2].title} listItem={listItem} />
      <Suspense fallback={isLoading}>
        {contextHolder}
        <LayoutDefault>
          <FormTitle
            notice={'注意：'}
            title={isEdit ? DETAIL_COMPANY_TITLE : REGISTER_NOTIFICATION_TITLE}
            type={isEdit ? 'text' : 'title'}
            styles={{ color: isEdit ? '#9D1C49' : '#656464' }}
          />
          <FormWrapper>
            <Container>
              <Spin spinning={isLoading || isSuccess}>
                <Form
                  encType="multipart/form-data"
                  form={form}
                  layout="horizontal"
                  size="large"
                  onFinish={onFinish}
                  initialValues={initialValues}
                >
                  <GridForm>
                    <Grid2>
                      {(isError || errorMessage) && (
                        <Alert
                          message={errorMessage}
                          type="error"
                          style={{ marginBottom: '24px' }}
                        />
                      )}
                    </Grid2>
                    <Grid1>
                      <FormLabelGrid
                        labelText={REGISTER_NOTIFICATION_FORM_TITLE}
                        required={true}
                      />
                    </Grid1>
                    <Grid2>
                      <FormTextRequired
                        placeHolder={'タイトル名を入力してください'}
                        name="title"
                        required={true}
                        message={REGISTER_NOTIFICATION_FIELD_REQUIRED}
                        maxLength={256}
                      />
                    </Grid2>
                    <Grid1>
                      <FormLabelGrid
                        labelText={REGISTER_NOTIFICATION_FORM_STATUS}
                        required={true}
                      />
                    </Grid1>
                    <Grid2>
                      <FormRadio1
                        name={'status'}
                        required={true}
                        onChange={onChangeStatus}
                      />
                    </Grid2>
                    <Grid1>
                      <FormLabelGrid
                        labelText={`${REGISTER_NOTIFICATION_FORM_PUBLISHED_FROM_DATE}・${REGISTER_NOTIFICATION_FORM_PUBLISHED_TO_DATE}`}
                        required={false}
                      />
                    </Grid1>
                    <Grid2>
                      <FormDateRangePicker
                        name="publishedFromToDate"
                        message={REGISTER_NOTIFICATION_FIELD_REQUIRED}
                        width={'100%'}
                        isShowTime={true}
                        dateFormat={DATE_FORMAT_LIST[0]}
                        listDisable={checkStatus(status)}
                        status={status}
                        form={form}
                        onChangeDate={onChangeDate}
                      />
                    </Grid2>
                    <Grid3>
                      <Popover
                        content={
                          REGISTER_NOTIFICATION_POPOVER_PUBLISHED_DATE_TO
                        }
                      >
                        <Button type="link" style={{ padding: 0 }}>
                          <InfoCircleOutlined
                            style={{
                              color: '#A5A5A5',
                              fontSize: '20px',
                            }}
                          />
                        </Button>
                      </Popover>
                    </Grid3>
                    <Grid1>
                      <FormLabelGrid
                        labelText={REGISTER_NOTIFICATION_FORM_DISPLAYED_DATE}
                        required={true}
                      />
                    </Grid1>
                    <Grid2>
                      <FormDatePicker
                        name="displayedDate"
                        width={'100%'}
                        dateFormat={DATE_FORMAT_LIST[1]}
                        placeHolder={
                          REGISTER_NOTIFICATION_FORM_PLACEHOLDER_DISPLAYED_DATE
                        }
                        message={REGISTER_NOTIFICATION_FIELD_REQUIRED}
                        required={true}
                      />
                    </Grid2>
                    <Grid1>
                      <FormLabelGrid
                        required={true}
                        labelText={REGISTER_NOTIFICATION_FORM_CATEGORY}
                      />
                    </Grid1>
                    <Grid2>
                      <FormDropDown
                        required={true}
                        name="category"
                        messageRequired={REGISTER_NOTIFICATION_FIELD_REQUIRED}
                        data={NOTIFICATION_CATEGORY_FOR_FORM}
                      />
                    </Grid2>
                    <FormAddProduct isEdit={isEdit} />
                    <Grid1>
                      <FormLabelGrid
                        labelText={REGISTER_NOTIFICATION_FORM_DESTINATION_TYPE}
                      />
                    </Grid1>
                    <Grid2>
                      <FormRadio2
                        name={'destinationType'}
                        setDestinationType={setDestinationType}
                      />
                    </Grid2>
                    <Grid1>
                      <FormLabelGrid
                        labelText={REGISTER_NOTIFICATION_FORM_CONTENT}
                      />
                    </Grid1>
                    <Grid2>
                      <Form.Item>
                        <CKEditorBuilder
                          data={content}
                          setData={setContent}
                          setPlainText={setPlainText}
                        />
                        <FormNote
                          text={
                            'ソースコードモードで編集した内容は、ソースコードモードをOFFにしないと反映しません。'
                          }
                        />
                      </Form.Item>
                    </Grid2>
                    <Grid1>
                      <FormLabelGrid
                        labelText={REGISTER_NOTIFICATION_FORM_URL}
                      />
                    </Grid1>
                    <Grid2>
                      <FormTextRequired
                        name="url"
                        placeHolder="URL"
                        marginBottom={'3px'}
                      />
                      <FormCheckBox text={'別のタブで開く'} name={'isNewTab'} />
                    </Grid2>
                    <Grid1>
                      <FormLabelGrid
                        labelText={REGISTER_NOTIFICATION_FORM_PDF}
                      />
                    </Grid1>
                    <Grid2>
                      <FormFileUpload
                        name="file"
                        multipleUpload={false}
                        maxCount={1}
                        idFile={idFile}
                        setIdFile={setIdFile}
                        text={'アップロード可能なファイル形式とサイズ：'}
                        hint={
                          'pdf, pptx, ppt, xlsx, xls, xlsm, docx, doc - 50 MB; txt - 1MB; zip, bin, exe, nabin, efm - 1GB; iso - 4GB; csv - 10MB'
                        }
                        isPreview={isPreview}
                        form={form}
                        setIsDisable={setIsDisable}
                        setIsCancel={setIsCancel}
                        setIsPreview={setIsPreview}
                        isDisabled={isDisable}
                        fileObject={fileObject}
                        setFileObject={setFileObject}
                      />
                    </Grid2>
                    <Grid2>
                      <FormFooterSubmit
                        isDisable={isDisable}
                        onCancel={handleCancel}
                        onDelete={handleDelete}
                        textOk={
                          isEdit
                            ? EDIT_NOTIFICATION_BUTTON_KEEP
                            : REGISTER_NOTIFICATION_FORM_SUBMIT
                        }
                        textCancel={REGISTER_NOTIFICATION_FORM_CANCEL}
                        textDelete={EDIT_NOTIFICATION_BUTTON_DELETE}
                        isDelete={isEdit}
                        onPreview={handlePreview}
                        isCancel={isCancel}
                        isPreview={isPreview}
                      />
                    </Grid2>
                  </GridForm>
                </Form>
              </Spin>
            </Container>
          </FormWrapper>
        </LayoutDefault>
      </Suspense>
    </>
  );
};
export default FormNotification;
