import { useIntl } from 'react-intl';

const useAccountStatusMultiLanguage = () => {
  const intl = useIntl();
  const ACCOUNT_STATUS_ENABLED = intl.formatMessage({
    id: 'account.status.enabled',
  });
  const ACCOUNT_STATUS_UNAPPROVED = intl.formatMessage({
    id: 'account.status.unapproved',
  });
  const ACCOUNT_STATUS_SUSPENDED = intl.formatMessage({
    id: 'account.status.suspended',
  });

  const ACCOUNT_STATUS = {
    enable: {
      label: ACCOUNT_STATUS_ENABLED,
      value: 'ENABLED',
    },
    unapproved: {
      label: ACCOUNT_STATUS_UNAPPROVED,
      value: 'UNAPPROVED',
    },
    suspended: {
      label: ACCOUNT_STATUS_SUSPENDED,
      value: 'SUSPENDED',
    },
  };

  return {
    ACCOUNT_STATUS,
  };
};

export default useAccountStatusMultiLanguage;
