import { Cascader, Form } from 'antd';

import React from 'react';

const FormCascader = ({ name, menuData }) => {
  return (
    <Form.Item name={name}>
      <Cascader
        options={menuData}
        notFoundContent={'データがありません'}
        changeOnSelect
      />
    </Form.Item>
  );
};

export default FormCascader;
