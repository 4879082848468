import { Button, Popover } from 'antd';
import React from 'react';
import { InfoCircleOutlined } from '@ant-design/icons';

const FormIconWarning = () => {
  return (
    <>
      <Popover
        placement="topRight"
        content={
          <span style={{ color: ' #656464' }}>
            終了日時を入力しない場合は、公開期間は無期限になります。
          </span>
        }
      >
        <Button
          type="link"
          style={{
            display: 'flex',
            paddingLeft: 8,
            alignItems: 'center',
          }}
        >
          <InfoCircleOutlined
            style={{
              color: '#A5A5A5',
              fontSize: '20px',
            }}
          />
        </Button>
      </Popover>
    </>
  );
};

export default FormIconWarning;
