import { useMemo } from 'react';

const useErrorCodeManageProject = () => {
  const ERROR_CODE_MANAGE_PROJECT = useMemo(() => {
    return {
      '400_DETAIL_MUST_HAS_FILE': {
        message: 'DETAIL_MUST_HAS_FILE',
        statusCode: 400,
        type: 'error',
      },
      '400_THIS_FILE_IS_NOT_AN_IMAGE': {
        message: 'アップロードファイルの形式が無効である。',
        statusCode: 400,
        type: 'error',
      },
      '400_PROJECT_NOT_EXISTED': {
        message: 'PROJECT_NOT_EXISTED',
        statusCode: 400,
        type: 'error',
      },
      '400_DETAIL_ID_IS_DUPLICATE': {
        message: 'DETAIL_ID_IS_DUPLICATE',
        statusCode: 400,
        type: 'error',
      },
      '400_DETAIL_ID_NOT_BELONG_TO_PROJECT': {
        message: 'DETAIL_ID_NOT_BELONG_TO_PROJECT',
        statusCode: 400,
        type: 'error',
      },
      '400_PUBLISHED_TO_MUST_AFTER_NOW': {
        message: '「非公開日時予約」は現在時点後よりの日時を入力してください。',
        statusCode: 400,
        type: 'error',
      },
      '400_PUBLISHED_FROM_MUST_AT_LEAST_FROM_NOW': {
        message: '「公開日時予約」は現在時点後よりの日時を入力してください。',
        statusCode: 400,
        type: 'error',
      },
      '400_PUBLISHED_FROM_MUST_BEFORE_PUBLISHED_TO': {
        message:
          '「公開日時予約」を「非公開日時予約」以降の日時で選択してください。 ',
        statusCode: 400,
        type: 'error',
      },
      '400_CAN_NOT_SET_PUBLISHED_DATE': {
        message: 'CAN_NOT_SET_PUBLISHED_DATE ',
        statusCode: 400,
        type: 'error',
      },
      '401_USER_NOT_FOUND': {
        message: 'USER_NOT_FOUND ',
        statusCode: 401,
        type: 'error',
      },
      '400_CAN_NOT_ACCESS_DATA': {
        message: 'CAN_NOT_ACCESS_DATA ',
        statusCode: 400,
        type: 'error',
      },
      '400_PROJECT_CAN_NOT_BE_SHOWED': {
        message: 'PROJECT_CAN_NOT_BE_SHOWED ',
        statusCode: 400,
        type: 'error',
      },
    };
  }, []);
  return {
    ERROR_CODE_MANAGE_PROJECT,
  };
};

export default useErrorCodeManageProject;
