import {
  MANAGE_PRODUCT_AND_SERVICE_PATH,
  OPENSEARCH_RESULT,
  PROJECT_REGISTRATION_PATH,
} from 'constant/appPath';
import Application from 'page/Application';
import ManageProductAndService from 'page/Application/ManageProductAndService';
import TopPageViewSuperDetailProduct from 'page/Application/ManageProductAndService/TopPageProduct/TopPageViewProductSuperDetail';
import {
  DetailNotifyPartnerAdmin,
  TopPageNotifyDetailPartnerAdmin,
  TopPagePartnerAdmin,
  TopPageProductDetailPartnerAdmin,
  TopPageProductSuperDetailPartnerAdmin,
} from 'page/Application/ManageProductAndService/TopPageProductPartnerAdmin';
import TopPageProductSolitonUser from 'page/Application/ManageProductAndService/TopPageProductSolitonUser/index';
import MyPage, { MyPageEdit, MyPageOverview } from 'page/Application/MyPage';
import PartnerWebsite, {
  PartnerWebsiteEdit,
  PartnerWebsiteOverView,
} from 'page/Application/PartnerWebsite';
import PartnerWebsiteDetail from 'page/Application/PartnerWebsite/PartnerWebsiteDetail';
import NotFound from 'page/NotFound';

import OpensearchResult from 'page/Application/Opensearch/OpensearchResult';
import { RESET_PASSWORD_PATH } from 'constant/authenticationPath';
import ResetPassword from 'page/Authentication/ForgotPassword/ResetPassword';
import ProjectPreviewSolitonAdmin, {
  ProjectDetail,
  ProjectPreview,
} from 'page/Application/ProjectPreviewSolitonAdmin';

export const solitonUserRouterData = [
  {
    path: '/',
    element: <Application />,
    errorElement: <NotFound />,
    children: [
      {
        path: '/partner-page/:partnerId',
        element: <PartnerWebsite />,
        children: [
          {
            index: true,
            element: <PartnerWebsiteOverView />,
          },
          {
            path: `/partner-page/:partnerId/edit`,
            element: <PartnerWebsiteEdit />,
          },
          {
            path: `/partner-page/:partnerId/detail/:noticeId`,
            element: <PartnerWebsiteDetail />,
          },
        ],
      },
      {
        path: 'my-page',
        element: <MyPage />,
        children: [
          {
            index: true,
            element: <MyPageOverview />,
          },
          {
            path: `edit`,
            element: <MyPageEdit />,
          },
        ],
      },
      {
        path: 'top-page',
        element: <TopPageProductSolitonUser />,
        children: [
          {
            index: true,
            element: <TopPagePartnerAdmin />,
          },
          {
            path: 'top-page-product/:productId',
            element: <TopPageProductDetailPartnerAdmin />,
          },
          {
            path: 'top-page-product/list-notify/:productId',
            element: <DetailNotifyPartnerAdmin />,
          },
          {
            path: 'top-page-product/list-notify/:productId/notify-detail/:notifyId',
            element: <TopPageNotifyDetailPartnerAdmin />,
          },
          {
            path: 'top-page-product/:productId/notify-detail/:notifyId',
            element: <TopPageNotifyDetailPartnerAdmin />,
          },
          {
            path: 'top-page-product/:productId/top-page-detail-product/:menuId',
            element: <TopPageProductSuperDetailPartnerAdmin />,
          },
          {
            path: 'top-page-product/:productId/top-page-detail-product/:menuId/detail/:detailId',
            element: <TopPageViewSuperDetailProduct />,
          },
          {
            path: 'list-notify',
            element: <DetailNotifyPartnerAdmin />,
          },
          {
            path: 'notify-detail/:notifyId',
            element: <TopPageNotifyDetailPartnerAdmin />,
          },
          {
            path: 'list-notify/notify-detail/:notifyId',
            element: <TopPageNotifyDetailPartnerAdmin />,
          },
        ],
      },
      {
        path: MANAGE_PRODUCT_AND_SERVICE_PATH,
        element: <ManageProductAndService />,
      },
      {
        path: PROJECT_REGISTRATION_PATH,
        element: <ProjectPreviewSolitonAdmin />,
        children: [
          {
            index: true,
            element: <ProjectPreview />,
          },
          {
            path: 'detail/:projectId',
            element: <ProjectDetail />,
          },
        ],
      },
      // this router doesn't mention in document
      // {
      //   path: MANAGE_NOTIFICATION_PATH,
      //   element: <ManageNotificationList />,
      // },
      {
        path: OPENSEARCH_RESULT,
        element: <OpensearchResult roleType={'solitonUser'} />,
      },
    ],
  },
  {
    path: RESET_PASSWORD_PATH,
    element: <ResetPassword />,
  },
  {
    path: '404',
    element: <NotFound/>
  }
];
