import React from 'react';
import {
  ButtonDelete,
  FormFooterWrapper,
} from 'page/Application/ManageCompanyList/component/Form/FormFooterSubmit/styled';
import {
  ButtonOkStyle,
  ButtonStyle,
} from 'page/Application/ManageCompanyList/component/Form/styled';
import useMultiLanguageEditCompany from 'page/Application/ManageCompanyList/EditCompany/hook/useMultiLanguageEditCompany';

const FormFooterSubmit = ({
  textOk,
  textCancel,
  textDelete,
  onCancel,
  onFinish,
  onDelete,
  isDelete,
  isLoading,
  isLoadingSubmit,
  isDisable,
}) => {
  const { DETAIL_COMPANY_BUTTON_DELETE } = useMultiLanguageEditCompany();
  return (
    <FormFooterWrapper>
      <ButtonOkStyle
        type="primary"
        htmlType="submit"
        onClick={onFinish}
        disabled={isLoadingSubmit || isDisable}
      >
        {/*must have a span tag so there are no spaces between letters*/}
        <span> {textOk}</span>
      </ButtonOkStyle>
      {isDelete && (
        <ButtonDelete
          disabled={isDisable}
          onClick={onDelete}
          loading={isLoading}
        >
          {/*must have a span tag so there are no spaces between letters*/}
          <span> {textDelete ?? DETAIL_COMPANY_BUTTON_DELETE}</span>
        </ButtonDelete>
      )}
      <ButtonStyle
        style={{ minWidth: 126 }}
        disabled={isDisable}
        onClick={onCancel}
      >
        {/*must have a span tag so there are no spaces between letters*/}
        <span> {textCancel}</span>
      </ButtonStyle>
    </FormFooterWrapper>
  );
};

export default FormFooterSubmit;
