import styled from 'styled-components';

export const SelectAllWrapper = styled.div`
  background: #f4f4f4;
  width: auto;
  padding: 15px;
  margin-bottom: 15px;
  text-align: center;
  border-radius: 6px;
  font-size: 14px;
`;

export const SelectAll = styled.span`
  font-weight: 600;
  margin: 0 12px;
  //background: red;
  cursor: pointer;
  color: #9d1c49;

  &:hover {
    color: #cc396e;
  }
`;
