import React from 'react';
import { ButtonHeader, HeaderTable, TitleScreen } from './styled';
import { HistoryOutlined } from '@ant-design/icons';
import { useNavigate } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import { network } from 'recoil/atom/network';

const HeaderTopPage = ({
  errorNetWork,
  titleScreen,
  isDisplayNotifyList,
  productId,
  isNotifyDetail,
}) => {
  const navigate = useNavigate();
  const isOnline = useRecoilValue(network);
  const handleMoveToListAllNotify = () => {
    if (isOnline) {
      if (productId) {
        navigate(`/top-page/top-page-product/list-notify/${productId}`);
      } else {
        navigate(`/top-page/list-notify`);
      }
    } else {
      errorNetWork();
    }
  };

  return (
    <HeaderTable>
      <TitleScreen
        style={{
          color: isNotifyDetail ? '#434343' : '',
          paddingLeft: '20px',
        }}
      >
        {titleScreen}
      </TitleScreen>
      {isDisplayNotifyList && (
        <>
          <ButtonHeader
            size={'large'}
            type={'text'}
            icon={<HistoryOutlined style={{ color: '#9D1C49' }} />}
            onClick={handleMoveToListAllNotify}
            className="btn-desktop"
          >
            {productId
              ? '過去の情報はこちらから'
              : ' 過去のお知らせはこちらから'}
          </ButtonHeader>
          <ButtonHeader
            className="btn-mobile"
            size={'middle'}
            icon={<HistoryOutlined style={{ color: '#9D1C49' }} />}
            onClick={handleMoveToListAllNotify}
          />
        </>
      )}
    </HeaderTable>
  );
};

export default HeaderTopPage;
