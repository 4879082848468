import React, { useEffect, useState } from 'react';
import useCustomQuery from 'hook/useQuery/useCustomQuery';
import { getListPartnerAccount } from 'api/partner/partner.api';
import { SearchStyled } from 'page/Application/ManageCompanyList/component/Form/styled';
import useMultiLanguageRegisterAccount from 'page/Application/ManageAccountSoliton/RegisterAccountSoliton/hook/useMultiLanguageRegisterAccount';
import TableListPartner from 'page/Application/ManageAccountSoliton/RegisterAccountSoliton/component/FormAddPartner/TableListPartner';
import AntdModal2 from 'components/AntdModal2';
import useSearchKeyword from 'hook/useSearchKeyword';

const ModalListPartner = ({ isModalOpen, handleCancel, handleOk }) => {
  const { searchKeyword, setSearchKeyword, onSearch, emptyText } =
    useSearchKeyword();
  const [inputValue, setInputValue] = useState('');
  const { useQueryAPI } = useCustomQuery();
  const {
    REGISTER_ACCOUNT_MODAL_PARTNER_TITLE,
    REGISTER_ACCOUNT_MODAL_SEARCH,
  } = useMultiLanguageRegisterAccount();
  const { data: partnerList, isLoading } = useQueryAPI({
    api: getListPartnerAccount,
    params: {
      keyword: searchKeyword,
    },
    key: 'partnerList',
    enabled: isModalOpen, // call api when modal add product open
  });
  useEffect(() => {
    if (!isModalOpen) {
      setSearchKeyword('');
      setInputValue('');
    }
  }, [isModalOpen, searchKeyword, setSearchKeyword]);
  return (
    <>
      <AntdModal2
        title={
          <div
            style={{ fontSize: 18, fontFamily: 'inter-regular, sans-serif' }}
          >
            {REGISTER_ACCOUNT_MODAL_PARTNER_TITLE}
          </div>
        }
        handleOk={handleOk}
        handleCancel={handleCancel}
        isModalOpen={isModalOpen}
        // width={600}
        //fixbug SPS513: edit size of modal box to match with design
        width={596}
        totalContent={'会社総数'}
        total={partnerList?.data?.total ?? 0}
        content={
          <>
            <SearchStyled
              placeholder={REGISTER_ACCOUNT_MODAL_SEARCH}
              onSearch={onSearch}
              loading={isLoading}
              size="large"
              maxLength={256}
              value={inputValue}
              onChange={(e) => setInputValue(e.target.value)}
            />
            <div style={{ marginTop: 12 }}>
              <TableListPartner
                emptyText={emptyText}
                dataRaw={partnerList}
                isLoading={isLoading}
              />
            </div>
          </>
        }
      />
    </>
  );
};

export default ModalListPartner;
