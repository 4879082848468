import styled from 'styled-components';
import BackgroundAuthenSidebarPrivacy from 'assets/images/background-sidebar-privacy.jpg';

export const Container = styled.div`
  height: 705px;
  width: 680px;
  margin: 0 auto;
  @media all and (min-width: 1200px) and (max-width: 1440px) {
    width: calc(100% - 100px);
  }
  @media all and (max-width: 900px) {
    width: calc(100% - 100px);
  }
  @media all and (max-width: 430px) {
    width: calc(100% - 50px);
    height: 100%;
  }
  @media all and (max-width: 375px) {
    height: 667px;
  }
`;
export const BoxPrivacy = styled.div`
  background-color: #f1f1f1;
  height: 470px;
  width: 680px;
  display: flex;
  align-items: center;
  border-radius: 8px;
  @media all and (min-width: 1200px) and (max-width: 1440px) {
    width: 100%;
  }
  @media all and (max-width: 900px) {
    width: 100%;
  }
  @media all and (max-width: 430px) {
    height: calc(100% - 205px);
  }
`;
export const ContainerPrivacy = styled.div`
  background-color: #f1f1f1;
  margin: 0 10px 0 20px;
  height: 440px;
  max-width: 660px;
  overflow-y: scroll;
  @media all and (min-width: 1200px) and (max-width: 1440px) {
    width: 100%;
  }
  @media all and (max-width: 900px) {
    width: 100%;
  }
  @media all and (max-width: 430px) {
    height: calc(95%);
  }
  @media all and (max-width: 375px) {
    height: calc(92%);
  }
  &::-webkit-scrollbar {
    width: 4px;
  }
  &::-webkit-scrollbar-track {
    background: transparent;
  }
  &::-webkit-scrollbar-thumb {
    background: #d9d9d9;
    border-radius: 8px;
  }
  &::-webkit-scrollbar-thumb:hover {
    background: #d9d9d9;
  }
`;
export const TittlePrivacy = styled.div`
  font-family: 'inter-regular', sans-serif;
  color: #656464;
  font-size: 14px;
  font-weight: 600;
  margin-bottom: 5px;
  padding-right: 6px;
`;
export const ContentPrivacy = styled.p`
  font-family: 'inter-regular', sans-serif;
  color: #656464;
  font-size: 14px;
  font-weight: 400;
  margin-bottom: 5px;
  white-space: pre-wrap;
  line-height: 1.5;
  padding-right: 6px;
`;
export const FooterWrapper = styled.div`
  display: flex;
`;

export const FooterText = styled.p`
  font-family: 'inter-medium', sans-serif;
  font-weight: 400;
  font-size: 12px;
  color: #656464;
  margin: 25px auto;
`;
export const TitlePrivacy = styled.p`
  font-family: 'inter-semibold', sans-serif;
  font-weight: 600;
  text-align: center;
  color: #656464;
  font-size: 26px;
  padding-top: 10px;
  padding-bottom: 20px;
  line-height: 1.2;
  @media all and (max-width: 770px) {
    font-size: 16px;
    padding-top: 10px;
    padding-bottom: 30px;
  }
`;

export const AuthSidebarPrivacy = styled.div`
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  position: relative;
  width: auto;
  height: 100%;
  background-image: url(${BackgroundAuthenSidebarPrivacy});
`;
export const TextOnSidebarPrivacy = styled.div`
  position: absolute;
  font-family: montserrat-black, sans-serif;
  color: white;
  text-align: center;
  text-transform: uppercase;
  bottom: -12px;
  left: 20px;
  font-size: 90px;
`;
export const LogoPrivacy = styled.div`
  padding: 30px 0 20px 0;
  text-align: center;
`;
