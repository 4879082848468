import styled from 'styled-components';

export const ProductContainer = styled.div`
  color: var(--FORM-LABEL-default);
  padding: 25px 10px 0;
`;
export const TitleProduct = styled.p`
  font-family: 'inter-regular', sans-serif;
  font-size: 16px;
  font-weight: 400;
  color: #656464;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  display: list-item;
  text-underline-offset: 4px;
  text-decoration: underline;
  min-height:20px;
  &:before {
    content: '';
    width: 4px;
    height: 4px;
    border-radius: 50%;
    margin-right: 20px;
    display: inline-block;
    background-color: #D9D9D9;
    vertical-align: middle;
`;

export const ProductItem = styled.div`
  margin-bottom: 20px;

  &:hover {
    cursor: pointer;
    transform: scale(1.03);
    transition: 0.25s;
  }
`;
