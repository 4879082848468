import axiosClient from 'api/config/axiosClient';

const getListPartner = ({
  pageNumber,
  pageSize,
  keyword,
  displayedDateFrom,
  displayedDateTo,
  category,
  status,
  sortField,
  sortDirection,
}) => {
  // const url = `v1/partner/?pageNumber=${pageNumber}&pageSize=${pageSize}&keyword=${encodeURIComponent(
  //   keyword,
  // )}`;
  const url = `v1/partner?pageNumber=${pageNumber}&pageSize=${pageSize}&keyword=${keyword}&displayedDateFrom=${displayedDateFrom}&displayedDateTo=${displayedDateTo}&category=${category}&status=${status}&sortField=${sortField}&sortDirection=${sortDirection}`;
  return axiosClient.get(url);
};

const getListProduct = ({ keyword, isNotify }) => {
  const url = `v1/partner/product?keyword=${encodeURIComponent(
    keyword,
  )}&isNotify=${isNotify}`;
  return axiosClient.get(url);
};

const getListPartnerAccount = ({ keyword }) => {
  const url = `v1/account/partners?keyword=${encodeURIComponent(keyword)}`;
  return axiosClient.get(url);
};

const deletePartner = (partnerId) => {
  const url = `v1/partner/${partnerId}`;
  return axiosClient.delete(url);
};

const getDetailPartner = ({ partnerId, signal }) => {
  const url = `v1/partner/${partnerId}`;
  return axiosClient.get(url, signal);
};

const createPartner = (payload) => {
  const url = 'v1/partner/';
  return axiosClient.post(url, payload);
};

const updateDetailPartner = ({ partnerId, payload }) => {
  const url = `v1/partner/${partnerId}`;
  return axiosClient.put(url, payload);
};

const getPartnerPage = ({ partnerId }) => {
  const url = `v1/partner/page/${partnerId}`;
  return axiosClient.get(url);
};
const getDetailPartnerPage = ({ partnerId, noticeId }) => {
  const url = `/v1/partner/page/${partnerId}/notice/${noticeId}`;
  return axiosClient.get(url);
};

const updatePartnerPage = ({ partnerId, payload }) => {
  const url = `v1/partner/page/${partnerId}`;
  return axiosClient.put(url, payload);
};

export {
  getListPartner,
  getListPartnerAccount,
  deletePartner,
  // deleteMultiPartner,
  createPartner,
  getListProduct,
  getDetailPartner,
  updateDetailPartner,
  getPartnerPage,
  updatePartnerPage,
  getDetailPartnerPage,
};
