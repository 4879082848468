import React, { Suspense, useEffect, useMemo, useState } from 'react';
import Breadcrumb from 'components/Layout/ApplicationLayout/Breadcrumb';
import LayoutDefault from 'components/Layout/LayoutDefault';
import FormTitle from '../FormTitle';
import FormLabelGrid from '../FormLabelGrid';
import FormTextRequired from '../FormTextRequired';
import useModalConfirm from 'hook/useModalConfirm';
import FormDropDown from '../FormDropDown';
import FormRadio from '../FormRadio';
import FormUploadImg from '../FormUploadImg';
import CKEditorBuilder from 'components/CKEditorBuilder';
import FormFooterSubmit from '../FormFooterSubmit';
import Tree from 'components/Tree';
import useMultiLanguageRegisterProduct from '../../RegisterProduct/hook/useMultiLanguageRegisterProduct';
import { useNavigate } from 'react-router-dom';
import { Container, FormWrapper } from 'components/Form/Template/styled';
import { Alert, Form, Spin } from 'antd';
import { Grid1, Grid2, GridForm } from '../styled';
import { MANAGE_PRODUCT_PATH } from 'constant/appPath';
import { Link } from 'react-router-dom';
import { TreeWrapper } from './styled';
import FormNote from 'page/Application/ManageProductAndService/Product/component/FormNote/index';

const FormProduct = ({
  onFinish,
  formDetailData,
  isEdit,
  isLoading,
  handleDeleteProduct,
  isDisable,
  setIsDisable,
  content,
  setContent,
  isError,
  errorMessage,
  isSuccess,
  setIsDisplayed,
  isDisplayed,
  listCategoryProduct,
  setIdFile,
  treeData,
  setTreeData,
  setFileObject,
  fileObject,
  setPlainText,
}) => {
  const { contextHolder, handleConfirmAction } = useModalConfirm();
  const [hideButtonUploadImg, setHideButtonUploadImg] = useState(true);
  const [showDropdown, setShowDropdown] = useState(true);
  const [isCancel, setIsCancel] = useState(false);
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const listBreadCrumb = [
    {
      title: <Link to={'/'}>企業管理</Link>,
    },
    {
      title: <Link to={'/product'}>プロダクト管理</Link>,
    },
    {
      title: isEdit ? 'プロダクト詳細' : 'プロダクト登録',
    },
  ];
  const {
    APPLICATION_PRODUCT_TITLE,
    APPLICATION_PRODUCT_EDIT_TITLE,
    APPLICATION_PRODUCT_FORM_PRODUCTNAME,
    APPLICATION_PRODUCT_PLACEHOLDER_PRODUCTNAME,
    APPLICATION_PRODUCT_FIELD_REQUIRED,
    APPLICATION_PRODUCT_FORM_CATEGORYNAME,
    APPLICATION_PRODUCT_FORM_STATUS,
    APPLICATION_PRODUCT_FORM_LOGO,
    APPLICATION_PRODUCT_FORM_CONTENT,
    APPLICATION_PRODUCT_MESSAGE_CANCEL,
    APPLICATION_PRODUCT_BUTTON_DELETE,
    APPLICATION_PRODUCT_BUTTON_KEEP,
    APPLICATION_PRODUCT_MESSAGE_DELETE,
    APPLICATION_PRODUCT_OK,
    APPLICATION_PRODUCT_FORM_CANCEL,
    APPLICATION_PRODUCT_FORM_SUBMIT,
    APPLICATION_PRODUCT_FORM_MENU,
    APPLICATION_PRODUCT_CONFIRM_MESSAGE_SUBMIT,
  } = useMultiLanguageRegisterProduct();

  const initialValues = {
    isDisplayed: isDisplayed,
  };

  const dataCategoryProduct = useMemo(() => {
    if (!listCategoryProduct) {
      return [];
    }
    return listCategoryProduct?.map((item) => ({
      label: item.title,
      value: item.categoryId,
      title: '',
      isDisplayed: item?.isDisplayed,
    }));
  }, [listCategoryProduct]);

  const onChangeStatus = (e) => {
    const statusValue = e.target.value;
    form.setFieldValue('isDisplayed', statusValue);
    setIsDisplayed(statusValue);
  };

  const handleCancel = () => {
    handleConfirmAction({
      confirmFn: navigate,
      params: `/${MANAGE_PRODUCT_PATH}`,
      title: isEdit
        ? '変更をキャンセルしてもよろしいでしょうか？'
        : APPLICATION_PRODUCT_MESSAGE_CANCEL,
      okText: APPLICATION_PRODUCT_OK,
      cancelText: APPLICATION_PRODUCT_FORM_CANCEL,
      setIsDisable: setIsCancel,
    });
    setIsCancel(true);
  };

  const handleDelete = () => {
    handleConfirmAction({
      confirmFn: handleDeleteProduct,
      title: APPLICATION_PRODUCT_MESSAGE_DELETE,
      okText: APPLICATION_PRODUCT_BUTTON_DELETE,
      cancelText: APPLICATION_PRODUCT_FORM_CANCEL,
      setIsDisable: setIsDisable,
    });
    setIsDisable(true);
  };

  const handleFinish = (values) => {
    const categoryData = values.categoryId
      ? dataCategoryProduct.find(
          (x) =>
            x.value == values?.categoryId?.value ||
            x.value == values?.categoryId,
        )
      : values.categoryId;
    if (
      categoryData &&
      categoryData.isDisplayed == false &&
      values.isDisplayed == true
    ) {
      handleConfirmAction({
        confirmFn: onFinish,
        title: APPLICATION_PRODUCT_CONFIRM_MESSAGE_SUBMIT,
        okText: APPLICATION_PRODUCT_OK,
        cancelText: APPLICATION_PRODUCT_FORM_CANCEL,
        setIsDisable: setIsDisable,
        params: values,
      });
      setIsDisable(true);
    } else {
      onFinish(values);
    }
  };

  useEffect(() => {
    if (isEdit && formDetailData) {
      form.setFieldsValue({
        ...formDetailData,
        productName: formDetailData.productName,
        categoryId: dataCategoryProduct.find(
          (x) => x.value === formDetailData.category?.categoryId ?? '',
        ),
        isDisplayed: formDetailData.isDisplayed,
      });
      if (formDetailData.image) {
        form.setFieldValue('avatar', [
          {
            uid: '0',
            id: formDetailData?.fileId,
            name: formDetailData?.avatarName,
            status: 'done',
            thumbUrl: formDetailData?.image,
            type: formDetailData?.contentType,
            size: formDetailData?.size,
            percent: 100,
          },
        ]);
        setHideButtonUploadImg(false);
      }
    }
  }, [isEdit, formDetailData, form, dataCategoryProduct]);

  return (
    <>
      <Breadcrumb
        titlePage={listBreadCrumb[2].title}
        listItem={listBreadCrumb}
      />
      <Suspense fallback={isLoading}>
        {contextHolder}
        <LayoutDefault>
          <FormTitle
            title={
              isEdit
                ? APPLICATION_PRODUCT_EDIT_TITLE
                : APPLICATION_PRODUCT_TITLE
            }
          ></FormTitle>
          <FormWrapper>
            <Container>
              <Spin spinning={isLoading || isSuccess}>
                <Form
                  encType="multipart/form-data"
                  form={form}
                  layout="horizontal"
                  size="large"
                  onFinish={handleFinish}
                  initialValues={initialValues}
                >
                  <GridForm>
                    <Grid2>
                      {(isError || errorMessage) && (
                        <Alert
                          message={errorMessage}
                          type="error"
                          style={{ marginBottom: '24px' }}
                        />
                      )}
                    </Grid2>
                    <Grid1>
                      <FormLabelGrid
                        labelText={APPLICATION_PRODUCT_FORM_PRODUCTNAME}
                        required={true}
                      ></FormLabelGrid>
                    </Grid1>
                    <Grid2>
                      <FormTextRequired
                        placeHolder={
                          APPLICATION_PRODUCT_PLACEHOLDER_PRODUCTNAME
                        }
                        name="productName"
                        required={true}
                        message={APPLICATION_PRODUCT_FIELD_REQUIRED}
                        maxLength={256}
                      ></FormTextRequired>
                    </Grid2>
                    <Grid1>
                      <FormLabelGrid
                        labelText={APPLICATION_PRODUCT_FORM_CATEGORYNAME}
                        required={true}
                      ></FormLabelGrid>
                    </Grid1>
                    <Grid2>
                      <FormDropDown
                        required={true}
                        name="categoryId"
                        data={dataCategoryProduct}
                        messageRequired={APPLICATION_PRODUCT_FIELD_REQUIRED}
                      ></FormDropDown>
                    </Grid2>
                    <Grid1>
                      <FormLabelGrid
                        labelText={APPLICATION_PRODUCT_FORM_STATUS}
                        required={true}
                      ></FormLabelGrid>
                    </Grid1>
                    <Grid2>
                      <FormRadio
                        name={'isDisplayed'}
                        required={true}
                        onChange={onChangeStatus}
                      />
                    </Grid2>
                    <FormUploadImg
                      setIdFile={setIdFile}
                      form={form}
                      hideButtonUploadImg={hideButtonUploadImg}
                      setHideButtonUploadImg={setHideButtonUploadImg}
                      labelText={APPLICATION_PRODUCT_FORM_LOGO}
                      setFileObject={setFileObject}
                      fileObject={fileObject}
                      setIsCancel={setIsCancel}
                      setIsDisable={setIsDisable}
                      isDisable={isDisable}
                    />
                    <Grid1>
                      <FormLabelGrid
                        labelText={APPLICATION_PRODUCT_FORM_CONTENT}
                      ></FormLabelGrid>
                    </Grid1>
                    <Grid2>
                      <Form.Item>
                        <CKEditorBuilder
                          data={content}
                          setData={setContent}
                          setPlainText={setPlainText}
                        />
                        <FormNote
                          text={
                            'ソースコードモードで編集した内容は、ソースコードモードをOFFにしないと反映しません。'
                          }
                        />
                      </Form.Item>
                    </Grid2>
                    <Grid1>
                      <FormLabelGrid
                        labelText={APPLICATION_PRODUCT_FORM_MENU}
                      ></FormLabelGrid>
                    </Grid1>
                    <Grid2>
                      <TreeWrapper>
                        <Tree
                          treeData={treeData}
                          setTreeData={setTreeData}
                          isLoading={isLoading}
                          showDropdown={showDropdown}
                          setShowDropdown={setShowDropdown}
                          width={158}
                          masterDoomWidth={194}
                        />
                      </TreeWrapper>
                    </Grid2>

                    <Grid2>
                      <FormFooterSubmit
                        isDisable={isDisable}
                        onCancel={handleCancel}
                        onDelete={handleDelete}
                        textOk={
                          isEdit
                            ? APPLICATION_PRODUCT_BUTTON_KEEP
                            : APPLICATION_PRODUCT_FORM_SUBMIT
                        }
                        textCancel={APPLICATION_PRODUCT_FORM_CANCEL}
                        textDelete={APPLICATION_PRODUCT_BUTTON_DELETE}
                        isDelete={isEdit}
                        isCancel={isCancel}
                      ></FormFooterSubmit>
                    </Grid2>
                  </GridForm>
                </Form>
              </Spin>
            </Container>
          </FormWrapper>
        </LayoutDefault>
      </Suspense>
    </>
  );
};

export default FormProduct;
