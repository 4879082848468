import React, { useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import useMultiLanguageNotificationList from 'page/Application/ManageNotificationList/NotificationList/hook/useMultiLanguageNotificationList';
import AntdTable2 from 'components/AntdTable2';
import { EditOutlined } from '@ant-design/icons';
import { MANAGE_NOTIFICATION_PATH, EDIT_PATH } from 'constant/appPath';
import { ButtonAction, GroupButtonAction } from 'style/styleComponent/styled';
import useCategoryNotifyMultiLanguage from 'hook/useNotifyCategoryMultiLanguage';
import useStatusNotifyMultiLanguage from 'hook/useStatusNotifyMultiLanguage';
import dayjs from 'dayjs';
import { DATE_FORMAT_LIST } from 'constant/notificationConstants';
import { Tooltip } from 'antd';
import { TooltipHide } from 'page/Application/ManageCompanyList/CompanyList/styled';

const TableData = ({
  dataRaw,
  isLoading,
  emptyText,
  setCurrentNotification,
  onChange,
  sortedInfo,
}) => {
  const navigate = useNavigate();
  const {
    NOTIFICATION_LIST_TABLE_TITLE_DISPLAYED_DATE,
    NOTIFICATION_LIST_TABLE_TITLE_TYPE,
    NOTIFICATION_LIST_TABLE_TITLE,
    NOTIFICATION_LIST_TABLE_TITLE_STATUS,
    NOTIFICATION_LIST_TABLE_TITLE_ACTION,
  } = useMultiLanguageNotificationList();
  const { NOTIFICATION_CATEGORY_FOR_LIST } = useCategoryNotifyMultiLanguage();
  const { STATUS_FOR_LIST } = useStatusNotifyMultiLanguage();

  const columns = useMemo(() => {
    const handleEdit = (notifyId) => {
      setCurrentNotification(null);
      navigate(`/${MANAGE_NOTIFICATION_PATH}/${EDIT_PATH}/${notifyId}`);
    };

    return [
      {
        key: 'displayedDate',
        title: (
          <Tooltip
            title={NOTIFICATION_LIST_TABLE_TITLE_DISPLAYED_DATE}
            placement={'topLeft'}
          >
            {NOTIFICATION_LIST_TABLE_TITLE_DISPLAYED_DATE}
          </Tooltip>
        ),
        dataIndex: 'displayedDate',
        ellipsis: true,
        align: 'left',
        width: '15%',
        render: (text) => {
          return (
            <TooltipHide>
              <Tooltip
                title={dayjs(text).format(DATE_FORMAT_LIST[1])}
                placement={'topLeft'}
              >
                {dayjs(text).format(DATE_FORMAT_LIST[1])}
              </Tooltip>
            </TooltipHide>
          );
        },
        showSorterTooltip: false,
        sorter: true,
        sortOrder:
          sortedInfo.columnKey === 'displayedDate' ? sortedInfo.order : null,
      },
      {
        key: 'category',
        title: (
          <Tooltip
            title={NOTIFICATION_LIST_TABLE_TITLE_TYPE}
            placement={'topLeft'}
          >
            {NOTIFICATION_LIST_TABLE_TITLE_TYPE}
          </Tooltip>
        ),
        dataIndex: 'category',
        ellipsis: true,
        align: 'left',
        width: '20%',
        render: (text) => {
          return (
            <TooltipHide>
              <Tooltip
                title={NOTIFICATION_CATEGORY_FOR_LIST[text]}
                placement={'topLeft'}
              >
                {NOTIFICATION_CATEGORY_FOR_LIST[text]}
              </Tooltip>
            </TooltipHide>
          );
        },
        showSorterTooltip: false,
        sorter: true,
        sortOrder:
          sortedInfo.columnKey === 'category' ? sortedInfo.order : null,
      },
      {
        key: 'title',
        title: (
          <Tooltip title={NOTIFICATION_LIST_TABLE_TITLE} placement={'topLeft'}>
            {NOTIFICATION_LIST_TABLE_TITLE}
          </Tooltip>
        ),
        dataIndex: 'title',
        ellipsis: true,
        align: 'left',
        showSorterTooltip: false,
        sorter: true,
        sortOrder: sortedInfo.columnKey === 'title' ? sortedInfo.order : null,
        render: (title) => (
          <TooltipHide>
            <Tooltip title={title} placement={'topLeft'}>
              {title}
            </Tooltip>
          </TooltipHide>
        ),
        sortOrder: sortedInfo.columnKey === 'title' ? sortedInfo.order : null,
      },
      {
        key: 'status',
        title: (
          <Tooltip
            title={NOTIFICATION_LIST_TABLE_TITLE_STATUS}
            placement={'topLeft'}
          >
            {NOTIFICATION_LIST_TABLE_TITLE_STATUS}
          </Tooltip>
        ),
        dataIndex: 'status',
        width: '10%',
        ellipsis: true,
        align: 'left',
        render: (text) => {
          return <div title={''}>{STATUS_FOR_LIST[text]}</div>;
        },
        showSorterTooltip: false,
        sorter: true,
        sortOrder: sortedInfo.columnKey === 'status' ? sortedInfo.order : null,
      },
      {
        key: 'notifyId',
        title: (
          <span style={{ paddingLeft: 32 }}>
            {NOTIFICATION_LIST_TABLE_TITLE_ACTION}
          </span>
        ),
        dataIndex: 'notifyId',
        render: (notifyId) => (
          <div key={notifyId} style={{ height: '32px', marginLeft: '8px' }}>
            <GroupButtonAction>
              <ButtonAction type="link" onClick={() => handleEdit(notifyId)}>
                <EditOutlined />
              </ButtonAction>
            </GroupButtonAction>
          </div>
        ),
        align: 'center',
        width: '20%',
      },
    ];
  }, [
    NOTIFICATION_LIST_TABLE_TITLE_DISPLAYED_DATE,
    sortedInfo.columnKey,
    sortedInfo.order,
    NOTIFICATION_LIST_TABLE_TITLE_TYPE,
    NOTIFICATION_LIST_TABLE_TITLE,
    NOTIFICATION_LIST_TABLE_TITLE_STATUS,
    NOTIFICATION_LIST_TABLE_TITLE_ACTION,
    setCurrentNotification,
    navigate,
    NOTIFICATION_CATEGORY_FOR_LIST,
    STATUS_FOR_LIST,
  ]);

  const dataSource = useMemo(() => {
    if (isLoading && !dataRaw) {
      return [];
    }
    return dataRaw?.list.map((item) => ({
      ...item,
      key: item.notifyId,
    }));
  }, [dataRaw, isLoading]);

  return (
    <AntdTable2
      height={532}
      columns={columns}
      dataSource={dataSource}
      isLoading={isLoading}
      emptyText={emptyText}
      onChange={onChange}
    ></AntdTable2>
  );
};

export default TableData;
