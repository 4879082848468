import React from 'react';
import { Button, Modal } from 'antd';
import { ExclamationCircleFilled } from '@ant-design/icons';
import {
  TitleStyle,
  FooterModal,
  ButtonGroup,
} from 'components/AntdModal2/styled';
import useMultiLanguageRegisterCompany from 'page/Application/ManageCompanyList/RegisterCompany/hook/useMultiLanguageRegisterCompany';

const AntdModal2 = ({
  isModalOpen,
  handleOk,
  handleCancel,
  loadingSubmit,
  title,
  content,
  width,
  total,
  totalContent,
}) => {
  const {
    REGISTER_COMPANY_SAVE,
    REGISTER_COMPANY_CANCEL,
    REGISTER_COMPANY_TOTAL,
  } = useMultiLanguageRegisterCompany();
  return (
    <Modal
      title={<TitleStyle>{title ?? 'Basic Modal'}</TitleStyle>}
      open={isModalOpen}
      onOk={handleOk}
      onCancel={handleCancel}
      centered={true}
      maskClosable={false}
      destroyOnClose={true}
      width={width ?? 430}
      icon={<ExclamationCircleFilled />}
      footer={[
        <FooterModal key="footerModal">
          {(totalContent ? `${totalContent}:` : REGISTER_COMPANY_TOTAL) +
            ' ' +
            total}
          <ButtonGroup>
            <Button
              key="submit"
              type="primary"
              loading={loadingSubmit}
              onClick={handleOk}
              style={{ minWidth: '72px' }}
            >
              <span> {REGISTER_COMPANY_SAVE}</span>
            </Button>
            <Button
              key="back"
              onClick={handleCancel}
              style={{
                marginLeft: '12px',
                width: '108px',
                background: 'white',
              }}
            >
              <span> {REGISTER_COMPANY_CANCEL}</span>
            </Button>
          </ButtonGroup>
        </FooterModal>,
      ]}
    >
      {content}
    </Modal>
  );
};
export default AntdModal2;
