import { useMemo } from 'react';

const useErrorCodeManageCompany = () => {
  const ERROR_CODE_MANAGE_COMPANY = useMemo(() => {
    return {
      '400_PARTNER_EXISTS': {
        message: 'パートナーが既に存在しました。',
        statusCode: 404,
        type: 'error',
      },
      '400_DOMAIN_NAME_HAS_BEEN_USED': {
        message: `がすでに登録されています。`,
        statusCode: 400,
        type: 'error',
      },
      '400_SALE_FORCE_ID_HAS_BEEN_USED': {
        message: 'IDがすでに登録されています。',
        statusCode: 400,
        type: 'error',
      },
      '400_EMAIL_WRONG_FORMAT': {
        message: '無効なメールアドレスです。形式を正しく入力してください。',
        statusCode: 400,
        type: 'error',
      },
      '400_DOMAIN_NAMES_ARE_DUPLICATE': {
        message: 'ドメインが重複しています。他のドメインを入力してください。',
        statusCode: 400,
        type: 'error',
      },
    };
  }, []);

  return {
    ERROR_CODE_MANAGE_COMPANY,
  };
};

export default useErrorCodeManageCompany;
