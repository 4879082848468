import React, { Suspense, useEffect, useMemo } from 'react';
import {
  getTopPageProduct,
  getDetailNotificationPublic,
} from 'api/product/topPage.api';
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';
import { Spin, Tooltip } from 'antd';
import { useRecoilValue } from 'recoil';
import { userInformation } from 'recoil/atom/authentication';
import { BreadCrumbDetail } from 'page/Application/ManageProductAndService/TopPageProduct/TopPageProductDetail/styled';
import Breadcrumb from 'components/Layout/ApplicationLayout/Breadcrumb/index';
import useCustomQuery from 'hook/useQuery/useCustomQuery';
import HeaderTopPage from 'page/Application/ManageProductAndService/TopPageProductPartnerAdmin/TopPagePartnerAdmin/component/HeaderTopPage';
import LayoutTopPage from 'page/Application/ManageProductAndService/TopPageProductPartnerAdmin/TopPagePartnerAdmin/component/LayoutTopPage';
import {
  TextWrapper,
  Text,
} from 'page/Application/ManageProductAndService/TopPageProductPartnerAdmin/TopPageProductDetailPartnerAdmin/component/ProductContent/styled';
import { CHECK_SPECIAL_CHARACTER } from 'constant/regex';
import { ERROR_MESSAGES_RESPONSE } from 'constant/topPageConstants';
import parse from 'html-react-parser';

const TopPageNotifyDetailPartnerAdmin = () => {
  const location = useLocation();
  const { useQueryAPI } = useCustomQuery();
  const { notifyId, productId } = useParams();
  const navigate = useNavigate();

  const { role } = useRecoilValue(userInformation);

  const {
    data: notificationDetail = [],
    isLoading,
    error,
  } = useQueryAPI({
    api: getDetailNotificationPublic,
    key: 'notificationDetail',
    params: {
      notifyId: notifyId,
      forProduct: !!productId,
    },
    enabled: !!notifyId,
  });

  const { data: productPageDetail = {}, error: errorProduct } = useQueryAPI({
    api: getTopPageProduct,
    key: 'productPageDetail',
    params: {
      productId: productId,
    },
    enabled: !!productId,
  });

  const breadCrumbNotifyProduct = useMemo(() => {
    return (
      productId !== undefined && {
        title: (
          <Tooltip
            placement={'topLeft'}
            title={productPageDetail?.data?.product?.productName}
          >
            <Link to={`/top-page/top-page-product/${productId}`}>
              <BreadCrumbDetail>
                {productPageDetail?.data?.product?.productName}
              </BreadCrumbDetail>
            </Link>
          </Tooltip>
        ),
      }
    );
  }, [productPageDetail?.data?.product?.productName, productId]);

  const breadCrumbNotify = useMemo(() => {
    if (productId !== undefined) {
      return {
        title: (
          <Tooltip placement={'topLeft'} title={'お知らせ一覧'}>
            <Link to={`/top-page/top-page-product/list-notify/${productId}`}>
              <BreadCrumbDetail>お知らせ一覧</BreadCrumbDetail>
            </Link>
          </Tooltip>
        ),
      };
    } else {
      return {
        title: (
          <Tooltip placement={'topLeft'} title={'お知らせ一覧'}>
            <Link to={`/top-page/list-notify`}>
              <BreadCrumbDetail>お知らせ一覧</BreadCrumbDetail>
            </Link>
          </Tooltip>
        ),
      };
    }
  }, [productId]);

  const listItemTopPageSA = useMemo(
    () => [
      {
        title: <Link to={'/manage-company-list'}>企業管理</Link>,
      },
      {
        title: <Link to={'/top-page'}>製品一覧</Link>,
      },
      breadCrumbNotifyProduct,
      location?.pathname?.includes('list-notify') && breadCrumbNotify,
      {
        title: (
          <Tooltip
            placement={'topLeft'}
            title={notificationDetail?.data?.title}
          >
            <BreadCrumbDetail>
              {notificationDetail?.data?.title}
            </BreadCrumbDetail>
          </Tooltip>
        ),
      },
    ],
    [
      breadCrumbNotifyProduct,
      notificationDetail?.data?.title,
      location,
      breadCrumbNotify,
    ],
  );

  const listItemTopPageSU = useMemo(
    () => [
      {
        title: <Link to={'/my-page'}>マイページ</Link>,
      },
      {
        title: <Link to={'/top-page'}>製品一覧</Link>,
      },
      breadCrumbNotifyProduct,
      location?.pathname?.includes('list-notify') && breadCrumbNotify,
      {
        title: (
          <Tooltip
            placement={'topLeft'}
            title={notificationDetail?.data?.title}
          >
            <BreadCrumbDetail>
              {notificationDetail?.data?.title}
            </BreadCrumbDetail>
          </Tooltip>
        ),
      },
    ],
    [
      breadCrumbNotifyProduct,
      notificationDetail?.data?.title,
      breadCrumbNotify,
      location?.pathname,
    ],
  );

  const listItemTopPagePartner = useMemo(() => {
    return [
      {
        title: <Link to={'/partner-page'}>パートナー専用ページ</Link>,
      },
      {
        title: <Link to={'/top-page'}>製品一覧</Link>,
      },
      breadCrumbNotifyProduct,
      location?.pathname?.includes('list-notify') && breadCrumbNotify,
      {
        title: (
          <Tooltip
            placement={'topLeft'}
            title={notificationDetail?.data?.title}
          >
            <BreadCrumbDetail>
              {notificationDetail?.data?.title}
            </BreadCrumbDetail>
          </Tooltip>
        ),
      },
    ];
  }, [
    breadCrumbNotifyProduct,
    notificationDetail?.data?.title,
    breadCrumbNotify,
    location?.pathname,
  ]);

  const listItemByRole = useMemo(() => {
    return {
      ROLE_SOLITON_ADMIN: listItemTopPageSA,
      ROLE_SOLITON_USER: listItemTopPageSU,
      ROLE_PARTNER_ADMIN: listItemTopPagePartner,
      ROLE_PARTNER_USER: listItemTopPagePartner,
    };
  }, [listItemTopPageSA, listItemTopPageSU, listItemTopPagePartner]);

  useEffect(() => {
    if (
      ERROR_MESSAGES_RESPONSE[error?.response?.data?.message] ||
      ERROR_MESSAGES_RESPONSE[errorProduct?.response?.data?.message] ||
      CHECK_SPECIAL_CHARACTER.test(notifyId)
    ) {
      navigate('/notify-not-found');
    }
  }, [
    error?.response?.data?.message,
    errorProduct?.response?.data?.message,
    navigate,
    notifyId,
  ]);

  return (
    <Suspense fallback={isLoading}>
      <Spin spinning={isLoading}>
        <Breadcrumb listItem={listItemByRole[role]} titlePage="製品一覧" />
        <LayoutTopPage>
          <HeaderTopPage
            titleScreen={notificationDetail?.data?.title}
            isDisplayNotifyList={false}
            isNotifyDetail={true}
          />
          <div style={{ marginTop: '20px' }} />
          <TextWrapper>
            <Text>
              {notificationDetail?.data?.content &&
                parse(notificationDetail?.data?.content)}
            </Text>
          </TextWrapper>
        </LayoutTopPage>
      </Spin>
    </Suspense>
  );
};

export default TopPageNotifyDetailPartnerAdmin;
