import { message } from 'antd';
import { useRecoilValue } from 'recoil';
import { network } from 'recoil/atom/network';

const UseErrorNetworkMessage = () => {
  const isOnline = useRecoilValue(network);
  const [messageErrorNetwork, contextErrorNetWork] = message.useMessage();
  const errorNetWork = () => {
    messageErrorNetwork
      .open({
        type: 'error',
        content:
          '問題が発生しました。インターネット接続を確認して、もう一度やり直してください。',
      })
      .then();
  };
  return { isOnline, errorNetWork, contextErrorNetWork };
};

export default UseErrorNetworkMessage;
