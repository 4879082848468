import React, { useState } from 'react';
import {
  ButtonToggle,
  HeaderWrapper,
  TitleSection,
} from 'page/Application/ManageProductAndService/TopPageProduct/TopPage/component/HeaderTopPageCollapse/styled';
import { CaretDownOutlined } from '@ant-design/icons';

const HeaderTopPageCollapse = ({
  titleScreen,
  handleToggleTable,
  type,
  collapse,
}) => {
  const [toggle, setIsToggle] = useState(false);

  const handleToggleShowHide = () => {
    setIsToggle(!toggle);
    handleToggleTable(type);
  };

  return (
    <HeaderWrapper>
      <TitleSection>{titleScreen}</TitleSection>
      {collapse && (
        <ButtonToggle
          onClick={handleToggleShowHide}
          className={toggle ? 'hide' : 'show'}
        >
          <CaretDownOutlined />
        </ButtonToggle>
      )}
    </HeaderWrapper>
  );
};

export default HeaderTopPageCollapse;
