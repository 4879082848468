import React, { useEffect, useMemo, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { downloadNotifyFile } from 'api/storage/storage.api';
import AntdTable from 'components/AntdTable';
import { DATE_FORMAT_LIST } from 'constant/notificationConstants';
import dayjs from 'dayjs';
import useCustomQuery from 'hook/useQuery/useCustomQuery';
import LinkNotify from 'page/Application/ManageProductAndService/TopPageProductPartnerUser/component/LinkNotify/index';
import { handleNavigate } from 'page/Application/ManageProductAndService/utils/navigate/navigateNotifyTopPage';
import { useRecoilValue } from 'recoil';
import { network } from 'recoil/atom/network';

const TableNotifyTopPage = ({
  errorNetWork,
  dataNotify,
  isLoading,
  onChange,
  isNotifyOnly,
  productId,
}) => {
  const location = useLocation();
  const [highLightedRows, setHighLightedRows] = useState(0);
  const navigate = useNavigate();
  const { useQueryAPI } = useCustomQuery();
  const [notifyId, setNotifyId] = useState(null);
  const isOnline = useRecoilValue(network);
  const { refetch } = useQueryAPI({
    api: downloadNotifyFile,
    params: {
      notifyId: notifyId,
    },
    options: {
      refetchOnWindowFocus: false,
    },
    enabled: !!notifyId,
  });
  const columns = useMemo(() => {
    return [
      {
        title: 'お知らせ',
        render: (record) => {
          return (
            <>
              <div>
                {record?.destinationType === 'NO_DETAILS' ? (
                  <p style={{ wordBreak: 'break-word' }}>
                    {record.titleWithCategory}
                  </p>
                ) : (
                  <LinkNotify
                    text={record.titleWithCategory}
                    type={record?.destinationType}
                    handleNavigate={() =>
                      handleNavigate(
                        record,
                        navigate,
                        setNotifyId,
                        refetch,
                        productId,
                      ).then()
                    }
                  />
                )}
              </div>
              <p>{dayjs(record.displayDate).format(DATE_FORMAT_LIST[1])}</p>
            </>
          );
        },
        responsive: ['xs'],
      },

      {
        key: 'displayedDate',
        dataIndex: 'displayedDate',
        ellipsis: true,
        width: '140px',
        align: 'left',
        render: (text) => {
          return (
            <div title={''} style={{ paddingLeft: 4 }}>
              {dayjs(text).format(DATE_FORMAT_LIST[1])}
            </div>
          );
        },
        showSorterTooltip: false,
        sorter: true,
        responsive: ['sm'],
      },
      {
        key: 'titleWithCategory',
        title: 'お知らせ',
        dataIndex: 'titleWithCategory',
        align: 'left',
        showSorterTooltip: false,
        sorter: true,
        responsive: ['sm'],
        render: (text, record) => {
          return record?.destinationType === 'NO_DETAILS' ? (
            <span>{text}</span>
          ) : (
            <LinkNotify
              text={text}
              type={record?.destinationType}
              handleNavigate={() => {
                if (isOnline) {
                  handleNavigate(
                    record,
                    navigate,
                    setNotifyId,
                    refetch,
                    productId,
                    location?.pathname,
                  ).then();
                } else {
                  errorNetWork();
                }
              }}
            />
          );
        },
      },
    ];
  }, [
    navigate,
    refetch,
    productId,
    isOnline,
    errorNetWork,
    location?.pathname,
  ]);

  const dataSource = useMemo(() => {
    if (isLoading || !dataNotify) {
      return [];
    }
    return dataNotify?.map((item) => ({
      ...item,
      key: item?.notifyId,
    }));
  }, [dataNotify, isLoading]);

  useEffect(() => {
    if (!isLoading && dataNotify) {
      const count = dataNotify?.filter(
        (item) => item?.isPinned === true,
      ).length;
      setHighLightedRows(() => {
        return count ? count : 0;
      });
    }
  }, [dataNotify, isLoading]);

  return (
    <AntdTable
      columns={columns}
      dataSource={dataSource}
      onChange={onChange}
      showHeader={false}
      highLightedRows={!isNotifyOnly ? highLightedRows : false}
      isBoldBottomBorder={true}
    />
  );
};

export default TableNotifyTopPage;
