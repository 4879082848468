import React, { useState } from 'react';
import { useRecoilValue } from 'recoil';
import { network } from 'recoil/atom/network';
import useUploadFileMultiLanguage from 'hook/useUploadFileMultiLanguage';
import { InboxOutlined } from '@ant-design/icons';
import { Form } from 'antd';
import {
  deleteS3FileWithPreSignedUrl,
  getPreSignedUrlToUploadOrDownload,
  uploadFileToS3WithPreSignedUrl,
} from 'api/storage/storage.api';
import FormMessage from 'components/Form/FormMessage/index';
import {
  NOTIFICATION_FILE_EXTENSION,
  NOTIFICATION_FILE_TYPE,
} from 'constant/notificationConstants';
import useCustomMutate from 'hook/useQuery/useCustomMutate';
import useCustomQuery from 'hook/useQuery/useCustomQuery';
import { CustomUpload } from './style';

const FormFileUpload = ({
  name,
  multipleUpload,
  isDisabled,
  maxCount,
  text,
  isPreview,
  form,
  idFile,
  setIdFile,
  hint,
  setIsDisable,
  setIsCancel,
  setIsPreview,
  fileObject,
  setFileObject,
}) => {
  const [isShowFile, setIsShowFile] = useState(true);
  const [httpMethod, setHttpMethod] = useState('');
  const {
    UPLOAD_FILE_SUCCESS,
    UPLOAD_FILE_FAILURE,
    UPLOAD_FILE_ALLOWED_EXTENSION,
    UPLOAD_FILE_MAX_SIZE,
    UPLOAD_FILE_WITHOUT_INTERNET,
  } = useUploadFileMultiLanguage();

  const status = useRecoilValue(network);

  const { useQueryAPI } = useCustomQuery();
  const { useMutationAPI } = useCustomMutate();

  const beforeUpload = (file) => {
    const fileType = NOTIFICATION_FILE_TYPE[file.type];
    const fileExtension =
      NOTIFICATION_FILE_EXTENSION[file.name?.split('.')?.pop().toLowerCase()];
    if (!isPreview && file) {
      if (!status) {
        return false;
      }
      if (!fileType && !fileExtension) {
        return false;
      } else {
        if (file.size > fileType?.size || file.size > fileExtension?.size) {
          return false;
        }
      }
    }
    return true;
  };

  const { refetch: refetchPreSignedUrl } = useQueryAPI({
    api: getPreSignedUrlToUploadOrDownload,
    params: {
      fileName: fileObject?.keyFileName,
      httpMethod: httpMethod,
    },
    options: {
      refetchOnWindowFocus: false,
    },
    enabled: !!fileObject?.keyFileName,
  });

  const { mutateAsync: mutateAsyncUpload } = useMutationAPI({
    api: uploadFileToS3WithPreSignedUrl,
    messageSuccess: UPLOAD_FILE_SUCCESS,
    messageError: UPLOAD_FILE_FAILURE,
  });

  const { mutate: deleteFileMutate } = useMutationAPI({
    api: deleteS3FileWithPreSignedUrl,
    messageSuccess: 'ファイルが削除されました。',
    messageError: 'ファイルの削除に失敗しました。',
  });

  const normFile = (e) => {
    if (Array.isArray(e)) {
      return e;
    }
    setIdFile(null);
    return e?.fileList;
  };

  const options = (onProgress, file) => ({
    onUploadProgress: (progress) => {
      const { loaded, total } = progress;
      onProgress(
        {
          percent: Math.round((loaded / total) * 100),
        },
        file,
      );
    },
    headers: {
      'Content-Type': file?.type,
    },
  });

  return (
    <Form.Item
      name={name}
      valuePropName={'fileList'}
      getValueFromEvent={normFile}
      required={false}
      rules={[
        {
          validator: (_, value) => {
            if (!isPreview && value && value.length > 0) {
              const fileType = NOTIFICATION_FILE_TYPE[value[0].type];
              const fileExtension =
                NOTIFICATION_FILE_EXTENSION[
                  value[0].name?.split('.')?.pop().toLowerCase()
                ];

              if (!status) {
                form.setFieldValue('file', []);
                return Promise.reject(
                  <FormMessage
                    text1={UPLOAD_FILE_WITHOUT_INTERNET}
                    marginBottom={'12px'}
                  />,
                );
              }
              if (!fileType && !fileExtension) {
                form.setFieldValue('file', []);
                return Promise.reject(
                  <FormMessage
                    text1={UPLOAD_FILE_ALLOWED_EXTENSION}
                    marginBottom={'12px'}
                  />,
                );
              } else {
                if (
                  value[0].size > fileType?.size ||
                  value[0].size > fileExtension?.size
                ) {
                  form.setFieldValue('file', []);
                  setIsShowFile(false);
                  return Promise.reject(
                    <FormMessage
                      text1={UPLOAD_FILE_MAX_SIZE}
                      marginBottom={'12px'}
                    />,
                  );
                }
              }
            }
            setIsShowFile(true);
            return Promise.resolve();
          },
        },
      ]}
    >
      <CustomUpload
        multipleUpload={multipleUpload}
        disabled={isDisabled}
        maxCount={maxCount}
        showUploadList={isShowFile}
        progress={{
          strokeColor: {
            '0%': '#108ee9',
            '100%': '#87d068',
          },
          size: 'small',
          format: (percent) => percent && `${parseFloat(percent.toFixed(2))}%`,
        }}
        beforeUpload={beforeUpload}
        onRemove={async () => {
          if (!idFile) {
            await setHttpMethod('DELETE');
            refetchPreSignedUrl().then((value) => {
              deleteFileMutate({
                url: value?.data,
              });
            });
          }
        }}
        customRequest={async ({ file, onError, onSuccess, onProgress }) => {
          setIsCancel(true);
          setIsPreview(true);
          setIsDisable(true);

          const extension = file?.name?.substring(
            file?.name?.lastIndexOf('.'),
            file?.name?.length,
          );

          const modifiedFileName = file?.name?.replace(
            extension,
            extension?.toLowerCase(),
          );

          await setFileObject({
            fileName: modifiedFileName,
            keyFileName: file?.uid,
            size: file?.size,
            contentType: file?.type,
          });

          await setHttpMethod('PUT');

          refetchPreSignedUrl().then((value) => {
            mutateAsyncUpload({
              url: value?.data,
              file: file,
              options: options(onProgress, file),
            })
              .then((value) => {
                onSuccess(value, file);
                setIsCancel(false);
                setIsPreview(false);
                setIsDisable(false);
              })
              .catch((error) => {
                onError(error);
                setIsCancel(false);
                setIsPreview(false);
                setIsDisable(false);
              });
          });
        }}
      >
        <p className="ant-upload-drag-icon">
          <InboxOutlined />
        </p>
        <p>{text}</p>
        <p className={'ant-upload-hint'}>{hint}</p>
      </CustomUpload>
    </Form.Item>
  );
};
export default FormFileUpload;
