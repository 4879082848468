import { useIntl } from 'react-intl';

const useMultiLanguagePreviewNotification = () => {
  const intl = useIntl();

  const PREVIEW_NOTIFICATION_TITLE = intl.formatMessage({
    id: 'application.previewNotification.title',
  });

  const PREVIEW_NOTIFICATION_MESSAGE_VIEWING = intl.formatMessage({
    id: 'application.previewNotification.messageViewing',
  });

  return {
    PREVIEW_NOTIFICATION_TITLE,
    PREVIEW_NOTIFICATION_MESSAGE_VIEWING,
  };
};
export default useMultiLanguagePreviewNotification;
