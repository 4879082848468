import React, { useEffect, useState } from 'react';
import { Drawer } from 'antd';
import { useRecoilState } from 'recoil';
import { drawerOpen } from 'recoil/atom/sidebarDrawer';
import Header from 'components/Layout/ApplicationLayout/SidebarMenu/Mobile/Header';
import Menu from 'components/Layout/ApplicationLayout/SidebarMenu/Mobile/Menu/';
import Footer from 'components/Layout/ApplicationLayout/SidebarMenu/Mobile/Footer';
import 'components/Layout/ApplicationLayout/SidebarMenu/Mobile/style/style.scss';

const SidebarMobile = () => {
  const [open, setOpen] = useRecoilState(drawerOpen);
  const [windowDimension, detectHW] = useState({
    winWidth: window.innerWidth,
    winHeight: window.innerHeight,
  });

  const detectSize = () => {
    detectHW({
      winWidth: window.innerWidth,
      winHeight: window.innerHeight,
    });
  };

  const onClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    window.addEventListener('resize', detectSize);
    return () => {
      window.removeEventListener('resize', detectSize);
    };
  }, [windowDimension]);

  useEffect(() => {
    if (windowDimension.winWidth > 1024) {
      onClose();
    }
  }, [windowDimension]);

  return (
    <Drawer
      className="sidebar-drawer"
      title={<Header />}
      placement={'left'}
      closable={false}
      onClose={onClose}
      open={open}
      key={'left'}
      destroyOnClose={true}
      footer={<Footer />}
    >
      <Menu />
    </Drawer>
  );
};

export default SidebarMobile;
