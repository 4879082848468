import { solitonUserMenu } from 'constant/menuData/soliton/userMenu';
import { solitonAdminMenu } from 'constant/menuData/soliton/adminMenu';
import { partnerAdminMenu } from 'constant/menuData/partner/adminMenu';
import { partnerUserMenu } from 'constant/menuData/partner/userMenu';

const menuData = {
  ROLE_SOLITON_ADMIN: solitonAdminMenu,
  ROLE_SOLITON_USER: solitonUserMenu,
  ROLE_PARTNER_ADMIN: partnerAdminMenu,
  ROLE_PARTNER_USER: partnerUserMenu,
};

export { menuData };
