import DetailNotifySolitonUser from 'page/Application/ManageProductAndService/TopPageProductSolitonUser/DetailNotifySolitonUser';
import TopPageProductDetailSolitonUser from 'page/Application/ManageProductAndService/TopPageProductSolitonUser/TopPageProductDetailSolitonUser';
import TopPageProductSuperDetailSolitonUser from 'page/Application/ManageProductAndService/TopPageProductSolitonUser/TopPageProductSuperDetailSolitonUser';
import TopPageSolitonUser from 'page/Application/ManageProductAndService/TopPageProductSolitonUser/TopPageSolitonUser';
import React from 'react';
import { Outlet } from 'react-router-dom';
import useSetTitlePage from "../../../../hook/useSetTitlePage";

const TopPageProductPartnerUser = () => {
  useSetTitlePage('製品一覧');

  return <Outlet />;
};

export default TopPageProductPartnerUser;

export {
  DetailNotifySolitonUser,
  TopPageProductDetailSolitonUser,
  TopPageProductSuperDetailSolitonUser,
  TopPageSolitonUser,
};
