import { useMemo } from 'react';

const useErrorCodeManageProduct = () => {
  const ERROR_CODE_MANAGE_PRODUCT = useMemo(() => {
    return {
      '400_ARGUMENT_IS_NOT_VALID': {
        message: '入力データを正しく渡していません。',
        statusCode: 400,
        type: 'error',
      },
      '400_THIS_FILE_IS_NOT_AN_IMAGE': {
        message: 'アップロードファイルの形式が無効である。',
        statusCode: 400,
        type: 'error',
      },
      '404_PRODUCT_NOT_FOUND': {
        message: '製品が見つかりませんでした。',
        statusCode: 400,
        type: 'error',
      },
      '400_PRODUCT_NAME_EXISTED': {
        message: '重複するプロダクト名があります。',
        statusCode: 400,
        type: 'error',
      },
    };
  }, []);
  return {
    ERROR_CODE_MANAGE_PRODUCT,
  };
};

export default useErrorCodeManageProduct;
