import React, { useEffect, useMemo } from 'react';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import { useRecoilState, useRecoilValue, useResetRecoilState } from 'recoil';
import ApplicationLayout from 'components/Layout/ApplicationLayout';
import PasswordExpire from 'page/Application/PasswordExpire';
import { network } from 'recoil/atom/network';
import { notification } from 'antd';
import {
  authenticationToken,
  passwordExpire,
  userInformation,
} from 'recoil/atom/authentication';
import { filterContent } from 'recoil/atom/filterContent';

const Application = () => {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const { role } = useRecoilValue(userInformation);
  const authorization = useRecoilValue(authenticationToken);
  const [showChangePassword, setShowChangePassword] =
    useRecoilState(passwordExpire);
  const resetFilterContent = useResetRecoilState(filterContent);
  const [api, contextHolder] = notification.useNotification();
  const [, setIsOnline] = useRecoilState(network);

  useEffect(() => {
    if (authorization.passwordExpiredRemainingDay <= 14) {
      // thoi gian mat khau < 14 ngay
      setShowChangePassword(true); // vao man hinh doi mat khau
    } else {
      setShowChangePassword(false); // user an bo qua, chuyen vao man hinh trang chu
    }
  }, [authorization.passwordExpiredRemainingDay, setShowChangePassword]);

  // define default value menu selected
  const checkRole = useMemo(() => {
    return {
      ROLE_PARTNER_USER: {
        path: `/partner-page`,
      },
      ROLE_PARTNER_ADMIN: {
        path: `/partner-page`,
      },
      ROLE_SOLITON_USER: {
        path: '/my-page',
      },
      ROLE_SOLITON_ADMIN: {
        path: '/manage-company-list',
      },
    };
  }, []);

  useEffect(() => {
    if (pathname === '/') {
      navigate(checkRole[role].path);
    }
    resetFilterContent();
  }, [pathname, role, checkRole, resetFilterContent, navigate]);

  useEffect(() => {
    const offlineNotification = () => {
      api.warning({
        message:
          '問題が発生しました。インターネット接続を確認して、もう一度やり直してください。',
        placement: 'bottomLeft',
      });
    };

    const onlineNotification = () => {
      api.success({
        message: 'インターネット接続が復元されました。',
        placement: 'bottomLeft',
      });
    };
    window.addEventListener('offline', () => {
      offlineNotification();
      setIsOnline(false);
    });
    window.addEventListener('online', () => {
      onlineNotification();
      setIsOnline(true);
    });
  }, [api, setIsOnline]);

  if (showChangePassword) {
    return <PasswordExpire />;
  } else {
    return (
      <ApplicationLayout>
        {contextHolder}
        <Outlet />
      </ApplicationLayout>
    );
  }
};

export default Application;
