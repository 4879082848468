import React from 'react';
import { LabelFrom } from '../styled';

const FormLabelGrid = ({ labelText, required, textAlign, title }) => {
  return (
    <LabelFrom style={{ textAlign: textAlign ?? 'right' }}>
      <span title={title}>{labelText}</span>
      {required && <span className="mark">*</span>}
    </LabelFrom>
  );
};

export default FormLabelGrid;
