import React from 'react';
import { InputStyled } from 'components/Form2/styled';
import { MAX_LENGTH_TEXT } from 'constant/inputMaxlength';
import { CHECK_CHARACTER, CHECK_LATIN_JAPANESE_SPACE } from 'constant/regex';
import LabelForm from 'components/Form/LabelForm';
import FormMessage from 'components/Form/FormMessage';
import FormItem from 'components/Form2/FormItem';
import useMultiLanguageRegisterAccount from 'page/Application/ManageAccountSoliton/RegisterAccountSoliton/hook/useMultiLanguageRegisterAccount';

const FormValidateTextOnly = ({
  maxLength,
  disabled,
  name,
  label,
  required,
  placeholder,
  textRequired,
  textValidate,
}) => {
  const {
    REGISTER_ACCOUNT_INPUT_TYPE_VALIDATION,
    REGISTER_ACCOUNT_CATEGORY_ENTRY_REQUIRED,
  } = useMultiLanguageRegisterAccount();
  return (
    <FormItem
      name={name}
      label={label ? <LabelForm text={'form label validate text'} /> : false}
      rules={[
        {
          required: required,
          message: (
            <FormMessage
              text1={
                textRequired
                  ? textRequired
                  : REGISTER_ACCOUNT_CATEGORY_ENTRY_REQUIRED
              }
              marginBottom={'12px'}
            />
          ),
        },
        () => ({
          validator(_, value) {
            if (
              value &&
              (CHECK_CHARACTER === null ||
                !CHECK_LATIN_JAPANESE_SPACE.test(value) || // check japanese, latin
                // value.trim() === '' || // check blank
                (value &&
                  [...value].some((char) => char.codePointAt(0) > 65535))) // check 4-byte characters
            ) {
              return Promise.reject(
                <FormMessage
                  text1={
                    textValidate
                      ? textValidate
                      : REGISTER_ACCOUNT_INPUT_TYPE_VALIDATION
                  }
                  marginBottom={'12px'}
                />,
              );
            }
            return Promise.resolve();
          },
        }),
      ]}
    >
      <InputStyled
        size="large"
        type="text"
        disabled={disabled}
        maxLength={maxLength ? maxLength : MAX_LENGTH_TEXT}
        placeholder={placeholder}
      />
    </FormItem>
  );
};

export default FormValidateTextOnly;
