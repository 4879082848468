import React, { useMemo } from 'react';
import { BackgroundAuthentication } from 'page/Authentication/styled';
import {
  ContainerPrivacy,
  ContentPrivacy,
  TittlePrivacy,
  FooterWrapper,
  FooterText,
  TitlePrivacy,
  Container,
  AuthSidebarPrivacy,
  BoxPrivacy,
  // TextOnSidebarPrivacy,
  LogoPrivacy,
} from 'page/Privacy/styled';
import useSetTitlePage from 'hook/useSetTitlePage';
import { Row, Col } from 'antd';
import Logo from 'components/Logo';

const Privacy = () => {
  useSetTitlePage('個人情報の取り扱いについて');
  const dataRaw = useMemo(() => {
    return [
      {
        title: '1．事業者の氏名または名称',
        content: '株式会社ソリトンシステムズ',
      },
      {
        title: '2．個人情報保護管理者',
        content:
          '株式会社ソリトンシステムズ　代表取締役社長\n' +
          '連絡先　株式会社ソリトンシステムズ　個人情報保護担当\n' +
          '〒160-0022　東京都新宿区新宿　2-4-3\n' +
          'E-mail：privacy@soliton.co.jp',
      },
      {
        title: '3．利用目的',
        content:
          '取得した個人情報は以下目的のために利用します。\n' +
          '・当社製品に関する情報のご案内のため\n' +
          '・セミナー・イベントの企画、実施およびその運営に関する案内、情報提供のため\n' +
          '\n' +
          'また、取得したページ閲覧履歴等の分析を行い以下目的のために利用します。\n' +
          '・マーケティング施策の効果測定のため',
      },
      {
        title: '4．個人情報の第三者提供について',
        content:
          'ご入力いただいた個人情報は、ご本人の同意がある場合または法令に基づく場合を除き、第三者に提供することはありません。',
      },
      {
        title: '5．個人情報の取り扱い委託について',
        content:
          'ご入力いただいた個人情報の全部または一部を委託することはありません。',
      },
      {
        title: '6．開示対象個人情報の開示等およびお問い合わせ窓口について',
        content:
          'ご本人からの求めがあれば、ご入力いただいた個人情報の利用目的の通知、開示、内容の訂正、追加または 削除、利用の停止、消去に応じます。\n' +
          'お問い合わせは以下にお願いします。\n' +
          '株式会社ソリトンシステムズ\n' +
          '個人情報保護担当\n' +
          'privacy@soliton.co.jp',
      },
      {
        title: '7．個人情報を入力するにあたっての注意事項',
        content:
          'アカウント登録フォームの必須項目を正しく入力いただきませんと「3. 利用目的」で記載した目的でのご案内を差し上げることができない場合があります。',
      },
      {
        title: '8．本サイトにおける個人情報の取得',
        content:
          '当サイトでは、サービス向上および利用実態の把握を目的としたデータの収集手段としてクッキー（Cookie）を利用しています。本サイトにご登録いただいた個人を識別できる情報と収集したページ閲覧履歴等の紐づけを行い、分析を行う場合があります。\n' +
          '分析した情報は、「3．利用目的」で記載した目的に限り使用します。',
      },
    ];
  }, []);
  return (
    <BackgroundAuthentication className="background-authentication">
      <div className="container">
        <Row className="row">
          <Col xs={0} sm={0} md={0} lg={0} xl={11}>
            <AuthSidebarPrivacy>
              {/*<TextOnSidebarPrivacy>*/}
              {/*  <span>we are</span>*/}
              {/*  <br />*/}
              {/*  soliton*/}
              {/*</TextOnSidebarPrivacy>*/}
            </AuthSidebarPrivacy>
          </Col>
          <Col xs={24} sm={24} md={24} lg={24} xl={13}>
            <Container>
              <LogoPrivacy>
                <Logo />
              </LogoPrivacy>
              <TitlePrivacy>
                個人情報の取り扱いについて
                <br />
                （必ずお読み下さい）
              </TitlePrivacy>
              <BoxPrivacy>
                <ContainerPrivacy>
                  {dataRaw?.map((item) => {
                    return (
                      <div key={item.title} style={{ marginBottom: 20 }}>
                        <TittlePrivacy>{item.title}</TittlePrivacy>
                        <ContentPrivacy>{item.content}</ContentPrivacy>
                      </div>
                    );
                  })}
                  <ContentPrivacy style={{ marginTop: 30 }}>
                    制定日：　2024年4月11日
                  </ContentPrivacy>
                </ContainerPrivacy>
              </BoxPrivacy>
              <FooterWrapper>
                <FooterText>
                  Copyright(C) Soliton Systems K.K., All rights reserved.
                </FooterText>
              </FooterWrapper>
            </Container>
          </Col>
        </Row>
      </div>
    </BackgroundAuthentication>
  );
};
export default Privacy;
