import styled from 'styled-components';

export const Text = styled.span`
  color: VAR(--FORM-LABEL-default);
  word-wrap: break-word;
  line-height: 20px;
  font-family: inherit;
  white-space: nowrap;

  ol {
    list-style: decimal inside;
    padding-left: 20px;
  }

  ul {
    list-style: initial;
    padding-left: 10px;
    margin-left: 10px;
  }
  li {
    word-wrap: normal;
  }
`;

export const TextWrapper = styled.div`
  width: auto;
  overflow-y: hidden;
  overflow-x: scroll;
  line-height: 20px;
  margin: 0 20px;
  padding-top: ${(props) => (!props?.image ? '0px' : '20px')};
  color: VAR(--FORM-LABEL-default);
  word-wrap: break-word;

  &::-webkit-scrollbar {
    width: 2px;
    height: 6px;
    cursor: grab;
  }

  &::-webkit-scrollbar-track {
    background: transparent;
  }

  &::-webkit-scrollbar-thumb {
    background: #ecececff;
    border-radius: 2px;
  }

  &::-webkit-scrollbar-thumb:hover {
    background: #c2c2c2ff;
  }
  ol {
    color: #656464;
    list-style: decimal;
  }

  ul {
    color: #656464;
    list-style: initial;
  }
  li {
    list-style-position: inside;
    word-wrap: normal;
  }
`;
