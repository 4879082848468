import styled from 'styled-components';
import BackgroundAuthentication1 from 'assets/images/background-authentication-1.jpg';
import { Form, Button } from 'antd';
export const BackgroundAuthentication = styled.div`
  width: 100%;
  height: 100dvh;
  background-image: url(${BackgroundAuthentication1});
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  @media all and (min-width: 0px) and (max-width: 430px) {
    background: unset;
  }
`;
export const Container = styled.div``;

export const AuthSidebar = styled.div`
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  position: relative;
`;

export const TextOnSidebar = styled.div`
  position: absolute;
  font-family: montserrat-black, sans-serif;
  color: white;
  text-align: center;
  text-transform: uppercase;
  top: 236px;
  left: 30px;
  font-size: 90px;
  @media all and (min-width: 0px) and (max-width: 320px) {
    display: none;
  }
  @media all and (min-width: 321px) and (max-width: 430px) {
    display: none;
  }
  @media all and (min-width: 431px) and (max-width: 768px) {
    display: none;
    top: 500px;
    left: 30px;
  }
  @media all and (min-width: 769px) and (max-width: 1024px) {
    top: 500px;
    left: 30px;
  }
  @media all and (min-width: 1025px) and (max-width: 1280px) {
    top: 500px;
    left: 30px;
  }
  @media all and (min-width: 1281px) and (max-width: 1440px) {
    top: 500px;
    left: 30px;
  }
`;

export const TitleAuthentication = styled.p`
  font-family: 'inter-semibold', sans-serif;
  font-weight: 600;
  text-align: center;
  line-height: 1.5;
  color: var(--PRIMARY-default);
`;
export const FormContainer = styled.div``;
export const FormItemStyled = styled(Form.Item)`
  margin-bottom: ${(props) =>
    props?.marginbottom ? props.marginbottom : '30px'};
`;

export const ButtonSubmit = styled(Button)`
  font-family: 'inter-regular', sans-serif;
  box-shadow: none;
`;
