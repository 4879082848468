import { Divider, Spin } from 'antd';
import { getTopPageMenuProduct } from 'api/product/topPage.api';
import Breadcrumb from 'components/Layout/ApplicationLayout/Breadcrumb';
import Link from 'components/Link/index';
import dayjs from 'dayjs';
import useCustomQuery from 'hook/useQuery/useCustomQuery';
import HeaderTopPageOnlyTitle from 'page/Application/ManageProductAndService/TopPageProduct/TopPage/component/HeaderTopPageOnlyTitle';
import LayoutTopPage from 'page/Application/ManageProductAndService/TopPageProduct/TopPage/component/LayoutTopPage';
import ProductContent from 'page/Application/ManageProductAndService/TopPageProduct/TopPageProductDetail/component/ProductContent';
import {
  BodyProductDetail,
  ButtonStyle,
  FormContent,
  FormContentAndDate,
  FormDate,
  FormDetailTitle,
  FormDownloadFile,
  FormHashCode,
  TableListProductDetail,
  TitleFormProductDetail,
} from 'page/Application/ManageProductAndService/TopPageProduct/TopPageProductDetail/styled';
import React, { useEffect, useMemo, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { downloadContentFile } from 'api/storage/storage.api';
import ShowMoreText from 'react-show-more-text';
import parse from 'html-react-parser';

const TopPageProductSuperDetailPartnerUser = () => {
  const { state } = useLocation();
  const { useQueryAPI } = useCustomQuery();
  const navigate = useNavigate();
  const [fileDetail, setFileDetail] = useState({});
  const [detailIdDisableDownload, setDetailIdsDisableDownload] = useState(null);
  const listItemTopPage = useMemo(() => {
    return [
      {
        title: (
          <Link
            size={14}
            text={'パートナー専用ページ'}
            to={'/partner-page'}
          ></Link>
        ),
      },
      {
        title: <Link size={14} text={'製品一覧'} to={'/top-page'}></Link>,
      },
      {
        title: state?.dataProduct?.data?.product?.productName ?? '',
      },
    ];
  }, [state?.dataProduct]);

  const { data: productDetail = [], isLoading } = useQueryAPI({
    api: getTopPageMenuProduct,
    key: 'productDetail',
    params: {
      menuId: state?.menuId,
    },
  });

  const {
    data: downloadFileData = {},
    isSuccess,
    isError,
  } = useQueryAPI({
    api: downloadContentFile,
    params: {
      detailId: fileDetail?.detailId,
    },
    options: {
      refetchOnWindowFocus: false,
    },
    enabled: !!fileDetail?.detailId,
  });
  const handleGoToViewPage = (index) => {
    navigate(`detail/${productDetail?.data?.listDetail[index]?.detailId}`, {
      state: {
        dataProduct: state?.dataProduct,
        dataDetail: productDetail?.data?.listDetail[index],
        breadCrumb: {
          name: 'パートナー専用ページ',
          url: `/partner-page`,
        },
      },
    });
  };
  const handleMoveToSuperChildDetailProduct = (menuId, menuName) => {
    navigate(`/top-page/top-page-detail-product/${menuId}`, {
      state: {
        dataProduct: state?.dataProduct,
        menuId: menuId,
        menuName: menuName,
      },
    });
  };
  const handleDownLoad = (fileName, detailId) => {
    setFileDetail({
      detailId: detailId,
      fileName: fileName,
    });
    setDetailIdsDisableDownload(detailId);
  };
  useEffect(() => {
    if (isSuccess) {
      window.open(downloadFileData, '_self');
    }
    if (isSuccess || isError) {
      setTimeout(() => setDetailIdsDisableDownload(null), 1000);
    }
  }, [downloadFileData, fileDetail?.fileName, isError, isSuccess]);
  return (
    <Spin spinning={isLoading}>
      <Breadcrumb
        listItem={listItemTopPage}
        titlePage={state?.dataProduct?.data?.product?.productName}
      />
      <LayoutTopPage>
        <ProductContent
          isLoading={isLoading}
          dataNotify={state?.dataProduct?.data}
        ></ProductContent>
      </LayoutTopPage>
      <LayoutTopPage>
        <HeaderTopPageOnlyTitle titleScreen={state?.menuName} />
        <BodyProductDetail>
          <div style={{ padding: '0 20px' }}>
            {productDetail?.data?.menu?.children?.map((item) => (
              <ButtonStyle
                style={{
                  backgroundColor: '#FFFFFF',
                  width: '140px',
                  display: 'be',
                }}
                onClick={() =>
                  handleMoveToSuperChildDetailProduct(
                    item?.menuId,
                    item?.menuName,
                  )
                }
                key={item?.menuId}
              >
                <div
                  style={{
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    fontWeight: 700,
                  }}
                  title={item?.menuName}
                >
                  {item?.menuName}
                </div>
              </ButtonStyle>
            ))}
          </div>
          {productDetail?.data?.listDetail?.map((item, index) => (
            <TableListProductDetail key={index}>
              <Divider
                orientation="left"
                orientationMargin="0"
                style={{ margin: 0 }}
              >
                <TitleFormProductDetail>
                  {item?.detailDisplayType !== 'FILE' ? (
                    <>
                      {item?.detailDisplayType === 'LINK' ? (
                        <FormDetailTitle
                          href={item?.detailUrl}
                          target={item?.isNewTab ? '_blank' : ''}
                          rel="noreferrer"
                          title={item?.detailTitle}
                        >
                          {item?.detailTitle}
                        </FormDetailTitle>
                      ) : (
                        <FormDetailTitle
                          onClick={() => handleGoToViewPage(index)}
                          rel="noreferrer"
                          title={item?.detailTitle}
                        >
                          {item?.detailTitle}
                        </FormDetailTitle>
                      )}
                    </>
                  ) : (
                    <FormDetailTitle
                      style={{
                        cursor: 'initial',
                        color: 'var(--BTN-TEXT-SECONDARY-default)',
                      }}
                      title={item?.detailTitle}
                    >
                      {item?.detailTitle}
                    </FormDetailTitle>
                  )}
                </TitleFormProductDetail>
              </Divider>
              <FormContentAndDate hasdetail={item.detailExplanation}>
                <FormDate>
                  更新日:{' '}
                  <span style={{ fontWeight: 400 }}>
                    {dayjs(item?.detailDisplayDate).format('YYYY年MM月DD日')}
                  </span>
                </FormDate>
              </FormContentAndDate>
              {item?.detailTagLst?.map((itemTag, index) => (
                <ButtonStyle
                  key={index}
                  style={{
                    background: '#F3EFE4',
                    border: 'none',
                  }}
                >
                  <div
                    style={{
                      whiteSpace: 'nowrap',
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                      fontWeight: 400,
                      fontFamily: 'Roboto',
                    }}
                    title={itemTag}
                  >
                    {itemTag}
                  </div>
                </ButtonStyle>
              ))}
              <FormContentAndDate>
                <FormContent>
                  <ShowMoreText
                    lines={2}
                    more={
                      <span style={{ fontWeight: 700, cursor: 'pointer' }}>
                        続きを見る
                      </span>
                    }
                    less={false}
                  >
                    {item.detailExplanation
                      ? parse(item.detailExplanation)
                      : ''}
                  </ShowMoreText>
                </FormContent>
              </FormContentAndDate>
              {item?.detailDisplayType === 'FILE' && (
                <>
                  {item?.showHash && (
                    <FormHashCode>
                      SHA256：{item?.fileResponse?.fileHash ?? '計算中'}
                    </FormHashCode>
                  )}
                  <FormDownloadFile
                    onClick={() =>
                      handleDownLoad(
                        item?.fileResponse?.fileName,
                        item?.detailId,
                      )
                    }
                    disabled={
                      item?.detailDisplayStatus === 'DOWNLOADABLE_STOPPED' ||
                      detailIdDisableDownload === item?.detailId
                    }
                  >
                    ダウンロード
                  </FormDownloadFile>
                </>
              )}
            </TableListProductDetail>
          ))}
        </BodyProductDetail>
      </LayoutTopPage>
    </Spin>
  );
};
export default TopPageProductSuperDetailPartnerUser;
