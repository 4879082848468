import React from 'react';
import { Outlet } from 'react-router-dom';
import ProjectDetail from 'page/Application/ProjectPreviewSolitonAdmin/ProjectDetail';
import ProjectPreview from 'page/Application/ProjectPreviewSolitonAdmin/ProjectPreview';

const ProjectPreviewSolitonAdmin = () => {
  return <Outlet />;
};

export default ProjectPreviewSolitonAdmin;

export { ProjectDetail, ProjectPreview };
