import React from 'react';
import { Outlet } from 'react-router-dom';
import NotificationList from 'page/Application/ManageNotificationList/NotificationList';
import RegistrationNotification from 'page/Application/ManageNotificationList/RegisterNotification';
import EditNotification from 'page/Application/ManageNotificationList/EditNotification';
import PreviewNotification from 'page/Application/ManageNotificationList/PreviewNotification';
import { NotificationProvider } from 'page/Application/ManageNotificationList/component/NotifcationContext';
import useSetTitlePage from 'hook/useSetTitlePage';

const ManageNotificationList = () => {
  useSetTitlePage('お知らせ管理');

  return (
    <>
      <NotificationProvider>
        <Outlet />
      </NotificationProvider>
    </>
  );
};

export default ManageNotificationList;
export {
  NotificationList,
  RegistrationNotification,
  EditNotification,
  PreviewNotification,
};
