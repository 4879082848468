import React, { useEffect, useMemo, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import Tree from 'components/Tree';
import {
  getPublicProject,
  updateProjectPosition,
} from 'api/product/product.api';
import { defaultData } from 'components/Tree/treeData';
import useCustomQuery from 'hook/useQuery/useCustomQuery';
import { TreeWrapper } from './styled';
import Breadcrumb from 'components/Layout/ApplicationLayout/Breadcrumb';
import useModalConfirm from 'hook/useModalConfirm';
import { ButtonOutline, ButtonPrimary } from 'components/Button';
import useCustomMutateRenderMessage from 'hook/useQuery/useCustomMutateRenderMessage';
import LayoutManageProjectRegistration from '../component/LayoutManageProjectRegistration';

const ManageProjectEditMode = () => {
  const { useQueryAPI } = useCustomQuery();
  const [treeData, setTreeData] = useState(defaultData);
  const { contextHolder, handleConfirmAction } = useModalConfirm();
  const [isCancel, setIsCancel] = useState(false);
  const navigate = useNavigate();

  const { useMutationAPIHandleErrorCode } = useCustomMutateRenderMessage({
    errorCode: [],
  });
  const listItem = [
    {
      title: <Link to={'/manage-company-list'}>企業管理</Link>,
    },
    {
      title: (
        <Link to={'/manage-project-registration'}>案件登録お申込み管理</Link>
      ),
    },
    {
      title: '案件登録お申し込み一覧',
    },
  ];

  const { data: projectDetail = [], isLoading } = useQueryAPI({
    api: getPublicProject,
    key: 'projectEditMode',
    params: {},
    options: {
      refetchOnWindowFocus: false,
    },
  });

  const { mutate: updateProjectPositionMutation, isLoading: isUpdateLoading } =
    useMutationAPIHandleErrorCode({
      api: updateProjectPosition,
      messageSuccess: '並び順を変更しました。',
      path: `/manage-project-registration`,
      refetchKey: 'projectDetail',
    });

  const handleCancel = () => {
    handleConfirmAction({
      confirmFn: navigate,
      params: `/manage-project-registration`,
      title: '変更をキャンセルしてもよろしいでしょうか？',
      okText: 'はい',
      cancelText: 'キャンセル',
      setIsDisable: setIsCancel,
    });
    setIsCancel(true);
  };

  const handleUpdate = () => {
    const listTreeDataProject = [];
    const listChildren = treeData?.children;
    let countOrderIdProject = 0;
    listChildren?.forEach((project) => {
      countOrderIdProject++;
      listTreeDataProject.push({
        projectId: project.value,
        orderId: countOrderIdProject,
      });
    });

    updateProjectPositionMutation({
      payload: {
        listProject: listTreeDataProject,
      },
    });
  };

  const treeDataFormated = useMemo(() => {
    if (isLoading === false && projectDetail) {
      const listProject = projectDetail?.data;
      const listItemTree = [];
      let projectOrderId = 0;
      listProject.forEach((element) => {
        ++projectOrderId;
        listItemTree.push({
          value: element.projectId,
          label: element.projectName,
          isRoot: true,
          isParent: false,
          parentUuid: '0',
          menuId: element.projectId,
          menuUuid: element.projectId,
          menuName: element.projectName,
          orderId: projectOrderId,
          isDrag: true,
          children: [],
        });
      });
      return listItemTree;
    }
    return defaultData;
  }, [isLoading, projectDetail]);

  useEffect(() => {
    if (isLoading === false && treeDataFormated) {
      setTreeData({
        ...defaultData,
        children: treeDataFormated,
      });
    }
  }, [isLoading, treeDataFormated]);

  return (
    <>
      {contextHolder}
      <Breadcrumb titlePage={listItem[2].title} listItem={listItem}>
        {/*<ButtonBreadcrumb text={'編集'} />*/}
        <ButtonPrimary
          text={'保存'}
          width={'79px'}
          marginRight={'8px'}
          onClick={handleUpdate}
          isLoading={isUpdateLoading}
        />
        <ButtonOutline
          text={'キャンセル'}
          width={'123px'}
          isDisabled={isCancel}
          onClick={handleCancel}
        />
      </Breadcrumb>
      <LayoutManageProjectRegistration>
        <TreeWrapper>
          <Tree
            treeData={treeData}
            setTreeData={setTreeData}
            isLoading={isLoading}
            width={1350}
            masterDoomWidth={1423}
            showDropdown={false}
            background={'#EBEBEB'}
          />
        </TreeWrapper>
      </LayoutManageProjectRegistration>
    </>
  );
};
export default ManageProjectEditMode;
