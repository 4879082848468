import React from 'react';
import { CheckboxStyled } from './styled';

const CheckboxCustom = ({ color, text, onChange, value }) => {
  return (
    <CheckboxStyled onChange={onChange} checked={value} color={color}>
      {text}
    </CheckboxStyled>
  );
};

export default CheckboxCustom;
