import React from 'react';
import { Button, Popover } from 'antd';
import { InfoCircleOutlined } from '@ant-design/icons';
import { DATE_FORMAT_LIST } from 'constant/notificationConstants';
import {
  FormDatePicker,
  FormDateRangePicker,
  FormLabelGrid,
} from 'page/Application/ManageProductAndService/Content/component/index';
import {
  Grid1,
  Grid2,
  Grid3,
} from 'page/Application/ManageProductAndService/Content/component/styled';
import { GridModal } from 'page/Application/ManageProductAndService/Content/ContentList/component/FormModalDisplay/styled';

const FormModalDisplay = ({ form, listDateDisabled }) => {
  return (
    <GridModal>
      <Grid1>
        <FormLabelGrid labelText={'表示日付'} />
      </Grid1>
      <Grid2>
        <FormDatePicker
          width={'490px'}
          placeHolder={'日時選択'}
          dateFormat={DATE_FORMAT_LIST[1]}
          name={'displayDate'}
          isShowTime={false}
        />
      </Grid2>
      <Grid1>
        <FormLabelGrid labelText={'公開日時予約・非公開日時予約'} />
      </Grid1>
      <Grid2>
        <FormDateRangePicker
          name={'publishedFromToDate'}
          width={'490px'}
          dateFormat={DATE_FORMAT_LIST[0]}
          isShowTime={true}
          form={form}
          listDisable={listDateDisabled}
          isUpdateStatus={true}
        />
      </Grid2>
      <Grid3>
        <Popover
          content={'終了日時を入力しない場合は、公開期間は無期限になります。'}
        >
          <Button type="link" style={{ padding: 0 }}>
            <InfoCircleOutlined
              style={{
                color: '#A5A5A5',
                fontSize: '20px',
                marginTop: '6px',
              }}
            />
          </Button>
        </Popover>
      </Grid3>
    </GridModal>
  );
};

export default FormModalDisplay;
