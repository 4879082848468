import React, { useMemo } from 'react';
import MyPageEditSolitonUser from 'page/Application/MyPage/MyPageEdit/MyPageEditSolitonUser';
import MyPageEditPartnerUser from 'page/Application/MyPage/MyPageEdit/MyPageEditPartnerUser';
import { useRecoilValue } from 'recoil';
import { userInformation } from 'recoil/atom/authentication';
const MyPageEdit = () => {
  const { role } = useRecoilValue(userInformation);

  const CheckRole = useMemo(() => {
    return {
      ROLE_PARTNER_USER: <MyPageEditPartnerUser />,
      ROLE_SOLITON_USER: <MyPageEditSolitonUser />,
    };
  }, []);

  return <>{CheckRole[role]}</>;
};

export default MyPageEdit;
