import axiosClient from 'api/config/axiosClient';
import axiosClientNotTimeOut from 'api/config/axiosClientNotTimeOut';

const downloadContentFile = ({ detailId }) => {
  const url = `files/download/detail/${detailId}`;
  return axiosClient.get(url);
};

const downloadNotifyFile = ({ notifyId }) => {
  const url = `files/download/notify/${notifyId}`;
  return axiosClient.get(url);
};

const getPreSignedUrlToUploadOrDownload = ({ fileName, httpMethod }) => {
  const url = `files/pre-signed-url?fileName=${fileName}&httpMethod=${httpMethod}`;
  return axiosClient.get(url);
};

const uploadFileToS3WithPreSignedUrl = ({ url, file, options }) => {
  return axiosClientNotTimeOut.put(url, file, options);
};

const deleteS3FileWithPreSignedUrl = ({ url, options }) => {
  return axiosClientNotTimeOut.delete(url, options);
};

export {
  downloadContentFile,
  downloadNotifyFile,
  getPreSignedUrlToUploadOrDownload,
  uploadFileToS3WithPreSignedUrl,
  deleteS3FileWithPreSignedUrl,
};
