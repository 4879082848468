import React, { Suspense, useEffect } from 'react';
import { getDetailSolitonUser } from 'api/myPage/solitonUser.api';
import { useRecoilValue } from 'recoil';
import { authenticationToken } from 'recoil/atom/authentication';
import { useNavigate } from 'react-router-dom';
import { Form, Spin, Tooltip } from 'antd';
import Breadcrumb from 'components/Layout/ApplicationLayout/Breadcrumb';
import LayoutDefault from 'components/Layout/LayoutDefault';
import RowButton from 'components/Layout/RowButton';
import useCustomQuery from 'hook/useQuery/useCustomQuery';
import {
  EmailPasswordAccountType,
  LastNameFirstName,
} from 'page/Application/MyPage/component/FormMyPage';
import ButtonPrimary from 'page/Application/MyPage/component/FormMyPage/ButtonPrimary';
import { FormItem } from 'components/Form2';
import LabelFormMyPage from 'page/Application/MyPage/component/FormMyPage/LabelFormMyPage';
import {
  FormMyPageWrapper,
  GridFormAccount,
  GridFormAccount1to2,
  GridFormAccount2to4,
} from 'page/Application/MyPage/component/FormMyPage/styled';
import {
  LinkToPartnerSite,
  ListPartnerWrapper,
  PartnerNameItems,
} from 'page/Application/MyPage/MyPageOverview/MyPageSolitonUser/styled';
import { FormTitle } from 'page/Application/ManageCompanyList/component/Form';

const MyPageSolitonUser = () => {
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const { accessToken } = useRecoilValue(authenticationToken);
  const { useQueryAPI } = useCustomQuery();
  const { data: getMyPageDetail = [], isLoading } = useQueryAPI({
    api: getDetailSolitonUser,
    key: 'getDetailSolitonUser',
    params: {
      token: accessToken,
    },
  });

  const listItem = [
    {
      title: 'マイページ',
    },
  ];

  const handleSwitchEditMode = () => {
    navigate(`/my-page/edit`, {
      state: {
        ...getMyPageDetail?.data,
      },
    });
  };

  const handleGoToPartnerPage = (item) => {
    navigate(`/partner-page/${item?.partnerId}`);
  };

  useEffect(() => {
    form.setFieldsValue({
      ...getMyPageDetail?.data,
      password: 'パスワードパスワード', // fake password to show dots
    });
  }, [accessToken, form, getMyPageDetail?.data]);

  return (
    <Suspense fallback={isLoading}>
      <Breadcrumb listItem={listItem} titlePage={'マイページ'} />
      <LayoutDefault>
        <FormTitle
          notice={'注意：'}
          title={'変更した内容は画面下部の「保存」ボタンを押すと保存されます。'}
          type={'text'}
          styles={{ color: '#9D1C49' }}
        />
        <Spin spinning={isLoading}>
          <FormMyPageWrapper>
            <Form size={'Large'} form={form} disabled={true}>
              <LastNameFirstName isEdit={false} />
              <EmailPasswordAccountType
                data={getMyPageDetail?.data}
                disabled={true}
                editPassword={false}
                isEdit={false}
              />

              <GridFormAccount>
                <GridFormAccount1to2>
                  <LabelFormMyPage
                    labelText={'担当パートナー'}
                    required={false}
                  />
                </GridFormAccount1to2>
                <GridFormAccount2to4>
                  <FormItem>
                    <ListPartnerWrapper>
                      {getMyPageDetail?.data?.partnerInCharge.map(
                        (item, index) => (
                          <PartnerNameItems key={index}>
                            <Tooltip
                              title={item.partnerName}
                              placement={'topLeft'}
                            >
                              <LinkToPartnerSite
                                enablenavigation={'true'}
                                onClick={() => {
                                  handleGoToPartnerPage(item);
                                }}
                              >
                                {item.partnerName}
                              </LinkToPartnerSite>
                            </Tooltip>
                          </PartnerNameItems>
                        ),
                      )}
                    </ListPartnerWrapper>
                  </FormItem>
                </GridFormAccount2to4>
              </GridFormAccount>
            </Form>
            <GridFormAccount>
              <GridFormAccount2to4>
                <RowButton>
                  <ButtonPrimary text={'編集'} onClick={handleSwitchEditMode} />
                </RowButton>
              </GridFormAccount2to4>
            </GridFormAccount>
          </FormMyPageWrapper>
        </Spin>
      </LayoutDefault>
    </Suspense>
  );
};

export default MyPageSolitonUser;
