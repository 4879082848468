import React, { Fragment, useState } from 'react';
import { Button, Form } from 'antd';
import {
  ButtonStyle,
  CheckBoxStyled,
  Grid2,
  Grid3to5,
  Grid5,
} from 'page/Application/ManageCompanyList/component/Form/styled';
import { CloseOutlined } from '@ant-design/icons';
import useMultiLanguageRegisterCompany from 'page/Application/ManageCompanyList/RegisterCompany/hook/useMultiLanguageRegisterCompany';
import { FormItemStyled, InputStyled } from 'components/Form2/styled';
import FormMessage from 'components/Form/FormMessage';
import { CHECK_SPECIAL_CHARACTER_WITHOUT_DOT_AND_DASH } from 'constant/regex';
import useModalConfirmAddDomainList from 'hook/useModalConfirmAddDomainList';

/**
 * form: is form instance for get, set value.
 */

const FormListDomain = ({ form, setIsDomainAvailable, isDomainAvailable }) => {
  const { handleConfirmAction, contextHolder } = useModalConfirmAddDomainList();
  const {
    REGISTER_COMPANY_AUTO_APPROVE,
    REGISTER_COMPANY_ADD,
    REGISTER_COMPANY_MESSAGE_ERROR_ENTER_DOMAIN_NAME,
    REGISTER_COMPANY_MESSAGE_DELETE_DOMAIN_OR_PARTNER_EMAIL,
  } = useMultiLanguageRegisterCompany();
  const [isDisable, setIsDisable] = useState(false);

  // remove domain
  const handleRemoveFormList = (remove, name) => {
    const domainName = document.getElementById('domainName' + name);
    handleConfirmAction({
      confirmFn: remove,
      title:
        domainName?.value +
        REGISTER_COMPANY_MESSAGE_DELETE_DOMAIN_OR_PARTNER_EMAIL,
      params: name,
      form: form,
      setIsDisable: setIsDisable,
      setIsAvailable: setIsDomainAvailable,
    });
    setIsDisable(true);
  };

  // rule validate email
  const ruleValidateDomain = [
    {
      required: false,
      message: 'ドメイン情報を入力してください。',
    },
    () => ({
      validator(_, value) {
        if (value && value.length > 0) {
          if (
            value.length >= 2 &&
            value.includes('.') &&
            value.length - value.indexOf('.') >= 3 &&
            !CHECK_SPECIAL_CHARACTER_WITHOUT_DOT_AND_DASH.test(value) && // Check special character without dot ('.') and dash ('-')
            /[A-Za-z0-9]/.test(value.charAt(value.indexOf('.') - 1)) && // Check character before ('.')
            !/^[-.]/.test(value) // Check begin not has dot ('.') and dash ('-')
          ) {
            setIsDomainAvailable(false);
            return Promise.resolve();
          } else {
            setIsDomainAvailable(true);
            return Promise.reject(
              <FormMessage
                text1={REGISTER_COMPANY_MESSAGE_ERROR_ENTER_DOMAIN_NAME}
                marginBottom={'12px'}
              />,
            );
          }
        } else {
          setIsDomainAvailable(true);
          return Promise.resolve();
        }
      },
    }),
  ];

  return (
    <>
      {contextHolder}
      <Form.List
        name="domainList"
        initialValue={[{ domainName: '', isAutoApprove: '' }]}
      >
        {(fields, { add, remove }) => (
          <>
            {fields.map(({ key, name, ...restField }) => {
              return (
                <Fragment key={key}>
                  <Grid2>
                    <FormItemStyled
                      marginbottom="14px"
                      {...restField}
                      name={[name, 'domainName']}
                      dependencies={['domainName']}
                      rules={ruleValidateDomain}
                    >
                      <InputStyled
                        id={'domainName' + name}
                        addonBefore="@"
                        maxLength={63}
                        placeholder="example.com"
                        disabled={
                          form.getFieldValue(name[key])?.domainList[name]
                            ?.domainId
                        }
                      />
                    </FormItemStyled>
                  </Grid2>

                  <Grid3to5>
                    <FormItemStyled
                      marginbottom="14px"
                      {...restField}
                      name={[name, 'isAutoApprove']}
                      valuePropName="checked"
                    >
                      <CheckBoxStyled>
                        {REGISTER_COMPANY_AUTO_APPROVE}
                      </CheckBoxStyled>
                    </FormItemStyled>
                  </Grid3to5>
                  <Grid5>
                    <Button
                      style={{ width: 44 }}
                      // disabled={
                      //   form.getFieldValue(name[key])?.domainList[key]
                      //     ?.domainId || isDisable
                      // }
                      // disabled={
                      //   form.getFieldValue(name[key])?.domainList[key]?.domainId
                      // }
                      disabled={isDisable}
                      icon={
                        <CloseOutlined
                          style={{ color: 'var(--PRIMARY-default)' }}
                        />
                      }
                      onClick={() => handleRemoveFormList(remove, name)}
                    />
                  </Grid5>
                </Fragment>
              );
            })}
            <Grid2>
              <FormItemStyled shouldUpdate>
                <ButtonStyle
                  disabled={
                    isDomainAvailable ||
                    form
                      .getFieldValue('domainList')
                      .some((value) => !value?.domainName)
                  }
                  onClick={() => {
                    add();
                    setIsDomainAvailable(true);
                  }}
                >
                  {/*must have a span tag so there are no spaces between letters*/}
                  <span> {REGISTER_COMPANY_ADD}</span>
                </ButtonStyle>
              </FormItemStyled>
            </Grid2>
          </>
        )}
      </Form.List>
    </>
  );
};

export default FormListDomain;
