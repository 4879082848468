import React from 'react';
import { BtnBasic } from 'components/Button/styled';

const ButtonBasic = ({ text, block, onClick, disable, width, size }) => {
  return (
    <BtnBasic
      block={block}
      size={size ?? 'large'}
      onClick={onClick}
      disabled={disable}
      style={{
        width: width,
      }}
    >
      {''}
      {text}
    </BtnBasic>
  );
};

export default ButtonBasic;
