import { useIntl } from 'react-intl';

const useErrorMessageMultiLanguageSignIn = () => {
  const intl = useIntl();
  const ERROR_EMAIL_NOT_FOUND = intl.formatMessage({
    id: 'signIn.errorMessage.404_Email_Not_Found',
  });

  const ERROR_WRONG_USERNAME_PASSWORD = intl.formatMessage({
    id: 'signIn.errorMessage.401_Wrong_Username_Password',
  });

  const ERROR_USER_NOT_APPROVED = intl.formatMessage({
    id: 'signIn.errorMessage.400_User_Not_Approved',
  });

  const ERROR_USER_HAS_BEEN_SUSPENDED = intl.formatMessage({
    id: 'signIn.errorMessage.400_User_Has_Been_Suspended',
  });

  return {
    ERROR_EMAIL_NOT_FOUND,
    ERROR_WRONG_USERNAME_PASSWORD,
    ERROR_USER_NOT_APPROVED,
    ERROR_USER_HAS_BEEN_SUSPENDED
  };
};

export default useErrorMessageMultiLanguageSignIn;