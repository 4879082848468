import React, { useEffect, useState } from 'react';
import { useRecoilState } from 'recoil';
import { filterProject } from 'recoil/atom/filterProject';
import { ExclamationCircleFilled } from '@ant-design/icons';
import { Col, Form, Modal, Row } from 'antd';
import { ButtonGroup, TitleStyle } from 'components/AntdModal/styled';
import { ButtonOutline, ButtonPrimary } from 'components/Button';
import { InputStyled } from 'components/Form/styled';
import { FormItem } from 'components/Form2';
import { SelectStyled } from 'components/Form2/styled';

const option = [
  {
    value: 1,
    label: '表示',
    title: '',
  },
  {
    value: 0,
    label: '非表示',
    title: '',
  },
];

const ModalFilter = ({
  errorNetWork,
  isModalOpen,
  width,
  setFilterData,
  setIsModalOpen,
  setCurrent,
  setResetFormStatus,
  resetFormStatus,
  isOnline,
  form,
}) => {
  const [isSubmit, setIsSubmit] = useState(true);
  const values = Form.useWatch([], form);
  const [, setFilterProject] = useRecoilState(filterProject);

  const handleReset = () => {
    form.resetFields();
  };

  const handleModalKeepFilter = () => {
    form.resetFields();
    setIsModalOpen(false);
  };

  const onFinish = () => {
    if (isOnline) {
      const value = form.getFieldsValue(true);
      let payload = {
        keyword: value.title ? value.title.trim() : '',
        status: value.status ?? '',
      };
      setFilterProject({
        ...payload,
      });
      setCurrent(1);
      setFilterData(payload);
      setIsModalOpen(false);
      setResetFormStatus(false);
    } else {
      errorNetWork();
    }
  };

  useEffect(() => {
    if (isModalOpen) {
      form.validateFields().then((values) => {
        if (values.title || values.status === 1 || values.status === 0) {
          setIsSubmit(false);
        } else {
          setIsSubmit(true);
        }
      });
    }
  }, [values, form, isModalOpen]);

  useEffect(() => {
    if (resetFormStatus) {
      form.resetFields();
    }
  }, [form, resetFormStatus]);

  return (
    <Modal
      title={<TitleStyle>{'絞り込み検索'}</TitleStyle>}
      open={isModalOpen}
      onCancel={handleModalKeepFilter}
      centered={true}
      width={width}
      icon={<ExclamationCircleFilled />}
      destroyOnClose={true}
      maskClosable={false}
      cancelText={'リセット'}
      footer={[
        <ButtonGroup
          key="footerModal"
          style={{ paddingTop: '20px', paddingBottom: '5px' }}
        >
          <ButtonPrimary
            key="submit"
            type="primary"
            loading={false}
            onClick={onFinish}
            text={'検索'}
            width={80}
            disabled={isSubmit}
            size={'large'}
          />
          <ButtonOutline onClick={handleReset} text={'リセット'} />
        </ButtonGroup>,
      ]}
    >
      <Form layout={'vertical'} form={form} style={{ marginTop: '26px' }}>
        <Row gutter={25}>
          <Col span={12}>
            <FormItem label={'タイトル'} name={'title'}>
              <InputStyled
                placeholder={'タイトル名を入力してください'}
                maxLength={256}
              />
            </FormItem>
          </Col>
          <Col span={12}>
            <FormItem label={'ステータス'} name={'status'}>
              <SelectStyled options={option} />
            </FormItem>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
};

export default ModalFilter;
