import styled from 'styled-components';
import { Pagination, Select } from 'antd';

export const PaginationWrapper = styled.div`
  border-top: 1px solid #f0f0f0;
  padding: 15px 0 7px 0;
  display: flex;
  align-items: center;
  justify-content: space-between;

  @media all and (min-width: 0px) and (max-width: 320px) {
    justify-content: center;
  }
  @media all and (min-width: 321px) and (max-width: 430px) {
    justify-content: center;
  }
  @media all and (min-width: 431px) and (max-width: 768px) {
    justify-content: center;
  }
`;
export const PaginationStyled = styled(Pagination)`
  text-align: center;
  span {
    color: var(--FORM-LABEL-default);
    font-weight: 500;
    font-family: 'inter-regular', sans-serif;
  }

  .ant-select-selection-item {
    color: var(--FORM-LABEL-default);
    font-weight: 500;
    min-width: 96px;
  }
  .ant-pagination-item,
  .ant-pagination-item > a,
  .ant-pagination-item-link,
  .ant-pagination-prev,
  .ant-pagination-next {
    border-radius: 50%;
    border-color: #ebebeb;
    background-color: #ebebeb;
    color: var(--FORM-LABEL-default);
    font-family: 'inter-regular', sans-serif;
    &:hover {
      border-radius: 50%;
      color: var(--PRIMARY-default);
      border-color: var(--PRIMARY-default);
      background-color: white;
    }
  }

  .ant-pagination-item-active,
  .ant-pagination-item-active > a {
    border-radius: 50%;
    color: white;
    border-color: var(--PRIMARY-default);
    background-color: var(--PRIMARY-default);

    &:hover {
      border-color: var(--PRIMARY-default);
      background-color: #ab3a5e;
      color: white;
    }
  }

  .ant-pagination-total-text {
    position: absolute;
    left: 0;
  }

  .ant-pagination-options {
    position: absolute;
    right: 0;
  }

  .ant-select {
    height: 36px;
  }
`;

export const TotalRecord = styled.div`
  font-size: 14px;
  color: #656464;
  min-width: 120px;

  @media all and (min-width: 0px) and (max-width: 320px) {
    display: none;
  }
  @media all and (min-width: 321px) and (max-width: 430px) {
    display: none;
  }
  @media all and (min-width: 431px) and (max-width: 768px) {
    display: none;
  }
`;

export const SelectStyled = styled(Select)`
  width: 120px;
  height: 36px;

  @media all and (min-width: 0px) and (max-width: 320px) {
    display: none;
  }
  @media all and (min-width: 321px) and (max-width: 430px) {
    display: none;
  }
  @media all and (min-width: 431px) and (max-width: 768px) {
    display: none;
  }
`;
