import { DatePicker, Form } from 'antd';
import FormMessage from 'components/Form/FormMessage/index';
import dayjs from 'dayjs';
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';
import { range } from 'lodash';
import React from 'react';

dayjs.extend(isSameOrAfter);

const FormDateRangePicker = ({
  name,
  width,
  dateFormat,
  listDisable,
  isShowTime,
  form,
  isList,
}) => {
  const onOkSelectedDate = (dates) => {
    if (dates) {
      let dateFrom;
      let dateTo;
      if (dates[0] && dayjs().isBefore(dates[0])) {
        dateFrom = dayjs(dates[0]);
      }
      if (dates[1] && dayjs().isBefore(dates[1])) {
        dateTo = dayjs(dates[1]);
      }
      form.setFields([
        {
          name: 'publishedFromToDate',
          value: [dateFrom, dateTo],
          errors: [],
          validated: false,
          validating: false,
        },
      ]);
    }
  };

  const disableRangeTime = (date, _type) => {
    if (!date) {
      return {
        disabledHours: () => range(0, 24),
        disabledMinutes: () => range(0, 60),
      };
    }
    if (dayjs().isSameOrAfter(date, 'hours')) {
      return {
        disabledHours: () => range(0, dayjs().hour()),
        disabledMinutes: () => range(0, dayjs().minute() + 1),
      };
    }
    if (dayjs().isSameOrAfter(date, 'days')) {
      return {
        disabledHours: () => range(0, dayjs().hour()),
      };
    }
  };

  const disabledDate = (current) => {
    if (!isList) {
      return dayjs().isAfter(current, 'days') || current.isAfter('2039-01-01');
    }
  };

  return (
    <Form.Item
      name={name}
      rules={[
        {
          validator: (_, value) => {
            if (!value) {
              return Promise.resolve();
            }
            if (value[0] && value[1]) {
              if (
                dayjs().isSameOrAfter(value[0], 'minutes') &&
                dayjs().isSameOrAfter(value[1], 'minutes')
              ) {
                return Promise.reject(
                  <FormMessage
                    text1={'公開日時・非公開日時が無効です'}
                    marginBottom={'12px'}
                  ></FormMessage>,
                );
              }
            }
            if (value[1] && dayjs().isSameOrAfter(value[1], 'minutes')) {
              return Promise.reject(
                <FormMessage
                  text1={
                    '「非公開日時予約」は現在時点後よりの日時を入力してください。'
                  }
                  marginBottom={'12px'}
                ></FormMessage>,
              );
            }
            return Promise.resolve();
          },
        },
      ]}
    >
      <DatePicker.RangePicker
        showTime={
          isShowTime && {
            format: 'HH:mm',
          }
        }
        format={dateFormat}
        style={{ width: width ?? '250px' }}
        placeholder={['日付From', '日付To']}
        disabled={listDisable}
        allowEmpty={true}
        inputReadOnly={true}
        disabledDate={disabledDate}
        disabledTime={disableRangeTime}
        onOk={onOkSelectedDate}
      />
    </Form.Item>
  );
};
export default FormDateRangePicker;
