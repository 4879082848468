import styled from 'styled-components';

export const FooterWrapper = styled.div`
  padding: 25px 0;
  //width: calc(100dvw - 356px);
  //grid-column: 2 / 3;
`;

export const FooterText = styled.p`
  font-family: 'inter-medium', sans-serif;
  font-weight: 400;
  font-size: 12px;
  color: #656464;
  line-height: 1.3;
  @media all and (min-width: 0px) and (max-width: 320px) {
    text-align: center;
  }
  @media all and (min-width: 321px) and (max-width: 430px) {
    text-align: center;
  }
  @media all and (min-width: 431px) and (max-width: 768px) {
    text-align: center;
  }
`;
