import React from 'react';
import LogoWhite from 'components/Logo/LogoWhite';

const Header = () => {
  return (
    <div className="header">
      <LogoWhite width={120} height={32} />
      <p className="hero-text">株式会社ソリトンシステムズ</p>
    </div>
  );
};

export default Header;
