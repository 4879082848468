import styled from 'styled-components';
import { Table } from 'antd';

export const TableStyled = styled(Table)`
  .ant-table-container {
    //min-height: calc(100dvh - 413px);
  }

  .ant-table-cell {
    color: var(--FORM-LABEL-default);
    font-family: 'inter-regular', sans-serif;
    height: 48px;

    &.ant-table-selection-column {
      padding-left: 15px;
    }
  }

  .ant-table-column-sorters {
    justify-content: start !important;
    column-gap: 12px;
  }

  .ant-table-column-title {
    flex: unset;
  }

  ::-webkit-scrollbar {
    width: 6px;
  }

  /* Track */

  ::-webkit-scrollbar-track {
    background: transparent;
  }

  /* Handle */

  ::-webkit-scrollbar-thumb {
    background: #d9d9d9;
    border-radius: 2px;
  }

  /* Handle on hover */

  ::-webkit-scrollbar-thumb:hover {
    background: #555;
  }

  .table-row-light {
    background-color: #ffffff;
  }

  .table-row-dark {
    background-color: #fbfbfb;
  }

  .ant-table-row:nth-child(
      -n + ${(props) => (props?.highLightedRows ? props.highLightedRows : 0)}
    )
    > .ant-table-cell-row-hover {
    background: #e8dfc8 !important;
  }

  .ant-table-row:nth-child(
      -n + ${(props) => (props?.highLightedRows ? props.highLightedRows : 0)}
    )
    > .ant-table-cell {
    background: #f3efe4;
    font-weight: 700;
  }

  .ant-table-tbody > tr:not(:last-child) > td {
    border-bottom: ${(props) =>
      props?.isBoldBottomBorder
        ? '0.5px solid #D2D3D4'
        : '1px solid #f0f0f0'} !important;
  }

  .ant-table-tbody > tr:last-child > td {
    border-bottom: 1px solid
      ${(props) => (props?.isBoldBottomBorder ? '#ffffff' : '#f0f0f0')} !important;
  }

  .ant-table-tbody > tr > td {
    color: ${(props) => props?.isBoldBottomBorder && '#434343'} !important;
  }

  .ant-table-thead {
    font-size: 16px;
  }
`;
