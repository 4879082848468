import React from 'react';
import { FooterPreviewWrapper } from 'page/Application/ManageNotificationList/component/Form/FormFooterSubmit/styled';
import { ButtonStyle } from 'page/Application/ManageNotificationList/component/Form/styled';

const FormFooterPreview = ({
  textOk,
  onFinish,
  isLoading,
  height,
  alignItems,
}) => {
  return (
    <FooterPreviewWrapper
      style={{
        height: height,
        alignItems: alignItems,
      }}
    >
      <ButtonStyle
        type="primary"
        htmlType="submit"
        onClick={onFinish}
        loading={isLoading}
      >
        {/*must have a span tag so there are no spaces between letters*/}
        <span> {textOk}</span>
      </ButtonStyle>
    </FooterPreviewWrapper>
  );
};

export default FormFooterPreview;
