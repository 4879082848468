import { createHashRouter } from 'react-router-dom';
import Authentication from 'page/Authentication';
import SignIn from 'page/Authentication/SignIn';
import SignUp from 'page/Authentication/SignUp';
import SignUpSelectPartner from 'page/Authentication/SignUpSelectPartner/index';
import VerifyEmail from 'page/Authentication/ForgotPassword/VerifyEmail';
import ResetPassword from 'page/Authentication/ForgotPassword/ResetPassword';
import NotFound from 'page/NotFound';
import {
  SIGN_IN_PATH,
  SIGN_UP_PATH,
  RESET_PASSWORD_PATH,
  FORGOT_PASSWORD_PATH,
  PRIVACY,
  SIGN_UP_CHOOSE_PARTNER_PATH,
} from 'constant/authenticationPath';
import Privacy from 'page/Privacy';

export const authenticationRouter = createHashRouter([
  {
    path: SIGN_IN_PATH,
    element: <Authentication />,
    errorElement: <NotFound />,
    children: [
      {
        index: true,
        element: <SignIn />,
      },
      {
        path: SIGN_UP_PATH,
        element: <SignUp />,
      },
      {
        path: SIGN_UP_PATH + '/' + SIGN_UP_CHOOSE_PARTNER_PATH,
        element: <SignUpSelectPartner />,
      },
      {
        path: FORGOT_PASSWORD_PATH,
        element: <VerifyEmail />,
      },
      {
        path: RESET_PASSWORD_PATH,
        element: <ResetPassword />,
      },
    ],
  },
  {
    path: PRIVACY,
    element: <Privacy />,
  },
]);
