import React, { useState } from 'react';
import useMultiLanguageApplicationLayout from 'components/Layout/ApplicationLayout/hook/useMultiLanguageApplicationLayout';
import { useRecoilState, useRecoilValue } from 'recoil';
import { useNavigate } from 'react-router-dom';
import { queryClient } from 'api/config/queryClient';
import { menuSelected } from 'recoil/atom/listMenu';
import { MenuOutlined, SearchOutlined } from '@ant-design/icons';
import { drawerOpen } from 'recoil/atom/sidebarDrawer';
import {
  HeaderButtonSearchStyled,
  SearchStyled,
} from 'components/Layout/ApplicationLayout/Header/styled';
import { Avatar, Button, Dropdown } from 'antd';
import {
  authenticationToken,
  userInformation,
} from 'recoil/atom/authentication';

const Header = () => {
  const navigate = useNavigate();

  const [value, setValue] = useState('');
  const [isDisabledSearch, setIsDisabledSearch] = useState(true);

  const [, setUserToken] = useRecoilState(authenticationToken);
  const [, setUserInformation] = useRecoilState(userInformation);
  const [, setMenuSelected] = useRecoilState(menuSelected);
  const [, setOpen] = useRecoilState(drawerOpen);
  const { role } = useRecoilValue(userInformation);

  const { SEARCH_ALL_SYSTEMS } = useMultiLanguageApplicationLayout();

  const handleLogout = () => {
    setUserToken({
      accessToken: '',
      passwordExpiredRemainingDay: 0,
    });
    setUserInformation({
      email: '',
      firstName: '',
      lastName: '',
      role: '',
      approveStatus: '',
      userId: 0,
    });
    setMenuSelected({
      id: 0,
      label: '',
      path: '',
    });
    queryClient.removeQueries();
    navigate('/', { replace: true });
  };

  // const handleRemoveUser = () => {
  //   localStorage.removeItem('user_account');
  //   handleLogout();
  // };

  const handleTextInAvatar = () => {
    switch (role) {
      case 'ROLE_SOLITON_ADMIN':
        return 'SA';
      case 'ROLE_SOLITON_USER':
        return 'SU';
      case 'ROLE_PARTNER_ADMIN':
        return 'PA';
      case 'ROLE_PARTNER_USER':
        return 'PU';
      default:
        return '';
    }
  };

  const items = [
    // {
    //   key: '1',
    //   label: <div onClick={handleRemoveUser}>Logout & remove user</div>,
    // },
    {
      key: '2',
      label: <div onClick={handleLogout}>ログアウト</div>,
    },
  ];

  const handleOpenSidebar = () => {
    setOpen(true);
  };

  const onSearch = () => {
    setValue('');
    setIsDisabledSearch(true);
    if (value.trim()) {
      navigate(`/opensearch?keyword=${encodeURIComponent(value)}`);
    }
  };

  const EnterButton = ({ onClick }) => (
    <HeaderButtonSearchStyled onClick={onClick} disabled={isDisabledSearch}>
      <SearchOutlined />
    </HeaderButtonSearchStyled>
  );

  return (
    <div className="header-application">
      <Button
        icon={<MenuOutlined />}
        className="button-menu"
        onClick={handleOpenSidebar}
      />
      <div className="group-item">
        <SearchStyled
          enterButton={<EnterButton onClick={onSearch} />}
          onSearch={onSearch}
          placeholder={SEARCH_ALL_SYSTEMS}
          className="search-input-responsive"
          value={value}
          onChange={(e) => {
            const value = e.target.value;
            setIsDisabledSearch(!value?.trim());
            setValue(value);
          }}
        />
        <div className="line-vertical" />
        <Dropdown
          menu={{
            items,
          }}
          placement="bottomRight"
          arrow={{
            pointAtCenter: true,
          }}
        >
          <Avatar
            className="avatar-responsive"
            style={{
              backgroundColor: '#9D1C49',
              minWidth: 40,
            }}
            size="large"
          >
            {handleTextInAvatar()}
          </Avatar>
        </Dropdown>
      </div>
    </div>
  );
};
export default Header;
