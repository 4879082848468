export const NOTIFICATION_STATUS_DATA = [
  {
    value: 'SHOW',
    label: 'Show',
  },
  {
    value: 'HIDE',
    label: 'Hide',
  },
  {
    value: 'SCHEDULED',
    label: 'Scheduled',
  },
];

export const NOTIFICATION_CATEGORY_DATA = [
  {
    value: 'UPDATE_INFORMATION',
    label: 'Update Information',
  },
  {
    value: 'NEWS',
    label: 'News',
  },
  {
    value: 'IMPORTANT_NOTICE',
    label: 'Important Notice',
  },
];

export const NOTIFICATION_DESTINATION_TYPE_DATA = [
  {
    id: 1,
    value: 'NO_DETAILS',
    label: 'No details',
  },
  {
    id: 2,
    value: 'DETAILS_AVAILABLE',
    label: 'Details Available',
  },
  {
    id: 3,
    value: 'INTERNAL_LINKS',
    label: 'Internal Link',
  },
  {
    id: 4,
    value: 'EXTERNAL_LINKS',
    label: 'External Link',
  },
  {
    id: 5,
    value: 'PDF',
    label: 'PDF',
  },
];
export const DATE_FORMAT_LIST = [
  'YYYY年MM月DD日 | HH時mm分',
  'YYYY年MM月DD日',
  'YYYY-MM-DDTHH:mm:00Z',
  'YYYY-MM-DD',
];
export const NOTIFICATION_FILE_TYPE = {
  // pdf
  'application/pdf': {
    name: 'pdf',
    size: '52428800',
  },
  // pptx
  'application/vnd.openxmlformats-officedocument.presentationml.presentation': {
    name: 'pptx',
    size: '52428800',
  },
  // ppt
  'application/vnd.ms-powerpoint': {
    name: 'ppt',
    size: '52428800',
  },
  // xlsx
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': {
    name: 'xlsx',
    size: '52428800',
  },
  // xls
  'application/vnd.ms-excel': {
    name: 'xls',
    size: '52428800',
  },
  // xlsm
  'application/vnd.ms-excel.sheet.macroEnabled.12': {
    name: 'xlsm',
    size: '52428800',
  },
  // docx
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document': {
    name: 'docx',
    size: '52428800',
  },
  // doc
  'application/msword': {
    name: 'doc',
    size: '52428800',
  },
  // txt
  'text/plain': {
    name: 'txt',
    size: '1048576',
  },
  // zip
  'application/x-zip-compressed': {
    name: 'zip',
    size: '1073741824',
  },
  // exe
  'application/x-msdownload': {
    name: 'exe',
    size: '1073741824',
  },
  // png
  'image/png': {
    name: 'png',
    size: '10485760',
  },
  // jpg
  'image/jpeg': {
    name: 'jpg',
    size: '10485760',
  },
  // gif
  'image/gif': {
    name: 'gif',
    size: '10485760',
  },
  // svg
  'image/svg+xml': {
    name: 'svg',
    size: '10485760',
  },
  // mp4
  'video/mp4': {
    name: 'mp4',
    size: '314572800',
  },
  // avi
  'video/avi': {
    name: 'avi',
    size: '314572800',
  },
  // mov
  'video/quicktime': {
    name: 'mov',
    size: '314572800',
  },
  // csv
  'text/csv': {
    name: 'csv',
    size: '10485760',
  },
};

export const NOTIFICATION_FILE_EXTENSION = {
  // bin
  bin: {
    size: '1073741824',
  },
  // nabin
  nabin: {
    size: '1073741824',
  },
  // efm
  efm: {
    size: '1073741824',
  },
  // iso
  iso: {
    size: '4294967296',
  },
  // xls
  wmb: {
    size: '314572800',
  },
};

export const TIME_ZONE = 'GMT+0';
