import React from 'react';
import {
  SelectAll,
  SelectAllWrapper,
} from 'page/Application/ManagerAccountPartner/AccountPartnerList/component/SelectAllTable/styled';
const SelectAllTable = ({
  handleSelectAllRecord,
  selectAllData,
  dataRaw,
  listIdSelected,
}) => {
  return (
    <SelectAllWrapper>
      {selectAllData ? (
        <span>システム上の{dataRaw?.total}件が全て選択されました。</span>
      ) : (
        <span>{[...new Set(listIdSelected)].length}件が選択されました。</span>
      )}
      <SelectAll onClick={handleSelectAllRecord}>
        {selectAllData
          ? '選択解除。'
          : `システム上の${dataRaw?.total}件を全て選択します。`}
      </SelectAll>
    </SelectAllWrapper>
  );
};

export default SelectAllTable;
