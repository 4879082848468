import { Table } from 'antd';
import styled from 'styled-components';

export const DragTable = styled(Table)`
  .ant-table-row {
    background-color: white;
    border-radius: 8px;
  }

  .ant-table {
    background-color: #ebebeb;
  }

  .ant-table-cell {
    border-radius: 8px 0 0 8px;
    white-space: normal;
  }

  .ant-table-cell-ellipsis {
    border-radius: 0 8px 8px 0;
  }
`;
export const SpaceAround = styled.div`
  padding-bottom: 20px;
  background-color: #ebebeb;
`;
export const ShowMoreTextSpan = styled.div`
  font-weight: 600;
  margin: 12px 0;
  cursor: pointer;
`;
export const ContentText = styled.div`
  line-height: 20px;
  font-family: inherit;
  white-space: nowrap;

  ol {
    color: #656464;
    list-style: decimal;
    padding-left: 20px;
  }

  ul {
    color: #656464;
    list-style: initial;
    padding-left: 20px;
  }
  li {
    word-wrap: normal;
  }
`;
export const FormContentAndDate = styled.div`
  overflow: auto hidden;
  scrollbar-color: auto;
  padding-bottom: 5px;
  margin-bottom: 10px;
  ol {
    list-style: decimal inside;
  }

  ul {
    list-style-position: inside;
  }

  li {
    word-wrap: normal;
    list-style-position: inside;
  }

  &::-webkit-scrollbar {
    width: 2px !important;
    height: 6px !important;
    cursor: grab !important;
  }

  &::-webkit-scrollbar-track {
    background: transparent !important;
  }

  &::-webkit-scrollbar-thumb {
    background: #ecececff !important;
    border-radius: 2px !important;
  }

  &::-webkit-scrollbar-thumb:hover {
    background: #c2c2c2ff !important;
  }

  &.date-padding {
    padding-bottom: 20px !important;
  }
`;
