import { DatePicker, Form } from 'antd';
import FormMessage from 'components/Form/FormMessage/index';
import dayjs from 'dayjs';
import React from 'react';

const FormDateRangePicker = ({
  name,
  width,
  isShowTime,
  dateFormat,
  placeHolder,
  required,
  message,
}) => {
  const disabledDate = (current) => {
    if (isShowTime) {
      return (
        (current && current < dayjs().endOf('day')) ||
        current.isAfter('2038-12-31')
      );
    } else {
      return (
        !current ||
        current.isBefore('1920-01-01') ||
        current.isAfter('2038-12-31')
      );
    }
  };

  return (
    <Form.Item
      name={name}
      getValueProps={(i) => ({
        value:
          i === null
            ? null
            : i === undefined
              ? undefined
              : dayjs(i, dateFormat),
      })}
      rules={[
        {
          message: (
            <FormMessage text1={message} marginBottom={'12px'}></FormMessage>
          ),
          required: required,
        },
      ]}
    >
      <DatePicker
        placeholder={placeHolder}
        style={{ width: width }}
        showTime={
          isShowTime && {
            format: 'HH:mm',
          }
        }
        format={dateFormat}
        inputReadOnly={true}
        disabledTime
        disabledDate={(date) => disabledDate(date)}
      ></DatePicker>
    </Form.Item>
  );
};

export default FormDateRangePicker;
