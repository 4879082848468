import React, { useState, useRef, useEffect } from 'react';
import {
  ContentText,
  FormContentAndDate,
  ShowMoreTextSpan,
} from 'page/Application/ManageProductAndService/TopPageProduct/TopPageEditSuperDetailProduct/styled';
import parse from 'html-react-parser';
const ShowMoreTextSpanItem = ({ item }) => {
  const [height, setHeight] = useState('0');
  const [able, setAble] = useState(true);
  const myRef = useRef(null);

  const handleHeight = () => {
    setHeight('fit-content');
    setAble(false);
  };

  useEffect(() => {
    if (myRef.current) {
      const newHeight = myRef.current.clientHeight;
      setHeight(newHeight);
      if (newHeight < '53') {
        setHeight('fit-content');
        setAble(false);
      } else {
        setHeight('51px');
        setAble(true);
      }
    }
  }, [myRef.current]);

  return (
    <>
      <FormContentAndDate>
        <ContentText
          ref={myRef}
          style={{
            height: height,
          }}
        >
          {item?.detailExplanation && parse(item?.detailExplanation)}
        </ContentText>
      </FormContentAndDate>
      {able && (
        <ShowMoreTextSpan onClick={handleHeight}>続きを見る</ShowMoreTextSpan>
      )}
    </>
  );
};
export default ShowMoreTextSpanItem;
