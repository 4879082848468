import React, { useMemo } from 'react';
import { FormMessage } from 'components/Form/index';
import { FormItem } from 'components/Form2';
import { InputStyled } from 'components/Form2/styled';
import { MAX_LENGTH_TEXT } from 'constant/inputMaxlength';
import {
  CHECK_CHARACTER,
  CHECK_HAS_NUMBER,
  CHECK_LATIN_JAPANESE_NUMBER,
  CHECK_SPECIAL_CHARACTER_WITHOUT_SPACE,
} from 'constant/regex';
import {
  GridFormAccount,
  GridFormAccount1to2,
  GridFormAccount2to3,
} from 'page/Application/MyPage/component/FormMyPage/styled';
import LabelFormMyPage from 'page/Application/MyPage/component/FormMyPage/LabelFormMyPage';
import { SelectStyled } from 'components/Form2/FormEmailSelectDomain/styled';
import { getListDutyExisted } from 'api/account/account.api';
import useCustomQuery from 'hook/useQuery/useCustomQuery';

const DepartmentPositionDuty = ({ editMode }) => {
  const { useQueryWithoutParams } = useCustomQuery();

  const rulesValidateTextAndNumber = [
    () => ({
      validator(_, value) {
        if (
          value &&
          (CHECK_CHARACTER === null ||
            !CHECK_LATIN_JAPANESE_NUMBER.test(value) || // check japanese, latin
            (value && [...value].some((char) => char.codePointAt(0) > 65535))) // check 4-byte characters
        ) {
          return Promise.reject(
            <FormMessage
              text1={
                '無効な文字列です。全角半角文字数字のみで入力してください。'
              }
              marginBottom={'12px'}
            />,
          );
        }
        return Promise.resolve();
      },
    }),
  ];

  const rulesValidateText = [
    () => ({
      validator(_, value) {
        if (
          value &&
          (!CHECK_HAS_NUMBER.test(value) || // check has a number
            CHECK_SPECIAL_CHARACTER_WITHOUT_SPACE.test(value) ||
            // !CHECK_LATIN_JAPANESE.test(value) || // check japanese, latin
            // CHECK_NUMBER_AFTER_BLANK.test(value) || // check blank then number
            (value && [...value].some((char) => char.codePointAt(0) > 65535))) // check 4-byte characters
        ) {
          return Promise.reject(
            <FormMessage
              text1={'無効な文字列です。全角半角文字のみで入力してください。'}
              marginBottom={'12px'}
            />,
          );
        }
        return Promise.resolve();
      },
    }),
  ];

  const { data: listDuty = [] } = useQueryWithoutParams({
    api: getListDutyExisted,
    key: 'listDuty',
    options: {
      refetchOnWindowFocus: false,
    },
  });

  const dutyOptions = useMemo(() => {
    return listDuty?.data?.map((value) => ({
      label: value?.name,
      title: '',
      value: value?.id,
    }));
  }, [listDuty?.data]);
  return (
    <GridFormAccount>
      <GridFormAccount1to2>
        <LabelFormMyPage labelText={'部署'} />
      </GridFormAccount1to2>
      <GridFormAccount2to3>
        <FormItem name={'department'} rules={rulesValidateTextAndNumber}>
          <InputStyled
            maxLength={MAX_LENGTH_TEXT}
            placeholder={editMode ? '部署を入力してください。' : ''}
          />
        </FormItem>
      </GridFormAccount2to3>

      <GridFormAccount1to2>
        <LabelFormMyPage labelText={'役職'} />
      </GridFormAccount1to2>

      <GridFormAccount2to3>
        <FormItem name={'position'} rules={rulesValidateText}>
          <InputStyled
            maxLength={MAX_LENGTH_TEXT}
            placeholder={editMode ? '役職を入力してください。' : ''}
          />
        </FormItem>
      </GridFormAccount2to3>

      <GridFormAccount1to2>
        <LabelFormMyPage labelText={'職務'} />
      </GridFormAccount1to2>
      <GridFormAccount2to3>
        <FormItem name={'dutyId'}>
          <SelectStyled options={dutyOptions} />
        </FormItem>
      </GridFormAccount2to3>
    </GridFormAccount>
  );
};

export default DepartmentPositionDuty;
