import React from 'react';
import { Form, Input } from 'antd';
import useMultiLanguageRegisterProduct from '../../RegisterProduct/hook/useMultiLanguageRegisterProduct';
import FormMessage from 'components/Form/FormMessage';

const FormTextRequired = ({
  message,
  required,
  maxLength,
  name,
  placeHolder,
  isDisabled,
}) => {
  const { APPLICATION_PRODUCT_FIELD_REQUIRED } =
    useMultiLanguageRegisterProduct();

  return (
    <Form.Item
      name={name ?? 'name'}
      rules={[
        {
          required: required,
          message: <FormMessage text1={message} marginBottom={'12px'} />,
          validator: (_, value) => {
            const trimmedString = value?.trim();
            if (!value || trimmedString.length === 0 || value.trim() === '') {
              return Promise.reject(APPLICATION_PRODUCT_FIELD_REQUIRED);
            }
            return Promise.resolve();
          },
        },
      ]}
    >
      <Input
        disabled={isDisabled}
        maxLength={maxLength ?? 256}
        placeholder={placeHolder}
      />
    </Form.Item>
  );
};

export default FormTextRequired;
