import React from 'react';
import { DatePicker, Form } from 'antd';
import FormMessage from 'components/Form/FormMessage/index';
import dayjs from 'dayjs';
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';
import { range } from 'lodash';

dayjs.extend(isSameOrAfter);

const FormDateRangePicker = ({
  name,
  width,
  dateFormat,
  listDisable,
  isShowTime,
  form,
  isList,
  handleSelectedDate,
  isUpdateStatus,
}) => {
  const onOkSelectedDate = (dates) => {
    if (dates) {
      const status = form.getFieldValue('detailDisplayStatus');
      let dateFrom;
      let dateTo;
      if (status === 'SCHEDULED') {
        if (dates[0]) {
          dateFrom = dayjs(dates[0]).isBefore(dayjs()) ? null : dayjs(dates[0]);
          form.setFields([
            {
              name: 'publishedFromToDate',
              errors: [],
            },
          ]);
        }
      } else {
        dateFrom = null;
      }
      if (dates[1]) {
        dateTo = dayjs(dates[1]).isBefore(dayjs()) ? null : dayjs(dates[1]);
      }
      form.setFieldValue('publishedFromToDate', [dateFrom, dateTo]);
    }
  };

  const disableRangeTime = (date, _type) => {
    if (!date) {
      return {
        disabledHours: () => range(0, 24),
        disabledMinutes: () => range(0, 60),
      };
    }
    if (dayjs().isSameOrAfter(date, 'hours')) {
      return {
        disabledHours: () => range(0, dayjs().hour()),
        disabledMinutes: () => range(0, dayjs().minute() + 1),
      };
    }
    if (dayjs().isSameOrAfter(date, 'days')) {
      return {
        disabledHours: () => range(0, dayjs().hour()),
      };
    }
  };

  const disabledDate = (current) => {
    if (!isList) {
      return dayjs().isAfter(current, 'days') || current.isAfter('2038-12-31');
    }
  };

  return (
    <Form.Item
      name={name}
      rules={[
        {
          validator: (_, value) => {
            if (!isList || isUpdateStatus) {
              const status = form.getFieldValue('detailDisplayStatus');
              if (status === 'SCHEDULED' && !value?.[0]) {
                return Promise.reject(
                  <FormMessage
                    text1={'情報を入力してください。'}
                    marginBottom={'12px'}
                  />,
                );
              }
            }
            if (isUpdateStatus) {
              if (value?.[0] && value?.[1]) {
                if (
                  dayjs().isSameOrAfter(value?.[0], 'minutes') &&
                  dayjs().isSameOrAfter(value?.[1], 'minutes')
                ) {
                  return Promise.reject(
                    <FormMessage
                      text1={'公開日時予約・非公開日時予約が無効です。'}
                      marginBottom={'12px'}
                    ></FormMessage>,
                  );
                }
              }
              if (value?.[0] && dayjs().isSameOrAfter(value?.[0], 'minutes')) {
                return Promise.reject(
                  <FormMessage
                    text1={
                      '「公開日時予約」は現在時点後よりの日時を入力してください。'
                    }
                    marginBottom={'12px'}
                  ></FormMessage>,
                );
              }
            }
            return Promise.resolve();
          },
        },
      ]}
    >
      <DatePicker.RangePicker
        popupClassName="dateRangePicker"
        showTime={
          isShowTime && {
            format: 'HH:mm',
          }
        }
        format={dateFormat}
        style={{ width: width ?? '250px' }}
        placeholder={['日付From', '日付To']}
        disabled={listDisable}
        allowEmpty={true}
        inputReadOnly={true}
        disabledDate={disabledDate}
        disabledTime={disableRangeTime}
        onCalendarChange={handleSelectedDate}
        onOk={onOkSelectedDate}
      />
    </Form.Item>
  );
};
export default FormDateRangePicker;
