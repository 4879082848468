import React, { useMemo, useState } from 'react';
import Breadcrumb from 'components/Layout/ApplicationLayout/Breadcrumb';
import LayoutDefault from 'components/Layout/LayoutDefault';
import HeaderListDataLayout from 'components/Layout/HeaderListDataLayout';
import useMultiLanguageCompanyList from 'page/Application/ManageCompanyList/CompanyList/hook/useMultiLanguageCompanyList';
import useSearchKeyword from 'hook/useSearchKeyword';
import Thumbnail from 'page/Application/ProjectRegistration/component/Thumbnail';
import ErrorImage from 'assets/images/errorImage.jpg';
import Pagination from 'components/Pagination';
import useCustomQuery from 'hook/useQuery/useCustomQuery';
import { useRecoilValue } from 'recoil';
import { getListProject } from 'api/project/solitonAdmin.api';
import { userInformation } from 'recoil/atom/authentication';
import { Link, useNavigate } from 'react-router-dom';
import { Empty, Spin, Row, Col, Tooltip } from 'antd';
import { InboxOutlined } from '@ant-design/icons';
import { network } from 'recoil/atom/network';
import {
  defaultPageSizeOptions,
  defaultSelectPageSizeOption,
} from 'constant/Pagination/pageSizeOption';
import {
  DescProject,
  ProjectItem,
  TitleProject,
} from 'page/Application/ProjectRegistration/ProjectRegistrationOverview/styled';

const ProjectPreview = () => {
  const navigate = useNavigate();
  const isOnline = useRecoilValue(network);
  const { role } = useRecoilValue(userInformation);

  const { COMPANY_LIST_NO_DATA } = useMultiLanguageCompanyList();
  const { searchKeyword, onSearch, emptyText } = useSearchKeyword();
  const { useQueryAPI } = useCustomQuery();

  const [searchValue, setSearchValue] = useState('');
  const [current, setCurrent] = useState(1);
  const [page, setPage] = useState(10);

  const { data: projectData = [], isLoading } = useQueryAPI({
    api: getListProject,
    key: 'projectList',
    params: {
      pageNumber: current,
      pageSize: page,
      keyword: encodeURIComponent(searchKeyword) ?? searchKeyword,
      status: '',
      displayedDateFrom: '',
      displayedDateTo: '',
      category: '',
      sortField: '',
      sortDirection: '',
      isViewMode: true,
    },
  });

  const listItemSA = useMemo(() => {
    return [
      {
        title: <Link to={'/'}>企業管理</Link>,
      },
      {
        title: '案件登録お申込み一覧',
      },
    ];
  }, []);

  const listItemSU = useMemo(() => {
    return [
      {
        title: <Link to={'/'}>マイページ</Link>,
      },
      {
        title: '案件登録お申込み一覧',
      },
    ];
  }, []);

  const listItem = useMemo(() => {
    return {
      ROLE_SOLITON_USER: listItemSU,
      ROLE_SOLITON_ADMIN: listItemSA,
    };
  }, [listItemSA, listItemSU]);

  const handleMoveToDetail = (id) => {
    if (isOnline) {
      navigate(`detail/${id}`);
    }
  };

  const projectList = useMemo(() => {
    if (projectData && projectData.ec === false) {
      return projectData.data.list;
    } else return [];
  }, [projectData]);

  const handleChangePageSize = (value) => {
    if (isOnline) {
      setPage(value);
      setCurrent(1);
    }
  };

  const handlePagingChange = (pageNumber, pageSize) => {
    if (isOnline) {
      setCurrent(pageNumber);
      setPage(pageSize);
    }
  };

  return (
    <>
      <Breadcrumb
        listItem={listItem[role]}
        titlePage={'案件登録お申込み一覧'}
      />
      <LayoutDefault>
        <Spin spinning={isLoading} style={{ maxHeight: 'unset' }}>
          <HeaderListDataLayout
            titleScreen={'案件登録お申し込み一覧'}
            searchPlaceholder={'タイトルに従え、検索します '}
            isLoading={isLoading}
            onSearch={onSearch}
            width={300}
            value={page}
            handleChangePageSize={handleChangePageSize}
            pageSizeOptions={defaultSelectPageSizeOption}
            searchValue={searchValue}
            setSearchValue={setSearchValue}
          />
          <>
            {isLoading && <div style={{ height: 520 }} />}
            {projectList.length === 0 && isLoading === false && (
              <Empty
                image={<InboxOutlined />}
                imageStyle={{ fontSize: 80 }}
                description={null}
                style={{ fontSize: '16px', minHeight: 532 }}
              >
                {emptyText || COMPANY_LIST_NO_DATA}
              </Empty>
            )}
            {projectList.length > 0 && isLoading === false && (
              <Row gutter={20}>
                {projectList.map((item) => (
                  <Col
                    xs={24}
                    sm={12}
                    md={12}
                    lg={8}
                    xl={6}
                    key={item.projectId}
                  >
                    <ProjectItem>
                      <div onClick={() => handleMoveToDetail(item.projectId)}>
                        {item.image === '' ? (
                          <Thumbnail imageUrl={ErrorImage} />
                        ) : (
                          <Thumbnail imageUrl={item.image} />
                        )}
                      </div>
                      <DescProject>
                        <TitleProject
                          onClick={() => handleMoveToDetail(item.projectId)}
                        >
                          <Tooltip
                            title={item.projectName}
                            placement={'topLeft'}
                          >
                            {item.projectName}
                          </Tooltip>
                        </TitleProject>
                      </DescProject>
                    </ProjectItem>
                  </Col>
                ))}
              </Row>
            )}
          </>
        </Spin>
        <Pagination
          totalTitle={'案件登録お申込み'}
          total={projectData.data?.total}
          currentPage={current}
          pageSize={page}
          pageSizeOptions={defaultPageSizeOptions}
          handlePagingChange={handlePagingChange}
          handleChangePageSize={handleChangePageSize}
        />
      </LayoutDefault>
    </>
  );
};

export default ProjectPreview;
