import FormMessage from 'components/Form/FormMessage/index';
import React from 'react';
import { Form, Select } from 'antd';

const FormDropDown = ({ name, required, data, messageRequired, width }) => {
  return (
    <Form.Item
      name={name}
      rules={[
        {
          required: required,
          message: (
            <FormMessage
              text1={messageRequired}
              marginBottom={'12px'}
            ></FormMessage>
          ),
        },
      ]}
    >
      <Select
        popupMatchSelectWidth={false}
        options={data}
        allowClear
        // style={{ width: width ?? '250px' }}
      />
    </Form.Item>
  );
};

export default FormDropDown;
