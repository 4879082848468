import { useIntl } from 'react-intl';

const useMultiLanguageRegisterCategory = () => {
  const intl = useIntl();

  const APPLICATION_REGISTERCATEGORY_TITLE = intl.formatMessage({
    id: 'application.registerCategory.title',
  });

  const APPLICATION_REGISTERCATEGORY_FORM_TITLE = intl.formatMessage({
    id: 'application.registerCategory.form.title',
  });

  const APPLICATION_REGISTERCATEGORY_FORM_STATUS = intl.formatMessage({
    id: 'application.registerCategory.form.status',
  });

  const APPLICATION_REGISTERCATEGORY_PLACEHOLDER_TITLE = intl.formatMessage({
    id: 'application.registerCategory.placeholder.title',
  });

  const APPLICATION_REGISTERCATEGORY_STATUS_SHOW = intl.formatMessage({
    id: 'application.registerCategory.status.show',
  });

  const APPLICATION_REGISTERCATEGORY_STATUS_HIDE = intl.formatMessage({
    id: 'application.registerCategory.status.hide',
  });

  const APPLICATION_REGISTERCATEGORY_FORM_SUBMIT = intl.formatMessage({
    id: 'application.registerCategory.form.submit',
  });

  const APPLICATION_REGISTERCATEGORY_FORM_CANCEL = intl.formatMessage({
    id: 'application.registerCategory.form.cancel',
  });

  const APPLICATION_REGISTERCATEGORY_FIELD_REQUIRED = intl.formatMessage({
    id: 'application.registerCategory.field.required',
  });

  const APPLICATION_REGISTERCATEGORY_BUTTON_KEEP = intl.formatMessage({
    id: 'application.registerCategory.button.keep',
  });

  const APPLICATION_REGISTERCATEGORY_BUTTON_DELETE = intl.formatMessage({
    id: 'application.registerCategory.button.delete',
  });

  const APPLICATION_REGISTERCATEGORY_OK = intl.formatMessage({
    id: 'application.registerCategory.ok',
  });

  const APPLICATION_REGISTERCATEGORY_MESSAGE_CANCEL = intl.formatMessage({
    id: 'application.registerCategory.message.cancel',
  });

  const APPLICATION_REGISTERCATEGORY_DELETE = intl.formatMessage({
    id: 'application.registerCategory.delete',
  });

  const APPLICATION_REGISTERCATEGORY_MESSAGE_DELETE = intl.formatMessage({
    id: 'application.registerCategory.message.delete',
  });

  const APPLICATION_REGISTERCATEGORY_MESSAGE_ADD_SUCCESS = intl.formatMessage({
    id: 'application.registerCategory.message.add.success',
  });

  const APPLICATION_REGISTERCATEGORY_FORM_WARNING_MESSAGE = intl.formatMessage({
    id: 'application.registerCategory.form.warning.message',
  });

  const APPLICATION_REGISTERCATEGORY_FORM_PRODUCT = intl.formatMessage({
    id: 'application.registerCategory.form.product',
  });

  const APPLICATION_REGISTERCATEGORY_FORM_SUBMIT_EXISTING_PRODUCT =
    intl.formatMessage({
      id: 'application.registerCategory.form.submit.existing.product',
    });

  return {
    APPLICATION_REGISTERCATEGORY_TITLE,
    APPLICATION_REGISTERCATEGORY_FORM_TITLE,
    APPLICATION_REGISTERCATEGORY_FORM_STATUS,
    APPLICATION_REGISTERCATEGORY_PLACEHOLDER_TITLE,
    APPLICATION_REGISTERCATEGORY_STATUS_SHOW,
    APPLICATION_REGISTERCATEGORY_STATUS_HIDE,
    APPLICATION_REGISTERCATEGORY_FORM_SUBMIT,
    APPLICATION_REGISTERCATEGORY_FORM_CANCEL,
    APPLICATION_REGISTERCATEGORY_FIELD_REQUIRED,
    APPLICATION_REGISTERCATEGORY_BUTTON_KEEP,
    APPLICATION_REGISTERCATEGORY_BUTTON_DELETE,
    APPLICATION_REGISTERCATEGORY_OK,
    APPLICATION_REGISTERCATEGORY_MESSAGE_CANCEL,
    APPLICATION_REGISTERCATEGORY_DELETE,
    APPLICATION_REGISTERCATEGORY_MESSAGE_DELETE,
    APPLICATION_REGISTERCATEGORY_MESSAGE_ADD_SUCCESS,
    APPLICATION_REGISTERCATEGORY_FORM_WARNING_MESSAGE,
    APPLICATION_REGISTERCATEGORY_FORM_PRODUCT,
    APPLICATION_REGISTERCATEGORY_FORM_SUBMIT_EXISTING_PRODUCT,
  };
};

export default useMultiLanguageRegisterCategory;
