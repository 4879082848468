export const ACCOUNT_STATUS = {
  enable: {
    label: '有効',
    value: 'ENABLED',
  },
  unapproved: {
    label: '未承認',
    value: 'UNAPPROVED',
  },
  suspended: {
    label: '無効',
    value: 'SUSPENDED',
  },
};
