import React, { useEffect } from 'react';
import { getDetailNotification } from 'api/notification/notification.api';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import Breadcrumb from 'components/Layout/ApplicationLayout/Breadcrumb';
import LayoutDefault from 'components/Layout/LayoutDefault2';
import {
  DATE_FORMAT_LIST,
  NOTIFICATION_DESTINATION_TYPE_DATA,
} from 'constant/notificationConstants';
import dayjs from 'dayjs';
import useNotifyCategoryMultiLanguage from 'hook/useNotifyCategoryMultiLanguage';
import useCustomQuery from 'hook/useQuery/useCustomQuery';
import parse from 'html-react-parser';
import { FormLabelGrid } from 'page/Application/ManageNotificationList/component/Form';
import FormFooterPreview from 'page/Application/ManageNotificationList/component/Form/FormFooterPreview';
import FormTitle from 'page/Application/ManageNotificationList/component/Form/FormTitle';
import { useNotification } from 'page/Application/ManageNotificationList/component/NotifcationContext';
import useMultiLanguagePreviewNotification from 'page/Application/ManageNotificationList/PreviewNotification/hook/useMultiLanguagePreviewNotification';
import {
  ContentBlock,
  TitleBlock,
  ButtonPreview,
  FormWrapper,
  Grid1,
  Grid2,
  Grid3,
  Grid4,
  GridFormPreview,
  GridTitle,
  LayoutPreview,
  SpanPreview,
  ContainerBlock,
} from './styled';

const PreviewNotification = () => {
  const { currentNotification, setCurrentNotification } = useNotification();
  const { NOTIFICATION_CATEGORY_FOR_LIST } = useNotifyCategoryMultiLanguage();
  const navigate = useNavigate();
  const { PREVIEW_NOTIFICATION_TITLE, PREVIEW_NOTIFICATION_MESSAGE_VIEWING } =
    useMultiLanguagePreviewNotification();
  const { useQueryAPI } = useCustomQuery();
  const { state } = useLocation();

  const listItem = [
    {
      title: <Link to={'/'}>企業管理</Link>,
    },
    {
      title: <Link to={'/manage-notification-list'}>お知らせ管理</Link>,
    },
    {
      title: (
        <Link to={`/manage-notification-list/edit/${state?.notifyId}`}>
          お知らせ登録
        </Link>
      ),
    },
    {
      title: 'プレビュー',
    },
  ];

  const { data: notificationDetail = [] } = useQueryAPI({
    api: getDetailNotification,
    key: 'notificationPreview',
    params: {
      notifyId: state?.notifyId,
    },
    enabled: !!state?.notifyId,
  });

  useEffect(() => {
    if (!currentNotification) {
      setCurrentNotification(notificationDetail?.data);
    }
  }, [currentNotification, notificationDetail, setCurrentNotification, state]);

  return (
    <>
      <Breadcrumb titlePage={'プレビュー'} listItem={listItem} />
      <LayoutPreview
        content={`${
          currentNotification?.destinationType ===
          NOTIFICATION_DESTINATION_TYPE_DATA[1].value
        }`}
      >
        <LayoutDefault>
          <GridTitle>
            <Grid1>
              <FormTitle
                title={PREVIEW_NOTIFICATION_TITLE}
                styles={{ border: 'none' }}
              />
            </Grid1>
            <Grid4>
              <div style={{ float: 'right', fontSize: 12 }}>
                {PREVIEW_NOTIFICATION_MESSAGE_VIEWING}
              </div>
            </Grid4>
          </GridTitle>
          <FormWrapper>
            <GridFormPreview>
              <Grid1>
                <FormLabelGrid
                  textAlign={'left'}
                  labelText={dayjs(currentNotification?.displayedDate).format(
                    DATE_FORMAT_LIST[1],
                  )}
                ></FormLabelGrid>
              </Grid1>
              <Grid2>
                <FormLabelGrid
                  textAlign={'left'}
                  labelText={
                    NOTIFICATION_CATEGORY_FOR_LIST[
                      currentNotification?.category
                    ]
                  }
                ></FormLabelGrid>
              </Grid2>
              <Grid3>
                {currentNotification?.destinationType ===
                NOTIFICATION_DESTINATION_TYPE_DATA[0].value ? (
                  <ButtonPreview type="link" style={{ cursor: 'pointer' }}>
                    <FormLabelGrid
                      textAlign={'left'}
                      labelText={currentNotification?.title}
                    ></FormLabelGrid>
                  </ButtonPreview>
                ) : (
                  <ButtonPreview type="link">
                    <FormLabelGrid
                      textAlign={'left'}
                      labelText={
                        <SpanPreview>{currentNotification?.title}</SpanPreview>
                      }
                    ></FormLabelGrid>
                  </ButtonPreview>
                )}
              </Grid3>
            </GridFormPreview>
          </FormWrapper>
          <FormFooterPreview
            alignItems={'end'}
            textOk={'戻る'}
            onFinish={() => navigate(-1)}
          ></FormFooterPreview>
        </LayoutDefault>
        {currentNotification?.destinationType ===
          NOTIFICATION_DESTINATION_TYPE_DATA[1].value && (
          <LayoutDefault>
            <TitleBlock>{currentNotification?.title}</TitleBlock>
            <ContainerBlock>
              <ContentBlock>
                {currentNotification?.content &&
                  parse(currentNotification?.content)}
              </ContentBlock>
            </ContainerBlock>
          </LayoutDefault>
        )}
      </LayoutPreview>
    </>
  );
};
export default PreviewNotification;
