import React from 'react';
import { Form, Input } from 'antd';

const FormText = ({
  maxLength,
  name,
  placeHolder,
  isDisabled,
  defaultValue,
  onChange,
}) => {
  return (
    <Form.Item name={name ?? 'name'}>
      <Input
        defaultValue={defaultValue}
        disabled={isDisabled}
        maxLength={maxLength}
        placeholder={placeHolder}
        onChange={onChange}
      />
    </Form.Item>
  );
};

export default FormText;
