import { LinkNotifyDetail } from 'page/Application/ManageProductAndService/TopPageProductPartnerUser/component/LinkNotify/styled';

const LinkNotify = ({ handleNavigate, text, type }) => {
  return (
    <LinkNotifyDetail onClick={handleNavigate} type={type}>
      {text}
    </LinkNotifyDetail>
  );
};

export default LinkNotify;
