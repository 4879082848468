import React from 'react';
import { LinkStyled } from './styled';

const Link = ({ text, to, fontSize, decoration, target }) => {
  return (
    <LinkStyled
      to={to}
      fontSize={fontSize}
      style={{ textDecoration: decoration }}
      target={target}
    >
      {text}
    </LinkStyled>
  );
};

export default Link;
