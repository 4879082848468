import { useIntl } from 'react-intl';

const ValidateEmailLanguage = () => {
  const intl = useIntl();

  const FORM_EMAIL_ADDRESS_LABEL = intl.formatMessage({
    id: 'authentication.emailAddress',
  });
  const FORM_EMAIL_ADDRESS_PLACEHOLDER = intl.formatMessage({
    id: 'authentication.emailAddress.placeholder',
  });

  const FORM_EMAIL_ADDRESS_ERROR_MESSAGE_EMPTY = intl.formatMessage({
    id: 'authentication.email.errorMessage.empty',
  });

  const FORM_EMAIL_ADDRESS_ERROR_MESSAGE_VALIDATE = intl.formatMessage({
    id: 'authentication.email.errorMessage.validate',
  });

  return {
    FORM_EMAIL_ADDRESS_LABEL,
    FORM_EMAIL_ADDRESS_PLACEHOLDER,
    FORM_EMAIL_ADDRESS_ERROR_MESSAGE_EMPTY,
    FORM_EMAIL_ADDRESS_ERROR_MESSAGE_VALIDATE,
  };
};

export default ValidateEmailLanguage;
