import styled from 'styled-components';
import { Button } from 'antd';

export const FormFooterWrapper = styled.div`
  display: flex;
  gap: 24px;
`;

export const ButtonDelete = styled(Button)`
  background: #656464;
  color: rgba(255, 255, 255, 1);
  //fixbug:514
  min-width: 86px;
  span {
    font-family: 'inter-regular', sans-serif;
    font-size: 14px;
  }
  &:hover {
    color: white !important;
    background: #656464 !important;
    border: 1px solid rgba(155, 155, 155, 0.1) !important;
  }
`;
