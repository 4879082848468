import React, { useEffect, useMemo, useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import Breadcrumb from 'components/Layout/ApplicationLayout/Breadcrumb';

import {
  DateTable,
  FormContent,
  FormHeader,
  TitleTableDetail,
} from 'page/Application/PartnerWebsite/styled';
import dayjs from 'dayjs';
import { getDetailPartnerPage } from 'api/partner/partner.api';
import useCustomQuery from 'hook/useQuery/useCustomQuery';
import { Spin } from 'antd';
import { useRecoilValue } from 'recoil';
import { partnerIdRecoil, userInformation } from 'recoil/atom/authentication';
import useSetTitlePage from 'hook/useSetTitlePage';
import { BreadCrumbDetail } from 'page/Application/ManageProductAndService/TopPageProduct/TopPageProductDetail/styled';

const PartnerWebsiteDetail = () => {
  useSetTitlePage('パートナー専用ページ詳細');
  const { useQueryAPI } = useCustomQuery();
  const navigate = useNavigate();
  const { partnerId: partnerIdParam, noticeId } = useParams();
  const { role } = useRecoilValue(userInformation);
  const { partnerId } = useRecoilValue(partnerIdRecoil);
  const [mainPartnerId] = useState(
    partnerIdParam ||
      partnerId ||
      JSON.parse(localStorage['user_information'])?.partnerId,
  );

  const {
    data: detailPartnerPage,
    isLoading,
    error,
  } = useQueryAPI({
    api: getDetailPartnerPage,
    key: 'partnerPageDetail',
    params: {
      partnerId: mainPartnerId,
      noticeId: noticeId,
    },
  });

  const listItem_SA = useMemo(() => {
    return [
      {
        title: <Link to={`/manage-company-list`}>企業管理</Link>,
      },
      {
        title: (
          <Link to={`/manage-company-list/edit/${mainPartnerId}`}>
            企業詳細
          </Link>
        ),
      },
      {
        title: (
          <Link to={`/partner-page/${mainPartnerId}`}>
            パートナー専用ページ
          </Link>
        ),
      },
      {
        title: (
          <BreadCrumbDetail>
            {detailPartnerPage?.data?.detailTitle}
          </BreadCrumbDetail>
        ),
      },
    ];
  }, [detailPartnerPage?.data?.detailTitle, mainPartnerId]);

  const listItem_SU = useMemo(() => {
    return [
      {
        title: <Link to={`/my-page`}>マイページ</Link>,
      },
      {
        title: (
          <Link to={`/partner-page/${mainPartnerId}`}>
            パートナー専用ページ
          </Link>
        ),
      },
      {
        title: (
          <BreadCrumbDetail>
            {detailPartnerPage?.data?.detailTitle}
          </BreadCrumbDetail>
        ),
      },
    ];
  }, [detailPartnerPage?.data?.detailTitle, mainPartnerId]);

  const listItem_PA_PU = useMemo(() => {
    return [
      {
        title: <Link to={`/partner-page`}>パートナー専用ページ</Link>,
      },
      {
        title: (
          <BreadCrumbDetail>
            {detailPartnerPage?.data?.detailTitle}
          </BreadCrumbDetail>
        ),
      },
    ];
  }, [detailPartnerPage?.data?.detailTitle]);

  const listItemByRole = useMemo(() => {
    return {
      ROLE_SOLITON_ADMIN: listItem_SA,
      ROLE_SOLITON_USER: listItem_SU,
      ROLE_PARTNER_ADMIN: listItem_PA_PU,
      ROLE_PARTNER_USER: listItem_PA_PU,
    };
  }, [listItem_SA, listItem_SU, listItem_PA_PU]);

  useEffect(() => {
    if (
      detailPartnerPage?.ec ||
      error?.response?.data?.message === '400_NOTICE_NOT_EXISTED' ||
      error?.response?.data?.message === '400_ARGUMENT_IS_NOT_VALID' ||
      error?.response?.data?.message === '400_CAN_NOT_ACCESS_DATA' ||
      error?.response?.data?.message === '400_NOT_IN_CHARGE_ANY_PARTNER'
    ) {
      navigate('/partner-not-found');
    }
  }, [
    noticeId,
    mainPartnerId,
    navigate,
    error?.response?.data?.message,
    detailPartnerPage?.ec,
  ]);

  return (
    <Spin spinning={isLoading}>
      <Breadcrumb
        listItem={listItemByRole[role]}
        titlePage="パートナー専用ページ"
      />
      <div className="information-wrapper">
        <div className="base-text information-wrapper__title">お知らせ詳細</div>
        <FormHeader>
          <TitleTableDetail>
            {detailPartnerPage?.data?.detailTitle}
          </TitleTableDetail>
          <DateTable style={{ fontWeight: 400, fontSize: 14 }}>
            {dayjs(detailPartnerPage?.data?.detailDisplayDate).format(
              'YYYY年MM月DD日',
            )}
          </DateTable>
        </FormHeader>
        <FormContent>
          {detailPartnerPage?.data?.detailSearchContent}
        </FormContent>
      </div>
    </Spin>
  );
};
export default PartnerWebsiteDetail;
