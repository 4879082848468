import React from 'react';
import LabelFormMyPage from 'page/Application/MyPage/component/FormMyPage/LabelFormMyPage';
import { FormItem } from 'components/Form2';
import { Checkbox, Space, Tooltip } from 'antd';
import { HeaderSelectDomain } from 'page/Application/MyPage/styled';
import {
  Grid1to2,
  Grid2to3,
  GridForm,
} from 'page/Application/MyPagePartnerAdmin/styled';

const CheckboxDomain = ({ domainSelected, setDomainSelected }) => {
  const handleSelectDomain = (e, id) => {
    const nextCheck = domainSelected.map((item) => {
      if (item.domainId !== id) {
        return item;
      } else {
        return {
          ...item,
          isAutoApprove: e.target.checked,
        };
      }
    });
    setDomainSelected(nextCheck);
  };

  return (
    <GridForm>
      <Grid1to2>
        <LabelFormMyPage labelText={'アカウント登録ドメイン'} />
      </Grid1to2>
      <Grid2to3>
        <FormItem name="lstDomainResponses">
          <div>
            <HeaderSelectDomain>
              <div>ドメイン</div>
              <div>自動承認</div>
            </HeaderSelectDomain>
            <div>
              <Space direction="vertical" style={{ width: '100%' }}>
                {domainSelected?.map((item, index) => (
                  <div
                    key={index}
                    style={{
                      display: 'flex',
                      width: '100%',
                      justifyContent: 'space-between',
                      wordWrap: 'break-word',
                      fontFamily: 'inter-regular, sans-serif',
                    }}
                  >
                    <div
                      style={{
                        paddingLeft: 20,
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        whiteSpace: 'nowrap',
                        maxWidth: 300,
                      }}
                    >
                      <Tooltip title={item.domainName} placement={'topLeft'}>
                        {item.domainName}
                      </Tooltip>
                    </div>
                    <Checkbox
                      style={{
                        paddingRight: 34,
                      }}
                      // value={item.domainName}
                      key={item.domainId}
                      checked={item.isAutoApprove}
                      onChange={(e) => handleSelectDomain(e, item.domainId)}
                    />
                  </div>
                ))}
              </Space>
            </div>
          </div>
        </FormItem>
      </Grid2to3>
    </GridForm>
  );
};

export default CheckboxDomain;
