import React from 'react';
import AccountSolitonList from 'page/Application/ManageAccountSoliton/AccountSolitonList';
import RegisterAccountSoliton from 'page/Application/ManageAccountSoliton/RegisterAccountSoliton';
import EditAccountSoliton from 'page/Application/ManageAccountSoliton/EditAccountSoliton';
import { Outlet } from 'react-router-dom';
import useSetTitlePage from 'hook/useSetTitlePage';

const ManageAccountSoliton = () => {
  useSetTitlePage('アカウント管理');

  return <Outlet />;
};

export default ManageAccountSoliton;

export { AccountSolitonList, RegisterAccountSoliton, EditAccountSoliton };
