import { Divider, Tooltip } from 'antd';
import { CategoryTopPageSpan, DividerStyled } from './styled';

const CategoryTopPage = ({ categoryName }) => {
  return (
    <DividerStyled
      orientation="left"
      orientationMargin="0"
      style={{ margin: 0, alignItems: 'baseline' }}
    >
      <Tooltip title={categoryName} placement="topLeft">
        <CategoryTopPageSpan>{categoryName}</CategoryTopPageSpan>
      </Tooltip>
    </DividerStyled>
  );
};

export default CategoryTopPage;
