import axiosClient from 'api/config/axiosClient';

const getDetailMyPageCompanyInformation = () => {
  const url = `v1/partner/mypage`;
  return axiosClient.get(url);
};

const updateMyPageCompanyInformation = (payload) => {
  const url = `v1/partner/mypage`;
  return axiosClient.put(url, payload);
};

export { getDetailMyPageCompanyInformation, updateMyPageCompanyInformation };
