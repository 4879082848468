import styled from 'styled-components';

export const GridLayout = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  min-height: 510px;
  margin-top: 20px;
  gap: 24px;
`;

export const TitleTable = styled.div`
  font-family: 'inter-regular', sans-serif;
  color: #656464;
  line-height: 1.5;
`;
export const TitleTableDetail = styled.div`
  line-height: 1.5;
  font-weight: 600;
  word-break: break-word;
  margin-bottom: 8px;
`;
export const DateTable = styled.div`
  min-width: 120px;
  font-family: 'inter-regular', sans-serif;
  color: #656464;
  font-size: 14px;
  line-height: 1.5;
  @media (min-width: 1025px) {
    padding-left: 50px;
  }
`;

export const BaseGrid = styled.div`
  background: white;
  border-radius: 8px;
  padding: 20px;
  @media all and (min-width: 0px) and (max-width: 320px) {
    padding: 10px;
  }
  @media all and (min-width: 320px) and (max-width: 430px) {
    padding: 10px;
  }
`;
export const Grid1 = styled(BaseGrid)`
  grid-column: 1 / 2;
  @media (max-width: 1200px) {
    grid-column: 1 / 3;
  }
`;

export const Grid2 = styled(BaseGrid)`
  grid-column: 2 / 3;
  @media (max-width: 1200px) {
    grid-column: 1 / 3;
  }
`;

export const BlockWrapper = styled.div`
  //min-height: calc(100dvh - 530px);
  height: fit-content;
`;

export const EditMode = styled.div`
  float: right;
  padding-top: 20px;
`;

export const BlockTitle = styled.div`
  font-family: 'inter-regular', sans-serif;
  background: #f4f4f4;
  padding: 20px 12px;
  border-radius: 6px;
  color: #656464;
  font-weight: 700;
  white-space: nowrap;
`;

export const ItemWrapper = styled.div`
  font-family: 'inter-regular', sans-serif;
  margin-top: 20px;
`;

export const ItemPartner = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  line-height: 2.5;
  font-size: 14px;
  margin-left: 12px;
  color: #656464;
`;

export const ContentAndDateFile = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;

  @media all and (min-width: 0px) and (max-width: 320px) {
    display: initial;
    padding: 4px 0 20px;
  }
  @media all and (min-width: 321px) and (max-width: 430px) {
    display: initial;
    padding: 4px 0 20px;
  }
  @media all and (min-width: 431px) and (max-width: 768px) {
    display: initial;
    padding: 4px 0 20px;
  }
`;

export const TextOverflow = styled.div`
  color: #656464;
  line-height: 2.5;
  font-family: 'inter-regular', sans-serif;
  font-size: 14px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const TextNotify = styled(TextOverflow)`
  margin-left: 12px;
  margin-bottom: 8px;
  line-height: 16px;
`;

export const ContentText = styled.div`
  white-space: nowrap;
  line-height: 24px;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const BreakDownContentText = styled.div`
  white-space: pre-line;
  word-break: break-all;
  line-height: 24px;
  cursor: ${(props) => (props?.hasdetail ? 'pointer' : '')};
  text-decoration: ${(props) => (props?.hasdetail ? 'underline' : '')};
  &:hover {
    color: ${(props) => (props?.hasdetail ? '#1890FF' : '')};
  }
`;

export const TextDateNotify = styled(TextOverflow)`
  margin: 0 12px 0 32px;
  opacity: 0.7;
  max-width: calc(50dvh);
  line-height: 16px;
`;

export const TextDate = styled(TextOverflow)`
  padding-left: 20px;
  width: 120px;
  @media all and (min-width: 0px) and (max-width: 320px) {
    padding-left: 0;
    line-height: 24px;
  }
  @media all and (min-width: 321px) and (max-width: 430px) {
    padding-left: 0;
    line-height: 24px;
  }
  @media all and (min-width: 431px) and (max-width: 768px) {
    padding-left: 0;
    line-height: 24px;
  }
`;

export const TextOverflowHidden = styled(TextOverflow)`
  color: #656464;
  line-height: 40px;
  font-family: 'inter-regular', sans-serif;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  @media all and (min-width: 0px) and (max-width: 320px) {
    width: 200px;
    max-width: 200px;
    line-height: 24px;
  }
  @media all and (min-width: 321px) and (max-width: 430px) {
    width: 200px;
    max-width: 200px;
    line-height: 24px;
  }
  @media all and (min-width: 431px) and (max-width: 768px) {
    width: 300px;
    max-width: 300px;
  }
  @media all and (min-width: 769px) and (max-width: 1024px) {
    max-width: 480px;
  }
  @media all and (min-width: 1025px) and (max-width: 1200px) {
    max-width: 700px;
  }
  @media all and (min-width: 1201px) and (max-width: 1700px) {
    max-width: 200px;
  }
  @media all and (min-width: 1701px) and (max-width: 1920px) {
    max-width: 400px;
  }
  @media (min-width: 1921px) {
    max-width: 500px;
  }
`;
export const ButtonDownload = styled.div`
  @media all and (min-width: 0px) and (max-width: 320px) {
    height: 68px;
  }
  @media all and (min-width: 321px) and (max-width: 430px) {
    height: 68px;
  }
  @media all and (min-width: 431px) and (max-width: 768px) {
    height: 68px;
  }
`;

export const GroupButton = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 12px;
`;

export const ItemNotify = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const HeaderTitleName = styled.div``;

export const HeaderShowEmail = styled.div`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 1510px;
  width: 99%;
`;

export const HeaderEditMode = styled.div`
  display: flex;
  justify-content: space-between;

  .ant-form-item-label > label::after {
    content: '';
  }

  @media all and (min-width: 0px) and (max-width: 1200px) {
    display: unset;
  }
`;

export const WarningTitleHeader = styled.span`
  font-size: 12px;
  color: var(--PRIMARY-default);
  padding-top: 2px;
  display: flex;
  align-items: center;
  padding-left: 60px;
`;

export const Text = styled.div`
  display: flex;
  justify-content: space-between;
  color: #656464;
  font-size: 16px;
  height: 40px;
  align-items: center;
`;
export const EmailTitle = styled(Text)`
  font-weight: 600;
  margin-bottom: 25px;
`;

export const FormHeader = styled.div`
  color: #656464;
  font-family: 'inter-regular', sans-serif;
  display: flex;
  justify-content: space-between;

  @media all and (min-width: 0px) and (max-width: 320px) {
    display: initial;
  }
  @media all and (min-width: 321px) and (max-width: 430px) {
    display: initial;
  }
  @media all and (min-width: 431px) and (max-width: 768px) {
    display: initial;
  }
  @media all and (min-width: 769px) and (max-width: 1024px) {
    display: initial;
  }
`;
export const FormContent = styled.p`
  color: #656464;
  font-family: 'inter-regular', sans-serif;
  margin: 20px 0;
  line-height: 1.5;
  white-space: pre-line;
  word-wrap: break-word;
`;
