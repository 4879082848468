import React from 'react';
import {
  Container,
  ContentWrapper,
  BlockFooter,
  BlockHeader,
} from 'components/Layout/LayoutDefault/styled';

const LayoutDefault = ({ children, minHeight }) => {
  return (
    <ContentWrapper style={{ minHeight: minHeight }}>
      <Container>
        <BlockHeader />
        {children}
        <BlockFooter />
      </Container>
    </ContentWrapper>
  );
};

export default LayoutDefault;
