import React from 'react';
import { restrictToVerticalAxis } from '@dnd-kit/modifiers';
import {
  arrayMove,
  SortableContext,
  useSortable,
  verticalListSortingStrategy,
} from '@dnd-kit/sortable';
import { DndContext } from '@dnd-kit/core';
import {
  DragTable,
  SpaceAround,
} from 'page/Application/ManageProductAndService/TopPageProduct/TopPageEditSuperDetailProduct/styled';
import { MenuOutlined } from '@ant-design/icons';
import { CSS } from '@dnd-kit/utilities';

const Row = ({ children, ...props }) => {
  const {
    attributes,
    listeners,
    setNodeRef,
    setActivatorNodeRef,
    transform,
    transition,
    isDragging,
  } = useSortable({
    id: props['data-row-key'],
  });
  const style = {
    ...props.style,
    transform: CSS.Transform.toString(
      transform && {
        ...transform,
        scaleY: 1,
      },
    ),
    transition,
    ...(isDragging
      ? {
          position: 'relative',
          zIndex: 1,
        }
      : {}),
  };
  return (
    <>
      <tr {...props} ref={setNodeRef} style={style} {...attributes}>
        {React.Children?.map(children, (child) => {
          if (child.key === 'sort') {
            return React.cloneElement(child, {
              children: (
                <MenuOutlined
                  ref={setActivatorNodeRef}
                  style={{
                    touchAction: 'none',
                    cursor: 'move',
                  }}
                  {...listeners}
                />
              ),
            });
          }
          return child;
        })}
      </tr>
      <SpaceAround />
    </>
  );
};

const FormDragTable = ({ dataSource, setDataSource, columns }) => {
  const onDragEnd = ({ active, over }) => {
    if (active.id !== over?.id) {
      setDataSource((previous) => {
        const activeIndex = previous.findIndex((i) => i.key === active.id);
        const overIndex = previous.findIndex((i) => i.key === over?.id);
        return arrayMove(previous, activeIndex, overIndex);
      });
    }
  };

  return (
    <DndContext modifiers={[restrictToVerticalAxis]} onDragEnd={onDragEnd}>
      <SortableContext
        // rowKey array
        items={dataSource?.map((i) => i.key)}
        strategy={verticalListSortingStrategy}
      >
        <DragTable
          components={{
            body: {
              row: Row,
            },
          }}
          key={dataSource?.length}
          rowKey={'key'}
          columns={columns}
          dataSource={dataSource}
          showHeader={false}
          pagination={false}
          bordered={false}
        />
      </SortableContext>
    </DndContext>
  );
};

export default FormDragTable;
