import styled from 'styled-components';
import { Button } from 'antd';

export const TopPageProductNotifyWrapper = styled.div`
  margin-bottom: 50px;
`;
export const TopPageProductMenuWrapper = styled.div`
  margin-top: 10px;
`;
export const ButtonVersion = styled(Button)`
  height: 40px;
  word-spacing: -3px;
  border: #656464 1px solid;
  font-size: 14px;
  font-weight: 400;
  font-family: 'inter-regular', sans-serif;
  margin-bottom: 10px;
  margin-top: 10px;
  span {
    max-width: 100%;
    padding-top: 4px;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;

export const ButtonStyle = styled(Button)`
  min-height: 40px;
  max-height: 40px;
  min-width: 88px;
  max-width: 160px;
  border: #656464 1px solid;
  font-size: 14px !important;
  box-shadow: none !important;
  font-family: 'inter-regular', sans-serif;
  color: #656464;
  margin-bottom: 20px;
  margin-right: 15px;
`;
export const BodyProductDetail = styled.div`
  font-family: 'inter-regular', sans-serif;
  color: #656464;
`;
export const TableListProductDetail = styled.div`
  padding: 0 20px;
  font-family: 'inter-regular', sans-serif;
  color: #656464;
`;
export const TitleFormProductDetail = styled.div`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-family: 'inter-regular', sans-serif;
  height: 40px;
  max-width: 1000px;
`;
export const FormContentAndDate = styled.div`
  display: flex;
  justify-content: space-between;
  //padding-bottom: 20px;
  align-items: center;
`;
export const FormContent = styled.div`
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  max-width: 80%;
`;
export const FormDate = styled.div`
  font-weight: 600;
  font-size: 14px;
`;
export const FormHashCode = styled.div`
  padding-bottom: 20px;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
`;
export const FormDownloadFile = styled(Button)`
  min-height: 40px;
  min-width: 88px;
  max-width: 160px;
  border: #656464 1px solid;
  font-size: 14px !important;
  box-shadow: none !important;
  font-family: 'inter-regular', sans-serif;
  color: #656464;
  margin-bottom: 20px;
`;
