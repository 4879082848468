import { atom } from 'recoil';
import { localStorageEffect } from 'recoil/localStorage/localStorageEffect';

export const version = atom({
  key: 'version',
  default: {
    current: '1.0.7',
    timestamp: '1716978475',
  },
  effects: [localStorageEffect('web-version')],
});

// https://www.currenttimestamp.com
