import { useIntl } from 'react-intl';

const useUploadFileMultiLanguage = () => {
  const intl = useIntl();

  const UPLOAD_FILE_SUCCESS = intl.formatMessage({
    id: 'application.upload.file.success',
  });

  const UPLOAD_FILE_FAILURE = intl.formatMessage({
    id: 'application.upload.file.failure',
  });

  const UPLOAD_FILE_ALLOWED_EXTENSION = intl.formatMessage({
    id: 'application.upload.file.allowed.extension',
  });

  const UPLOAD_FILE_MAX_SIZE = intl.formatMessage({
    id: 'application.upload.file.max.size',
  });

  const UPLOAD_FILE_WITHOUT_INTERNET = intl.formatMessage({
    id: 'application.upload.file.withoutInternet',
  });

  const UPLOAD_FILE_REQUIRED = intl.formatMessage({
    id: 'application.upload.file.required',
  });

  return {
    UPLOAD_FILE_SUCCESS,
    UPLOAD_FILE_FAILURE,
    UPLOAD_FILE_ALLOWED_EXTENSION,
    UPLOAD_FILE_MAX_SIZE,
    UPLOAD_FILE_WITHOUT_INTERNET,
    UPLOAD_FILE_REQUIRED,
  };
};

export default useUploadFileMultiLanguage;
