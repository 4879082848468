import React, { useEffect, useMemo, useState } from 'react';
import { useMutation } from 'react-query';
import { useNavigate } from 'react-router-dom';
import { useRecoilState, useResetRecoilState } from 'recoil';
import { messageFeedback } from 'recoil/atom/authentication';
import { signUpUser } from 'recoil/atom/signUpUser';
import {
  getListPartnerForRegister,
  registrationAccount,
} from 'api/authentication/authen.api';
import FormInput from 'page/Authentication/component/Form/FormInput/index';
import FormSelect from 'page/Authentication/component/Form/FormSelect/index';
import { Alert, Form } from 'antd';
import { FormItemButtonSubmit } from 'components/Form/index';
import { SIGN_IN_PATH } from 'constant/authenticationPath';
import useCheckErrorCode from 'hook/useCheckErrorCode';
import useCustomQuery from 'hook/useQuery/useCustomQuery';
import LabelForm from 'page/Authentication/component/Form/LabelForm/index';
import useErrorCodeSignUp from 'page/Authentication/SignUp/hook/useErrorCodeSignUp';
import useMultiLanguageSignUp from 'page/Authentication/SignUp/hook/useMultiLanguageSignUp';
import { FormContainer, TitleAuthentication } from 'page/Authentication/styled';

const SignUpSelectPartner = () => {
  const [loading, setLoading] = useState(false);

  const { useQueryAPI } = useCustomQuery();

  const [formRegistration] = Form.useForm();
  const navigate = useNavigate();

  const [signUpUserRecoil] = useRecoilState(signUpUser);
  const [, setShowMessageFeedback] = useRecoilState(messageFeedback);
  const resetSignUpUserRecoil = useResetRecoilState(signUpUser);

  const { SIGN_UP_EMAIL_SUCCESS_MESSAGE, SIGN_UP_CHOOSE_PARTNER_TITLE } =
    useMultiLanguageSignUp();
  const { ERROR_CODE_SIGN_UP } = useErrorCodeSignUp();
  const { errorMessage, handleClearErrorMessage, handleRenderMessage } =
    useCheckErrorCode({ errorCode: ERROR_CODE_SIGN_UP });

  const {
    data: listPartner = [],
    isError: isListError,
    isLoading,
  } = useQueryAPI({
    api: getListPartnerForRegister,
    key: 'listPartner',
    params: {
      email: signUpUserRecoil?.email,
    },
    enabled: !!signUpUserRecoil?.email,
  });

  const listPartnerOption = useMemo(() => {
    return listPartner?.data?.map((value) => ({
      label: value?.partnerName,
      value: value?.partnerId,
    }));
  }, [listPartner?.data]);

  const { mutate, isError } = useMutation({
    mutationFn: registrationAccount,
    onSuccess: () => {
      handleClearErrorMessage();
      setLoading(false);

      // show message feed back
      setShowMessageFeedback({
        display: true,
        type: 'success',
        content:
          '登録が完了しました。管理者へアカウント承認の確認を連絡してください。',
      });
      navigate(SIGN_IN_PATH);
    },

    onError: (response) => {
      setLoading(false);
      handleRenderMessage(response);
    },
  });

  const onFinish = (value) => {
    const payload = {
      email: signUpUserRecoil.email,
      firstName: signUpUserRecoil.firstName,
      lastName: signUpUserRecoil.lastName,
      password: signUpUserRecoil.password,
      phoneNumber: signUpUserRecoil.phoneNumber,
      partnerId: value?.partnerId,
    };
    setLoading(true);
    mutate(payload);
  };

  useEffect(() => {
    if (isListError || !signUpUserRecoil?.email) {
      navigate('/sign-up');
    }
    if (listPartnerOption?.length === 1) {
      formRegistration.setFieldValue(
        'partnerId',
        listPartnerOption?.[0]?.value,
      );
    }
  }, [
    isListError,
    navigate,
    signUpUserRecoil?.email,
    formRegistration,
    listPartnerOption,
  ]);

  return (
    <FormContainer className="form-responsive">
      <TitleAuthentication className="title-form-sign-up-responsive">
        {SIGN_UP_CHOOSE_PARTNER_TITLE}
      </TitleAuthentication>
      {isError && errorMessage !== '' && (
        <Alert message={errorMessage} type="error" />
      )}
      <Form
        className="size"
        form={formRegistration}
        name="account_registration"
        onFinish={onFinish}
        layout={'vertical'}
        style={{ position: 'relative', top: 60 }}
      >
        <LabelForm text={'候補が見つかりました。'} />
        <br />
        <LabelForm text={'所属されている企業を選択してください。'} />
        <FormSelect
          name={'partnerId'}
          label={'会社名'}
          required={true}
          data={listPartnerOption}
          isLoading={isLoading}
          isDisable={listPartnerOption?.length === 1}
        />
        <FormItemButtonSubmit
          size="12px"
          button="signup"
          className="note-signup"
          formName="sign-up-choose-partner"
          loading={loading}
        />
      </Form>
    </FormContainer>
  );
};

export default SignUpSelectPartner;
