import styled from 'styled-components';
import { Button } from 'antd';

export const FormFooterWrapper = styled.div`
  display: flex;
  gap: 24px;
`;

export const ButtonDelete = styled(Button)`
  background: #656464;
  color: rgba(255, 255, 255, 1);
  min-width: 88px;
  font-size: 14px !important;
  font-family: 'inter-regular', sans-serif;

  ${({ disabled }) => {
    if (!disabled) {
      return ` 
      &:hover {
      color: white !important;
      background: #656464 !important;
      border: 1px solid rgba(155, 155, 155, 0.1) !important;
      `;
    }
  }}
}
`;

export const ButtonOkStyle = styled(Button)`
  font-size: 14px !important;
  box-shadow: none !important;

  span {
    font-family: 'inter-regular', sans-serif;
  }

  min-width: 86px;
  border: var(--FORM-LABEL-default) 1px solid;
`;

export const FooterPreviewWrapper = styled.div`
  display: flex;
  justify-content: right;
  position: absolute;
  bottom: 16px;
  right: 16px;
`;
