import { atom } from 'recoil';
import { localStorageEffect } from 'recoil/localStorage/localStorageEffect';

export const menuSelected = atom({
  key: 'menuSelected',
  default: {
    id: 0,
    label: '',
    path: '',
  },
  effects: [localStorageEffect('menu-selected')],
});

export const subMenu = atom({
  key: 'subMenuOpen',
  default: {
    toggle: false,
    id: null,
  },
  effects: [localStorageEffect('sub-menu-Open')],
});
