import useSetTitlePage from 'hook/useSetTitlePage';
import React, { useState } from 'react';
import FormCategory from '../component/FormCategory';
import { registerCategory } from 'api/product/category.api';
import useMultiLanguageRegisterCategory from './hook/useMultiLanguageRegisterCategory';
import { MANAGE_CATEGORY_PATH } from 'constant/appPath';
import useErrorCodeManageCategory from '../hook/useErrorCodeManageCategory';
import useCustomMutateRenderMessage from 'hook/useQuery/useCustomMutateRenderMessage';
import { CATEGORY_STATUS_DATA } from 'constant/categoryConstants';

const RegisterCategory = () => {
  const {
    APPLICATION_REGISTERCATEGORY_TITLE,
    APPLICATION_REGISTERCATEGORY_MESSAGE_ADD_SUCCESS,
  } = useMultiLanguageRegisterCategory();
  const [isDisable, setIsDisable] = useState(false);
  const { ERROR_CODE_MANAGE_CATEGORY } = useErrorCodeManageCategory();
  const [content, setContent] = useState(null);
  const { errorMessage, useMutationAPIHandleErrorCode } =
    useCustomMutateRenderMessage({
      errorCode: ERROR_CODE_MANAGE_CATEGORY,
    });
  const [isDisplayed, setIsDisplayed] = useState(CATEGORY_STATUS_DATA[0].value);

  const {
    mutate: registerCategoryMutate,
    isError,
    isLoading,
    isSuccess,
  } = useMutationAPIHandleErrorCode({
    api: registerCategory,
    refetchKey: 'listCategory',
    messageSuccess: APPLICATION_REGISTERCATEGORY_MESSAGE_ADD_SUCCESS,
    path: `/${MANAGE_CATEGORY_PATH}`,
  });

  const handleRegister = (value) => {
    const payload = {
      ...value?.payload,
      title: value?.payload?.title?.trim(),
      isDisplayed: value?.payload?.isDisplayed,
    };
    registerCategoryMutate(payload);
  };

  useSetTitlePage('カテゴリ管理');

  return (
    <FormCategory
      onFinish={handleRegister}
      isEdit={false}
      isLoading={isLoading}
      titleForm={APPLICATION_REGISTERCATEGORY_TITLE}
      isDisable={isDisable}
      setIsDisable={setIsDisable}
      isError={isError}
      errorMessage={errorMessage}
      isSuccess={isSuccess}
      content={content}
      setContent={setContent}
      setIsDisplayed={setIsDisplayed}
      isDisplayed={isDisplayed}
    />
  );
};

export default RegisterCategory;
