import styled from 'styled-components';

export const FormMyPageWrapper = styled.div`
  margin: 50px 0 42px 0;

  @media all and (min-width: 0px) and (max-width: 320px) {
    margin: 20px 0 12px 0;
  }
  @media all and (min-width: 320px) and (max-width: 430px) {
    margin: 20px 0 12px 0;
  }

  @media all and (min-width: 430px) and (max-width: 768px) {
    margin: 20px 0 12px 0;
  }
`;
export const GridFormMyPage = styled.div`
  display: grid;
  margin: 0 auto;
  column-gap: 24px;
  grid-template-columns: 160px 340px 100px;
  width: 572px;

  @media all and (min-width: 0px) and (max-width: 320px) {
    grid-template-columns: 1fr 1fr 100px;
    width: 268px;
  }

  @media all and (min-width: 320px) and (max-width: 430px) {
    grid-template-columns: 1fr 1fr 100px;
    width: auto;
  }

  @media all and (min-width: 430px) and (max-width: 768px) {
    grid-template-columns: 160px 1fr 100px;
    width: auto;
  }
`;
export const GridFormMyPageCheckBoxGroup = styled.div`
  display: grid;
  margin: 0 auto;
  grid-template-columns: 158px 800px;
  column-gap: 12px;
  width: 970px;

  @media all and (min-width: 0px) and (max-width: 720px) {
    width: auto;
  }
`;

export const GridMyPageColumn1 = styled.div`
  grid-column: 1/2;
  @media all and (min-width: 0px) and (max-width: 720px) {
    grid-column: 1/3;
  }
`;

export const GridMyPageColumn2 = styled.div`
  grid-column: 2/3;

  @media all and (min-width: 0px) and (max-width: 720px) {
    grid-column: 1/4;
  }
`;

export const GridMyPagPassword = styled.div`
  grid-column: 2/3;

  @media all and (min-width: 0px) and (max-width: 720px) {
    grid-column: 1/3;
  }
`;
export const GridMyPageColumn3 = styled.div`
  grid-column: 3/4;
`;

export const LabelFormResponsive = styled.div`
  padding: 9px 0;
  text-align: right;
  font-family: 'inter-regular', sans-serif;

  span {
    color: var(--FORM-LABEL-default);
  }
  .mark {
    margin-right: 4px;
    color: #9d1c49;
  }

  @media all and (min-width: 0px) and (max-width: 320px) {
    text-align: left;
  }

  @media all and (min-width: 320px) and (max-width: 430px) {
    text-align: left;
  }
  @media all and (min-width: 430px) and (max-width: 768px) {
    text-align: left;
  }
`;

export const GridFormAccount = styled.div`
  display: grid;
  grid-template-columns: 160px 400px 1fr;
  column-gap: 15px;
  width: 975px;
  margin: 0 auto;

  @media all and (min-width: 0px) and (max-width: 320px) {
    grid-template-columns: 1fr 1fr 100px;
    width: auto;
  }
  @media all and (min-width: 320px) and (max-width: 430px) {
    grid-template-columns: 1fr 1fr 100px;
    width: auto;
  }
  @media all and (min-width: 431px) and (max-width: 768px) {
    grid-template-columns: 1fr 1fr 100px;
    width: auto;
  }
  @media all and (min-width: 769px) and (max-width: 1024px) {
    width: 700px;
  }
  @media all and (min-width: 1025px) and (max-width: 1200px) {
    width: 600px;
  }
  @media all and (min-width: 1201px) and (max-width: 1440px) {
    width: 800px;
  }

  &.margin-top-20 {
    margin-top: 20px;
  }
`;

export const GridFormAccount1to2 = styled.div`
  grid-column: 1/2;

  @media all and (min-width: 0px) and (max-width: 320px) {
    grid-column: 1/4;
  }
  @media all and (min-width: 320px) and (max-width: 430px) {
    grid-column: 1/4;
  }
  @media all and (min-width: 430px) and (max-width: 768px) {
    grid-column: 1/4;
  }
`;

export const GridFormAccount2to3 = styled.div`
  grid-column: 2/3;

  @media all and (min-width: 0px) and (max-width: 320px) {
    grid-column: 1/4;
  }
  @media all and (min-width: 320px) and (max-width: 430px) {
    grid-column: 1/4;
  }
  @media all and (min-width: 430px) and (max-width: 768px) {
    grid-column: 1/4;
  }
`;

export const GridFormAccount2to4 = styled.div`
  grid-column: 2/4;
  width: 400px;

  @media all and (min-width: 0px) and (max-width: 320px) {
    grid-column: 1/4;
    width: auto;
  }
  @media all and (min-width: 321px) and (max-width: 430px) {
    grid-column: 1/4;
    width: auto;
  }
  @media all and (min-width: 431px) and (max-width: 768px) {
    grid-column: 1/4;
    width: auto;
  }
  @media all and (min-width: 769px) and (max-width: 1024px) {
    grid-column: 2/4;
    width: 280px;
  }
  @media all and (min-width: 1025px) and (max-width: 1200px) {
    grid-column: 2/4;
    width: 280px;
  }
`;

export const GridFormAccount3to4 = styled.div`
  grid-column: 3/4;
  height: 64px;

  @media all and (min-width: 0px) and (max-width: 320px) {
    grid-column: 1/4;
  }
  @media all and (min-width: 320px) and (max-width: 430px) {
    grid-column: 1/4;
  }
  @media all and (min-width: 430px) and (max-width: 768px) {
    grid-column: 1/4;
  }
`;
