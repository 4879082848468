import {
  AuditOutlined,
  LayoutOutlined,
  ProjectOutlined,
  TeamOutlined,
  CaretUpOutlined,
  CaretDownOutlined,
} from '@ant-design/icons';
import React from 'react';
import {
  MANAGE_ACCOUNT_PARTNER_PATH,
  PROJECT_REGISTRATION_PATH,
  TOP_PAGE_PATH,
} from 'constant/appPath';

/*
 * id range from 101 - 199
 * */
export const partnerAdminMenu = [
  {
    id: 101,
    label: 'パートナー専用ページ',
    labelMobile: 'パートナー専用ページ',
    path: '/partner-page',
    icon: '',
    isParent: true,
    isTopPage: true,
    parentId: 0,
    children: [],
  },
  {
    id: 102,
    label: 'マイページ', // my page
    path: '',
    icon: <LayoutOutlined />,
    iconClose: <CaretUpOutlined />,
    iconOpen: <CaretDownOutlined />,
    isParent: true,
    isTopPage: false,
    parentId: 0,
    children: [
      {
        id: 103,
        label: '企業情報',
        path: '/company-information',
        icon: 'dot',
        isParent: false,
        isTopPage: false,
        parentId: 0,
        children: [],
      },
      {
        id: 104,
        label: 'アカウント情報',
        path: '/account-information',
        icon: 'dot',
        isParent: false,
        isTopPage: false,
        parentId: 0,
        children: [],
      },
    ],
  },
  {
    id: 105,
    label: 'アカウント管理', // manage account
    path: `/${MANAGE_ACCOUNT_PARTNER_PATH}`,
    icon: <TeamOutlined />,
    isParent: true,
    isTopPage: false,
    parentId: 0,
    children: [],
  },

  {
    id: 108,
    label: '製品一覧', // manage product & service
    path: `/${TOP_PAGE_PATH}`,
    icon: <AuditOutlined />,
    isParent: true,
    isTopPage: false,
    parentId: 0,
    children: [],
  },
  {
    id: 109,
    label: '案件登録お申し込み', // project registration
    path: `/${PROJECT_REGISTRATION_PATH}`,
    icon: <ProjectOutlined />,
    isParent: true,
    isTopPage: false,
    parentId: 0,
    children: [],
  },
];
