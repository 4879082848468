import styled from 'styled-components';

export const TitleStyle = styled.div`
  color: #656464;
  font-size: 18px;
  font-weight: 600;
  font-family: 'inter-regular', sans-serif;
`;
export const FooterModal = styled.div`
  margin: 12px 2px;
  font-family: 'inter-semibold', sans-serif;
  font-weight: 400;
  font-size: 14px;
  color: #656464;
  display: flex;
  justify-content: right;
  align-items: center;
`;
export const ButtonGroup = styled.div`
  button {
    font-family: 'inter-regular', sans-serif;
    box-shadow: none !important;
    font-size: 14px !important;
  }
`;
