import useErrorMessageMultiLanguageSignIn from 'page/Authentication/SignIn/hook/useErrorMessageMultiLanguageSignIn';
import { useMemo } from 'react';

const useErrorCodeSignIn = () => {
  const {
    ERROR_EMAIL_NOT_FOUND,
    ERROR_WRONG_USERNAME_PASSWORD,
    ERROR_USER_NOT_APPROVED,
    ERROR_USER_HAS_BEEN_SUSPENDED,
  } = useErrorMessageMultiLanguageSignIn();

  // sign in error code
  const ERROR_CODE_SIGN_IN = useMemo(() => {
    return {
      '404_EMAIL_NOT_FOUND': {
        message: ERROR_EMAIL_NOT_FOUND,
        statusCode: 404,
        type: 'error',
      },
      '401_WRONG_USERNAME_PASSWORD': {
        message: ERROR_WRONG_USERNAME_PASSWORD,
        statusCode: 401,
        type: 'error',
      },
      '400_USER_NOT_APPROVED': {
        message: ERROR_USER_NOT_APPROVED,
        statusCode: 400,
        type: 'error',
      },
      '400_VALIDATION_ERROR': {
        message: 'メールアドレスが正しくありません。',
        statusCode: 400,
        type: 'error',
      },
      '400_USER_HAS_BEEN_SUSPENDED': {
        message: ERROR_USER_HAS_BEEN_SUSPENDED,
        statusCode: 400,
        type: 'error',
      },
    };
  }, [
    ERROR_EMAIL_NOT_FOUND,
    ERROR_WRONG_USERNAME_PASSWORD,
    ERROR_USER_NOT_APPROVED,
    ERROR_USER_HAS_BEEN_SUSPENDED,
  ]);

  return { ERROR_CODE_SIGN_IN };
};

export default useErrorCodeSignIn;
