import React from 'react';
import LabelFormMyPage from 'page/Application/MyPage/component/FormMyPage/LabelFormMyPage';
import { FormItem } from 'components/Form2';
import {
  ItemProduct,
  ListProduct,
} from 'page/Application/MyPagePartnerAdmin/CompanyInformation/CompanyInformationOverview/styled';
import { listProductType } from 'page/Application/ManageCompanyList/component/ListProduct';
import {
  Grid1to2,
  Grid2to3,
  GridForm,
} from 'page/Application/MyPagePartnerAdmin/styled';
import { Tooltip } from 'antd';

const AccessibleProduct = ({ productData }) => {
  return (
    <GridForm>
      <Grid1to2>
        <LabelFormMyPage labelText={'権限'} />
      </Grid1to2>
      <Grid2to3>
        <FormItem>
          <ListProduct>
            {productData?.map((item, index) => {
              return (
                <ItemProduct key={index}>
                  <span style={{ marginLeft: -8 }}>
                    {item?.isDisplayed === true ? '【表示】' : '【非表示】'}
                  </span>
                  【{listProductType[item.type]}】
                  <Tooltip title={item.productName} placement={'topLeft'}>
                    {item.productName}
                  </Tooltip>
                </ItemProduct>
              );
            })}
          </ListProduct>
        </FormItem>
      </Grid2to3>
    </GridForm>
  );
};

export default AccessibleProduct;
