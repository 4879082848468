import { useIntl } from 'react-intl';

const useMultiLanguageEditNotification = () => {
  const intl = useIntl();

  const EDIT_NOTIFICATION_TITLE = intl.formatMessage({
    id: 'application.editNotification.title',
  });

  const EDIT_NOTIFICATION_BUTTON_KEEP = intl.formatMessage({
    id: 'application.editNotification.button.keep',
  });

  const EDIT_NOTIFICATION_BUTTON_DELETE = intl.formatMessage({
    id: 'application.editNotification.button.delete',
  });

  const EDIT_NOTIFICATION_BUTTON_CANCEL = intl.formatMessage({
    id: 'application.editNotification.button.cancel',
  });

  const EDIT_NOTIFICATION_CONFIRM_DELETE_EDIT = intl.formatMessage({
    id: 'application.editNotification.confirm.deleteEdit',
  });

  const EDIT_NOTIFICATION_DELETE = intl.formatMessage({
    id: 'application.editNotification.delete',
  });

  const EDIT_NOTIFICATION_MESSAGE_SUCCESS = intl.formatMessage({
    id: 'application.editNotification.messageSuccess',
  });

  const EDIT_NOTIFICATION_DELETE_MESSAGE_SUCCESS = intl.formatMessage({
    id: 'application.editNotification.delete.messageSuccess',
  });

  return {
    EDIT_NOTIFICATION_TITLE,
    EDIT_NOTIFICATION_BUTTON_KEEP,
    EDIT_NOTIFICATION_BUTTON_DELETE,
    EDIT_NOTIFICATION_BUTTON_CANCEL,
    EDIT_NOTIFICATION_CONFIRM_DELETE_EDIT,
    EDIT_NOTIFICATION_DELETE,
    EDIT_NOTIFICATION_MESSAGE_SUCCESS,
    EDIT_NOTIFICATION_DELETE_MESSAGE_SUCCESS,
  };
};
export default useMultiLanguageEditNotification;
