import React, { Suspense, useEffect, useMemo, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useRecoilState, useRecoilValue } from 'recoil';
import { userInformation } from 'recoil/atom/authentication';
import {
  DeleteOutlined,
  DownloadOutlined,
  PlusOutlined,
} from '@ant-design/icons';
import { deleteMultipleUser, getListAccount } from 'api/account/account.api';
import { exportAccountPartnerCSV } from 'api/account/partnerAccount.api';
import {
  ButtonBreadcrumb,
  ButtonTextIcon,
  LineVertical,
} from 'components/Button';
import Breadcrumb from 'components/Layout/ApplicationLayout/Breadcrumb';
import HeaderListDataLayout from 'components/Layout/HeaderListDataLayout';
import LayoutDefault from 'components/Layout/LayoutDefault';
import Pagination from 'components/Pagination';
import {
  defaultPageSizeOptions,
  defaultSelectPageSizeOption,
} from 'constant/Pagination/pageSizeOption';
import { SORT_DIRECTION } from 'constant/sortConstants';
import useModalConfirm from 'hook/useModalConfirm';
import useCustomMutate from 'hook/useQuery/useCustomMutate';
import useCustomQuery from 'hook/useQuery/useCustomQuery';
import useSearchKeyword from 'hook/useSearchKeyword';
import useMultiLanguageAccountList from 'page/Application/ManageAccountSoliton/AccountSolitonList/hook/useMultiLanguageManageAccountSolitonList';
import useMultiLanguageRegisterAccount from 'page/Application/ManageAccountSoliton/RegisterAccountSoliton/hook/useMultiLanguageRegisterAccount';
import SelectAllTable from 'page/Application/ManagerAccountPartner/AccountPartnerList/component/SelectAllTable';
import TablePartner from 'page/Application/ManagerAccountPartner/AccountPartnerList/component/TablePartner';
import { IconWrapper } from 'page/Application/ManagerAccountPartner/AccountPartnerList/styled';
import { network } from 'recoil/atom/network';
import { message } from 'antd';

const AccountPartnerList = () => {
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);

  const navigate = useNavigate();
  const { role, partnerName } = useRecoilValue(userInformation);
  const { searchKeyword, onSearch, emptyText } = useSearchKeyword();
  const [, setItemDelete] = useState('');
  const { useQueryAPI } = useCustomQuery();
  const { useMutationAPI } = useCustomMutate();
  const { handleConfirmAction, contextHolder } = useModalConfirm();
  const [isDisable, setIsDisable] = useState(false);
  const [page, setPage] = useState(10);
  const [current, setCurrent] = useState(1);
  const [listIdSelected, setListIdSelected] = useState([]);
  const [selectAllData, setSelectAllData] = useState(false);
  const [, setSelectAllPage] = useState(false);
  const [idSelected, setIdSelected] = useState(null);
  const [sortedInfo, setSortedInfo] = useState({});
  const [searchValue, setSearchValue] = useState('');
  const [isOnline] = useRecoilState(network);
  const [messageErrorNetwork, contextErrorNetwork] = message.useMessage();

  const {
    ACCOUNT_LIST_TITLE,
    ACCOUNT_EXPORT_FILE,
    ACCOUNT_LIST_MULTI_DELETE,
    ACCOUNT_LIST_MULTI_DELETE_CONFIRM1,
    ACCOUNT_LIST_MULTI_DELETE_CONFIRM2,
    ACCOUNT_LIST_MULTI_DELETE_SUCCESS1,
    ACCOUNT_LIST_MULTI_DELETE_SUCCESS2,
    ACCOUNT_LIST_PLACEHOLDER_SEARCH,
    ACCOUNT_LIST_PLACEHOLDER_SEARCH_ROLE_SA,
    ACCOUNT_LIST_TABLE_PAGINATION_TOTAL,
  } = useMultiLanguageAccountList();
  const { REGISTER_ACCOUNT_REGISTER_EXPORT_FILE_MESSAGE_SUCCESS } =
    useMultiLanguageRegisterAccount();

  const listItemSA = useMemo(() => {
    return [
      {
        title: <Link to={'/'}>企業管理</Link>,
      },
      {
        title: 'パートナーアカウント管理',
      },
    ];
  }, []);

  const listItemPA = useMemo(() => {
    return [
      {
        title: <Link to={'/'}>パートナー専用ページ</Link>,
      },
      {
        title: 'アカウント管理',
      },
    ];
  }, []);

  const listItemByRole = useMemo(() => {
    return {
      ROLE_SOLITON_ADMIN: listItemSA,
      ROLE_SOLITON_USER: listItemSA,
      ROLE_PARTNER_ADMIN: listItemPA,
      ROLE_PARTNER_USER: listItemPA,
    };
  }, [listItemPA, listItemSA]);

  const listTitleByRole = useMemo(() => {
    return {
      ROLE_SOLITON_ADMIN: 'パートナーアカウント管理',
      ROLE_SOLITON_USER: 'パートナーアカウント管理',
      ROLE_PARTNER_ADMIN: 'アカウント管理',
      ROLE_PARTNER_USER: 'アカウント管理',
    };
  }, []);

  const listPlaceholderByRole = useMemo(() => {
    return {
      ROLE_SOLITON_ADMIN: ACCOUNT_LIST_PLACEHOLDER_SEARCH_ROLE_SA,
      ROLE_PARTNER_ADMIN: ACCOUNT_LIST_PLACEHOLDER_SEARCH,
    };
  }, [
    ACCOUNT_LIST_PLACEHOLDER_SEARCH,
    ACCOUNT_LIST_PLACEHOLDER_SEARCH_ROLE_SA,
  ]);

  const { data: accountList = [], isLoading } = useQueryAPI({
    api: getListAccount,
    key: 'accountListPartner',
    params: {
      pageNumber: current,
      pageSize: page,
      keyword: encodeURIComponent(searchKeyword),
      isPartner: true, // value === true for get list user partner
      displayedDateFrom: '',
      displayedDateTo: '',
      category: '',
      status: '',
      sortField: sortedInfo?.sortField ?? '',
      sortDirection: sortedInfo?.sortDirection ?? '',
    },
  });

  const { mutate: deleteSingleUserMutate } = useMutationAPI({
    api: deleteMultipleUser,
    refetchKey: 'accountListPartner',
    messageSuccess: 'アカウントを削除しました。',
  });

  const { mutate: deleteMultipleUserMutate } = useMutationAPI({
    api: deleteMultipleUser,
    refetchKey: 'accountListPartner',
    messageSuccess:
      ACCOUNT_LIST_MULTI_DELETE_SUCCESS1 +
      `${selectAllData ? accountList?.data?.total : listIdSelected.length}` +
      ACCOUNT_LIST_MULTI_DELETE_SUCCESS2,
  });

  const { mutate: exportListAccountPartner } = useMutationAPI({
    api: exportAccountPartnerCSV,
    messageSuccess: REGISTER_ACCOUNT_REGISTER_EXPORT_FILE_MESSAGE_SUCCESS,
    downloadFile: true,
  });

  const errorNetwork = () => {
    messageErrorNetwork
      .open({
        type: 'error',
        content:
          '問題が発生しました。インターネット接続を確認して、もう一度やり直してください。',
      })
      .then();
  };

  const handlePagingChange = (pageNumber, pageSize) => {
    setCurrent(pageNumber);
    setPage(pageSize);
  };

  const handleChangePageSize = (value) => {
    setPage(value);
    setCurrent(1);
  };

  const handleDeleteUser = (userId) => {
    deleteSingleUserMutate({
      listUserId: [userId],
      isPartner: true,
      isAll: false,
    });
    setIdSelected(null);
    if (listIdSelected.includes(userId)) {
      let NewListID = listIdSelected.filter((id) => id !== userId);
      setListIdSelected(NewListID);
    }
  };

  const handleDeleteMultipleUser = () => {
    if (isOnline) {
      const newListId = [...new Set(listIdSelected)];
      const payload = {
        keyword: encodeURIComponent(searchValue),
        isPartner: true,
        listUserId: newListId,
        isAll: selectAllData,
      };
      deleteMultipleUserMutate(payload);
      setListIdSelected([]);
    } else {
      errorNetwork();
    }
  };

  const handleSelectAllRecord = () => {
    if (!selectAllData) {
      setSelectAllData((current) => !current);
    } else {
      handleSelectRowToDefault();
    }
  };

  const handleExportCSV = () => {
    setIsDisable(true);
    const payload = {
      listUserIds: listIdSelected,
      partnerName: '',
      approveStatus: '',
      updatedTimeFrom: '',
      updatedTimeTo: '',
      keyword: searchKeyword,
      isCondition: selectAllData,
    };
    exportListAccountPartner(payload);
    setTimeout(() => setIsDisable(false), 1000);
  };

  const handleNavigate = () => {
    navigate('/manage-account-partner/register');
  };

  const TitleByRole = {
    ROLE_SOLITON_ADMIN: ACCOUNT_LIST_TITLE,
    ROLE_PARTNER_ADMIN: `${partnerName ?? ''}のアカウント一覧`,
  };

  useEffect(() => {
    if (searchKeyword) {
      setCurrent(1);
    }
  }, [searchKeyword]);

  const handleChange = (pagination, filters, sorter) => {
    setSortedInfo({
      sortField: sorter.order && sorter.field,
      sortDirection: sorter.order && SORT_DIRECTION[sorter.order],
      order: sorter.order,
      columnKey: sorter.columnKey,
    });
  };
  const handleSelectRowToDefault = () => {
    setSelectAllData(false);
    setSelectAllPage(false);
    setSelectedRowKeys([]);
    setListIdSelected([]);
  };

  return (
    <>
      <Breadcrumb
        listItem={listItemByRole[role]}
        titlePage={listTitleByRole[role]}
      >
        <ButtonBreadcrumb
          text={'新規登録'}
          icon={<PlusOutlined />}
          onClick={handleNavigate}
        />
      </Breadcrumb>
      <LayoutDefault>
        {contextErrorNetwork}
        {contextHolder}
        <Suspense fallback={isLoading}>
          <HeaderListDataLayout
            titleScreen={TitleByRole[role]}
            shortenTitle={TitleByRole?.[role]?.length >= 256}
            searchPlaceholder={listPlaceholderByRole[role]}
            isLoading={isLoading}
            onSearch={onSearch}
            width={250}
            value={page}
            handleChangePageSize={handleChangePageSize}
            pageSizeOptions={defaultSelectPageSizeOption}
            searchValue={searchValue}
            setSearchValue={setSearchValue}
          >
            {role === 'ROLE_SOLITON_ADMIN' && (
              <>
                <ButtonTextIcon
                  disabled={isDisable}
                  text={ACCOUNT_EXPORT_FILE}
                  size={'middle'}
                  icon={
                    <IconWrapper
                      style={{
                        opacity: isDisable ? 0.3 : 1,
                      }}
                    >
                      <DownloadOutlined style={{ color: '#9d1c49' }} />
                    </IconWrapper>
                  }
                  onClick={handleExportCSV}
                />
                <LineVertical />
              </>
            )}
            <ButtonTextIcon
              text={ACCOUNT_LIST_MULTI_DELETE}
              size={'middle'}
              icon={
                <IconWrapper
                  style={{
                    opacity: listIdSelected.length === 0 || isDisable ? 0.3 : 1,
                  }}
                >
                  <DeleteOutlined style={{ color: '#9d1c49' }} />
                </IconWrapper>
              }
              disabled={listIdSelected.length === 0 || isDisable}
              onClick={() => {
                handleConfirmAction({
                  confirmFn: handleDeleteMultipleUser,
                  title: (
                    <div>
                      <span>{ACCOUNT_LIST_MULTI_DELETE_CONFIRM1}</span>
                      <span style={{ fontSize: '16px' }}>
                        {selectAllData
                          ? accountList?.data?.total
                          : listIdSelected.length}
                      </span>
                      <span>{ACCOUNT_LIST_MULTI_DELETE_CONFIRM2}</span>
                    </div>
                  ),
                  okText: <span> {ACCOUNT_LIST_MULTI_DELETE}</span>,
                  setIsDisable: setIsDisable,
                });
                setIsDisable(true);
              }}
            />
            <LineVertical />
          </HeaderListDataLayout>
          {listIdSelected.length !== 0 && (
            <SelectAllTable
              dataRaw={accountList.data}
              handleSelectAllRecord={handleSelectAllRecord}
              selectAllData={selectAllData}
              listIdSelected={listIdSelected}
            />
          )}
          <TablePartner
            setCurrent={setCurrent}
            idSelected={idSelected}
            setIdSelected={setIdSelected}
            isLoading={isLoading}
            isDisable={isDisable}
            setIsDisable={setIsDisable}
            emptyText={emptyText}
            dataRaw={accountList.data}
            setItemDelete={setItemDelete}
            setListIdSelected={setListIdSelected}
            listIdSelected={listIdSelected}
            handleDeleteUser={handleDeleteUser}
            selectAllData={selectAllData}
            setSelectAllData={setSelectAllData}
            setSelectAllPage={setSelectAllPage}
            onChange={handleChange}
            sortedInfo={sortedInfo}
            selectedRowKeys={selectedRowKeys}
            setSelectedRowKeys={setSelectedRowKeys}
          />
          <Pagination
            totalTitle={ACCOUNT_LIST_TABLE_PAGINATION_TOTAL}
            total={accountList.data?.total}
            currentPage={current}
            pageSize={page}
            handlePagingChange={handlePagingChange}
            pageSizeOptions={defaultPageSizeOptions}
            handleChangePageSize={handleChangePageSize}
          />
        </Suspense>
      </LayoutDefault>
    </>
  );
};

export default AccountPartnerList;
