import React from 'react';
import { Form } from 'antd';
import FormMessage from 'components/Form/FormMessage/index';
import { InputStyled } from 'components/Form/styled';
import useMultiLanguageRegisterNotification from 'page/Application/ManageNotificationList/RegisterNotification/hook/useMultiLanguageRegisterNotification';

const FormTextRequired = ({
  message,
  required,
  maxLength,
  name,
  placeHolder,
  isDisabled,
  marginBottom,
}) => {
  const { REGISTER_NOTIFICATION_FIELD_REQUIRED } =
    useMultiLanguageRegisterNotification();

  return (
    <Form.Item
      style={{ marginBottom: marginBottom }}
      name={name ?? 'name'}
      rules={[
        {
          required: required,
          message: message,
          validator: (object, value) => {
            const field = object.field;
            if (field === 'title') {
              const trimmedString = value?.trim();
              if (trimmedString?.length === 0 || value?.trim() === '') {
                return Promise.reject(REGISTER_NOTIFICATION_FIELD_REQUIRED);
              }
            }
            if (field === 'url') {
              const urlRegex = /^(http|https):\/\/.+$/i;
              if (required && !value) {
                return Promise.reject(
                  <FormMessage text1={'情報を入力してください。'} />,
                );
              }

              if (value && !urlRegex.test(value)) {
                return Promise.reject(
                  <FormMessage text1={'URLの形式が正しくありません。'} />,
                );
              }
            }
            return Promise.resolve();
          },
        },
      ]}
    >
      <InputStyled
        disabled={isDisabled}
        maxLength={maxLength}
        placeholder={placeHolder}
      />
    </Form.Item>
  );
};

export default FormTextRequired;
