import { useRecoilState } from 'recoil';
import { messageFeedback } from 'recoil/atom/authentication';
import { useMutation } from 'react-query';
import { queryClient } from 'api/config/queryClient';
import { useNavigate } from 'react-router-dom';
import useMultiLanguageRegisterAccount from 'page/Application/ManageAccountSoliton/RegisterAccountSoliton/hook/useMultiLanguageRegisterAccount';
import useMultiLanguage from 'hook/useMultiLanguage';

const useCustomMutate = () => {
  const navigate = useNavigate();
  const [, setShowMessageFeedback] = useRecoilState(messageFeedback);
  const { APPLICATION_CHECK_NETWORK_CONNECT } = useMultiLanguage();
  const { REGISTER_ACCOUNT_REGISTER_EXPORT_FILE_NAME } =
    useMultiLanguageRegisterAccount();
  /**
   * api: from axios define in folder api
   * refetchKey: is queryKey you define for refetch api
   * messageSuccess: content for message component, trigger when fetch api SUCCESS,
   * messageError: content for message component, trigger when fetch api ERROR,
   * useMutate: return mutate, isLoading, isError... read doc
   */

  const useMutationAPI = ({
    api,
    refetchKey,
    messageSuccess,
    messageError,
    path,
    downloadFile,
  }) => {
    return useMutation({
      mutationFn: api,
      onSuccess: (response) => {
        queryClient.refetchQueries((refetchKey ??= '')).then(() => {
          setShowMessageFeedback({
            display: true,
            type: 'success',
            content: messageSuccess ?? 'delete success',
          });
          navigate(path);
        });
        if (downloadFile) {
          let binaryData = [];
          binaryData.push(response);

          // create file link in browser's memory
          const url = window.URL.createObjectURL(
            new Blob([
              new Uint8Array([0xEF, 0xBB, 0xBF]),binaryData], { type: 'text/csv;charset=UTF-8' }),
          );

          // create "a" HTML element with href to file & click
          const link = document.createElement('a');
          link.href = url;
          link.style.display = 'none';
          link.setAttribute(
            'download',
            `${REGISTER_ACCOUNT_REGISTER_EXPORT_FILE_NAME}.csv`,
          );
          document.body.appendChild(link);
          link.click();

          // clean up "a" element & remove ObjectURL
          document.body.removeChild(link);
          URL.revokeObjectURL(url);
        }
      },
      onError: () => {
        setShowMessageFeedback({
          display: true,
          type: 'error',
          content: messageError ?? APPLICATION_CHECK_NETWORK_CONNECT,
        });
      },
    });
  };
  return { useMutationAPI };
};

export default useCustomMutate;
