import styled from 'styled-components';

export const TitleSection = styled.span`
  font-family: 'inter-semibold', sans-serif;
  font-size: 16px;
  font-weight: 600;
  color: var(--FORM-LABEL-default);
  line-height: 38px;
`;

export const HeaderWrapper = styled.div`
  background: #d9d9d94d;
  padding: 12px 20px;
  border-radius: 6px;
  margin: 7px 0 5px 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const ButtonToggle = styled.div`
  padding: 11px;

  border-radius: 4px;
  transition: 0.25s;
  cursor: pointer;

  &.show {
    transform: rotate(0deg);
  }

  &.hide {
    transform: rotate(-90deg);
  }
`;
