import { createHashRouter } from 'react-router-dom';
import {
  partnerAdminRouterData,
  partnerUserRouterData,
  solitonAdminRouterData,
  solitonUserRouterData,
} from 'constant/routerData';

const solitonAdminRouter = createHashRouter(solitonAdminRouterData);
const solitonUserRouter = createHashRouter(solitonUserRouterData);
const partnerAdminRouter = createHashRouter(partnerAdminRouterData);
const partnerUserRouter = createHashRouter(partnerUserRouterData);

const appRouter = {
  ROLE_SOLITON_ADMIN: solitonAdminRouter,
  ROLE_SOLITON_USER: solitonUserRouter,
  ROLE_PARTNER_ADMIN: partnerAdminRouter,
  ROLE_PARTNER_USER: partnerUserRouter,
};

export { appRouter };
