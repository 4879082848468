import React, { useEffect, useState } from 'react';
import {
  deleteCategory,
  getDetailCategory,
  updateCategory,
} from 'api/product/category.api';
import { useNavigate, useParams } from 'react-router-dom';
import useSetTitlePage from 'hook/useSetTitlePage';
import useMultiLanguageEditCategory from './hook/useMultiLanguageEditCategory';
import { MANAGE_CATEGORY_PATH } from 'constant/appPath';
import FormCategory from '../component/FormCategory';
import useCustomMutateRenderMessage from 'hook/useQuery/useCustomMutateRenderMessage';
import useCustomQuery from 'hook/useQuery/useCustomQuery';
import useErrorCodeManageCategory from '../hook/useErrorCodeManageCategory';
import { CATEGORY_STATUS_DATA } from 'constant/categoryConstants';
import { CHECK_SPECIAL_CHARACTER } from 'constant/regex';

const EditCategory = () => {
  const { useQueryAPI } = useCustomQuery();
  const {
    APPLICATION_REGISTERCATEGORY_EDIT_TITLE,
    APPLICATION_REGISTERCATEGORY_MESSAGE_EDIT_SUCCESS,
    APPLICATION_REGISTERCATEGORY_MESSAGE_DELETE_SUCCESS,
  } = useMultiLanguageEditCategory();
  const { categoryId } = useParams();
  const [isDisable, setIsDisable] = useState(false);
  const { ERROR_CODE_MANAGE_CATEGORY } = useErrorCodeManageCategory();
  const { errorMessage, useMutationAPIHandleErrorCode } =
    useCustomMutateRenderMessage({
      errorCode: ERROR_CODE_MANAGE_CATEGORY,
    });
  const [title, setTitle] = useState(null);
  //const { currentCategory, setCurrentCategory } = useCategory();
  const navigate = useNavigate();
  const [isDisplayed, setIsDisplayed] = useState(CATEGORY_STATUS_DATA[0].value);
  const {
    data: categoryDetail = [],
    isLoading,
    error,
  } = useQueryAPI({
    api: getDetailCategory,
    key: 'categoryDetail',
    params: {
      categoryId: categoryId,
    },
    options: {
      refetchOnWindowFocus: false, // cancel fetch data when out focus
    },
  });

  const {
    mutate: updateCategoryMutate,
    isLoading: isLoadingUpdate,
    isSuccess,
  } = useMutationAPIHandleErrorCode({
    api: updateCategory,
    refetchKey: 'categoryDetail',
    path: `/${MANAGE_CATEGORY_PATH}`,
    messageSuccess: APPLICATION_REGISTERCATEGORY_MESSAGE_EDIT_SUCCESS,
  });

  const {
    mutate: deleteCategoryMutate,
    isError,
    isLoading: isLoadingDelete,
    isSuccess: isSuccessDelete,
  } = useMutationAPIHandleErrorCode({
    api: deleteCategory,
    refetchKey: 'listCategory',
    path: `/${MANAGE_CATEGORY_PATH}`,
    messageSuccess: `${APPLICATION_REGISTERCATEGORY_MESSAGE_DELETE_SUCCESS}`,
  });

  useEffect(() => {
    if (
      error?.response?.data?.message === '400_CATEGORY_NOT_FOUND' ||
      error?.response?.data?.message === '400_ARGUMENT_IS_NOT_VALID' ||
      CHECK_SPECIAL_CHARACTER.test(categoryId)
    ) {
      navigate('/category-not-found');
    }
    const data = categoryDetail?.data;
    setTitle(data?.title?.trim() ?? data?.title?.trim());
    setIsDisplayed(data?.isDisplayed ?? data?.isDisplayed);
  }, [
    error?.response?.data?.message,
    navigate,
    categoryDetail?.data,
    categoryId,
  ]);

  const handleUpdate = (value) => {
    updateCategoryMutate({
      categoryId: value?.categoryId,
      payload: value?.payload,
    });
  };

  const handleDeleteCategory = () => {
    deleteCategoryMutate({
      categoryId: categoryId,
    });
  };

  useSetTitlePage('カテゴリ詳細');

  return (
    <FormCategory
      titleForm={APPLICATION_REGISTERCATEGORY_EDIT_TITLE}
      formDetailData={categoryDetail.data}
      isEdit={true}
      isLoading={isLoading || isLoadingUpdate || isLoadingDelete}
      onFinish={handleUpdate}
      isDisable={isDisable}
      setIsDisable={setIsDisable}
      handleDeleteCategory={handleDeleteCategory}
      errorMessage={errorMessage}
      isError={isError}
      isSuccess={isSuccess || isSuccessDelete}
      title={title}
      setTitle={setTitle}
      setIsDisplayed={setIsDisplayed}
      isDisplayed={isDisplayed}
      categoryId={categoryId}
    />
  );
};

export default EditCategory;
