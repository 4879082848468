import {
  MANAGE_COMPANY_LIST_PATH,
  MANAGE_NOTIFICATION_PATH,
  MANAGE_PROJECT_REGISTRATION_PATH,
  MANAGE_ACCOUNT_SOLITON_PATH,
  MANAGE_ACCOUNT_PARTNER_PATH,
  // DASHBOARD_PATH,
  // WEBSITE_SETTING_PATH,
  // PROJECT_REGISTRATION_PATH,
} from 'constant/appPath';
import {
  // LayoutOutlined,
  ProjectOutlined,
  TeamOutlined,
  SolutionOutlined,
  CaretUpOutlined,
  CaretDownOutlined,
  NotificationOutlined,
  AuditOutlined,
  // ProfileOutlined,
  // SettingOutlined,
} from '@ant-design/icons';
import React from 'react';

/*
 * id range from 200 - 299
 * */

export const solitonAdminMenu = [
  // {
  //   id: 200,
  //   label: 'ダッシュボード',
  //   path: DASHBOARD_PATH,
  //   icon: <LayoutOutlined />,
  //   isParent: true,
  //   isTopPage: false,
  //   parentId: 0,
  //   children: [],
  // },
  {
    id: 201,
    label: '企業管理',
    path: `/${MANAGE_COMPANY_LIST_PATH}`,
    icon: <TeamOutlined />,
    isParent: true,
    isTopPage: false,
    parentId: 0,
    children: [],
  },
  {
    id: 202,
    label: 'アカウント管理', // manage account path
    path: ``,
    icon: <SolutionOutlined />,
    isParent: true,
    isTopPage: false,
    parentId: 0,
    children: [
      {
        id: 203,
        label: 'ソリトンアカウント管理', // manage soliton account
        path: `/${MANAGE_ACCOUNT_SOLITON_PATH}`,
        icon: 'dot',
        isParent: false,
        isTopPage: false,
        parentId: 202,
        children: [],
      },
      {
        id: 204,
        label: 'パートナーアカウント管理', // manage soliton partner
        path: `/${MANAGE_ACCOUNT_PARTNER_PATH}`,
        icon: 'dot',
        isParent: false,
        isTopPage: false,
        parentId: 202,
        children: [],
      },
    ],
  },
  {
    id: 205,
    label: 'お知らせ管理', // manage notification
    path: `/${MANAGE_NOTIFICATION_PATH}`,
    icon: <NotificationOutlined />,
    isParent: true,
    isTopPage: false,
    parentId: 0,
    children: [],
  },
  {
    id: 207,
    label: '製品情報管理', // manage product & service
    path: ``,
    icon: <AuditOutlined />,
    isParent: true,
    isTopPage: false,
    parentId: 0,
    children: [
      {
        id: 208,
        label: 'カテゴリ管理', // manage category
        path: `/category`,
        icon: 'dot',
        isParent: false,
        isTopPage: false,
        parentId: 207,
        children: [],
      },
      {
        id: 209,
        label: 'プロダクト管理', // manage product
        path: `/product`,
        icon: 'dot',
        isParent: false,
        isTopPage: false,
        parentId: 207,
        children: [],
      },
      {
        id: 210,
        label: 'コンテンツ管理', // manage content
        path: `/content`,
        icon: 'dot',
        isParent: false,
        isTopPage: false,
        parentId: 207,
        children: [],
      },
      {
        id: 211,
        label: '製品一覧', // manage top page
        path: `/top-page`,
        icon: 'dot',
        isParent: false,
        isTopPage: false,
        parentId: 207,
        children: [],
      },
    ],
  },
  {
    id: 212,
    label: '案件登録お申し込み', // project registration
    path: ``,
    icon: <ProjectOutlined />,
    isParent: true,
    isTopPage: false,
    parentId: 0,
    iconClose: <CaretUpOutlined />,
    iconOpen: <CaretDownOutlined />,
    children: [
      {
        id: 213,
        label: '案件登録お申込み管理', // manage project registration
        path: `/${MANAGE_PROJECT_REGISTRATION_PATH}`,
        icon: 'dot',
        isParent: false,
        isTopPage: false,
        parentId: 212,
        children: [],
      },
      {
        id: 214,
        label: '案件登録お申込み一覧',
        path: '/project-registration',
        icon: 'dot',
        isParent: false,
        isTopPage: false,
        parentId: 212,
        children: [],
      },
    ],
  },
  // {
  //   id: 213,
  //   label: 'サイト設定', // website setting
  //   path: `/${WEBSITE_SETTING_PATH}`,
  //   icon: <SettingOutlined />,
  //   isParent: true,
  //   isTopPage: false,
  //   parentId: 0,
  //   children: [],
  // },
];
