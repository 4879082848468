import React, { Suspense, useState } from 'react';
import AntdModal from 'components/AntdModal';
import { DatePicker, Form } from 'antd';
import { InputStyled } from 'components/Form2/styled';
import { FormItem } from 'components/Form2';
import FormMessage from 'components/Form/FormMessage';
import FormFileUpload from 'page/Application/PartnerWebsite/component/FormFileUpload';
import dayjs from 'dayjs';
import FormLabelGrid from 'components/Form/FormLabelGrid';

const ModalFile = ({
  isModalOpen,
  onCancel,
  form,
  dataEdit,
  isEditPopup,
  dataSource,
  setDataSource,
  setIsModalOpen,
  totalData,
}) => {
  const [, setIdFile] = useState(null);
  const [fileObject, setFileObject] = useState(null);
  const [isDisabled, setIsDisabled] = useState(false);
  const disabledDate = (current) => {
    return (
      !current ||
      current.isBefore('1920-01-01') ||
      current.isAfter('2039-01-01')
    );
  };

  const initialValues = {
    detailDisplayDate: null,
    // fileResponse: dataEdit && dataEdit?.fileResponse,
  };

  const sortObjectsByDateDescending = (objects) => {
    const objectsWithoutDetailId = [];
    const objectsWithDetailId = [];
    let foundDetailId = false;

    for (let i = 0; i < objects.length; i++) {
      const obj = objects[i];
      if ((!foundDetailId && obj.detailId) || obj?.hadSort) {
        foundDetailId = true;
      }

      if (foundDetailId) {
        objectsWithDetailId.push(obj);
      } else {
        objectsWithoutDetailId.push(obj);
      }
    }

    objectsWithoutDetailId.sort(
      (a, b) => new Date(b.detailDisplayDate) - new Date(a.detailDisplayDate),
    );

    return [...objectsWithoutDetailId, ...objectsWithDetailId];
  };

  const onFinish = () => {
    form
      .validateFields()
      .then(() => {
        const value = form.getFieldsValue(true);
        if (isEditPopup) {
          const editDataSource = dataSource.map((item) => {
            if (dataEdit.key === item.key) {
              return {
                ...item,
                ...value,
                detailId: item.detailId,
                detailDisplayDate:
                  value?.detailDisplayDate &&
                  dayjs(value?.detailDisplayDate).format('YYYY-MM-DD'),
                fileResponse:
                  item.fileResponse.name !==
                  (value.fileResponse[0].name || value?.fileResponse.name)
                    ? value?.fileResponse[0]?.originFileObj
                      ? value?.fileResponse[0]?.originFileObj
                      : value?.fileResponse
                    : item.fileResponse,
                fileStorageRequest: fileObject && {
                  fileName: fileObject?.fileName,
                  keyFileName: fileObject?.keyFileName,
                  contentType: fileObject?.contentType,
                  size: fileObject?.size,
                },
              };
            }
            return { ...item };
          });
          setDataSource(editDataSource);
          setIsModalOpen(false);
          form.resetFields();
        } else {
          const editDataSource = (prev) =>
            sortObjectsByDateDescending([
              {
                key: totalData + 1,
                ...value,
                detailDisplayDate:
                  value?.detailDisplayDate &&
                  dayjs(value?.detailDisplayDate).format('YYYY-MM-DD'),
                fileResponse: value?.fileResponse[0]?.originFileObj,
                fileStorageRequest: fileObject && {
                  fileName: fileObject?.fileName,
                  keyFileName: fileObject?.keyFileName,
                  contentType: fileObject?.contentType,
                  size: fileObject?.size,
                },
              },
              ...prev,
            ]);
          setDataSource(editDataSource);
          setIsModalOpen(false);
          form.resetFields();
        }
      })
      .catch(() => {
        setIsModalOpen(true);
        return false;
      });
  };

  return (
    <Suspense fallback={form}>
      <AntdModal
        isModalOpen={isModalOpen}
        onCancel={onCancel}
        title={!isEditPopup ? 'ファイル情報入力' : 'ファイル情報の変更'}
        width={740}
        textSubmit={!isEditPopup ? '登録' : '保存'}
        handleOk={onFinish}
        isDisabled={isDisabled}
      >
        <Form
          initialValues={initialValues}
          size={'Large'}
          labelCol={{
            span: 4,
          }}
          style={{ paddingTop: 32 }}
          name={'uploadFile'}
          form={form}
        >
          <FormItem
            label={<FormLabelGrid labelText={'タイトル'} required={true} />}
            name={'detailTitle'}
            rules={[
              {
                validator: (_, value) => {
                  const trimmedString = value?.trim();
                  if (
                    !value ||
                    trimmedString.length === 0 ||
                    value.trim() === ''
                  ) {
                    return Promise.reject(
                      <FormMessage
                        text1={'情報を入力してください。'}
                        marginBottom={'12px'}
                      />,
                    );
                  }
                  return Promise.resolve();
                },
              },
            ]}
          >
            <InputStyled
              maxLength={256}
              placeholder={'タイトル名を入力してください'}
            />
          </FormItem>

          <FormItem
            label={<FormLabelGrid labelText={'表示日付'} required={true} />}
            name="detailDisplayDate"
            rules={[
              {
                required: true,
                message: (
                  <FormMessage
                    text1={'情報を入力してください。'}
                    marginBottom={'12px'}
                  />
                ),
              },
            ]}
          >
            <DatePicker
              inputReadOnly
              style={{ width: '100%', height: 40 }}
              placeholder={'日付選択'}
              format={'YYYY年MM月DD日'}
              disabledDate={(date) => disabledDate(date)}
            />
          </FormItem>
          <FormItem
            name="fileResponse"
            label={
              <div>
                アップロード
                <span style={{ marginLeft: 6, color: '#9d1c49' }}>*</span>
              </div>
            }
          >
            <FormFileUpload
              name={'fileResponse'}
              multipleUpload={false}
              required={true}
              maxCount={1}
              setIdFile={setIdFile}
              text={'アップロード可能なファイル形式とサイズ：'}
              hint={
                'pdf, pptx, ppt, xlsx, xls, xlsm, docx, doc - 50 MB; txt - 1MB; zip, bin, exe, nabin, efm - 1GB; iso - 4GB; csv - 10MB'
              }
              form={form}
              isPreview={false}
              setFileObject={setFileObject}
              fileObject={fileObject}
              isDisabled={isDisabled}
              setIsDisabled={setIsDisabled}
            />
          </FormItem>
        </Form>
      </AntdModal>
    </Suspense>
  );
};

export default ModalFile;
