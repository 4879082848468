import styled from 'styled-components';
import { Button } from 'antd';

export const TopPageProductNotifyWrapper = styled.div`
  margin-bottom: 50px;
`;
export const TopPageProductMenuWrapper = styled.div`
  margin-top: 10px;
`;
export const ButtonVersion = styled(Button)`
  width: 140px;
  height: 40px;
  word-spacing: -3px;
  border: #656464 1px solid;
  font-size: 14px;
  font-weight: 400;
  font-family: 'inter-regular', sans-serif;
  margin-bottom: 10px;
  margin-top: 20px;
`;

export const ButtonStyle = styled(Button)`
  height: 40px;
  border: #656464 1px solid;
  font-size: 14px !important;
  box-shadow: none !important;
  font-family: 'inter-regular', sans-serif;
  color: #656464;
  margin-bottom: 20px;
`;
export const BodyProductDetail = styled.div`
  font-family: 'inter-regular', sans-serif;
  color: #656464;
  @media all and (min-width: 0px) and (max-width: 320px) {
    padding: 0;
  }
  @media all and (min-width: 320px) and (max-width: 430px) {
    padding: 0;
  }
`;
export const TitleSubmenu = styled.div`
  font-family: 'inter-regular', sans-serif;
  color: #656464;
  font-weight: 700;
  font-size: 22px;
  line-height: 26px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: fit-content;
  padding-bottom: 20px;

  @media all and (min-width: 0px) and (max-width: 320px) {
    max-width: 200px;
  }
  @media all and (min-width: 321px) and (max-width: 430px) {
    max-width: 250px;
  }
  @media all and (min-width: 431px) and (max-width: 768px) {
    max-width: 280px;
  }
  @media all and (min-width: 769px) and (max-width: 1024px) {
    max-width: 600px;
  }
  @media all and (min-width: 1025px) and (max-width: 1200px) {
    max-width: 550px;
  }
  @media all and (min-width: 1201px) and (max-width: 1440px) {
    max-width: 700px;
  }

  // Tach ra cho 2 truong hop hien thi o view va o edit mode
  @media all and (min-width: 1441px) and (max-width: 1720px) {
    max-width: 900px;
  }
  // Tach ra cho 2 truong hop hien thi o view va o edit mode
  @media all and (min-width: 1721px) and (max-width: 1921px) {
    max-width: 1460px;
  }
`;
export const TableListProductDetail = styled.div`
  font-family: 'inter-regular', sans-serif;
  color: #656464;
  position: relative;
  background-color: ${(props) => (props?.background ? '' : 'white')};
  margin-bottom: 10px;
  padding: ${(props) => (props.paddingedit ? '0' : '16px 35px')};
  border-radius: 8px;
`;
export const TitleFormProductDetail = styled.div`
  display: flex;
  height: 16px;
  justify-content: space-between;

  @media all and (min-width: 0px) and (max-width: 320px) {
    display: initial;
  }
  @media all and (min-width: 321px) and (max-width: 430px) {
    display: initial;
  }
  @media all and (min-width: 431px) and (max-width: 768px) {
    display: initial;
  }
`;
export const FormDetailTitle = styled.a`
  //display: flex;
  font-family: 'inter-regular', sans-serif;
  height: 20px;
  color: var(--BTN-TEXT-SECONDARY-default);
  font-weight: 700;
  cursor: ${(props) => props?.cursor ?? 'pointer'};
  font-size: 16px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  pointer-events: ${(props) => props?.pointerEvents};
  &.link {
    text-decoration: underline;
  }

  &:hover {
    color: ${(props) => props?.color};
  }

  @media all and (min-width: 0px) and (max-width: 320px) {
    max-width: 300px;
  }
  @media all and (min-width: 321px) and (max-width: 430px) {
    max-width: 340px;
  }
  @media all and (min-width: 431px) and (max-width: 768px) {
    max-width: 600px;
  }
  @media all and (min-width: 769px) and (max-width: 1024px) {
    max-width: 460px;
  }
  @media all and (min-width: 1025px) and (max-width: 1200px) {
    max-width: 700px;
  }
  @media all and (min-width: 1201px) and (max-width: 1440px) {
    max-width: 650px;
  }

  // Tach ra cho 2 truong hop hien thi o view va o edit mode
  @media all and (min-width: 1441px) and (max-width: 1720px) {
    max-width: 800px;
  }
  // Tach ra cho 2 truong hop hien thi o view va o edit mode
  @media all and (min-width: 1721px) and (max-width: 1920px) {
    max-width: 1060px;
  }
  @media (min-width: 1921px) {
    max-width: 1260px;
  }
`;

export const TextWrapper = styled.div`
  color: VAR(--FORM-LABEL-default);
  word-wrap: break-word;
  line-height: 20px;
  font-family: inherit;
  white-space: nowrap;

  ol {
    list-style: decimal inside;
    padding-left: 20px;
  }

  ul {
    list-style: initial;
    padding-left: 10px;
    margin-left: 10px;
  }
  li {
    word-wrap: normal;
  }
`;

export const DateWrapper = styled.div`
  text-align: right;
  margin: 8px 0 12px 0;
`;

export const FormDate = styled.div`
  font-weight: 600;
  font-size: 14px;

  @media all and (min-width: 0px) and (max-width: 320px) {
    padding-top: 10px;
  }
  @media all and (min-width: 321px) and (max-width: 430px) {
    padding-top: 10px;
  }
  @media all and (min-width: 431px) and (max-width: 768px) {
    padding-top: 10px;
  }

  &.date-witch-rich-text {
    top: 0;
  }
`;
export const FormContentAndDate = styled.div`
  overflow-x: scroll;
  overflow-y: hidden;
  padding-bottom: 20px;
  ol {
    list-style: decimal inside;
  }

  ul {
    list-style-position: inside;
  }

  li {
    word-wrap: normal;
    list-style-position: inside;
  }

  &::-webkit-scrollbar {
    width: 2px;
    height: 6px;
    cursor: grab;
  }

  &::-webkit-scrollbar-track {
    background: transparent;
  }

  &::-webkit-scrollbar-thumb {
    background: #ecececff;
    border-radius: 2px;
  }

  &::-webkit-scrollbar-thumb:hover {
    background: #c2c2c2ff;
  }

  &.date-padding {
    padding-bottom: 20px;
  }
`;
export const FormContent = styled.div`
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  width: 100%;
  align-items: center;
  max-width: 1500px;
  overflow: hidden;
  word-break: break-all;
  text-overflow: ellipsis;

  @media all and (min-width: 0px) and (max-width: 320px) {
    font-size: 14px;
  }
  @media all and (min-width: 320px) and (max-width: 430px) {
    font-size: 14px;
  }

  ol {
    color: #656464;
    list-style: decimal;
  }

  ul {
    color: #656464;
    list-style: initial;
  }
  li {
    list-style-position: inside;
    word-wrap: normal;
  }
`;

export const FormHashCode = styled.div`
  padding-bottom: 20px;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  line-break: anywhere;

  @media all and (min-width: 0px) and (max-width: 320px) {
    font-size: 14px;
  }
  @media all and (min-width: 320px) and (max-width: 430px) {
    font-size: 14px;
  }
`;
export const FormDownloadFile = styled(Button)`
  min-height: 40px;
  width: 140px;
  border: #656464 1px solid;
  font-size: 14px !important;
  box-shadow: none !important;
  font-family: 'inter-regular', sans-serif;
  color: #656464;
  margin-bottom: 20px;
`;

export const BreadCrumbDetail = styled.div`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 300px;
`;
