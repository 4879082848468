import Link from 'components/Link/index';
import { LinkStyled } from 'components/Link/styled';
import React, { Suspense, useEffect, useMemo, useState } from 'react';
import { Alert, Form, Spin } from 'antd';
import useMultiLanguageRegisterCategory from '../../RegisterCategory/hook/useMultiLanguageRegisterCategory';
import FormLabelGrid from '../FormLabelGrid';
import FormTextRequired from '../FormTextRequired';
import FormRadio from '../FormRadio';
import { MANAGE_CATEGORY_PATH } from 'constant/appPath';
import { Grid1, Grid2, GridForm } from '../styled';
import FormFooterSubmit from '../FormFooterSubmit';
import { useNavigate } from 'react-router-dom';
import useModalConfirm from 'hook/useModalConfirm';
import Breadcrumb from 'components/Layout/ApplicationLayout/Breadcrumb';
import LayoutDefault from 'components/Layout/LayoutDefault';
import FormTitle from '../FormTitle';
import { Container, FormWrapper } from 'components/Form/Template/styled';
import useMultiLanguageEditCategory from '../../EditCategory/hook/useMultiLanguageEditCategory';
import FormListProduct from '../FormListProduct';

const FormCategory = ({
  onFinish,
  formDetailData,
  isEdit,
  isLoading,
  handleDeleteCategory,
  isDisable,
  setIsDisable,
  isError,
  errorMessage,
  isSuccess,
  setIsDisplayed,
  isDisplayed,
  categoryId,
}) => {
  const [form] = Form.useForm();
  const { contextHolder, handleConfirmAction } = useModalConfirm();
  const [isCancel, setIsCancel] = useState(false);
  const navigate = useNavigate();
  const {
    APPLICATION_REGISTERCATEGORY_TITLE,
    APPLICATION_REGISTERCATEGORY_FORM_TITLE,
    APPLICATION_REGISTERCATEGORY_FORM_STATUS,
    APPLICATION_REGISTERCATEGORY_PLACEHOLDER_TITLE,
    APPLICATION_REGISTERCATEGORY_FORM_SUBMIT,
    APPLICATION_REGISTERCATEGORY_BUTTON_KEEP,
    APPLICATION_REGISTERCATEGORY_BUTTON_DELETE,
    APPLICATION_REGISTERCATEGORY_FORM_CANCEL,
    APPLICATION_REGISTERCATEGORY_FIELD_REQUIRED,
    APPLICATION_REGISTERCATEGORY_OK,
    APPLICATION_REGISTERCATEGORY_MESSAGE_CANCEL,
    APPLICATION_REGISTERCATEGORY_DELETE,
    APPLICATION_REGISTERCATEGORY_MESSAGE_DELETE,
    APPLICATION_REGISTERCATEGORY_FORM_WARNING_MESSAGE,
    APPLICATION_REGISTERCATEGORY_FORM_PRODUCT,
    APPLICATION_REGISTERCATEGORY_FORM_SUBMIT_EXISTING_PRODUCT,
  } = useMultiLanguageRegisterCategory();

  const listBreadCrumb = useMemo(() => {
    return [
      {
        title: (
          <Link
            fontSize={14}
            text={'企業管理'}
            to={'/manage-company-list'}
          ></Link>
        ),
      },
      {
        title: (
          <Link fontSize={14} text={'カテゴリ管理'} to={'/category'}></Link>
        ),
      },
      {
        title: isEdit ? 'カテゴリ詳細' : 'カテゴリ登録',
      },
    ];
  }, [isEdit]);

  useEffect(() => {
    if (isEdit && formDetailData) {
      form.setFieldsValue({
        ...formDetailData,
        title: formDetailData.title,
        isDisplayed: formDetailData.isDisplayed,
      });
    }
  }, [isEdit, formDetailData, form]);

  const handleDelete = () => {
    handleConfirmAction({
      confirmFn: handleDeleteCategory,
      title: APPLICATION_REGISTERCATEGORY_MESSAGE_DELETE,
      okText: APPLICATION_REGISTERCATEGORY_DELETE,
      cancelText: APPLICATION_REGISTERCATEGORY_FORM_CANCEL,
      setIsDisable: setIsDisable,
    });
    setIsDisable(true);
  };

  const handleCancel = () => {
    handleConfirmAction({
      confirmFn: navigate,
      params: `/${MANAGE_CATEGORY_PATH}`,
      title: APPLICATION_REGISTERCATEGORY_MESSAGE_CANCEL,
      okText: APPLICATION_REGISTERCATEGORY_OK,
      cancelText: APPLICATION_REGISTERCATEGORY_FORM_CANCEL,
      setIsDisable: setIsCancel,
    });
    setIsCancel(true);
  };

  const handleUpdate = () => {
    const value = form.getFieldsValue();
    const payload = {
      ...value,
      title: value?.title?.trim(),
      isDisplayed: value?.isDisplayed,
    };
    if (isEdit) {
      if (formDetailData?.isDisplayed == true && isDisplayed == false) {
        handleConfirmAction({
          confirmFn: onFinish,
          params: { categoryId: categoryId, payload: JSON.stringify(payload) },
          title: APPLICATION_REGISTERCATEGORY_FORM_SUBMIT_EXISTING_PRODUCT,
          okText: APPLICATION_REGISTERCATEGORY_OK,
          cancelText: APPLICATION_REGISTERCATEGORY_FORM_CANCEL,
          setIsDisable: setIsDisable,
        });
        setIsDisable(true);
      } else {
        onFinish({ categoryId: categoryId, payload: JSON.stringify(payload) });
      }
    } else {
      onFinish({ payload });
    }
  };

  const initialValues = {
    isDisplayed: isDisplayed,
  };

  const onChangeStatus = (e) => {
    const statusValue = e.target.value;
    form.setFieldValue('isDisplayed', statusValue);
    setIsDisplayed(statusValue);
  };

  return (
    <>
      <Breadcrumb
        titlePage={listBreadCrumb[2].title}
        listItem={listBreadCrumb}
      />
      <Suspense fallback={isLoading}>
        {contextHolder}
        <LayoutDefault>
          <FormTitle
            title={
              isEdit
                ? APPLICATION_REGISTERCATEGORY_FORM_WARNING_MESSAGE
                : APPLICATION_REGISTERCATEGORY_TITLE
            }
            type={isEdit ? 'text' : 'title'}
          ></FormTitle>
          <FormWrapper>
            <Container>
              <Spin spinning={isLoading || isSuccess}>
                <Form
                  encType="multipart/form-data"
                  form={form}
                  layout="horizontal"
                  size="large"
                  initialValues={initialValues}
                  onFinish={handleUpdate}
                >
                  <GridForm>
                    <Grid2>
                      {(isError || errorMessage) && (
                        <Alert
                          message={errorMessage}
                          type="error"
                          style={{ marginBottom: '24px' }}
                        />
                      )}
                    </Grid2>
                    <Grid1>
                      <FormLabelGrid
                        labelText={APPLICATION_REGISTERCATEGORY_FORM_TITLE}
                        required={true}
                      ></FormLabelGrid>
                    </Grid1>
                    <Grid2>
                      <FormTextRequired
                        placeHolder={
                          APPLICATION_REGISTERCATEGORY_PLACEHOLDER_TITLE
                        }
                        name="title"
                        required={true}
                        message={APPLICATION_REGISTERCATEGORY_FIELD_REQUIRED}
                        maxLength={256}
                      ></FormTextRequired>
                    </Grid2>
                    <Grid1>
                      <FormLabelGrid
                        labelText={APPLICATION_REGISTERCATEGORY_FORM_STATUS}
                        required={true}
                      ></FormLabelGrid>
                    </Grid1>
                    <Grid2>
                      <FormRadio
                        name={'isDisplayed'}
                        required={true}
                        onChange={onChangeStatus}
                      />
                    </Grid2>
                    {isEdit && (
                      <>
                        <Grid1>
                          <FormLabelGrid
                            labelText={
                              APPLICATION_REGISTERCATEGORY_FORM_PRODUCT
                            }
                            required={false}
                          />
                        </Grid1>
                        <Grid2>
                          <FormListProduct
                            lstProductData={formDetailData?.listProduct}
                          />
                        </Grid2>
                      </>
                    )}
                    <Grid2>
                      <FormFooterSubmit
                        isDisable={isDisable}
                        onCancel={handleCancel}
                        onDelete={handleDelete}
                        textOk={
                          isEdit
                            ? APPLICATION_REGISTERCATEGORY_BUTTON_KEEP
                            : APPLICATION_REGISTERCATEGORY_FORM_SUBMIT
                        }
                        textCancel={APPLICATION_REGISTERCATEGORY_FORM_CANCEL}
                        textDelete={APPLICATION_REGISTERCATEGORY_BUTTON_DELETE}
                        isDelete={isEdit}
                        isCancel={isCancel}
                        isDisableDelete={
                          isEdit && formDetailData?.listProduct?.length > 0
                        }
                      ></FormFooterSubmit>
                    </Grid2>
                  </GridForm>
                </Form>
              </Spin>
            </Container>
          </FormWrapper>
        </LayoutDefault>
      </Suspense>
    </>
  );
};
export default FormCategory;
