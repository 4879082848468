import React from 'react';

const LogoWhite = ({ width, height }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 75 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1.25 5.59393C1.3 5.55643 1.29376 5.50017 1.30001 5.45017C1.40001 4.54392 1.68125 3.70018 2.1875 2.93768C2.9 1.86268 3.88126 1.15642 5.12501 0.818921C5.58751 0.693921 6.05623 0.643921 6.5375 0.643921C8.44375 0.643921 10.3438 0.643921 12.25 0.643921C12.2875 0.643921 12.3313 0.643927 12.3813 0.650177C12.3813 0.700177 12.3875 0.737671 12.3875 0.775171C12.3875 2.11892 12.3875 3.46893 12.3875 4.81268C12.3875 4.83143 12.3875 4.85642 12.3875 4.87517C12.3875 4.93142 12.3563 4.95642 12.3 4.95017C12.2625 4.95017 12.225 4.95017 12.1875 4.95017C10.475 4.95017 8.75625 4.95017 7.04375 4.95017C6.79375 4.95017 6.54375 4.96892 6.30625 5.06267C5.84998 5.23767 5.59999 5.57517 5.53749 6.05642C5.51249 6.25644 5.525 6.45644 5.5875 6.65019C5.70625 7.05019 5.98124 7.30019 6.3625 7.44394C6.5375 7.51269 6.725 7.55019 6.9125 7.56269C7.4625 7.60644 8 7.68144 8.5375 7.81894C9.5625 8.08769 10.4938 8.53144 11.2625 9.28144C11.8938 9.90019 12.325 10.6377 12.5938 11.4752C12.8563 12.3002 12.9313 13.1502 12.8125 14.0127C12.5313 16.0689 11.225 17.8627 9.2125 18.6752C8.3875 19.0064 7.53125 19.1627 6.65 19.1627C4.91251 19.1627 3.16874 19.1627 1.43124 19.1627C1.36874 19.1627 1.3125 19.1439 1.25 19.1314C1.25 17.7127 1.25 16.3002 1.25 14.8814C1.31875 14.8689 1.38751 14.8502 1.45626 14.8502C3.13751 14.8502 4.81251 14.8502 6.49375 14.8502C6.6625 14.8502 6.8375 14.8377 7.00625 14.8002C7.9625 14.5627 8.38125 13.5939 8.09375 12.7689C7.95625 12.3877 7.66875 12.1564 7.30625 12.0127C7.0375 11.9064 6.75625 11.8564 6.46875 11.8314C6.01249 11.7877 5.56873 11.7064 5.13123 11.5689C3.66873 11.1002 2.58748 10.1877 1.89373 8.81894C1.57498 8.18144 1.38748 7.51269 1.30623 6.80019C1.30623 6.75644 1.27501 6.71894 1.25626 6.68144V5.57517L1.25 5.59393Z"
        fill="white"
      />
      <path
        d="M52.6437 19.369C52.5125 19.3503 52.3875 19.3253 52.2562 19.3065C51.1312 19.144 50.1125 18.7315 49.2 18.0565C48.0812 17.2315 47.275 16.169 46.8 14.869C46.4687 13.969 46.3375 13.0378 46.4 12.0815C46.525 10.2628 47.2562 8.7315 48.5812 7.4815C49.4875 6.62525 50.5625 6.06901 51.7812 5.80026C52.575 5.62526 53.3812 5.59401 54.1875 5.70026C56.5375 6.00651 58.6125 7.5815 59.5562 9.76275C60.0125 10.819 60.1875 11.9128 60.1 13.0565C59.8875 15.8378 57.9812 18.1753 55.4187 19.0315C54.95 19.1878 54.4625 19.294 53.9687 19.3378C53.9312 19.3378 53.8875 19.3565 53.85 19.3628H52.6437V19.369ZM53.2687 9.72525C51.7375 9.71275 50.4687 10.9565 50.4625 12.5003C50.4625 14.1065 51.7625 15.294 53.225 15.3065C54.7312 15.319 56.0062 14.1128 56.0437 12.5815C56.0812 11.044 54.85 9.73775 53.2687 9.72525Z"
        fill="white"
      />
      <path
        d="M60.7062 19.1752C60.7062 19.1252 60.7062 19.0814 60.7062 19.0377C60.7062 16.4939 60.7062 13.9439 60.7062 11.4002C60.7062 10.4002 60.9187 9.45019 61.3999 8.56894C61.9374 7.58769 62.7187 6.85644 63.7125 6.35644C64.725 5.85019 65.8 5.64394 66.925 5.73144C68.325 5.83769 69.55 6.36269 70.5875 7.30644C71.4437 8.09394 72 9.05644 72.2625 10.1939C72.3625 10.6252 72.4062 11.0564 72.4062 11.4939C72.4062 14.0002 72.4062 16.5127 72.4062 19.0189C72.4062 19.0689 72.4062 19.1127 72.4062 19.1564C72.3062 19.1877 68.2687 19.2002 68.0812 19.1689C68.0812 19.1314 68.075 19.0877 68.075 19.0439C68.075 18.7189 68.075 18.3877 68.075 18.0627C68.075 16.0252 68.075 13.9877 68.075 11.9502C68.075 11.5064 67.9375 11.1127 67.6375 10.7814C67.2562 10.3689 66.775 10.2252 66.2312 10.3439C65.6562 10.4689 65.2812 10.8377 65.1062 11.3939C65.05 11.5627 65.0375 11.7377 65.0375 11.9127C65.0375 14.2689 65.0375 16.6252 65.0375 18.9814V19.1377L65.0187 19.1564C65.0187 19.1564 65.0187 19.1627 65.0062 19.1627C64.9875 19.1627 64.9687 19.1689 64.9437 19.1689C63.55 19.1689 62.1624 19.1689 60.7687 19.1689C60.7562 19.1689 60.7437 19.1689 60.7124 19.1564L60.7062 19.1752Z"
        fill="white"
      />
      <path
        d="M43.7186 10.3688C43.7186 10.4375 43.7186 10.4813 43.7186 10.525C43.7186 11.7188 43.7248 12.9063 43.7311 14.1C43.7311 14.225 43.7436 14.3438 43.7561 14.4688C43.8186 14.8875 44.0748 15.1563 44.4998 15.225C44.6498 15.25 44.7998 15.2625 44.9498 15.2625C45.2998 15.2625 45.6436 15.2625 45.9936 15.2625C46.1123 15.2625 46.1186 15.2625 46.1498 15.3875C46.4686 16.5875 46.7811 17.7813 47.0998 18.9813C47.1123 19.0313 47.1248 19.0813 47.1373 19.15C47.0936 19.15 47.0623 19.1625 47.0311 19.1625C46.2186 19.1625 45.3998 19.175 44.5873 19.1563C43.7498 19.1375 42.9311 19.0063 42.1561 18.6875C41.2561 18.3188 40.5373 17.7438 40.0373 16.9C39.7186 16.3625 39.5248 15.7875 39.4623 15.1688C39.4311 14.9 39.4186 14.6313 39.4186 14.3625C39.4186 9.85 39.4186 5.34378 39.4186 0.831274V0.662524C39.4748 0.662524 39.5248 0.650024 39.5748 0.650024C40.8748 0.650024 42.1686 0.650024 43.4686 0.650024C43.5311 0.650024 43.5936 0.650024 43.6498 0.650024C43.7061 0.650024 43.7311 0.675024 43.7311 0.731274C43.7311 0.762524 43.7311 0.793774 43.7311 0.831274C43.7311 2.51877 43.7311 4.20628 43.7311 5.88753V6.06253C43.7873 6.06253 43.8373 6.06878 43.8811 6.06878C44.4998 6.06878 45.1186 6.06878 45.7373 6.06878C45.7873 6.06878 45.8311 6.06878 45.8748 6.06878C45.8998 6.17503 45.9123 10.1938 45.8873 10.3625C45.8436 10.3625 45.7998 10.375 45.7498 10.375C45.1311 10.375 44.5123 10.375 43.8936 10.375C43.8436 10.375 43.7998 10.375 43.7311 10.375L43.7186 10.3688Z"
        fill="white"
      />
      <path
        d="M32.0187 19.15H27.8562L27.8437 19.1375C27.8437 19.1375 27.8437 19.1312 27.8374 19.125C27.8374 19.1062 27.8312 19.0937 27.8312 19.075C27.8312 12.9625 27.8312 6.84375 27.8312 0.731274C27.8312 0.712524 27.8312 0.700024 27.8312 0.681274C27.8312 0.675024 27.8374 0.668774 27.8499 0.650024H32.0187V19.1437V19.15Z"
        fill="white"
      />
      <path
        d="M27.1373 12.9438C27.0936 12.9438 27.0561 12.95 27.0186 12.95C26.0623 12.95 25.1123 12.95 24.1561 12.95C24.0061 12.95 23.8561 12.9313 23.7061 12.9125C23.4373 12.875 23.2186 12.7438 23.0623 12.525C22.9686 12.4 22.8873 12.2688 22.8186 12.1313C22.4436 11.425 22.0748 10.7125 21.7061 10C21.5623 9.73125 21.3936 9.48125 21.1436 9.29375C20.6311 8.9 20.0061 8.925 19.5248 9.35625C19.3248 9.53125 19.1811 9.75 19.0623 9.9875C18.7873 10.5188 18.5123 11.05 18.2373 11.5813C18.1373 11.775 18.0436 11.9688 17.9311 12.1563C17.8561 12.2875 17.7686 12.4125 17.6748 12.5313C17.4623 12.7875 17.1748 12.9188 16.8436 12.9438C16.7248 12.95 16.6061 12.9563 16.4873 12.9563C15.5686 12.9563 14.6561 12.9563 13.7373 12.9563C13.7186 12.9563 13.6936 12.9563 13.6748 12.9563C13.5748 12.9563 13.5686 12.95 13.5623 12.85C13.5623 12.6375 13.5498 12.425 13.5623 12.2125C13.7061 9.4625 15.4373 7.10625 18.0123 6.15623C18.9561 5.80623 19.9373 5.67498 20.9436 5.76873C22.6873 5.93123 24.1561 6.65625 25.3498 7.93125C26.1186 8.75625 26.6436 9.71875 26.9311 10.8063C27.0998 11.4375 27.1686 12.0813 27.1561 12.7375C27.1561 12.8063 27.1436 12.875 27.1373 12.95V12.9438Z"
        fill="white"
      />
      <path
        d="M13.7874 14.2125C13.8374 14.2125 13.8874 14.2063 13.9311 14.2063C14.7811 14.2063 15.6311 14.2063 16.4874 14.2063C16.8061 14.2063 17.1249 14.1875 17.4374 14.1C17.9999 13.95 18.4374 13.625 18.7749 13.1563C18.8811 13.0063 18.9811 12.85 19.0686 12.6875C19.4061 12.0563 19.7311 11.4188 20.0686 10.7875C20.0874 10.7563 20.0999 10.7188 20.1249 10.6938C20.2624 10.4875 20.5436 10.5 20.6624 10.7125C20.7624 10.8938 20.8561 11.0813 20.9499 11.2688C21.2186 11.7813 21.4874 12.2875 21.7561 12.8C21.8936 13.0625 22.0624 13.3 22.2686 13.5188C22.6186 13.8813 23.0561 14.0938 23.5499 14.1563C23.8124 14.1938 24.0811 14.2063 24.3436 14.2063C25.1561 14.2125 25.9749 14.2063 26.7874 14.2063C26.8374 14.2063 26.8811 14.2063 26.9311 14.2125C26.3624 16.775 23.7999 19.4375 20.1436 19.3313C16.5624 19.2188 14.2686 16.5063 13.7811 14.2125H13.7874Z"
        fill="white"
      />
      <path
        d="M37.9 19.1499H33.6063C33.6063 19.1124 33.5938 19.0812 33.5938 19.0499C33.5938 19.0124 33.5938 18.9749 33.5938 18.9374C33.5938 14.6624 33.5938 10.3937 33.5938 6.11869C33.5938 5.91869 33.5937 5.93118 33.7812 5.93118C35.1 5.93118 36.4188 5.93118 37.7375 5.93118C37.85 5.93118 37.9063 5.98743 37.9063 6.09993C37.9063 10.3999 37.9063 14.6937 37.9063 18.9937V19.1437L37.9 19.1499Z"
        fill="white"
      />
      <path
        d="M38.0687 2.96262C38.0687 4.24387 37.0187 5.28137 35.7312 5.28137C34.4437 5.28137 33.4125 4.23761 33.4125 2.95012C33.4125 1.60011 34.55 0.612616 35.7687 0.625117C36.925 0.637617 38.075 1.58762 38.0687 2.96262Z"
        fill="white"
      />
      <path
        d="M73.8125 4.15637C73.8 4.99387 73.35 5.70638 72.5875 6.00638C71.2813 6.51887 70.0938 5.66263 69.8876 4.55638C69.6688 3.40013 70.3876 2.58138 71.1188 2.31263C72.3688 1.85013 73.5063 2.63762 73.7625 3.67512C73.8 3.83137 73.8 4.00013 73.8188 4.16263L73.8125 4.15637ZM73.525 4.15637C73.55 3.25012 72.7688 2.45013 71.8313 2.44388C70.9125 2.44388 70.125 3.21887 70.1313 4.15012C70.1375 5.10637 70.8751 5.85637 71.8188 5.86262C72.7938 5.87512 73.55 5.04387 73.525 4.15637Z"
        fill="white"
      />
      <path
        d="M72.2813 4.22515C72.325 4.25015 72.3438 4.2564 72.3563 4.26265C72.55 4.35015 72.6563 4.5064 72.6813 4.7064C72.7 4.87515 72.7 5.05015 72.7063 5.23765C72.6063 5.23765 72.5063 5.23765 72.4125 5.23765C72.3625 5.23765 72.35 5.1939 72.3438 5.15015C72.3438 5.07515 72.3438 5.00015 72.3438 4.9314C72.3375 4.8439 72.325 4.75015 72.3 4.6689C72.2563 4.52515 72.1563 4.43765 72 4.41265C71.8125 4.3814 71.625 4.38765 71.425 4.3939C71.425 4.6814 71.425 4.9564 71.4188 5.2439C71.3 5.2439 71.1938 5.2439 71.0875 5.2439C71.0438 5.2439 71.025 5.21265 71.025 5.1689C71.025 5.15015 71.025 5.12515 71.025 5.1064C71.025 4.4689 71.025 3.8314 71.025 3.1939C71.025 3.1564 71.025 3.1189 71.025 3.0814C71.05 3.07515 71.075 3.0564 71.0938 3.06265C71.4563 3.06265 71.8125 3.03765 72.175 3.08765C72.2313 3.0939 72.2875 3.1064 72.3438 3.12515C72.5625 3.20015 72.6938 3.35015 72.7125 3.5814C72.7188 3.65015 72.7188 3.7189 72.7125 3.78765C72.6938 3.96265 72.6 4.08765 72.4375 4.16265C72.3938 4.1814 72.35 4.20015 72.275 4.22515H72.2813ZM71.4313 4.1064C71.6438 4.1064 71.8438 4.12515 72.05 4.07515C72.0875 4.0689 72.1313 4.0564 72.1625 4.03765C72.3125 3.9564 72.3563 3.82515 72.3438 3.6689C72.3313 3.51265 72.2625 3.4189 72.1125 3.37515C71.8938 3.31265 71.6688 3.33765 71.4375 3.3314V4.1064H71.4313Z"
        fill="white"
      />
    </svg>
  );
};

export default LogoWhite;
