import { useIntl } from 'react-intl';
const useMultiLanguageApplicationLayout = () => {
  const intl = useIntl();
  const SEARCH_ALL_SYSTEMS = intl.formatMessage({
    id: 'authentication.applicationLayout.searchAll',
  });
  return {
    SEARCH_ALL_SYSTEMS,
  };
};
export default useMultiLanguageApplicationLayout;
