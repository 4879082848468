import React from 'react';
import useMultiLanguageRegisterAccount from 'page/Application/ManageAccountSoliton/RegisterAccountSoliton/hook/useMultiLanguageRegisterAccount';
import { InputStyled } from 'components/Form2/styled';
import { MAX_LENGTH_TEXT } from 'constant/inputMaxlength';
import LabelForm from 'components/Form/LabelForm';
import FormMessage from 'components/Form/FormMessage';
import {
  CHECK_CHARACTER,
  CHECK_LATIN_JAPANESE_NUMBER,
  CHECK_MOBILE_NUMBER,
} from 'constant/regex';
import { FormItemStyled } from 'components/Form/styled';

const FormItemValidatePhoneNumber = ({
  isEdit,
  maxLength,
  isPhone,
  textRequired,
  textValidate,
  onChange,
}) => {
  const {
    // REGISTER_ACCOUNT_PHONE_NUMBER,
    REGISTER_ACCOUNT_PLACEHOLDER_PHONE_NUMBER,
    REGISTER_ACCOUNT_INPUT_TYPE_VALIDATION,
    REGISTER_ACCOUNT_CATEGORY_ENTRY_REQUIRED,
  } = useMultiLanguageRegisterAccount();
  return (
    <FormItemStyled
      marginbottom="5px"
      className={'phoneNumber'}
      name={'phoneNumber'}
      label={<LabelForm className="label-name" text="電話番号" />}
      extra={
        <FormMessage
          text1={'携帯電話をお持ちの方は、携帯電話番号をご記入ください。'}
        />
      }
      rules={[
        {
          required: isEdit,
          message: (
            <FormMessage
              text1={
                textRequired
                  ? textRequired
                  : REGISTER_ACCOUNT_CATEGORY_ENTRY_REQUIRED
              }
            />
          ),
        },
        () => ({
          validator(_, value) {
            if (isPhone) {
              if (!CHECK_MOBILE_NUMBER.test(value)) {
                return Promise.reject(
                  <FormMessage
                    text1={
                      '無効な文字列です。電話番号は0～9で入力してください。'
                    }
                  />,
                );
              }
            }

            if (!isPhone) {
              if (
                value &&
                (CHECK_CHARACTER === null ||
                  !CHECK_LATIN_JAPANESE_NUMBER.test(value) || // check japanese, latin
                  value.trim() === '' || // check blank
                  (value &&
                    [...value].some((char) => char.codePointAt(0) > 65535))) // check 4-byte characters
              ) {
                return Promise.reject(
                  <FormMessage
                    text1={
                      textValidate
                        ? textValidate
                        : REGISTER_ACCOUNT_INPUT_TYPE_VALIDATION
                    }
                    marginBottom={'12px'}
                  />,
                );
              }
            }

            return Promise.resolve();
          },
        }),
      ]}
    >
      <InputStyled
        onChange={onChange}
        maxLength={maxLength ? maxLength : MAX_LENGTH_TEXT}
        placeholder={REGISTER_ACCOUNT_PLACEHOLDER_PHONE_NUMBER}
      />
    </FormItemStyled>
  );
};
export default FormItemValidatePhoneNumber;
