import React, { useMemo } from 'react';
import { useResetRecoilState } from 'recoil';
import { signUpUser } from 'recoil/atom/signUpUser';
import LoadingSpin from 'components/LoadingSpin';
import Link from 'components/Link';
import BtnSubmitFullSizeLanguage from 'page/Authentication/component/Button/language/btnSubmitFullSize';
import { SIGN_IN_PATH, SIGN_UP_PATH } from 'constant/authenticationPath';
import { TextAlignCenter } from 'page/Authentication/component/Button/styled';
import { ButtonSubmit } from 'page/Authentication/styled';

const BtnSubmitFullSize = ({ loading, formName, size, isMaintenance }) => {
  const {
    FORM_SIGN_IN_BUTTON_SUBMIT,
    FORM_SIGN_IN_MOVE_TO_SIGN_UP,
    FORM_FORGOT_PASSWORD_BUTTON_SUBMIT,
    FORM_FORGOT_PASSWORD_MOVE_TO_SIGN_IN,
    FORM_SIGN_UP_BUTTON_SUBMIT,
    FORM_SIGN_UP_NAVIGATE_TO_LOGIN,
    FORM_PASSWORD_BUTTON_SUBMIT,
  } = BtnSubmitFullSizeLanguage();
  const resetSignUpUserRecoil = useResetRecoilState(signUpUser);

  const checkFormName = useMemo(() => {
    switch (formName) {
      case 'sign-in':
        resetSignUpUserRecoil();
        return {
          buttonText: FORM_SIGN_IN_BUTTON_SUBMIT,
          linkText: FORM_SIGN_IN_MOVE_TO_SIGN_UP,
          path: `/${SIGN_UP_PATH}`,
        };
      case 'sign-up':
        return {
          buttonText: FORM_SIGN_UP_BUTTON_SUBMIT,
          linkText: FORM_SIGN_UP_NAVIGATE_TO_LOGIN,
          path: SIGN_IN_PATH,
        };

      case 'forgot-password':
        resetSignUpUserRecoil();
        return {
          buttonText: FORM_FORGOT_PASSWORD_BUTTON_SUBMIT,
          linkText: FORM_FORGOT_PASSWORD_MOVE_TO_SIGN_IN,
          path: SIGN_IN_PATH,
        };
      case 'reset-password':
        return {
          buttonText: FORM_PASSWORD_BUTTON_SUBMIT,
          linkText: FORM_FORGOT_PASSWORD_MOVE_TO_SIGN_IN,
          path: SIGN_IN_PATH,
        };
      case 'password-expire':
        return {
          buttonText: FORM_PASSWORD_BUTTON_SUBMIT,
          linkText: FORM_FORGOT_PASSWORD_MOVE_TO_SIGN_IN,
          path: SIGN_IN_PATH,
        };

      default:
        return null;
    }
  }, [
    formName,
    resetSignUpUserRecoil,
    FORM_SIGN_IN_BUTTON_SUBMIT,
    FORM_SIGN_IN_MOVE_TO_SIGN_UP,
    FORM_SIGN_UP_BUTTON_SUBMIT,
    FORM_SIGN_UP_NAVIGATE_TO_LOGIN,
    FORM_FORGOT_PASSWORD_BUTTON_SUBMIT,
    FORM_FORGOT_PASSWORD_MOVE_TO_SIGN_IN,
    FORM_PASSWORD_BUTTON_SUBMIT,
  ]);

  return (
    <>
      <ButtonSubmit
        className="button-responsive"
        type="primary"
        block
        htmlType="submit"
        disabled={loading}
      >
        {' '}
        {loading ? <LoadingSpin /> : checkFormName.buttonText}
      </ButtonSubmit>
      <TextAlignCenter>
        <Link
          to={isMaintenance ? SIGN_IN_PATH : checkFormName.path}
          text={checkFormName.linkText}
          size={size}
        />
      </TextAlignCenter>
    </>
  );
};

export default BtnSubmitFullSize;
