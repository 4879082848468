import React, { useEffect, useMemo, useState } from 'react';
import { useRecoilValue } from 'recoil';
import { getResultFuzzySearch } from 'api/opensearch/opensearch.api';
import useCustomQuery from 'hook/useQuery/useCustomQuery';
import Breadcrumb from 'components/Layout/ApplicationLayout/Breadcrumb';
import HeaderOpenSearch from '../Component/HeaderOpenSearch';
import LayoutOpenSearch from '../Component/LayoutOpenSearch';
import TableNotifyOpenSearch from '../Component/TableNotifyOpenSearch';
import Pagination from 'components/Pagination';
import { Link, useSearchParams } from 'react-router-dom';
import { userInformation } from 'recoil/atom/authentication';
import { defaultSelectPageSizeOption } from 'constant/Pagination/pageSizeOption';
import { Spin } from 'antd';
import useSetTitlePage from '../../../../hook/useSetTitlePage';

const OpensearchResult = ({ roleType }) => {
  const [pageSize, setPageSize] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  let [dataOpenSearch, setDataOpenSearch] = useState([]);
  let [searchParams] = useSearchParams();
  const { useQueryAPI } = useCustomQuery();
  const keyword = searchParams.get('keyword');
  const { role } = useRecoilValue(userInformation);
  useSetTitlePage('検索結果');

  const listItemSA = useMemo(() => {
    return [
      {
        title: <Link to={'/manage-company-list'}>企業管理</Link>,
      },
      {
        title: '検索結果',
      },
    ];
  }, []);

  const listItemSU = useMemo(() => {
    return [
      {
        title: <Link to={'/my-page'}>マイページ</Link>,
      },
      {
        title: '検索結果',
      },
    ];
  }, []);

  const listItemPartner = useMemo(() => {
    return [
      {
        title: <Link to={'/partner-page'}>パートナー専用ページ</Link>,
      },
      {
        title: '検索結果',
      },
    ];
  }, []);

  const listItemByRole = useMemo(() => {
    return {
      ROLE_SOLITON_ADMIN: listItemSA,
      ROLE_SOLITON_USER: listItemSU,
      ROLE_PARTNER_USER: listItemPartner,
      ROLE_PARTNER_ADMIN: listItemPartner,
    };
  }, [listItemSA, listItemSU, listItemPartner]);

  const { data: resultListData, isLoading } = useQueryAPI({
    api: getResultFuzzySearch,
    params: {
      keyword: keyword.trim(),
      pageSize: pageSize,
    },
    key: 'resultList',
    enabled: !!keyword,
  });

  const handlePagingChange = (pageNumber, pageSize) => {
    setCurrentPage(pageNumber);
    setPageSize(pageSize);
  };

  const handleChangePageSize = (value) => {
    setPageSize(value);
    setCurrentPage(1);
  };

  useEffect(() => {
    if (isLoading === false && resultListData?.ec === false) {
      if (resultListData?.data.listPageOpenSearch?.length === 1) {
        setDataOpenSearch(
          resultListData?.data.listPageOpenSearch[0]?.listSearchResponse,
        );
      } else if (resultListData?.data.listPageOpenSearch?.length > 1) {
        setDataOpenSearch(
          resultListData?.data.listPageOpenSearch[currentPage - 1]
            ?.listSearchResponse,
        );
      } else {
        setDataOpenSearch([]);
      }
    }
  }, [currentPage, isLoading, resultListData?.data, resultListData?.ec]);

  useEffect(() => {
    setPageSize(10);
    setCurrentPage(1);
  }, [keyword]);

  return (
    <>
      <Breadcrumb listItem={listItemByRole[role]} titlePage="検索結果" />
      <LayoutOpenSearch>
        <Spin spinning={isLoading}>
          <HeaderOpenSearch
            titleScreen={
              <div style={{ maxWidth: '100%', wordBreak: 'break-all' }}>
                「{keyword.trim()}」検索結果の一覧
              </div>
            }
            loading={isLoading}
            isDisplayNotifyList={true}
            pageSize={pageSize}
            handleChangePageSize={handleChangePageSize}
          />
          <TableNotifyOpenSearch
            isLoading={isLoading}
            keyword={keyword.trim()}
            dataOpenSearch={dataOpenSearch}
            roleType={roleType || 'partnerUser'}
          />
          <Pagination
            totalTitle={'検索結果の総数'}
            currentPage={currentPage}
            handlePagingChange={handlePagingChange}
            total={resultListData?.data?.totalRecords}
            pageSize={pageSize}
            selectPageSizeOptions={defaultSelectPageSizeOption}
            handleChangePageSize={handleChangePageSize}
          />
        </Spin>
      </LayoutOpenSearch>
    </>
  );
};
export default OpensearchResult;
