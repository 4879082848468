import React, { useMemo } from 'react';
import { MoreOutlined } from '@ant-design/icons';
import { DropDownStyled } from 'components/Tree/components/Dropdown/styled';
import isPropValid from '@emotion/is-prop-valid';
import { StyleSheetManager } from 'styled-components';

// This implements the default behavior from styled-components v5
const shouldForwardProp = (propName, target) => {
  if (typeof target === 'string') {
    // For HTML elements, forward the prop if it is a valid HTML attribute
    return isPropValid(propName);
  }
  // For other elements, forward all props
  return true;
};

const ChildrenMenu = ({
  onCreateMode,
  onEditMode,
  onDelete,
  showMenu,
  onFilterMenu,
  filterEnable,
}) => {
  const items = useMemo(() => {
    if (filterEnable) {
      return [
        {
          key: '1',
          label: <div onClick={onCreateMode}>追加</div>,
        },
        {
          key: '2',
          label: <div onClick={onEditMode}>編集</div>,
        },
        {
          key: '3',
          label: <div onClick={onDelete}>削除</div>,
        },
        {
          key: '4',
          label: <div onClick={onFilterMenu}>コンテンツ</div>,
        },
      ];
    } else {
      return [
        {
          key: '1',
          label: <div onClick={onCreateMode}>追加</div>,
        },
        {
          key: '2',
          label: <div onClick={onEditMode}>編集</div>,
        },
        {
          key: '3',
          label: <div onClick={onDelete}>削除</div>,
        },
      ];
    }
  }, [onCreateMode, onEditMode, onDelete, filterEnable, onFilterMenu]);

  return (
    <StyleSheetManager shouldForwardProp={shouldForwardProp}>
      <DropDownStyled
        menu={{ items }}
        placement="bottom"
        opacity={showMenu ? '1' : '0'}
      >
        <MoreOutlined />
      </DropDownStyled>
    </StyleSheetManager>
  );
};

export default ChildrenMenu;
