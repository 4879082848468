import { useIntl } from 'react-intl';

const useMultiLanguageRegisterProduct = () => {
  const intl = useIntl();

  const APPLICATION_PRODUCT_MESSAGE_ADD_SUCCESS = intl.formatMessage({
    id: 'application.product.message.add.success',
  });

  const APPLICATION_PRODUCT_TITLE = intl.formatMessage({
    id: 'application.product.title',
  });

  const APPLICATION_PRODUCT_EDIT_TITLE = intl.formatMessage({
    id: 'application.product.edit.title',
  });

  const APPLICATION_PRODUCT_FORM_PRODUCTNAME = intl.formatMessage({
    id: 'application.product.form.productName',
  });

  const APPLICATION_PRODUCT_PLACEHOLDER_PRODUCTNAME = intl.formatMessage({
    id: 'application.product.placeholder.productName',
  });

  const APPLICATION_PRODUCT_FIELD_REQUIRED = intl.formatMessage({
    id: 'application.product.field.required',
  });

  const APPLICATION_PRODUCT_FORM_CATEGORYNAME = intl.formatMessage({
    id: 'application.product.form.categoryName',
  });

  const APPLICATION_PRODUCT_FORM_STATUS = intl.formatMessage({
    id: 'application.product.form.status',
  });

  const APPLICATION_PRODUCT_STATUS_SHOW = intl.formatMessage({
    id: 'application.product.status.show',
  });

  const APPLICATION_PRODUCT_STATUS_HIDE = intl.formatMessage({
    id: 'application.product.status.hide',
  });

  const APPLICATION_PRODUCT_FORM_LOGO = intl.formatMessage({
    id: 'application.product.form.logo',
  });

  const APPLICATION_PRODUCT_FORM_CONTENT = intl.formatMessage({
    id: 'application.product.form.content',
  });

  const APPLICATION_PRODUCT_FORM_MENU = intl.formatMessage({
    id: 'application.product.form.menu',
  });

  const APPLICATION_PRODUCT_BUTTON_DELETE = intl.formatMessage({
    id: 'application.product.button.delete',
  });

  const APPLICATION_PRODUCT_BUTTON_KEEP = intl.formatMessage({
    id: 'application.product.button.keep',
  });

  const APPLICATION_PRODUCT_MESSAGE_CANCEL = intl.formatMessage({
    id: 'application.product.message.cancel',
  });

  const APPLICATION_PRODUCT_MESSAGE_DELETE = intl.formatMessage({
    id: 'application.product.message.delete',
  });

  const APPLICATION_PRODUCT_OK = intl.formatMessage({
    id: 'application.product.ok',
  });

  const APPLICATION_PRODUCT_FORM_CANCEL = intl.formatMessage({
    id: 'application.product.form.cancel',
  });

  const APPLICATION_PRODUCT_FORM_SUBMIT = intl.formatMessage({
    id: 'application.product.form.submit',
  });

  const APPLICATION_PRODUCT_CONFIRM_MESSAGE_SUBMIT = intl.formatMessage({
    id: 'application.product.confirm.message.submit',
  });

  return {
    APPLICATION_PRODUCT_MESSAGE_ADD_SUCCESS,
    APPLICATION_PRODUCT_TITLE,
    APPLICATION_PRODUCT_EDIT_TITLE,
    APPLICATION_PRODUCT_FORM_PRODUCTNAME,
    APPLICATION_PRODUCT_PLACEHOLDER_PRODUCTNAME,
    APPLICATION_PRODUCT_FIELD_REQUIRED,
    APPLICATION_PRODUCT_FORM_CATEGORYNAME,
    APPLICATION_PRODUCT_FORM_STATUS,
    APPLICATION_PRODUCT_STATUS_SHOW,
    APPLICATION_PRODUCT_STATUS_HIDE,
    APPLICATION_PRODUCT_FORM_LOGO,
    APPLICATION_PRODUCT_FORM_CONTENT,
    APPLICATION_PRODUCT_BUTTON_DELETE,
    APPLICATION_PRODUCT_BUTTON_KEEP,
    APPLICATION_PRODUCT_MESSAGE_CANCEL,
    APPLICATION_PRODUCT_MESSAGE_DELETE,
    APPLICATION_PRODUCT_OK,
    APPLICATION_PRODUCT_FORM_CANCEL,
    APPLICATION_PRODUCT_FORM_SUBMIT,
    APPLICATION_PRODUCT_FORM_MENU,
    APPLICATION_PRODUCT_CONFIRM_MESSAGE_SUBMIT,
  };
};

export default useMultiLanguageRegisterProduct;
