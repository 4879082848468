import React, { useState } from 'react';
import Breadcrumb from 'components/Layout/ApplicationLayout/Breadcrumb';
import LayoutDefault from 'components/Layout/LayoutDefault';
import Tree from 'components/Tree';
import { defaultData, listItemTree } from 'components/Tree/treeData';

const listItem = [
  {
    title: 'マイページ',
  },
];

const initialData = {
  ...defaultData,
  children: listItemTree,
};
const WebsiteSetting = () => {
  const [treeData, setTreeData] = useState(initialData);
  const [showDropdown, setShowDropdown] = useState(true);

  return (
    <>
      <Breadcrumb listItem={listItem} titlePage={'マイページ'} />
      <LayoutDefault>
        <Tree
          treeData={treeData}
          setTreeData={setTreeData}
          isLoading={false}
          showDropdown={showDropdown}
          setShowDropdown={setShowDropdown}
        />
      </LayoutDefault>
    </>
  );
};

export default WebsiteSetting;
