import React, { useState } from 'react';
import { useRecoilState } from 'recoil';
import ModalTitleFile from 'page/Application/ManageProjectRegistration/component/ModalTitleFile';
import dayjs from 'dayjs';
import { EditOutlined } from '@ant-design/icons';
import { Form } from 'antd';
import { FormAddPermissionWrapper } from 'page/Application/ManageCompanyList/component/Form/FormAddProduct/styled';
import { ButtonStyle } from 'page/Application/ManageCompanyList/component/Form/styled';
import { Grid2 } from 'page/Application/ManageProjectRegistration/component/styled';
import { fileDetailsNew, listFileDetailsRecoil } from 'recoil/atom/fileRequest';
import { ButtonAction } from 'style/styleComponent/styled';

const FormAddTitleFile = ({ isEdit, userId, dataSource, data, formName }) => {
  const [form] = Form.useForm();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [, setFileDetailsNew] = useRecoilState(fileDetailsNew);
  const [listFileDetails, setListFileDetails] = useRecoilState(
    listFileDetailsRecoil,
  );
  const [isCheckDate, setIsCheckDate] = useState(true);

  const [, setList] = useState([]);
  const [detailStatus, setDetailStatus] = useState([]);
  const [fileObject, setFileObject] = useState(null);

  const handleOpenModal = () => {
    checkStatus(true);
    form.setFieldValue('displayStatus', true);
    setIsModalOpen(true);
    form.setFields([{}]);
  };

  const checkStatus = (value) => {
    if (value === true) {
      setDetailStatus([false, false]);
    } else {
      setDetailStatus([true, true]);
    }
  };

  const handleEditOpenModal = () => {
    checkStatus(dataSource?.displayStatus);
    form.setFieldValue('displayStatus', dataSource?.displayStatus);
    setIsModalOpen(true);
  };

  const handleCancel = () => {
    if (!isEdit) {
      form.setFieldValue('displayStatus', true);
      form.resetFields();
    }
    form.resetFields(['title']);
    form.setFieldsValue({
      titleFile: dataSource?.title,
      displayStatus: dataSource?.displayStatus,
      file: [dataSource?.multipartFile],
      displayedDate: dataSource?.displayDate,
      textArea: dataSource?.explanation,
      publishedFromToDate: [
        dataSource?.publishedFrom && dayjs(dataSource?.publishedFrom),
        dataSource?.publishedTo && dayjs(dataSource?.publishedTo),
      ],
    });
    setIsModalOpen(false);
  };
  const handleAddFile = () => {
    form
      .validateFields()
      .then((values) => {
        const newForm = {
          mainIndex: listFileDetails.length,
          title: values?.titleFile?.trim(),
          displayStatus: values?.displayStatus,
          explanation: values?.textArea,
          displayDate: values?.displayedDate
            ? dayjs(values?.displayedDate).format('YYYY-MM-DD')
            : null,
          publishedFrom: values?.publishedFromToDate?.[0] ?? null,
          publishedTo: values?.publishedFromToDate?.[1] ?? null,
          fileStorageRequest: fileObject && {
            ...fileObject,
          },
          multipartFile: values?.[0]?.originFileObj ?? values?.file?.[0],
        };

        if (isEdit) {
          const nextCheck = listFileDetails.map((item, index) => {
            if (dataSource.key === index) {
              return {
                ...newForm,
                mainIndex: item.mainIndex,
                detailId: item.detailId ?? null,
              };
            }
            return {
              ...item,
              mainIndex: item.mainIndex,
            };
          });
          setListFileDetails(nextCheck);
        }
        if (userId || dataSource) {
          data?.map((obj) => {
            if (obj?.key === userId) {
              setList((prev) => [...prev, { key: obj.key, ...newForm }]);
              return { key: obj.key, ...newForm };
            }
            setList((prev) => [...prev, obj]);
            return obj;
          });
        } else {
          setFileDetailsNew(newForm);
        }
        setIsModalOpen(false);
        setIsCheckDate(false);
        form.resetFields();
      })
      .catch((err) => {
        setIsModalOpen(true);
        return false;
      });
  };

  return (
    <>
      {
        <ModalTitleFile
          isEdit={isEdit}
          isModalOpen={isModalOpen}
          handleCancel={handleCancel}
          handleOk={handleAddFile}
          form={form}
          dataSource={dataSource}
          checkStatus={detailStatus}
          setDetailStatus={setDetailStatus}
          isCheckDate={isCheckDate}
          setIsCheckDate={setIsCheckDate}
          setFileObject={setFileObject}
          fileObject={fileObject}
          formName={formName}
        />
      }
      <Grid2>
        {isEdit ? (
          <ButtonAction type="link" onClick={handleEditOpenModal}>
            <EditOutlined />
          </ButtonAction>
        ) : (
          <FormAddPermissionWrapper>
            <ButtonStyle onClick={handleOpenModal}>
              {/*must have a span tag so there are no spaces between letters*/}
              <span> {'追加'}</span>
            </ButtonStyle>
          </FormAddPermissionWrapper>
        )}
      </Grid2>
    </>
  );
};
export default FormAddTitleFile;
