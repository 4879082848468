import React, { Suspense, useEffect, useState } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import { Form, Spin } from 'antd';
import { getDetailPartnerUser } from 'api/myPage/partnerUser.api';
import { ButtonPrimary } from 'components/Button';
import Breadcrumb from 'components/Layout/ApplicationLayout/Breadcrumb';
import LayoutDefault from 'components/Layout/LayoutDefault';
import RowButton from 'components/Layout/RowButton';
import useCustomQuery from 'hook/useQuery/useCustomQuery';
import {
  DepartmentPositionDuty,
  EmailPasswordAccountType,
  InformationCompany,
  LastNameFirstName,
} from 'page/Application/MyPage/component/FormMyPage';
import {
  FormMyPageWrapper,
  GridFormAccount,
  GridFormAccount2to3,
} from 'page/Application/MyPage/component/FormMyPage/styled';
import PhoneNumber from 'page/Application/MyPage/component/FormMyPage/PhoneNumber';
import { TextBoxGroupJapanArea } from 'page/Application/MyPagePartnerAdmin/component/Form';
import { FormTitle } from 'page/Application/ManageCompanyList/component/Form';
const MyPagePartnerUser = () => {
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const { useQueryAPI } = useCustomQuery();
  const [checkedListAll, setCheckedListAll] = useState([]);
  const listItem = [
    {
      title: <Link to={'/'}>パートナー専用ページ</Link>,
    },
    {
      title: 'マイページ',
    },
  ];

  const { data: partnerDetail = [], isLoading } = useQueryAPI({
    api: getDetailPartnerUser,
    key: 'myPagePU',
    params: {},
  });

  useEffect(() => {
    const data = partnerDetail?.data;
    form.setFieldsValue({
      ...data,
      companyName: data?.partnerName,
      password: 'パスワードパスワード',
    });

    const detailData = partnerDetail?.data;
    form.setFieldsValue({
      duty: detailData?.dutyId,
      phoneNumber:
        detailData?.phoneNumber && detailData?.phoneNumber !== ''
          ? detailData?.phoneNumber
          : detailData?.mobileNumber,
    });
  }, [partnerDetail?.data, form, partnerDetail]);

  const handleSwitchEditMode = () => {
    navigate(`/my-page/edit`, {
      state: {
        ...partnerDetail?.data,
      },
    });
  };

  return (
    <Suspense fallback={isLoading}>
      <Breadcrumb listItem={listItem} titlePage={'マイページ'} />
      <LayoutDefault>
        <FormTitle
          notice={'注意：'}
          title={'変更した内容は画面下部の「保存」ボタンを押すと保存されます。'}
          type={'text'}
          styles={{ color: '#9D1C49' }}
        />
        <Spin spinning={isLoading}>
          <FormMyPageWrapper>
            <Form size={'Large'} form={form} disabled={true}>
              <InformationCompany />
              <LastNameFirstName isEdit={false} />
              <EmailPasswordAccountType
                isEdit={false}
                data={partnerDetail?.data}
                editPassword={false}
                disabled={true}
                isDetail={true}
              />
              <PhoneNumber isEdit={false} maxLength={14} isPhone={true} />
              <DepartmentPositionDuty editMode={false} />
              <TextBoxGroupJapanArea
                isEdit={true}
                formDetailData={partnerDetail}
                checkedListAll={checkedListAll}
                setCheckedListAll={setCheckedListAll}
                disabled={true}
              />
            </Form>
            <GridFormAccount className="margin-top-20">
              <GridFormAccount2to3>
                <RowButton>
                  <ButtonPrimary
                    text={'編集'}
                    width={120}
                    onClick={handleSwitchEditMode}
                  />
                </RowButton>
              </GridFormAccount2to3>
            </GridFormAccount>
          </FormMyPageWrapper>
        </Spin>
      </LayoutDefault>
    </Suspense>
  );
};

export default MyPagePartnerUser;
