export const handleNavigate = async (
  data,
  navigate,
  setNotifyId,
  refetch,
  productId,
  location,
) => {
  if (data?.destinationType === 'DETAILS_AVAILABLE') {
    if (location?.includes('list-notify')) {
      if (productId) {
        navigate(
          `/top-page/top-page-product/list-notify/${productId}/notify-detail/${data?.notifyId}`,
        );
      } else {
        navigate(`/top-page/list-notify/notify-detail/${data?.notifyId}`);
      }
    } else {
      if (productId) {
        navigate(
          `/top-page/top-page-product/${productId}/notify-detail/${data?.notifyId}`,
        );
      } else {
        navigate(`/top-page/notify-detail/${data?.notifyId}`);
      }
    }
  }

  if (
    (data?.url && data?.destinationType === 'EXTERNAL_LINKS') ||
    data?.destinationType === 'INTERNAL_LINKS'
  ) {
    if (data?.isNewTab) {
      window.open(data?.url, '_blank');
    } else {
      window.open(data?.url, '_self');
    }
  }

  if (data?.destinationType === 'PDF') {
    if (data?.saveName) {
      await setNotifyId(data?.notifyId);
      await refetch().then((value) => {
        window.open(value?.data, '_self');
      });
    }
  }
};
