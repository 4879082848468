import React from 'react';
import {
  GroupButton,
  HeaderTable,
  SearchStyled,
  SelectStyled,
  TitleScreen,
} from 'components/Layout/HeaderListDataLayout/styled';
import { Tooltip } from 'antd';

const HeaderListDataLayout = ({
  titleScreen,
  children,
  searchPlaceholder,
  onSearch,
  searchValue,
  setSearchValue,
  isLoading,
  maxLength,
  handleChangePageSize,
  value,
  isHidden,
  pageSizeOptions,
  shortenTitle,
}) => {
  return (
    <HeaderTable>
      {shortenTitle ? (
        <Tooltip
          title={titleScreen}
          overlayStyle={{ maxWidth: '900px' }}
          placement="topLeft"
        >
          <TitleScreen shortenTitle={shortenTitle}>{titleScreen}</TitleScreen>
        </Tooltip>
      ) : (
        <TitleScreen>{titleScreen}</TitleScreen>
      )}

      <GroupButton>
        {children}
        {isHidden ?? (
          <SearchStyled
            placeholder={
              searchPlaceholder ?? '姓名、又は、会社名に従え、検索します。'
            }
            onSearch={onSearch}
            value={searchValue}
            onChange={(event) => setSearchValue(event.target.value)}
            loading={isLoading}
            maxLength={maxLength ?? 256}
            style={{
              fontSize: 14,
            }}
          />
        )}
        <SelectStyled
          value={value}
          onChange={handleChangePageSize}
          options={pageSizeOptions}
          title={''}
        />
      </GroupButton>
    </HeaderTable>
  );
};

export default HeaderListDataLayout;
