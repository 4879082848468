import styled from 'styled-components';
import { Button, Checkbox } from 'antd';
import Search from 'antd/es/input/Search';

export const GridForm = styled.div`
  display: grid;
  grid-template-columns: 200px 400px 40px 51px 44px;
  column-gap: 15px;
`;
export const Grid1 = styled.div`
  grid-column: 1 / 2;
  padding-top: 8px;
`;

export const Grid2 = styled.div`
  grid-column: 2 / 3;
`;
export const Grid3 = styled.div`
  grid-column: 3/ 4;
  margin: 0 auto;
`;

export const Grid5 = styled.div`
  grid-column: 5/ 6;
`;

export const Grid3to5 = styled.div`
  grid-column: 3/ 5;
`;

export const Grid2to4 = styled.div`
  grid-column: 2 / 5;
`;

export const LabelFrom = styled.div`
  padding-top: 2px;
  text-align: right;
  font-family: 'inter-regular', sans-serif;
  span {
    color: var(--FORM-LABEL-default);
  }

  .mark {
    margin-left: 6px;
    color: #9d1c49;
  }
`;

export const CheckBoxStyled = styled(Checkbox)`
  border: 1px solid #d9d9d9;
  justify-content: center;
  border-radius: 8px;
  padding: 8px;
  span {
    color: var(--FORM-LABEL-default);
    font-family: 'inter-regular', sans-serif;
  }
`;
export const SearchStyled = styled(Search)`
  .ant-input-lg {
    line-height: 1.7;
  }
`;
export const ButtonStyle = styled(Button)`
  font-size: 14px !important;
  box-shadow: none !important;
  position: relative;
  span {
    color: var(--FORM-LABEL-default);
    font-family: 'inter-regular', sans-serif;
  }
  min-width: 86px;
  border: var(--FORM-LABEL-default) 1px solid;
`;
export const ButtonOkStyle = styled(Button)`
  font-size: 14px !important;
  box-shadow: none !important;
  span {
    font-family: 'inter-regular', sans-serif;
  }
  min-width: 86px;
  border: var(--FORM-LABEL-default) 1px solid;
`;
export const ButtonIconStyled = styled(Button)`
  min-width: 44px;
  width: 44px;
  .icon {
    color: var(--PRIMARY-default);
  }
`;
