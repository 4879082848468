import React from 'react';
import { FormItemStyled, InputStyled } from 'components/Form2/styled';
import useMultiLanguage from 'hook/useMultiLanguage';
import { CHECK_TEXT_NUMBER } from 'constant/regex';
import { FormMessage } from 'components/Form';

const FormValidateSaleForceId = ({ formName, maxLength }) => {
  const { FORM_SALE_FORCE_VALIDATE_MESSAGE } = useMultiLanguage();

  return (
    <FormItemStyled
      name={formName}
      rules={[
        () => ({
          validator(_, value) {
            if (value && value.length > 0) {
              // validate length 18 character
              if (CHECK_TEXT_NUMBER.test(value) || value.length !== 18) {
                return Promise.reject(
                  <FormMessage
                    text1={FORM_SALE_FORCE_VALIDATE_MESSAGE}
                    marginBottom={'12px'}
                  />,
                );
              } else {
                return Promise.resolve();
              }
            } else {
              return Promise.resolve();
            }
          },
        }),
      ]}
    >
      <InputStyled maxLength={maxLength ?? 256} />
    </FormItemStyled>
  );
};

export default FormValidateSaleForceId;
