import { message } from 'antd';

const UseMessageApi = () => {

  const [messageApiErrorNetwork, contextHolderMessageApi] = message.useMessage();

  const handleErrorNetwork = () => {
    messageApiErrorNetwork.open({
      type: 'error',
      content: '問題が発生しました。インターネット接続を確認して、もう一度やり直してください。',
    }).then();
  };
  return {
    handleErrorNetwork,
    contextHolderMessageApi,
  };
};

export default UseMessageApi;