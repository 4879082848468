import React, { useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { handleNavigate } from 'page/Application/ManageProductAndService/utils/navigate/navigateNotifyTopPage';
import LinkNotify from 'page/Application/ManageProductAndService/TopPageProductPartnerUser/component/LinkNotify';
import DragSortingTable from '../DragSortingTable';
import dayjs from 'dayjs';
import { ButtonAction } from 'style/styleComponent/styled';
import { PinnedLineIcon } from '../../styled';
import { DATE_FORMAT_LIST } from 'constant/notificationConstants';
import { useRecoilValue } from 'recoil';
import { network } from 'recoil/atom/network';
const PinnedTable = ({
  dataSourcePinned,
  setDataSource,
  isLoading,
  refetch,
  setNotifyId,
  productId,
  errorNetWork,
}) => {
  const navigate = useNavigate();
  const isOnline = useRecoilValue(network);
  const columns = useMemo(() => {
    const handleUnpin = (notifyId) => {
      setDataSource((prevState) => {
        return prevState.map((data) => {
          if (data.notifyId === notifyId) {
            data.isPinned = false;
            data.notifyOrderId = 0;
          } else if (data.isPinned == true) {
            data.notifyOrderId = 1;
          }
          return data;
        });
      });
    };
    return [
      {
        key: 'sort',
        width: 50,
        align: 'center',
      },
      {
        title: 'お知らせ',
        render: (record) => {
          return (
            <>
              <div>
                {record?.destinationType === 'NO_DETAILS' ? (
                  <p style={{ wordBreak: 'break-word' }}>
                    {record.titleWithCategory}
                  </p>
                ) : (
                  <LinkNotify
                    text={record.titleWithCategory}
                    type={record?.destinationType}
                    handleNavigate={() =>
                      handleNavigate(
                        record,
                        navigate,
                        setNotifyId,
                        refetch,
                        productId,
                      )
                    }
                  />
                )}
              </div>
              <p>{dayjs(record.displayDate).format(DATE_FORMAT_LIST[1])}</p>
            </>
          );
        },
        responsive: ['xs'],
      },

      {
        key: 'displayedDate',
        dataIndex: 'displayedDate',
        align: 'left',
        render: (text) => {
          return (
            <div title={''}>{dayjs(text).format(DATE_FORMAT_LIST[1])}</div>
          );
        },
        showSorterTooltip: false,
        sorter: true,
        ellipsis: true,
        width: '150px',
        responsive: ['sm'],
      },
      {
        key: 'titleWithCategory',
        title: 'お知らせ',
        dataIndex: 'titleWithCategory',
        align: 'left',
        showSorterTooltip: false,
        sorter: true,
        responsive: ['sm'],
        render: (text, record) => {
          return record?.destinationType === 'NO_DETAILS' ? (
            <span>{text}</span>
          ) : (
            <LinkNotify
              text={text}
              type={record?.destinationType}
              handleNavigate={() => {
                if (isOnline) {
                  handleNavigate(
                    record,
                    navigate,
                    setNotifyId,
                    refetch,
                    productId,
                  ).then();
                } else {
                  errorNetWork();
                }
              }}
            />
          );
        },
      },
      {
        key: 'notifyId',
        dataIndex: 'notifyId',
        render: (notifyId) => (
          <ButtonAction
            type="link"
            onClick={() => handleUnpin(notifyId)}
            icon={<PinnedLineIcon />}
          />
        ),
        align: 'center',
        width: 50,
      },
    ];
  }, [navigate, productId, refetch, setDataSource, setNotifyId, isOnline]);

  return (
    <>
      {!isLoading && dataSourcePinned != [] && (
        <DragSortingTable
          columns={columns}
          isLoading={isLoading}
          dataSource={dataSourcePinned}
          setDataSource={setDataSource}
          isBoldBottomBorder={true}
        />
      )}
    </>
  );
};

export default PinnedTable;
